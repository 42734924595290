import { useState } from 'react';
import { useSavedSearchesList } from '../services/savedSearches/queries';
import { useSavedSearchesDelete } from '../services/savedSearches/mutations';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useOrg } from '../../contexts/OrgProvider';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { fireDialog } from '../components/Dialog';
import { DataGridStyle } from '../theme/styled/DataGridStyle';
import { useUsersList } from '../services/users/queries';
import { DeleteIcon, ShareIcon } from '../theme/icons';
import { useSharedUsersFilter } from '../services/sharedUsersFilter/mutations';

const ShareFilterDialog = ({
  isOpen,
  onReject,
  userId,
  createSharedUsersFilter,
  isLoadingCreate,
  setSelectedSharedFilters,
}) => {
  const { data: usersList, isFetching } = useUsersList();
  const [pageSize, setPageSize] = useState(25);
  const columns = [
    {
      field: 'first_name',
      headerName: 'User',
      flex: 1,
    },
    {
      field: 'last_name',
      headerName: '',
      flex: 1,
    },
  ];

  const shareFilter = () => {
    createSharedUsersFilter(userId);
    onReject();
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Share</DialogTitle>
      <DialogContent>
        <DataGridStyle
          checkboxSelection
          loading={isFetching}
          rows={usersList ?? []}
          columns={columns}
          sx={{ minHeight: '400px' }}
          rowsPerPageOptions={[10, 25, 50]}
          onPageSizeChange={setPageSize}
          pageSize={pageSize}
          density="compact"
          disableSelectionOnClick
          onSelectionModelChange={setSelectedSharedFilters}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="cancel" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton loading={isLoadingCreate} onClick={shareFilter}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const DeleteItemDialog = ({
  isOpen,
  onReject,
  onResolve,
  item,
  deleteItem,
  isDeleting,
}) => {
  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Remove / {item.name}</DialogTitle>
      <DialogContent>Are you sure you want to delete this filter?</DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          loading={isDeleting}
          onClick={() => deleteItem(item.id, { onSuccess: onResolve })}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default function FiltersSettingsPage() {
  const [selectedSharedFilters, setSelectedSharedFilters] = useState([]);
  const [userId, setUserId] = useState('');

  const { id: orgId } = useOrg();

  const { data: savedSearchesList, isLoading: isFetching } = useSavedSearchesList({
    organization_id: orgId,
  });

  const {
    mutate: deleteItem,
    isError: didFailDeleteItem,
    isLoading: isDeleting,
    isSuccess: isSuccessfullyDeleted,
  } = useSavedSearchesDelete();

  const {
    mutate: createSharedUsersFilter,
    isLoading: isLoadingCreate,
    isSuccess: isSuccessCreateSharedFilter,
    isError: isErrorCreateSharedFilter,
  } = useSharedUsersFilter(userId, {
    user_ids: selectedSharedFilters.map((id) => id),
  });

  const confirmDelete = (item) => {
    fireDialog((promiseParams) => {
      return DeleteItemDialog({ ...promiseParams, item, deleteItem, isDeleting });
    });
  };

  const shareFilter = (userId) => {
    setUserId(userId);
    return fireDialog((promiseParams) => {
      return ShareFilterDialog({
        ...promiseParams,
        userId,
        createSharedUsersFilter,
        isLoadingCreate,
        setSelectedSharedFilters,
      });
    });
  };

  return (
    <SettingsCard sx={{ mb: 4 }} showLoading={isFetching}>
      <SettingsCard.Header>Filters</SettingsCard.Header>
      {(didFailDeleteItem || isErrorCreateSharedFilter) && (
        <Alert sx={{ mt: '15px' }} severity="error">
          An error occurred, please try again
        </Alert>
      )}
      {isSuccessfullyDeleted && (
        <Alert sx={{ mt: '15px' }} severity="info">
          Filter has been deleted successfully
        </Alert>
      )}
      {isSuccessCreateSharedFilter && (
        <Alert sx={{ mt: '15px' }} severity="info">
          Filter has been shared successfully
        </Alert>
      )}
      <SettingsCard.Main>
        <TableContainer sx={{ mb: 2 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Resource</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {savedSearchesList?.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell width={'27%'}>{item?.name}</TableCell>
                    <TableCell width={'27%'}>
                      {item?.user?.first_name} {item?.user?.last_name}
                    </TableCell>
                    <TableCell width={'16%'}>{item.resource_class}</TableCell>
                    <TableCell width={'30%'}>
                      <Button
                        startIcon={<ShareIcon />}
                        sx={{ ml: 1 }}
                        variant="text"
                        onClick={() => shareFilter(item.id)}
                      >
                        Share
                      </Button>
                      <Button
                        startIcon={<DeleteIcon />}
                        sx={{ ml: 1 }}
                        variant="text"
                        color="error"
                        onClick={() => confirmDelete(item)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </SettingsCard.Main>
    </SettingsCard>
  );
}

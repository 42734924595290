import { useMutation, useQueryClient } from 'react-query';
import NotificationPreferences from './NotificationPreferences';

export const useNotificationPreferencesEdit = (options) => {
  const queryClient = useQueryClient();
  return useMutation((preference) => NotificationPreferences.edit(preference), {
    onSuccess: () => {
      queryClient.invalidateQueries('notification-preferences');
    },
    ...options,
  });
};

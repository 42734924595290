import Cookies from 'js-cookie';
import { getActiveOrg } from '../util/UserUtils';

export default class Step {
  static async create(step) {
    step['organization_id'] = getActiveOrg();
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/task_sequences/${step.task_sequence_id}/task_sequence_steps`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(step),
      }
    );
    return response.json();
  }

  static async edit(step) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/task_sequences/${step.task_sequence_id}/task_sequence_steps/${step.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(step),
      }
    );
    return response.json();
  }

  static async delete(sequence_id, step_id) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/task_sequences/${sequence_id}/task_sequence_steps/${step_id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    return response;
  }
}

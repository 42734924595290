import Cookies from 'js-cookie';
import { toJSON, toParamString } from '../queryClientConfig';

import { getActiveOrg } from '../../../util/UserUtils';

export default class MessageTemplates {
  static async list(filters) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/message_templates?${toParamString(
        filters
      )}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async create(template) {
    template['organization_id'] = getActiveOrg();
    return await fetch(`${process.env.REACT_APP_API_BASE}/message_templates`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(template),
    }).then(toJSON);
  }

  static async show(id) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/message_templates/${id}`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
      },
    }).then(toJSON);
  }

  static async edit(template) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/message_templates/${template.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(template),
      }
    ).then(toJSON);
  }

  static async delete(options) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/message_templates/${options.id}?new_message_template_id=${options.newMessageTemplateId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
  }
}

import { useState, useEffect } from 'react';
/**
 * Hook to help provide a consistent way of ensuring that HookForm will not run useForm
 * until all form depencies exist
 * @param {*} formProps
 * @param {*} condition
 * @return {*}
 */
export const useConditionalUseFormProps = (formProps, condition) => {
  const [useFormProps, setUseFormProps] = useState();
  useEffect(() => {
    if (condition) setUseFormProps(formProps);
  }, [condition]);
  return useFormProps;
};

export const useWatch = (watchFn, watch) =>
  useEffect(() => {
    const watchSelect = watch(() => watchFn());
    return () => watchSelect.unsubscribe();
  }, [watch]);

import { useEffect } from 'react';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { DemoModeIcon } from '../theme/icons';
import { useOrganizationsReset } from '../../api/organization/mutations';
import { fireDialog } from '../components/Dialog';

export default function ResetDemoModePage() {
  const handleResetClick = () => {
    fireDialog((promiseProps) =>
      ResetDialog({
        ...promiseProps,
      })
    );
  };

  return (
    <SettingsCard>
      <SettingsCard.Header>Demo Mode</SettingsCard.Header>
      <SettingsCard.SubHeader>
        Delete existing data and populate account with sales demo data
      </SettingsCard.SubHeader>
      <SettingsCard.Main>
        <Button startIcon={<DemoModeIcon />} onClick={handleResetClick}>
          Reset
        </Button>
      </SettingsCard.Main>
    </SettingsCard>
  );
}

const ResetDialog = ({ isOpen, _, onReject }) => {
  const { isLoading, mutate } = useOrganizationsReset({
    onSettled: () => {
      window.location.href = '/';
    },
  });

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Reset Organization for Demo?</DialogTitle>
      <DialogContent>
        You are about to reset the organizations leads and tasks back to beginning.
        This can not be undone. Are you sure you want to reset the organization?
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          No
        </Button>
        <LoadingButton loading={isLoading} onClick={() => mutate()}>
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export const setActiveOrg = (org) => {
  if (!org) {
    return;
  }
  window.localStorage.setItem('default_org', org.id);
  window.localStorage.setItem('default_org_name', org.name);
};

export const conditionallySetActiveOrg = (orgs, setOrg) => {
  const org = orgs[0];
  const found_org = orgs.filter(
    (o) => o.id == window.localStorage.getItem('default_org')
  )[0];

  if (
    window.localStorage.getItem('default_org') &&
    window.localStorage.getItem('default_org').length != 0 &&
    found_org
  ) {
    setActiveOrg(found_org); // Update name if out of date
    setOrg(found_org);
    return false;
  }

  setActiveOrg(org);
  setOrg(org);
  return true;
};

export const getActiveOrg = () => {
  return window.localStorage.getItem('default_org');
};

export const getActiveOrgName = () => {
  return window.localStorage.getItem('default_org_name');
};

export const wipeActiveOrg = () => {
  window.localStorage.removeItem('default_org');
  window.localStorage.removeItem('default_org_name');
};

import { useEffect } from 'react';
import { Device } from 'twilio-client';

const device = new Device();
/*
  Takes a twilio token and the device event listender callbacks.
  Returns interface to call and disconnect.
*/
const useTwilioDevice = ({
  twilioToken,
  onDeviceReady,
  onDeviceOffline,
  onDeviceConnect,
  onDeviceDisconnect,
  onDeviceError,
  onAudioDeviceChange,
}) => {
  useEffect(() => {
    device.on('ready', onDeviceReady);
    device.on('offline', onDeviceOffline);
    device.on('connect', onDeviceConnect);
    device.on('disconnect', onDeviceDisconnect);
    device.on('error', onDeviceError);
    return () => {
      device.destroy();
      device.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    if (twilioToken) {
      // initial Device setup
      if (!device.token) {
        device.setup(twilioToken, {
          debug: true,
          enableRingingState: true,
          fakeLocalDTMF: true,
        });
        //setup required before .audio.on accessed
        device.audio.on('deviceChange', onAudioDeviceChange);
      } else {
        device.updateToken(twilioToken);
      }
    }
  }, [twilioToken]);

  const deviceStartConnection = (callData) => device.connect(callData);
  const deviceDisconnectAll = () => device.disconnectAll();

  return { deviceStartConnection, deviceDisconnectAll };
};

export default useTwilioDevice;

import { Button, Grid, TextField, Alert } from '@mui/material';
import Note from '../../clients/Note';
import { useUsersCurrent } from '../../MUI/services/users/queries';
import { useState, useEffect } from 'react';
import { useOpportunitiesEdit } from '../../api/opportunities/opportunitiesMutations';

export default function CallNotes({
  lead,
  handleCloseModal,
  opportunityId,
  lostReasonId,
  leadStatus,
  lostDetails,
  lostReasons,
}) {
  const { data: currentUser } = useUsersCurrent();
  const { mutate: editOpportunity } = useOpportunitiesEdit();
  const [notes, setNotes] = useState('');
  const lostReason = lostReasons.find((reason) => reason.id === lostReasonId);
  const [lostReasonStatus, setLostReasonStatus] = useState('');

  useEffect(() => {
    if (lostReasonId !== null) {
      setLostReasonStatus('');
    }
  }, [lostReasonId]);

  const saveNote = () => {
    if (notes != '') {
      Note.create({
        lead_id: lead.id,
        user_id: currentUser.id,
        value: notes,
      });
    }
    const opportunityParams = {
      id: opportunityId,
      lead_id: lead.id,
      lost_reason_id: lostReasonId,
      lost_details: lostDetails,
      user_id: currentUser.id,
      status: leadStatus,
    };

    if (lostReason?.name) {
      opportunityParams.lost_reason = lostReason.name;
    }

    if (lead?.opportunities[0]?.lost_reason_id) {
      delete opportunityParams.status;
    }

    if (leadStatus == '2' && !lostReasonId) {
      setLostReasonStatus(lostReasonId);
    } else {
      if (opportunityId) {
        editOpportunity(opportunityParams);
      }
      handleCloseModal();
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          multiline
          fullWidth
          variant={'filled'}
          label="Call Notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          rows={5}
        />
      </Grid>
      {lostReasonStatus === null && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <Alert severity="error" alignItems="center" justifyContent="center">
            Please select Lost Reason.
          </Alert>
        </Grid>
      )}
      <Grid item xs={6} sx={{ px: 1, py: 2 }}>
        <Button
          size="md"
          variant="outlined"
          fullWidth={true}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={6} sx={{ px: 1, py: 2 }}>
        <Button size="md" fullWidth={true} onClick={saveNote}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
}

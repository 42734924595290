import React, { useState } from 'react';
import { Box, TablePagination } from '@mui/material';
import { GridFooterContainer } from '@mui/x-data-grid';
import { DataGridStyle } from '../../theme/styled/DataGridStyle';
import { useReportsByUsersList } from '../../services/reportsByUsers/queries';

const ReportsUsersChart = ({ params }) => {
  const [reportValues, setReportValues] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sortModel, setSortModel] = useState([
    { field: 'created_at', sort: 'desc' },
  ]);
  const { isLoading } = useReportsByUsersList(
    {
      canUse: params.canUse,
      filters: [...params?.filters, ['page', page], ['page_size', pageSize]],
    },
    setReportValues
  );

  const handleSort = (newModel) => {
    setSortModel(newModel);
  };

  const columns = [
    {
      field: 'name',
      headerName: 'User',
      flex: 0,
      minWidth: 200,
    },
    {
      field: 'manual_call_made_by_user',
      headerName: 'Calls Made',
      flex: 1,
    },
    {
      field: 'manual_email_sent_by_user',
      headerName: 'Emails Sent',
      flex: 1,
    },
    {
      field: 'manual_text_messages_sent_by_user',
      headerName: 'Texts Sent',
      flex: 1,
    },
    {
      field: 'task_cancelation_by_user',
      headerName: 'Task Cancelation',
      flex: 1,
    },
    {
      field: 'task_completion_by_user',
      headerName: 'Tasks Completed',
      flex: 1,
    },
    {
      field: 'lead_won_by_user',
      headerName: 'Leads Marked Won',
      flex: 1,
    },
    {
      field: 'lead_lost_by_user',
      headerName: 'Leads Marked Lost',
      flex: 1,
    },
    {
      field: 'daily_logins_by_user',
      headerName: 'Daily Logins',
      flex: 1,
    },
  ];

  const CustomFooter = ({ page, onPageChange, onPageSizeChange, pageSize }) => {
    const handlePageChange = (event, newPage) => {
      onPageChange(newPage);
    };
    const handlePageSizeChange = (event) => {
      const newPageSize = parseInt(event.target.value, 10);
      onPageSizeChange(newPageSize);
    };

    return (
      <GridFooterContainer
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TablePagination
          component="div"
          count={reportValues?.total_count ?? 0}
          page={page}
          rowsPerPage={pageSize}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handlePageSizeChange}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </GridFooterContainer>
    );
  };

  return (
    <>
      {reportValues?.items?.length > 0 && (
        <Box width="100%">
          <DataGridStyle
            rows={reportValues.items}
            columns={columns}
            loading={isLoading}
            onPageChange={(page) => setPage(page + 1)}
            page={page - 1}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSort}
            rowCount={reportValues?.total_count ?? 0}
            paginationMode="server"
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
            density="compact"
            autoHeight
            disableColumnSelector
            components={{
              Footer: () => (
                <CustomFooter
                  rowCount={reportValues?.total_count ?? 0}
                  page={page - 1}
                  onPageChange={(page) => setPage(page + 1)}
                  onPageSizeChange={setPageSize}
                  pageSize={pageSize}
                />
              ),
            }}
          />
        </Box>
      )}
    </>
  );
};

export default ReportsUsersChart;

import { useMutation, useQueryClient } from 'react-query';
import { useOrg } from '../../../contexts/OrgProvider';
import { mutationKeyFactory } from '../queryClientConfig';
import Agents from './Agents';
import { agentsQueryKeys } from './queries';

export const agentsMutationKeys = {
  ...mutationKeyFactory('agents'),
};

export const useAgentsEdit = (options) => {
  const queryClient = useQueryClient();
  return useMutation(Agents.edit, {
    mutationKey: agentsMutationKeys.edit(),
    onSuccess: () => {
      queryClient.invalidateQueries(agentsQueryKeys.all);
    },
    ...options,
  });
};

export const useAgentsCreate = () => {
  const queryClient = useQueryClient();
  const { id } = useOrg();
  return useMutation((params) => Agents.create({ organization_id: id, ...params }), {
    mutationKey: agentsMutationKeys.create(),
    onSuccess: () => queryClient.invalidateQueries(agentsQueryKeys.all),
  });
};

export const useAgentsDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(Agents.delete, {
    mutationKey: agentsMutationKeys.delete(),
    onSuccess: () => queryClient.invalidateQueries(agentsQueryKeys.all),
  });
};

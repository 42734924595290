import { useQuery } from 'react-query';

import { ONE_HOUR, queryKeyFactory } from '../../MUI/services/queryClientConfig';
import CustomFields from './CustomFields';

export const customFieldsQueryKeys = queryKeyFactory('custom_fields');

export const useCustomFields = (params = [], options) => {
  const transformToItems = (data) => {
    return data.items;
  };
  params.push(['page_size', '10000']);
  return useQuery(
    customFieldsQueryKeys.list(params),
    () => CustomFields.list(params),
    {
      select: transformToItems,
      staleTime: ONE_HOUR,
      ...options,
    }
  );
};

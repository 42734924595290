import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { DeleteIcon } from '../../theme/icons';
import { fireDialog } from '../Dialog';

import { useLeadsDeleteGroup } from '../../services/leads/mutations';

const DeleteItemDialog = ({
  isOpen,
  onReject,
  onResolve,
  leads,
  deleteLeadsGroup,
  isDeleting,
}) => {
  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Remove</DialogTitle>
      <DialogContent>Are you sure you want to delete selected leads?</DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          loading={isDeleting}
          color="error"
          onClick={() => deleteLeadsGroup({ id: leads }, { onSuccess: onResolve })}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const GroupDeleteButton = ({ leads }) => {
  const [show, setShow] = useState(false);

  const {
    mutate: deleteLeadsGroup,
    isError: didFailDeleteItem,
    isLoading: isDeleting,
    isSuccess: isSuccessfullyDeleted,
  } = useLeadsDeleteGroup();

  return (
    <>
      <Button
        variant="outlined"
        size="medium"
        color="error"
        disabled={leads.length === 0}
        startIcon={<DeleteIcon />}
        onClick={() => {
          fireDialog((promisParams) =>
            DeleteItemDialog({
              ...promisParams,
              leads,
              deleteLeadsGroup,
              isDeleting,
            })
          );
        }}
        sx={{
          marginRight: '5px',
        }}
      >
        Delete
      </Button>
    </>
  );
};

export default GroupDeleteButton;

import { useMutation, useQueryClient } from 'react-query';
import { useOrg } from '../../../contexts/OrgProvider';
import { useUsersCurrent } from '../users/queries';
import SavedSearches from './SavedSearches';
import { savedSearchesQueryKeys } from './queries';

export const useSavedSearchesCreate = () => {
  const queryClient = useQueryClient();
  const { data: currentUser } = useUsersCurrent();
  const { id } = useOrg();
  return useMutation((params) =>
    SavedSearches.create({
      ...params,
      user_id: currentUser.id,
      organization_id: id,
    })
  );
};

export const useSavedSearchesEdit = () => {
  const queryClient = useQueryClient();
  return useMutation(SavedSearches.edit, {
    onSuccess: () => queryClient.invalidateQueries(savedSearchesQueryKeys.lists()),
  });
};

export const useSavedSearchesDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(SavedSearches.delete, {
    onSuccess: () => queryClient.invalidateQueries(savedSearchesQueryKeys.lists()),
  });
};

import { useParams, Prompt, Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { SaveIcon } from '../theme/icons';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Grid,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  TextField,
  InputLabel,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import parsePhoneNumber from 'libphonenumber-js';
import CircularProgress from '@mui/material/CircularProgress';
import { useTeamsShow } from '../../api/teams/queries';
import { useTeamsEdit } from '../../api/teams/mutations';
import { usePhonesDelete, usePhonesTeamsRegister } from '../../api/phones/mutations';
import { usePhonesSearch } from '../../api/phones/queries';
import ClipLoader from 'react-spinners/ClipLoader';
import { WAIT_TIME, createYupResolver } from '../components/HookForm/yupValidations';
import { useConditionalUseFormProps } from '../components/HookForm/hooks';
import HookForm from '../components/HookForm';
import AgentsList from './AgentsList';
import ForwardingPhone from './ForwardingPhone';
import { useQueryClient } from 'react-query';
import { useDebounce } from '../services/hooks/useDebounce';
import { useOrg } from '../../contexts/OrgProvider';
import { useTeamsPhonesDelete } from '../../api/phones/mutations';

export default function TeamSettings() {
  const queryClient = useQueryClient();
  const { teamId } = useParams();
  const [releaseDialog, setReleaseDialog] = useState(false);
  const [phone, setPhone] = useState('');
  const [phoneInput, setPhoneInput] = useState('');
  const [phoneSid, setPhoneSid] = useState('');
  const [areaCode, setAreaCode] = useState();
  const { id: orgId } = useOrg();
  const {
    mutate: editTeam,
    isLoading: isSavingTeam,
    isSuccess: isEditSuccess,
    isError: isEditError,
    reset,
  } = useTeamsEdit();
  const {
    mutate: deleteTeamPhone,
    isLoading: isTeamPhoneLoading,
  } = useTeamsPhonesDelete();
  const debouncedPhoneInput = useDebounce(phoneInput, 200);

  const {
    data: availablePhones,
    refetch: refetchPhones,
    isLoading: isSearchingPhones,
  } = usePhonesSearch(debouncedPhoneInput);
  const {
    mutate: registerPhoneTeams,
    isLoading: isRegisteringPhone,
  } = usePhonesTeamsRegister();
  const { data: team, isFetching: isFetchingTeam, isError } = useTeamsShow(teamId);

  useEffect(() => {
    if (team?.phone) setPhone(team.phone);
    if (team?.phone_number_sid) setPhoneSid(team.phone_number_sid);
  }, [team]);

  useEffect(() => {
    if (areaCode) {
      refetchPhones();
    }
  }, [areaCode]);

  const possibleTimezones = [
    'Pacific/Honolulu',
    'America/Los_Angeles',
    'America/Phoenix',
    'America/Denver',
    'America/Chicago',
    'America/New_York',
    'America/Puerto_Rico',
  ];

  const formatPhone = (phone) => {
    const phoneNumber = parsePhoneNumber(phone);
    if (phoneNumber) {
      return phoneNumber.formatNational();
    }
    return phone;
  };

  const releasePhone = () => {
    deleteTeamPhone(teamId, {
      onSuccess: () => {
        setReleaseDialog(false);
        setPhone('');
        setPhoneSid('');
        queryClient.invalidateQueries(['teams']);
      },
    });
  };

  const releasePhoneModal = () => {
    return (
      <Dialog open={releaseDialog} onClose={() => setReleaseDialog(false)}>
        <DialogTitle>Release phone number?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Releasing the phone number is irreversible. Are you sure you want to
            release the phone number?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setReleaseDialog(false)}>No</Button>
          <LoadingButton
            autoFocus
            loading={isTeamPhoneLoading}
            disabled={isTeamPhoneLoading}
            onClick={releasePhone}
          >
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  };

  const onRegisterPhoneTeams = (query) => {
    registerPhoneTeams(
      {
        phone_number: query.phone_number,
        team_id: teamId,
      },
      {
        onSuccess: (data) => {
          setPhone(data.phone);
          setPhoneSid(data.phone_number_sid);
          queryClient.invalidateQueries(['teams']);
        },
      }
    );
  };

  const defaultValues = {
    ['name']: team?.name,
    ['isEnabled']: team?.is_enabled,
    ['shouldRecord']: team?.should_record,
    ['timeZone']: team?.time_zone,
    ['waitMode']: team?.wait_mode,
    [WAIT_TIME]: team?.wait_timeout ?? null,
  };

  const onTeamFormSubmit = (formData) => {
    editTeam({
      id: teamId,
      name: formData['name'],
      is_enabled: formData['isEnabled'],
      should_record: formData['shouldRecord'],
      time_zone: formData['timeZone'],
      wait_mode: formData['waitMode'],
      wait_timeout: formData[WAIT_TIME],
    });
  };

  const useFormProps = useConditionalUseFormProps(
    {
      defaultValues,
      resolver: createYupResolver(defaultValues),
    },
    !!team
  );

  // Redirect if user switches to another account while on the old account's team
  if (String(orgId) !== String(team?.organization_id) && !!team) {
    return <Redirect to="/settings/teams" />;
  }

  return (
    <SettingsCard>
      {releasePhoneModal()}
      <SettingsCard.Header>Team</SettingsCard.Header>
      <SettingsCard.SubHeader>
        Manage the phone, Active Call Notification sequence, and call forwarding
        settings.
      </SettingsCard.SubHeader>
      <SettingsCard.Main>
        {isFetchingTeam ? (
          <Grid item align="center">
            <ClipLoader size={100} color="#34D1B6" />
          </Grid>
        ) : (
          <Box sx={{ mt: 2 }}>
            <HookForm useFormProps={useFormProps} onSubmit={onTeamFormSubmit}>
              <Grid container direction="column" rowSpacing={2} sx={{ width: 500 }}>
                {isEditSuccess && (
                  <Grid item>
                    <Alert severity="success" onClose={() => reset()}>
                      Phone was saved successfully!
                    </Alert>
                  </Grid>
                )}
                {isEditError && (
                  <Grid item>
                    <Alert severity="error" onClose={() => reset()}>
                      There was an error saving your phone settings.
                    </Alert>
                  </Grid>
                )}
                <Grid>
                  <HookForm.TextField name="name" label="Name" />
                </Grid>
                {phoneSid ? (
                  <Grid item>
                    <Stack direction="row">
                      <TextField
                        label="Phone"
                        size="small"
                        value={formatPhone(phone)}
                        disabled
                      />
                      <Button variant="text" onClick={() => setReleaseDialog(true)}>
                        Release
                      </Button>
                    </Stack>
                  </Grid>
                ) : (
                  <Grid item>
                    <Autocomplete
                      disableClearable
                      sx={{ width: '200px' }}
                      size="small"
                      fullWidth={false}
                      forcePopupIcon={false}
                      options={availablePhones ?? []}
                      getOptionLabel={(option) => option.friendly_name}
                      filterOptions={(x) => {
                        if (typeof x.filter == 'function') {
                          return x;
                        }
                        return [];
                      }}
                      onChange={(e, v) => onRegisterPhoneTeams(v)}
                      onInputChange={(e, v) => setPhoneInput(v)}
                      loading={isSearchingPhones}
                      noOptionsText="No phone numbers found"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Phone"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isSearchingPhones || isRegisteringPhone ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item>
                  <HookForm.Select name="timeZone" label="Time Zone">
                    {possibleTimezones.map((zone) => (
                      <MenuItem value={zone} key={zone}>
                        {zone}
                      </MenuItem>
                    ))}
                  </HookForm.Select>
                </Grid>
                <Grid item>
                  <InputLabel shrink={true}>
                    Wait settings affect the Active Call Notification prompt and how
                    long it waits for your agent's input.
                  </InputLabel>
                  <HookForm.Select
                    name="waitMode"
                    label="Prompt's Wait Mode"
                    sx={{ width: '10rem' }}
                  >
                    <MenuItem value="default" key={0}>
                      No wait
                    </MenuItem>
                    <MenuItem value="speech" key={1}>
                      Wait for speech
                    </MenuItem>
                  </HookForm.Select>
                </Grid>
                <Grid item>
                  <HookForm.TextField
                    label="Max Wait Time"
                    type="number"
                    name={WAIT_TIME}
                  />
                </Grid>
                <Grid item>
                  <HookForm.Switch name="isEnabled" label="Enable for new leads" />
                </Grid>
                <Grid item>
                  <HookForm.Switch
                    name="shouldRecord"
                    label="Enable call recording and transcriptions"
                  />
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    disabled={isSavingTeam}
                    loading={isSavingTeam}
                    startIcon={<SaveIcon />}
                    type="submit"
                  >
                    Save
                  </LoadingButton>
                </Grid>
              </Grid>
            </HookForm>
            <SettingsCard.Divider />
            <AgentsList />
            <SettingsCard.Divider />
            <ForwardingPhone teamId={teamId} />
          </Box>
        )}
      </SettingsCard.Main>
    </SettingsCard>
  );
}

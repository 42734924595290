import { useQuery } from 'react-query';
import { queryKeyFactory } from '../queryClientConfig';
import Events from './Events';

export const eventsQueryKeys = queryKeyFactory('events');

export const useEventsList = (filters, options) => {
  return useQuery(eventsQueryKeys.list(filters), () => Events.list(filters), {
    ...options,
  });
};

import Cookies from 'js-cookie';
import { toJSON } from '../../MUI/services/queryClientConfig';

export default class CallFlowAPI {
  static async share(json) {
    return await fetch(`${process.env.REACT_APP_IVR}/shared_call_flows`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
      },
      body: JSON.stringify(json),
    }).then(toJSON);
  }
  static async create(json) {
    return await fetch(`${process.env.REACT_APP_IVR}/call_flows`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
      },
      body: JSON.stringify(json),
    });
  }

  static async edit({ callFlowId, data }) {
    return await fetch(`${process.env.REACT_APP_IVR}/call_flows/${callFlowId}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(toJSON);
  }

  static async getAll(filters) {
    const params = new URLSearchParams(filters).toString();
    return await fetch(`${process.env.REACT_APP_IVR}/call_flows?${params}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(toJSON);
  }

  static async getById(id) {
    return await fetch(`${process.env.REACT_APP_IVR}/call_flows/${id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(toJSON)
      .catch((e) => e);
  }

  static async delete(call_flow_id) {
    return await fetch(`${process.env.REACT_APP_IVR}/call_flows/${call_flow_id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
}

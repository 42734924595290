import { useState, useEffect } from 'react';
import { Alert, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import HookForm from '../components/HookForm';
import { createYupResolver } from '../components/HookForm/yupValidations';
import { SaveIcon } from '../theme/icons';
import {
  useForwardingPhonesEdit,
  useForwardingPhonesCreate,
} from '../../api/forwardingPhones/mutations';
import { useForwardingPhonesList } from '../../api/forwardingPhones/queries';
import ClipLoader from 'react-spinners/ClipLoader';
import parsePhoneNumber from 'libphonenumber-js';

const formatPhone = (phone) => {
  const phoneNumber = parsePhoneNumber(phone);
  if (phoneNumber) {
    return phoneNumber.formatNational();
  }
  return phone;
};

export default function ForwardingPhone({ teamId }) {
  const [isReady, setIsReady] = useState(false);
  const { isFetching, isError, data } = useForwardingPhonesList(teamId);
  const {
    mutate: editForwardingPhone,
    isLoading: isEditing,
    isSuccess: isEditSuccess,
    isError: isEditError,
  } = useForwardingPhonesEdit();
  const {
    mutate: createForwardingPhone,
    isSuccess: isCreateSuccess,
    isError: isCreateError,
  } = useForwardingPhonesCreate();
  const onSubmit = (formData) => {
    if (data.length > 0) {
      editForwardingPhone({
        id: data[0].id,
        number: formData.number,
        extension: formData.extension,
      });
      return;
    }
    createForwardingPhone({
      team_id: teamId,
      number: formData.number,
      extension: formData.extension,
    });
  };

  useEffect(() => {
    if (data) setIsReady(true);
  }, [data]);

  const buildForm = () => {
    let formProps = {
      defaultValues: {
        ['number']: '',
        ['extension']: '',
      },
      resolver: createYupResolver({
        ['number']: '',
        ['extension']: '',
      }),
    };
    if (data?.length > 0) {
      formProps = {
        defaultValues: {
          ['number']: formatPhone(data[0].number),
          ['extension']: data[0].extension,
        },
        resolver: createYupResolver({
          ['number']: formatPhone(data[0].number),
          ['extension']: data[0].extension,
        }),
      };
    }
    return (
      <HookForm onSubmit={onSubmit} useFormProps={formProps}>
        <Grid item sx={{ mb: 1 }}>
          <HookForm.PhoneNumberField name="number" label="Phone Number" />
        </Grid>
        <Grid item sx={{ mb: 2 }}>
          <HookForm.TextField name="extension" label="Extension" />
        </Grid>
        <LoadingButton
          type="submit"
          loading={isEditing}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          sx={{ maxWidth: 'max-content' }}
        >
          Save
        </LoadingButton>
      </HookForm>
    );
  };

  return (
    <Grid container direction="column" sx={{ width: 500 }}>
      <Typography variant="h6">Call Forwarding</Typography>
      <Typography sx={{ width: 0, minWidth: '100%', mb: 2 }}>
        What number would you like to receive phone calls back to.
      </Typography>
      {(isEditSuccess || isCreateSuccess) && (
        <Alert severity="success">
          Changes to your phone settings were saved successfully.
        </Alert>
      )}
      {isEditError ||
        (isCreateError && (
          <Alert severity="error">
            There was an error saving your changes. Please try again.
          </Alert>
        ))}
      {isError && (
        <Alert severity="error">
          There was an error loading your configuration. Please refresh the page.
        </Alert>
      )}
      {isFetching && (
        <Grid item align="center">
          <ClipLoader size={100} color="#34D1B6" />
        </Grid>
      )}
      {isReady && buildForm()}
    </Grid>
  );
}

import { useQuery } from 'react-query';
import Services from './Services';
import { queryKeyFactory, ONE_HOUR } from '../queryClientConfig';

const servicesQueryKeys = queryKeyFactory('services');

export const useServicesList = (params = [], options) => {
  return useQuery(servicesQueryKeys.list(params), () => Services.list(params), {
    staleTime: ONE_HOUR,
    ...options,
  });
};

import { useQuery } from 'react-query';
import { queryKeyFactory } from '../queryClientConfig';
import UniversalSearch from './UniversalSearch';
import Leads from '../leads/Leads';

export const universalSearchQueryKeys = queryKeyFactory('universalSearch');
export const leadsQueryKeys = queryKeyFactory('leads');

export const useUniversalSearchList = (filters, options) => {
  if (options.pathName === '/calls') {
    return useQuery(
      universalSearchQueryKeys.list(filters),
      () => UniversalSearch.list(filters),
      {
        staleTime: 0,
        ...options,
      }
    );
  } else {
    return useQuery(leadsQueryKeys.list(filters), () => Leads.list(filters), {
      staleTime: 0,
      ...options,
    });
  }
};

import { useMutation, useQueryClient } from 'react-query';
import { downloadsQueryKeys } from '../downloads/queries';
import Downloads from './Downloads';

export const useCsvExportsCreate = () => {
  const queryClient = useQueryClient();

  return useMutation(Downloads.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(downloadsQueryKeys.lists());
    },
    onError: (error) => {
      showErrorToast('Custom error occurred: ' + error.message);
      // Handle the error as needed
    },
  });
};

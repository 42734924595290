import { useQuery } from 'react-query';
import ReportsByUsers from './ReportsByUsers';
import { queryKeyFactory } from '../queryClientConfig';

export const reportsByUsersQueryKeys = queryKeyFactory('reports');

export const useReportsByUsersList = (
  params = { canUse: false, filters: [] },
  setReportValues
) => {
  return useQuery(
    reportsByUsersQueryKeys.list([...params?.filters]),
    () => {
      if (params.canUse) return ReportsByUsers.list([...params?.filters]);
    },
    {
      onSuccess: (data) => {
        setReportValues(data);
      },
    }
  );
};

import { useState } from 'react';
import { Button } from '@mui/material';

import { PlusIcon } from '../../theme/icons';
import LeadCreateModal from '../../components/LeadCreateModal';
import { fireDialog } from '../Dialog';

const AddLeadButtonWrapper = (props) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        variant="outlined"
        size="medium"
        onClick={() => {
          fireDialog((promisParams) => LeadCreateModal({ ...promisParams }));
        }}
        startIcon={<PlusIcon />}
      >
        Add Lead
      </Button>
    </>
  );
};

export default AddLeadButtonWrapper;

import { useMutation, useQueryClient } from 'react-query';
import { leadLocksQueryKeys } from './queries';
import LeadLocks from './LeadLocks';
import { useOrg } from '../../../contexts/OrgProvider';

export const useLeadLocksCreate = (options) => {
  const { id } = useOrg();
  const queryClient = useQueryClient();
  return useMutation(
    (params) => {
      LeadLocks.create({ organization_id: id, ...params });
    },
    {
      ...options,
    }
  );
};

export const useLeadLocksDelete = (options) => {
  const queryClient = useQueryClient();
  return useMutation(LeadLocks.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(leadLocksQueryKeys.lists());
    },
    ...options,
  });
};

import React, { Fragment } from 'react';
import 'animate.css';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import PipelineChart from './PipelineChart';

const ChartCard = ({ leads }) => {
  return (
    <Fragment>
      <Card className={`mt-5 card-sigma`}>
        <Card.Header className={`py-4`}>
          <Container fluid>
            <Row>
              <Col xs={9} className={`text-truncate font-weight-medium`}>
                {`Pipeline`}
              </Col>
            </Row>
          </Container>
        </Card.Header>
        <Card.Body className={`p-0`}>
          <PipelineChart leads={leads} />
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default ChartCard;

import Cookies from 'js-cookie';
import { toJSON, toParamString } from '../queryClientConfig';

export default class Reports {
  static async listMessaging(filters = []) {
    return await fetch(
      `${process.env.REACT_APP_MESSAGING_API_BASE}/reports?${toParamString(
        filters
      )}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async listSales(filters = []) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/reports?${toParamString(filters)}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }
}

import { useState, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import FilterButtonWrapper from '../components/Filters/FilterButtonWrapper';
import FilterBar from '../components/Reports/Conversions/FilterBar';
import { initialReportsFilterMapValues } from '../components/Reports/Conversions/FilterBar';
import ReportCard from '../components/Reports/ReportCard';
import { useOrg } from '../../contexts/OrgProvider';
import { parseFilterParams } from '../components/Filters/utils';

const ReportsConversion = () => {
  const reportGroups = {
    'apiBase-Leads': [
      { type: 'lead_generated', name: 'Total Leads Generated' },
      { type: 'lead_won', name: 'Total Marked Won' },
      { type: 'lead_conversion_rate', name: 'Total Conversion Rate' },
      {
        type: 'lead_conversion_rate_by_source',
        name: 'Total Conversion Rate by Source',
      },
      { type: 'lead_conversion_by_source', name: 'Total Conversion by Source' },
      { type: 'lead_by_source', name: 'Leads Created by Source' },
      {
        type: 'lead_conversion_rate_by_team',
        name: 'Total Conversion Rate by Team',
      },
      { type: 'lead_worth', name: 'Leads are Worth' },
      { type: 'win_worth', name: 'Wins are Worth' },
      { type: 'average_conversion_time', name: 'Average Time to Convert' },
      { type: 'lead_lost_by_reason', name: 'Lost Reasons' },
      {
        type: 'lead_conversion_with_texts',
        name: '% of Conversions with Text Message Received',
      },
      {
        type: 'lead_conversion_with_emails',
        name: '% of Conversions with Email Received',
      },
      {
        type: 'lead_conversion_without_texts_emails',
        name: '% of Conversions without Text nor Email Received',
      },
    ],
  };

  const filterBarRef = useRef();
  const { id: orgId, isMasterAccount } = useOrg();

  const [showFilters, setFilterToggle] = useState(window.innerWidth > 768);
  const [reportsFilterMap, setReportsFilterMap] = useState(
    initialReportsFilterMapValues
  );

  const isDateRangeSelected = () => {
    if (!reportsFilterMap['date_range']?.length) return false;

    const dateFilters = reportsFilterMap['date_range'][0];

    return Boolean(
      (dateFilters.filterValue === '16' && dateFilters.startDate) ||
        (dateFilters.filterValue === '16' && dateFilters.endDate) ||
        dateFilters.filterValue !== '16'
    );
  };

  const createParams = (apiGroup, type) => {
    const [_, filters] = parseFilterParams(reportsFilterMap);
    filters.push(['type', type]);
    const params = {
      apiGroup,
      canUse: isDateRangeSelected(),
      filters,
    };
    if (!isMasterAccount) {
      params.filters.push(['organization_id', orgId]);
    }
    return params;
  };

  const currentPath = window.location.pathname;
  const shouldShowFilters = currentPath === '/reports/conversion';

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          <Typography fontSize={18} fontWeight={700}>
            Conversion Reports
          </Typography>
          <Box display="flex" alignItems="center">
            <Box sx={{ marginLeft: '10px' }}>
              {shouldShowFilters && (
                <FilterButtonWrapper
                  showFilters={showFilters}
                  setFilterToggle={setFilterToggle}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" height="0%" width="100%" flexBasis="100%" gap={2}>
        {showFilters ? (
          <FilterBar ref={filterBarRef} setReportsFilterMap={setReportsFilterMap} />
        ) : (
          <></>
        )}
        <Box>
          {Object.entries(reportGroups).map(([apiGroup, reportTypes]) => (
            <Box
              key={apiGroup}
              sx={{
                pl: 3,
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'start',
                gap: 3,
              }}
            >
              {reportTypes.map((report) => (
                <ReportCard
                  key={report.type}
                  report={report}
                  params={createParams(apiGroup, report.type)}
                />
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ReportsConversion;

import { useAgentsList } from '../services/agents/queries';
import { useAgentsEdit } from '../services/agents/mutations';
import DraggableListViewItem from '../components/DragAndDrop/DraggableListViewItem';
import DroppableListView from '../components/DragAndDrop/DroppableListView';
import { useOrderLocalData } from '../components/DragAndDrop/hooks';
import { DragDropContext } from 'react-beautiful-dnd';
import HookForm from '../components/HookForm';
import ListView from '../components/ListView';
import { useEffect, useState } from 'react';
import { PlusIcon, SaveIcon } from '../theme/icons';
import LoadingButton from '@mui/lab/LoadingButton';
import { fireDialog } from '../components/Dialog';
import { FIELD_WIDTH } from '../theme/fixedValues';
import parsePhoneNumber from 'libphonenumber-js';
import { Prompt, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

import {
  FIRST_NAME,
  LAST_NAME,
  PHONE_NUMBER,
  TIMEOUT_SECONDS,
  createYupResolver,
} from '../components/HookForm/yupValidations';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  InputLabel,
} from '@mui/material';
import { useAgentsCreate, useAgentsDelete } from '../services/agents/mutations';
const NAME_WIDTH = '150px';
const ORDER_WIDTH = '80px';
const PHONE_WIDTH = '140px';

const AgentsList = () => {
  const { teamId } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { data: agentsList, isFetching, isSuccess: isListSuccess } = useAgentsList({
    team_id: teamId,
  });
  const [isAgentsEditError, setIsAgentsEditError] = useState(false);
  const { mutate: editAgent, isLoading: isLoadingEditAgent } = useAgentsEdit({
    onError: () => setIsAgentsEditError(true),
  });

  const {
    localCopy: agentsOrderCopy,
    handleDragEnd,
    setCopy,
    isOrderDirty,
  } = useOrderLocalData();

  useEffect(() => {
    if (agentsList) setCopy(agentsList);
  }, [agentsList]);

  const formatPhoneValue = (phone) => {
    const phoneNumber = parsePhoneNumber(phone);
    if (phoneNumber) {
      return phoneNumber.formatNational();
    }
    return phone;
  };

  const handleEditAgentClick = (agent) => {
    history.push(`${url}/agents/${agent.id}`);
  };

  const handleDeleteAgentClick = (id) => {
    fireDialog((promiseProps) => DeleteAgentDialog({ id, ...promiseProps }));
  };

  const handleCreateAgentClick = () => {
    fireDialog((promiseProps) =>
      CreateAgentDialog({ teamId, agentsList, ...promiseProps })
    );
  };

  const handleSaveOrderClick = () => {
    agentsOrderCopy?.forEach((agent) => editAgent(agent));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h6">Active Call Notification Phones</Typography>
      <Typography sx={{ width: 0, minWidth: '100%' }}>
        Manage phone numbers to be called for Active Call Notifications
      </Typography>
      <Prompt
        message={(location) => {
          if (location.pathname.includes('logout')) return true;
          if (isOrderDirty)
            return 'You have unsaved changes, are you sure you would like to leave this page?';
        }}
      />
      {isAgentsEditError && (
        <Alert severity="error">
          There was a problem editing your Active Call Notification phones. Try
          again.
        </Alert>
      )}
      {isFetching && agentsList == undefined && (
        <Grid item align="center">
          <ClipLoader size={100} color="#34D1B6" />
        </Grid>
      )}
      {isListSuccess && agentsList.length == 0 && (
        <Alert severity="info" sx={{ mt: 1, mb: 2 }}>
          Add your first phone to start receiving Active Call Notifications
        </Alert>
      )}
      {isListSuccess && agentsList.length > 0 && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <DroppableListView droppableId={'agents'} sx={{ mb: 2 }}>
            <ListView.Item>
              <ListView.HeaderText colWidth={ORDER_WIDTH}>Order</ListView.HeaderText>
              <ListView.HeaderText colWidth={NAME_WIDTH}>Name</ListView.HeaderText>
              <ListView.HeaderText colWidth={PHONE_WIDTH}>Phone</ListView.HeaderText>
            </ListView.Item>
            {agentsOrderCopy?.map((agent, i) => (
              <DraggableListViewItem
                key={agent.id}
                draggableId={agent.first_name || ''}
                index={i}
              >
                <ListView.Text colWidth="40px" textAlign="center" />
                <ListView.Text
                  colWidth={NAME_WIDTH}
                >{`${agent.first_name} ${agent.last_name}`}</ListView.Text>
                <ListView.Text colWidth={PHONE_WIDTH}>{`${formatPhoneValue(
                  agent.phone
                )}`}</ListView.Text>
                <ListView.EditButton
                  onClick={() => handleEditAgentClick(agent)}
                  disabled={isOrderDirty}
                />
                <ListView.DeleteButton
                  onClick={() => handleDeleteAgentClick(agent.id)}
                  disabled={isOrderDirty}
                />
              </DraggableListViewItem>
            ))}
          </DroppableListView>
        </DragDropContext>
      )}
      <Button
        startIcon={<PlusIcon />}
        onClick={() => handleCreateAgentClick()}
        disabled={isOrderDirty}
      >
        Add New
      </Button>
      <LoadingButton
        sx={{ ml: 2 }}
        startIcon={<SaveIcon />}
        onClick={handleSaveOrderClick}
        disabled={!isOrderDirty}
        loading={isLoadingEditAgent}
      >
        Save
      </LoadingButton>
    </Box>
  );
};

export default AgentsList;

// delete agent --------------------------------------------------------
const DeleteAgentDialog = ({ isOpen, onResolve, onReject, id }) => {
  const { mutate: deleteAgent, isError, isLoading } = useAgentsDelete();

  const handleDeleteAgent = () => {
    deleteAgent(id, {
      onSuccess: onResolve,
    });
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Delete Active Call Notification Phone</DialogTitle>
      <DialogContent>
        {isError && (
          <Alert severity="error">
            There was a problem deleting your Active Call Notification phone. Try
            again.
          </Alert>
        )}
        Are you sure you would like to delete this Active Call Notification phone?
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          disabled={isLoading}
          onClick={handleDeleteAgent}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
// create agent --------------------------------------------------------
const CreateAgentDialog = ({ isOpen, onResolve, onReject, teamId, agentsList }) => {
  const { mutate: createAgent, isLoading, isError } = useAgentsCreate();
  const EXTENSTION = 'extension';

  const defaultValues = {
    [FIRST_NAME]: '',
    [LAST_NAME]: '',
    [PHONE_NUMBER]: '',
    [EXTENSTION]: '',
    [TIMEOUT_SECONDS]: null,
  };

  const onAgentFormSubmit = (formData) => {
    const maxOrder = agentsList.reduce((acc, current) => {
      if (current.order > acc) return current.order;
      return acc;
    }, 0);

    createAgent(
      {
        team_id: teamId,
        order: maxOrder + 1,
        first_name: formData[FIRST_NAME],
        last_name: formData[LAST_NAME],
        phone: formData[PHONE_NUMBER],
        extension: formData[EXTENSTION],
        timeout_seconds: formData[TIMEOUT_SECONDS],
      },
      {
        onSuccess: onResolve,
      }
    );
  };

  const useFormProps = {
    defaultValues,
    resolver: createYupResolver(defaultValues),
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Add Active Call Notification Phone</DialogTitle>
      <HookForm useFormProps={useFormProps} onSubmit={onAgentFormSubmit}>
        <DialogContent>
          {isError && (
            <Alert severity="error">
              There was a problem creating your Active Call Notification phone. Try
              again.
            </Alert>
          )}
          <Box
            display="grid"
            direction="column"
            rowGap={2}
            sx={{ width: FIELD_WIDTH, mb: 2 }}
          >
            <HookForm.TextField name={FIRST_NAME} label="First Name" />
            <HookForm.TextField name={LAST_NAME} label="Last Name" />
            <HookForm.PhoneNumberField name={PHONE_NUMBER} label="Phone Number" />
            <HookForm.TextField
              label="Max Dial Time"
              type="number"
              name={TIMEOUT_SECONDS}
              helperText="How long the line rings before hanging up."
            />
            <HookForm.TextField name={EXTENSTION} label="Ext" sx={{ width: 90 }} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton loading={isLoading} disabled={isLoading} type="submit">
            Confirm
          </LoadingButton>
        </DialogActions>
      </HookForm>
    </Dialog>
  );
};

import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router';
import ClipLoader from 'react-spinners/ClipLoader';
import MessageTemplate from '../../clients/MessageTemplate';
import TemplateErrorBanner from './TemplateErrorBanner';
import TemplateForm from './TemplateForm';
import { useHistory } from 'react-router';
import { useMessageTemplatesEdit } from '../../MUI/services/messageTemplates/mutations';

const EditTemplateView = () => {
  const history = useHistory();
  const { templateId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [type, setType] = useState(null);
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [subject, setSubject] = useState(null);
  const [content, setContent] = useState(null);
  const [errorDisplay, setErrorDisplay] = useState(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const {
    mutate: editMessageTemplate,
    isSuccess: didSucceedEditingMessageTemplate,
    isError: didFailEditingMessageTemplate,
    isLoading: isEditingMessageTemplate,
    data: editedMessageTemplateData,
  } = useMessageTemplatesEdit();

  const submitClick = (e, { name, subject, content }) => {
    e.preventDefault();
    if (type === 'Email' && subject.trim() === '') {
      if (
        !confirm(
          'You do not have a subject for this email template. Continue anyway?'
        )
      ) {
        return;
      }
    }
    setIsSubmitDisabled(true);

    editMessageTemplate({
      id,
      name,
      content,
      subject,
    });

    if (!isEditingMessageTemplate) {
      setIsSubmitDisabled(false);
      history.push('/templates');
    }
  };

  useEffect(() => {
    MessageTemplate.show(templateId).then((data) => {
      setName(data.name);
      setType(data.task_action.name);
      setId(data.id);
      setContent(data.content);
      setSubject(data.subject);
      setIsLoading(false);
    });
  }, []);

  return isLoading ? (
    <Row>
      <Col className="text-center p-5">
        <ClipLoader size={100} color="#34D1B6" />
      </Col>
    </Row>
  ) : (
    <Container fluid>
      <h3>{`Edit ${type} ${name}`}</h3>
      <TemplateErrorBanner
        errorDisplay={errorDisplay}
        setErrorDisplay={setErrorDisplay}
      />
      <TemplateForm
        template={{ name, subject, content, id }}
        type={type}
        submitClick={submitClick}
        isSubmitDisabled={isSubmitDisabled}
      />
    </Container>
  );
};

export default EditTemplateView;

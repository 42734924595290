import { create } from './PromiseDialog';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

// create and fire given dialog
export const fireDialog = (dialog) => create(dialog)();

// confirm dialog ------------------------------------------
export const ConfirmDialog = ({
  isOpen,
  onResolve,
  onReject,
  title,
  subTitle,
  message,
}) => {
  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>{subTitle ? `${title} / ${subTitle}` : title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <Button onClick={onResolve}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

export const fireConfirmDialog = create(ConfirmDialog);

import React, { Fragment } from 'react';
import moment from 'moment';
import { useDateRangeContext } from '../common/DateRangeProvider';
import useMetricsQuery from '../../hooks/useMetricsQuery';
import { Line } from 'react-chartjs-2';

const PipelineChart = () => {
  const { startDate, endDate } = useDateRangeContext();
  const [totalValue, isLoadingTotalValue] = useMetricsQuery({
    metricKey: 'total_value',
    startDate,
    endDate,
  });
  const [wonValue, isLoadingWonValue] = useMetricsQuery({
    metricKey: 'won_value',
    startDate,
    endDate,
  });

  const data = {
    labels: totalValue?.map(({ calculated_at }) =>
      moment(calculated_at).format('MMMM DD')
    ),

    datasets: [
      {
        label: 'Pipeline Value',
        data: totalValue?.map(({ value }) => value / 100),
        fill: false,
        backgroundColor: '#BAADF3',
        borderColor: '#BAADF3',
        yAxisID: 'y-axis-2',
      },
      {
        label: 'LeadSigma Wins',
        data: wonValue?.map(({ value }) => value / 100),
        fill: false,
        backgroundColor: '#34D1B6',
        borderColor: '#34D1B6',
        yAxisID: 'y-axis-1',
      },
    ],
  };

  const options = {
    interaction: {
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'right',
          id: 'y-axis-1',
          gridLines: {
            drawOnArea: false,
          },
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
              }).format(value);
            },
          },
        },
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-2',
          gridLines: {
            drawOnArea: false,
          },
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
              }).format(value);
            },
          },
        },
      ],
    },
  };

  return (
    <Fragment>
      <Line data={data} options={options} />
    </Fragment>
  );
};

export default PipelineChart;

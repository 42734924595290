import { useQuery } from 'react-query';
import { queryKeyFactory } from '../../MUI/services/queryClientConfig';
import OverrideArguments from './OverrideArguments';

export const aiOverideArgumentsQueryKeys = queryKeyFactory('override-arguments');

export const useOverrideArgumentsList = (params, options) => {
  return useQuery(
    aiOverideArgumentsQueryKeys.list(),
    () => OverrideArguments.list(params),
    {
      staleTime: 0,
      ...options,
    }
  );
};

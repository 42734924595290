import { useQuery } from 'react-query';
import { queryKeyFactory } from '../../MUI/services/queryClientConfig';
import Slots from './Slots';

export const slotesQueryKeys = queryKeyFactory('slots');
export const useSlotsList = (params, options) => {
  return useQuery(slotesQueryKeys.list(params), () => Slots.list(params), {
    staleTime: 0,
    ...options,
  });
};

import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import AIModules from './AIModules';
import { aimodulesQueryKeys } from './queries';
import { aimodulesFunctionDefinitionsQueryKeys } from '../aiModulesFunctionDefinitions/queries';

export const useAiModuleCreate = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => AIModules.create(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(aimodulesQueryKeys.all);
      queryClient.invalidateQueries(aimodulesFunctionDefinitionsQueryKeys.all);
    },
  });
};

export const useAiModuleEdit = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => AIModules.edit({ ...params }), {
    onSuccess: () => {
      queryClient.invalidateQueries(aimodulesQueryKeys.all);
      queryClient.invalidateQueries(aimodulesFunctionDefinitionsQueryKeys.all);
    },
  });
};

export const useAiModuleDelete = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => AIModules.delete(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(aimodulesQueryKeys.all);
      queryClient.invalidateQueries(aimodulesFunctionDefinitionsQueryKeys.all);
    },
  });
};

import { useQuery } from 'react-query';
import { ONE_HOUR, queryKeyFactory } from '../queryClientConfig';
import AdsAccounts from './AdsAccount';
export const adsAccountsQueryKeys = {
  ...queryKeyFactory('ads_accounts'),
};
export const googleAdsQueryKeys = {
  ...queryKeyFactory('google_ads'),
};
export const googleActionsQueryKeys = {
  ...queryKeyFactory('google_actions'),
};

export const useAdsAccountsList = (filters, options) => {
  const mergedFilters = Object.assign({}, ...filters);

  return useQuery(
    adsAccountsQueryKeys.list(mergedFilters),
    () => AdsAccounts.list(mergedFilters),
    {
      staleTime: ONE_HOUR,
      ...options,
    }
  );
};

export const useGoogleAdsList = (filters, options) => {
  const mergedFilters = Object.assign({}, ...filters);

  return useQuery(
    googleAdsQueryKeys.list(mergedFilters),
    () => AdsAccounts.listGoogle(mergedFilters),
    {
      staleTime: 0,
      ...options,
    }
  );
};

export const useGoogleActionsList = (filters, options) => {
  const mergedFilters = Object.assign({}, ...filters);

  return useQuery(
    googleActionsQueryKeys.list(mergedFilters),
    () => AdsAccounts.listActions(mergedFilters),
    {
      staleTime: ONE_HOUR,
      ...options,
    }
  );
};

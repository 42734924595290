import Cookies from 'js-cookie';

export default class UserPushEndpoints {
  static async create(endpoint) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/user_push_endpoints`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(endpoint),
      }
    );
    return response.json();
  }
}

import { useState } from 'react';
import {
  Box,
  FormControl,
  Typography,
  TextField,
  CircularProgress,
  Button,
  Autocomplete,
} from '@mui/material';

import { usePhonesSearch } from '../../../api/phones/queries';
import { useDebounce } from '../../services/hooks/useDebounce';
import { usePhonesIvrsRegister } from '../../../api/phones/mutations';

export default function NumberSetUpStep({
  handleCollect,
  collectedData,
  handleForwardNavigate,
  handleBackNavigate,
}) {
  const [phoneInput, setPhoneInput] = useState(collectedData?.numberSetup);
  const [phoneName, setPhoneName] = useState(collectedData?.numberSetupName);
  const { mutate: registerPhoneIvrs, isLoading } = usePhonesIvrsRegister();

  const debouncedPhoneInput = useDebounce(phoneInput, 200);

  const { data: availablePhones, isLoading: isSearchingPhones } = usePhonesSearch(
    debouncedPhoneInput
  );

  const handleChangePhoneName = (e) => {
    setPhoneName(e.target.value);
    handleCollect('numberSetupName', e.target.value);
  };

  const handleChangePhone = (value) => {
    setPhoneInput(value.replace(/\D/g, ''));
  };

  const register = (phone) => {
    return registerPhoneIvrs(
      {
        phone_number: phone.phone_number,
        team_id: collectedData.teamId,
        call_flow_id: collectedData.callFlowId,
      },
      {
        onSuccess: (data) => {
          handleCollect('numberSetup', phone.friendly_name);
          handleCollect('numberSetupId', data.id);
        },
      }
    );
  };

  return (
    <Box mt={3} mb={5}>
      <h4>Number Set Up</h4>
      <hr />
      <FormControl>
        <Typography>Number is a specific area code</Typography>
        <Box mt={1} display="flex" flexDirection="column">
          <TextField
            style={{ width: '300px', margin: '0 0 15px 0' }}
            value={phoneName}
            onChange={handleChangePhoneName}
            label={'Phone Name'}
            variant="outlined"
          />
          <Autocomplete
            disableClearable
            sx={{ width: '300px' }}
            size="small"
            fullWidth={false}
            forcePopupIcon={false}
            options={availablePhones ?? []}
            getOptionLabel={(option) => {
              return option.friendly_name;
            }}
            filterOptions={(x) => {
              if (typeof x.filter == 'function') {
                return x;
              }
              return [];
            }}
            defaultValue={{ friendly_name: collectedData?.numberSetup }}
            onChange={(e, v) => register(v)}
            onInputChange={(_, e) => handleChangePhone(e)}
            loading={isSearchingPhones}
            noOptionsText="No phone numbers found"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Phone"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isSearchingPhones || isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>
      </FormControl>
      <Box display="flex" alignItems="center" justifyContent="space-between" mt={5}>
        <Button onClick={() => handleBackNavigate()}>Back</Button>
        <Button
          disabled={!(collectedData?.numberSetup && collectedData.numberSetupName)}
          onClick={() => handleForwardNavigate('total', 'numberSetup')}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

import { useHistory } from 'react-router';
import { IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DownloadIcon } from '../theme/icons';
import { toastOptions } from '../../util/AppUtils';

const CsvDownloadButton = ({ onClick }) => {
  const history = useHistory();

  const notify = () => {
    toast.info('Your download is queued. Click here to go to Downloads', {
      onClick: () => history.push('settings/downloads'),
      toastOptions,
    });
  };

  const handleButtonClick = () => {
    onClick();
    notify();
  };

  return (
    <>
      <IconButton
        onClick={handleButtonClick}
        sx={{
          color: '#616161',
          marginRight: '3rem',
        }}
      >
        <DownloadIcon />
      </IconButton>
    </>
  );
};

export default CsvDownloadButton;

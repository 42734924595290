import React from 'react';
import { createContext, useContext } from 'react';

const DateRangeContext = createContext(null);

export const useDateRangeContext = () => {
  const context = useContext(DateRangeContext);
  if (!context)
    throw new Error(
      'DateRangeContext is invalid. Consumers must be called inside of a provider and provide non-null initial value'
    );
  return context;
};

export const DateRangeProvider = ({ children, selectedDateRange }) => {
  return (
    <DateRangeContext.Provider value={selectedDateRange}>
      {children}
    </DateRangeContext.Provider>
  );
};

import { useQuery } from 'react-query';
import { useOrg } from '../../../contexts/OrgProvider';
import { queryKeyFactory, ONE_HOUR } from '../queryClientConfig';
import UserOrganizationConnections from './UserOrganizationConnections';

export const userOrganizationConnectionsQueryKeys = {
  ...queryKeyFactory('user-org-connections'),
};

export const useUserOrganizationConnectionsList = (params = [], options) => {
  const { id, isMasterAccount } = useOrg();
  return useQuery(
    userOrganizationConnectionsQueryKeys.list([...params]),
    () => {
      const filters = [...params];
      if (!isMasterAccount) {
        filters.push(['organization_id[]', id]);
      }
      return UserOrganizationConnections.list(filters);
    },
    {
      staleTime: ONE_HOUR,
      ...options,
    }
  );
};

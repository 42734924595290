import { Box, Grid, Typography, CircularProgress } from '@mui/material';
import { useRef, useEffect, useState } from 'react';
import { dateToAbbreviatedDateTimeString } from '../../services/dateTime';
import { eventTypeNameMap } from '../../services/events/eventsUtils';
import { useEventsList } from '../../services/events/queries';
import { useLeadsShow } from '../../services/leads/queries';
import { messageTemplateContentToPlainText } from '../../services/messageTemplates/messageTemplatesUtils';
import { formatName, formatPhoneNumber } from '../../services/textFormatting';
import { useUsersList } from '../../services/users/queries';
import {
  AcnDeclinedIcon,
  AcnIcon,
  AcnUnansweredIcon,
  EmailIcon,
  EmailUnsubscribedIcon,
  ExclamationIcon,
  LeadCreatedIcon,
  LeadDuplicatedIcon,
  MoneyIcon,
  NoteIcon,
  PhoneIcon,
  TextIcon,
  XIcon,
} from '../../theme/icons';
import AuthenticatedFileViewer from './AuthenticatedFileViewer';
import AuthenticatedPlayer from './AuthenticedPlayer';
import { EventCard } from './EventCard';
import ImageEnlarger from './ImageEnlarger';

const LeadEventHistory = ({ setCommunicationsCount, lead }) => {
  const scrollRef = useRef(null);
  const { data: usersList } = useUsersList();
  const { data: eventsList } = useEventsList(
    [
      ['lead_id', lead?.id],
      ['page_size', 10000],
    ],
    {
      enabled: !!lead?.id,
    }
  );
  const [leadEventHistory, setLeadEventHistory] = useState([]);

  const scrollToBottom = () => {
    scrollRef.current.scrollIntoView({ behavior: 'auto' });
  };

  const userFriendlyDirection = (direction) =>
    direction === 'out' ? 'Outbound' : 'Inbound';

  // unknown connected busy voicemail live_voicemail no_answer wrong_number
  const userFriendlyCallStatusDisplay = (callStatus) => {
    if (!callStatus || typeof callStatus !== 'string') {
      return 'Unknown';
    } else if (callStatus == 'live_voicemail') {
      return 'Left live voicemail';
    } else if (callStatus == 'no_answer') {
      return 'No answer';
    } else if (callStatus == 'wrong_number') {
      return 'Wrong number';
    }
    return callStatus.charAt(0).toUpperCase() + callStatus.slice(1);
  };

  const getEventUsername = (eventUserId, sender_type = '') => {
    if (!eventUserId || sender_type == 'automation') return 'LeadSigma';
    const user = usersList?.find(({ id }) => eventUserId === id);
    if (user) return formatName(user?.first_name, user?.last_name);
    return '...';
  };

  useEffect(() => {
    if (usersList && lead && eventsList) {
      const eventsMap = {};
      const communicationsMap = {};

      // ANCHOR lead communications
      lead.calls.forEach((communication) => {
        const metaTexts = [
          `From: ${formatPhoneNumber(communication.from)}`,
          `User: ${getEventUsername(communication.caller_user_id)}`,
          `Outcome: ${userFriendlyCallStatusDisplay(communication.status)}`,
        ];
        if (communication.call_type === 'active_call_notification') {
          metaTexts.push(`To: ${formatPhoneNumber(communication.to)}`);
        }
        communicationsMap[communication.id] = {
          eventCardProps: {
            title: `${userFriendlyDirection(communication.direction)} Call`,
            icon: <PhoneIcon />,
            direction: communication.direction,
            timestamp: dateToAbbreviatedDateTimeString(communication.created_at),
            metaTexts,
            collapseContent: (
              <CallEventContent callRecording={communication.call_recording} />
            ),
          },
          event_date: communication.created_at,
        };
      });
      lead.texts.forEach((communication) => {
        let metaTexts = [
          `From: ${formatPhoneNumber(communication.from)}`,
          `User: ${getEventUsername(
            communication.sender_user_id,
            communication.sender_type
          )}`,
        ];

        if (communication.error) {
          const phone = lead.phones[0];

          if (!phone.is_valid) {
            metaTexts.push('Error: Invalid phone number');
          } else if (phone.phone_type === 'landline') {
            metaTexts.push('Error: Landline phone number');
          } else {
            metaTexts.push('Error: Undelivered');
          }
        }

        communicationsMap[communication.id] = {
          eventCardProps: {
            title: `${userFriendlyDirection(communication.direction)} Text`,
            icon: <TextIcon />,
            direction: communication.direction,
            timestamp: dateToAbbreviatedDateTimeString(communication.created_at),
            metaTexts,
            collapseContent: (
              <TextEventContent
                message={communication.message}
                attachments={communication.attachments}
              />
            ),
          },
          event_date: communication.created_at,
        };
      });
      lead.emails.forEach((communication) => {
        let metaTexts = [
          `Subject: ${communication.subject}`,
          `From: ${formatPhoneNumber(communication.from)}`,
          `User: ${getEventUsername(communication.sender_user_id)}`,
        ];
        if (communication.last_opened) {
          metaTexts.push(
            `Last Opened: ${dateToAbbreviatedDateTimeString(
              communication.last_opened
            )}`
          );
        }
        if (communication.opened_count) {
          metaTexts.push(`Opened: ${communication.opened_count}`);
        }
        if (communication.error) {
          metaTexts.push(`Error: ${communication.error}`);
        }
        communicationsMap[communication.id] = {
          eventCardProps: {
            title: `${userFriendlyDirection(communication.direction)} Email`,
            icon: <EmailIcon />,
            direction: communication.direction,
            timestamp: dateToAbbreviatedDateTimeString(communication.created_at),
            metaTexts: metaTexts,
            collapseContent: messageTemplateContentToPlainText(
              communication.message
            ),
          },
          event_date: communication.created_at,
        };
      });
      lead.notes.forEach((note) => {
        communicationsMap[note.id] = {
          eventCardProps: {
            title: `Note Created`,
            icon: <NoteIcon />,
            timestamp: dateToAbbreviatedDateTimeString(note.created_at),
            metaTexts: [`User: ${getEventUsername(note.user_id)}`],
            collapseContent: note.value,
          },
          event_date: note.created_at,
        };
      });
      // ANCHOR lead events
      eventsList.forEach((event) => {
        const { event_type } = { ...event };
        if (event_type === 'lead_created') {
          eventsMap[event.id] = {
            eventCardProps: {
              title: `Lead Created`,
              icon: <LeadCreatedIcon />,
              timestamp: dateToAbbreviatedDateTimeString(event.created_at),
              metaTexts: [`User: ${getEventUsername(event.actor_user_id)}`],
            },
            event_date: event.created_at,
          };
        }
        if (event_type === 'lead_duplicated') {
          eventsMap[event.id] = {
            eventCardProps: {
              title: `Duplicate lead detected`,
              icon: <LeadDuplicatedIcon />,
              timestamp: dateToAbbreviatedDateTimeString(event.created_at),
              metaTexts: [
                `
                Duplicate lead: ${event.subject.duplicate_lead?.first_name} ${event.subject.duplicate_lead?.last_name}
                `,
              ],
            },
            event_date: event.created_at,
          };
        }
        if (event_type === 'task_cancelled') {
          eventsMap[event.id] = {
            eventCardProps: {
              title: eventTypeNameMap['task_cancelled'],
              icon: <XIcon />,
              timestamp: dateToAbbreviatedDateTimeString(event.created_at),
              metaTexts: [`User: ${getEventUsername(event.actor_user_id)}`],
            },
            event_date: event.created_at,
          };
        }
        if (event_type === 'task_closed_no_communication') {
          eventsMap[event.id] = {
            eventCardProps: {
              title: eventTypeNameMap['task_closed_no_communication'],
              icon: <ExclamationIcon color="secondary" />,
              timestamp: dateToAbbreviatedDateTimeString(event.created_at),
              metaTexts: [`User: ${getEventUsername(event.actor_user_id)}`],
            },
            event_date: event.created_at,
          };
        }
        if (event_type === 'call_slingshot_initiated') {
          eventsMap[event.id] = {
            eventCardProps: {
              title: eventTypeNameMap['call_slingshot_initiated'],
              icon: <AcnIcon />,
              timestamp: dateToAbbreviatedDateTimeString(event.created_at),
              metaTexts: [`User: ${getEventUsername(event.actor_user_id)}`],
            },
            event_date: event.created_at,
          };
        }
        if (event_type === 'call_slingshot_accepted') {
          eventsMap[event.id] = {
            eventCardProps: {
              title: eventTypeNameMap['call_slingshot_accepted'],
              icon: <AcnIcon />,
              timestamp: dateToAbbreviatedDateTimeString(event.created_at),
              metaTexts: [
                `Name: ${formatName(
                  event.actor.agent?.first_name,
                  event.actor.agent?.last_name
                )}`,
                `Phone: ${formatPhoneNumber(event.actor.agent?.phone)}`,
              ],
            },
            event_date: event.created_at,
          };
        }
        if (event_type === 'call_slingshot_declined') {
          eventsMap[event.id] = {
            eventCardProps: {
              title: eventTypeNameMap['call_slingshot_declined'],
              icon: <AcnDeclinedIcon />,
              timestamp: dateToAbbreviatedDateTimeString(event.created_at),
              metaTexts: [
                `Name: ${formatName(
                  event.actor.agent?.first_name,
                  event.actor.agent?.last_name
                )}`,
                `Phone: ${formatPhoneNumber(event.actor.agent?.phone)}`,
              ],
            },
            event_date: event.created_at,
          };
        }
        if (event_type === 'call_slingshot_unanswered') {
          eventsMap[event.id] = {
            eventCardProps: {
              title: eventTypeNameMap['call_slingshot_unanswered'],
              icon: <AcnUnansweredIcon />,
              timestamp: dateToAbbreviatedDateTimeString(event.created_at),
              metaTexts: [
                `Name: ${formatName(
                  event.actor.agent?.first_name,
                  event.actor.agent?.last_name
                )}`,
                `Phone: ${formatPhoneNumber(event.actor.agent?.phone)}`,
              ],
            },
            event_date: event.created_at,
          };
        }
        if (event_type === 'email_unsubscribed') {
          eventsMap[event.id] = {
            eventCardProps: {
              title: eventTypeNameMap['email_unsubscribed'],
              icon: <EmailUnsubscribedIcon />,
              timestamp: dateToAbbreviatedDateTimeString(event.created_at),
              metaTexts: [`Email: ${event.subject.lead?.email_addresses[0]?.email}`],
            },
            event_date: event.created_at,
          };
        }
        if (event_type === 'opportunity_status_updated') {
          if (event.subject?.opportunity?.status === 'won')
            eventsMap[event.id] = {
              eventCardProps: {
                title: `Lead Won`,
                icon: <MoneyIcon />,
                timestamp: dateToAbbreviatedDateTimeString(event.created_at),
                metaTexts: [`User: ${getEventUsername(event.actor_user_id)}`],
              },
              event_date: event.created_at,
            };
          else if (event.subject?.opportunity?.status === 'lost')
            eventsMap[event.id] = {
              eventCardProps: {
                title: `Lead Lost`,
                icon: <MoneyIcon />,
                timestamp: dateToAbbreviatedDateTimeString(event.created_at),
                metaTexts: [
                  `User: ${getEventUsername(event.actor_user_id)}`,
                  `Reason: ${event.subject.lost_reason?.name || 'Unknown'}`,
                ],
                collapseContent: event.subject.opportunity.lost_details,
              },
              event_date: event.created_at,
            };
          else if (event.subject?.opportunity?.status === 'pending')
            eventsMap[event.id] = {
              eventCardProps: {
                title: `Lead Pending`,
                icon: <MoneyIcon />,
                timestamp: dateToAbbreviatedDateTimeString(event.created_at),
                metaTexts: [`User: ${getEventUsername(event.actor_user_id)}`],
              },
              event_date: event.created_at,
            };
        }
      });

      const communications = Object.values(communicationsMap);
      const events = Object.values(eventsMap);
      // Sort communications by date asc
      communications.sort((a, b) =>
        new Date(a.event_date) > new Date(b.event_date) ? -1 : 1
      );
      // open the most recent 5 communications
      communications.forEach((event, i) => {
        if (i <= 4) event.eventCardProps.defaultOpen = true;
      });
      // combine events and communications then sort desc
      const leadEventHistory = [...events, ...communications].sort((a, b) =>
        new Date(a.event_date) < new Date(b.event_date) ? -1 : 1
      );

      if (setCommunicationsCount) {
        setCommunicationsCount(leadEventHistory.length);
      }

      setLeadEventHistory(leadEventHistory);
    }
  }, [eventsList, usersList, lead]);

  useEffect(() => {
    scrollToBottom();
  }, [leadEventHistory]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      height="100%"
      overflow="auto"
      px={2}
    >
      {leadEventHistory.length == 0 ? (
        <Grid container sx={{ mt: 5 }} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        leadEventHistory.map((event, i) => {
          return <EventCard key={i} {...event.eventCardProps} />;
        })
      )}
      <div ref={scrollRef} />
    </Box>
  );
};

export default LeadEventHistory;
//---------------------------------------------------------------------
const CallEventContent = ({ callRecording }) => {
  let player,
    transcription = null;

  if (callRecording?.download && callRecording?.download.trim() != '') {
    player = <AuthenticatedPlayer source={callRecording.download} />;
    transcription = callRecording?.transcription?.content;
  }
  // TODO tooltip?
  return (
    <Box>
      <Box sx={{ '& .react-audio-player': { width: '100%' } }} width="100%">
        {player}
      </Box>
      {transcription && (
        <Box display="flex" flexDirection="column">
          <Typography fontWeight={500}>Transcription:</Typography>
          <Typography>{transcription}</Typography>
        </Box>
      )}
    </Box>
  );
};

const TextEventContent = ({ message, attachments }) => {
  return (
    <Box>
      <Typography>{message}</Typography>
      {attachments?.map((attachment, i) => {
        if (attachment.content_type.startsWith('image')) {
          return <ImageEnlarger key={i} src={attachment.download} />;
        } else {
          return <AuthenticatedFileViewer key={i} src={attachment.download} />;
        }
      })}
    </Box>
  );
};

import { queryKeyFactory, ONE_HOUR } from '../queryClientConfig';
import Enterprises from './Enterprises';
import { useQuery } from 'react-query';

export const enterprisesQueryKeys = {
  ...queryKeyFactory('enterprises'),
};

export const useEnterprisesShow = (id, options) => {
  return useQuery(enterprisesQueryKeys.show({ id }), () => Enterprises.show(id), {
    staleTime: ONE_HOUR,
    ...options,
  });
};

import { Container } from 'react-bootstrap';
import TemplateForm from './TemplateForm';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TemplateErrorBanner from './TemplateErrorBanner';
import { useMessageTemplatesCreate } from '../../MUI/services/messageTemplates/mutations';

const CreateTemplateView = ({ location: { state } }) => {
  const history = useHistory();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState(null);
  const [type, setType] = useState(null);

  const {
    mutate: createMessageTemplate,
    isSuccess: didSucceedMutatingMessageTemplate,
    isError: didFailMutatingMessageTemplate,
    isLoading: isMutatingMessageTemplate,
    data: createdMessageTemplateData,
  } = useMessageTemplatesCreate();

  const getTaskActionId = (type) => {
    switch (type) {
      case 'Email':
        return '0fcbfe42-8a33-411e-8dad-46b269a34ec1';
      case 'Text':
        return '1b09c81b-8384-49a0-85c5-2466aacf9d37';
      case 'Call':
        return 'a50370fd-5b8b-4b87-ba15-744ec43840e6';
      default:
        return null;
    }
  };

  const submitClick = (e, { name, subject, content }) => {
    e.preventDefault();
    if (type === 'Email' && subject.trim() === '') {
      if (
        !confirm(
          'You do not have a subject for this email template. Continue anyway?'
        )
      ) {
        return;
      }
    }
    setIsSubmitDisabled(true);

    createMessageTemplate({
      name,
      content,
      subject,
      task_action_id: getTaskActionId(type),
    });

    if (!didFailMutatingMessageTemplate) {
      setIsSubmitDisabled(false);
      history.push('/templates');
    } else {
      setErrorDisplay(
        'There was an error creating your template. Please try again.'
      );
      setIsSubmitDisabled(false);
    }
  };

  useEffect(() => {
    if (state) setType(state.type);
  });
  return (
    <Container fluid className={'p-0'}>
      <h3>
        {type ? `Create a new ${type} Template` : 'Please Select Template Type'}
      </h3>
      <TemplateErrorBanner
        errorDisplay={errorDisplay}
        setErrorDisplay={setErrorDisplay}
      />

      <TemplateForm
        submitClick={submitClick}
        isSubmitDisabled={isSubmitDisabled}
        type={type}
      />
    </Container>
  );
};

export default CreateTemplateView;

import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Route, useLocation, useHistory } from 'react-router-dom';
import CognitoCallback from './components/CognitoCallback';
import { minutesUntilJwtExpires } from './util/AppUtils';
import Cookies from 'js-cookie';
import { JWT_TOKEN } from './util/AuthUtil';
import Logout from './components/Logout/Logout';
import { useInterval } from './MUI/services/hooks/useInterval';
import jwt_decode from 'jwt-decode';
import { wipeActiveOrg } from './util/UserUtils';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';

const LOGIN_REDIRECT = 'login-redirect-to';

export const AuthContainer = ({ children }) => {
  const [isValidCognitoJwt, setIsValidCognitoJwt] = useState(false);
  const [showSessionWarningModal, setShowSessionWarningModal] = useState(false);
  const hasLoggedOut = useRef(false);
  const location = useLocation();
  const history = useHistory();

  const checkCognitoJwtExpiration = () => {
    const minutesLeftOnJwt = minutesUntilJwtExpires(Cookies.get(JWT_TOKEN));
    if (minutesLeftOnJwt <= 0) {
      if (!location.pathname.includes('logout') && !hasLoggedOut.current) {
        hasLoggedOut.current = true;
        setCurrentLocationToLocalStorage();
        history.push('/logout');
      }
    } else if (minutesLeftOnJwt <= 4) {
      setShowSessionWarningModal(true);
      if (!location.pathname.includes('logout') && !hasLoggedOut.current) {
        hasLoggedOut.current = true;
        setTimeout(() => {
          setCurrentLocationToLocalStorage();
          history.push('/logout');
        }, minutesLeftOnJwt * 60 * 1000);
      }
    }
  };

  useInterval(() => {
    checkCognitoJwtExpiration();
  }, 1000 * 60 * 2);

  useEffect(() => {
    // check for cookie
    if (hasUnexpiredCognitoJwt()) setIsValidCognitoJwt(true);
    else {
      setCurrentLocationToLocalStorage();
      goToLoginUrl();
    }

    // check JWT when user navigates to app from another tab/window
    window.addEventListener('visibilitychange', (e) => {
      if (document.visibilityState === 'visible') {
        checkCognitoJwtExpiration();
      }
    });
  }, []);

  if (!isValidCognitoJwt)
    return (
      <Route exact path={'/callback'}>
        <CognitoCallback />
      </Route>
    );

  if (location.pathname === '/logout') {
    logUserOut();
    return (
      <Route path={'*'}>
        <Logout setIsValidCognitoJwt={setIsValidCognitoJwt} />
      </Route>
    );
  }

  return (
    <Fragment>
      {showSessionWarningModal && (
        <SessionWarningModal
          showSessionWarningModal={showSessionWarningModal}
          setShowSessionWarningModal={setShowSessionWarningModal}
        />
      )}
      {children}
    </Fragment>
  );
};
//----------------------------------------------------------------------------
const hasUnexpiredCognitoJwt = () => {
  let decoded;
  try {
    decoded = jwt_decode(Cookies.get(JWT_TOKEN));
  } catch (_e) {
    return false;
  }
  if (decoded.exp < Math.floor(Date.now() / 1000)) {
    return false;
  }
  return true;
};
//----------------------------------------------------------------------------
export const setCurrentLocationToLocalStorage = () => {
  localStorage.setItem(
    LOGIN_REDIRECT,
    window.location.pathname.replace('logout', '') + window.location.search
  );
};
//----------------------------------------------------------------------------
export const goToLoginUrl = () => {
  window.location = process.env.REACT_APP_LOGIN_URL;
};
//----------------------------------------------------------------------------
const logUserOut = () => {
  wipeActiveOrg();
  Cookies.remove(JWT_TOKEN, {
    domain: window.location.hostname.replace(/^[^.]+\./g, ''),
  });
};
//----------------------------------------------------------------------------
const SessionWarningModal = ({
  showSessionWarningModal,
  setShowSessionWarningModal,
}) => {
  const createMinutesLeftDisplay = () => {
    const decimalTime = minutesUntilJwtExpires(Cookies.get(JWT_TOKEN));
    const min = Math.floor(Math.abs(decimalTime));
    const sec = String(Math.floor((Math.abs(decimalTime) * 60) % 60));
    return `${min}:${sec.length === 1 ? '0' + sec : sec}`;
  };

  const [minutesLeftDisplay, setMinutesLeftDisplay] = useState(
    createMinutesLeftDisplay()
  );

  useInterval(() => {
    setMinutesLeftDisplay(createMinutesLeftDisplay());
  }, 1000);

  return (
    <>
      <Dialog open={showSessionWarningModal}>
        <DialogTitle>Session Timeout</DialogTitle>
        <DialogContent>
          {`Your session will expire and you will be logged out in ${minutesLeftDisplay} minutes`}
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="inherit"
            onClick={() => setShowSessionWarningModal(false)}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              logUserOut();
              setCurrentLocationToLocalStorage();
              goToLoginUrl();
            }}
          >
            Log Out
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

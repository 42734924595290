import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import OverrideArguments from './OverrideArguments';
import { aiprofileQueryKeys } from '../aiprofile/queries';

export const useOverrideArgumentsCreate = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => OverrideArguments.create(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(aiprofileQueryKeys.all);
    },
  });
};

export const useOverrideArgumentsEdit = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => OverrideArguments.edit({ ...params }), {
    onSuccess: () => {
      queryClient.invalidateQueries(aiprofileQueryKeys.all);
    },
  });
};

export const useOverrideArgumentsDelete = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => OverrideArguments.delete(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(aiprofileQueryKeys.all);
    },
  });
};

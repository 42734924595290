import { Alert, Box, Grid, CircularProgress } from '@mui/material';
import CallsAuthenticatedPlayer from './CallsAuthenticated';

const CallsEventHistory = ({ recording }) => {
  return (
    <Box display="flex" flexDirection="column" gap={1} height="100%" overflow="auto">
      {recording?.data ? (
        <CallEventContent callRecording={recording.data} />
      ) : recording?.status == 'error' ? (
        <Alert severity="error">Error loading recording.</Alert>
      ) : (
        <Grid container sx={{ my: 3 }} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      )}
    </Box>
  );
};

export default CallsEventHistory;
//---------------------------------------------------------------------
const CallEventContent = ({ callRecording }) => {
  let player = null;

  if (callRecording?.download && callRecording?.download.trim() != '') {
    player = <CallsAuthenticatedPlayer source={callRecording.download} />;
  }
  return (
    <Box>
      <Box sx={{ '& .react-audio-player': { width: '100%' } }}>{player}</Box>
    </Box>
  );
};

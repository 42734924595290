import { useQuery } from 'react-query';
import { queryKeyFactory } from '../../MUI/services/queryClientConfig';
import AIModules from './AIModules';
export const aimodulesQueryKeys = queryKeyFactory('ai-modules');

export const useAiModulesList = (params, options) => {
  return useQuery(aimodulesQueryKeys.list(), () => AIModules.list(params), {
    staleTime: 0,
    ...options,
  });
};

export const useAiModulesShow = (moduleID, options) => {
  return useQuery(
    aimodulesQueryKeys.list(moduleID),
    () => AIModules.show(moduleID),
    {
      staleTime: 0,
      ...options,
    }
  );
};

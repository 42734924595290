import { useQuery } from 'react-query';
import { queryKeyFactory } from '../queryClientConfig';
import Sessions from './Sessions';

export const sessionsIdQueryKeys = queryKeyFactory('sessions');

export const useSessionsList = (filters, options) => {
  return useQuery(sessionsIdQueryKeys.list(filters), () => Sessions.list(filters), {
    staleTime: 0,
    ...options,
  });
};

export const useSessionsShow = (session_id, options) => {
  return useQuery(
    sessionsIdQueryKeys.show({ session_id }),
    () => Sessions.show(session_id),
    {
      enabled: !!session_id,
      ...options,
    }
  );
};

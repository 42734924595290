// takes all contact collections from a lead and combines into one array
export const concatAllLeadContactTypes = (types, lead) => {
  let contactsArray = [];
  types.forEach((type) => {
    if (!lead[type]) return;
    contactsArray = [...contactsArray, ...lead[type]];
  });
  return contactsArray;
};
// FUNCTIONAL sort by
export const sortByCreatedAt = (arr, sortBy) => {
  let copyArr = JSON.parse(JSON.stringify(arr));
  return sortBy === 'asc'
    ? copyArr?.sort((a, b) =>
        new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
      )
    : copyArr?.sort((a, b) =>
        new Date(a.created_at) > new Date(b.created_at) ? 1 : -1
      );
};
// given an array, counts the number of inbounds before an existing outbound
export const countInboundsBeforeOutbound = (contactArray) => {
  let count = 0;
  for (let i = 0; i < contactArray.length; i++) {
    if (contactArray[i].direction === 'in') count++;
    if (contactArray[i].direction === 'out') return count;
  }
  return count;
};

export const getInboundCommunicationCounts = (lead) => {
  const tempCommunicationsList = concatAllLeadContactTypes(
    ['calls', 'emails', 'texts'],
    lead
  );
  const sorted = sortByCreatedAt(tempCommunicationsList, 'asc');
  const mostRecentOut = sorted.find(({ direction }) => direction === 'out');
  if (!mostRecentOut) {
    return {
      callsCount: lead.calls.length,
      textsCount: lead.texts.length,
      emailsCount: lead.emails.length,
      totalCount: lead.calls.length + lead.texts.length + lead.emails.length,
    };
  }
  const date = mostRecentOut.created_at;

  const calls = lead.calls.filter(
    ({ created_at }) => new Date(created_at) > new Date(date)
  );
  const texts = lead.texts.filter(
    ({ created_at }) => new Date(created_at) > new Date(date)
  );
  const emails = lead.emails.filter(
    ({ created_at }) => new Date(created_at) > new Date(date)
  );

  return {
    callsCount: calls.length,
    textsCount: texts.length,
    emailsCount: emails.length,
    totalCount: calls.length + texts.length + emails.length,
  };
};

export const renderInboundCommunicationDisplay = (lead) => {
  if (lead) {
    let renderArr = [];
    const { callsCount, textsCount, emailsCount } = getInboundCommunicationCounts(
      lead
    );
    if (callsCount)
      renderArr = [
        ...renderArr,
        newMessageAlert(callsCount, `${callsCount === 1 ? 'call' : 'calls'}`),
      ];
    if (textsCount)
      renderArr = [
        ...renderArr,
        newMessageAlert(textsCount, `${textsCount === 1 ? 'text' : 'texts'}`),
      ];
    if (emailsCount)
      renderArr = [
        ...renderArr,
        newMessageAlert(emailsCount, `${emailsCount === 1 ? 'email' : 'emails'}`),
      ];

    return renderArr;
  }
};

const newMessageAlert = (count, type) => {
  return (
    <div role={'alert'} className={'fade alert sigma-alert show'}>
      {`You have received ${count} new inbound ${type} from this lead.`}
    </div>
  );
};

export const getLeadPrimaryPhoneNumber = (lead) => {
  const primary = lead.phones ? lead.phones.filter((x) => x.is_primary) : [];
  if (primary.length == 0) {
    return '';
  }
  return primary[0].number;
};

import { useMutation, useQueryClient } from 'react-query';
import Phones from './Phones';
import { phoneIdQueryKeys } from '../../MUI/services/phoneIds/queries';

export const usePhonesTeamsRegister = (options) => {
  return useMutation(Phones.register_for_teams, {
    mutationKey: ['phones', 'create'],
    ...options,
  });
};

export const usePhonesIvrsRegister = (options) => {
  const queryClient = useQueryClient();
  return useMutation(Phones.register_for_ivrs, {
    mutationKey: ['phones', 'create'],
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(phoneIdQueryKeys.all);
    },
  });
};

export const usePhonesDelete = (options) => {
  return useMutation(Phones.delete, {
    mutationKey: ['phones', 'delete'],
    ...options,
  });
};

export const useTeamsPhonesDelete = (options) => {
  return useMutation(Phones.team_phone_delete, {
    mutationKey: ['phones', 'delete'],
    ...options,
  });
};

import { useQuery } from 'react-query';
import LostReasons from './LostReasons';

import { useOrg } from '../../contexts/OrgProvider';
import { ONE_HOUR, queryKeyFactory } from '../../MUI/services/queryClientConfig';

const lostReasonsQueryKeys = {
  ...queryKeyFactory('lostReasons'),
};

export const useLostReasons = (params = [], options) => {
  const sortList = (data) => {
    return data.items.sort((a, b) => (a.name === 'Other' ? -1 : 1));
  };

  const { id, isMasterAccount } = useOrg();
  return useQuery(
    lostReasonsQueryKeys.list(params),
    () => {
      const filters = [['page_size', 10000], ...params];

      return LostReasons.list(filters);
    },
    {
      staleTime: ONE_HOUR,
      select: sortList,
      ...options,
    }
  );
};

import { Box } from '@mui/material';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { ListViewStyle } from '../ListView';

const DroppableListView = ({ droppableId, children, ...other }) => {
  return (
    <Droppable droppableId={droppableId}>
      {(provided) => (
        <Box
          component={ListViewStyle}
          {...provided.droppableProps}
          ref={provided.innerRef}
          {...other}
        >
          {children}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  );
};

export default DroppableListView;

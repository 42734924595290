import { Children } from 'react';
import { XIcon } from '../../../theme/icons';
import { defineUiElements } from '../utils';
import { fireDialog } from '../../Dialog';
import {
  Button,
  Box,
  Paper,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const Container = ({
  children,
  title,
  isListOptions,
  component,
  handleDeleteComponent,
}) => {
  const deletePopup = ({ isOpen, onResolve, onReject }) => {
    return (
      <Dialog open={isOpen} onClose={onReject}>
        <DialogTitle>Delete step {title}?</DialogTitle>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton onClick={() => onResolve(handleDeleteComponent(component))}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  };

  const deleteOption = () => {
    fireDialog((promiseProps) => {
      return deletePopup(promiseProps);
    });
  };

  return (
    <Paper
      sx={{
        margin: '50px 0 70px',
        padding: '20px',
        background: ({ palette }) => `${palette.grey[100]}`,
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '-20px',
            left: '50%',
            width: '70px',
            height: '70px',
            borderRadius: '50%',
            fontSize: '24px',
            transform: 'translate(-50%, -50%)',
            border: ({ palette }) => `1px solid ${palette.grey[600]}`,
            background: ({ palette }) => `${palette.grey[100]}`,
          }}
        >
          {defineUiElements(title, true)}
        </Box>
        {isListOptions || (
          <Button
            onClick={deleteOption}
            variant="text"
            sx={{
              position: 'absolute',
              right: '-15px',
              borderRadius: '50%',
              top: '5px',
              padding: '0',
              minWidth: 'auto',
              transform: 'translate(-50%, -50%)',
              color: 'initial',
              '&:hover': {
                background: 'transparent',
              },
              '& > span': {
                margin: '0',
              },
            }}
            endIcon={<XIcon />}
          />
        )}
        {Children.only(children)}
      </Box>
    </Paper>
  );
};

import { toJSON, toParamString } from '../queryClientConfig';
import Cookies from 'js-cookie';

export default class Organizations {
  static async list(filters) {
    return await fetch(
      `${process.env.REACT_APP_MESSAGING_API_BASE}/organizations?${toParamString(
        filters
      )}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async show(id) {
    return await fetch(
      `${process.env.REACT_APP_MESSAGING_API_BASE}/organizations/${id}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async billingAddresses(organization_id) {
    return await fetch(
      `${process.env.REACT_APP_MESSAGING_API_BASE}/organizations/${organization_id}/billing_addresses`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async organizationServices(organization_id) {
    return await fetch(
      `${
        process.env.REACT_APP_MESSAGING_API_BASE
      }/organization_services?${toParamString({
        organization_id: organization_id,
      })}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async create(organization) {
    return await fetch(`${process.env.REACT_APP_MESSAGING_API_BASE}/organizations`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(organization),
    }).then(toJSON);
  }

  static async edit(organization) {
    return await fetch(
      `${process.env.REACT_APP_MESSAGING_API_BASE}/organizations/${organization.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(organization),
      }
    ).then(toJSON);
  }

  static async delete(organization_id) {
    return await fetch(
      `${process.env.REACT_APP_MESSAGING_API_BASE}/organizations/${organization_id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
  }
}

import { useQuery } from 'react-query';
import { useOrg } from '../../../contexts/OrgProvider';
import { queryKeyFactory } from '../queryClientConfig';
import LeadLocks from './LeadLocks';

export const leadLocksQueryKeys = queryKeyFactory('lead_locks');

export const useLeadLocksList = (options) => {
  const { id, isMasterAccount } = useOrg();
  const filters = [];
  if (!isMasterAccount) filters.push(['organization_id', id]);
  return useQuery(leadLocksQueryKeys.list(filters), () => LeadLocks.list(filters), {
    staleTime: 0,
    refetchInterval: 1000 * 15,
    ...options,
  });
};

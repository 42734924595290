import { useUniversalSearchList } from '../services/universalSearch/queries';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Autocomplete,
  TextField,
  Box,
  InputAdornment,
  Typography,
  Chip,
} from '@mui/material';
import { SearchIcon } from '../theme/icons';
import { useEffect } from 'react';
import { getLeadName } from '../services/leads/leadsUtils';
import { useDebounce } from '../services/hooks/useDebounce';
import { useOrg } from '../../contexts/OrgProvider';
import { useOrganizationsList } from '../services/organizations/queries';
import { getAccountName } from '../services/organizations/organizationsUtils';
import { dateToPrettyDateString } from '../services/dateTime';
import { formatPhoneNumber } from '../services/textFormatting';

const LeadSearch = () => {
  const location = useLocation();
  const history = useHistory();
  const [searchInput, setSearchInput] = useState('');
  const [searchValue, setSearchValue] = useState(null);
  const debouncedSearchText = useDebounce(searchInput, 300);
  const { id: orgId, isMasterAccount } = useOrg();
  const { data: accountsList } = useOrganizationsList();
  const { data: searchResultsList, isLoading } = useUniversalSearchList(
    {
      search: debouncedSearchText,
      ...(location.pathname !== '/calls'
        ? { order: 'leads.created_at desc' }
        : null),
      ...(!isMasterAccount && { organization_id: orgId }),
    },
    {
      enabled: debouncedSearchText.length > 0,
      pathName: location.pathname,
    }
  );

  const handleSearchSelect = ({ id, number, name, type }) => {
    setSearchInput('');
    setSearchValue(null);
    switch (type) {
      case 'call':
        history.push(`/calls/${id}`);
        break;
      case 'phone':
        history.push(`/calls?tracking_phone=${encodeURIComponent(number)}`);
        break;
      case 'target':
        history.push(`/calls?target_name=${name}`);
        break;
      case 'lead':
        history.push(`/leads/${id}`);
        break;
    }
  };

  const getResourceName = (option) => {
    switch (option.resource_type) {
      case 'Call':
        return `${formatPhoneNumber(option.resource.from)}${
          option.resource.cnam ? ' (' + option.resource.cnam + ')' : ''
        }`;
      case 'Phone':
        return formatPhoneNumber(option.resource.number);
      case 'Target':
        return option.resource.name;
      default:
        return option.name;
    }
  };

  const getResourceNumber = (option) => {
    switch (option.resource_type) {
      case 'Call':
        return option.resource.from;
      case 'Phone':
        return option.resource.number;
      case 'Target':
        return option.resource.target_phone;
    }
  };

  const searchResults = (list) => {
    if (!list) return [];

    return list?.items.map((item) => {
      if (location.pathname === '/calls') {
        // Call, Phone or Phone Pool info
        return {
          id: item.resource.id,
          name: getResourceName(item),
          number: getResourceNumber(item),
          type: item.resource_type.toLowerCase(),
          secondaryInfo:
            item.resource_type === 'Target' ? 'Phone Pool' : item.resource_type,
          ...(isMasterAccount && {
            accountName: getAccountName(item.resource, accountsList) ?? 'N/A',
          }),
        };
      } else {
        // Lead info
        return {
          id: item.id,
          name: getLeadName(item),
          type: 'lead',
          secondaryInfo: dateToPrettyDateString(String(item.created_at)),
          ...(isMasterAccount && {
            accountName: getAccountName(item, accountsList) ?? 'N/A',
          }),
        };
      }
    });
  };

  useEffect(() => {
    if (searchValue) handleSearchSelect(searchValue);
  }, [searchValue]);

  return (
    <Box flex={{ xs: 1, sm: 0.8 }}>
      <Autocomplete
        forcePopupIcon={false}
        disableClearable
        value={searchValue}
        inputValue={searchInput}
        onInputChange={(e, newInput) => {
          setSearchInput(newInput);
        }}
        autoComplete
        loading={isLoading}
        noOptionsText="No matches found"
        size="small"
        onChange={(_, value) => {
          setSearchValue(value);
        }}
        options={searchResults(searchResultsList)}
        filterOptions={(x) => x}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => {
          return (
            <Box component="li" width="100%" {...props} key={option.id}>
              <Box
                width="inherit"
                maxWidth="inherit"
                display="flex"
                alignItems="center"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  overflow="hidden"
                  flexBasis="100%"
                >
                  <Typography
                    sx={{
                      minWidth: 0,
                      display: 'inline-block',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {option.name}
                  </Typography>
                  <Typography fontSize={12}>{option.secondaryInfo}</Typography>
                </Box>
                {isMasterAccount && <Chip size="small" label={option.accountName} />}
              </Box>
            </Box>
          );
        }}
        renderInput={(params) => {
          params = {
            ...params,
            InputProps: {
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            },
          };
          return (
            <TextField
              size="small"
              {...params}
              sx={{
                '.MuiInputBase-root:not(:focus-within)': {
                  backgroundColor: ({ palette }) => palette.grey[100],
                },
                '& legend': { width: '0px' },
              }}
              label=""
              placeholder="Search name or phone"
            />
          );
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </Box>
  );
};

export default LeadSearch;

import { useCallback, useState } from 'react';
import { Box, Button, LinearProgress } from '@mui/material';
import Cookies from 'js-cookie';

import NumberCallFLowStep from './NumberCallFlowStep';
import CallForwardingStep from './CallForwardingStep';
import NumberSetUpStep from './NumberSetUpStep';
import TotalStep from './TotalStep';
import { CommonItem } from './common';
import { steps } from './steps';
import { useOrg } from '../../../contexts/OrgProvider';

export default function CallTrackingWizard({ setAddNewCallTracking }) {
  const { id: orgId } = useOrg();
  const [navigation, setNavigation] = useState({
    currentStep: 'networkStatus',
    previousSteps: [],
  });

  const resetNavigation = () => {
    setAddNewCallTracking(false);
    setNavigation({
      currentStep: 'networkStatus',
      previousSteps: [],
    });
  };

  const handleForwardNavigate = useCallback(
    (nextStep, currentStep) => {
      return setNavigation((state) => {
        const routes = [...state.previousSteps];
        routes.push(currentStep);
        return {
          ...state,
          currentStep: nextStep,
          previousSteps: routes,
        };
      });
    },
    [setNavigation, navigation]
  );

  const handleBackNavigate = useCallback(() => {
    return setNavigation((state) => {
      const routes = [...state.previousSteps];
      const needed = routes.pop();
      return { ...state, currentStep: needed, previousSteps: routes };
    });
  }, [setNavigation, navigation]);

  const [collectedData, setCollectedData] = useState({
    networkStatus: '',
    locatedAt: '',
    trackType: '',
    isGoogleAdExtension: '',
    whereGoogleAdShouldRun: '',
    callFlowId: '',
    numberCallFlowName: '',
    numberSetupId: '',
    numberSetup: '',
    numberSetupName: '',
    callForwarding: '',
    teamId: '',
    teamName: '',
    organizationId: orgId,
    userToken: 'Bearer ' + Cookies.get('cognito-jwt'),
  });

  const handleCollect = useCallback(
    (key, val) => {
      return setCollectedData((state) => {
        return { ...state, [key]: val };
      });
    },
    [setCollectedData, collectedData]
  );

  const neededStep = steps.filter((item) => item.name === navigation.currentStep);

  return (
    <Box mt={3} style={{ position: 'relative' }}>
      <h5 style={{ textAlign: 'center' }}>Create tracking number</h5>
      <span
        style={{
          position: 'absolute',
          top: '-30px',
          right: '10px',
          fontSize: '40px',
          cursor: 'pointer',
        }}
        onClick={() => resetNavigation()}
      >
        &times;
      </span>
      <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
        <LinearProgress
          style={{ height: '7px', width: 'calc(100% - 120px)' }}
          variant="determinate"
          value={neededStep[0].complete}
        />
        <span>
          {neededStep[0].complete === 100 ? (
            <span>&#127881; Complete</span>
          ) : (
            `${neededStep[0].complete} % Complete`
          )}
        </span>
      </Box>
      {neededStep.map((neededItem) => {
        if (neededItem.details) {
          return (
            <CommonItem
              name={neededItem.name}
              key={neededItem.name}
              mainTitle={neededItem.headerTitle}
              itemDetails={neededItem.details}
              handleCollect={handleCollect}
              handleForwardNavigate={handleForwardNavigate}
            />
          );
        } else if (neededItem.name === 'callForwarding') {
          return (
            <CallForwardingStep
              handleForwardNavigate={handleForwardNavigate}
              handleCollect={handleCollect}
              collectedData={collectedData}
              handleBackNavigate={handleBackNavigate}
              key={neededItem.name}
            />
          );
        } else if (neededItem.name === 'numberCallFlow') {
          return (
            <NumberCallFLowStep
              key={neededItem.name}
              handleForwardNavigate={handleForwardNavigate}
              collectedData={collectedData}
              handleBackNavigate={handleBackNavigate}
              handleCollect={handleCollect}
            />
          );
        } else if (neededItem.name === 'numberSetup') {
          return (
            <NumberSetUpStep
              key={neededItem.name}
              handleBackNavigate={handleBackNavigate}
              handleForwardNavigate={handleForwardNavigate}
              collectedData={collectedData}
              handleCollect={handleCollect}
            />
          );
        } else
          return (
            <TotalStep
              key={neededItem.name}
              collectedData={collectedData}
              handleBackNavigate={handleBackNavigate}
              handleForwardNavigate={handleForwardNavigate}
              setAddNewCallTracking={setAddNewCallTracking}
            />
          );
      })}
      {Boolean(neededStep[0].details && navigation.previousSteps.length) && (
        <Button onClick={() => handleBackNavigate()}>Back</Button>
      )}
    </Box>
  );
}

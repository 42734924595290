import React, { forwardRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
// some dead imports below are due to slider being commented out.
// slider code is not currently needed in the app and will likely be removed.
import {
  Checkbox as MuiCheckbox,
  TextField as MuiTextField,
  Slider as MuiSlider,
  Input,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  FormControlLabel,
  Switch,
  Autocomplete,
} from '@mui/material';
import { IMaskInput } from 'react-imask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks } from '@fortawesome/free-solid-svg-icons';

// ------------------------------------------------------------------
export const ContextCheckbox = ({ name, checkboxSx, ...other }) => {
  const { control } = useFormContext();
  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <MuiCheckbox sx={checkboxSx} {...field} checked={field.value} />
          )}
        />
      }
      {...other}
    />
  );
};

// ------------------------------------------------------------------
export const ContextTextField = ({ name, defaultValue, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field, fieldState: { error } }) => (
        <MuiTextField
          {...field}
          size="small"
          error={!!error}
          helperText={error?.message}
          {...other}
        />
      )}
    />
  );
};

// ------------------------------------------------------------------
export const ContextPhoneNumberField = ({
  name,
  label,
  mask,
  showError,
  ...other
}) => {
  const getMask = (mask) => {
    switch (mask) {
      case 'E164':
        return PhoneInputMaskE164;
      default:
        return PhoneInputMask;
    }
  };
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      showError={showError}
      render={({ field, fieldState: { error } }) => (
        <FormControl error={!!error} size="small" {...other}>
          <InputLabel
            variant="outlined"
            htmlFor="phone-input-mask"
            error={showError}
          >
            {label}
          </InputLabel>
          <OutlinedInput
            label={label}
            {...field}
            id="phone-input-mask"
            inputComponent={getMask(mask)}
            error={showError}
          />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export const PhoneInputMask = forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export const PhoneInputMaskE164 = forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+1(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

// ------------------------------------------------------------------
export const ContextSelect = ({ name, children, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MuiTextField
          {...field}
          sx={{
            '& .MuiSelect-select .MuiInputBase-input .MuiOutlinedInput-input': {
              padding: 0,
              border: '2px solid red',
            },
          }}
          select
          error={!!error}
          helperText={error?.message}
          {...other}
        >
          {children}
        </MuiTextField>
      )}
    />
  );
};

export const ContextSwitch = ({ name, label, disabled, ...other }) => {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      label={label}
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Switch
              checked={field.value}
              onChange={() => {
                field.onChange();
              }}
              disabled={disabled}
              {...field}
            />
          )}
        />
      }
      {...other}
    />
  );
};

export const ContextAutocomplete = ({
  name,
  required,
  label,
  options,
  ...other
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: required }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          size="small"
          forcePopupIcon={false}
          sx={{ width: '100%' }}
          onChange={(event, value) => {
            onChange(value);
          }}
          value={value}
          options={options}
          getOptionLabel={(item) => item.name ?? ''}
          isOptionEqualToValue={(option, value) => {
            value === undefined || value === '' || option.id === value.id;
          }}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={label}
              placeholder=""
              error={!!error}
              helperText={error?.message}
              name={name}
            />
          )}
          {...other}
        />
      )}
    />
  );
};

/*
Sliders are not currently being used. They will probably be ruled out of the app
entirely at some point. Until then, here is the majority of code needed to use them.
*/

// ------------------------------------------------------------------
// let marks = [
//   { value: 0, label: 0 },
//   { value: 15, label: '15' },
//   { value: 30, label: '30' },
// ];
// export const ContextSlider = ({ name, max, min, steps }) => {
//   const { control } = useFormContext();
//   return (
//     <Controller
//       name={name}
//       control={control}
//       render={({ field }) => {
//         return (
//           <Box display="flex" columnGap={3}>
//             <MuiSlider
//               aria-labelledby="input-slider"
//               disableSwap
//               // size="small"
//               valueLabelDisplay="auto"
//               max={max}
//               marks={marks}
//               step={steps}
//               {...field}
//             />
//             <Input
//               size="small"
//               onChange={(e) => {
//                 let intValue = parseInt(e.target.value);
//                 if (intValue > max) field.onChange(max);
//                 else if (intValue < min || isNaN(intValue)) field.onChange(min);
//                 else field.onChange(intValue);
//               }}
//               inputProps={{
//                 value: field.value,
//                 step: steps,
//                 min,
//                 max,
//                 type: 'number',
//                 'aria-labelledby': 'input-slider',
//               }}
//             />
//           </Box>
//         );
//       }}
//     />
//   );
// };

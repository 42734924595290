import React, { useContext, useState } from 'react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import SyncLoader from 'react-spinners/SyncLoader';

import Lead from '../../clients/Lead';
import Note from '../../clients/Note';
import AppContext from '../../contexts/AppContext';
import { leadsQueryKeys } from '../../MUI/services/leads/queries';
import { tasksQueryKeys } from '../../MUI/services/tasks/queries';
import { useQueryClient } from 'react-query';

const NoteCreate = ({ show, closeModal, lead, task }) => {
  const [value, setValue] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const { user } = useContext(AppContext);
  const queryClient = useQueryClient();

  const handleSave = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    const note = {
      lead_id: lead.id,
      user_id: user.id,
      value: value,
    };
    setIsSaving(true);
    Note.create(note).then(() => {
      if (task) queryClient.invalidateQueries(tasksQueryKeys.show(task.id));
      if (lead) queryClient.invalidateQueries(leadsQueryKeys.all);
      handleClose();
      setIsSaving(false);
    });
  };

  const updateValue = (e) => {
    setValue(e.target.value);
  };

  const handleClose = () => {
    setValue('');
    closeModal();
  };

  return (
    <Modal show={show} onHide={handleClose} className={'modal-sigma'}>
      <Form onSubmit={handleSave}>
        <Modal.Header className={'text-center'}>
          <Modal.Title className={'w-100'}>Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId={'content'}>
            <Form.Control as={'textarea'} rows={5} onChange={updateValue} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col xs={'6'}>
                <button
                  type={'button'}
                  className={'btn btn-light btn-block'}
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </Col>
              <Col xs={'6'}>
                <button
                  type={'submit'}
                  className={'btn btn-sigma-primary btn-block'}
                >
                  {isSaving ? <SyncLoader color={'white'} size={'10'} /> : 'Add'}
                </button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default NoteCreate;

import { useHistory, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/system';
import { Box, Typography, Grid } from '@mui/material';

import TaskDetail from '../../../components/tasks/TaskDetail';

import { useTasksShow } from '../../services/tasks/queries';
import FinishLine from '../../../assets/images/finish-line.png';

export const TaskCardWrapper = ({ setTaskShowError }) => {
  const { taskIdParam } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const { data: task, error: error } = useTasksShow(taskIdParam, {
    onSettled: (data, error) => {
      if (error?.status === 404) {
        setTaskShowError(true);
        history.push({
          pathname: '/tasks',
        });
      }
      setIsLoading(false);
    },
  });

  useEffect(() => {
    setIsLoading(true);
  }, [taskIdParam]);

  if (!task || isLoading) {
    return (
      <Grid container direction="column">
        <Grid item align="center">
          <ClipLoader size={100} color="#34D1B6" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Box maxHeight={'100%'} flex={4}>
      <Box position="relative" height="100%">
        <TaskDetail task={task} lead={task.lead} />
      </Box>
    </Box>
  );
};

export const NoTodoTasksOverlay = () => {
  return (
    <Box height="100%" display="flex" justifyContent="center" alignItems="center">
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box component="img" src={FinishLine} display="block" maxHeight={150} />
        <Typography fontSize={18} fontWeight={500}>
          Follow-up Champion
        </Typography>
      </Box>
    </Box>
  );
};

export const NoNonTodoTasksOverlay = () => {
  return (
    <Box height="100%" display="flex" justifyContent="center" alignItems="center">
      <Typography fontSize={18} fontWeight={500}>
        No tasks to display
      </Typography>
    </Box>
  );
};

export const TasksDataGridStyle = styled(DataGrid)(
  ({ theme, showNoActionCursor, taskIdParam }) => {
    return {
      backgroundColor: 'white',
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: '4px',
      '& .MuiDataGrid-footerContainer': {
        justifyContent: 'flex-start',
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },

      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: taskIdParam ? theme.palette.grey[100] : 'white',
      },
      '& .MuiDataGrid-columnHeader:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-columnHeader:focus-within': {
        outline: 'none',
      },
      '& .MuiDataGrid-cell:focus-within': {
        outline: 'none',
      },
      '& .MuiDataGrid-row': {
        cursor: showNoActionCursor ? 'not-allowed' : 'pointer',
      },
    };
  }
);

export const ActiveCellSpan = ({ children, cell }) => {
  const { taskIdParam } = useParams();

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      sx={{
        color: ({ palette }) => palette.info.main,
        backgroundColor:
          cell.id === taskIdParam ? ({ palette }) => palette.grey[100] : 'inherit',
        '&::before':
          cell.id === taskIdParam
            ? {
                content: "''",
                position: 'absolute',
                left: 0,
                height: '100%',
                width: '4px',
                backgroundColor: ({ palette }) => palette.primary.main,
              }
            : {},
      }}
    >
      {children}
    </Box>
  );
};

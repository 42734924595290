/*
  !FormProviderWrapper will be removed soon and affected componentes will be reimplemented correctly.
*/
import FormProviderWrapper from './_HookForm/FormProviderWrapper';
import UseFormWrapper from './_HookForm/UseFormWrapper';

import {
  ContextTextField,
  ContextSelect,
  ContextPhoneNumberField,
  ContextCheckbox,
  ContextSwitch,
  ContextAutocomplete,
} from './_HookForm/FormContextInputs';

const HookForm = UseFormWrapper;

HookForm.Checkbox = ContextCheckbox;
HookForm.TextField = ContextTextField;
HookForm.PhoneNumberField = ContextPhoneNumberField;
HookForm.Select = ContextSelect;
HookForm.Switch = ContextSwitch;
HookForm.Autocomplete = ContextAutocomplete;

FormProviderWrapper.Checkbox = ContextCheckbox;
FormProviderWrapper.TextField = ContextTextField;
FormProviderWrapper.PhoneNumberField = ContextPhoneNumberField;
FormProviderWrapper.Select = ContextSelect;
FormProviderWrapper.Switch = ContextSwitch;
FormProviderWrapper.Autocomplete = ContextAutocomplete;

export default HookForm;
export const FormProvider = FormProviderWrapper;

import React, { useEffect, useState } from 'react';
import { Row, Col, Dropdown } from 'react-bootstrap';
import { UPDATE_STEP } from './useRenderedSteps';
import { SequenceCheckbox } from './SequenceShow';
import { MdEdit } from 'react-icons/md';
import EmailTemplateModal from './EmailTemplateModal';
import { actionId } from '../../util/AppUtils';
import SyncLoader from 'react-spinners/SyncLoader';
import { colorValues } from '../../util/Constants';
import TemplatePreview from './TemplatePreview';
import {
  getCurrentTemplateName,
  getDropdownButtonPreview,
  getDropdownItemPreview,
} from './util';
import {
  useMessageTemplatesCreate,
  useMessageTemplatesEdit,
} from '../../MUI/services/messageTemplates/mutations';

const EmailOptions = ({ dispatch, action, messageTemplates }) => {
  const [showEmailTemplatesModal, setShowEmailTemplatesModal] = useState(false);
  const [isNewTemplate, setIsNewTemplate] = useState(false);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);

  const [messageTemplatesList, setMessageTemplates] = useState(messageTemplates);

  const {
    mutate: createMessageTemplate,
    isSuccess: didSucceedMutatingMessageTemplate,
    isError: didFailMutatingMessageTemplate,
    isLoading: isMutatingMessageTemplate,
    data: createdMessageTemplateData,
  } = useMessageTemplatesCreate();

  const {
    mutate: editMessageTemplate,
    isSuccess: didSucceedEditingMessageTemplate,
    isError: didFailEditingMessageTemplate,
    isLoading: isEditingMessageTemplate,
    data: editedMessageTemplateData,
  } = useMessageTemplatesEdit();

  useEffect(() => {
    setMessageTemplates(messageTemplates);
  }, [messageTemplates]);

  const templates = messageTemplatesList
    .filter((t) => t.task_action.name == 'Email')
    .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

  const createTemplate = ({ name, content, subject }) => {
    setIsLoadingTemplates(true);
    setShowEmailTemplatesModal(false);
    setIsNewTemplate(false);

    createMessageTemplate({
      name,
      content,
      subject,
      task_action_id: actionId.Email,
    });

    if (didSucceedMutatingMessageTemplate) {
      setIsLoadingTemplates(false);
      dispatch({
        type: UPDATE_STEP,
        payload: {
          editID: action.editID,
          updates: {
            message_template_id: createdMessageTemplateData.id,
          },
        },
      });
    }
    setIsLoadingTemplates(false);
  };

  const editTemplate = ({ name, content, subject, selectedTemplateId }) => {
    setIsLoadingTemplates(true);
    setShowEmailTemplatesModal(false);
    setIsNewTemplate(false);

    editMessageTemplate({ id: selectedTemplateId, name, content, subject });

    if (didSucceedEditingMessageTemplate) {
      setIsLoadingTemplates(false);
      dispatch({
        type: UPDATE_STEP,
        payload: {
          editID: action.editID,
          updates: {
            message_template_id: editedMessageTemplateData.id,
          },
        },
      });
    }
    setIsLoadingTemplates(false);
  };

  return (
    <Row>
      {showEmailTemplatesModal && (
        <EmailTemplateModal
          showEmailTemplatesModal={showEmailTemplatesModal}
          setShowEmailTemplatesModal={setShowEmailTemplatesModal}
          templates={templates}
          action={action}
          isNewTemplate={isNewTemplate}
          setIsNewTemplate={setIsNewTemplate}
          createTemplate={createTemplate}
          editTemplate={editTemplate}
        />
      )}
      <Col xs={4} className="d-flex align-items-center ">
        <SequenceCheckbox
          label="Automate"
          checked={action.automated}
          onChange={(e) =>
            dispatch({
              type: UPDATE_STEP,
              payload: {
                editID: action.editID,
                updates: {
                  automated: e.target.checked,
                },
              },
            })
          }
        />
      </Col>
      <Col xs={8} className="d-flex align-items-center">
        {isLoadingTemplates ? (
          <div className="ml-2 py-2 mt-1">
            <SyncLoader size="10px" color={colorValues.greyColor} />
          </div>
        ) : (
          <>
            <Dropdown
              className="mr-3"
              value={action.message_template_id}
              onSelect={(e) => {
                if (e === 'new') {
                  setShowEmailTemplatesModal(true);
                  setIsNewTemplate(true);
                } else {
                  dispatch({
                    type: UPDATE_STEP,
                    payload: {
                      editID: action.editID,
                      updates: {
                        message_template_id: e,
                      },
                    },
                  });
                }
              }}
            >
              <Dropdown.Toggle className="btn-light position-relative d-flex align-items-center">
                <div className="option-dropdown">
                  {getCurrentTemplateName(templates, action)}
                </div>
                <TemplatePreview
                  getTemplateContent={() =>
                    getDropdownButtonPreview(action, templates)
                  }
                >
                  <div className="sequence-template-preview" />
                </TemplatePreview>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  key="new"
                  eventKey="new"
                  className="d-flex align-items-center"
                >
                  <h5>+</h5>&nbsp; Create New Template
                </Dropdown.Item>
                {templates.map((template) => (
                  <TemplatePreview
                    key={template.id}
                    getTemplateContent={() =>
                      getDropdownItemPreview(template, action.task_action.name)
                    }
                  >
                    <Dropdown.Item
                      className="option-dropdown"
                      key={template.id}
                      eventKey={template.id}
                    >
                      {template.name}
                    </Dropdown.Item>
                  </TemplatePreview>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <button
              className="btn-no-styling btn"
              onClick={() => setShowEmailTemplatesModal(true)}
            >
              <MdEdit size="25" />
            </button>
          </>
        )}
      </Col>
    </Row>
  );
};

export default EmailOptions;

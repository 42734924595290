import { useOrg } from '../../../contexts/OrgProvider';
import { useQuery } from 'react-query';
import { queryKeyFactory, ONE_HOUR } from '../queryClientConfig';
import Users from './Users';

const usersQueryKeys = {
  ...queryKeyFactory('users'),
};
usersQueryKeys.current = ['users', 'current'];
export { usersQueryKeys };

export const useUsersCurrent = (options) => {
  const transformToItem = (data) => {
    return data.items[0];
  };

  return useQuery(usersQueryKeys.current, Users.current, {
    staleTime: Infinity,
    useErrorBoundary: true,
    select: transformToItem,
    ...options,
  });
};

// Filters out current user
export const useUsersList = (params = [], options) => {
  const parseUsers = (data) => {
    let hasCurrentUser = null;
    const temp = [];
    data.items.forEach((item) => {
      if (item.id == currentUser.id) {
        hasCurrentUser = item;
        return;
      }
      return temp.push(item);
    });
    if (hasCurrentUser) {
      const lastName = `${hasCurrentUser.last_name ?? ''} (You)`.trim();
      return [{ ...hasCurrentUser, last_name: lastName }, ...temp];
    }

    return temp;
  };

  const { data: currentUser } = useUsersCurrent();
  const { id, isMasterAccount } = useOrg();

  return useQuery(
    usersQueryKeys.list(params),
    () => {
      const filters = [
        ['page_size', 1000],
        ['order', 'first_name asc,last_name asc'],
        ...params,
      ];
      if (!isMasterAccount) {
        filters.push(['organization_id[]', id]);
      }
      return Users.list(filters);
    },
    {
      staleTime: ONE_HOUR,
      useErrorBoundary: true,
      select: parseUsers,
      ...options,
    }
  );
};

export const useMultiAccountUsersList = (params = [], options) => {
  const parseUsers = (data) => {
    let hasCurrentUser = null;
    const temp = [];
    data.items.forEach((item) => {
      if (item.id == currentUser.id) {
        hasCurrentUser = item;
        return;
      }
      return temp.push(item);
    });
    if (hasCurrentUser) {
      const lastName = `${hasCurrentUser.last_name ?? ''} (You)`.trim();
      return [{ ...hasCurrentUser, last_name: lastName }, ...temp];
    }

    return temp;
  };

  const { data: currentUser } = useUsersCurrent();
  return useQuery(
    usersQueryKeys.list(params),
    () => {
      const filters = [
        ['page_size', 1000],
        ['order', 'first_name asc,last_name asc'],
        ...params,
      ];
      return Users.list(filters);
    },
    {
      staleTime: ONE_HOUR,
      select: parseUsers,
      ...options,
    }
  );
};

export const useUsersShow = (user_id) => {
  return useQuery(usersQueryKeys.show({ user_id }), () => Users.show(user_id), {
    staleTime: ONE_HOUR,
  });
};

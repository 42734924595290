import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SaveIcon, EditIcon, DeleteIcon, PlusIcon } from '../theme/icons';
import {
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TableHead,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  Alert,
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { LoadingButton } from '@mui/lab';
import { fireDialog } from '../components/Dialog';
import HookForm from '../components/HookForm';
import { useAiModuleFunctionDefinitionsEdit } from '../../api/aiModulesFunctionDefinitions/mutation';
import { useAiModuleFunctionDefinitionsShow } from '../../api/aiModulesFunctionDefinitions/queries';
import {
  useAIModulesFunctionArgumentsCreate,
  useAIModulesFunctionArgumentsDelete,
  useAIModulesFunctionArgumentsEdit,
} from '../../api/aiModulesFunctionArguments/mutation';
export default function AIModulesFunctionArguments() {
  const aiFunctionID = useParams();
  const { data: aiModuleFucntionData } = useAiModuleFunctionDefinitionsShow({
    id: aiFunctionID?.functionID,
  });
  const {
    mutate: editfunctionmutate,
    isError,
    isSuccess,
    isLoading,
  } = useAiModuleFunctionDefinitionsEdit();

  const [aiModulesFunctionName, setAIModulesFunctionName] = useState();
  const [aiModulesFunctionDescription, setAIModulesFunctionDescription] = useState();
  const [isAlertVisible, setAlertVisible] = useState(false);
  const [dataTypes, setDataTypes] = useState([]);
  const [url, setUrl] = useState();
  const [method, setMethod] = useState(aiModuleFucntionData?.method);

  const handleEditSubmit = () => {
    editfunctionmutate({
      id: aiFunctionID?.functionID,
      name: aiModulesFunctionName,
      description: aiModulesFunctionDescription,
      data_type: dataTypes,
      url: url,
      method: method,
    });
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
      }, 300);
    }
  }, [isSuccess, isError]);

  const onCreate = () => {
    fireDialog((promiseProps) => (
      <AddAIModulesFunction
        functionID={aiFunctionID?.functionID}
        {...promiseProps}
      />
    ));
  };

  const onDelete = (aiModuleFunArgID) => {
    fireDialog((promiseProps) => (
      <DeleteAIModulesFunctionArg
        aiModuleFunArgID={aiModuleFunArgID}
        {...promiseProps}
      />
    ));
  };
  const onEdit = (functionArgData) => {
    fireDialog((promiseProps) => (
      <EditAIModulesFunction functionArgData={functionArgData} {...promiseProps} />
    ));
  };

  useEffect(() => {
    if (aiModuleFucntionData) {
      setAIModulesFunctionName(aiModuleFucntionData?.name);
      setAIModulesFunctionDescription(aiModuleFucntionData?.description);
      setDataTypes(aiModuleFucntionData?.data_type);
      setUrl(aiModuleFucntionData?.url);
      setMethod(aiModuleFucntionData?.method);
    }
  }, [aiModuleFucntionData]);

  const APIMethosd = ['GET', 'POST'];

  return (
    <Box sx={{ height: '100%' }}>
      <SettingsCard mt={2}>
        <SettingsCard.Header>Edit Function</SettingsCard.Header>
        {isError && (
          <Alert severity="error">There was a problem while updating.</Alert>
        )}
        {isAlertVisible && (
          <Alert severity="success">function updated successfully.</Alert>
        )}
        <Grid
          container
          display="flex"
          direction="column"
          sx={{ rowGap: 2, marginTop: 2 }}
        >
          <Grid item>
            <TextField
              label="Name"
              type="text"
              value={aiModulesFunctionName || ''}
              onChange={(e) => setAIModulesFunctionName(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              label="URL"
              type="text"
              value={url || ''}
              onChange={(e) => setUrl(e.target.value)}
            />
          </Grid>
          <Grid item>
            <FormControl size="small" sx={{ width: '24%' }}>
              <InputLabel>Method</InputLabel>
              <Select
                label="Method"
                value={method || ''}
                onChange={(e) => setMethod(e.target.value)}
              >
                {APIMethosd.map((type) => (
                  <MenuItem value={type} key={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              multiline
              label="Description"
              type="text"
              rows={5}
              defaultValue={aiModulesFunctionDescription || ''}
              sx={{ width: '50%' }}
              onChange={(e) => setAIModulesFunctionDescription(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid>
            <LoadingButton
              loading={isLoading}
              startIcon={<SaveIcon />}
              type="submit"
              onClick={handleEditSubmit}
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid
          container
          display="flex"
          direction="column"
          sx={{ rowGap: 1, marginTop: 2.5 }}
        >
          <SettingsCard.Header>Arguments</SettingsCard.Header>
          <TableContainer sx={{ mb: 2 }}>
            <Table>
              <TableHead>
                <TableRow size="small">
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Required</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {aiModuleFucntionData?.function_arguments?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <Typography component={'span'} variant="subtitle3">
                        {item?.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography component={'span'} variant="subtitle3">
                        {item?.data_type}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={item.required}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        startIcon={<EditIcon />}
                        size="small"
                        variant="text"
                        color="primary"
                        sx={{ marginRight: 1 }}
                        onClick={() => onEdit(item)}
                      >
                        Edit
                      </Button>
                      <Button
                        startIcon={<DeleteIcon />}
                        size="small"
                        variant="text"
                        color="error"
                        sx={{ marginRight: 1 }}
                        onClick={() => onDelete(item.id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item>
          <Button
            startIcon={<PlusIcon />}
            onClick={onCreate}
            sx={{ marginRight: 1 }}
          >
            Add New
          </Button>
        </Grid>
      </SettingsCard>
    </Box>
  );
}

const AddAIModulesFunction = ({ isOpen, onReject, onResolve, functionID }) => {
  const {
    mutate: functionargumentmutate,
    isError,
    isSuccess,
    isLoading,
  } = useAIModulesFunctionArgumentsCreate();

  const [aiModulesFunctionDescription, setAIModulesFunctionDescription] = useState();
  const [aiModulesFunctionType, setAIModulesFunctionType] = useState();
  const [
    aiModulesFunctionArguemntName,
    setAIModulesFunctionArguemntName,
  ] = useState();
  const [checked, setChecked] = useState('');

  const handleCreateSubmit = () => {
    functionargumentmutate({
      function_definition_id: functionID,
      name: aiModulesFunctionArguemntName,
      description: aiModulesFunctionDescription,
      data_type: aiModulesFunctionType,
      required: checked,
    });
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        onResolve();
      }, 300);
    }
  }, [isError, isSuccess]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const defaultValues = {
    name: '',
    description: '',
    required: '',
    data_type: '',
  };

  const useFormProps = {
    defaultValues,
  };

  const dataTypes = ['string', 'number', 'boolean'];

  return (
    <Box>
      <Dialog open={isOpen} onClose={onReject}>
        <DialogTitle>Create Argument</DialogTitle>
        <HookForm useFormProps={useFormProps} onSubmit={handleCreateSubmit}>
          <DialogContent>
            {isError && (
              <Alert severity="error">
                There was a problem while creating argument.
              </Alert>
            )}
            {isSuccess && (
              <Alert severity="success">Argument created successfully.</Alert>
            )}
            <Box display="flex" flexDirection="column" rowGap={2}>
              <TextField
                label="Name"
                type="text"
                value={aiModulesFunctionArguemntName || ''}
                onChange={(e) => setAIModulesFunctionArguemntName(e.target.value)}
              />
              <FormControl size="small">
                <InputLabel>Data Type</InputLabel>
                <Select
                  label="Data Type"
                  value={aiModulesFunctionType}
                  onChange={(e) => setAIModulesFunctionType(e.target.value)}
                >
                  {dataTypes.map((type) => (
                    <MenuItem value={type} key={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Description"
                type="text"
                multiline
                rows={5}
                value={aiModulesFunctionDescription || ''}
                onChange={(e) => setAIModulesFunctionDescription(e.target.value)}
              />
            </Box>
            <FormControlLabel
              style={{ marginLeft: -10 }}
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Required"
            />
          </DialogContent>
          <DialogActions>
            <Button variant="text" color="inherit" onClick={onReject}>
              Cancel
            </Button>
            <LoadingButton type="submit" loading={isLoading} disabled={isSuccess}>
              Confirm
            </LoadingButton>
          </DialogActions>
        </HookForm>
      </Dialog>
    </Box>
  );
};

const EditAIModulesFunction = ({ isOpen, onReject, onResolve, functionArgData }) => {
  const {
    mutate: editmutatefunctionArg,
    isError,
    isSuccess,
    isLoading,
  } = useAIModulesFunctionArgumentsEdit();

  const [aiModulesFunctionDescription, setAIModulesFunctionDescription] = useState(
    functionArgData?.description
  );
  const [aiModulesFunctionType, setAIModulesFunctionType] = useState(
    functionArgData?.data_type
  );
  const [aiModulesFunctionArguemntName, setAIModulesFunctionArguemntName] = useState(
    functionArgData?.name
  );
  const [checked, setChecked] = useState(functionArgData?.required);

  const handleCreateSubmit = () => {
    editmutatefunctionArg({
      function_argumetn_id: functionArgData?.id,
      name: aiModulesFunctionArguemntName,
      description: aiModulesFunctionDescription,
      data_type: aiModulesFunctionType,
      required: checked,
    });
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        onResolve();
      }, 300);
    }
  }, [isError, isSuccess]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const defaultValues = {
    name: '',
    description: '',
    required: '',
    data_type: '',
  };

  const useFormProps = {
    defaultValues,
  };

  const dataTypes = ['string', 'number', 'boolean'];

  return (
    <Box>
      <Dialog open={isOpen} onClose={onReject}>
        <DialogTitle>Edit Argument</DialogTitle>
        <HookForm useFormProps={useFormProps} onSubmit={handleCreateSubmit}>
          <DialogContent>
            {isError && (
              <Alert severity="error">
                There was a problem while updating Function.
              </Alert>
            )}
            {isSuccess && (
              <Alert severity="success">Function updated successfully.</Alert>
            )}
            <Box display="flex" flexDirection="column" rowGap={2}>
              <TextField
                label="Name"
                type="text"
                value={aiModulesFunctionArguemntName || ''}
                onChange={(e) => setAIModulesFunctionArguemntName(e.target.value)}
              />
              <FormControl size="small">
                <InputLabel>Data Type</InputLabel>
                <Select
                  label="Data Type"
                  value={aiModulesFunctionType}
                  onChange={(e) => setAIModulesFunctionType(e.target.value)}
                >
                  {dataTypes.map((type) => (
                    <MenuItem value={type} key={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Description"
                type="text"
                multiline
                rows={5}
                value={aiModulesFunctionDescription || ''}
                onChange={(e) => setAIModulesFunctionDescription(e.target.value)}
              />
            </Box>
            <FormControlLabel
              style={{ marginLeft: -10 }}
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Required"
            />
          </DialogContent>
          <DialogActions>
            <Button variant="text" color="inherit" onClick={onReject}>
              Cancel
            </Button>
            <LoadingButton type="submit" loading={isLoading} disabled={isSuccess}>
              Confirm
            </LoadingButton>
          </DialogActions>
        </HookForm>
      </Dialog>
    </Box>
  );
};

const DeleteAIModulesFunctionArg = ({
  isOpen,
  onReject,
  onResolve,
  aiModuleFunArgID,
}) => {
  const {
    mutate: aiModulesFunctionArgDelete,
    isLoading: didDeleteloading,
    isError: didDeleteError,
    isSuccess: didDelteSuccess,
  } = useAIModulesFunctionArgumentsDelete();

  useEffect(() => {
    if (didDelteSuccess && !didDeleteError) {
      setTimeout(() => {
        onResolve();
      }, 1000);
    }
  }, [didDelteSuccess, didDeleteError]);

  const onSubmit = () => {
    aiModulesFunctionArgDelete({ id: aiModuleFunArgID });
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Delete Function Argument</DialogTitle>
      <DialogContent>
        {didDeleteError && (
          <Alert severity="error">
            There was a problem while deleting function argument.
          </Alert>
        )}
        {didDelteSuccess && <Alert severity="success">Deleted successfully.</Alert>}
        Are you sure to delete function argument.
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          loading={didDeleteloading}
          disabled={didDelteSuccess}
          onClick={onSubmit}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import { createContext, useContext, useEffect, useState } from 'react';
import { conditionallySetActiveOrg } from '../util/UserUtils';
import { useUsersCurrent } from '../MUI/services/users/queries';
import LogRocket from 'logrocket';

const OrgContext = createContext();
export const useOrg = () => useContext(OrgContext);

const OrgProvider = ({ children }) => {
  const { data: user } = useUsersCurrent();
  // org: {id: string, name: string}
  const [org, setOrg] = useState();
  const setOrgHandler = (org) =>
    setOrg({
      ...org,
      id: String(org.id),
      isEnterpriseUser: checkIsEnterpriseUser(org, user),
      isMasterAccount: checkIsMasterAccount(org),
      enterpriseIds: user?.authorizations?.table?.enterprise_ids,
    });

  useEffect(() => {
    conditionallySetActiveOrg(
      user.authorizations.table.organizations,
      setOrgHandler
    );
  }, []);

  useEffect(() => {
    if (org) {
      LogRocket.identify(user.id, {
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        username: user.username,
        organization_id: org.id,
        organization_name: org.name,
      });
    }
  }, [org]);
  //TODO loading state?
  if (!org) return <div></div>;
  return <OrgContext.Provider value={org}>{children}</OrgContext.Provider>;
};

export default OrgProvider;

const checkIsMasterAccount = (account) => {
  return !!account.enterprise_id;
};
const checkIsEnterpriseUser = (account, currentUser) => {
  const enterprise_ids = currentUser?.authorizations?.table?.enterprise_ids;
  return enterprise_ids.includes(account.enterprise_id);
};

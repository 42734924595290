import { Box } from '@mui/material';

export function TabPanel(props) {
  const { children, value, index, sx, ...other } = props;

  return (
    <Box
      sx={{ ...sx }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  );
}

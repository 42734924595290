/* 
    File to store common hard coded values to share for styling that 
    don't belong applied to the theme
*/

export const COL_WIDTH_LG = 450;
export const COL_WIDTH_TH1 = 175;
export const COL_WIDTH_TH2 = COL_WIDTH_LG - COL_WIDTH_TH1;
export const CELL_HEIGHT_MD = 35;

// this should be added to the theme instead with some more clever responsive
// behavior at some point.
export const FIELD_HEIGHT = 30;
export const FIELD_WIDTH = 250;

import parsePhoneNumber from 'libphonenumber-js';

export const formatPhoneNumber = (phone) => {
  if (!phone) return '';
  const phoneNumber = parsePhoneNumber(phone);
  if (phoneNumber) {
    return phoneNumber.formatNational();
  }
  return phone;
};

export const formatPhoneNumberE164 = (phone) => {
  if (!phone) return '';
  const phoneNumber = parsePhoneNumber(phone, 'US');
  if (phoneNumber) {
    return phoneNumber.number;
  }
  return phone;
};

export const centsToDollarString = (centAmount) => {
  const dollars = Math.round(centAmount / 100);
  return dollars.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const formatName = (firstName, lastName) => {
  return firstName || lastName
    ? `${firstName ?? ''} ${lastName ?? ''}`.trim()
    : 'Anonymous';
};

export const formatTimeDuration = (end, start) => {
  if (end == null) {
    return 'In Progress';
  } else {
    const startTime = new Date(start);
    const endTime = new Date(end);

    if (startTime > endTime) return '-';

    const seconds = Math.round((endTime - startTime) / 1000);

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const hoursText = String(hours).padStart(2, '0');
    const minutesText = String(minutes).padStart(2, '0');
    const secondsText = String(remainingSeconds).padStart(2, '0');

    return [hoursText, minutesText, secondsText].join(':');
  }
};

export const formatKey = (key) => {
  const words = key.split('_');
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  return capitalizedWords.join(' ');
};

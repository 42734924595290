import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormHelperText,
  InputLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from 'react-bootstrap';
import { useOrg } from '../../../contexts/OrgProvider';
import { MicrophoneIcon, SaveIcon } from '../../theme/icons';
import { useCallFlowCreate, useCallFlowEdit } from '../../../api/callFlow/mutations';

export const CallFlowHeader = ({
  callFlowId,
  setTab,
  resetToInitial,
  shouldRecord,
  setShouldRecord,
  callFlowName,
  setCallFlowName,
  callFlowWebhook,
  setCallFlowWebhook,
  createApiBlocks,
  createApiChains,
  isCallFlowReady,
  doSearchEmptyInputs,
  setDoSearchEmptyInputs,
}) => {
  const history = useHistory();
  const { id: organization_id } = useOrg();

  const [err, setErr] = useState(false);
  const [success, setSuccess] = useState('');

  const { mutate: editCallFlow, isLoading: isLoading2 } = useCallFlowEdit();
  const { mutate: createCallFlow, isLoading } = useCallFlowCreate();

  const sendData = () => {
    const data = {
      organization_id,
      name: callFlowName,
      webhook: callFlowWebhook,
      should_record: shouldRecord,
      blocks: createApiBlocks(),
      chains: createApiChains(),
    };
    if (callFlowId) {
      return editCallFlow(
        { callFlowId, data },
        {
          onSuccess: () => {
            setSuccess(`Call flow updated successfully`);
            const successTimeout = setTimeout(() => {
              setSuccess(null);
              clearTimeout(successTimeout);
            }, 1500);
            resetToInitial();
          },
        }
      );
    } else {
      createCallFlow(data, {
        onSuccess: (payload) => {
          if (payload.status === 422) {
            setErr('The name has already been taken');
          } else {
            resetToInitial();
            setSuccess(`Call flow created successfully`);
            setTimeout(() => setTab && setTab(true), 1000);
          }
        },
      });
    }
  };

  return (
    <>
      {success && (
        <Alert variant="success" mt={2}>
          {success}
        </Alert>
      )}
      {err && (
        <Alert variant="danger" mt={2}>
          {err || 'Something went wrong. Please contact your support team!'}
        </Alert>
      )}
      <Box>
        <Typography textAlign="center" variant="h3">
          {callFlowId ? 'Call Flow' : 'Start Call Flow'}
        </Typography>
        <Typography textAlign="center" variant="subtitle1">
          {callFlowId ? 'Edit ' : 'Build '} your call flow or duplicate steps from an
          existing call flow.
        </Typography>
        <hr />
        <Box mt={1} display="flex" justifyContent="space-between">
          <Box>
            <Box flex>
              <InputLabel shrink={true} />
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={{ width: '300px' }}
                name="callFlowField"
                label={'Call Flow Name' || callFlowName}
                variant={'outlined'}
                value={callFlowName}
                onChange={(e) => setCallFlowName(e.target.value)}
                error={!callFlowName && doSearchEmptyInputs}
              />
            </Box>
            <Box flex>
              <InputLabel shrink={true} />
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={{ width: '300px', mt: 2 }}
                name="callFlowWebhook"
                label={'Post Call Webhook' || callFlowWebhook}
                variant={'outlined'}
                value={callFlowWebhook}
                onChange={(e) => setCallFlowWebhook(e.target.value)}
              />
            </Box>
            <Box sx={{ mt: 2 }} display="flex" alignItems="center">
              <MicrophoneIcon />
              <Typography variant="span">Record calls</Typography>
              <Switch
                checked={shouldRecord}
                onChange={() => setShouldRecord((val) => !val)}
              />
            </Box>
          </Box>
          <Box>
            <LoadingButton
              startIcon={<SaveIcon />}
              loadingPosition="start"
              loading={isLoading || isLoading2}
              disabled={isLoading || isLoading2}
              onClick={() => {
                setDoSearchEmptyInputs(true);
                if (callFlowName && isCallFlowReady) {
                  sendData();
                }
              }}
            >
              Save
            </LoadingButton>
            <Button
              variant="outlined"
              sx={{ ml: 2 }}
              onClick={() => {
                if (callFlowId) {
                  history.push('/settings/call-flow');
                } else {
                  setTab(true);
                  resetToInitial();
                }
              }}
            >
              Cancel
            </Button>
            <FormHelperText
              sx={{
                visibility:
                  doSearchEmptyInputs && (!isCallFlowReady || !callFlowName)
                    ? 'visible'
                    : 'hidden',
              }}
            >
              *Fill in all parameters.
            </FormHelperText>
          </Box>
        </Box>
      </Box>
      <hr />
    </>
  );
};

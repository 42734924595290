import { useQuery, useQueryClient } from 'react-query';
import { useOrg } from '../../../contexts/OrgProvider';
import { ONE_HOUR, queryKeyFactory } from '../queryClientConfig';
import PhoneId from './PhoneId';

export const phoneIdQueryKeys = queryKeyFactory('phoneId');

export const usePhoneIDList = (filters = [], options) => {
  return useQuery(phoneIdQueryKeys.list(filters), () => PhoneId.list(filters), {
    staleTime: ONE_HOUR,
    ...options,
  });
};

export const usePhoneIDShow = (teamId, options) => {
  const queryClient = useQueryClient();
  return useQuery(
    phoneIdQueryKeys.show({ team_id: teamId }),
    () => PhoneId.show(teamId),
    {
      staleTime: ONE_HOUR,
      initialData: () => {
        let cache = queryClient.getQueryData(
          phoneIdQueryKeys.show({ team_id: teamId })
        );
        if (cache) {
          return;
        }
        return queryClient.getQueryData('phoneId')?.find((d) => d.id == teamId);
      },
      ...options,
    }
  );
};

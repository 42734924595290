import Cookies from 'js-cookie';
import { toJSON, toParamString } from '../queryClientConfig';

export default class Activity {
  static async list(filters) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/activity?${toParamString(filters)}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }
}

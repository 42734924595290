import { useHistory } from 'react-router-dom';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import ListView from '../components/ListView';
import { useUsersCurrent, useUsersList } from '../services/users/queries';
import { useUsersDelete } from '../services/users/mutations';
import { PlusIcon } from '../theme/icons';
import LoadingButton from '@mui/lab/LoadingButton';
import { fireDialog } from '../components/Dialog';
import { formatPhoneNumber } from '../services/textFormatting';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Alert,
} from '@mui/material';
import { useOrg } from '../../contexts/OrgProvider';

const NAME_WIDTH = 180;
const EMAIL_WIDTH = 220;
const PHONE_WIDTH = 150;

const UsersListSettingsPage = () => {
  const filterList = (data) => {
    return data.items.filter(({ id }) => id !== currentUser.id);
  };

  const { id: orgId } = useOrg();
  const { data: currentUser } = useUsersCurrent();
  const { data: usersList, isFetching, isError, isSuccess } = useUsersList(
    [['organization_id[]', orgId]],
    {
      select: filterList,
    }
  );
  const history = useHistory();

  const handleDeleteUserClick = (userId) =>
    fireDialog((promiseParams) => DeleteUserDialog({ userId, ...promiseParams }));

  return (
    <SettingsCard showLoading={isFetching}>
      {isError && (
        <Alert severity="error">
          There was an error loading your users. Try again.
        </Alert>
      )}
      <SettingsCard.Header>Users</SettingsCard.Header>
      <SettingsCard.SubHeader>
        Add, edit, and delete users. Adding a user gives them login access to
        LeadSigma.
      </SettingsCard.SubHeader>
      <SettingsCard.Main>
        {isSuccess && usersList.length == 0 ? (
          <Alert severity="info" sx={{ mt: 1, mb: 2 }}>
            Add your first user to LeadSigma
          </Alert>
        ) : (
          <ListView sx={{ mb: 2 }}>
            <ListView.Item>
              <ListView.HeaderText colWidth={NAME_WIDTH}>User</ListView.HeaderText>
              <ListView.HeaderText colWidth={EMAIL_WIDTH}>
                Username
              </ListView.HeaderText>
              <ListView.HeaderText colWidth={PHONE_WIDTH}>Phone</ListView.HeaderText>
            </ListView.Item>
            {usersList?.map((user) => (
              <ListView.Item key={user.id}>
                <ListView.Text
                  colWidth={NAME_WIDTH}
                >{`${user.first_name} ${user.last_name}`}</ListView.Text>
                <ListView.Text colWidth={EMAIL_WIDTH}>{user.username}</ListView.Text>
                <ListView.Text colWidth={PHONE_WIDTH}>
                  {formatPhoneNumber(user.phone)}
                </ListView.Text>
                <ListView.EditButton
                  onClick={() => history.push(`users/edit/${user.id}`)}
                />
                <ListView.DeleteButton
                  onClick={() => handleDeleteUserClick(user.id)}
                />
              </ListView.Item>
            ))}
          </ListView>
        )}
        <Button
          startIcon={<PlusIcon />}
          onClick={() => history.push(`users/create`)}
        >
          Add New
        </Button>
      </SettingsCard.Main>
    </SettingsCard>
  );
};

export default UsersListSettingsPage;

// dialogs ---------------------------------------------------------
const DeleteUserDialog = ({ isOpen, onResolve, onReject, userId }) => {
  const { mutate: deleteUser, isLoading, isError } = useUsersDelete();

  const handleDeleteUser = () => {
    deleteUser(userId, {
      onSuccess: onResolve,
    });
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Delete User</DialogTitle>
      <DialogContent>
        {isError && (
          <Alert severity="error">
            There was a problem deleting your user. Try again.
          </Alert>
        )}
        Are you sure you would like to delete this user?
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          disabled={isLoading}
          onClick={handleDeleteUser}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

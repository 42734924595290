//TODO dead code
import React, { useContext, useEffect, useState } from 'react';
import 'animate.css';
import {
  faPhone,
  faEnvelope,
  faCommentAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SyncLoader from 'react-spinners/SyncLoader';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';

import {
  useLeadLocksCreate,
  useLeadLocksDelete,
} from '../../MUI/services/leadLocks/mutations';
import { useLeadLocksList } from '../../MUI/services/leadLocks/queries';

const OverrideLockModal = ({ showOverride, handleCloseOverride, lead, user }) => {
  const { data: locks } = useLeadLocksList();
  const { mutate: createLeadLock } = useLeadLocksCreate();
  const { mutate: deleteLeadLock } = useLeadLocksDelete({
    onSettled: () => {
      createLeadLock({
        lead_id: lead.id,
        user_id: user.id,
        organization_id: lead.organization_id,
      });
    },
  });
  const override = () => {
    handleCloseOverride();
    const lock = locks.find((lock) => lock.lead_id == lead.id);
    deleteLeadLock(lock.id);
  };

  return (
    <Modal
      show={showOverride}
      onHide={handleCloseOverride}
      className={'modal-sigma'}
    >
      <Modal.Header className={'text-center'}>
        <Modal.Title className={'w-100'}>Override Lock</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {lead.first_name} {lead.last_name} has been locked by another user.
        <br />
        <br />
        You will override this lock and be able to contact this lead.
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row>
            <Col xs={'6'}>
              <button
                type={'button'}
                className={'btn btn-light btn-block'}
                onClick={handleCloseOverride}
              >
                Cancel
              </button>
            </Col>
            <Col xs={'6'}>
              <button
                onClick={override}
                className={'btn btn-sigma-primary btn-block'}
              >
                Confirm
              </button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

export default OverrideLockModal;

import { useMutation, useQueryClient } from 'react-query';

import TaskSequences from './TaskSequences';

export const useTaskSequenceDelete = (options) => {
  const queryClient = useQueryClient();
  return useMutation(TaskSequences.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries('task-sequences');
    },
    ...options,
  });
};

export const useTaskSequenceEdit = () => {
  const queryClient = useQueryClient();
  return useMutation(TaskSequences.edit, {
    onSuccess: () => {
      queryClient.invalidateQueries('task-sequences');
    },
  });
};

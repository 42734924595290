export const weekdaySortMap = {
  //   sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

export const sortByWeekday = (a, b) => {
  let dayA = a.weekday.toLowerCase();
  let dayB = b.weekday.toLowerCase();
  return weekdaySortMap[dayA] - weekdaySortMap[dayB];
};

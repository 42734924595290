import { React, useEffect, useState } from 'react';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import {
  Box,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TableHead,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Alert,
  Slider,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import HookForm from '../components/HookForm';
import { PlusIcon, EditIcon, DeleteIcon } from '../theme/icons';
import { LoadingButton } from '@mui/lab';
import { useAiModulesList } from '../../api/aiModules/queries';
import { useAiModuleCreate, useAiModuleDelete } from '../../api/aiModules/mutation';
import { useOrg } from '../../contexts/OrgProvider';
import { fireDialog } from '../components/Dialog';

export default function AIModulesPage() {
  const { data: orgId } = useOrg();
  const { data: aiModule, isLoading, isError, error } = useAiModulesList();

  const onCreate = () => {
    fireDialog((promiseProps) => AddAIModules({ orgId, ...promiseProps }));
  };

  const onDelete = (aiID) => {
    fireDialog((promiseProps) => <DeleteAIModules aiID={aiID} {...promiseProps} />);
  };
  return (
    <Box sx={{ height: '100%' }}>
      <SettingsCard.Main>
        {error?.status && (
          <Alert severity="error">You are not autherized to vist the page.</Alert>
        )}
        {!isLoading ? (
          <>
            <TableContainer sx={{ mb: 2 }}>
              <Table>
                <TableHead>
                  <TableRow size="small">
                    <TableCell>Name</TableCell>
                    <TableCell>LLM</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {aiModule?.items.map((ai) => (
                    <TableRow key={ai.id}>
                      <TableCell>
                        <Typography component={'span'} variant="subtitle3">
                          {ai?.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'span'} variant="subtitle3">
                          {ai?.llm}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Button
                          startIcon={<EditIcon />}
                          size="small"
                          variant="text"
                          color="primary"
                          sx={{ marginRight: 1 }}
                          component={Link}
                          to={`/settings/modules/${ai.id}`}
                        >
                          Edit
                        </Button>
                        <Button
                          startIcon={<DeleteIcon />}
                          size="small"
                          variant="text"
                          color="error"
                          sx={{ marginRight: 1 }}
                          onClick={() => onDelete(ai.id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              startIcon={<PlusIcon />}
              onClick={onCreate}
              sx={{ marginRight: 1 }}
            >
              Add New
            </Button>
          </>
        ) : (
          <CircularProgress size={50} />
        )}
      </SettingsCard.Main>
    </Box>
  );
}

const AddAIModules = ({ isOpen, onReject, onResolve }) => {
  const {
    mutate: createmutate,
    isError,
    isSuccess,
    isLoading,
  } = useAiModuleCreate();
  const [aiModulesName, setAIModulesName] = useState();
  const [aiModulesPrompt, setAIModulesPrompt] = useState();
  const [aiAgentTemprature, setAiAgentTemprature] = useState(0.25);
  const [aiAgentllm, setAiAgentllm] = useState();
  const history = useHistory();

  const handleCreateSubmit = () => {
    createmutate(
      {
        name: aiModulesName,
        prompt: aiModulesPrompt,
        llm: aiAgentllm,
        temperature: aiAgentTemprature,
      },
      {
        onSuccess: (data) => {
          let to = `/settings/modules/${data.id}`;
          history.push(to);
        },
      }
    );
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        onResolve();
      }, 200);
    }
  }, [isError, isSuccess]);

  const defaultValues = {
    name: '',
    prompt: '',
    llm: '',
    temperature: 0.25,
  };

  const useFormProps = {
    defaultValues,
  };

  const handleSliderChange = (value) => {
    setAiAgentTemprature(value);
  };

  return (
    <Box>
      <Dialog open={isOpen} onClose={onReject}>
        <DialogTitle>Create Modules</DialogTitle>
        <HookForm useFormProps={useFormProps} onSubmit={handleCreateSubmit}>
          <DialogContent>
            {isError && (
              <Alert severity="error">
                There was a problem while creating Modules.
              </Alert>
            )}
            {isSuccess && (
              <Alert severity="success">Modules created successfully.</Alert>
            )}
            <Box display="flex" flexDirection="column" rowGap={2}>
              <TextField
                label="Name"
                type="text"
                fullWidth
                value={aiModulesName || ''}
                onChange={(e) => setAIModulesName(e.target.value)}
              />
              <TextField
                label="Prompt"
                type="text"
                multiline
                rows={5}
                value={aiModulesPrompt || ''}
                onChange={(e) => setAIModulesPrompt(e.target.value)}
              />
              <Box display="flex" flexDirection="column">
                <InputLabel>LLM</InputLabel>
                <Select
                  variant={'standard'}
                  value={aiAgentllm || ''}
                  onChange={(e) => setAiAgentllm(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value="gpt35">GPT 3.5</MenuItem>
                  <MenuItem value="gpt4">GPT 4</MenuItem>
                  <MenuItem value="azure_gpt35">Azure GPT 3.5</MenuItem>
                  <MenuItem value="azure_gpt4">Azure GPT 4</MenuItem>
                </Select>
              </Box>
              <Box display="flex" flexDirection="column">
                <InputLabel>Temperature</InputLabel>
                <Slider
                  value={aiAgentTemprature || ''}
                  onChange={(e, value) => handleSliderChange(value)}
                  step={0.01}
                  marks={[
                    { value: 0, label: '0' },
                    { value: 1, label: '1' },
                    { value: 2, label: '2' },
                  ]}
                  min={0}
                  max={2}
                  valueLabelDisplay="auto"
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="text" color="inherit" onClick={onReject}>
              Cancel
            </Button>
            <LoadingButton type="submit" loading={isLoading} disabled={isSuccess}>
              Confirm
            </LoadingButton>
          </DialogActions>
        </HookForm>
      </Dialog>
    </Box>
  );
};

const DeleteAIModules = ({ isOpen, onReject, onResolve, aiID }) => {
  const {
    mutate: aiModulesDelete,
    isLoading: didDeleteloading,
    isError: didDeleteError,
    isSuccess: didDelteSuccess,
  } = useAiModuleDelete();

  useEffect(() => {
    if (didDelteSuccess && !didDeleteError) {
      setTimeout(() => {
        onResolve();
      }, 200);
    }
  }, [didDelteSuccess, didDeleteError]);

  const onSubmit = () => {
    aiModulesDelete({ id: aiID });
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Modules</DialogTitle>
      <DialogContent>
        {didDeleteError && (
          <Alert severity="error">There was a problem while deleting modules.</Alert>
        )}
        {didDelteSuccess && <Alert severity="success">Deleted successfully.</Alert>}
        Are you sure to delete ai modules.
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          loading={didDeleteloading}
          disabled={didDelteSuccess}
          onClick={onSubmit}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

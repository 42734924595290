import React, { useState, useContext, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import ClipLoader from 'react-spinners/ClipLoader';
import Sequence from '../../../clients/Sequence';
import { MdCheckBox } from 'react-icons/md';

const ImportContainer = () => {
  const [isLoading, setIsLoading] = useState();
  const [sequences, setSequences] = useState([]);
  const [sequenceId, setSequenceId] = useState();

  useEffect(() => {
    loadSequences();
  }, []);

  const loadSequences = () => {
    setIsLoading(true);
    Sequence.list().then((data) => {
      // Sort first by locked status, then sort in-place by name
      setSequences(
        data.items
          .sort((a, b) => a.is_locked - b.is_locked)
          .sort((a, b) => {
            if (a.name > b.name) {
              return -1;
            }
            if (b.name > a.name) {
              return 1;
            }
            return 0;
          })
          .reverse()
      );
      setIsLoading(false);
    });
  };

  const updateSequence = (id) => {
    setSequenceId(id);
  };

  const checkmarkIcon = (id) => {
    if (id == sequenceId) {
      return <MdCheckBox size="2rem" class="selected-checkmark" />;
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className={`pt-0 sm:pt-5 px-0 sm:px-2`}>
          <nav aria-label="breadcrumb">
            <ol className="sigma-breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/settings">Settings</Link>
              </li>
              <li className="breadcrumb-item active">Import</li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          Do you need to create a sequence before adding leads?
          <br />
          <Link className="my-3 btn btn-sigma-primary" to="/settings/sequences">
            Create New Sequence
          </Link>
          <br />
          Or choose an already existing sequence.
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className={`mt-4`}>
            <Row className="mx-0 py-4 bg-sigma-light-primary bg-sigma-light-primary leads-header">
              <Col xs={12} className={'text-left p-0'}>
                My Sequences
              </Col>
            </Row>
            {isLoading ? (
              <Row>
                <Col className="text-center p-5">
                  <ClipLoader size={100} color="#34D1B6" />
                </Col>
              </Row>
            ) : (
              <>
                <ListGroup>
                  {sequences.map((sequence) => (
                    <ListGroup.Item
                      action
                      className={`task-item`}
                      key={sequence.id}
                      onClick={() => updateSequence(sequence.id)}
                    >
                      <Row>
                        <Col xs={11} className="my-auto">
                          <span className="h6">{sequence.name}</span>
                        </Col>
                        <Col xs={1} className="my-auto">
                          {checkmarkIcon(sequence.id)}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="mt-3">
          <Link
            to={{
              pathname: '/settings/import/upload',
              state: { taskSequenceId: sequenceId },
            }}
            className={
              sequenceId ? 'btn btn-sigma-primary' : 'btn btn-sigma-primary disabled'
            }
          >
            Continue
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default ImportContainer;

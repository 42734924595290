import { toJSON, toParamString } from '../queryClientConfig';
import Cookies from 'js-cookie';

export default class UserOrganizationConnections {
  static async list(filters = []) {
    return await fetch(
      `${
        process.env.REACT_APP_ORGANIZATION_SERVICE_URL
      }/user_organization_connections?${toParamString(filters)}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async show(id) {
    return await fetch(
      `${process.env.REACT_APP_ORGANIZATION_SERVICE_URL}/user_organization_connections/${id}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async create(userOrganizationConnection) {
    return await fetch(
      `${process.env.REACT_APP_ORGANIZATION_SERVICE_URL}/user_organization_connections`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userOrganizationConnection),
      }
    ).then(toJSON);
  }

  static async edit(userOrganizationConnection) {
    return await fetch(
      `${process.env.REACT_APP_ORGANIZATION_SERVICE_URL}/user_organization_connections/${userOrganizationConnection.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userOrganizationConnection),
      }
    ).then(toJSON);
  }

  static async delete(id) {
    return await fetch(
      `${process.env.REACT_APP_ORGANIZATION_SERVICE_URL}/user_organization_connections/${id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
  }

  static async batchUpdate({ toCreate = [], toDelete = [] }) {
    return await fetch(
      `${process.env.REACT_APP_ORGANIZATION_SERVICE_URL}/user_organization_connections/batch_update`,
      {
        method: 'PATCH',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ to_create: toCreate, to_delete: toDelete }),
      }
    ).then(toJSON);
  }
}

import { GridFooterContainer } from '@mui/x-data-grid';
import TablePagination from '@mui/material/TablePagination';
import CsvDownloadButton from '../../components/CsvDownloadButton';

import { useCsvExportsCreate } from '../../services/downloads/mutations';

const CustomFooter = ({
  rowCount,
  page,
  onPageChange,
  onPageSizeChange,
  pageSize,
  organization_id,
  requested_url,
  isCurrentUserAdmin,
}) => {
  const { mutate: handleSubmitData } = useCsvExportsCreate();

  const handlePageChange = (event, newPage) => {
    onPageChange(newPage);
  };
  const handlePageSizeChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    onPageSizeChange(newPageSize);
  };

  return (
    <GridFooterContainer
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <TablePagination
        component="div"
        count={rowCount}
        page={page}
        rowsPerPage={pageSize}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handlePageSizeChange}
        rowsPerPageOptions={[10, 25, 50]}
        style={{ marginRight: 'auto' }}
      />
      {isCurrentUserAdmin && (
        <CsvDownloadButton
          onClick={() =>
            handleSubmitData({
              organization_id: organization_id,
              requested_url: requested_url,
            })
          }
        />
      )}
    </GridFooterContainer>
  );
};

export default CustomFooter;

import { toJSON } from '../queryClientConfig';
import Cookies from 'js-cookie';

export default class Schedules {
  static async list(organization_id) {
    return await fetch(
      `${process.env.REACT_APP_MESSAGING_API_BASE}/schedules?organization_id=${organization_id}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async show(schedule_id) {
    return await fetch(
      `${process.env.REACT_APP_MESSAGING_API_BASE}/schedules/${schedule_id}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async create(schedule) {
    return await fetch(`${process.env.REACT_APP_MESSAGING_API_BASE}/schedules`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(schedule),
    }).then(toJSON);
  }

  static async edit(schedule) {
    return await fetch(
      `${process.env.REACT_APP_MESSAGING_API_BASE}/schedules/${schedule.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(schedule),
      }
    ).then(toJSON);
  }

  static async delete(schedule_id) {
    return await fetch(
      `${process.env.REACT_APP_MESSAGING_API_BASE}/schedules/${schedule_id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
  }
}

import { useState } from 'react';

export const useFilters = ({ initialFilters }) => {
  const [filterMap, setFilterMap] = useState(initialFilters);
  const setFilterMapValue = (filterKey, newFilterValue) => {
    if (typeof newFilterValue === 'function') {
      setFilterMap((prev) => {
        return {
          ...prev,
          [filterKey]: newFilterValue(prev[filterKey]),
        };
      });
    } else {
      setFilterMap((prev) => ({ ...prev, [filterKey]: newFilterValue }));
    }
  };

  const clearFilterMap = () => setFilterMap(initialFilters);

  const setBatchedFilterValues = (newFilters) => {
    setFilterMap({ ...initialFilters, ...newFilters });
  };

  return {
    filterMap,
    setFilterMapValue,
    setFilterMap,
    clearFilterMap,
    setBatchedFilterValues,
  };
};

import { Box, Button } from '@mui/material';

import HookForm from '../../components/HookForm';

export default function CallForwardingStep({
  handleCollect,
  collectedData,
  handleForwardNavigate,
  handleBackNavigate,
}) {
  const formProps = {
    defaultValues: {
      number: collectedData.callForwarding,
    },
  };

  return (
    <Box mt={3} mb={5}>
      <h4>Call Forwarding</h4>
      <hr />
      <h5>Where do you want to route these calls?</h5>
      <p style={{ color: 'grey', margin: '25px 0' }}>
        Enter an existing phone number where we should forward your calls. Tell us
        which phone should ring when the customer calls your tracking phone number.
        This is typically your primary business phone number. Example: 404-555-1213
      </p>
      <HookForm
        onChange={(e) => handleCollect('callForwarding', e.target.value)}
        useFormProps={formProps}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <HookForm.PhoneNumberField name="number" label="Phone Number" />
        </Box>
      </HookForm>
      <Box display="flex" alignItems="center" justifyContent="space-between" mt={5}>
        <Button onClick={() => handleBackNavigate()}>Back</Button>
        <Button
          disabled={collectedData.callForwarding.length !== 14}
          onClick={() => handleForwardNavigate('numberCallFlow', 'callForwarding')}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

import Cookies from 'js-cookie';
import { toJSON } from '../../../api/utils/utils';

export default class SenderEmailAddresses {
  static async list(filters) {
    const params = new URLSearchParams(filters).toString();
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/sender_email_addresses?${params}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }
}

export const DEFAULT_SCOPE = 'stack';
export const SCOPE_KEY = 'factoryStack';

if (typeof window !== 'undefined') {
  if (!window[SCOPE_KEY]) {
    window[SCOPE_KEY] = {};
  }
}

export const registerContainer = (scope, ref) => {
  window[SCOPE_KEY][scope] = ref;

  return ref;
};

export const unregisterContainer = (scope) => {
  delete window[SCOPE_KEY][scope];
};

export const getContainer = (scope) => window[SCOPE_KEY][scope || DEFAULT_SCOPE];

import { useQuery } from 'react-query';
import { queryKeyFactory } from '../queryClientConfig';
import Targets from './Targets';

export const targetsIdQueryKeys = queryKeyFactory('targets');

export const useTargetsList = (filters = [], options) => {
  return useQuery(targetsIdQueryKeys.list(filters), () => Targets.list(filters), {
    staleTime: 0,
    ...options,
  });
};

export const useTargetsShow = (target_id, options) => {
  return useQuery(
    targetsIdQueryKeys.show({ target_id }),
    () => Targets.show(target_id),
    {
      enabled: !!target_id,
      ...options,
    }
  );
};

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { leadsQueryKeys } from '../services/leads/queries';
import { tasksQueryKeys } from '../services/tasks/queries';
import { useHistory } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { useTeamsList } from '../services/teams/queries';
import { useQueryClient } from 'react-query';
import { useCustomFields } from '../../api/customFields/queries';
import {
  customFieldFormName,
  DATE_TIME_TYPE,
} from '../../api/customFields/customFieldsUtils';
import { useSourcesList } from '../services/sources/queries';
import { useOrg } from '../../contexts/OrgProvider';
import { useUsersCurrent, useUsersList } from '../services/users/queries';
import { useUserOrganizationConnectionsList } from '../services/userOrganizationConnections/queries';
import { useTaskSequencesList } from '../services/taskSequences/queries';
import { formatName } from '../services/textFormatting';
import HookForm from './HookForm';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  createYupHash,
  EMAIL_ADDRESS,
  EMAIL_ADDDRESS_OPTIONAL,
  FIRST_NAME,
  LAST_NAME,
  PHONE_NUMBER,
  PHONE_NUMBER_OPTIONAL,
  SOURCE,
  SEQUENCE,
  TEAM,
  USER,
  MONEY,
} from './HookForm/yupValidations';
import { useOrganizationDefaultsList } from '../services/organizationDefaults/queries';
import { LoadingButton } from '@mui/lab';
import Lead from '../../clients/Lead';
import CustomFieldValueInput from './CustomFieldValueInput';
import { useLeadsCreate } from '../services/leads/mutations';

const SOURCE_FORM_NAME_OPTIONAL = 'sourceFormNameOptional';
const USER_FORM_NAME_OPTIONAL = 'userFormNameOptional';
const NOTE_NAME = 'noteName';

const LeadCreateModal = ({ isOpen, onResolve, onReject }) => {
  const { id: orgId } = useOrg();
  const { data: currentUser } = useUsersCurrent();
  const {
    data: organizationDefaultsList,
    isLoading: isLoadingOrganizationDefaults,
    isError: didFailLoadingOrganizationDefaults,
  } = useOrganizationDefaultsList([['organization_id[]', orgId]], {});
  const [organizationDefaults, setOrganizationDefaults] = useState(null);
  useEffect(() => {
    if (organizationDefaultsList) {
      const data_hash = {};
      organizationDefaultsList.forEach((def) => {
        data_hash[def.key] = def.value;
      });

      setOrganizationDefaults(data_hash);
    }
  }, [organizationDefaultsList]);
  const {
    data: customFields,
    isLoading: isLoadingCustomFields,
    isError: didFailLoadingCustomFields,
  } = useCustomFields([['organization_id[]', orgId]], {});
  const {
    data: sources,
    isLoading: isLoadingSources,
    isError: didFailLoadingSources,
  } = useSourcesList([['organization_id[]', orgId]]);
  const {
    data: userConnections,
    isLoading: isLoadingUserConnections,
    isError: didFailLoadingUserConnections,
  } = useUserOrganizationConnectionsList([
    ['organization_id[]', orgId],
    ['page_size', 1000],
  ]);
  const {
    data: users,
    isLoading: isLoadingUsers,
    isError: didFailLoadingUsers,
  } = useUsersList(
    !!userConnections
      ? userConnections.items?.map((uc) => ['user_id[]', uc.user_id])
      : [],
    {
      enabled: !isLoadingUserConnections && !!userConnections,
    }
  );
  const {
    data: teamsList,
    isLoading: isLoadingTeams,
    isError: didFailLoadingTeams,
  } = useTeamsList([['organization_id[]', orgId]]);
  const {
    data: sequences,
    isLoading: isLoadingSequences,
    isError: didFailLoadingSequences,
  } = useTaskSequencesList([['organization_id[]', orgId]], {});

  const {
    mutate: createLead,
    isLoading: isMutatingLead,
    isSuccess: didSucceedMutatingLead,
    isError: didFailMutatingLead,
  } = useLeadsCreate();

  useEffect(() => {
    if (didSucceedMutatingLead && !didFailMutatingLead) {
      onResolve();
      history.push({ state: 'refreshLeads' });
    }
  }, [didSucceedMutatingLead, didFailMutatingLead]);

  const history = useHistory();
  const queryClient = useQueryClient();

  const formatIsoStringToUTC = (isoString) => {
    if (!isoString) return '';
    const formatted = moment(isoString).utc().format();
    return formatted;
  };

  const handleSave = (formData) => {
    const _lead = {};

    _lead.organization_id = orgId;

    _lead.first_name = formData[FIRST_NAME];
    _lead.last_name = formData[LAST_NAME];
    if (formData[PHONE_NUMBER] || formData[PHONE_NUMBER_OPTIONAL]) {
      _lead.phones = [
        {
          is_primary: true,
          label: 'mobile',
          number: formData[PHONE_NUMBER] || formData[PHONE_NUMBER_OPTIONAL],
        },
      ];
    }
    if (formData[EMAIL_ADDRESS] || formData[EMAIL_ADDDRESS_OPTIONAL]) {
      _lead.email_addresses = [
        {
          is_primary: true,
          email: formData[EMAIL_ADDRESS] || formData[EMAIL_ADDDRESS_OPTIONAL],
        },
      ];
    }
    _lead.source_id = (formData[SOURCE] || formData[SOURCE_FORM_NAME_OPTIONAL])?.id;
    _lead.task_sequence_id = formData[SEQUENCE]?.id;
    _lead.team_id = formData[TEAM]?.id;
    _lead.assigned_user_id = (
      formData[USER] || formData[USER_FORM_NAME_OPTIONAL]
    )?.id;
    if (formData[NOTE_NAME]) {
      _lead.notes = [{ user_id: currentUser.id, value: formData[NOTE_NAME] }];
    }
    if (formData[MONEY]) {
      _lead.opportunities = [
        {
          user_id: currentUser.id,
          value_cents: Math.round(formData[MONEY] * 100),
        },
      ];
    }

    _lead.custom_field_values = customFields.map((field) => {
      let value = formData[customFieldFormName(field)] ?? '';
      if (field.data_type == DATE_TIME_TYPE && value) {
        value = formatIsoStringToUTC(value);
      }
      return {
        custom_field_id: field.id,
        value: value,
      };
    });

    createLead(_lead);
  };

  const orgDefaultIsTrue = (key) => {
    return organizationDefaults && organizationDefaults[key] === 'true';
  };

  // ANCHOR Form params setup

  let defaultValues = {};
  let yupValidations = {};
  if (
    !isLoadingCustomFields &&
    !isLoadingSources &&
    !isLoadingUsers &&
    !isLoadingOrganizationDefaults &&
    !isLoadingTeams &&
    !!customFields &&
    !!organizationDefaults &&
    !!sequences &&
    !!teamsList &&
    !!sources &&
    !!users
  ) {
    const manualYupHash = {};
    defaultValues = {
      [FIRST_NAME]: '',
      [LAST_NAME]: '',
      [SEQUENCE]: sequences[0],
      [TEAM]: teamsList[0],
      [MONEY]: organizationDefaults['opportunity_value_cents'] / 100,
      [NOTE_NAME]: '',
    };
    if (orgDefaultIsTrue('required_phone')) {
      defaultValues[PHONE_NUMBER] = '';
    } else {
      defaultValues[PHONE_NUMBER_OPTIONAL] = '';
    }

    if (orgDefaultIsTrue('required_email')) {
      defaultValues[EMAIL_ADDRESS] = '';
    } else {
      defaultValues[EMAIL_ADDDRESS_OPTIONAL] = '';
    }

    if (orgDefaultIsTrue('required_source')) {
      defaultValues[SOURCE] = '';
    } else {
      defaultValues[SOURCE_FORM_NAME_OPTIONAL] = '';
    }

    if (orgDefaultIsTrue('required_assigned_user')) {
      defaultValues[USER] = '';
    } else {
      defaultValues[USER_FORM_NAME_OPTIONAL] = '';
    }

    customFields.forEach((field) => {
      defaultValues[customFieldFormName(field)] = '';
      if (field.required) {
        manualYupHash[customFieldFormName(field)] = yup
          .string()
          .nullable()
          .required('A value is required');
      }
    });

    yupValidations = yupResolver(
      yup.object({ ...createYupHash(defaultValues), ...manualYupHash })
    );
  }

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Add Lead</DialogTitle>
      {isLoadingCustomFields ||
      isLoadingSequences ||
      isLoadingOrganizationDefaults ||
      isLoadingSources ||
      isLoadingUserConnections ||
      isLoadingUsers ||
      isLoadingTeams ||
      !yupValidations ||
      !defaultValues ? (
        <>
          <DialogContent>
            <Box display="flex" justifyContent="center">
              <ClipLoader size={300} color="#34D1B6" />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="cancel" onClick={onReject}>
              Cancel
            </Button>
            <LoadingButton loading={false} type="submit">
              Confirm
            </LoadingButton>
          </DialogActions>
        </>
      ) : (
        <>
          <HookForm
            onSubmit={handleSave}
            useFormProps={{ defaultValues, resolver: yupValidations }}
            useDirtyFormCheck={false}
          >
            <DialogContent>
              {(didFailLoadingCustomFields ||
                didFailLoadingOrganizationDefaults ||
                didFailLoadingSequences ||
                didFailLoadingSources ||
                didFailLoadingTeams ||
                didFailLoadingUserConnections ||
                didFailLoadingUsers) && (
                <Alert severity="error">
                  There was a problem while loading. Try again.
                </Alert>
              )}
              {didFailMutatingLead && (
                <Alert severity="error">
                  There was a problem creating your lead. Try again.
                </Alert>
              )}
              <Box
                display="flex"
                flexDirection="column"
                rowGap={2}
                sx={{ pt: 2, px: 0, width: '100%' }}
              >
                <Box display="grid" direction="row" rowGap={2}>
                  <HookForm.Autocomplete
                    options={sequences}
                    name={SEQUENCE}
                    label="Sequence"
                    required={true}
                  />

                  <HookForm.TextField label="First Name" name={FIRST_NAME} />

                  <HookForm.TextField label="Last Name" name={LAST_NAME} />

                  <HookForm.TextField
                    name={
                      orgDefaultIsTrue('required_email')
                        ? EMAIL_ADDRESS
                        : EMAIL_ADDDRESS_OPTIONAL
                    }
                    label="Email"
                  />

                  <HookForm.PhoneNumberField
                    name={
                      orgDefaultIsTrue('required_phone')
                        ? PHONE_NUMBER
                        : PHONE_NUMBER_OPTIONAL
                    }
                    label="Phone Number"
                  />

                  <HookForm.Autocomplete
                    options={sources}
                    name={
                      orgDefaultIsTrue('required_source')
                        ? SOURCE
                        : SOURCE_FORM_NAME_OPTIONAL
                    }
                    label="Source"
                    required={orgDefaultIsTrue('required_source')}
                  />

                  <HookForm.Autocomplete
                    options={teamsList}
                    name={TEAM}
                    label="Team"
                    required={true}
                  />

                  <HookForm.Autocomplete
                    options={users}
                    name={
                      orgDefaultIsTrue('required_assigned_user')
                        ? USER
                        : USER_FORM_NAME_OPTIONAL
                    }
                    label="Assigned User"
                    required={orgDefaultIsTrue('required_assigned_user')}
                    getOptionLabel={(item) =>
                      !!item ? formatName(item.first_name, item.last_name) : ''
                    }
                  />

                  <HookForm.TextField
                    name={MONEY}
                    label="Value"
                    type="number"
                    inputProps={{
                      step: 0.01,
                      min: 0,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />

                  {customFields?.map((field) => {
                    return <CustomFieldValueInput customField={field} />;
                  })}

                  <HookForm.TextField
                    name={NOTE_NAME}
                    label="Note"
                    multiline
                    rows={3}
                  />
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="cancel" onClick={onReject}>
                Cancel
              </Button>
              <LoadingButton loading={isMutatingLead} type="submit">
                Confirm
              </LoadingButton>
            </DialogActions>
          </HookForm>
        </>
      )}
    </Dialog>
  );
};

export default LeadCreateModal;

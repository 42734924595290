import { useMutation, useQueryClient } from 'react-query';
import { mutationKeyFactory } from '../queryClientConfig';
import Enterprises from './Enterprises';
import { enterprisesQueryKeys } from './queries';

export const enterprisesMutationKeys = {
  ...mutationKeyFactory('enterprises'),
};

export const useEnterprisesEdit = () => {
  const queryClient = useQueryClient();
  return useMutation(Enterprises.edit, {
    mutationKey: enterprisesMutationKeys.edit(),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(
        enterprisesQueryKeys.shows({ id: variables.id })
      );
    },
  });
};

import { useQuery } from 'react-query';

import { useOrg } from '../../contexts/OrgProvider';
import { ONE_HOUR } from '../../MUI/services/queryClientConfig';
import SlingshotFields from './SlingshotFields';

export const useSlingshotFields = (params = {}, options = {}) => {
  const { id } = useOrg();
  return useQuery(
    ['slingshot_fields'],
    (params) => SlingshotFields.list({ ...params, organization_id: id }),
    {
      staleTime: ONE_HOUR,
      ...options,
    }
  );
};

import { useMutation, useQueryClient } from 'react-query';

import { useOrg } from '../../../contexts/OrgProvider';
import Targets from './Targets';

export const useTargetsEdit = () => {
  const queryClient = useQueryClient();
  return useMutation(Targets.edit, {
    mutationKey: ['targets', 'edit'],
    onSuccess: () => {
      queryClient.invalidateQueries('targets');
    },
  });
};

export const useTargetsDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(Targets.delete, {
    mutationKey: ['targets', 'delete'],
    onSuccess: () => {
      queryClient.invalidateQueries('targets');
    },
  });
};

export const useTargetsCreate = () => {
  const queryClient = useQueryClient();
  const { id } = useOrg();
  return useMutation(
    (params) =>
      Targets.create({
        ...params,
        organization_id: String(id),
      }),
    {
      mutationKey: ['targets', 'create'],
      onSuccess: () => {
        queryClient.invalidateQueries('targets');
      },
    }
  );
};

import React, { useState, useEffect, useRef } from 'react';
import { Modal, Form, Row } from 'react-bootstrap';
import Text from '../common/Text/Text';
import { MailMergeDropdown } from './MailMergeDropdown';
import TemplateModalFooter from './TemplateModalFooter';

const TextTemplatesModal = ({
  showTextTemplatesModal,
  setShowTextTemplatesModal,
  templates,
  action,
  editTemplate,
  createTemplate,
  isNewTemplate,
  setIsNewTemplate,
}) => {
  const [selectedTemplateId, setSelectedId] = useState();
  const [name, setName] = useState();
  const [content, setContent] = useState('');
  const [isCreateTemplate, setIsCreateTemplate] = useState(false);
  const [messageHasFocus, setMessageHasFocus] = useState(false);
  const messageRef = useRef(null);
  const [isSaveAsNew, setIsSaveAsNew] = useState(false);
  const [saveAsNewName, setSaveAsNewName] = useState('');

  useEffect(() => {
    if (selectedTemplateId === 'new') {
      setContent('');
      setName('');
      setIsCreateTemplate(true);
    } else {
      setIsCreateTemplate(false);
      const selectedTemplate = templates.find(({ id }) => id === selectedTemplateId);
      if (selectedTemplate) {
        setContent(selectedTemplate.content);
        setName(selectedTemplate.name);
      }
    }
  }, [selectedTemplateId]);

  useEffect(() => {
    if (isNewTemplate) setSelectedId('new');
    else setSelectedId(action.message_template_id);
  }, [isNewTemplate]);

  const isButtonDisabled = () => {
    return name?.trim() === '' || content?.trim() === '';
  };

  const closeModal = () => {
    setShowTextTemplatesModal(false);
    setIsNewTemplate(false);
  };

  const selectMailMergeFieldHandler = (e) => {
    setContent((prev) => {
      return `${prev.slice(0, messageRef.current.selectionStart)}${e} ${prev.slice(
        messageRef.current.selectionStart
      )}`;
    });
    setMessageHasFocus(false);
  };

  const renderSaveAsNewForm = () => {
    return (
      <>
        <Form.Label>
          <Text weight="bold">New Template Name</Text>
        </Form.Label>
        <Form.Control
          id="new-template-name"
          type="text"
          maxLength={127}
          placeholder={'Template Name'}
          value={saveAsNewName}
          onChange={(e) => setSaveAsNewName(e.target.value)}
        />
      </>
    );
  };

  return (
    <Modal
      show={showTextTemplatesModal}
      onHide={closeModal}
      className="modal-sigma"
      dialogClassName="template-modal"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title className="w-100 text-center">Edit Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isSaveAsNew ? (
          renderSaveAsNewForm()
        ) : (
          <>
            <Form.Label>
              <Text weight="bold">Template</Text>
            </Form.Label>
            <Form.Control
              id="template-select"
              as="select"
              onChange={(e) => setSelectedId(e.target.value)}
              value={selectedTemplateId}
            >
              <option key="new" value="new">
                Create New Template
              </option>
              {templates.map((t) => (
                <option key={t.id} value={t.id}>
                  {t.name}
                </option>
              ))}
            </Form.Control>
            {isCreateTemplate && (
              <>
                <Form.Label>
                  <Text weight="bold">Template Name</Text>
                </Form.Label>
                <Form.Control
                  id="subject"
                  type="text"
                  maxLength={127}
                  placeholder={'Template Name'}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </>
            )}
            <Row noGutters className="my-2 justify-content-between">
              <Form.Label className="mb-0 d-flex align-items-end">
                <Text weight="bold">Message</Text>
              </Form.Label>
              <MailMergeDropdown
                toggleClassName="ml-2 btn-light px-2"
                onSelect={selectMailMergeFieldHandler}
                disabled={!messageHasFocus}
              />
            </Row>
            <Form.Control
              onFocus={() => setMessageHasFocus(true)}
              onBlur={(e) => {
                if (e.relatedTarget?.id !== 'token-button')
                  setMessageHasFocus(false);
              }}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              as="textarea"
              rows={8}
              type="text"
              placeholder={'Template Message'}
              maxLength={4095}
              ref={messageRef}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <TemplateModalFooter
          isSaveAsNew={isSaveAsNew}
          setIsSaveAsNew={setIsSaveAsNew}
          isCreateTemplate={isCreateTemplate}
          isButtonDisabled={isButtonDisabled}
          saveAsNewName={saveAsNewName}
          setSaveAsNewName={setSaveAsNewName}
          closeModal={closeModal}
          saveAsNewTemplateHelper={() =>
            createTemplate({
              name: saveAsNewName,
              content,
            })
          }
          saveTemplateChangesHelper={() =>
            editTemplate({ name, content, selectedTemplateId })
          }
          createNewTemplateHelper={() => createTemplate({ name, content })}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default TextTemplatesModal;

import { Button, Typography, List, ListItem } from '@mui/material';
import { EditIcon, DeleteIcon, ShareIcon } from '../theme/icons';
import { styled } from '@mui/system';

const ListView = ({ children, ...other }) => {
  return <ListViewStyle {...other}>{children}</ListViewStyle>;
};
export default ListView;

const Item = ({ children }) => (
  <ListViewItemStyle sx={{}}>{children}</ListViewItemStyle>
);

const HeaderText = ({ children, colWidth }) => (
  <Typography
    noWrap
    textTransform="capitalize"
    sx={{ flexBasis: colWidth ?? 0 }}
    variant="body2"
    fontWeight={500}
  >
    {children}
  </Typography>
);

const Text = ({ children, colWidth, ...other }) => (
  <Typography noWrap sx={{ flexBasis: colWidth ?? 0 }} variant="body2" {...other}>
    {children}
  </Typography>
);

const EditButton = ({ colWidth, onClick, ...other }) => (
  <Button
    sx={{ flexBasis: colWidth ?? 0 }}
    startIcon={<EditIcon />}
    variant="text"
    onClick={onClick}
    {...other}
  >
    Edit
  </Button>
);

const DeleteButton = ({ colWidth, onClick, ...other }) => (
  <Button
    sx={{ flexBasis: colWidth ?? 0 }}
    startIcon={<DeleteIcon />}
    variant="text"
    color="error"
    onClick={onClick}
    {...other}
  >
    Delete
  </Button>
);

const ShareButton = ({ colWidth, onClick, ...other }) => (
  <Button
    sx={{ flexBasis: colWidth ?? 0 }}
    startIcon={<ShareIcon />}
    variant="text"
    onClick={onClick}
    {...other}
  >
    Share
  </Button>
);

ListView.Item = Item;
ListView.EditButton = EditButton;
ListView.DeleteButton = DeleteButton;
ListView.ShareButton = ShareButton;
ListView.HeaderText = HeaderText;
ListView.Text = Text;

export const ListViewStyle = styled(List)(({ theme }) => ({
  '& .MuiListItem-root': {
    padding: 0,
    minHeight: 36,
    alignItems: 'center',
  },
  // emotion throws an error if last-child is used...
  '& li:last-of-type': {
    border: 0,
  },
}));

export const ListViewItemStyle = styled(ListItem)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

import React, { useState, useEffect } from 'react';
import Image from 'react-image-enlarger';
import Cookies from 'js-cookie';
import { Skeleton } from '@mui/material';

const ImageEnlarger = ({ src }) => {
  const [zoomed, setZoomed] = useState(false);
  const [signedUrl, setSignedUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(src, {
      headers: {
        mode: 'cors',
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
      },
    }).then((response) => {
      setIsLoading(false);
      setSignedUrl(response.url);
    });
  }, [src]);

  if (isLoading) {
    return <Skeleton height={100} width={100} sx={{ m: 3 }} />;
  }

  return (
    <div style={{ margin: '0.25rem' }}>
      <Image
        style={{ width: '200px', height: 'auto' }}
        zoomed={zoomed}
        src={signedUrl}
        onClick={() => setZoomed(true)}
        onRequestClose={() => setZoomed(false)}
      />
    </div>
  );
};

export default ImageEnlarger;

import Cookies from 'js-cookie';
import { getActiveOrg } from '../util/UserUtils';

export default class Sequence {
  static async create(sequence) {
    sequence['organization_id'] = getActiveOrg();
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/task_sequences`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(sequence),
      }
    );
    return response.json();
  }

  static async list() {
    const response = await fetch(
      `${
        process.env.REACT_APP_API_BASE
      }/task_sequences?organization_id=${getActiveOrg()}&page_size=1000&order=name`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    );
    return response.json();
  }

  static async show(id) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/task_sequences/${id}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    );
    return response.json();
  }

  static async edit(task_sequence) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/task_sequences/${task_sequence.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(task_sequence),
      }
    );
    return response.json();
  }

  static async delete(id, newTaskSequenceId) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/task_sequences/${id}?new_task_sequence_id=${newTaskSequenceId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    return response;
  }
}

import { useAgentsShow } from '../services/agents/queries';
import {
  useSchedulesDelete,
  useSchedulesEdit,
  useSchedulesCreate,
} from '../services/schedules/mutations';
import { useAgentsEdit } from '../services/agents/mutations';
import {
  Button,
  Box,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Alert,
} from '@mui/material';
import TimePicker from '@mui/lab/TimePicker';
import HookForm from '../components/HookForm';
import { PlusIcon, SaveIcon } from '../theme/icons';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { Redirect, useParams } from 'react-router-dom';
import {
  FIRST_NAME,
  LAST_NAME,
  PHONE_NUMBER,
  TIMEOUT_SECONDS,
  createYupResolver,
} from '../components/HookForm/yupValidations';
import { useConditionalUseFormProps } from '../components/HookForm/hooks';
import { FIELD_WIDTH } from '../theme/fixedValues';
import { useState } from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterMoment from '@mui/lab/AdapterMoment';
import moment from 'moment';
import ListView from '../components/ListView';
import { fireDialog } from '../components/Dialog';
import { sortByWeekday, weekdaySortMap } from '../services/schedules/schedulesUtils';
import LoadingButton from '@mui/lab/LoadingButton';
import parsePhoneNumber from 'libphonenumber-js';
import { toUtcTimestamp } from '../services/dateTime';
import { useOrg } from '../../contexts/OrgProvider';

const WEEKDAY_WIDTH = 200;
const TIME_WIDTH = 100;

const EXTENSTION = 'extension';
const IS_TEXTABLE = 'is_textable';

const AgentsShowSettingsPage = () => {
  const { agentId } = useParams();
  const {
    data: agent,
    isLoading: isLoadingAgent,
    isError: isAgentsShowError,
  } = useAgentsShow(agentId);
  const {
    mutate: editAgent,
    isLoading: isEditingAgent,
    isError: isErrorEditingAgent,
    isSuccess,
    reset,
  } = useAgentsEdit();
  const { id: orgId } = useOrg();

  const formatPhoneValue = (phone) => {
    const phoneNumber = parsePhoneNumber(phone);
    if (phoneNumber) {
      return phoneNumber.formatNational();
    }
    return phone;
  };

  const defaultValues = {
    [FIRST_NAME]: agent?.first_name,
    [LAST_NAME]: agent?.last_name,
    [PHONE_NUMBER]: formatPhoneValue(agent?.phone ?? ''),
    [TIMEOUT_SECONDS]: agent?.timeout_seconds ?? null,
    [EXTENSTION]: agent?.extension ?? '',
    [IS_TEXTABLE]: agent?.is_textable ?? false,
  };

  const onAgentFormSubmit = (formData) => {
    editAgent(
      {
        ...agent,
        first_name: formData[FIRST_NAME],
        last_name: formData[LAST_NAME],
        phone: formData[PHONE_NUMBER],
        timeout_seconds: formData[TIMEOUT_SECONDS],
        extension: formData[EXTENSTION],
        is_textable: formData[IS_TEXTABLE],
      },
      {
        onSuccess: () => {
          setTimeout(() => {
            reset();
          }, 5000);
        },
      }
    );
  };

  const useFormProps = useConditionalUseFormProps(
    {
      defaultValues,
      resolver: createYupResolver(defaultValues),
    },
    !!agent
  );

  const handleEditScheduleClick = (schedule) => {
    fireDialog((promiseProps) => EditScheduleDialog({ schedule, ...promiseProps }));
  };
  const handleDeleteScheduleClick = (id) => {
    fireDialog((promiseProps) => DeleteScheduleDialog({ id, ...promiseProps }));
  };
  const handleCreateScheduleClick = () => {
    fireDialog((promiseProps) =>
      CreateScheduleDialog({ agentId: agent.id, ...promiseProps })
    );
  };

  if (String(orgId) !== String(agent?.organization_id) && !!agent) {
    return <Redirect to="/settings/teams" />;
  }

  return (
    <SettingsCard showLoading={isLoadingAgent}>
      {isAgentsShowError ? (
        <Alert severity="error">
          There was a problem loading this Active Call Notification phone. Refresh
          the page to try again.
        </Alert>
      ) : (
        <>
          <SettingsCard.Header>{agent?.first_name}</SettingsCard.Header>
          <SettingsCard.SubHeader>
            Manage this Active Call Notification phone's information
          </SettingsCard.SubHeader>
          <SettingsCard.Main>
            {isSuccess && (
              <Alert severity="success">
                Your changes to this schedule were saved successfully.
              </Alert>
            )}
            {isErrorEditingAgent && (
              <Alert severity="error">
                There was a problem saving your changes. Try again.
              </Alert>
            )}
            <HookForm useFormProps={useFormProps} onSubmit={onAgentFormSubmit}>
              <Box
                display="grid"
                direction="column"
                rowGap={2}
                sx={{ width: FIELD_WIDTH, mb: 1 }}
              >
                <HookForm.TextField name={FIRST_NAME} label="First Name" />
                <HookForm.TextField name={LAST_NAME} label="Last Name" />
                <HookForm.PhoneNumberField
                  name={PHONE_NUMBER}
                  label="Phone Number"
                />
                <HookForm.TextField
                  label="Max Dial Time"
                  type="number"
                  name={TIMEOUT_SECONDS}
                />
              </Box>
              <InputLabel shrink={true} sx={{ ml: 2 }}>
                How long the line rings before hanging up.
              </InputLabel>
              <Box
                display="grid"
                direction="column"
                rowGap={2}
                sx={{ width: FIELD_WIDTH, mb: 1 }}
              >
                <HookForm.TextField
                  name={EXTENSTION}
                  label="Ext"
                  sx={{ width: 90 }}
                />
              </Box>
              <InputLabel shrink={true} sx={{ ml: 2 }}>
                One 'w' will wait a half second before the next digit.
              </InputLabel>
              <HookForm.Switch
                name={IS_TEXTABLE}
                label="Receives Active Call Notification Texts"
              />
              <InputLabel shrink={true} sx={{ ml: 2 }}>
                Enable or disable Active Call Notification texts to this phone
              </InputLabel>
              <LoadingButton
                loadingPosition="start"
                loading={isEditingAgent}
                startIcon={<SaveIcon />}
                disabled={isEditingAgent}
                type="submit"
              >
                Save
              </LoadingButton>
            </HookForm>
          </SettingsCard.Main>
          <SettingsCard.Divider />
          <SettingsCard.Header>Schedule</SettingsCard.Header>
          <ListView sx={{ mb: 1 }}>
            <ListView.Item>
              <ListView.HeaderText colWidth={WEEKDAY_WIDTH}>
                Weekday
              </ListView.HeaderText>
              <ListView.HeaderText colWidth={TIME_WIDTH}>
                Start Time
              </ListView.HeaderText>
              <ListView.HeaderText colWidth={TIME_WIDTH}>
                End Time
              </ListView.HeaderText>
            </ListView.Item>
            {agent?.schedules.sort(sortByWeekday).map((schedule) => (
              <ListView.Item key={schedule.id}>
                <ListView.Text colWidth={WEEKDAY_WIDTH} textTransform={'capitalize'}>
                  {schedule.weekday}
                </ListView.Text>
                <ListView.Text colWidth={TIME_WIDTH} textTransform={'capitalize'}>
                  {toUtcTimestamp(schedule.start)}
                </ListView.Text>
                <ListView.Text colWidth={TIME_WIDTH} textTransform={'capitalize'}>
                  {toUtcTimestamp(schedule.end)}
                </ListView.Text>
                <ListView.EditButton
                  onClick={() => handleEditScheduleClick(schedule)}
                />
                <ListView.DeleteButton
                  onClick={() => handleDeleteScheduleClick(schedule.id)}
                />
              </ListView.Item>
            ))}
          </ListView>
          <Button startIcon={<PlusIcon />} onClick={handleCreateScheduleClick}>
            Add New
          </Button>
        </>
      )}
    </SettingsCard>
  );
};

export default AgentsShowSettingsPage;

// edit schedule --------------------------------------------------------
const EditScheduleDialog = ({ isOpen, onResolve, onReject, schedule }) => {
  const { mutate: editSchedule, isLoading, isError } = useSchedulesEdit();
  const [selectedStart, setSelectedStart] = useState(moment(schedule.start).utc());
  const [selectedEnd, setSelectedEnd] = useState(moment(schedule.end).utc());
  const [selectedWeekday, setSelectedWeekday] = useState(schedule.weekday);

  const handleEditScheduleSubmit = () => {
    editSchedule(
      {
        ...schedule,
        weekday: selectedWeekday,
        start: selectedStart,
        end: selectedEnd,
      },
      {
        onSuccess: () => {
          onResolve();
        },
      }
    );
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Edit Schedule</DialogTitle>
      <DialogContent>
        {isError && (
          <Alert severity="error">
            There was a problem creating your schedule. Try again.
          </Alert>
        )}
        <Box
          display="flex"
          flexDirection="column"
          rowGap={2}
          sx={{ pt: 2, px: 0, width: '100%', maxWidth: 200 }}
        >
          <FormControl>
            <InputLabel id="weekday-select-label">Weekday</InputLabel>
            <Select
              sx={{
                '& .MuiSelect-select': { p: 1 },
              }}
              labelId="weekday-select-label"
              label="Weekday"
              id="weekday-select"
              value={selectedWeekday}
              onChange={(e) => setSelectedWeekday(e.target.value)}
            >
              {Object.keys(weekdaySortMap).map((day) => (
                <MenuItem key={day} value={day}>
                  <Typography textTransform="capitalize">{day}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
              label="Start Time"
              value={selectedStart}
              maxTime={selectedEnd}
              onChange={(newTime) => setSelectedStart(newTime)}
              renderInput={(params) => (
                <TextField sx={{ '& input': { p: 1 } }} {...params} />
              )}
            />
            <TimePicker
              label="End Time"
              value={selectedEnd}
              minTime={selectedStart}
              onChange={(newTime) => setSelectedEnd(newTime)}
              renderInput={(params) => (
                <TextField sx={{ '& input': { p: 1 } }} {...params} />
              )}
            />
          </LocalizationProvider>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          disabled={isLoading}
          onClick={handleEditScheduleSubmit}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

// delete schedule --------------------------------------------------------
const DeleteScheduleDialog = ({ isOpen, onResolve, onReject, id }) => {
  const { mutate: deleteSchedule, isLoading, isError } = useSchedulesDelete();
  const handleDeleteScheduleSubmit = () => {
    deleteSchedule(id, {
      onSuccess: () => {
        onResolve();
      },
    });
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Delete Schedule</DialogTitle>
      <DialogContent>
        {isError && (
          <Alert severity="error">
            There was a problem creating the schedule. Try again.
          </Alert>
        )}
        Are you sure you would like to delete this schedule?
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          disabled={isLoading}
          onClick={handleDeleteScheduleSubmit}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
// create schedule --------------------------------------------------------
const CreateScheduleDialog = ({ isOpen, onResolve, onReject, agentId }) => {
  const { mutate: createSchedule, isLoading, isError } = useSchedulesCreate();
  const [selectedWeekday, setSelectedWeekday] = useState('monday');
  const [selectedStart, setSelectedStart] = useState(
    moment().utc().hours(8).minutes(0)
  );
  const [selectedEnd, setSelectedEnd] = useState(
    moment().utc().hours(17).minutes(0)
  );
  const handleCreateScheduleSubmit = () => {
    createSchedule(
      {
        agent_id: agentId,
        weekday: selectedWeekday,
        start: selectedStart,
        end: selectedEnd,
      },
      {
        onSuccess: () => {
          onResolve();
        },
      }
    );
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Add Schedule</DialogTitle>
      <DialogContent>
        {isError && (
          <Alert severity="error">
            There was a problem creating the schedule. Try again.
          </Alert>
        )}
        <Box
          display="flex"
          flexDirection="column"
          rowGap={2}
          sx={{ pt: 2, px: 0, width: '100%', maxWidth: 200 }}
        >
          <FormControl>
            <InputLabel id="weekday-select-label">Weekday</InputLabel>
            <Select
              sx={{
                '& .MuiSelect-select': { p: 1 },
              }}
              labelId="weekday-select-label"
              label="Weekday"
              id="weekday-select"
              value={selectedWeekday}
              onChange={(e) => setSelectedWeekday(e.target.value)}
            >
              {Object.keys(weekdaySortMap).map((day) => (
                <MenuItem key={day} value={day}>
                  <Typography textTransform="capitalize">{day}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
              label="Start Time"
              value={selectedStart}
              maxTime={selectedEnd}
              onChange={(newTime) => setSelectedStart(newTime)}
              renderInput={(params) => (
                <TextField sx={{ '& input': { p: 1 } }} {...params} />
              )}
            />
            <TimePicker
              label="End Time"
              value={selectedEnd}
              minTime={selectedStart}
              onChange={(newTime) => setSelectedEnd(newTime)}
              renderInput={(params) => (
                <TextField sx={{ '& input': { p: 1 } }} {...params} />
              )}
            />
          </LocalizationProvider>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          disabled={isLoading}
          onClick={handleCreateScheduleSubmit}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import {
  customFieldFormName,
  DATE_TIME_TYPE,
  FREESOLO_OPTION_TYPES,
  OPTION_TYPES,
} from '../../api/customFields/customFieldsUtils';
import HookForm from './HookForm';
import { useFormContext } from 'react-hook-form';

const CustomFieldValueInput = ({ customField }) => {
  const { setValue } = useFormContext();
  return (
    <>
      {customField.data_type == DATE_TIME_TYPE && (
        <HookForm.TextField
          type="datetime-local"
          name={customFieldFormName(customField)}
          label={customField.name}
          InputLabelProps={{ shrink: true }}
        />
      )}
      {customField.data_type == 'string' && (
        <HookForm.TextField
          name={customFieldFormName(customField)}
          label={customField.name}
        />
      )}
      {OPTION_TYPES.includes(customField.data_type) && (
        <HookForm.Autocomplete
          name={customFieldFormName(customField)}
          label={customField.name}
          required={customField.required === true}
          onBlur={(e) => {
            if (FREESOLO_OPTION_TYPES.includes(customField.data_type)) {
              setValue(name, e.target.value, {
                shouldDirty: true,
                shouldValidate: true,
              });
            }
          }}
          freeSolo={FREESOLO_OPTION_TYPES.includes(customField.data_type)}
          getOptionLabel={(o) => o || ''}
          options={customField.options_data ?? []}
          isOptionEqualToValue={(option, value) => {
            return option && value && option === value;
          }}
        />
      )}
    </>
  );
};

export default CustomFieldValueInput;

import { useQuery, useQueryClient } from 'react-query';

import { useOrg } from '../../contexts/OrgProvider';
import { ONE_HOUR } from '../../MUI/services/queryClientConfig';
import Teams from './Teams';

export const useTeamsList = (options) => {
  const { id } = useOrg();
  return useQuery(['teams'], () => Teams.list({ organization_id: id }), {
    staleTime: ONE_HOUR,
    ...options,
  });
};

export const useTeamsShow = (teamId, options) => {
  const queryClient = useQueryClient();
  return useQuery(['teams', teamId], () => Teams.show(teamId), {
    staleTime: ONE_HOUR,
    initialData: () => {
      let cache = queryClient.getQueryData(['teams', teamId]);
      if (cache) {
        return;
      }
      return queryClient.getQueryData('teams')?.find((d) => d.id == teamId);
    },
    ...options,
  });
};

import { useQuery } from 'react-query';
import { queryKeyFactory } from '../queryClientConfig';
import CallRecordings from './CallRecordings';

export const callRecordingsIdQueryKeys = queryKeyFactory('callstracking');

export const useCallRecordingsShow = (recording_id, options) => {
  return useQuery(
    callRecordingsIdQueryKeys.show({ recording_id }),
    () => CallRecordings.show(recording_id),
    {
      enabled: !!recording_id,
      ...options,
    }
  );
};

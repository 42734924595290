import { useQuery } from 'react-query';
import { queryKeyFactory } from '../queryClientConfig';
import Calls from './Calls';
import PhonePools from './PhonePools';

export const callsIdQueryKeys = queryKeyFactory('callstracking');
export const phonePoolsIdQueryKeys = queryKeyFactory('phonePools');

export const useCallsList = (filters, options) => {
  return useQuery(callsIdQueryKeys.list(filters), () => Calls.list(filters), {
    staleTime: 0,
    ...options,
  });
};

export const useCallsShow = (call_id, options) => {
  return useQuery(callsIdQueryKeys.show({ call_id }), () => Calls.show(call_id), {
    enabled: !!call_id,
    ...options,
  });
};

export const usePhonePoolsList = (params, options) => {
  return useQuery(
    phonePoolsIdQueryKeys.list(params),
    () => {
      const filters = [['page_size', 10000], ...params];
      return PhonePools.list(filters);
    },
    {
      staleTime: 0,
      ...options,
    }
  );
};

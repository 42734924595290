import { useState } from 'react';
import { TextareaAutosize, Box, Button, Typography } from '@mui/material';

export const VoiceMail = ({
  handleMutateComponentListByParametersInUI,
  id,
  parameters,
  getUpdatedInputParams,
}) => {
  const [inputParameters, setInputParameters] = useState(
    getUpdatedInputParams(id)?.parameters || parameters
  );

  const handleChange = (e) => {
    let value;
    if (e.target.name === 'file') {
      value = e.target.files[0];
    } else {
      value = e.target.value;
    }

    setInputParameters({
      ...inputParameters,
      [e.target.name]: value,
    });
    handleMutateComponentListByParametersInUI(
      { ...inputParameters, [e.target.name]: value },
      id
    );
  };

  return (
    <Box mt={2}>
      <Box>
        <Box display={'flex'} alignItems={'center'}>
          <Button sx={{ marginRight: '20px' }}>
            <label style={{ margin: '0', cursor: 'pointer' }} htmlFor="file">
              Select Your Audio File
            </label>
          </Button>
          {inputParameters.file && (
            <Typography>{inputParameters.file?.name}</Typography>
          )}
        </Box>

        <input
          onChange={handleChange}
          id="file"
          name="file"
          style={{ visibility: 'hidden', display: 'none' }}
          accept="audio/*"
          type="file"
        />
      </Box>
      <br />
      <TextareaAutosize
        minRows={5}
        placeholder={'Type your message here'}
        style={{ width: '300px', marginTop: '20px' }}
        value={inputParameters.message}
        name="message"
        onChange={handleChange}
      />
    </Box>
  );
};

import { useQuery } from 'react-query';
import { ONE_HOUR, queryKeyFactory } from '../queryClientConfig';
import SenderEmailAddresses from './SenderEmailAddresses';

export const senderEmailAddressesQueryKeys = queryKeyFactory(
  'sender-email-addresses'
);

export const useSenderEmailAddressesList = (user_id) => {
  const filters = { user_id };
  return useQuery(
    senderEmailAddressesQueryKeys.list(filters),
    () => SenderEmailAddresses.list({ ...filters }),
    {
      staleTime: ONE_HOUR,
    }
  );
};

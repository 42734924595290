import Cookies from 'js-cookie';
import { toJSON } from '../../MUI/services/queryClientConfig';

export default class AIMappigs {
  static async create(params) {
    return await fetch(`${process.env.REACT_APP_IVR}/mappings`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    }).then(toJSON);
  }

  static async list() {
    return await fetch(`${process.env.REACT_APP_IVR}/mappings`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(toJSON);
  }

  static async show(moduleID) {
    return await fetch(`${process.env.REACT_APP_IVR}/mappings/${moduleID}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(toJSON);
  }

  static async edit(params) {
    return await fetch(`${process.env.REACT_APP_IVR}/mappings/${params.id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    }).then(toJSON);
  }

  static async delete(params) {
    return await fetch(`${process.env.REACT_APP_IVR}/mappings/${params}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
}

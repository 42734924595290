export default class TwilioToken {
  static async create() {
    const response = await fetch(
      `${process.env.REACT_APP_TWILIO_ADAPTER}/get-token`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: JSON.stringify({}),
      }
    );
    return response.json();
  }
}

import { Row, Col } from 'react-bootstrap';

export default function PhoneKeypad({ sendDigitClick }) {
  return (
    <div>
      <Row className={'mt-3'}>
        <Col className={'text-center'} xs={{ span: 2, offset: 2 }}>
          <button
            onClick={() => sendDigitClick('1')}
            className="btn-sigma-circle-small"
          >
            1
          </button>
        </Col>
        <Col className={'text-center'} xs={4}>
          <button
            onClick={() => sendDigitClick('2')}
            className="btn-sigma-circle-small"
          >
            2
          </button>
        </Col>
        <Col className={'text-center'} xs={2}>
          <button
            onClick={() => sendDigitClick('3')}
            className="btn-sigma-circle-small"
          >
            3
          </button>
        </Col>
      </Row>
      <Row className={'mt-3'}>
        <Col className={'text-center'} xs={{ span: 2, offset: 2 }}>
          <button
            onClick={() => sendDigitClick('4')}
            className="btn-sigma-circle-small"
          >
            4
          </button>
        </Col>
        <Col className={'text-center'} xs={4}>
          <button
            onClick={() => sendDigitClick('5')}
            className="btn-sigma-circle-small"
          >
            5
          </button>
        </Col>
        <Col className={'text-center'} xs={2}>
          <button
            onClick={() => sendDigitClick('6')}
            className="btn-sigma-circle-small"
          >
            6
          </button>
        </Col>
      </Row>
      <Row className={'mt-3'}>
        <Col className={'text-center'} xs={{ span: 2, offset: 2 }}>
          <button
            onClick={() => sendDigitClick('7')}
            className="btn-sigma-circle-small"
          >
            7
          </button>
        </Col>
        <Col className={'text-center'} xs={4}>
          <button
            onClick={() => sendDigitClick('8')}
            className="btn-sigma-circle-small"
          >
            8
          </button>
        </Col>
        <Col className={'text-center'} xs={2}>
          <button
            onClick={() => sendDigitClick('9')}
            className="btn-sigma-circle-small"
          >
            9
          </button>
        </Col>
      </Row>
      <Row className={'mt-3'}>
        <Col className={'text-center'} xs={{ span: 2, offset: 2 }}>
          <button
            onClick={() => sendDigitClick('*')}
            className="btn-sigma-circle-small"
          >
            *
          </button>
        </Col>
        <Col className={'text-center'} xs={4}>
          <button
            onClick={() => sendDigitClick('0')}
            className="btn-sigma-circle-small"
          >
            0
          </button>
        </Col>
        <Col className={'text-center'} xs={2}>
          <button
            onClick={() => sendDigitClick('#')}
            className="btn-sigma-circle-small"
          >
            #
          </button>
        </Col>
      </Row>
    </div>
  );
}

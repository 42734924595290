import groupBy from 'lodash/groupBy';
import { actionId } from '../../util/AppUtils';
import { v4 as uuid } from 'uuid';
import htmlToDraft from 'html-to-draftjs';
import { ContentState } from 'draft-js';

export const getGroupedStepsArray = (steps) =>
  Object.entries(
    groupBy(steps, function (step) {
      return step.days_after;
    })
  );

export const getNextDaysAfterInt = (daysAfterSet) => {
  if (daysAfterSet.size === 0) return 0;
  return Math.max(...Array.from(daysAfterSet)) + 1;
};

export const createStep = ({ type, days_after, message_template_id }) => {
  return {
    automated: false,
    days_after: parseInt(days_after),
    task_action: { id: actionId[type], name: type },
    task_action_id: actionId[type],
    message_template_id: message_template_id,
    editID: uuid(),
  };
};

export const getDropdownButtonPreview = (action, templates) => {
  const template = templates?.find(({ id }) => id === action.message_template_id);
  if (!template) return '';
  if (action.task_action.name === 'Text' || action.task_action.name === 'Call')
    return template.content ?? '';
  else
    return ContentState.createFromBlockArray(
      htmlToDraft(template.content)
    ).getPlainText();
};

export const getDropdownItemPreview = (template, taskActionType) => {
  if (taskActionType === 'Text' || taskActionType === 'Call')
    return template.content;
  return ContentState.createFromBlockArray(
    htmlToDraft(template.content)
  ).getPlainText();
};

export const getCurrentTemplateName = (templates, action) => {
  const template = templates?.find(({ id }) => id === action.message_template_id);
  return template ? template.name : '';
};

import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {
  Box,
  Chip,
  Typography,
  AccordionActions,
  Autocomplete,
  TextField,
  Button,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { useOrg } from '../../../contexts/OrgProvider';
import { useFilters } from '../../services/hooks/useFilters';
import { useUsersCurrent } from '../../services/users/queries';
import { useSavedSearchesEdit } from '../../services/savedSearches/mutations';
import { useSavedSearchesList } from '../../services/savedSearches/queries';
import { useTaskSequencesList } from '../../services/taskSequences/queries';
import { useSourcesList } from '../../services/sources/queries';
import { useTeamsList } from '../../services/teams/queries';
import { useOrganizationsList } from '../../services/organizations/queries';
import { useUsersList } from '../../services/users/queries';
import { useCustomFields } from '../../../api/customFields/queries';
import { DeleteIcon, StarIcon } from '../../theme/icons';
import { DateFilter, deserializeSavedDateFilter } from '../Filters/DateFilter';
import {
  CreateSavedSearchDialog,
  DeleteSavedSearchDialog,
} from '../SavedSearchDialogs';
import DebouncedTextFilter from '../Filters/DebouncedTextFilter';
import {
  CustomFieldAccordion,
  FilterAccordion,
  FilterAccordionSummary,
} from '../Filters/FilterAccordion';
import { fireDialog } from '../Dialog';
import { parseFilterParams } from '../Filters/utils';
import { getAccountName } from './utils';

const FilterBar = forwardRef(({ setPage, setLeadsFilterMap }, ref) => {
  useImperativeHandle(ref, () => ({
    changeFilterParams(params) {
      setFilterMap((prev) => ({
        ...prev,
        ...params,
      }));
    },
  }));

  const history = useHistory();
  const { search } = useLocation();
  const { leadIdParam } = useParams();

  const [canUseTaskSequencesList, setCanUseTaskSequencesList] = useState(false);
  const [canUseSourcesList, setCanUseSourcesList] = useState(false);
  const [canUseUsersList, setCanUseUsersList] = useState(false);
  const [sequenceOptions, setSequenceOptions] = useState([]);
  const [sourceInputValue, setSourceInputValue] = useState('');
  const [sourcesOptions, setSourcesOptions] = useState([]);
  const [teamsOptions, setTeamsOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [causeTextFieldValueChange, setCauseTextFieldValueChange] = useState(false);
  const [selectedSavedSearchId, setSelectedSavedSearchId] = useState();

  const parseQueryParams = () => {
    const params = new URLSearchParams(search);
    let queryParamsHash = {};
    params.forEach((value, key) => {
      queryParamsHash[key] = value;
    });

    return queryParamsHash;
  };
  const [queryParams, setQueryParams] = useState(parseQueryParams());

  const { filterMap, setFilterMapValue, setFilterMap, clearFilterMap } = useFilters({
    initialFilters: initialLeadsFilterMapValues,
  });

  const { id: orgId, isMasterAccount } = useOrg();
  const { data: currentUser } = useUsersCurrent();
  const { data: accountsList } = useOrganizationsList();
  const { data: teamsList } = useTeamsList();
  const {
    data: taskSequencesList,
    isLoading: taskSequencesListIsLoading,
  } = useTaskSequencesList([], {
    enabled: canUseTaskSequencesList,
  });
  const { data: sourcesList, isLoading: sourcesListIsLoading } = useSourcesList(
    [['name', sourceInputValue]],
    {
      enabled: canUseSourcesList,
    }
  );
  const {
    data: usersList,
    isSuccess: isUsersSuccess,
    isLoading: usersListIsLoading,
  } = useUsersList([], {
    enabled: canUseUsersList,
  });
  const { data: savedSearchesList } = useSavedSearchesList(
    { user_id: currentUser.id, organization_id: orgId },
    {
      resourceClass: 'leads',
    }
  );
  const { mutate: editSavedSearch } = useSavedSearchesEdit();
  const selectedAccountsSet = useSelectedAccountsSet(filterMap);
  const { data: customFieldsList } = useCustomFields([['organization_id', orgId]], {
    select: (data) => {
      return data?.items
        ?.filter(
          (customField) => !isMasterAccount || !customField.master_custom_field_id
        )
        ?.sort((a, b) => {
          if (a.organization_id == orgId && b.organization_id != orgId) {
            return false;
          } else if (a.organization_id != orgId && b.organization_id == orgId) {
            return true;
          }
          return b.order < a.order;
        });
    },
  });

  const createFilterMapFromSavedFilters = (filters) => {
    let map = {};
    let customFieldsIdMap = {};

    filters?.forEach((filter) => {
      const filterMapKey = filter[0].replace('[]', '');
      if (filterMapKey === 'first_name') {
        map['first_name'] = filter[1];
      }
      if (filterMapKey === 'last_name') {
        map['last_name'] = filter[1];
      }
      if (filterMapKey === 'email_address') {
        map['email_address'] = filter[1];
      }
      if (filterMapKey === 'phone_number') {
        map['phone_number'] = filter[1];
      }
      if (filterMapKey === 'created_at_selector') {
        map['created_at'] = deserializeSavedDateFilter(filters);
      }
      if (filterMapKey === 'organization_id') {
        const filterId = filter[1];
        const found = accountsList?.find(
          ({ id }) => String(id) === String(filterId)
        );
        const newSelectedOption = {
          filterValue: filterId,
          name: found?.name,
        };
        if (!map['organization_id']) {
          map['organization_id'] = [newSelectedOption];
        } else {
          map['organization_id'].push(newSelectedOption);
        }
      }
      if (filterMapKey === 'task_sequence_id') {
        const filterId = filter[1];
        const found = taskSequencesList?.find(
          ({ id }) => String(id) === String(filterId)
        );
        const newSelectedOption = {
          filterValue: filterId,
          name: found?.name,
        };
        if (!map['task_sequence_id']) {
          map['task_sequence_id'] = [newSelectedOption];
        } else {
          map['task_sequence_id'].push(newSelectedOption);
        }
      }
      if (filterMapKey === 'task_sequence_name') {
        const newSelectedOption = {
          filterValue: filter[1],
          name: filter[1],
        };
        if (!map['task_sequence_name']) {
          map['task_sequence_name'] = [newSelectedOption];
        } else {
          map['task_sequence_name'].push(newSelectedOption);
        }
      }
      if (filterMapKey === 'status') {
        const filterId = filter[1];
        const found = [
          { id: 0, name: 'Pending' },
          { id: 1, name: 'Won' },
          { id: 2, name: 'Lost' },
        ]?.find(({ id }) => String(id) === String(filterId));
        const newSelectedOption = {
          filterValue: filterId,
          name: found?.name,
        };
        if (!map['status']) {
          map['status'] = [newSelectedOption];
        } else {
          map['status'].push(newSelectedOption);
        }
      }
      if (filterMapKey === 'source_id') {
        const filterId = filter[1];
        const found = sourcesList?.find(({ id }) => String(id) === String(filterId));
        const newSelectedOption = {
          filterValue: filterId,
          name: found?.name,
        };
        if (!map['source_id']) {
          map['source_id'] = [newSelectedOption];
        } else {
          map['source_id'].push(newSelectedOption);
        }
      }
      if (filterMapKey === 'source_name') {
        const filterId = filter[1];
        const newSelectedOption = {
          filterValue: filter[1],
          name: filter[1],
        };
        if (!map['source_name']) {
          map['source_name'] = [newSelectedOption];
        } else {
          map['source_name'].push(newSelectedOption);
        }
      }
      if (filterMapKey === 'team_id') {
        const filterId = filter[1];
        const found = teamsList?.find(({ id }) => String(id) === String(filterId));
        const newSelectedOption = {
          filterValue: filterId,
          name: found?.name,
        };
        if (!map['team_id']) {
          map['team_id'] = [newSelectedOption];
        } else {
          map['team_id'].push(newSelectedOption);
        }
      }
      if (filterMapKey === 'assigned_user_id') {
        const filterId = filter[1];
        const userIdCleaned = String(filterId).replace('eq::', '');
        const found = usersList?.find(({ id }) => String(id) === userIdCleaned);
        const newSelectedOption = {
          filterValue: filterId,
          name:
            filterId !== 'blank::_'
              ? `${found?.first_name} ${found?.last_name}`
              : 'Unassigned',
        };
        if (!map['assigned_user_id']) {
          map['assigned_user_id'] = [newSelectedOption];
        } else {
          map['assigned_user_id'].push(newSelectedOption);
        }
      }
      if (filterMapKey === 'last_contacted_at_selector') {
        map['last_contacted_at'] = deserializeSavedDateFilter(filters);
      }
      if (filterMapKey === 'last_contacted_at_selector') {
        map['last_contacted_at'] = deserializeSavedDateFilter(filters);
      }
      if (filterMapKey === 'next_task_due_at_selector') {
        map['next_task_due_at'] = deserializeSavedDateFilter(filters);
      }
      if (filterMapKey === 'custom_field_values') {
        const customFieldString = filter[1];
        const customFieldSplit = String(customFieldString).split('::');
        const customFieldId = customFieldSplit[0];
        const operator = customFieldSplit[1];
        const filterValue = customFieldSplit[2];

        const newSelectedOption = {
          operator,
          filterValue,
        };
        customFieldsIdMap[customFieldId] = newSelectedOption;
      }
    });
    map['custom_field_values'] = { customFieldsIdMap };
    return map;
  };

  const loadSavedSearch = (savedSearch, doClearFilterMap) => {
    if (doClearFilterMap) clearFilterMap();
    const savedFiltersMap = createFilterMapFromSavedFilters(
      savedSearch || Object.entries(queryParams)
    );

    Object.entries(savedFiltersMap).forEach(([filterMapKey, filterMapValue]) =>
      setFilterMapValue(filterMapKey, filterMapValue)
    );
    setCauseTextFieldValueChange((prev) => !prev);
  };

  const handleClearFilterMap = () => {
    history.push(`/leads`);
    clearFilterMap();
    setSelectedSavedSearchId(null);
    setCauseTextFieldValueChange((prev) => !prev);
  };

  useEffect(() => {
    if (!sourcesList) {
      return;
    }
    let uniq = [...new Set(sourcesList.map((t) => t.name))];
    let options = uniq
      .sort()
      .filter((t) => t.trim() != '')
      .map((t) => {
        return { filterValue: t, name: t };
      });
    setSourcesOptions(options);
  }, [sourcesList]);

  useEffect(() => {
    if (!taskSequencesList) {
      return;
    }
    let uniq = [...new Set(taskSequencesList.map((t) => t.name))];
    let options = uniq
      .sort()
      .filter((t) => t.trim() != '')
      .map((t) => {
        return { filterValue: t, name: t };
      });
    setSequenceOptions(options);
  }, [taskSequencesList]);

  useEffect(() => {
    const teamsFilterOptions = teamsList
      ?.reduce((acc, team) => {
        if (
          selectedAccountsSet?.has(String(team.organization_id)) ||
          !selectedAccountsSet
        ) {
          const teamAccountName = getAccountName(team, accountsList);
          if (teamAccountName) {
            acc.push({
              filterValue: team.id,
              account: teamAccountName,
              name: team.name,
            });
          }
        }
        return acc;
      }, [])
      .sort((a, b) => String(a.account).localeCompare(String(b.account)));

    setTeamsOptions(teamsFilterOptions ?? []);
  }, [selectedAccountsSet, teamsList, accountsList]);

  useEffect(() => {
    if (!isUsersSuccess) {
      return;
    }

    const userFilterOptions = usersList?.reduce((acc, user) => {
      acc.push({
        filterValue: `eq::${user.id}`,
        name: `${user.first_name} ${user.last_name}`,
      });
      return acc;
    }, []);

    userFilterOptions.push({
      filterValue: 'blank::_',
      name: 'Unassigned',
    });

    setUserOptions(userFilterOptions);
  }, [usersList]);

  useEffect(() => {
    const defaultSearch = savedSearchesList?.find(({ is_default }) => is_default);
    if (!selectedSavedSearchId) setSelectedSavedSearchId(defaultSearch?.id);
    loadSavedSearch(defaultSearch?.data, false);
  }, [savedSearchesList, accountsList, taskSequencesList, usersList, teamsList]);

  useEffect(() => {
    setLeadsFilterMap(filterMap);
    setPage(1);
  }, [filterMap]);

  useEffect(() => {
    if (sourceInputValue.length > 2) {
      setCanUseSourcesList(true);
    } else {
      setCanUseSourcesList(false);
      setSourcesOptions([]);
    }
  }, [sourceInputValue]);

  useEffect(() => {
    if (Object.keys(queryParams).length > 0) {
      const queryParamsFiltersMap = createFilterMapFromSavedFilters(
        Object.entries(queryParams)
      );

      Object.entries(
        queryParamsFiltersMap
      ).forEach(([filterMapKey, filterMapValue]) =>
        setFilterMapValue(filterMapKey, filterMapValue)
      );
      setCauseTextFieldValueChange((prev) => !prev);
    }
  }, [queryParams]);

  useEffect(() => {
    setQueryParams(parseQueryParams());
  }, [search]);

  return (
    <Box
      flexDirection="column"
      //TODO responsive width
      width={250}
      sx={{
        display: `${leadIdParam ? 'none' : 'flex'}`,
        border: ({ palette }) => `1px solid ${palette.grey[300]}`,
        borderRadius: '4px',
        backgroundColor: 'white',
      }}
    >
      <Box
        columnGap={1}
        sx={{
          borderRadius: 'inherit',
          minHeight: '39px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: '10px',
          backgroundColor: ({ palette }) => palette.grey[100],
          borderBottom: ({ palette }) => `1px solid ${palette.grey[300]}`,
        }}
      >
        <Typography fontSize={14} fontWeight={500} sx={{ flexBasis: '100%' }}>
          Filters
        </Typography>
        <Button
          variant="outlined"
          color="inherit"
          sx={{
            backgroundColor: ({ palette }) => palette.common.white,
            minWidth: '40px',
            height: '25px',
            p: 0.5,
          }}
          onClick={() => {
            const [_, filterParams] = parseFilterParams(
              filterMap,
              isMasterAccount,
              orgId
            );

            fireDialog((promiseProps) =>
              CreateSavedSearchDialog({
                ...promiseProps,
                setSelectedSavedSearchId,
                resourceClass: 'leads',
                filterParams,
              })
            );
          }}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          sx={{
            backgroundColor: ({ palette }) => palette.common.white,
            minWidth: '40px',
            height: '25px',
            p: 0.5,
          }}
          onClick={handleClearFilterMap}
        >
          Clear
        </Button>
      </Box>
      <Box overflow="auto">
        <FilterAccordion defaultExpanded>
          <FilterAccordionSummary>Saved Searches</FilterAccordionSummary>
          <AccordionActions sx={{ alignItems: 'flex-start' }}>
            <Box width="100%" display="flex" flexDirection="column">
              {savedSearchesList?.length > 0 ? (
                savedSearchesList.map((savedSearch) => (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: 0.5,
                      ':hover': {
                        cursor: 'pointer',
                      },
                    }}
                    key={savedSearch.id}
                  >
                    <Box
                      flexBasis="100%"
                      onClick={() => {
                        setSelectedSavedSearchId(savedSearch.id);
                        loadSavedSearch(savedSearch.data, true);
                      }}
                    >
                      <Typography
                        fontSize={14}
                        fontWeight={
                          selectedSavedSearchId == savedSearch.id ? '600' : ''
                        }
                      >
                        {savedSearch.name}
                      </Typography>
                    </Box>
                    <IconButton
                      onClick={() =>
                        editSavedSearch({
                          ...savedSearch,
                          is_default: !savedSearch.is_default,
                        })
                      }
                      size="small"
                      sx={{
                        padding: 0,
                        color: ({ palette }) =>
                          savedSearch.is_default
                            ? palette.warning.light
                            : palette.grey[300],
                      }}
                    >
                      <StarIcon />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        fireDialog((promiseProps) =>
                          DeleteSavedSearchDialog({
                            ...promiseProps,
                            savedSearchId: savedSearch.id,
                          })
                        )
                      }
                      sx={{ padding: 0 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))
              ) : (
                <Typography fontSize={14}>No saved searches</Typography>
              )}
            </Box>
          </AccordionActions>
        </FilterAccordion>
        <DebouncedTextFilter
          filterMap={filterMap}
          setFilterMapValue={setFilterMapValue}
          label="First Name"
          filterKey="first_name"
          causeTextFieldValueChange={causeTextFieldValueChange}
          setSelectedSavedSearchId={setSelectedSavedSearchId}
        />
        <DebouncedTextFilter
          filterMap={filterMap}
          setFilterMapValue={setFilterMapValue}
          label="Last Name"
          filterKey="last_name"
          causeTextFieldValueChange={causeTextFieldValueChange}
          setSelectedSavedSearchId={setSelectedSavedSearchId}
        />
        <DebouncedTextFilter
          filterMap={filterMap}
          setFilterMapValue={setFilterMapValue}
          label="Email Address"
          filterKey="email_address"
          causeTextFieldValueChange={causeTextFieldValueChange}
          setSelectedSavedSearchId={setSelectedSavedSearchId}
        />
        <DebouncedTextFilter
          filterMap={filterMap}
          setFilterMapValue={setFilterMapValue}
          label="Phone Number"
          filterKey="phone_number"
          causeTextFieldValueChange={causeTextFieldValueChange}
          setSelectedSavedSearchId={setSelectedSavedSearchId}
        />
        <DateFilter
          setSelectedSavedSearchId={setSelectedSavedSearchId}
          setFilterMapValue={setFilterMapValue}
          filterMap={filterMap}
          fieldName={'created_at'}
        />
        <FilterAccordion
          onChange={(_, expanded) => {
            setCanUseTaskSequencesList(expanded);
          }}
        >
          <FilterAccordionSummary>
            Task Sequence
            {filterMap.task_sequence_name.length > 0 && (
              <Chip
                onDelete={() => {
                  setSelectedSavedSearchId(null);
                  setFilterMapValue('task_sequence_name', []);
                }}
                label={filterMap.task_sequence_name.length}
              />
            )}
          </FilterAccordionSummary>
          <AccordionActions>
            <Autocomplete
              autoComplete
              multiple
              sx={{ width: '100%' }}
              onChange={(_, value) => {
                setSelectedSavedSearchId(null);
                setFilterMapValue('task_sequence_name', value);
              }}
              value={filterMap.task_sequence_name ?? []}
              getOptionLabel={(option) => option.name}
              options={sequenceOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Task Sequence"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {taskSequencesListIsLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option.filterValue === value.filterValue
              }
            />
          </AccordionActions>
        </FilterAccordion>
        <FilterAccordion>
          <FilterAccordionSummary>
            Lead Source
            {filterMap.source_name.length > 0 && (
              <Chip
                onDelete={() => {
                  setSelectedSavedSearchId(null);
                  setFilterMapValue('source_name', []);
                }}
                label={filterMap.source_name.length}
              />
            )}
          </FilterAccordionSummary>
          <AccordionActions>
            <Autocomplete
              autoComplete
              multiple
              sx={{ width: '100%' }}
              onChange={(_, value) => {
                setSelectedSavedSearchId(null);
                setFilterMapValue('source_name', value);
              }}
              onClose={() => {
                setCanUseSourcesList(false);
                setSourcesOptions([]);
              }}
              onInputChange={(_, v) => setSourceInputValue(v)}
              inputValue={sourceInputValue}
              value={filterMap.source_name ?? []}
              getOptionLabel={(option) => option.name}
              options={sourcesOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Source"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {sourcesListIsLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option.filterValue === value.filterValue
              }
            />
          </AccordionActions>
        </FilterAccordion>
        <FilterAccordion>
          <FilterAccordionSummary>
            Lead Status
            {filterMap.status.length > 0 && (
              <Chip
                onDelete={() => {
                  setSelectedSavedSearchId(null);
                  setFilterMapValue('status', []);
                }}
                label={filterMap.status.length}
              />
            )}
          </FilterAccordionSummary>
          <AccordionActions>
            <Autocomplete
              autoComplete
              multiple
              sx={{ width: '100%' }}
              value={filterMap.status ?? []}
              onChange={(_, value) => {
                setSelectedSavedSearchId(null);
                setFilterMapValue('status', value);
              }}
              options={[
                { filterValue: 0, name: 'Pending' },
                { filterValue: 1, name: 'Won' },
                { filterValue: 2, name: 'Lost' },
              ]}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Lead Status" />}
              isOptionEqualToValue={(option, value) =>
                option.filterValue === value.filterValue
              }
            />
          </AccordionActions>
        </FilterAccordion>
        {isMasterAccount && (
          <FilterAccordion>
            <FilterAccordionSummary>
              Account
              {filterMap?.organization_id?.length > 0 && (
                <Chip
                  onDelete={() => {
                    setSelectedSavedSearchId(null);
                    setFilterMapValue('organization_id', []);
                  }}
                  label={filterMap.organization_id.length}
                />
              )}
            </FilterAccordionSummary>
            <AccordionActions>
              <Autocomplete
                autoComplete
                multiple
                sx={{ width: '100%' }}
                value={filterMap.organization_id ?? []}
                onChange={(_, value) => {
                  setSelectedSavedSearchId(null);
                  setFilterMapValue('organization_id', value);
                }}
                options={
                  accountsList?.map(({ id, name }) => ({
                    filterValue: id,
                    name,
                  })) ?? []
                }
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Account" />}
                isOptionEqualToValue={(option, value) =>
                  option.filterValue === value.filterValue
                }
              />
            </AccordionActions>
          </FilterAccordion>
        )}
        <FilterAccordion>
          <FilterAccordionSummary>
            Team
            {filterMap.team_id.length > 0 && (
              <Chip
                onDelete={() => {
                  setSelectedSavedSearchId(null);
                  setFilterMapValue('team_id', []);
                }}
                label={filterMap.team_id.length}
              />
            )}
          </FilterAccordionSummary>
          <AccordionActions>
            <Autocomplete
              autoComplete
              multiple
              sx={{ width: '100%' }}
              value={filterMap.team_id ?? []}
              onChange={(_, value) => {
                setSelectedSavedSearchId(null);
                setFilterMapValue('team_id', value);
              }}
              options={teamsOptions}
              groupBy={(option) => option.account}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Team" />}
              isOptionEqualToValue={(option, value) =>
                option.filterValue === value.filterValue
              }
            />
          </AccordionActions>
        </FilterAccordion>
        <FilterAccordion
          onChange={(_, expanded) => {
            setCanUseUsersList(expanded);
          }}
        >
          <FilterAccordionSummary>
            Assigned User
            {filterMap?.assigned_user_id.length > 0 && (
              <Chip
                onDelete={() => {
                  setSelectedSavedSearchId(null);
                  setFilterMapValue('assigned_user_id', []);
                }}
                label={filterMap?.assigned_user_id.length}
              />
            )}
          </FilterAccordionSummary>
          <AccordionActions>
            <Autocomplete
              autoComplete
              multiple
              sx={{ width: '100%' }}
              value={filterMap?.assigned_user_id ?? []}
              onChange={(_, value) => {
                setSelectedSavedSearchId(null);
                setFilterMapValue('assigned_user_id', value);
              }}
              options={userOptions}
              groupBy={(option) => option.account}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) =>
                option.filterValue === value.filterValue
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="User"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {usersListIsLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </AccordionActions>
        </FilterAccordion>
        <DateFilter
          setSelectedSavedSearchId={setSelectedSavedSearchId}
          setFilterMapValue={setFilterMapValue}
          filterMap={filterMap}
          fieldName={'last_contacted_at'}
          title="Last Contacted"
        />
        <DateFilter
          setSelectedSavedSearchId={setSelectedSavedSearchId}
          setFilterMapValue={setFilterMapValue}
          filterMap={filterMap}
          fieldName={'next_task_due_at'}
          title="Next Task Date"
          displayFuture={true}
        />
        {customFieldsList?.map((customField) => (
          <CustomFieldAccordion
            causeTextFieldValueChange={causeTextFieldValueChange}
            key={customField.id}
            customField={customField}
            filterMap={filterMap}
            setFilterMapValue={setFilterMapValue}
            customFieldValueType={'custom_field_values'}
            setSelectedSavedSearchId={setSelectedSavedSearchId}
          />
        ))}
      </Box>
    </Box>
  );
});

const useSelectedAccountsSet = (filterMap) => {
  const [selectedAccountsSet, setSelectedAccountsSet] = useState(null);

  useEffect(() => {
    if (filterMap.organization_id?.length > 0) {
      let set = new Set();
      filterMap.organization_id?.forEach(({ filterValue }) =>
        set.add(String(filterValue))
      );
      setSelectedAccountsSet(set);
    } else {
      setSelectedAccountsSet(null);
    }
  }, [filterMap]);

  return selectedAccountsSet;
};

const initialLeadsFilterMapValues = {
  first_name: '',
  last_name: '',
  email_address: '',
  phone_number: '',
  created_at: [],
  task_sequence_id: [],
  task_sequence_name: [],
  status: [],
  source_id: [],
  source_name: [],
  organization_id: [],
  team_id: [],
  assigned_user_id: [],
  last_contacted_at: [],
  next_task_due_at: [],
  custom_field_values: { customFieldsIdMap: {} },
};

export default FilterBar;

import { useUsersCurrent, useUsersShow } from '../services/users/queries';
import { useNotificationEmailAddressesList } from '../services/notificationEmailAddresses/queries';
import { useUsersEdit } from '../services/users/mutations';
import { useParams, Prompt } from 'react-router-dom';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Alert,
  Button,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  InputLabel,
  Toolbar,
  Paper,
  Tooltip,
  Switch,
  Checkbox,
  Zoom,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { LoadingButton } from '@mui/lab';
import HookForm, { FormProvider } from '../components/HookForm';
import { fireDialog } from '../components/Dialog';
import { SaveIcon, PlusIcon, DeleteIcon, FilterIcon } from '../theme/icons';
import { FIELD_WIDTH } from '../theme/fixedValues';
import { formatPhoneNumber } from '../services/textFormatting';
import { useConditionalUseFormProps } from '../components/HookForm/hooks';
import ListView from '../components/ListView';
import {
  createYupResolver,
  FIRST_NAME,
  LAST_NAME,
  PHONE_NUMBER,
  PHONE_NUMBER_OPTIONAL,
} from '../components/HookForm/yupValidations';
import {
  AddEmailDialog,
  EditEmailDialog,
  DeleteEmailDialog,
} from './_settingsPagesComponents/notificationEmailAddressesDialogs';
import { useOrganizationsList } from '../services/organizations/queries';
import { useEnterprisesShow } from '../services/enterprises/queries';
import { useRolesList } from '../services/roles/queries';
import { useUserOrganizationConnectionsList } from '../services/userOrganizationConnections/queries';
import { useEnterprisesEdit } from '../services/enterprises/mutations';
import { useOrg } from '../../contexts/OrgProvider';
import { userOrganizationConnectionsQueryKeys } from '../services/userOrganizationConnections/queries';
import { useQueryClient } from 'react-query';
import { useUserOrganizationConnectionsBatchUpdate } from '../services/userOrganizationConnections/mutations';

const ENTERPRISE_USER = 'enterprise_user_bool';

const UsersShowSettingsPage = () => {
  // ANCHOR Use states and use effects

  const {
    enterprise_id,
    isEnterpriseUser,
    isMasterAccount,
    call_tracking_enabled,
  } = useOrg();
  const { data: currentUser } = useUsersCurrent();
  const isUserAdministrator = () => {
    return currentUser?.user_administrator == true || isEnterpriseUser;
  };

  const { userId } = useParams();

  const queryClient = useQueryClient();

  const {
    data: user,
    isFetching: isFetchingUser,
    isError: didFailUser,
  } = useUsersShow(userId);

  const {
    data: notificationEmailAddresses,
    isFetching: isFetchingNotificationEmailAddresses,
    isError: isErrorNotificationEmailAddresses,
    isSuccess: isEmailsSuccess,
  } = useNotificationEmailAddressesList(userId);

  const {
    mutate: editUser,
    isLoading: isEditing,
    isError,
    isSuccess,
    reset,
  } = useUsersEdit();

  const {
    data: enterprise,
    isFetching: isFetchingEnterprises,
    isError: didFailEnterprises,
  } = useEnterprisesShow(enterprise_id, {
    enabled: isEnterpriseUser,
  });

  const {
    data: organizations,
    isFetching: isFetchingOrganizations,
    isError: didFailOrganizations,
  } = useOrganizationsList(
    { enterprise_id: enterprise_id },
    { enabled: isEnterpriseUser && !!enterprise }
  );

  const [
    isBatchUpdatingUserOrgConnections,
    setIsBatchUpdatingUserOrgConnections,
  ] = useState(false);
  const [
    didFailBatchUpdatingUserOrgConnections,
    setDidFailBatchUpdatingUserOrgConnections,
  ] = useState(false);
  const [
    didSucceedBatchUpdatingUserOrgConnections,
    setDidSucceedBatchUpdatingUserOrgConnections,
  ] = useState(false);
  const {
    mutate: batchUpdateUserOrgConnection,
    reset: resetBatchUpdateUserOrgConnection,
  } = useUserOrganizationConnectionsBatchUpdate({
    onError: () => {
      setDidFailBatchUpdatingUserOrgConnections(true);
    },
  });

  const [enterpriseSwitcherOn, setEnterpriseSwitcherOn] = useState(
    !!enterprise?.users?.find((eu) => {
      return eu.user_id == userId;
    })
  );

  useEffect(() => {
    if (enterprise) {
      const found = !!enterprise?.users?.find((eu) => {
        return eu.user_id == userId;
      });
      setEnterpriseSwitcherOn(found);
      setEnterpriseFormValue(ENTERPRISE_USER, found);
    }
  }, [enterprise]);

  const {
    mutate: editEnterprise,
    isLoading: isEditingEnterprise,
    isError: didFailEditingEnterprise,
    isSuccess: didSucceedEditingEnterprise,
    reset: resetEditEnterprise,
  } = useEnterprisesEdit();

  const {
    data: userOrgConnections,
    isFetching: isFetchingUserOrgConnections,
    isError: didFailUserOrgConnections,
  } = useUserOrganizationConnectionsList(
    [
      ['user_id[]', userId],
      ['page_size', '10000'],
    ],
    {
      enabled: isEnterpriseUser && !!userId,
    }
  );

  const {
    data: viewableRoles,
    isFetching: isFetchingRoles,
    isError: didFailRoles,
  } = useRolesList({ enabled: isUserAdministrator() });

  const [rows, setRows] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isAccountAccessDirty, setIsAccountAccessDirty] = useState(false);

  useEffect(() => {
    recalculate();
  }, [organizations, userOrgConnections]);

  const recalculate = () => {
    if (organizations && userOrgConnections) {
      const tempRows = organizations?.map((org) => {
        const hasAccess = !!userOrgConnections?.items?.find((userOrg) => {
          return userOrg.organization_id == org.id;
        });
        return { name: org.name, id: org.id, selected: hasAccess };
      });
      setRows(tempRows);
    }
  };

  // ANCHOR Form props

  const defaultValues = {
    [FIRST_NAME]: user?.first_name,
    [LAST_NAME]: user?.last_name,
    [PHONE_NUMBER_OPTIONAL]: formatPhoneNumber(user?.phone ?? ''),
  };

  const useFormProps = useConditionalUseFormProps(
    { defaultValues, resolver: createYupResolver(defaultValues) },
    !!user
  );

  const rolesFormProps = () => {
    const prop = {};
    viewableRoles?.forEach((role) => {
      prop[role.id] = !!user?.roles?.find((userRole) => {
        return userRole.role_id == role.id;
      });
    });
    return {
      defaultValues: prop,
      resolver: createYupResolver(prop),
    };
  };

  const accountAccessDefaults = {
    [ENTERPRISE_USER]: !!enterprise?.users?.find((eu) => {
      return eu.user_id == userId;
    }),
  };
  const accountAccessMethods = useForm({
    defaultValues: accountAccessDefaults,
    resolver: createYupResolver(accountAccessDefaults),
  });
  const {
    handleSubmit: handleAccountAccessSubmit,
    watch,
    setValue: setEnterpriseFormValue,
  } = {
    ...accountAccessMethods,
  };

  const enterpriseSwitchWatcher = watch(ENTERPRISE_USER);
  useEffect(() => {
    if (enterpriseSwitchWatcher != undefined) {
      setEnterpriseSwitcherOn(!!enterpriseSwitchWatcher);
    }
  }, [enterpriseSwitchWatcher]);

  // ANCHOR On change/click Actions

  const onUserEditSubmit = (formData) => {
    resetAllStates();
    editUser({
      id: user.id,
      first_name: formData[FIRST_NAME],
      last_name: formData[LAST_NAME],
      phone: formData[PHONE_NUMBER_OPTIONAL],
    });
  };

  const onUserRoleSubmit = (formData) => {
    const newRoles = [];
    Object.keys(formData).forEach((key) => {
      const found_user_role = user?.roles?.find((user_role) => {
        return user_role.role_id == key;
      });

      if (formData[key] === true && !found_user_role)
        newRoles.push({ role_id: key });
      if (formData[key] === false && found_user_role)
        newRoles.push({ id: found_user_role.id, _destroy: true });
    });
    if (newRoles.length > 0) {
      resetAllStates();
      editUser({ id: userId, roles: newRoles });
    }
  };

  const onAccountAccessEdit = (formData) => {
    setIsAccountAccessDirty(false);
    const enterpriseUserIndex = enterprise?.users?.findIndex((eu) => {
      return eu.user_id == userId;
    });
    const hadEnterpriseAccess = enterpriseUserIndex != -1;
    const newEnterprise = { ...enterprise };
    resetAllStates();
    recalculate();

    if (formData[ENTERPRISE_USER]) {
      if (hadEnterpriseAccess != formData[ENTERPRISE_USER]) {
        newEnterprise.users.push({ user_id: userId });
        editEnterprise(newEnterprise);
      }
    } else {
      if (hadEnterpriseAccess != formData[ENTERPRISE_USER]) {
        newEnterprise.users[enterpriseUserIndex] = {
          ...newEnterprise.users[enterpriseUserIndex],
          _destroy: true,
        };
        editEnterprise(newEnterprise);
      }

      const removedOrgConnections = [];
      const addedOrgConnections = [];
      rows.forEach((row) => {
        const userOrgConnection = userOrgConnections?.items?.find((userOrg) => {
          return userOrg.organization_id == row.id;
        });
        if (!!userOrgConnection && !row.selected) {
          removedOrgConnections.push(userOrgConnection.id);
        } else if (!userOrgConnection && row.selected) {
          addedOrgConnections.push({ user_id: userId, organization_id: row.id });
        }
      });

      if (addedOrgConnections.length > 0 || removedOrgConnections.length > 0) {
        setIsBatchUpdatingUserOrgConnections(true);
        batchUpdateUserOrgConnection(
          { toCreate: addedOrgConnections, toDelete: removedOrgConnections },
          {
            onSettled: () => {
              setDidSucceedBatchUpdatingUserOrgConnections(
                !didFailBatchUpdatingUserOrgConnections
              );
              setIsBatchUpdatingUserOrgConnections(false);
              queryClient.invalidateQueries(
                userOrganizationConnectionsQueryKeys.list({
                  user_id: userId,
                })
              );
            },
          }
        );
      }
    }
  };

  const handleAddEmailClick = () =>
    fireDialog((promiseProps) => AddEmailDialog({ ...promiseProps, user }));

  const handleEditEmailClick = (emailAddress) =>
    fireDialog((promiseProps) =>
      EditEmailDialog({ emailAddress, user, ...promiseProps })
    );

  const handleDeleteEmailClick = (emailAddress) => {
    fireDialog((promiseProps) =>
      DeleteEmailDialog({
        ...promiseProps,
        emailAddress,
        user,
      })
    );
  };

  const resetAllStates = () => {
    reset();
    resetBatchUpdateUserOrgConnection();
    resetEditEnterprise();
    setDidFailBatchUpdatingUserOrgConnections(false);
    setDidSucceedBatchUpdatingUserOrgConnections(false);
  };

  // ANCHOR User Orgs Table https://mui.com/material-ui/react-table/#sorting-amp-selecting

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Account',
    },
  ];

  const EnhancedTableHead = (props) => {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
      disabled,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              disabled={disabled}
              inputProps={{
                'aria-label': 'select all accounts',
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const EnhancedTable = ({
    rows,
    setRows,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    isDirty,
    setDirty,
    disabled,
  }) => {
    const handleRequestSort = (event, property) => {
      if (disabled) return;
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
      setDirty(true);
      const newSelecteds = rows?.map((n) => {
        return { ...n, selected: event.target.checked };
      });
      setRows(newSelecteds);
      return;
    };

    const handleClick = (event, id) => {
      if (disabled) return;
      if (!isDirty) {
        setDirty(true);
      }
      const row = rows?.find((x) => {
        return x.id == id;
      });
      let newSelected = [...rows];

      if (row) row.selected = !row.selected;

      setRows(newSelected);
    };

    const handleChangePage = (event, newPage) => {
      if (disabled) return;
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      if (disabled) return;
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const isSelected = (id) => {
      const found = rows?.find((x) => {
        return x.id == id;
      });
      return found?.selected === true;
    };

    // Avoid a layout jump when reaching the last page with empty rows
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (rows?.length || 0)) : 0;

    return (
      <>
        <TableContainer sx={{ maxHeight: '300px' }}>
          <Table
            aria-labelledby="tableTitle"
            density="compact"
            size="medium"
            stickyHeader
          >
            <EnhancedTableHead
              numSelected={
                rows?.filter((x) => {
                  return x.selected;
                }).length
              }
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length || 0}
              disabled={disabled}
            />
            <TableBody>
              {rows
                ?.slice()
                ?.sort(getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          disabled={disabled}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.name}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 25 * emptyRows,
                  }}
                >
                  <TableCell colSpan={2} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          // TODO: Use server pagination and ordering
          count={rows?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            '& .MuiTablePagination-spacer': {
              display: 'none',
            },
          }}
        />
      </>
    );
  };

  // ANCHOR Render

  return (
    <SettingsCard
      showLoading={
        isFetchingNotificationEmailAddresses ||
        isFetchingUser ||
        isFetchingOrganizations ||
        isFetchingRoles ||
        isFetchingEnterprises ||
        isFetchingUserOrgConnections
      }
    >
      {didFailUser ? (
        <Alert severity="error">
          There was a problem leading your user. Try again.
        </Alert>
      ) : (
        <>
          <Prompt
            message={(location) => {
              if (location.pathname.includes('logout')) return true;
              if (
                isAccountAccessDirty ||
                !!enterprise?.users?.find((eu) => {
                  return eu.user_id == userId;
                }) !== enterpriseSwitcherOn
              )
                return 'You have unsaved changes, are you sure you would like to leave this page?';
            }}
          />
          <SettingsCard.Header>{`${user?.first_name} ${user?.last_name}`}</SettingsCard.Header>
          <SettingsCard.SubHeader>
            This is the name that will appear in automated communications and the
            activity log. The phone number will be used for SMS notifications if
            enabled.
          </SettingsCard.SubHeader>
          <SettingsCard.Main>
            <HookForm
              onSubmit={onUserEditSubmit}
              useFormProps={useFormProps}
              useDirtyFormCheck
            >
              {isSuccess && (
                <Alert severity="success" onClose={resetAllStates}>
                  Your changes have been saved successfully.
                </Alert>
              )}
              {isError && (
                <Alert severity="error" onClose={resetAllStates}>
                  There was an error saving your changes. Try again.
                </Alert>
              )}
              <Box
                display="grid"
                direction="column"
                rowGap={2}
                sx={{ width: FIELD_WIDTH }}
              >
                <HookForm.TextField name={FIRST_NAME} label="First Name" />
                <HookForm.TextField name={LAST_NAME} label="Last Name" />
                <HookForm.PhoneNumberField
                  name={PHONE_NUMBER_OPTIONAL}
                  label="Phone Number"
                />
                <LoadingButton
                  type="submit"
                  loading={isEditing}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  sx={{ maxWidth: 'max-content' }}
                >
                  Save
                </LoadingButton>
              </Box>
            </HookForm>
          </SettingsCard.Main>
          <SettingsCard.Divider />
          <SettingsCard.Header>Notification Emails</SettingsCard.Header>
          <SettingsCard.SubHeader>
            We will send notifications about LeadSigma activity at these email
            addresses:
          </SettingsCard.SubHeader>
          <SettingsCard.Main>
            {isErrorNotificationEmailAddresses && (
              <Alert>
                There was an error loading your notification email addresses. Try
                again.
              </Alert>
            )}
            {isEmailsSuccess && notificationEmailAddresses.length == 0 ? (
              <Alert severity="info" sx={{ mt: 1, mb: 2 }}>
                Add your first email address to start receiving notifications by
                email
              </Alert>
            ) : (
              <ListView>
                <ListView.Item>
                  <ListView.HeaderText>Email Address</ListView.HeaderText>
                </ListView.Item>
                {notificationEmailAddresses?.map((emailAddress) => (
                  <ListView.Item key={emailAddress.id}>
                    <ListView.Text colWidth={400}>
                      {emailAddress.email}
                    </ListView.Text>
                    <ListView.EditButton
                      onClick={() => handleEditEmailClick(emailAddress)}
                    />
                    <ListView.DeleteButton
                      onClick={() => handleDeleteEmailClick(emailAddress)}
                    />
                  </ListView.Item>
                ))}
              </ListView>
            )}

            <Button startIcon={<PlusIcon />} onClick={handleAddEmailClick}>
              Add New
            </Button>
          </SettingsCard.Main>
          <SettingsCard.Divider />
          {(isEnterpriseUser || isUserAdministrator()) && (
            <>
              <SettingsCard.Header>Roles</SettingsCard.Header>
              <SettingsCard.SubHeader>
                View and change this user's privileges.
              </SettingsCard.SubHeader>
              <SettingsCard.Main>
                <HookForm
                  onSubmit={onUserRoleSubmit}
                  useFormProps={rolesFormProps()}
                  useDirtyFormCheck
                >
                  {didFailRoles && (
                    <Alert severity="error" onClose={resetAllStates}>
                      There was a problem loading user roles. Try again.
                    </Alert>
                  )}
                  <Box sx={{ mb: 2 }}>
                    {viewableRoles?.map((role) => {
                      return role.name == 'Call Tracking' ? (
                        call_tracking_enabled && (
                          <HookForm.Switch
                            name={role.id}
                            label={role.name}
                            key={role.id}
                            disabled={!!enterpriseSwitcherOn}
                          />
                        )
                      ) : (
                        <HookForm.Switch
                          name={role.id}
                          label={role.name}
                          key={role.id}
                          disabled={!!enterpriseSwitcherOn}
                        />
                      );
                    })}
                  </Box>
                  <LoadingButton
                    type="submit"
                    loading={isEditing}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    sx={{ maxWidth: 'max-content' }}
                  >
                    Save
                  </LoadingButton>
                </HookForm>
              </SettingsCard.Main>
              <SettingsCard.Divider />
            </>
          )}
          {isEnterpriseUser && isMasterAccount && (
            <>
              <SettingsCard.Header>Authorized Accounts</SettingsCard.Header>
              <SettingsCard.SubHeader>
                View and change this user's account and enterprise level access.
                <br />
                Restrict access to individual accounts.
              </SettingsCard.SubHeader>
              <SettingsCard.Main></SettingsCard.Main>
              {(didFailEnterprises ||
                didFailUserOrgConnections ||
                didFailOrganizations) && (
                <Alert severity="error">
                  There was an error loading access. Try again.
                </Alert>
              )}
              {didFailEditingEnterprise && (
                <Alert severity="error" onClose={resetAllStates}>
                  There was an error authorizing the user to your enterprise. Try
                  again.
                </Alert>
              )}
              {didSucceedEditingEnterprise && (
                <Alert severity="success" onClose={resetAllStates}>
                  Successfully updated user's access to your enterprise.
                </Alert>
              )}
              {didFailBatchUpdatingUserOrgConnections && (
                <Alert severity="error" onClose={resetAllStates}>
                  There was an error saving your user's account access. Try again.
                </Alert>
              )}
              {didSucceedBatchUpdatingUserOrgConnections &&
                !didFailBatchUpdatingUserOrgConnections && (
                  <Alert severity="success" onClose={resetAllStates}>
                    Successfully saved user's account access.
                  </Alert>
                )}
              <FormProvider
                methods={accountAccessMethods}
                onSubmit={handleAccountAccessSubmit(onAccountAccessEdit)}
              >
                <Box width={'100%'}>
                  <HookForm.Switch
                    name={ENTERPRISE_USER}
                    label={'Authorized as Enterprise Administrator'}
                    key={ENTERPRISE_USER}
                  />
                  <InputLabel shrink={true}>
                    Enterprise Administators can create new accounts and administrate
                    over all accounts.
                  </InputLabel>
                </Box>
                {!enterpriseSwitcherOn && (
                  <Box
                    sx={{
                      width: '100%',
                      mt: 1,
                    }}
                    id="user-org-access-table"
                  >
                    <FormLabel sx={{ fontWeight: 'bold' }}>
                      Individual account access
                    </FormLabel>
                    <EnhancedTable
                      rows={rows}
                      setRows={setRows}
                      order={order}
                      setOrder={setOrder}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                      page={page}
                      setPage={setPage}
                      rowsPerPage={rowsPerPage}
                      setRowsPerPage={setRowsPerPage}
                      isDirty={isAccountAccessDirty}
                      setDirty={setIsAccountAccessDirty}
                      disabled={false}
                    />
                  </Box>
                )}
                <LoadingButton
                  type="submit"
                  loading={isBatchUpdatingUserOrgConnections || isEditingEnterprise}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  sx={{ maxWidth: 'max-content' }}
                  disabled={
                    !isAccountAccessDirty &&
                    !!enterprise?.users?.find((eu) => {
                      return eu.user_id == userId;
                    }) === enterpriseSwitcherOn
                  }
                >
                  Save
                </LoadingButton>
              </FormProvider>
            </>
          )}
        </>
      )}
    </SettingsCard>
  );
};

export default UsersShowSettingsPage;

import { useQuery } from 'react-query';
import MessageTemplates from './MessageTemplates';
import { ONE_HOUR, queryKeyFactory } from '../queryClientConfig';
import { useOrg } from '../../../contexts/OrgProvider';

export const messageTemplatesQueryKeys = queryKeyFactory('message-templates');

export const useMessageTemplatesList = (params = [], options) => {
  const { id, isMasterAccount } = useOrg();

  return useQuery(
    messageTemplatesQueryKeys.list(params),
    () => {
      const filters = [['page_size', 1000], ...params];
      if (!isMasterAccount) {
        filters.push(['organization_id[]', id]);
      }
      return MessageTemplates.list(filters);
    },
    {
      staleTime: ONE_HOUR,
      ...options,
    }
  );
};

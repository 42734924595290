import { styled } from '@mui/system';
export const CellSpanStyle = styled('span')(() => ({
  paddingLeft: '10px',
  paddingRight: '10px',
  dipslay: 'inline-block',

  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

/**
 * This is similary used to merge slingshot fields and custom fields in the public api
 * LINK https://github.com/LeadSigma/public-api/blob/45b94f2bd8d044401668b83159858f6d38625a8f/app/models/lead_fields.py#L19
 *
 * @param {*} slingshotFields
 * @param {*} customFields
 * @return {*} merged array of fields with pretty name and slingshot field id set
 */
export const mergeSlinghotAndCustomFields = (
  slingshotFields = [],
  customFields = []
) => {
  let leadFields = slingshotFields.map((sf) => {
    if (sf.name == 'name') return;

    const customFieldId = sf.custom_field_id;
    if (!customFieldId) {
      return {
        ...sf,
        slingshot_field_id: sf.id,
        name: toTitleCase(sf.name.replace('_', ' ')),
      };
    }

    const customField = customFields.find((cf) => {
      return cf.id == customFieldId;
    });
    if (customField) return { ...customField, slingshot_field_id: sf.id };
  });

  return leadFields.filter((item) => {
    return !!item;
  });
};

import React from 'react';
import { Col } from 'react-bootstrap';
import { dateToAbbreviatedDateTimeString } from '../../MUI/services/dateTime';
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Typography,
  TableBody,
  Grid,
  CircularProgress,
} from '@mui/material';
const AppointmentList = ({ appointmentdata }) => {
  return (
    <>
      <Col>
        <strong>Appointments</strong>
      </Col>
      <>
        {appointmentdata ? (
          <TableContainer sx={{ mb: 2 }}>
            <Table style={{ width: 600 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: '16px' }}>Start Time</TableCell>
                  <TableCell>End Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appointmentdata.length === 0 ? (
                  <TableRow>
                    <TableCell sx={{ padding: '16px' }}>
                      <Typography component={'span'} variant="subtitle3">
                        None
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography component={'span'} variant="subtitle3">
                        None
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  appointmentdata.map((appointment, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ padding: '16px' }}>
                        <Typography component={'span'} variant="subtitle3">
                          {appointment?.start_time
                            ? dateToAbbreviatedDateTimeString(appointment.start_time)
                            : 'None'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'span'} variant="subtitle3">
                          {appointment?.ends_at
                            ? dateToAbbreviatedDateTimeString(appointment.ends_at)
                            : 'None'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Grid container sx={{ my: 3 }} justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        )}
      </>
    </>
  );
};

export default AppointmentList;

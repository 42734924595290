import Cookies from 'js-cookie';

export default class Opportunities {
  static async edit(opportunity) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/leads/${opportunity.lead_id}/opportunities/${opportunity.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(opportunity),
      }
    );
    return response.json();
  }
}

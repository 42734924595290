import Cookies from 'js-cookie';
import { toJSON } from '../utils/utils';
import { toParamString } from '../../MUI/services/queryClientConfig';

export default class LeadLinks {
  static async list(params = {}) {
    return await fetch(
      `${process.env.REACT_APP_CALENDAR_SERVICE}/lead_links?${toParamString(
        params
      )}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(toJSON);
  }
}

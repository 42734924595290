import { useMutation, useQueryClient } from 'react-query';
import SharedSequences from './SharedSequences';
import { useOrg } from '../../../contexts/OrgProvider';

export const useSharedSequencesCreate = (options) => {
  const queryClient = useQueryClient();
  const { id } = useOrg();
  return useMutation(
    (sharedSequence) =>
      SharedSequences.create({ organization_id: id, ...sharedSequence }),
    {
      ...options,
    }
  );
};

//TODO RQ change janky communication refresh
import React, { useContext, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ClipLoader from 'react-spinners/ClipLoader';
import { BiMicrophone, BiMicrophoneOff } from 'react-icons/bi';
import { ImPhone, ImPhoneHangUp } from 'react-icons/im';
import { IoMdKeypad } from 'react-icons/io';
import { GrNotes } from 'react-icons/gr';
import CallOutcome from './CallOutcome';
import LeadStatus from './LeadStatus';
import AppContext from '../../contexts/AppContext';
import Call from '../../clients/Call';
import CallAssist from './CallAssist';
import PhoneButton from './PhoneButton';
import PhoneKeypad from './PhoneKeypad';
import { useHistory } from 'react-router';
import { getLeadName, minutesUntilJwtExpires } from '../../util/AppUtils';
import useTwilioDevice from '../../hooks/useTwilioDevice';
import { getLeadPrimaryPhoneNumber } from '../../util/LeadUtils';
import { getMediaDevicePermission, getUserTaskPhone } from './phoneUtils';
import useQueryClientUtil from '../../api/utils/queryClient/useQueryClientUtil';
import { useQueryClient } from 'react-query';
import { tasksQueryKeys } from '../../MUI/services/tasks/queries';
import { leadsQueryKeys } from '../../MUI/services/leads/queries';
import { useLeadLocksCreate } from '../../MUI/services/leadLocks/mutations';
import { useLeadLocksList } from '../../MUI/services/leadLocks/queries';
import { getLockStatus, LOCK_BY_USER } from '../tasks/TaskUtils';
import { MdOutlineMinimize } from 'react-icons/md';
import { red } from '@mui/material/colors';
import { FiMaximize2 } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { minimizeCall, updateDeviceStatus } from '../../redux/actions';

const PhoneCall = ({ task, lead, show, closeModal }) => {
  const { user, twilioToken, isLoadingTwilioToken, createTwilioToken } = useContext(
    AppContext
  );
  const { refetchTodoAndCompletedTasks } = useQueryClientUtil();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [activeConnection, setActiveConnection] = useState();
  const [showPhoneComplete, setShowPhoneComplete] = useState(false);
  const [muted, setMuted] = useState(false);
  const [callSid, setCallSid] = useState('');
  const [callId, setCallId] = useState('');
  const [showKeypad, setShowKeypad] = useState(false);
  const [warning, setWarning] = useState();
  const [isLeadLockedToUser, setIsLeadLockedToUser] = useState(false);
  const [hasMediaPermission, setHasMediaPermission] = useState();
  const { mutate: createLeadLock, isSuccess: isLeadLocked } = useLeadLocksCreate();
  const dispatch = useDispatch();
  const deviceStatus = useSelector((state) => state.phoneCall.deviceStatus);
  const isMinimized = useSelector((state) => state.phoneCall.isMinimized);
  const [isLockLost, setisLockLost] = useState(false);
  const { data: locks, refetch: refetchLockList } = useLeadLocksList({
    onSettled: (data) => {
      setIsLeadLockedToUser(getLockStatus(lead, data, user) === LOCK_BY_USER);
    },
  });

  // just in case, check token time left on load and then every 30 seconds
  useEffect(() => {
    if (twilioToken && minutesUntilJwtExpires(twilioToken) <= 5) {
      createTwilioToken();
    }
    const i = setInterval(() => {
      if (twilioToken && minutesUntilJwtExpires(twilioToken) <= 5) {
        createTwilioToken();
      }
    }, 30000);
    return () => clearInterval(i);
  }, [twilioToken]);

  useEffect(() => {
    createLeadLock({
      lead_id: lead.id,
      user_id: user.id,
      organization_id: lead.organization_id,
    });

    if (isLeadLocked) setIsLeadLockedToUser(true);

    getMediaDevicePermission(setHasMediaPermission, setWarning);

    if (locks && !locks.length) setisLockLost(true);
  }, []);

  useEffect(() => {
    if (locks && !locks.length) setisLockLost(true);
  }, [locks]);

  useEffect(() => {
    let i;
    if (!activeConnection) {
      i = setInterval(() => {
        Call.list({
          direction: 'out',
          lead_id: lead.id,
          organization_id: lead.organization_id,
        }).then((calls) => {
          if (calls.length > 0) {
            const FIVE_MINUTES = 5 * 60 * 1000;
            calls.forEach((call) => {
              const d = new Date(call.created_at);
              if (new Date() - d < FIVE_MINUTES) {
                setShowPhoneComplete(true);
                setCallId(call.id);
                markTaskComplete();
                clearInterval(i);
              }
            });
          }
        });
      }, 15000);
    }
    return () => clearInterval(i);
  }, [activeConnection]);

  useEffect(() => {
    if (!callSid) {
      return;
    }
    Call.list({ sid: callSid, organization_id: lead.organization_id }).then(
      (calls) => {
        if (calls.length > 0) {
          setCallId(calls[0].id);
        }
      }
    );
  }, [callSid]);

  useEffect(() => {
    if (deviceStatus === 'Connected') {
      setShowPhoneComplete(true);
    }
  }, [deviceStatus]);

  // DEVICE EVENT HANDLERS
  const onDeviceReady = () => {
    dispatch(updateDeviceStatus('Ready'));
  };

  const onDeviceConnect = (connection) => {
    dispatch(updateDeviceStatus('Connected'));
    setCallSid(connection.parameters.CallSid);
  };

  const onDeviceDisconnect = () => {
    dispatch(updateDeviceStatus('Ready'));
    setActiveConnection();
  };

  const handleMinimizeCall = () => {
    dispatch(minimizeCall());
  };

  const onDeviceOffline = (device) => {
    dispatch(updateDeviceStatus('Offline'));
  };

  const onDeviceError = (error) => {
    dispatch(updateDeviceStatus('Error'));
  };

  const onAudioDeviceChange = (media) => {
    getMediaDevicePermission(setHasMediaPermission, setWarning);
  };

  // DEVICE INSTANCE
  const { deviceStartConnection, deviceDisconnectAll } = useTwilioDevice({
    twilioToken,
    onDeviceReady,
    onDeviceOffline,
    onDeviceConnect,
    onDeviceDisconnect,
    onDeviceError,
    onAudioDeviceChange,
  });

  // CLOSING LOGIC
  const handleCloseModal = () => {
    if (activeConnection) {
      setActiveConnection();
    }
    if (task && callId) {
      queryClient.invalidateQueries(tasksQueryKeys.all);
      history.push({ pathname: '/tasks' });
    }
    closeModal();
    history.push({ state: 'refreshLeads' });
    queryClient.invalidateQueries(leadsQueryKeys.all);
  };

  const markTaskComplete = () => {
    if (task) {
      refetchTodoAndCompletedTasks();
    }
  };

  // CLICK HANDLERS
  const handleStartCallClick = () => {
    markTaskComplete();
    dispatch(updateDeviceStatus('Calling...'));
    const connection = deviceStartConnection({
      phone_number: getLeadPrimaryPhoneNumber(lead),
      team_id: lead.team_id,
      lead_id: lead.id,
      user_id: user.id,
    });
    setActiveConnection(connection);
    queryClient.invalidateQueries(leadsQueryKeys.shows());
  };

  const handleEndCallClick = () => {
    deviceDisconnectAll();
  };

  const toggleMuteClick = () => {
    if (activeConnection != undefined) {
      setMuted((prev) => {
        activeConnection.mute(!prev);
        return !prev;
      });
    }
  };

  const sendDigitClick = (digit) => {
    if (activeConnection != undefined) activeConnection.sendDigits(digit);
  };

  const lockTheLead = () => {
    createLeadLock({
      lead_id: lead.id,
      user_id: user.id,
      organization_id: lead.organization_id,
    });

    if (isLeadLocked) {
      setisLockLost(false);
      refetchLockList();
    }
  };

  return (
    <Dialog
      open={show}
      onClose={() => {}}
      maxWidth={'max-content'}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderRadius: '0.8rem',
          backdropFilter: 'blur(0) !important',
        },
      }}
      style={{
        transform: 'translate(-50%, -50%)',
        position: 'fixed',
        zIndex: 1300,
        width: '10% !important',
        height: 'fit-content',
        top: '50%',
        left: '50%',
        overflow: 'auto',
        visibility: isMinimized ? 'hidden' : 'visible',
        width: 'max-content',
        display: 'table',
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#34d1b6 !important',
          color: 'white',
        }}
      >
        <Grid container justifyContent="space-between">
          <Grid item></Grid>
          <Grid item>
            {deviceStatus == 'Connected' ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '8px' }}>Call</span>
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: red[500],
                    borderRadius: '50%',
                    display: 'inline-block',
                    marginRight: '8px',
                  }}
                />
                <span style={{ color: red[500] }}>Connected</span>
              </div>
            ) : (
              <span>Call Now</span>
            )}
          </Grid>
          <Grid item>
            {isMinimized ? (
              <IconButton
                size="x-small"
                sx={{ borderRadius: 0, border: '1px solid white', p: 0.5, m: 0 }}
                onClick={handleMinimizeCall}
              >
                <FiMaximize2 color="white" />
              </IconButton>
            ) : (
              <IconButton
                size="x-small"
                sx={{ borderRadius: 0, border: '1px solid white', p: 0.5, m: 0 }}
                onClick={handleMinimizeCall}
              >
                <MdOutlineMinimize color="white" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      <Grid container sx={isMinimized ? { p: 2, display: 'none' } : { p: 2 }}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item sm={12} md={4} sx={{ display: { xs: 'none', md: 'block' } }}>
              <CallAssist
                organization_id={lead.organization_id}
                lead_id={lead.id}
                user_id={user.id}
                template_id={task?.message_template_id}
              />
            </Grid>
            <Grid item xs={12} md={8} sx={{ pl: 2 }}>
              {showPhoneComplete ? (
                <>
                  <Grid container>
                    <Grid item xs={3}>
                      <Grid Container>
                        <Grid item xs={12}>
                          <h6>{getLeadName(lead)}</h6>
                        </Grid>
                        <Grid item xs={12}>
                          {isLoadingTwilioToken ? 'Fetching token...' : deviceStatus}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={9}>
                      <Grid container justifyContent="space-evenly">
                        <Grid item>
                          <PhoneButton
                            size="small"
                            icon={muted ? <BiMicrophoneOff /> : <BiMicrophone />}
                            label="Mute"
                            onClick={toggleMuteClick}
                          />
                        </Grid>
                        <Grid item>
                          {activeConnection ? (
                            <PhoneButton
                              size="small"
                              label="End Call"
                              color="error"
                              onClick={handleEndCallClick}
                              icon={<ImPhoneHangUp />}
                            />
                          ) : (
                            <PhoneButton
                              size="small"
                              disabled={
                                deviceStatus !== 'Ready' || !hasMediaPermission
                              }
                              label="Start Call"
                              color="success"
                              onClick={handleStartCallClick}
                              icon={<ImPhone />}
                            />
                          )}
                        </Grid>
                        <Grid item>
                          <PhoneButton
                            size="small"
                            icon={<IoMdKeypad />}
                            label="Keypad"
                            onClick={() => setShowKeypad(!showKeypad)}
                          />
                        </Grid>
                        <Grid item>
                          <PhoneButton
                            size="small"
                            icon={<GrNotes />}
                            label="Notes"
                            onClick={() => setShowPhoneComplete(!showPhoneComplete)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <hr />
                  {showKeypad ? (
                    <PhoneKeypad sendDigitClick={sendDigitClick} />
                  ) : (
                    <Grid container>
                      <Grid item xs={12}>
                        <CallOutcome call_id={callId} />
                      </Grid>
                      <Grid item xs={12} sx={{ marginTop: '12px' }}>
                        <LeadStatus
                          lead={lead}
                          handleCloseModal={handleCloseModal}
                        />
                      </Grid>
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  {' '}
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <PhoneErrorAlert phoneStatusDisplay={deviceStatus} />
                    <PhoneWarningAlert warning={warning} />
                    <Grid item>
                      <Typography variant="h5">{getLeadName(lead)}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        {isLoadingTwilioToken ? 'Fetching token...' : deviceStatus}
                      </Typography>
                    </Grid>
                  </Grid>
                  {showKeypad && <PhoneKeypad sendDigitClick={sendDigitClick} />}
                  <Grid container justifyContent="space-evenly" sx={{ mt: 2 }}>
                    <Grid item>
                      <PhoneButton
                        size="large"
                        icon={muted ? <BiMicrophoneOff /> : <BiMicrophone />}
                        label="Mute"
                        onClick={toggleMuteClick}
                      />
                    </Grid>
                    <Grid item>
                      {activeConnection ? (
                        <PhoneButton
                          size="large"
                          label="End Call"
                          color="error"
                          onClick={handleEndCallClick}
                          icon={<ImPhoneHangUp />}
                        />
                      ) : (
                        <PhoneButton
                          size="large"
                          disabled={deviceStatus !== 'Ready' || !hasMediaPermission}
                          label="Start Call"
                          color="success"
                          onClick={handleStartCallClick}
                          icon={<ImPhone />}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      <PhoneButton
                        size="large"
                        icon={<IoMdKeypad />}
                        label="Keypad"
                        onClick={() => setShowKeypad(!showKeypad)}
                      />
                    </Grid>
                    <Grid item>
                      <PhoneButton
                        size="large"
                        icon={<GrNotes />}
                        label="Notes"
                        onClick={() => setShowPhoneComplete(!showPhoneComplete)}
                      />
                    </Grid>
                  </Grid>
                  <hr />
                  {isLockLost ? (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      sx={{ p: 3 }}
                    >
                      <Grid item>
                        Lead is not locked to you. Do you want to lock it?
                        <Button
                          sx={{
                            fontWeight: 300,
                            fontSize: '16px',
                            padding: 0,
                            marginLeft: 1,
                            top: -1.5,
                            textDecoration: 'underline',
                          }}
                          variant={'link'}
                          onClick={() => lockTheLead()}
                        >
                          Lock the lead
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      sx={{ p: 3 }}
                    >
                      {isLeadLockedToUser ? (
                        <Grid item>
                          Or connect by calling{' '}
                          <a
                            href={`tel:${user.task_phone && user.task_phone.number}`}
                          >
                            {getUserTaskPhone(user)}
                          </a>{' '}
                          from your desk or mobile phone
                        </Grid>
                      ) : (
                        <Grid item sx={{ pt: 1 }}>
                          <ClipLoader size={70} color="#34D1B6" />
                        </Grid>
                      )}
                    </Grid>
                  )}
                  <Grid container>
                    <Grid item xs={6} sx={{ p: 1 }}>
                      <Button
                        fullWidth={true}
                        size="md"
                        variant="outlined"
                        onClick={handleCloseModal}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={6} sx={{ p: 1 }}>
                      <LoadingButton
                        fullWidth={true}
                        size="md"
                        loading={!isLeadLockedToUser}
                        href={`tel:${user?.task_phone?.number}`}
                      >
                        {getUserTaskPhone(user)}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default PhoneCall;

const PhoneErrorAlert = ({ phoneStatusDisplay }) => {
  if (phoneStatusDisplay != 'Error') return <></>;
  return <Alert severity="error">There was an error with the phone call.</Alert>;
};

const PhoneWarningAlert = ({ warning }) => {
  if (!warning) return <></>;
  return <Alert severity="error">{warning}</Alert>;
};

import React, { useState, useContext, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import LeadBatch from '../../../clients/LeadBatch';
import ClipLoader from 'react-spinners/ClipLoader';
import useQueryClientUtil from '../../../api/utils/queryClient/useQueryClientUtil';
import AppContext from '../../../contexts/AppContext';
import { useTeamsList } from '../../../MUI/services/teams/queries';
import { useOrg } from '../../../contexts/OrgProvider';

const CompleteContainer = () => {
  const { state } = useLocation();
  // 0: starting, 1: complete, 2: error
  const [batchStatus, setBatchStatus] = useState(0);
  const [leadsCreated, setLeadsCreated] = useState(0);
  const { user } = useContext(AppContext);
  const { refethTasksDueToday } = useQueryClientUtil();
  const { id: orgId } = useOrg();

  const { data: teamsList, isFetching } = useTeamsList([
    ['organization_id[]', orgId],
  ]);

  useEffect(() => {
    if (user.id && state && !isFetching) {
      if (!teamsList || teamsList.length == 0) {
        setBatchStatus(2);
      } else {
        LeadBatch.create({
          team_id: teamsList[0].id,
          import_file_id: state.importFileId,
          task_sequence_id: state.taskSequenceId,
          lead_fields: state.leadFields,
          task_delay: parseInt(state.taskDelay),
        })
          .then((data) => {
            if (!data) {
              setBatchStatus(2);
            } else {
              setLeadsCreated(data.leads_created);
              setBatchStatus(1);
              refethTasksDueToday();
            }
          })
          .catch((error) => {
            setBatchStatus(2);
          });
      }
    }
  }, [state, isFetching]);

  if (state?.leadFields?.length === 0 || !state?.leadFields)
    return (
      <Alert variant="danger">
        It seems you do not have a CSV file uploaded. Please click on Import and
        upload a CSV.
      </Alert>
    );

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className={`pt-0 sm:pt-5 px-0 sm:px-2`}>
          <nav aria-label="breadcrumb">
            <ol className="sigma-breadcrumb">
              <li className="breadcrumb-item">Settings</li>
              <li className="breadcrumb-item">Import</li>
              <li className="breadcrumb-item">Upload</li>
              <li className="breadcrumb-item">Preview</li>
              <li className="breadcrumb-item active">Complete</li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row>
        <Col xs={{ offset: 4, span: 4 }} className="bg-sigma-light p-4">
          {batchStatus == 0 && (
            <Row>
              <Col className="text-center p-5">
                <ClipLoader size={100} color="#34D1B6" />
              </Col>
            </Row>
          )}
          {batchStatus == 1 &&
            `Your upload is complete.  ${leadsCreated} leads were created.`}
          {batchStatus == 2 && (
            <span>
              Upload of leads may have been unsuccessfull. Check the{' '}
              <Link to="/leads">leads page.</Link> Please contact
              support@leadsigma.com for guidance.
            </span>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default CompleteContainer;

import { createStore, combineReducers } from 'redux';

// Define an initial state for the phone call
const initialPhoneCallState = {
  isMinimized: false,
  deviceStatus: 'Initializing...',
};

// Reducer for phone call state
const phoneCallReducer = (state = initialPhoneCallState, action) => {
  switch (action.type) {
    case 'RESET_MINIMIZE_CALL':
      return { ...state, isMinimized: false };
    case 'MINIMIZE_CALL':
      return { ...state, isMinimized: !state.isMinimized };
    case 'UPDATE_DEVICE_STATUS':
      return { ...state, deviceStatus: action.payload };
    default:
      return state;
  }
};

// Combine all reducers
const rootReducer = combineReducers({
  phoneCall: phoneCallReducer,
});

// Create the Redux store
const store = createStore(rootReducer);

export default store;

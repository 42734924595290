import Cookies from 'js-cookie';
import { handleApiErrors } from '../util/HandleApiErrors';
import { getActiveOrg } from '../util/UserUtils';

export default class MessageTemplate {
  static async list() {
    return await fetch(
      `${
        process.env.REACT_APP_API_BASE
      }/message_templates?organization_id=${getActiveOrg()}&page_size=1000`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(handleApiErrors);
  }

  static async show(template_id) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/message_templates/${template_id}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(handleApiErrors);
  }

  static async render(templateId, userId, leadId) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/rendered_message_templates/${templateId}?user_id=${userId}&lead_id=${leadId}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(handleApiErrors);
  }

  static async create(template) {
    template['organization_id'] = getActiveOrg();
    return await fetch(`${process.env.REACT_APP_API_BASE}/message_templates`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(template),
    }).then(handleApiErrors);
  }

  static async edit(template) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/message_templates/${template.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(template),
      }
    ).then(handleApiErrors);
  }

  static async delete(template_id, new_message_template_id) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/message_templates/${template_id}?new_message_template_id=${new_message_template_id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(handleApiErrors);
  }
}

import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Button,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
} from '@mui/material';
import HookForm from '../components/HookForm';
import { LoadingButton } from '@mui/lab';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { PlusIcon, EditIcon, DeleteIcon } from '../theme/icons';
import { Link } from 'react-router-dom';
import { useCalendarsList } from '../services/calendars/queries';
import { useOrg } from '../../contexts/OrgProvider';
import {
  useCalendarsCreate,
  useCalendarsDelete,
} from '../services/calendars/mutations';
import { fireDialog } from '../components/Dialog';
import { useCalendarsEdit } from '../services/calendars/mutations';

export default function CalendarsList() {
  const { id: orgId } = useOrg();
  const { data: calendardata, isLoading, isError } = useCalendarsList([
    { organization_id: orgId, vendor: 'leadsigma' },
  ]);

  const onCreate = () => {
    fireDialog((promiseProps) => AddCalendar({ orgId, ...promiseProps }));
  };
  const onEditClick = (calendarData) => {
    fireDialog((promiseProps) => EditCalendar({ calendarData, ...promiseProps }));
  };
  const onDeleteClick = (calendarId) => {
    fireDialog((promiseProps) => DeleteCalendar({ calendarId, ...promiseProps }));
  };
  return (
    <SettingsCard showLoading={isLoading}>
      <Box sx={{ height: '100%' }}>
        <SettingsCard.Header>Calendars</SettingsCard.Header>
        <SettingsCard.Main>
          <TableContainer sx={{ mb: 2 }}>
            <Table>
              <TableHead>
                <TableRow size="small">
                  <TableCell>Name</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {calendardata?.items.map((calendarData) => (
                  <TableRow key={calendarData.id}>
                    <TableCell>
                      <Typography component={'span'} variant="subtitle3">
                        {calendarData.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        variant="text"
                        color="primary"
                        sx={{ marginRight: 1 }}
                        component={Link}
                        to={`/settings/calendars/${calendarData.id}`}
                      >
                        Availability
                      </Button>
                      <Button
                        startIcon={<EditIcon />}
                        size="small"
                        variant="text"
                        color="primary"
                        sx={{ marginRight: 1 }}
                        onClick={() => onEditClick(calendarData)}
                      >
                        Edit
                      </Button>
                      <Button
                        startIcon={<DeleteIcon />}
                        size="small"
                        variant="text"
                        color="error"
                        sx={{ marginRight: 1 }}
                        onClick={() => onDeleteClick(calendarData.id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            startIcon={<PlusIcon />}
            onClick={onCreate}
            sx={{ marginRight: 1 }}
          >
            Add New
          </Button>
        </SettingsCard.Main>
      </Box>
    </SettingsCard>
  );
}

const AddCalendar = ({ orgId, isOpen, onReject, onResolve }) => {
  const {
    mutate: createCalendar,
    isError,
    isSuccess,
    isLoading,
  } = useCalendarsCreate();
  const [calendarName, setCalendarName] = useState();

  const handleCreateSubmit = (formData) => {
    let appointment_in_sec = '';

    if (formData.appointment_duration) {
      const appointmentDuration_min = formData.appointment_duration.split(' ');
      appointment_in_sec = parseInt(appointmentDuration_min[0], '') * 60;
    }
    createCalendar({
      organization_id: orgId,
      name: calendarName,
      vendor: 'leadsigma',
      timezone: formData.timeZone,
      config_items: [
        {
          name: 'appointment_duration',
          value: appointment_in_sec,
        },
      ],
    });
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        onResolve();
      }, 1000);
    }
  }, [isError, isSuccess]);

  const defaultValues = {
    name: '',
    timeZone: 'America/Chicago',
  };

  const useFormProps = {
    defaultValues,
  };

  const possibleTimezones = [
    'Pacific/Honolulu',
    'America/Los_Angeles',
    'America/Phoenix',
    'America/Denver',
    'America/Chicago',
    'America/New_York',
    'America/Puerto_Rico',
  ];

  const appointmentDuration = [
    '5 Minutes',
    '10 Minutes',
    '15 Minutes',
    '20 Minutes',
    '25 Minutes',
    '30 Minutes',
    '35 Minutes',
    '40 Minutes',
    '45 Minutes',
    '50 Minutes',
    '55 Minutes',
    '60 Minutes',
    '65 Minutes',
    '70 Minutes',
    '75 Minutes',
    '80 Minutes',
    '85 Minutes',
    '90 Minutes',
    '95 Minutes',
    '100 Minutes',
    '105 Minutes',
    '110 Minutes',
    '115 Minutes',
    '120 Minutes',
  ];

  return (
    <Box>
      <Dialog open={isOpen} onClose={onReject}>
        <DialogTitle>Create Calendar</DialogTitle>
        <HookForm useFormProps={useFormProps} onSubmit={handleCreateSubmit}>
          <DialogContent>
            {isError && (
              <Alert severity="error">
                There was a problem while creating calendar.
              </Alert>
            )}
            {isSuccess && (
              <Alert severity="success">Calendar created successfully.</Alert>
            )}
            <Box display="flex" flexDirection="column" rowGap={2}>
              <TextField
                label="Name"
                type="text"
                fullWidth
                value={calendarName || ''}
                onChange={(e) => setCalendarName(e.target.value)}
              />
              <HookForm.Select name="timeZone" label="Time Zone">
                {possibleTimezones.map((zone) => (
                  <MenuItem value={zone} key={zone}>
                    {zone}
                  </MenuItem>
                ))}
              </HookForm.Select>
              <HookForm.Select
                name="appointment_duration"
                label="Appointment Duration"
              >
                {appointmentDuration.map((duration) => (
                  <MenuItem value={duration} key={duration}>
                    {duration}
                  </MenuItem>
                ))}
              </HookForm.Select>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="text" color="inherit" onClick={onReject}>
              Cancel
            </Button>
            <LoadingButton type="submit" loading={isLoading} disabled={isSuccess}>
              Confirm
            </LoadingButton>
          </DialogActions>
        </HookForm>
      </Dialog>
    </Box>
  );
};

const EditCalendar = ({ calendarData, isOpen, onReject, onResolve }) => {
  const { mutate: calendarEdit, isLoading, isError, isSuccess } = useCalendarsEdit();
  const [calendarName, setupdatedCalendarName] = useState();
  const [defaultappointmentmin, SetDefaultappointmentmin] = useState();
  const [intervalDisplay, setIntervalDisplay] = useState();

  useEffect(() => {
    const appoint_duration_minutes = calendarData?.config_items.find(
      (item) => item.name === 'appointment_duration'
    );
    const interval_display_time = calendarData?.config_items.find(
      (item) => item.name === 'interval_display'
    );

    const update_interval_display = IntervalDisplay.find(
      (item) => item.split(' ')[0] === interval_display_time?.value
    );

    setIntervalDisplay(update_interval_display);

    if (appoint_duration_minutes) {
      const default_appointment_min =
        Math.floor(appoint_duration_minutes.value / 60) + ' ' + 'Minutes';
      SetDefaultappointmentmin(default_appointment_min);
    }
  }, [calendarData]);

  const handleCreateSubmit = (formData) => {
    let appointment_in_sec = '';
    let interval_display_min = '';

    if (formData.appointment_duration) {
      SetDefaultappointmentmin(formData.appointment_duration);
      const appointmentDuration_min = formData.appointment_duration.split(' ');
      appointment_in_sec = parseInt(appointmentDuration_min[0]) * 60;
    }

    if (formData.interval_display) {
      const interval_display_time = formData.interval_display.split(' ');
      interval_display_min = interval_display_time[0];
    }

    calendarEdit({
      id: calendarData.id,
      name: calendarName || calendarData.name,
      vendor: formData.vendor,
      timezone: formData.timeZone,
      config_items: [
        {
          name: 'appointment_duration',
          value: appointment_in_sec,
        },
        {
          name: 'interval_display',
          value: interval_display_min,
        },
      ],
    });
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        onResolve();
      }, 1000);
    }
  }, [isError, isSuccess]);

  const defaultValues = {
    name: '',
    timeZone: calendarData.timezone || '',
    appointment_duration: defaultappointmentmin || '',
    interval_display: intervalDisplay || '',
  };

  const useFormProps = {
    defaultValues,
  };

  const possibleTimezones = [
    'Pacific/Honolulu',
    'America/Los_Angeles',
    'America/Phoenix',
    'America/Denver',
    'America/Chicago',
    'America/New_York',
    'America/Puerto_Rico',
  ];

  const appointmentDuration = [
    '5 Minutes',
    '10 Minutes',
    '15 Minutes',
    '20 Minutes',
    '25 Minutes',
    '30 Minutes',
    '35 Minutes',
    '40 Minutes',
    '45 Minutes',
    '50 Minutes',
    '55 Minutes',
    '60 Minutes',
    '65 Minutes',
    '70 Minutes',
    '75 Minutes',
    '80 Minutes',
    '85 Minutes',
    '90 Minutes',
    '95 Minutes',
    '100 Minutes',
    '105 Minutes',
    '110 Minutes',
    '115 Minutes',
    '120 Minutes',
  ];

  const IntervalDisplay = [
    '00:05:00  5 Minutes',
    '00:10:00  10 Minutes',
    '00:15:00  15 Minutes',
    '00:30:00  30 Minutes',
    '00:60:00  60 Minutes',
  ];

  return (
    <Box>
      <Dialog open={isOpen} onClose={onReject}>
        <DialogTitle>Edit Calendar</DialogTitle>
        <HookForm useFormProps={useFormProps} onSubmit={handleCreateSubmit}>
          <DialogContent>
            {isError && (
              <Alert severity="error">
                There was a problem while updating calendar.
              </Alert>
            )}
            {isSuccess && (
              <Alert severity="success">Calendar updated successfully.</Alert>
            )}
            <Box display="flex" flexDirection="column" rowGap={2}>
              <TextField
                label="Name"
                type="text"
                fullWidth
                defaultValue={calendarData.name || ''}
                onChange={(e) => setupdatedCalendarName(e.target.value)}
              />
              <HookForm.Select name="timeZone" label="Time Zone">
                {possibleTimezones.map((zone) => (
                  <MenuItem value={zone} key={zone}>
                    {zone}
                  </MenuItem>
                ))}
              </HookForm.Select>
              <HookForm.Select
                name="appointment_duration"
                label="Appointment Duration"
              >
                {appointmentDuration.map((duration) => (
                  <MenuItem value={duration} key={duration}>
                    {duration}
                  </MenuItem>
                ))}
              </HookForm.Select>
              <HookForm.Select name="interval_display" label="Interval Display">
                {IntervalDisplay.map((interval) => (
                  <MenuItem value={interval} key={interval}>
                    {interval}
                  </MenuItem>
                ))}
              </HookForm.Select>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="text" color="inherit" onClick={onReject}>
              Cancel
            </Button>
            <LoadingButton type="submit" loading={isLoading} disabled={isSuccess}>
              Confirm
            </LoadingButton>
          </DialogActions>
        </HookForm>
      </Dialog>
    </Box>
  );
};

const DeleteCalendar = ({ calendarId, isOpen, onReject, onResolve }) => {
  const {
    mutate: deleteCalendar,
    isError,
    isSuccess,
    isLoading,
  } = useCalendarsDelete();

  const handledeleteSubmit = () => {
    deleteCalendar(calendarId);
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        onResolve();
      }, 1000);
    }
  }, [isError, isSuccess]);

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Calendar</DialogTitle>
      <DialogContent>
        {isError && (
          <Alert severity="error">
            There was a problem while deleteing calendar.
          </Alert>
        )}
        {isSuccess && <Alert severity="success">Deleted successfully.</Alert>}
        Are you sure to delete calendar.
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          loading={isLoading}
          disabled={isSuccess}
          onClick={handledeleteSubmit}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

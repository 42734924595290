import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';

export const DataGridStyle = styled(DataGrid)(({ theme }) => ({
  backgroundColor: 'white',
  '& .MuiDataGrid-footerContainer': {
    justifyContent: 'flex-start',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },

  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.grey[100],
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& 	.MuiDataGrid-virtualScrollerContent:hover': {
    cursor: 'pointer',
  },
}));

import { useQuery } from 'react-query';

import { useOrg } from '../../contexts/OrgProvider';
import { ONE_HOUR } from '../../MUI/services/queryClientConfig';
import Rules from './Rules';

export const useRules = (options) => {
  const { id } = useOrg();
  return useQuery(
    ['rules'],
    () => Rules.list({ organization_id: id, page_size: 1000 }),
    {
      staleTime: ONE_HOUR,
      ...options,
    }
  );
};

import { useQuery } from 'react-query';
import { queryKeyFactory } from '../../MUI/services/queryClientConfig';
import AIModulesFunctionDefinitions from './AIModulesFunctionDefinitions';

export const aimodulesFunctionDefinitionsQueryKeys = queryKeyFactory(
  'ai-modules-function-definition'
);

export const useAiModuleFunctionDefinitionsList = (params, options) => {
  return useQuery(
    aimodulesFunctionDefinitionsQueryKeys.list(),
    () => AIModulesFunctionDefinitions.list(params),
    {
      staleTime: 0,
      ...options,
    }
  );
};

export const useAiModuleFunctionDefinitionsShow = (moduleID, options) => {
  return useQuery(
    aimodulesFunctionDefinitionsQueryKeys.list(moduleID),
    () => AIModulesFunctionDefinitions.show(moduleID),
    {
      staleTime: 0,
      ...options,
    }
  );
};

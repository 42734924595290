import { useQuery } from 'react-query';
import { queryKeyFactory } from '../../MUI/services/queryClientConfig';
import AIMappigs from './AIMappings';

export const aimappingsQueryKeys = queryKeyFactory('ai-mappings');

export const useAIMappingsList = (params, options) => {
  return useQuery(aimappingsQueryKeys.list(), () => AIMappigs.list(params), {
    staleTime: 0,
    ...options,
  });
};

export const useAIMappingsShow = (mappingID, options) => {
  return useQuery(aimappingsQueryKeys.list(), () => AIMappigs.show(mappingID), {
    staleTime: 0,
    ...options,
  });
};

import 'animate.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import { useContext } from 'react';
import AppContext from '../../contexts/AppContext';
import { useHistory } from 'react-router-dom';
import { useLeadLocksList } from '../../MUI/services/leadLocks/queries';
import { useLeadLocksDelete } from '../../MUI/services/leadLocks/mutations';

const UnlockUserLockModal = ({
  showSelfUnlock,
  handleCloseUnlock,
  lead,
  pipeline,
  tasks,
}) => {
  const history = useHistory();
  const { data: locks } = useLeadLocksList();
  const { mutate: deleteLock } = useLeadLocksDelete();
  const unlock = () => {
    handleCloseUnlock();
    const lock = locks.find((lock) => lock.lead_id == lead.id);
    deleteLock(lock.id, {
      onSuccess: () => {
        if (pipeline) history.push('/leads');
        if (tasks) history.push('/tasks');
      },
    });
  };

  return (
    <Modal
      show={showSelfUnlock}
      onHide={handleCloseUnlock}
      className={'modal-sigma'}
    >
      <Modal.Header className={'text-center'}>
        <Modal.Title className={'w-100'}>Unlock Lead</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {lead.first_name} {lead.last_name} is currently locked by you.
        <br />
        <br />
        Do you want to release this lock?
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row>
            <Col xs={'6'}>
              <button
                type={'button'}
                className={'btn btn-light btn-block'}
                onClick={handleCloseUnlock}
              >
                Cancel
              </button>
            </Col>
            <Col xs={'6'}>
              <button onClick={unlock} className={'btn btn-sigma-primary btn-block'}>
                Release
              </button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

export default UnlockUserLockModal;

import Cookies from 'js-cookie';
import { toJSON, toParamString } from '../queryClientConfig';

export default class Calls {
  static async list(filters = []) {
    return await fetch(
      `${process.env.REACT_APP_IVR}/calls?${toParamString(filters)}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async show(call_id) {
    return await fetch(`${process.env.REACT_APP_IVR}/calls/${call_id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(toJSON);
  }
}

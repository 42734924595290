import Cookies from 'js-cookie';
import { getActiveOrg } from '../util/UserUtils';

export default class Note {
  static async create(note) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/notes`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        organization_id: getActiveOrg(),
        lead_id: note.lead_id,
        user_id: note.user_id,
        value: note.value,
      }),
    });
    return response.json();
  }

  static async edit(note) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/notes/${note.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(note),
      }
    );
    return response.json();
  }

  static async delete(note_id) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/notes/${note_id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    return response;
  }
}

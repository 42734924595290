import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Switch } from 'react-router-dom';
import { AuthContainer } from './AuthContainer';
import OrgProvider from './contexts/OrgProvider';
import { AppContextProvider } from './contexts/AppContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import DynamicRouteLoader from './contexts/DynamicRouteLoader';
import MuiThemeProvider from './MUI/theme/MuiThemeProvider';
import UserCacheGuard from './contexts/UserCacheGuard';
import { Provider } from 'react-redux'; // Import the Provider component
import store from './redux/store';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Switch>
            <MuiThemeProvider>
              <AuthContainer>
                <DynamicRouteLoader>
                  <UserCacheGuard>
                    <OrgProvider>
                      <AppContextProvider>
                        <App />
                      </AppContextProvider>
                    </OrgProvider>
                  </UserCacheGuard>
                </DynamicRouteLoader>
              </AuthContainer>
            </MuiThemeProvider>
          </Switch>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={true} />
      </QueryClientProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

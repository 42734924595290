import Cookies from 'js-cookie';
import { toJSON } from '../queryClientConfig';

export default class AuthorizedUsers {
  static async create(mailbox) {
    return await fetch(`${process.env.REACT_APP_EMAIL_SERVICE}/authorized_users`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(mailbox),
    }).then(toJSON);
  }

  static async delete(id) {
    return await fetch(
      `${process.env.REACT_APP_EMAIL_SERVICE}/authorized_users/${id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
  }
}

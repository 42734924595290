import React, { useState, useEffect } from 'react';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import {
  Box,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  Tab,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { EditIcon, DeleteIcon, PlusIcon } from '../theme/icons';
import { LoadingButton } from '@mui/lab';
import { TabContext, TabList } from '@mui/lab';
import {
  useFewShotsCreate,
  useFewShotsEdit,
  useFewShotsDelete,
} from '../../api/fewShots/mutations';
import { useFewShotsList } from '../../api/fewShots/queries';
import { useAiProfileList } from '../../api/aiprofile/queries';
import { useOrg } from '../../contexts/OrgProvider';
import { fireDialog } from '../components/Dialog';
import HookForm from '../components/HookForm';
import { Link, useHistory } from 'react-router-dom';
import {
  useAiProfileDelete,
  useAiProfileCreate,
} from '../../api/aiprofile/mutations';
import { AiUpsellModal } from '../components/CallTracking/UpsellModal';
import { useOrganizationServices } from '../services/organizations/queries';
import { useCalendarsList } from '../services/calendars/queries';
import AIModulesPage from './AIModulesPage';
import { useAiModulesList } from '../../api/aiModules/queries';

export default function AIPage() {
  const [fewshotsList, setfewshotsList] = useState([]);
  const { id: orgId } = useOrg();
  const { data: organizationServicesList } = useOrganizationServices(orgId);
  const [isAIEnabled, setIsAIEnabled] = useState(true);
  const { data: fewshotslist } = useFewShotsList({
    organization_id: orgId,
    page_size: 1000,
  });
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (fewshotslist && fewshotslist.items && Array.isArray(fewshotslist.items)) {
      const fewshotsdata = fewshotslist.items.map((item) => ({
        id: item.id,
        user: item.user,
        assistant: item.assistant,
      }));
      if (fewshotsdata.length > 0) {
        setfewshotsList(fewshotsdata);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  }, [fewshotslist]);

  useEffect(() => {
    if (organizationServicesList) {
      const isAIEnabled = organizationServicesList.some(
        (service) => service.service_id == 2
      );
      setIsAIEnabled(isAIEnabled);
    }
  }, [organizationServicesList]);

  const onAddClick = () => {
    fireDialog((promiseProps) => <AddFewShotsDialog {...promiseProps} />);
  };

  const onDeleteClick = (field) => {
    fireDialog((promiseProps) => (
      <DeleteFewShotsDialog field={field} {...promiseProps} />
    ));
  };

  const onEditClick = (field) => {
    fireDialog((promiseProps) => (
      <EditFewShotsDialog field={field} {...promiseProps} />
    ));
  };

  const [currentTab, setCurrentTab] = useState('Agents');
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box sx={{ height: '100%' }}>
      <SettingsCard showLoading={isLoading}>
        <AiUpsellModal enabled={isAIEnabled} />
        <TabContext value={currentTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Agents" value="Agents" />
              <Tab label="Training Data" value="Training Data" />
              <Tab label="Modules" value="Modules" />
            </TabList>
          </Box>
        </TabContext>
        {currentTab === 'Agents' && (
          <div>
            <AiProfile />
          </div>
        )}
        {currentTab === 'Modules' && (
          <div>
            <AIModulesPage />
          </div>
        )}
        {currentTab === 'Training Data' && (
          <div>
            <SettingsCard.Main>
              <TableContainer sx={{ mb: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow size="small">
                      <TableCell>User</TableCell>
                      <TableCell>Assistant</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fewshotsList?.map((field) => (
                      <TableRow key={field.id}>
                        <TableCell>
                          <Typography component={'span'} variant="subtitle3">
                            {field.user}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'span'} variant="subtitle3">
                            {field.assistant}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Button
                            startIcon={<EditIcon />}
                            size="small"
                            variant="text"
                            color="primary"
                            sx={{ marginRight: 1 }}
                            onClick={() => onEditClick(field)}
                          >
                            Edit
                          </Button>
                          <Button
                            startIcon={<DeleteIcon />}
                            size="small"
                            variant="text"
                            color="error"
                            sx={{ marginRight: 1 }}
                            onClick={() => onDeleteClick(field)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                startIcon={<PlusIcon />}
                onClick={onAddClick}
                sx={{ marginRight: 1 }}
              >
                Add New
              </Button>
            </SettingsCard.Main>
          </div>
        )}
      </SettingsCard>
    </Box>
  );
}

const AddFewShotsDialog = ({ isOpen, onReject, onResolve }) => {
  const { id: orgId } = useOrg();
  const {
    mutate: fewshotsCreate,
    isLoading,
    isError: didFailfewshot,
    isSuccess: didSuccessfewshot,
  } = useFewShotsCreate();

  const [userData, setUserData] = useState('');
  const [assistantData, setAssistantData] = useState('');

  useEffect(() => {
    if (didSuccessfewshot && !didFailfewshot) {
      setTimeout(() => {
        onResolve();
      }, 1000);
    }
  }, [didSuccessfewshot, didFailfewshot]);

  const handleCreateSubmit = () => {
    fewshotsCreate({
      organization_id: orgId,
      user: userData,
      assistant: assistantData,
    });
  };

  const defaultValues = {
    user: '',
    assistant: '',
  };

  const useFormProps = {
    defaultValues,
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Training Data</DialogTitle>
      <HookForm useFormProps={useFormProps} onSubmit={handleCreateSubmit}>
        <DialogContent>
          {didFailfewshot && (
            <Alert severity="error">
              There was a problem while adding user and assistant.
            </Alert>
          )}
          {didSuccessfewshot && (
            <Alert severity="success">Created training data successfully.</Alert>
          )}
          <Box display="flex" flexDirection="column" rowGap={2}>
            <TextField
              multiline
              label="User"
              type="text"
              fullWidth
              rows={3}
              onChange={(e) => setUserData(e.target.value)}
            />
            <TextField
              multiline
              label="Assistant"
              type="text"
              fullWidth
              rows={3}
              onChange={(e) => setAssistantData(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            loading={isLoading}
            disabled={didSuccessfewshot}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </HookForm>
    </Dialog>
  );
};

const EditFewShotsDialog = ({ isOpen, onReject, onResolve, field }) => {
  const {
    mutate: fewshotsEdit,
    isLoading: isfewshotseditloading,
    isError: didEditError,
    isSuccess: didEditSuccess,
  } = useFewShotsEdit();

  const [edituserData, setEditUserData] = useState([]);
  const [editassistantData, setEditAssistantData] = useState([]);

  useEffect(() => {
    if (didEditSuccess && !didEditError) {
      setTimeout(() => {
        onResolve();
      }, 1000);
    }
  }, [didEditSuccess, didEditError]);

  const handleEditSubmit = () => {
    fewshotsEdit({
      id: field.id,
      user: edituserData,
      assistant: editassistantData,
    });
  };

  const defaultValues = {
    user: field.user,
    assistant: field.assistant,
  };

  const useFormProps = {
    defaultValues,
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Assistant</DialogTitle>
      <HookForm useFormProps={useFormProps} onSubmit={handleEditSubmit}>
        <DialogContent>
          {didEditError && (
            <Alert severity="error">
              There was a problem while adding user and assistant.
            </Alert>
          )}
          {didEditSuccess && (
            <Alert severity="success">Created training data successfully.</Alert>
          )}
          <Box display="flex" flexDirection="column" rowGap={2}>
            <TextField
              multiline
              label="User"
              type="text"
              fullWidth
              rows={3}
              defaultValue={field.user}
              onChange={(e) => setEditUserData(e.target.value)}
            />
            <TextField
              multiline
              label="Assistant"
              type="text"
              fullWidth
              rows={3}
              defaultValue={field.assistant}
              onChange={(e) => setEditAssistantData(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            loading={isfewshotseditloading}
            disabled={didEditSuccess}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </HookForm>
    </Dialog>
  );
};

const DeleteFewShotsDialog = ({ isOpen, onReject, onResolve, field }) => {
  const {
    mutate: fewshotsDelete,
    isLoading: didDeleteloading,
    isError: didDeleteError,
    isSuccess: didDelteSuccess,
  } = useFewShotsDelete();

  useEffect(() => {
    if (didDelteSuccess && !didDeleteError) {
      setTimeout(() => {
        onResolve();
      }, 1000);
    }
  }, [didDelteSuccess, didDeleteError]);

  const onSubmit = () => {
    fewshotsDelete({ id: field.id });
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Assistant</DialogTitle>
      <DialogContent>
        {didDeleteError && (
          <Alert severity="error">
            There was a problem while deleting training data.
          </Alert>
        )}
        {didDelteSuccess && <Alert severity="success">Deleted successfully.</Alert>}
        Are you sure to delete training data.
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          loading={didDeleteloading}
          disabled={didDelteSuccess}
          onClick={onSubmit}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const AiProfile = () => {
  const { id: orgId } = useOrg();
  const { data: aiprofilelist } = useAiProfileList({ organization_id: orgId });

  const handleDelete = (aiData) => {
    fireDialog((promiseProps) => (
      <DeleteAiAgents aiData={aiData} {...promiseProps} />
    ));
  };

  const handleAiAgent = () => {
    fireDialog((promiseProps) => <AddAiAgents {...promiseProps} />);
  };

  return (
    <Box sx={{ height: '100%' }}>
      <SettingsCard.Main>
        <TableContainer sx={{ mb: 2 }}>
          <Table>
            <TableHead>
              <TableRow size="small">
                <TableCell>Name</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {aiprofilelist?.items.map((aiData) => (
                <TableRow key={aiData.id}>
                  <TableCell>
                    <Typography component={'span'} variant="subtitle3">
                      {aiData.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button
                      startIcon={<EditIcon />}
                      size="small"
                      variant="text"
                      color="primary"
                      sx={{ marginRight: 1 }}
                      component={Link}
                      to={`/settings/AI/${aiData.id}`}
                    >
                      Edit
                    </Button>
                    <Button
                      startIcon={<DeleteIcon />}
                      size="small"
                      variant="text"
                      color="error"
                      sx={{ marginRight: 1 }}
                      onClick={() => handleDelete(aiData.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          startIcon={<PlusIcon />}
          onClick={handleAiAgent}
          sx={{ marginRight: 1 }}
        >
          Add New
        </Button>
      </SettingsCard.Main>
    </Box>
  );
};

const AddAiAgents = ({ isOpen, onReject, onResolve }) => {
  const { id: orgId } = useOrg();
  const {
    mutate: aiProfileCreate,
    isLoading,
    isError,
    isSuccess,
  } = useAiProfileCreate();

  const [aiAgentCreateData, setAiAgentCreateData] = useState({
    temperature: '0.25',
  });
  const {
    data: aiModulesList,
    isLoading: aiModuleLoading,
    isError: aiModuleError,
  } = useAiModulesList();
  const { data: calendarData } = useCalendarsList([{ organization_id: orgId }]);
  const [calendarId, setCalendarId] = useState();
  const [selectedCalendarId, setSelectedCalendarId] = useState(null);
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const history = useHistory();
  useEffect(() => {
    if (calendarData && calendarData.items && Array.isArray(calendarData.items)) {
      const calendar_id = calendarData.items.map((item) => item.id);
      setCalendarId(calendar_id);
    }
  }, [calendarData]);

  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        onResolve();
      }, 300);
    }
  }, [isSuccess, isError]);

  const handleTextChange = (e, field) => {
    const value = e.target.value;
    setAiAgentCreateData({ ...aiAgentCreateData, [field]: value });
  };

  const handleSelectedModuleId = (id, field) => {
    setSelectedModuleId(id);
    setAiAgentCreateData({ ...aiAgentCreateData, [field]: id });
  };

  const handleCreateSubmit = () => {
    aiProfileCreate(
      {
        organization_id: orgId,
        calendar_id: selectedCalendarId,
        name: aiAgentCreateData.name,
        greeting: aiAgentCreateData.greeting,
        ai_module_id: selectedModuleId,
      },
      {
        onSuccess: (data) => {
          history.push(`/settings/AI/${data?.id}`);
        },
      }
    );
  };

  const handleSelectedCalendarId = (id) => {
    setSelectedCalendarId(id);
  };

  const defaultValues = {
    name: '',
    greeting: '',
    calendar_id: '',
    ai_module_id: '',
  };

  const useFormProps = {
    defaultValues,
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>AI Agent</DialogTitle>
      <HookForm useFormProps={useFormProps} onSubmit={handleCreateSubmit}>
        <DialogContent>
          {isError && (
            <Alert severity="error">
              There was a problem while adding llm and name.
            </Alert>
          )}
          {isSuccess && (
            <Alert severity="success">AI Agent created successfully.</Alert>
          )}
          <Box display="flex" flexDirection="column">
            <InputLabel sx={{ marginTop: 1 }}>Name</InputLabel>
            <TextField
              label="Name"
              type="text"
              fullWidth
              value={aiAgentCreateData.name || ''}
              onChange={(e) => handleTextChange(e, 'name')}
            />
            <InputLabel sx={{ marginTop: 1.3 }}>Module</InputLabel>
            <FormControl>
              <Select
                variant={'standard'}
                value={aiAgentCreateData.moduleID || ''}
                onChange={(e) => handleTextChange(e, 'moduleID')}
              >
                {aiModulesList &&
                  aiModulesList?.items.length > 0 &&
                  aiModulesList?.items.map((aiModule) => (
                    <MenuItem
                      key={aiModule.id}
                      value={aiModule.name}
                      onClick={() =>
                        handleSelectedModuleId(aiModule.id, 'modeuleID')
                      }
                    >
                      {aiModule.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <InputLabel sx={{ marginTop: 1.3 }}>Calendar</InputLabel>
            <FormControl>
              <Select
                variant={'standard'}
                value={aiAgentCreateData.calendar_name || ''}
                onChange={(e) => handleTextChange(e, 'calendar_name')}
              >
                {calendarData &&
                  calendarData?.items.length > 0 &&
                  calendarData?.items.map((calendar) => (
                    <MenuItem
                      key={calendar.id}
                      value={calendar.name + ' ' + calendar.vendor}
                      onClick={() => handleSelectedCalendarId(calendar.id)}
                    >
                      {calendar.name + ' ' + calendar.vendor}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <InputLabel sx={{ marginTop: 1 }}>Greeting</InputLabel>
            <TextField
              multiline
              label="Greeting"
              type="text"
              rows={7}
              fullWidth
              value={aiAgentCreateData.greeting || ''}
              onChange={(e) => handleTextChange(e, 'greeting')}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton type="submit" loading={isLoading} disabled={isSuccess}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </HookForm>
    </Dialog>
  );
};

const DeleteAiAgents = ({ isOpen, onReject, onResolve, aiData }) => {
  const {
    mutate: aiProfileDelete,
    isLoading: didAIProfileDeleteloading,
    isError: didAIProfileDeleteError,
    isSuccess: didAIProfileDeleteSuccess,
  } = useAiProfileDelete();

  useEffect(() => {
    if (didAIProfileDeleteSuccess && !didAIProfileDeleteError) {
      setTimeout(() => {
        onResolve();
      }, 1000);
    }
  }, [didAIProfileDeleteSuccess, didAIProfileDeleteError]);

  const onSubmit = () => {
    aiProfileDelete({ id: aiData });
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Delete AI Agent</DialogTitle>
      <DialogContent>
        {didAIProfileDeleteError && (
          <Alert severity="error">
            There was a problem while deleting AI Agent.
          </Alert>
        )}
        {didAIProfileDeleteSuccess && (
          <Alert severity="success">AI Agent deleted successfully.</Alert>
        )}
        Are you sure to delete AI Agent.
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          loading={didAIProfileDeleteloading}
          disabled={didAIProfileDeleteSuccess}
          onClick={onSubmit}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

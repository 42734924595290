import { useMutation, useQueryClient } from 'react-query';
import { lostReasonsQueryKeys } from './queries';
import LostReasons from './LostReasons';

export const useLostReasonsEdit = () => {
  const queryClient = useQueryClient();
  return useMutation(LostReasons.edit, {
    onSuccess: () => {
      queryClient.invalidateQueries(lostReasonsQueryKeys.lists());
    },
  });
};

export const useLostReasonsCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(LostReasons.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(lostReasonsQueryKeys.lists());
    },
  });
};

export const useLostReasonsDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(LostReasons.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(lostReasonsQueryKeys.lists());
    },
  });
};

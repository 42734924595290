import { useQuery } from 'react-query';
import Phones from './Phones';

export const usePhonesSearch = (phone, options) => {
  const area_code = phone.substring(0, 3);

  const params = { area_code, contains: phone, limit: 10 };
  return useQuery(['phones', phone], () => Phones.search(params), {
    // < 10 is simplest way to prevent fetch when a number is selected (which will error without handling non-digits)
    enabled: phone?.length > 2 && phone?.length <= 10,
    staleTime: 0,
    cacheTime: 0,
    retry: 0,
    ...options,
  });
};

import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Box, Typography, Alert } from '@mui/material';
import { useOrg } from '../../contexts/OrgProvider';
import { useUsersCurrent } from '../services/users/queries';
import { useOrganizationsList } from '../services/organizations/queries';
import { dateToAbbreviatedDateTimeString } from '../services/dateTime';
import { useCallsList } from '../services/callTracking/queries';
import { formatPhoneNumber, formatTimeDuration } from '../services/textFormatting';
import { toParamString } from '../services/queryClientConfig';
import { DataGridStyle } from '../theme/styled/DataGridStyle';
import { CellSpanStyle } from '../theme/styled/CellSpanStyle';
import { parseFilterParams } from '../components/Filters/utils';
import { FilterBar } from '../components/CallTracking/FilterBar';
import { CallTrackingUpsellModal } from '../components/CallTracking/UpsellModal';
import CustomFooter from '../components/Activities/CustomFooter';
import FilterButtonWrapper from '../components/Filters/FilterButtonWrapper';
import { useOrganizationServices } from '../services/organizations/queries';
import { CallCardWrapper, ActiveCellSpan } from '../components/CallTracking/ui';

const CallTracking = () => {
  const history = useHistory();
  const location = useLocation();
  const { callIdParam } = useParams();
  const filterBarRef = useRef();

  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(1);
  const [sortModel, setSortModel] = useState([
    { field: 'created_at', sort: 'desc' },
  ]);
  const [callsFilterMap, setCallsFilterMap] = useState({ status: null });
  const [isCallTrackingEnabled, setIsCallTrackingEnabled] = useState(true);

  const { data: currentUser } = useUsersCurrent();
  const { id: orgId, isMasterAccount } = useOrg();
  const { data: organizationServicesList } = useOrganizationServices(orgId);
  const {
    data: accountsList,
    isError: isAccountsListError,
  } = useOrganizationsList();

  const [requestParams, filterParams] = useMemo(
    () => parseFilterParams(callsFilterMap, isMasterAccount, orgId),
    [callsFilterMap, orgId]
  );

  const [showFilters, setFilterToggle] = useState(window.innerWidth > 768);
  const [isCallShowError, setCallShowError] = useState(false);
  const [isError, setError] = useState(true);

  useEffect(() => {
    if (isCallShowError && isError) {
      if (location.pathname !== '/calls') {
        setError(false);
      }
    }
  }, [isCallShowError, isError, location]);

  useEffect(() => {
    function handleWindowSizeChange() {
      if (window.innerWidth <= 768) {
        setFilterToggle(false);
      }
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const getSorting = () => {
    if (sortModel.length === 0) {
      return [];
    }
    return [
      ['order', `${sortModel[0].field}`],
      ['direction', sortModel[0].sort],
    ];
  };

  const createCallsListFilters = () => {
    const filters = [
      ['page', page],
      ['page_size', pageSize],
      ...getSorting(),
      ...filterParams,
    ];
    if (!isMasterAccount) {
      filters.push(['organization_id', orgId]);
    }
    return filters;
  };

  const transformCallList = (data) => {
    const transformedItems = data?.items?.map((call) => {
      const tempCall = { ...call };
      const foundAccount = accountsList?.find(
        ({ id }) => String(call.phone.organization_id) === String(id)
      );
      tempCall.duration = formatTimeDuration(call.ends_at, call.created_at);
      if (foundAccount) tempCall.appended_account_name = foundAccount.name;
      return tempCall;
    });
    return { ...data, items: transformedItems };
  };

  const {
    data: callsList,
    isError: isCallsListError,
    isLoading,
    refetch,
  } = useCallsList(createCallsListFilters(), {
    select: transformCallList,
  });

  useEffect(() => {
    if (!callIdParam) {
      refetch();
    }
  }, [callIdParam]);

  const columns = [
    {
      field: 'phones.organization_id',
      headerName: 'Account',
      flex: 1,
      hide: !!callIdParam,
      renderCell: ({ row }) => (
        <CellSpanStyle>{row.appended_account_name}</CellSpanStyle>
      ),
    },
    {
      field: 'targets.name',
      headerName: 'Tracking Name',
      sortable: true,
      flex: 1,
      hide: !!callIdParam,
      renderCell: (cell) => <CellSpanStyle>{cell?.row?.phone?.name}</CellSpanStyle>,
    },
    {
      field: 'phones.number',
      headerName: 'Tracking #',
      sortable: true,
      hide: !!callIdParam,
      flex: 1,
      maxWidth: 150,
      renderCell: (cell) => (
        <CellSpanStyle>{formatPhoneNumber(cell?.row?.phone.number)}</CellSpanStyle>
      ),
    },
    {
      field: 'sessions.visits.first.source',
      headerName: 'Source',
      flex: 1,
      hide: !!callIdParam,
      sortable: false,
      renderCell: (cell) => (
        <CellSpanStyle>{cell?.row?.visit?.source}</CellSpanStyle>
      ),
    },
    {
      field: 'created_at',
      headerName: 'Start Time',
      flex: 1,
      maxWidth: 230,
      hide: !!callIdParam,
      renderCell: ({ formattedValue }) => formattedValue,
      valueFormatter: ({ value }) => (
        <CellSpanStyle>{dateToAbbreviatedDateTimeString(value)}</CellSpanStyle>
      ),
    },
    {
      field: 'ends_at',
      headerName: 'Duration',
      hide: !!callIdParam || showFilters,
      flex: 1,
      sortable: false,
      maxWidth: 100,
      renderCell: ({ row }) => <CellSpanStyle>{row.duration}</CellSpanStyle>,
    },
    {
      field: 'cnam',
      headerName: 'Contact Name',
      hide: !!callIdParam || showFilters,
      flex: 1,
      renderCell: (cell) => {
        return (
          <CellSpanStyle>{cell?.row?.cnam || 'Anonymous Caller'}</CellSpanStyle>
        );
      },
    },
    {
      field: 'calls.from',
      headerName: 'Contact #',
      flex: 1,
      maxWidth: 150,
      hide: !!callIdParam,
      renderCell: (cell) => (
        <CellSpanStyle>{formatPhoneNumber(cell?.row?.from)}</CellSpanStyle>
      ),
    },
    {
      field: 'from_and_cnam',
      headerName: 'Contact Name and Phone',
      flex: 1,
      hide: !callIdParam,
      sortable: false,
      renderCell: (cell) => (
        <ActiveCellSpan cell={cell}>
          <Box
            sx={{
              dipslay: 'inline-block',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >{`${formatPhoneNumber(cell?.row?.from)} > ${
            cell?.row?.cnam ? cell?.row?.cnam : 'Anonymous Caller'
          }`}</Box>
        </ActiveCellSpan>
      ),
    },
  ];

  useEffect(() => {
    if (organizationServicesList) {
      const isEnabled = organizationServicesList.some(
        (service) => service.service_id == 3
      );
      setIsCallTrackingEnabled(isEnabled);
    }
  }, [organizationServicesList]);

  const shouldShowFilters = location.pathname === '/calls';

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      <CallTrackingUpsellModal callTrackingEnabled={isCallTrackingEnabled} />
      <Box display="flex" justifyContent="space-between">
        <Typography fontSize={18} fontWeight={700}>
          Call Trackings
        </Typography>
        {shouldShowFilters && (
          <FilterButtonWrapper
            showFilters={showFilters}
            setFilterToggle={setFilterToggle}
          />
        )}
      </Box>
      {isCallsListError && !isAccountsListError && (
        <Alert severity="error">
          There was an error loading your call tracking. Try again.
        </Alert>
      )}
      {isAccountsListError && !isCallsListError && (
        <Alert severity="error">
          There was an error loading the information for your accounts. Try again.
        </Alert>
      )}
      {isCallShowError && (
        <Alert onClose={() => setError(false)} severity="error">
          Call not found.
        </Alert>
      )}

      <Box display="flex" height="0%" width="100%" flexBasis="100%" gap={2}>
        {showFilters && (
          <FilterBar
            setPage={setPage}
            ref={filterBarRef}
            setCallsFilterMap={setCallsFilterMap}
          />
        )}
        <DataGridStyle
          disableSelectionOnClick
          componentsProps={{
            row: {
              onClick: (e) => {
                history.push(`/calls/${e.currentTarget.dataset.id}`);
              },
            },
          }}
          rows={callsList?.items ?? []}
          columns={columns}
          loading={isLoading}
          rowCount={callsList?.total_count ?? 0}
          onPageChange={(page) => setPage(page + 1)}
          page={page - 1}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={(newModel) => {
            setPage(1);
            setSortModel(newModel);
          }}
          onPageSizeChange={setPageSize}
          pageSize={pageSize}
          paginationMode="server"
          disableColumnFilter
          disableColumnMenu
          density="compact"
          sx={{
            '& .MuiDataGrid-cell': {
              outline: 'none',
              padding: 0,
              position: 'relative',
            },
          }}
          components={{
            Footer: () => (
              <CustomFooter
                rowCount={callsList?.total_count ?? 0}
                page={page - 1}
                onPageChange={(page) => setPage(page + 1)}
                onPageSizeChange={setPageSize}
                pageSize={pageSize}
                organization_id={orgId}
                requested_url={`${process.env.REACT_APP_IVR}/calls?${toParamString(
                  requestParams
                )}`}
                isCurrentUserAdmin={currentUser.roles.some(
                  (role) => role.role_name === 'Administrator'
                )}
              />
            ),
          }}
        />
        {callIdParam && <CallCardWrapper setCallShowError={setCallShowError} />}
      </Box>
    </Box>
  );
};

export default CallTracking;

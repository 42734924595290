import { useQuery } from 'react-query';
import BillingResources from './BillingResources';
import { queryKeyFactory } from '../queryClientConfig';

export const billingResourcesQueryKeys = queryKeyFactory('billing_resources');

export const useBillingResourcesList = (filters = [], options) => {
  return useQuery(
    billingResourcesQueryKeys.list(filters),
    () => BillingResources.list(filters),
    {
      staleTime: 0,
      ...options,
    }
  );
};

import { useEffect, useRef, useState } from 'react';
import { Col, Row, Form, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Prompt } from 'react-router-dom';
import TemplateTextEditor from './TemplateTextEditor';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

const createInitialEditorState = (template) =>
  template
    ? EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlToDraft(template.content))
      )
    : EditorState.createEmpty();

const TemplateForm = ({ type, template, submitClick, isSubmitDisabled }) => {
  const subjectRef = useRef(null);
  const contentRef = useRef(null);
  const [activeRef, setActiveRef] = useState(null);
  const [formState, setFormState] = useState({
    name: template?.name ? template?.name : '',
    subject: template?.subject ? template?.subject : '',
    content: template?.content ? template?.content : '',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [token, setToken] = useState();
  const [editorHasFocus, setEditorHasFocus] = useState(false);
  const [editorHasValidText, setEditorHasValidText] = useState(false);
  const [editorState, setEditorState] = useState(createInitialEditorState(template));

  const handleClickListener = (e) => {
    if (e.target.id === 'textContent' || e.target.id === 'emailContent')
      setActiveRef(contentRef);
    else if (e.target.id === 'emailSubject') setActiveRef(subjectRef);
    else setActiveRef(null);
  };

  const onFocusHandler = (e) => {
    setEditorHasFocus(false);
    setToken();
    if (e.target.id === 'textContent') {
      setActiveRef(contentRef);
    } else if (e.target.id === 'emailSubject') {
      setActiveRef(subjectRef);
    } else if (e.target.className.includes('public-DraftEditor-content')) {
      setEditorHasFocus(true);
      setActiveRef(null);
    } else {
      setActiveRef(null);
    }
  };

  const checkIfDisableSubmit = () => {
    if (isSubmitDisabled) return true;
    if (type === 'Email') return !formState.name || !editorHasValidText;
    return !formState.name || !formState.content;
  };

  const TemplateName = (
    <Form.Group as={Row} controlId="templateName" className="mt-3">
      <Col xs={12} sm={2}>
        <Form.Label className="ml-3">
          <strong>Name</strong>
        </Form.Label>
      </Col>
      <Col xs={12} sm={3} className="px-4 px-sm-3">
        <Form.Control
          onFocus={() => setActiveRef(null)}
          value={formState.name}
          onChange={(e) => {
            setFormState({ ...formState, name: e.target.value });
            if (!isEditing) setIsEditing(true);
          }}
          type="text"
          maxLength={127}
          placeholder={'Template Name'}
        />
      </Col>
    </Form.Group>
  );

  const getField = (id) => {
    return id === 'emailSubject' ? 'subject' : 'content';
  };

  const renderTokenButton = (token, title) => (
    <Button
      id="token-button"
      disabled={!activeRef && !editorHasFocus}
      className={'btn-sm btn-sigma-primary w-100 px-0'}
      // function slices the active form text field with the clicked token button's text value.
      onClick={(e) => {
        if (!isEditing) setIsEditing(true);
        setToken(token);
        if (activeRef)
          setFormState((prev) => {
            return {
              ...prev,
              [getField(activeRef.current.id)]: `${prev[
                getField(activeRef.current.id)
              ]?.slice(0, activeRef.current.selectionStart)}${token}${prev[
                getField(activeRef.current.id)
              ]?.slice(activeRef.current.selectionStart)}`,
            };
          });
      }}
    >
      {title}
    </Button>
  );

  const TokenButtonsRow = (
    <>
      <Row className="mt-3">
        <Col xs={12}>
          <p className="pl-3 pl-sm-0">
            Automatically insert predefined mapped fields into the message or subject
            line with the following:
          </p>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col xs={5} sm={6} xl={2} className=" p-1">
          {renderTokenButton('{{first_name}}', 'First Name')}
        </Col>
        <Col xs={5} sm={6} xl={2} className="p-1">
          {renderTokenButton('{{last_name}}', 'Last Name')}
        </Col>
        <Col xs={5} sm={6} xl={2} className="p-1">
          {renderTokenButton('{{user_first_name}}', 'User First Name')}
        </Col>
        <Col xs={5} sm={6} xl={2} className="p-1">
          {renderTokenButton('{{user_last_name}}', 'User Last Name')}
        </Col>
        <Col xs={5} sm={6} xl={2} className="p-1">
          {renderTokenButton('{{team_name}}', 'Team Name')}
        </Col>
        <Col xs={5} sm={6} xl={2} className="p-1">
          {renderTokenButton('{{team_phone}}', 'Team Phone')}
        </Col>
      </Row>
    </>
  );

  useEffect(() => {
    window.addEventListener('click', (event) => {
      handleClickListener(event);
    });
    return () =>
      window.removeEventListener('click', (event) => {
        handleClickListener(event);
      });
  }, []);

  const submitContentFormatter = () => {
    if (type !== 'Email') return formState;
    const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    return { name: formState.name, subject: formState.subject, content };
  };

  return (
    <Form>
      <Prompt
        when={isEditing}
        message={(location, action) => {
          if (location.pathname.includes('logout')) return true;
          const message =
            'You have unsaved changes, are you sure you would like to leave this page?';
          if (action === 'PUSH') return true;
          if (action === 'POP') return message;
          return location.state === 'cancelTemplate' ? true : message;
        }}
      />
      {type === 'Email' ? (
        <>
          <Card className={`mt-4`}>
            <Row className="mx-0 py-4 bg-sigma-light-primary bg-sigma-light-primary leads-header">
              <Col xs={12} className={'text-left p-0'}>
                {`Email Template`}
              </Col>
            </Row>
            {TemplateName}
            <Form.Group as={Row} controlId="emailSubject">
              <Col xs={12} sm={2}>
                <Form.Label className="ml-3">
                  <strong>Subject</strong>
                </Form.Label>
              </Col>
              <Col xs={12} sm={8} className="px-4 px-sm-3">
                <Form.Control
                  onFocus={(event) => onFocusHandler(event)}
                  ref={subjectRef}
                  value={formState.subject}
                  onChange={(e) => {
                    setFormState({ ...formState, subject: e.target.value });
                    if (!isEditing) setIsEditing(true);
                  }}
                  type="text"
                  placeholder={'Template Subject'}
                  maxLength={127}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="emailContent">
              <Col xs={12} sm={2}>
                <Form.Label className="ml-3">
                  <strong>Content</strong>
                </Form.Label>
              </Col>
              <Col xs={12} sm={8} className="px-4 px-sm-3">
                <TemplateTextEditor
                  editorState={editorState}
                  setEditorState={(newEditorState) => {
                    setIsEditing(true);
                    setEditorState(newEditorState);
                  }}
                  token={token}
                  setToken={setToken}
                  onFocusHandler={onFocusHandler}
                  editorHasFocus={editorHasFocus}
                  setEditorHasFocus={setEditorHasFocus}
                  editorHasValidText={editorHasValidText}
                  setEditorHasValidText={setEditorHasValidText}
                />
                {TokenButtonsRow}
              </Col>
            </Form.Group>
          </Card>
        </>
      ) : (
        <Card className={`mt-3`}>
          <Row className="mx-0 py-4 bg-sigma-light-primary bg-sigma-light-primary leads-header">
            <Col xs={12} className={'text-left p-0'}>
              {`${type} Message Template`}
            </Col>
          </Row>
          {TemplateName}
          <Form.Group as={Row} controlId="textContent">
            <Col xs={12} sm={2}>
              <Form.Label className="ml-3">
                <strong>Message</strong>
              </Form.Label>
            </Col>
            <Col xs={12} sm={8} className="px-4 px-sm-3">
              <Form.Control
                onFocus={(e) => onFocusHandler(e)}
                className="mr-5"
                onClick={(e) => {
                  setActiveRef(e.target);
                }}
                value={formState.content}
                onChange={(e) => {
                  setFormState({ ...formState, content: e.target.value });
                  if (!isEditing) setIsEditing(true);
                }}
                as="textarea"
                rows={7}
                type="text"
                placeholder={'Template Message'}
                maxLength={4095}
                ref={contentRef}
              />
              {TokenButtonsRow}
            </Col>
          </Form.Group>
        </Card>
      )}
      <Row className="mt-4">
        <Col xs={12} sm={3} xl={2}>
          <Button
            type="submit"
            className={'btn btn-sigma-primary w-100 px-0'}
            disabled={checkIfDisableSubmit()}
            onClick={(e) => {
              setIsEditing(false);
              submitClick(e, submitContentFormatter());
            }}
          >
            Save Template
          </Button>
        </Col>
        <Col xs={12} sm={3} xl={2}>
          <Link
            to={{ pathname: '/settings/templates', state: 'cancelTemplate' }}
            className="btn btn-light btn-block"
          >
            Cancel
          </Link>
        </Col>
      </Row>
    </Form>
  );
};

export default TemplateForm;

import Cookies from 'js-cookie';
import { toJSON } from '../utils/utils';

export default class SlotsCopies {
  static async create(rule) {
    return await fetch(`${process.env.REACT_APP_CALENDAR_SERVICE}/slot_copies`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(rule),
    }).then(toJSON);
  }
}

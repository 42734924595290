import { useMutation, useQueryClient } from 'react-query';
import { phoneIdQueryKeys } from './queries';
import PhoneId from './PhoneId';

export const usePhoneIdEdit = (options) => {
  const queryClient = useQueryClient();
  return useMutation(PhoneId.edit, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(phoneIdQueryKeys.all);
      const phones = queryClient.getQueryData(phoneIdQueryKeys.list());
      if (phones) {
        const teamIndex = phones.findIndex((x) => x.id == variables.id);
        phones[teamIndex] = data;
        queryClient.setQueryData(phoneIdQueryKeys.list(), phones);
      }
      queryClient.setQueryData(phoneIdQueryKeys.show(variables.id), data);
    },
    ...options,
  });
};

export const usePhoneIdDelete = (options) => {
  const queryClient = useQueryClient();
  return useMutation(PhoneId.delete, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(phoneIdQueryKeys.all);
      queryClient.removeQueries(phoneIdQueryKeys.show(variables.id));
    },
    ...options,
  });
};

export const usePhoneIdCreate = (options) => {
  const queryClient = useQueryClient();
  return useMutation(PhoneId.create, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(phoneIdQueryKeys.all);
      queryClient.removeQueries(phoneIdQueryKeys.show(variables.id));
    },
    ...options,
  });
};

import { useQuery } from 'react-query';
import { queryKeyFactory, ONE_HOUR } from '../queryClientConfig';
import Roles from './Roles';

const rolesQueryKeys = {
  ...queryKeyFactory('roles'),
};
export { rolesQueryKeys };

// Filters out current role
export const useRolesList = (options) => {
  const filterList = (data) => {
    return data.filter(({ system_role }) => system_role !== true);
  };

  return useQuery(rolesQueryKeys.list(), () => Roles.list(), {
    staleTime: ONE_HOUR,
    select: filterList,
    ...options,
  });
};

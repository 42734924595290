import React, { useContext, useEffect, useState } from 'react';
import {
  ListGroup,
  Card,
  Row,
  Col,
  Container,
  Modal,
  Alert,
  Form,
} from 'react-bootstrap';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { fireDialog } from '../../MUI/components/Dialog';
import Sequence from '../../clients/Sequence';
import Step from '../../clients/Step';
import ClipLoader from 'react-spinners/ClipLoader';
import { AiOutlinePlus } from 'react-icons/ai';
import { Link, useHistory } from 'react-router-dom';
import { useOrganizationsList } from '../../MUI/services/organizations/queries';
import { useTaskSequencesList } from '../../MUI/services/taskSequences/queries';
import { useSharedSequencesCreate } from '../../MUI/services/sharedSequences/mutations';
import { useTaskSequenceDelete } from '../../MUI/services/taskSequences/mutations';
import { DataGridStyle } from '../../MUI/theme/styled/DataGridStyle';
import { useOrg } from '../../contexts/OrgProvider';

const SequenceDelete = ({
  showDelete,
  handleCloseDelete,
  deleteId,
  taskSequences,
}) => {
  const [newTaskSequenceId, setNewTaskSequenceId] = useState();

  const {
    mutate: deleteTaskSequence,
    isSuccess: didSucceedMutatingTaskSequence,
    isError: didFailMutatingTaskSequence,
    isLoading: isMutatingTaskSequence,
  } = useTaskSequenceDelete();

  useEffect(() => {
    if (taskSequences.length > 0) {
      setNewTaskSequenceId(taskSequences[0].id);
    }
  }, [taskSequences]);

  const deleteSequence = () => {
    deleteTaskSequence({ id: deleteId, newTaskSequenceId: newTaskSequenceId });
    handleCloseDelete();
  };

  const updateNewTaskSequence = (e) => {
    setNewTaskSequenceId(e.target.value);
  };

  const taskSequenceOptions = () => {
    return taskSequences
      .filter((ts) => ts.id != deleteId)
      .map((ts) => {
        return (
          <option key={ts.id} value={ts.id}>
            {ts.name}
          </option>
        );
      });
  };

  return (
    <Modal show={showDelete} onHide={handleCloseDelete} className={'modal-sigma'}>
      <Modal.Header className={'text-center'}>
        <Modal.Title className={'w-100'}>Delete Sequence?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You are about to delete the task sequence
        <br />
        <br />
        This will remove all assigned leads. Do you want to add these leads to a
        different sequence?
        <Form.Control
          as="select"
          onChange={updateNewTaskSequence}
          defaultValue={newTaskSequenceId}
          className="my-3"
        >
          {taskSequenceOptions()}
        </Form.Control>
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row>
            <Col xs={'6'}>
              <button
                type={'button'}
                className={'btn btn-light btn-block'}
                onClick={handleCloseDelete}
              >
                Cancel
              </button>
            </Col>
            <Col xs={'6'}>
              <button
                onClick={deleteSequence}
                className={'btn btn-sigma-primary btn-block'}
              >
                Delete
              </button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

const SequenceList = () => {
  const [isTaskLoading, setIsTaskLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const handleCloseDelete = () => setShowDelete(false);
  // const [taskSequenceList, setTaskSequenceList] = useState([]);
  const {
    data: taskSequencesList,
    isLoading: isTaskSequenceLoading,
    isError: isTaskSequenceErrored,
  } = useTaskSequencesList();

  const [error, setError] = useState();

  const history = useHistory();

  useEffect(() => {
    if (history.location.state?.error) {
      setError(history.location.state.error);
      history.push({ state: { error: null } });
    }
  }, [history]);

  const addSequence = () => {
    setIsTaskLoading(true);
    Sequence.create({ name: 'New Sequence', lead_type: 1 }).then((seq) => {
      Step.create({
        task_sequence_id: seq.id,
        days_after: 0,
        task_action_id: 'a50370fd-5b8b-4b87-ba15-744ec43840e6',
      }).then(() => {
        setIsTaskLoading(false);
        history.push(`/settings/sequences/${seq.id}`);
      });
    });
  };

  const deleteSequence = (id) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const shareSequence = (sequenceId) =>
    fireDialog((promiseParams) =>
      ShareSequenceDialog({ ...promiseParams, sequenceId })
    );

  return (
    <>
      {isTaskSequenceLoading || isTaskLoading ? (
        <></>
      ) : (
        <SequenceDelete
          showDelete={showDelete}
          handleCloseDelete={handleCloseDelete}
          deleteId={deleteId}
          taskSequences={taskSequencesList}
        />
      )}
      <Row className={'mt-0'}>
        <Col xs={12}>
          <nav aria-label="breadcrumb">
            <ol className="sigma-breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/settings">Settings</Link>
              </li>
              <li className="breadcrumb-item active">Sequences</li>
            </ol>
          </nav>
        </Col>
        {error && (
          <Col xs={12}>
            {' '}
            <Alert
              className="w-100"
              show={error}
              variant="danger"
              onClose={() => setError(false)}
              dismissible
            >
              There was an error loading your sequence.
            </Alert>
          </Col>
        )}
      </Row>
      <Card className={`mt-3`}>
        <Row className="mx-0 py-4 bg-sigma-light-primary bg-sigma-light-primary leads-header">
          <Col xs={12} className={'text-left p-0'}>
            My Sequences
          </Col>
        </Row>
        {isTaskSequenceLoading || isTaskLoading ? (
          <Row>
            <Col className="text-center p-5">
              <ClipLoader size={100} color="#34D1B6" />
            </Col>
          </Row>
        ) : (
          <>
            <ListGroup>
              {taskSequencesList.map((sequence) => (
                <ListGroup.Item className={`task-item`} key={sequence.id}>
                  <Row>
                    <Col xs={6} className="my-auto">
                      <span className="h6">{sequence.name}</span>
                    </Col>
                    <Col xs={6}>
                      <Link
                        to={`/settings/sequences/${sequence.id}`}
                        className="btn btn-sigma-white-border mr-2"
                      >
                        Edit
                      </Link>
                      <button
                        className="btn btn-sigma-white-border"
                        onClick={() => deleteSequence(sequence.id)}
                      >
                        Delete
                      </button>
                      <button
                        className="btn btn-sigma-white-border ml-2"
                        onClick={() => shareSequence(sequence.id)}
                      >
                        Share
                      </button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
              <ListGroup.Item className={`task-item`} key={'add-seq'} action="true">
                <Row>
                  <Col xs={12} onClick={addSequence} className="my-auto">
                    <span className="h6">Add Sequence</span>
                    <AiOutlinePlus size="18px" className="text-success ml-2 mb-1" />
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </>
        )}
      </Card>
    </>
  );
};

const ShareSequenceDialog = ({ isOpen, onResolve, onReject, sequenceId }) => {
  const { isMasterAccount, isEnterpriseUser, enterprise_id } = useOrg();
  const { data: organizations, isFetching, isError } = useOrganizationsList({
    enterprise_id: enterprise_id,
  });
  const {
    mutate: createShareSequence,
    isError: isErrorCreating,
    isLoading: isLoadingCreate,
  } = useSharedSequencesCreate();
  const [pageSize, setPageSize] = useState(25);
  const [selected, setSelected] = useState([]);
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
  ];

  const shareSequence = () => {
    const orgIds = selected.map((id) => {
      return { organization_id: id };
    });
    createShareSequence({ task_sequence_id: sequenceId, organizations: orgIds });
    onReject();
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Share</DialogTitle>
      <DialogContent>
        <DataGridStyle
          checkboxSelection
          loading={isFetching}
          rows={organizations ?? []}
          columns={columns}
          sx={{ minHeight: '400px' }}
          rowsPerPageOptions={[10, 25, 50]}
          onPageSizeChange={setPageSize}
          pageSize={pageSize}
          density="compact"
          disableSelectionOnClick
          onSelectionModelChange={setSelected}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="cancel" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton loading={isLoadingCreate} onClick={shareSequence}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SequenceList;

import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Call from '../../clients/Call';
import { useState } from 'react';
import { ClipLoader } from 'react-spinners';

export default function CallOutcome({ call_id }) {
  const [isLoading, setIsLoading] = useState(false);
  const [callStatusId, setCallStatusId] = useState(null);
  const saveCallOutcome = (e) => {
    setCallStatusId(e.target.value);
    setIsLoading(true);
    Call.edit({ id: call_id, status: parseInt(e.target.value) }).then(() => {
      setIsLoading(false);
    });
  };

  if (!call_id) {
    return <></>;
  }

  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <ClipLoader size={50} color="#34D1B6" />
      </Box>
    );
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="template-label" sx={{ marginLeft: '-14px' }}>
        Call Outcome
      </InputLabel>
      <Select variant={'standard'} value={callStatusId} onChange={saveCallOutcome}>
        <MenuItem key="1" value="1">
          Connected
        </MenuItem>
        <MenuItem key="2" value="2">
          Busy
        </MenuItem>
        <MenuItem key="3" value="3">
          Left voicemail
        </MenuItem>
        <MenuItem key="4" value="4">
          Left live message
        </MenuItem>
        <MenuItem key="5" value="5">
          No answer - No voicemail left
        </MenuItem>
        <MenuItem key="6" value="6">
          Wrong number
        </MenuItem>
      </Select>
    </FormControl>
  );
}

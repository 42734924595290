import React, { useEffect, useState } from 'react';
import { Box, Button, MenuItem, TextField } from '@mui/material';
import { FieldOption } from './FieldOption';
import { useSizeUiTracker } from '../../hooks';
import { voiceOptions } from '../utils';

export const Menu = ({
  id,
  parameters,
  activeCallerPressOption,
  handleDeleteBranchInBuilder,
  handleMutateBuilderCallerPressOption,
  availableCallerPressOption,
  handleAddComponent,
  doSearchEmptyInputs,
  getUpdatedInputParams,
  handleMutateComponentListByParametersInUI,
  ...rest
}) => {
  const wrapper = (itemSelectValue, isActive) => {
    return (
      <FieldOption
        mutate={mutate}
        itemSelectValue={itemSelectValue}
        isActive={isActive}
        id={id}
        takenOptionIndexes={takenOptionIndexes}
        setTakenOptionsIndexes={setTakenOptionsIndexes}
        handleRemoveOption={handleRemoveOption}
        selectOptionsProp={selectOptions}
        setSelectOptionsProp={setSelectOptions}
        setSelectOptionsChanges={setSelectOptionsChanges}
        setIsSelectOptionsChanged={setIsSelectOptionsChanged}
        defineActiveOption={defineActiveOption}
        activeCallerPressOption={activeCallerPressOption}
        handleMutateBuilderCallerPressOption={handleMutateBuilderCallerPressOption}
      />
    );
  };

  const [takenOptionIndexes, setTakenOptionsIndexes] = useState(new Set());
  const [callerPressList, setCallerPressList] = useState([]);
  const [inputParameters, setInputParameters] = useState(
    getUpdatedInputParams(id)?.parameters || parameters
  );
  const [selectOptions, setSelectOptions] = useState(
    parameters?.optionsLabels || [
      { digit: 1, text: '' },
      { digit: 2, text: '' },
      { digit: 3, text: '' },
      { digit: 4, text: '' },
      { digit: 5, text: '' },
      { digit: 6, text: '' },
      { digit: 7, text: '' },
      { digit: 8, text: '' },
      { digit: 9, text: '' },
    ]
  );
  const [isSelectOptionsChanged, setIsSelectOptionsChanged] = useState(false);
  const [selectOptionsChanges, setSelectOptionsChanges] = useState({});

  const { top, height } = useSizeUiTracker(selectOptions, callerPressList);

  const mutate = (prevVal, newVal) => {
    setCallerPressList((list) => {
      return list.map((el) => {
        const val =
          el.props.itemSelectValue === prevVal ? newVal : el.props.itemSelectValue;
        return wrapper(val, el.props.isActive);
      });
    });
  };

  const generateFirstLowValue = () => {
    for (let i = 1; i <= selectOptions.length; i++) {
      if (!takenOptionIndexes.has(i)) {
        return i;
      }
    }
  };

  function handleAddOption() {
    const val = generateFirstLowValue();
    setTakenOptionsIndexes((list) => {
      list.add(val);
      return list;
    });
    setCallerPressList((prevList) => {
      return prevList
        .map((el) => {
          return wrapper(el.props.itemSelectValue, el.props.isActive);
        })
        .concat(wrapper(val.toString(), !prevList.length));
    });
  }

  function handleRemoveOption(selectValue) {
    setTakenOptionsIndexes((list) => {
      list.delete(selectValue);
      return list;
    });

    setCallerPressList((prevList) =>
      prevList
        .filter((item) => +item.props.itemSelectValue !== selectValue)
        .map((el) => {
          return wrapper(+el.props.itemSelectValue, el.props.isActive);
        })
    );

    setSelectOptions((prevSelectOptions) => {
      return prevSelectOptions.map((option) => {
        if (option.digit === selectValue) {
          return {
            ...option,
            text: '',
          };
        }
        return option;
      });
    });

    handleDeleteBranchInBuilder(id, selectValue);
  }

  function defineActiveOption(e, itemPrevOption, itemNewOption) {
    const clickOnParent = e.target.dataset.parent && e.currentTarget.dataset.parent;
    if (clickOnParent) {
      setCallerPressList((prevList) => {
        return prevList.map((item) => {
          return wrapper(
            item.props.itemSelectValue,
            item.props.itemSelectValue === itemPrevOption
          );
        });
      });
      handleMutateBuilderCallerPressOption(+itemNewOption, id);
    }
  }

  const handleChange = (e) => {
    setInputParameters({
      ...inputParameters,
      [e.target.name]: e.target.value,
    });
    handleMutateComponentListByParametersInUI(
      { ...inputParameters, [e.target.name]: e.target.value },
      id
    );
  };

  useEffect(() => {
    let totalValues = [];

    for (let key in availableCallerPressOption) {
      const branch = availableCallerPressOption[key].filter(
        (el) => el.action !== 'initialList'
      );
      if (
        branch.length ||
        branch[0]?.action === 'respond' ||
        branch[0]?.action === 'ai' ||
        branch[0]?.action === 'hangup'
      ) {
        totalValues.push(key);
      }
    }

    if (totalValues.length) {
      const data = totalValues.map((el) => {
        setTakenOptionsIndexes((list) => {
          list.add(+el);
          return list;
        });
        return wrapper(el, el === totalValues[0]);
      });
      const isActiveBranch = data.filter((el) => el.props.isActive)[0];
      handleMutateBuilderCallerPressOption(
        +isActiveBranch.props.itemSelectValue,
        id
      );
      setCallerPressList(data);
    } else {
      setTakenOptionsIndexes((list) => {
        list.add(activeCallerPressOption);
        return list;
      });
      setCallerPressList((list) => {
        return list.concat(wrapper(activeCallerPressOption.toString(), true));
      });
      handleMutateBuilderCallerPressOption(+activeCallerPressOption, id);
    }
  }, []);

  useEffect(() => {
    if (isSelectOptionsChanged) {
      handleMutateComponentListByParametersInUI(
        { ...parameters, optionsLabels: [...selectOptions] },
        id,
        selectOptionsChanges,
        isSelectOptionsChanged
      );
      setIsSelectOptionsChanged(false);
    } else {
      handleMutateComponentListByParametersInUI(
        { ...parameters, optionsLabels: [...selectOptions] },
        id
      );
    }
  }, [selectOptionsChanges, selectOptions]);

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <TextField
          sx={{ width: '300px', marginBottom: '20px' }}
          label="Please choose voice"
          select
          name="voice"
          value={inputParameters?.voice || ''}
          onChange={handleChange}
          error={doSearchEmptyInputs && !inputParameters?.voice}
        >
          {voiceOptions.map(({ operator, label }) => {
            return (
              <MenuItem key={operator} value={operator}>
                {label}
              </MenuItem>
            );
          })}
        </TextField>
        <br />
        <TextField
          sx={{ width: '300px' }}
          label="Message"
          multiline
          placeholder="Type your message here"
          name="message"
          value={inputParameters?.message}
          onChange={handleChange}
          error={doSearchEmptyInputs && !inputParameters?.message}
        />
      </Box>
      <Box
        sx={{
          position: `${!callerPressList.length ? 'static' : 'relative'}`,
          zIndex: '2',
          '&::before': {
            content: '""',
            position: 'absolute',
            height,
            zIndex: '-1',
            width: '5%',
            backgroundColor: 'transparent',
            top,
            right: '20px',
            border: '2px solid black',
            borderBottomColor: 'transparent',
            borderLeftColor: 'transparent',
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            height: '31px',
            zIndex: '-1',
            width: 'calc(50% - 20px)',
            backgroundColor: 'transparent',
            bottom: '-29px',
            right: '20px',
            border: '2px solid black',
            borderRightColor: 'transparent',
            borderBottomColor: 'transparent',
          },
        }}
      >
        {callerPressList.map((item) => {
          return <Box key={item.props.itemSelectValue}>{item}</Box>;
        })}
        {selectOptions.length === callerPressList.length || (
          <Button onClick={handleAddOption} sx={{ mt: 2 }}>
            + Add menu option
          </Button>
        )}
      </Box>

      {callerPressList.length === 0 || (
        <Box
          sx={{
            width: '100%',
            position: 'absolute',
            bottom: '-50px',
            textAlign: 'center',
          }}
        >
          The caller pressed "{activeCallerPressOption}"
        </Box>
      )}
    </>
  );
};

import React from 'react';
import { Modal, Row, Col, Button, Dropdown, ButtonGroup } from 'react-bootstrap';

const RevealNumberModal = ({ show, setShow, setShowPhone, setShowNumber }) => {
  return (
    <Modal
      className="modal-sigma"
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header>
        <Modal.Title>Call Tasks</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        To call this lead, please click "Call Now" to ensure accurate communication
        tracking.
        <br />
        <br />
        If you do not have a microphone, use your normal phone by dialing the number
        in the prompt.
      </Modal.Body>
      <Modal.Footer>
        <Row noGutters className="w-100">
          <Col xs={6} className="px-3">
            <Dropdown as={ButtonGroup} className="btn-block">
              <Button
                className="btn btn-light btn-block"
                onClick={() => setShow(false)}
              >
                Cancel
              </Button>

              <Dropdown.Toggle split variant="light" id="dropdown-split-basic" />

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setShowNumber(true);
                    setShow(false);
                  }}
                >
                  Reveal Number
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs={6} className="px-3">
            <Button
              className="btn btn-sigma-primary btn-block"
              onClick={() => {
                setShow(false);
                setShowPhone(true);
              }}
            >
              Call Now
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default RevealNumberModal;

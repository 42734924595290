import Cookies from 'js-cookie';
import { toJSON, toParamString } from '../queryClientConfig';

export default class Transcriptions {
  static async show(transcription_id) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/transcriptions/${transcription_id}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(toJSON);
  }
}

import Cookies from 'js-cookie';
import { toJSON, toParamString } from '../queryClientConfig';

export default class LostReasons {
  static async list(filters) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/lost_reasons?${toParamString(filters)}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async create(reason) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/lost_reasons`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reason),
    }).then(toJSON);
  }

  static async edit(reason) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/lost_reasons/${reason.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reason),
      }
    ).then(toJSON);
  }

  static async delete(id) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/lost_reasons/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
}

import { useQuery } from 'react-query';
import Task from '../../clients/Task';
import { taskKeys } from '../utils/queryClient/queryConfigs';

const { tasksDueToday, tasksCompleted } = taskKeys;

export const useTasksDueToday = () => {
  return useQuery(tasksDueToday, () =>
    Task.list({
      due_today: true,
    })
  );
};

export const useCompletedTasks = () => {
  return useQuery(tasksCompleted, () =>
    Task.list({
      completed_today: true,
    })
  );
};

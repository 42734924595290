import { useQuery } from 'react-query';
import { queryKeyFactory } from '../queryClientConfig';
import Cloud9Relationship from './Cloud9Relationship';
export const cloud9QueryKeys = queryKeyFactory('cloud9Relationship');

export const useCloud9Relationship = (filters, options) => {
  return useQuery(
    cloud9QueryKeys.list(filters),
    () => Cloud9Relationship.list(filters),
    {
      staleTime: 0,
      retry: 0,
      ...options,
    }
  );
};

import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import parsePhoneNumber from 'libphonenumber-js';
import moment from 'moment';

import MessageTemplate from '../../clients/MessageTemplate.js';
import TextMessageClient from '../../clients/TextMessage.js';
import Task from '../../clients/Task.js';
import AppContext from '../../contexts/AppContext';
import { useHistory } from 'react-router';
import { EditorState } from 'draft-js';
import Skeleton from 'react-loading-skeleton';
import { toastOptions } from '../../util/AppUtils';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { tasksQueryKeys } from '../../MUI/services/tasks/queries.js';
import { useMessageTemplatesList } from '../../MUI/services/messageTemplates/queries.js';
import { leadsQueryKeys } from '../../MUI/services/leads/queries.js';

const TextMessage = ({
  show,
  closeModal,
  task,
  lead,
  setShowFollowUpDismiss,
  textCommunicationCallback,
}) => {
  const [textTemplates, setTextTemplates] = useState([]);
  const [messageBody, setMessageBody] = useState('');
  const [templateOptions, setTemplateOptions] = useState();
  const [defaultTemplate, setDefaultTemplate] = useState();
  const [renderedTemplate, setRenderedTemplate] = useState();
  const [isRenderingTemplate, setRenderingTemplate] = useState(false);
  const history = useHistory();
  const { user } = useContext(AppContext);
  const queryClient = useQueryClient();
  const filterTextList = (data) => {
    return data.filter((t) => t.task_action.name == 'Text');
  };
  const { data: messageTemplates } = useMessageTemplatesList(
    [['organization_id[]', lead.organization_id]],
    {
      select: filterTextList,
    }
  );

  useEffect(() => {
    if (messageTemplates) setTextTemplates(messageTemplates);
  }, [messageTemplates, task]);

  useEffect(() => {
    setTemplateOptions(messageTemplateOptions());
  }, [textTemplates, task]);

  useEffect(() => {
    if (renderedTemplate) {
      setMessageBody(renderedTemplate.content);
    } else {
      setMessageBody('');
    }
  }, [renderedTemplate]);

  const renderTemplate = (templateId) => {
    setRenderingTemplate(true);

    MessageTemplate.render(templateId, user.id, lead.id)
      .then((res) => {
        setRenderedTemplate(res);
        setRenderingTemplate(false);
      })
      .catch(() => {
        setRenderedTemplate(null);
        setRenderingTemplate(false);
      });
  };

  const primaryPhone = () => {
    const primary = lead.phones ? lead.phones.filter((x) => x.is_primary) : [];
    if (primary.length == 0) {
      return '';
    }
    const phoneNumber = parsePhoneNumber(primary[0].number);
    if (phoneNumber) {
      return phoneNumber.formatNational();
    }
    return primary[0].number;
  };
  const isSelected = (template) => {
    if (!task) {
      return false;
    }
    return task.message_template_id === template.id;
  };

  const messageTemplateOptions = () => {
    return textTemplates
      .map((template) => {
        const selected = isSelected(template);
        if (selected) {
          setDefaultTemplate(template.id);
          renderTemplate(template.id);
        }
        return (
          <option value={template.id} key={template.id}>
            {template.name}
          </option>
        );
      })
      .sort((a, b) => (a.props.children > b.props.children ? 1 : -1));
  };

  const changeTemplate = (e) => {
    const selectedId = e.target.value;
    const template = textTemplates.find((e) => e.id == selectedId);
    if (!template) {
      setMessageBody('');
      return;
    }
    renderTemplate(template.id);
  };

  const updateTask = () => {
    Task.edit({
      id: task.id,
      user_id: user.id,
      completed_at: new Date().toISOString(),
    }).then(() => {
      queryClient.invalidateQueries(tasksQueryKeys.all);
      handleClose();
      history.push({ pathname: '/tasks' });
    });
  };

  const handleSend = () => {
    toast.info('Sending text', toastOptions);
    TextMessageClient.create(
      lead.id,
      lead.team_id,
      messageBody,
      user.id,
      lead.organization_id
    )
      .then((res) => {
        queryClient.invalidateQueries(tasksQueryKeys.all);
        queryClient.invalidateQueries(leadsQueryKeys.shows());
        toast.success('Sent text successfully', toastOptions);
      })
      .catch((_) => {
        toast.error('Failed to send text. Please try again.', toastOptions);
      });

    if (task) {
      updateTask();
    } else {
      handleClose();
    }
  };

  const updateMessageBody = (e) => {
    setMessageBody(e.target.value);
  };

  const handleClose = () => {
    setMessageBody('');
    closeModal();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className={'modal-sigma'}
      backdrop={'static'}
    >
      <Modal.Header className={'text-center'}>
        <Modal.Title className={'w-100'}>{primaryPhone()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId={'to'}>
            <Form.Label className={'mr-1'}>
              <strong>To:</strong>
            </Form.Label>
            {lead.first_name} {lead.last_name}
          </Form.Group>
          <Form.Group controlId={'template'}>
            <Form.Label>
              <strong>Template:</strong>
            </Form.Label>
            <Form.Control
              as={'select'}
              onChange={changeTemplate}
              defaultValue={defaultTemplate}
            >
              <option>---</option>
              {templateOptions}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId={'message'}>
            <Form.Label>
              <strong>Message:</strong>
            </Form.Label>
            {isRenderingTemplate ? (
              <Skeleton height={50} />
            ) : (
              <Form.Control
                as={'textarea'}
                rows={6}
                value={messageBody}
                onChange={updateMessageBody}
              />
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row>
            <Col xs={'6'}>
              <button
                type={'button'}
                className={'btn btn-light btn-block'}
                onClick={handleClose}
              >
                Cancel
              </button>
            </Col>
            <Col xs={'6'}>
              <Button
                variant="primary"
                className="w-100"
                onClick={handleSend}
                disabled={messageBody.trim() === ''}
              >
                Send Text Now
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

export default TextMessage;

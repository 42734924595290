import { useQuery } from 'react-query';
import { queryKeyFactory } from '../queryClientConfig';
import Cloud9Staff from './Cloud9Staff';

export const cloud9QueryKeys = queryKeyFactory('cloud9Staff');

export const useCloud9Staff = (filters, options) => {
  return useQuery(cloud9QueryKeys.list(filters), () => Cloud9Staff.list(filters), {
    staleTime: 0,
    retry: 0,
    ...options,
  });
};

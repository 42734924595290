import { useQuery, useQueryClient } from 'react-query';
import { useOrg } from '../../../contexts/OrgProvider';
import { ONE_HOUR, queryKeyFactory } from '../queryClientConfig';
import Teams from './Teams';

export const teamsQueryKeys = queryKeyFactory('teams');

export const useTeamsList = (params = [], options) => {
  const { id, isMasterAccount } = useOrg();
  return useQuery(
    teamsQueryKeys.list([...params]),
    () => {
      const filters = [['page_size', 10000], ...params];
      if (!isMasterAccount) {
        filters.push(['organization_id[]', id]);
      }
      return Teams.list(filters);
    },
    {
      staleTime: ONE_HOUR,
      ...options,
    }
  );
};

export const useTeamsShow = (teamId, options) => {
  const queryClient = useQueryClient();
  return useQuery(
    teamsQueryKeys.show({ team_id: teamId }),
    () => Teams.show(teamId),
    {
      staleTime: ONE_HOUR,
      initialData: () => {
        let cache = queryClient.getQueryData(
          teamsQueryKeys.show({ team_id: teamId })
        );
        if (cache) {
          return;
        }
        return queryClient.getQueryData('teams')?.find((d) => d.id == teamId);
      },
      ...options,
    }
  );
};

import Cookies from 'js-cookie';
import { getActiveOrg } from '../util/UserUtils';

export default class Metrics {
  static async list({ key = 0, startDate, endDate, page_size }) {
    const organizationId = getActiveOrg();

    const params = `key=${key}&organization_id=${organizationId}&calculated_at_begin=${startDate}&calculated_at_end=${endDate}&page_size=${page_size}&order=calculated_at`;
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/metrics?${params}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    );
    return response.json();
  }
}

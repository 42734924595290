import { useQuery } from 'react-query';
import { queryKeyFactory } from '../queryClientConfig';
import Cloud9AppointmentTypes from './Cloud9AppointmentTypes';

export const cloud9QueryKeys = queryKeyFactory('cloud9AppointmentTypes');

export const useCloud9AppointmnetTypes = (filters, options) => {
  return useQuery(
    cloud9QueryKeys.list(filters),
    () => Cloud9AppointmentTypes.list(filters),
    {
      staleTime: 0,
      retry: 0,
      ...options,
    }
  );
};

import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { ExclamationIcon } from '../../theme/icons';
import { Box } from '@mui/system';

const AuthenticatedPlayer = ({ source }) => {
  const [url, setUrl] = useState();
  const [isInS3Error, setIsInS3Error] = useState(false);
  const [isInError, setIsInError] = useState(false);

  const errorHandler = (err) => {
    setIsInS3Error(true);
  };

  useEffect(() => {
    if (!isInS3Error && url) {
      return;
    }
    const options = {
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${Cookies.get('cognito-jwt')}`,
      },
    };
    fetch(source, options)
      .then((response) => {
        setIsInS3Error(false);
        setUrl(response.url);
      })
      .catch((err) => {
        setIsInError(true);
      });
  }, [source, isInS3Error]);

  return isInError ? (
    <div>
      <ExclamationIcon /> Error loading recording
    </div>
  ) : !url || isInS3Error ? (
    <Box mt={2}>Loading...</Box>
  ) : (
    <ReactAudioPlayer src={url} controls autoPlay={false} onError={errorHandler} />
  );
};

export default AuthenticatedPlayer;

import { toJSON } from '../queryClientConfig';
import Cookies from 'js-cookie';

export default class SharedTemplates {
  static async create(sharedTemplate) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/shared_templates`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sharedTemplate),
    }).then(toJSON);
  }
}

import { useQuery } from 'react-query';
import { queryKeyFactory } from '../../MUI/services/queryClientConfig';
import Appointments from './Appointments';

export const leadappointmentsQueryKeys = queryKeyFactory('appointments');

export const useAppointmentsList = (filters, options) => {
  const mergedFilters = Object.assign({}, ...filters);
  return useQuery(
    leadappointmentsQueryKeys.list(mergedFilters),
    () => Appointments.list(mergedFilters),
    {
      staleTime: 0,
      ...options,
    }
  );
};

import { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TemplatePreview = ({ children, getTemplateContent }) => {
  const [preview, setPreview] = useState();

  return (
    <OverlayTrigger
      onEntered={() => setPreview(getTemplateContent())}
      placement={window.innerWidth > 992 ? 'right' : 'left'}
      overlay={
        <Tooltip className={`preview-tooltip ${!preview && 'd-none'}`}>
          {preview}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export default TemplatePreview;

import Cookies from 'js-cookie';
import { toParamString } from '../../MUI/services/queryClientConfig';
import { toJSON } from '../utils/utils';

export default class Teams {
  static async search(phoneParams) {
    return await fetch(
      `${process.env.REACT_APP_PHONE_API}/v1/phones?${toParamString(phoneParams)}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async register_for_teams(team) {
    return await fetch(`${process.env.REACT_APP_PHONE_API}/v1/phones/teams`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
      },
      body: JSON.stringify(team),
    }).then(toJSON);
  }

  static async register_for_ivrs(team) {
    return await fetch(`${process.env.REACT_APP_PHONE_API}/v1/phones/ivrs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
      },
      body: JSON.stringify(team),
    }).then(toJSON);
  }

  static async delete(phoneID) {
    return await fetch(
      `${process.env.REACT_APP_PHONE_API}/v1/phones/ivr?phone_id=${phoneID}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        },
      }
    );
  }

  static async team_phone_delete(teamID) {
    return await fetch(
      `${process.env.REACT_APP_PHONE_API}/v1/phones?team_id=${teamID}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        },
      }
    );
  }
}

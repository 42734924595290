import { Box, IconButton, Typography } from '@mui/material';

export default function PhoneButton({ size, icon, label, onClick, color }) {
  const width = size === 'large' ? '90px' : '45px';
  const height = size === 'large' ? '90px' : '45px';

  return (
    <>
      <IconButton
        color={color}
        onClick={onClick}
        size={size}
        sx={{
          border: '1px solid black',
          width: width,
          height: height,
          backgroundColor: 'rgb(239, 239, 239)',
        }}
      >
        {icon}
      </IconButton>
      {size == 'large' && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 1 }}
        >
          <Typography variant="subtitle2">{label}</Typography>
        </Box>
      )}
    </>
  );
}

import { useEffect, useState } from 'react';
import { useCustomFields } from '../../api/customFields/queries';
import { useAccountFields } from '../../api/accountFields/queries';
import { dateToAbbreviatedDateString } from '../services/dateTime';
import {
  useCustomFieldsCreate,
  useCustomFieldsDelete,
  useCustomFieldsEdit,
  useCustomFieldsReorder,
} from '../../api/customFields/mutations';
import {
  useAccountFieldsCreate,
  useAccountFieldsDelete,
  useAccountFieldsEdit,
} from '../../api/accountFields/mutations';
import { FormProvider } from '../components/HookForm';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  MenuItem,
  InputLabel,
  Typography,
  Tab,
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import { DragDropContext } from 'react-beautiful-dnd';
import { Prompt } from 'react-router-dom';
import { string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { DeleteIcon, EditIcon, PlusIcon, SaveIcon } from '../theme/icons';
import { fireDialog } from '../components/Dialog';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { DragableTable } from '../components/DragableTable';
import LoadingButton from '@mui/lab/LoadingButton';
import { useOrg } from '../../contexts/OrgProvider';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  OPTION_TYPES,
  DATE_TIME_TYPE,
} from '../../api/customFields/customFieldsUtils';
import TabList from '@mui/lab/TabList';

const CUSTOM_FIELD_NAME = 'customFieldName';
const CUSTOM_FIELD_VALUE = 'customFieldValue';
const DATA_TYPE_NAME = 'dataTypeName';
const MASTER_BOOL_NAME = 'customFieldMasterBoolName';
const OPTIONS_DATA_NAME = 'optionsDataName';
const REQUIRE_NAME = 'requiredName';

export default function CustomFields() {
  /* ANCHOR AccountFieldsEntity */
  const { id: orgId } = useOrg();

  const [currentTab, setCurrentTab] = useState('Lead');

  const handleChangeTab = (_, tabName) => {
    setCurrentTab(tabName);
  };

  const {
    data: customFields,
    isFetching: areCustomFieldsLoading,
    isError: didLoadingCustomFieldsError,
  } = useCustomFields([['organization_id[]', orgId]]);

  const {
    data: accountFields,
    isFetching: areAccountFieldsLoading,
    isError: didLoadingAccountFieldsError,
  } = useAccountFields([['organization_id[]', orgId]]);

  const [localCustomFields, setLocalCustomFields] = useState([]);

  useEffect(() => {
    if (customFields && !isOrderDirty) {
      setLocalCustomFields(JSON.parse(JSON.stringify(customFields)));
    }
  }, [customFields]);

  /* ANCHOR Edit Custom Field*/

  const handleEditCustomFieldClick = (customField, hook, currentTab) => {
    fireDialog((promiseParams) =>
      EditCustomFieldDialog({
        ...promiseParams,
        customField,
        hook,
        currentTab,
      })
    );
  };

  /* ANCHOR Delete custom field */

  const handleDeleteCustomFieldClick = (customField, hook, currentTab) => {
    fireDialog((promiseProps) =>
      DeleteCustomFieldDialog({
        ...promiseProps,
        customField,
        hook,
        currentTab,
      })
    );
  };

  /* ANCHOR Create Custom Field */

  const handleAddCustomFieldClick = (nextOrder, hook, currentTab) => {
    fireDialog((promiseProps) =>
      AddCustomFieldDialog({
        ...promiseProps,
        nextOrder,
        hook,
        currentTab,
      })
    );
  };

  /* ANCHOR Reorder Custom Field */

  const {
    mutate: updateOrder,
    isLoading: isMutatingOrder,
    isError: didFailMutatingReorder,
  } = useCustomFieldsReorder({});
  const [isOrderDirty, setIsOrderDirty] = useState(false);

  const handleReorderClick = () => {
    const ordering = localCustomFields.map((item, index) => {
      return { id: item.id, field_order: item.order, name: item.name };
    });
    updateOrder(ordering, { onSuccess: setIsOrderDirty(false) });
  };
  /**
   * If the item wasa actually dragged, updates the local custom fields list with the custom fields swapped
   * And the order updated on all custom fields. Also flips order dirty state
   *
   * @param {*} result - Result of the dragging
   * @param {*} provided
   * @return {*}
   */
  const handleDragEnd = (result, provided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    setIsOrderDirty(true);
    setLocalCustomFields((prev) => {
      let newLocalItems = [...prev];
      const moving_item = newLocalItems[result.source.index];
      newLocalItems.splice(result.source.index, 1);
      newLocalItems.splice(result.destination.index, 0, moving_item);

      newLocalItems = newLocalItems.map((item, index) => {
        return { ...item, order: index + 1 };
      });

      return newLocalItems;
    });
  };

  /* ANCHOR render custom field */

  const prettifyDataType = (dataType) => {
    if (dataType == 'string') return 'Text';
    else if (dataType == DATE_TIME_TYPE) return 'Date & Time';
    else if (dataType == 'string_options') return 'Choices';
    else if (dataType == 'free_string_options') return 'Choices & Custom Text';
    return '';
  };

  const generateColsFromCustomFields = () => {
    if (localCustomFields) {
      return localCustomFields.map((item, index) => ({
        key: item.id,
        cols: [
          index + 1,
          item.name,
          prettifyDataType(item.data_type),
          <>
            <Button
              startIcon={<EditIcon />}
              size="small"
              variant="text"
              color="primary"
              onClick={() =>
                handleEditCustomFieldClick(item, useCustomFieldsEdit, currentTab)
              }
              disabled={isOrderDirty}
              sx={{ marginRight: 1 }}
            >
              Edit
            </Button>
          </>,
          <>
            <Button
              startIcon={<DeleteIcon />}
              size="small"
              variant="text"
              color="error"
              onClick={() =>
                handleDeleteCustomFieldClick(item, useCustomFieldsDelete, currentTab)
              }
              disabled={isOrderDirty}
            >
              Delete
            </Button>
          </>,
          <></>,
        ],
      }));
    }
    return [];
  };

  return (
    <SettingsCard showLoading={areCustomFieldsLoading || areAccountFieldsLoading}>
      <Prompt
        message={(location) => {
          if (location.pathname.includes('logout')) return true;
          if (isOrderDirty)
            return 'You have unsaved changes, are you sure you would like to leave this page?';
        }}
      />
      <SettingsCard.Header>Custom Fields</SettingsCard.Header>
      {currentTab === 'Lead' && (
        <SettingsCard.SubHeader>
          Custom fields will display for every lead in the order shown below.
        </SettingsCard.SubHeader>
      )}
      <TabContext value={currentTab}>
        <TabList onChange={handleChangeTab}>
          <Tab label="Lead" value="Lead" />
          <Tab label="Account" value="Account" />
        </TabList>
      </TabContext>
      <SettingsCard.Main>
        {didFailMutatingReorder && (
          <Alert severity="error">
            There was a problem reordering your custom fields. Try again.
          </Alert>
        )}
        {(didLoadingCustomFieldsError || didLoadingAccountFieldsError) && (
          <Alert severity="error">
            There was a problem loading your custom fields. Try again.
          </Alert>
        )}
        {Boolean(customFields?.length) || (
          <Alert severity="info" sx={{ mt: 1, mb: 2 }}>
            No custom fields created. Add your first one below.
          </Alert>
        )}
        {currentTab === 'Lead' && Boolean(customFields?.length) && (
          <TableContainer sx={{ mb: 2 }}>
            <Table size="small">
              <colgroup>
                <col style={{ width: '5%' }} />
                <col style={{ width: '5%' }} />
                <col style={{ width: '40%' }} />
                <col style={{ width: '40%' }} />
                <col style={{ width: '5%' }} />
                <col style={{ width: '5%' }} />
                <col />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>Order</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Data Type</TableCell>
                  <TableCell colSpan={3} />
                </TableRow>
              </TableHead>
              <DragDropContext onDragEnd={handleDragEnd}>
                <DragableTable items={generateColsFromCustomFields()} />
              </DragDropContext>
            </Table>
          </TableContainer>
        )}
        {currentTab === 'Account' && Boolean(accountFields?.length) && (
          <TableContainer sx={{ mb: 2 }}>
            <Table size="small">
              <colgroup>
                <col style={{ width: '25%' }} />
                <col style={{ width: '25%' }} />
                <col style={{ width: '21%' }} />
                <col style={{ width: '28%' }} />
                <col />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>Created at</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {accountFields?.map((field) => (
                  <TableRow key={field.id}>
                    <TableCell>
                      <Typography component={'span'} variant="subtitle3">
                        {field.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography component={'span'} variant="subtitle3">
                        {field.value}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography component={'span'} variant="subtitle3">
                        {dateToAbbreviatedDateString(field.created_at)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Button
                        startIcon={<EditIcon />}
                        size="small"
                        variant="text"
                        color="primary"
                        onClick={() =>
                          handleEditCustomFieldClick(
                            field,
                            useAccountFieldsEdit,
                            currentTab
                          )
                        }
                        sx={{ marginRight: 1 }}
                      >
                        Edit
                      </Button>
                      <Button
                        startIcon={<DeleteIcon />}
                        size="small"
                        variant="text"
                        color="error"
                        onClick={() =>
                          handleDeleteCustomFieldClick(
                            field,
                            useAccountFieldsDelete,
                            currentTab
                          )
                        }
                        sx={{ marginRight: 1 }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {currentTab === 'Lead' && (
          <>
            <Button
              startIcon={<PlusIcon />}
              onClick={() => {
                handleAddCustomFieldClick(
                  customFields?.reduce((acc, current) => {
                    if (current.order > acc) return current.order;
                    return acc;
                  }, 0) + 1,
                  useCustomFieldsCreate,
                  currentTab
                );
              }}
              disabled={isOrderDirty}
              sx={{ marginRight: 1 }}
            >
              Add New
            </Button>
            <LoadingButton
              loading={isMutatingOrder}
              startIcon={<SaveIcon />}
              loadingPosition="start"
              onClick={handleReorderClick}
              disabled={!isOrderDirty}
            >
              Save
            </LoadingButton>
          </>
        )}
        {currentTab === 'Account' && (
          <Button
            startIcon={<PlusIcon />}
            onClick={() => {
              handleAddCustomFieldClick(
                +accountFields?.length + 1,
                useAccountFieldsCreate,
                currentTab
              );
            }}
            sx={{ marginRight: 1 }}
          >
            Add New
          </Button>
        )}
      </SettingsCard.Main>
    </SettingsCard>
  );
}

const CustomFieldOptions = ({ append, fields, remove, didError }) => {
  return (
    <>
      <InputLabel sx={{ mb: 0 }}>Options</InputLabel>
      {fields?.length != 0 ? (
        <>
          {fields.map((item, index) => {
            return (
              <Box
                sx={{
                  display: 'grid',
                  gap: 1,
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  justifyItems: 'left',
                }}
                key={item.id}
              >
                <FormProvider.TextField
                  name={`${OPTIONS_DATA_NAME}.${index}.val`}
                  defaultValue={item.val}
                  label={`Option ${index + 1}`}
                />
                <Button
                  sx={{ height: 'fit-content', p: 1 }}
                  startIcon={<DeleteIcon />}
                  variant="text"
                  color="error"
                  onClick={() => {
                    remove(index);
                  }}
                >
                  Delete
                </Button>
              </Box>
            );
          })}
        </>
      ) : (
        <>
          <Alert
            severity={didError ? 'error' : 'info'}
            sx={{
              mt: 0,
              mb: 0,
            }}
          >
            Add your first
          </Alert>
        </>
      )}
      <Button
        startIcon={<PlusIcon />}
        sx={{ width: 'fit-content', px: 1, py: 0.5 }}
        onClick={() => {
          append({ val: '' });
        }}
      >
        Add New
      </Button>
    </>
  );
};

/* ANCHOR Dialogs */

const AddCustomFieldDialog = ({
  isOpen,
  onResolve,
  onReject,
  nextOrder,
  hook,
  currentTab,
}) => {
  const { isMasterAccount, enterprise_id } = useOrg();
  const {
    mutate: createCustomField,
    isSuccess: didSucceedMutatingCustomField,
    isError: didFailMutatingCustomField,
    isLoading: isMutatingCustomField,
  } = hook();

  useEffect(() => {
    if (didSucceedMutatingCustomField && !didFailMutatingCustomField) onResolve();
  }, [didSucceedMutatingCustomField, didFailMutatingCustomField]);

  const defaultValues = {
    [CUSTOM_FIELD_NAME]: '',
    [CUSTOM_FIELD_VALUE]: '',
    [REQUIRE_NAME]: false,
    [DATA_TYPE_NAME]: 'string',
    [OPTIONS_DATA_NAME]: [],
    [MASTER_BOOL_NAME]: false,
  };

  const onCreateCustomFieldSubmit = (formData) => {
    const options = watch(OPTIONS_DATA_NAME)?.map((o) => o.val) || [];

    createCustomField({
      value: formData[CUSTOM_FIELD_VALUE],
      name: formData[CUSTOM_FIELD_NAME],
      order: nextOrder,
      required: formData[REQUIRE_NAME],
      data_type: formData[DATA_TYPE_NAME],
      master: formData[MASTER_BOOL_NAME],
      associated_enterprise_id: enterprise_id,
      options_data: options,
    });
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(
      yup.object({
        [CUSTOM_FIELD_NAME]: string().required('This field is required'),
        [OPTIONS_DATA_NAME]: yup
          .array()
          .of(
            yup
              .object()
              .shape({ val: yup.string().required('Option must be filled out.') })
          )
          .when(DATA_TYPE_NAME, (data_type, passSchema) => {
            return OPTION_TYPES.includes(data_type)
              ? passSchema.min(1, 'There must be at least one option.')
              : passSchema;
          }),
      })
    ),
  });

  const { control, handleSubmit, watch, formState } = { ...methods };

  const { fields, append, remove } = useFieldArray({
    control,
    name: OPTIONS_DATA_NAME,
  });

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Create {currentTab} Field</DialogTitle>
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(onCreateCustomFieldSubmit)}
        useDirtyFormCheck={true}
      >
        <DialogContent>
          {didFailMutatingCustomField && (
            <Alert severity="error">
              There was a problem creating your custom field. Try again.
            </Alert>
          )}
          <Box display="grid" direction="column" rowGap={2}>
            <FormProvider.TextField name={CUSTOM_FIELD_NAME} label={`Name`} />
            {currentTab === 'Account' && (
              <FormProvider.TextField name={CUSTOM_FIELD_VALUE} label={`Value`} />
            )}
            {currentTab === 'Lead' && (
              <>
                <FormProvider.Select name={DATA_TYPE_NAME} label="Data Type">
                  <MenuItem key={'string'} value={'string'}>
                    Text
                  </MenuItem>
                  <MenuItem key={DATE_TIME_TYPE} value={DATE_TIME_TYPE}>
                    Date & Time
                  </MenuItem>
                  <MenuItem key={'string_options'} value={'string_options'}>
                    Choice
                  </MenuItem>
                  <MenuItem
                    key={'free_string_options'}
                    value={'free_string_options'}
                  >
                    Choice + Custom Text
                  </MenuItem>
                </FormProvider.Select>
                {OPTION_TYPES.includes(watch(DATA_TYPE_NAME)) && (
                  <CustomFieldOptions
                    append={append}
                    fields={fields}
                    remove={remove}
                    didError={formState.submitCount > 0 && !!formState.errors}
                  />
                )}
                <FormProvider.Switch
                  name={REQUIRE_NAME}
                  label={'Require for manual entries'}
                />
                {isMasterAccount && (
                  <Box>
                    <FormProvider.Switch
                      name={MASTER_BOOL_NAME}
                      label="Shared with connected accounts"
                      sx={{ mb: 0 }}
                    />
                    <InputLabel shrink={true} sx={{ ml: 2, mt: 0 }}>
                      Adds to all current accounts and future accounts automatically.
                    </InputLabel>
                  </Box>
                )}
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="cancel" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton loading={isMutatingCustomField} type="submit">
            Confirm
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

const EditCustomFieldDialog = ({
  isOpen,
  onResolve,
  onReject,
  customField,
  hook,
  currentTab,
}) => {
  const {
    mutate: editCustomField,
    isSuccess: didSucceedMutatingCustomField,
    isError: didFailMutatingCustomField,
    isLoading: isMutatingCustomField,
  } = hook();

  useEffect(() => {
    if (didSucceedMutatingCustomField && !didFailMutatingCustomField) onResolve();
  }, [didSucceedMutatingCustomField, didFailMutatingCustomField]);

  const onEditCustomFieldSubmit = (formData) => {
    const options = watch(OPTIONS_DATA_NAME)?.map((o) => o.val) || [];

    editCustomField({
      ...customField,
      name: formData[CUSTOM_FIELD_NAME],
      value: formData[CUSTOM_FIELD_VALUE],
      options_data: options,
      required: formData[REQUIRE_NAME],
    });
  };

  const defaultValues = {
    [CUSTOM_FIELD_NAME]: customField.name,
    [CUSTOM_FIELD_VALUE]: customField.value,
    [REQUIRE_NAME]: customField.required,
    [OPTIONS_DATA_NAME]:
      customField.options_data?.map((cf) => {
        return { val: cf };
      }) || [],
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(
      yup.object({
        [CUSTOM_FIELD_NAME]: string().required('This field is required'),
        [OPTIONS_DATA_NAME]: yup
          .array()
          .of(
            yup
              .object()
              .shape({ val: yup.string().required('Option must be filled out.') })
          )
          .when(DATA_TYPE_NAME, (_, passSchema) => {
            return OPTION_TYPES.includes(customField.data_type)
              ? passSchema.min(1, 'There must be at least one option.')
              : passSchema;
          }),
      })
    ),
  });
  const { control, handleSubmit, formState, watch } = { ...methods };

  const { fields, append, remove } = useFieldArray({
    control,
    name: OPTIONS_DATA_NAME,
  });

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Edit {currentTab} Field</DialogTitle>
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(onEditCustomFieldSubmit)}
        useDirtyFormCheck={true}
      >
        <DialogContent>
          <Box display="grid" direction="column" rowGap={2}>
            <FormProvider.TextField name={CUSTOM_FIELD_NAME} label={`Name`} />
            {currentTab === 'Account' && (
              <FormProvider.TextField name={CUSTOM_FIELD_VALUE} label={`Value`} />
            )}
            {currentTab === 'Lead' && (
              <FormProvider.Switch
                name={REQUIRE_NAME}
                label={'Require for manual entries'}
              />
            )}
            {OPTION_TYPES.includes(customField.data_type) && (
              <CustomFieldOptions
                append={append}
                fields={fields}
                remove={remove}
                didError={formState.submitCount > 0 && !!formState.errors}
              />
            )}
          </Box>
          {customField.master && (
            <InputLabel shrink sx={{ ml: 0, mt: 2, mb: 0 }}>
              This custom field is shared with all connected accounts.
            </InputLabel>
          )}
          {customField.master_custom_field_id && (
            <InputLabel
              shrink
              sx={{
                ml: 0,
                mt: 2,
                mb: 0,
                width: '100%',
                overflowWrap: 'anywhere',
                wordBreak: 'break-word',
                overflow: 'unset',
                whiteSpace: 'break-spaces',
                textOverflow: 'unset',
              }}
            >
              This custom field is controlled by a Master Account. Changes made may
              be overwritten.
            </InputLabel>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="cancel" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton loading={isMutatingCustomField} type="submit">
            Confirm
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

const DeleteCustomFieldDialog = ({
  isOpen,
  onResolve,
  onReject,
  customField,
  hook,
  currentTab,
}) => {
  const {
    mutate: deleteCustomField,
    isSuccess: didSucceedMutatingCustomField,
    isError: didFailMutatingCustomField,
    isLoading: isMutatingCustomField,
  } = hook();

  useEffect(() => {
    if (didSucceedMutatingCustomField && !didFailMutatingCustomField) onResolve();
  }, [didSucceedMutatingCustomField, didFailMutatingCustomField]);

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>
        Delete {currentTab} Field / {customField.name}
      </DialogTitle>
      <DialogContent>
        {didFailMutatingCustomField && (
          <Alert severity="error">
            There was a problem deleting your custom field. Try again.
          </Alert>
        )}
        You are about to delete the custom field listed above. This will permanently
        remove the field from all leads and no leads created in the future will
        display the field.
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          loading={isMutatingCustomField}
          onClick={() => deleteCustomField(customField.id)}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Skeleton } from '@mui/material';
import { DownloadIcon } from '../../theme/icons';

const AuthenticatedFileViewer = ({ src }) => {
  const [signedUrl, setSignedUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(src, {
      headers: {
        mode: 'cors',
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
      },
    }).then((response) => {
      setIsLoading(false);
      setSignedUrl(response.url);
    });
    //TODO catch?
  }, [src]);

  if (isLoading) {
    return <Skeleton width={100} sx={{ m: 3 }} />;
  }

  return (
    <a href={signedUrl} target="_blank">
      <DownloadIcon /> Open File
    </a>
  );
};

export default AuthenticatedFileViewer;

import { useMutation, useQueryClient } from 'react-query';
import { mutationKeyFactory } from '../queryClientConfig';
import { usersQueryKeys } from './queries';
import { notificationEmailAddressesQueryKeys } from '../notificationEmailAddresses/queries';
import { useOrg } from '../../../contexts/OrgProvider';
import User from './Users';
import { userOrganizationConnectionsQueryKeys } from '../userOrganizationConnections/queries';

const usersMutationKeys = mutationKeyFactory('users');

export const useUsersEdit = (options) => {
  const queryClient = useQueryClient();
  return useMutation(User.edit, {
    onSuccess: (_, variables) => {
      queryClient
        .invalidateQueries(
          notificationEmailAddressesQueryKeys.list({ user_id: variables.id })
        )
        .then(() => {
          queryClient.refetchQueries(
            notificationEmailAddressesQueryKeys.list({ user_id: variables.id })
          );
        });
      queryClient
        .resetQueries(usersQueryKeys.show({ user_id: variables.id }))
        .then(() => {
          queryClient.refetchQueries(usersQueryKeys.show({ user_id: variables.id }));
        });
      queryClient.invalidateQueries(usersQueryKeys.lists());
    },
    mutationKey: usersMutationKeys.edit(),
    ...options,
  });
};

export const useUsersDelete = (options) => {
  const queryClient = useQueryClient();
  return useMutation(User.delete, {
    mutationKey: usersMutationKeys.delete(),
    onSuccess: () => {
      queryClient.invalidateQueries(usersQueryKeys.lists());
      queryClient.invalidateQueries(userOrganizationConnectionsQueryKeys.lists());
    },
    ...options,
  });
};

export const useUsersCreate = () => {
  const { id } = useOrg();
  const queryClient = useQueryClient();
  return useMutation((params) => User.create({ organization_id: id, ...params }), {
    mutationKey: usersMutationKeys.create(),
    onSuccess: () => {
      queryClient.invalidateQueries(usersQueryKeys.lists());
      queryClient.invalidateQueries(userOrganizationConnectionsQueryKeys.lists());
    },
  });
};

import Cookies from 'js-cookie';
import { toJSON, toParamString } from '../queryClientConfig';

export default class LeadLocks {
  static async list(filters = {}) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/lead_locks?${toParamString(filters)}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }
  static async create(lock) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/lead_locks`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(lock),
    }).then(toJSON);
  }

  static async delete(id) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/lead_locks/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
}

import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { getActiveOrg } from '../../util/UserUtils';

const BroadcastChannelModal = () => {
  const history = useHistory();
  const [showBroadcastChannelModal, setShowBroadcastChannelModal] = useState(false);
  const [broadcastUrl, setBroadcastUrl] = useState();

  useEffect(() => {
    if ('BroadcastChannel' in window) {
      const pushChannel = new BroadcastChannel('push');
      pushChannel.onmessage = (message) => {
        setBroadcastUrl(decodeURIComponent(message.data.url));
      };
      return () => pushChannel.close();
    }
  }, []);

  useEffect(() => {
    if (broadcastUrl) {
      const url = new URL(broadcastUrl, window.location.origin);
      const orgPath = url.pathname.match(/organizations\/[0-9]+/g)[0];
      const orgPathArr = orgPath.split('/');
      if (orgPathArr[1] === getActiveOrg()) toCurrentOrg();
      else setShowBroadcastChannelModal(true);
    }
  }, [broadcastUrl]);

  function toCurrentOrg() {
    const url = new URL(broadcastUrl, window.location.origin);
    history.push('/');
    // timeout to throw to api so navigation prompts don't get triggered twice
    setTimeout(() => {
      history.push(url.pathname + url.search);
    }, 0);
  }

  const reloadOrg = () => {
    window.location.href = broadcastUrl;
  };

  return (
    <Modal
      show={showBroadcastChannelModal}
      onHide={() => setShowBroadcastChannelModal(false)}
      className="modal-sigma"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>Leave this page?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        LeadSigma wants to take you to a different page and load new content. All
        changes on your current page will be unsaved. Do you want to continue?
      </Modal.Body>
      <Modal.Footer>
        <Row noGutters className="w-100">
          <Col xs={6} className="px-3">
            <Button
              className="btn btn-light btn-block"
              onClick={() => {
                setShowBroadcastChannelModal(false);
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col xs={6} className="px-3">
            <Button className="btn btn-sigma-primary btn-block" onClick={reloadOrg}>
              Yes!
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default BroadcastChannelModal;

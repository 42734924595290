import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Typography, Alert } from '@mui/material';

import { useOrg } from '../../contexts/OrgProvider';
import { useOrganizationsList } from '../services/organizations/queries';
import { useActivitiesList } from '../services/activity/queries';
import { useUsersCurrent } from '../services/users/queries';
import { dateToAbbreviatedDateTimeString } from '../services/dateTime';

import { eventTypeNameMap } from '../services/events/eventsUtils';
import { toParamString } from '../services/queryClientConfig';
import { DataGridStyle } from '../theme/styled/DataGridStyle';
import { parseActivitiesFilterParams } from '../components/Activities/utils';
import { CellSpanStyle } from '../theme/styled/CellSpanStyle';
import FilterButtonWrapper from '../components/Filters/FilterButtonWrapper';
import CustomFooter from '../components/Activities/CustomFooter';
import FilterBar from '../components/Activities/FilterBar';

const Activity = () => {
  const history = useHistory();
  const filterBarRef = useRef();
  const { id: orgId, isMasterAccount, enterprise_id } = useOrg();
  const { data: accountsList } = useOrganizationsList();
  const { data: currentUser } = useUsersCurrent();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [activitiesFilterMap, setActivitiesFilterMap] = useState({ status: null });

  const [requestParams, filterParams] = useMemo(
    () => parseActivitiesFilterParams(activitiesFilterMap, isMasterAccount, orgId),
    [activitiesFilterMap, orgId]
  );

  const [orgIds, setOrgIds] = useState([]);
  const [sortModel, setSortModel] = useState([]);

  const [showFilters, setFilterToggle] = useState(
    window.innerWidth <= 768 ? false : true
  );

  const isOnEnterprise = () => {
    return !!enterprise_id;
  };

  useEffect(() => {
    if (accountsList) {
      setOrgIds(
        accountsList.map((org) => {
          return org.id;
        })
      );
    }
  }, [accountsList]);

  const getSorting = () => {
    if (sortModel.length === 0) {
      return [];
    }
    return [
      ['order', `${sortModel[0].field}`],
      ['direction', sortModel[0].sort],
    ];
  };

  const createActivitiesListFilters = () => {
    return [
      ['page', page],
      ['page_size', pageSize],
      ...getSorting(),
      ...filterParams,
    ];
  };

  const handleSort = (newModel) => {
    setSortModel(newModel);
  };

  const transformList = (data) => {
    const itemsWithAccountName = data?.items?.map((activity) => {
      const tempActivity = { ...activity };
      const foundAccount = accountsList?.find(
        ({ id }) => String(activity.organization_id) === String(id)
      );
      if (foundAccount) tempActivity.appended_account_name = foundAccount.name;
      return tempActivity;
    });
    return { ...data, items: itemsWithAccountName };
  };

  const { data, isFetching, isError } = useActivitiesList(
    createActivitiesListFilters(),
    {
      select: transformList,
    }
  );

  const columns = [
    {
      field: 'description',
      headerName: 'Activity',
      flex: 2,
      minWidth: 400,
      sortable: false,
      renderCell: ({ row, value }) => {
        return (
          <Box
            component="span"
            color={({ palette }) =>
              row.resource_class && row.resource_id ? palette.info.main : ''
            }
            dangerouslySetInnerHTML={{ __html: value }}
          />
        );
      },
    },
    {
      field: 'event_type',
      headerName: 'Event',
      flex: 1,
      sortable: false,
      valueFormatter: ({ value }) => {
        return eventTypeNameMap[value];
      },
    },
    {
      field: 'created_at',
      headerName: 'Date',
      flex: 1,
      sortable: true,
      valueFormatter: ({ value }) => dateToAbbreviatedDateTimeString(value),
    },
    {
      field: 'organization_id',
      headerName: 'Account',
      hide: !isOnEnterprise(),
      flex: 1,
      sortable: true,
      renderCell: ({ row }) => (
        <CellSpanStyle>{row.appended_account_name}</CellSpanStyle>
      ),
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      {isError && (
        <Alert severity="error">
          There was an error loading your activities. Try again.
        </Alert>
      )}

      <Box display="flex" justifyContent="space-between" sx={{ pr: 2 }}>
        <Typography fontSize={18} fontWeight={700}>
          Activity
        </Typography>
        <Box display="flex" alignItems="center">
          <Box sx={{ marginLeft: '10px' }}>
            <FilterButtonWrapper
              showFilters={showFilters}
              setFilterToggle={setFilterToggle}
            />
          </Box>
        </Box>
      </Box>

      <Box display="flex" height="0%" width="100%" flexBasis="100%">
        {showFilters ? (
          <FilterBar
            setPage={setPage}
            ref={filterBarRef}
            setActivitiesFilterMap={setActivitiesFilterMap}
          />
        ) : (
          <></>
        )}
        <Box width="100%" height="100%">
          <DataGridStyle
            disableSelectionOnClick
            sx={{
              '& .clickable': {
                cursor: 'pointer',
              },
              '& .not-clickable': {
                cursor: 'default',
              },
            }}
            rows={
              data?.items?.filter((r) =>
                orgIds?.includes(Number(r.organization_id))
              ) ?? []
            }
            columns={columns}
            getRowClassName={(params) => {
              if (params.row.resource_class && params.row.resource_id)
                return 'clickable';
              return 'not-clickable';
            }}
            onRowClick={({ row: { resource_class, resource_id } }) => {
              if (resource_class && resource_id) {
                let route = '';
                if (resource_class === 'lead') route = `leads/${resource_id}`;
                if (resource_class === 'template') route = 'settings/templates';
                if (resource_class === 'sequence') route = 'settings/sequences';
                history.push(`${route}`);
              }
            }}
            loading={isFetching}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSort}
            disableColumnFilter
            disableColumnMenu
            density="compact"
            components={{
              Footer: () => (
                <CustomFooter
                  rowCount={data?.total_count ?? 0}
                  page={page - 1}
                  onPageChange={(page) => setPage(page + 1)}
                  onPageSizeChange={setPageSize}
                  pageSize={pageSize}
                  organization_id={orgId}
                  requested_url={`${
                    process.env.REACT_APP_API_BASE
                  }/activity?${toParamString(requestParams)}`}
                  isCurrentUserAdmin={currentUser.roles.some(
                    (role) => role.role_name === 'Administrator'
                  )}
                />
              ),
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Activity;

import { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { EditIcon } from '../theme/icons';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { LoadingButton } from '@mui/lab';
import {
  useGoogleAdsList,
  useAdsAccountsList,
  useGoogleActionsList,
} from '../services/adsAccounts/queries';
import {
  useAdsAccountEdit,
  useAdsAccountDelete,
} from '../services/adsAccounts/mutations';
import { useOrg } from '../../contexts/OrgProvider';
import GoogleIcon from '../../assets/images/btn_google_dark_normal_ios.svg';
import { fireDialog } from '../components/Dialog';
import ClipLoader from 'react-spinners/ClipLoader';

export default function GooglePage() {
  const {
    mutate: editAdsAccount,
    isLoading: isSaving,
    isSuccess,
  } = useAdsAccountEdit();
  const [googleAccount, setGoogleAccount] = useState();
  const [googleMccId, setGoogleMccId] = useState();
  const [googleAccountId, setGoogleAccountId] = useState();
  const [googleAccountName, setGoogleAccountName] = useState();
  const [actionId, setActionId] = useState('');
  const [actionsEnabled, setActionsEnabled] = useState(false);
  const { id: orgId } = useOrg();

  const { data: adsAccounts, isLoading } = useAdsAccountsList([
    { platform: 'google', organization_id: orgId },
  ]);

  const {
    data: googleCustomers,
    isLoading: googleCustomersIsLoading,
  } = useGoogleAdsList([{ organization_id: orgId }]);

  const {
    data: conversionActions,
    isLoading: actionsIsLoading,
    isFetching: actionsIsFetching,
    refetch,
  } = useGoogleActionsList(
    [
      {
        organization_id: orgId,
        manager_customer_id: googleMccId,
        customer_id: googleAccountId,
      },
    ],
    {
      enabled: actionsEnabled,
    }
  );

  useEffect(() => {
    if (
      adsAccounts &&
      Array.isArray(adsAccounts.items) &&
      adsAccounts.items.length > 0
    ) {
      setGoogleAccount(adsAccounts.items[0]);
      if (adsAccounts.items[0].account) {
        const account = JSON.parse(adsAccounts.items[0].account);
        setGoogleAccountId(account.id);
        setGoogleAccountName(account.name);
        setGoogleMccId(account.mccId);
        setActionId(account.actionId);
        setActionsEnabled(true);
      }
    }
  }, [adsAccounts]);

  const handleDeactivate = () => {
    let id = googleAccount.id;
    fireDialog((promiseProps) => DeleteAdsAccountDialog({ id, ...promiseProps }));
  };

  const handleUpdate = (e) => {
    const account = e.target.value.split(':');
    setGoogleMccId(account[0]);
    setGoogleAccountId(account[1]);
    setGoogleAccountName(account[2]);
    refetch();
  };

  const handleSave = (e) => {
    editAdsAccount({
      id: googleAccount.id,
      account: JSON.stringify({
        id: googleAccountId,
        mccId: googleMccId,
        name: googleAccountName,
        actionId: actionId,
      }),
    });
  };

  const handleActionUpdate = (e) => {
    setActionId(e.target.value);
  };

  const buildGoogleAdsSelect = (googleCustomers) => {
    let options = [];
    googleCustomers.map((item) => {
      options.push(
        <ListSubheader key={`subheader-${item.id}`}>{item.name}</ListSubheader>
      );
      item.clients.map((client) => {
        options.push(
          <MenuItem key={client.id} value={`${item.id}:${client.id}:${client.name}`}>
            {client.name} ({client.id})
          </MenuItem>
        );
      });
    });
    return options;
  };

  const buildGoogleActionsSelect = (actions) => {
    let options = [];
    actions.map((item) => {
      options.push(
        <MenuItem key={item.id} value={`${item.id}`}>
          {item.name}
        </MenuItem>
      );
    });
    return options;
  };

  const DeleteAdsAccountDialog = ({ isOpen, onResolve, onReject, id }) => {
    const { mutate: deleteAdsAccount, isError, isLoading } = useAdsAccountDelete();

    const handleAdsAccountDelete = () => {
      deleteAdsAccount(id, {
        onSuccess: onResolve,
      });
      setGoogleAccount(null);
    };

    return (
      <Dialog open={isOpen} onClose={onReject}>
        <DialogTitle>Deactive Google Adswords?</DialogTitle>
        <DialogContent>
          {isError && (
            <Alert severity="error">
              There was a problem deactivating Google Adswords. Try again.
            </Alert>
          )}
          Are you sure you would like to deactivate Google Adwords?
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            disabled={isLoading}
            onClick={handleAdsAccountDelete}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  };

  if (isLoading) {
    return (
      <Box>
        <SettingsCard
          sx={{
            width: '100%',
          }}
        >
          <Grid container>
            <SettingsCard.Header>Google Ads Integration</SettingsCard.Header>
            <SettingsCard.Main sx={{ m: 5 }}>
              <Grid item align="center">
                <ClipLoader size={50} color="#34D1B6" />
              </Grid>
            </SettingsCard.Main>
          </Grid>
        </SettingsCard>
      </Box>
    );
  }

  return (
    <Box>
      <SettingsCard
        sx={{
          width: '100%',
        }}
      >
        <Grid container>
          <SettingsCard.Header>Google Ads Integration</SettingsCard.Header>
          <Grid item xs={3} m={0.5}>
            {googleAccount ? (
              <Chip
                label="Active"
                color="primary"
                style={{
                  backgroundColor: '#009900',
                  borderRadius: '4px',
                  mt: '2',
                  border: '0px solid green',
                }}
              />
            ) : (
              <Chip
                label="Inactive"
                color="primary"
                style={{
                  backgroundColor: '#dce0dd',
                  borderRadius: '4px',
                  mt: '2',
                  border: '0px solid #dce0dd',
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item xs={8}>
            <Typography sx={{ mt: 1 }}>
              Measure the ROI and effectiveness of your campaigns by integrating with
              Google Ads. Once your direct integration with Google Ads is active, you
              can match leads with your active campaigns, and see leads as
              conversions in Google Ads. You'll also be able to:<br></br>&nbsp;&nbsp;
              • Align your campaign clicks with individual callers or visitors.
              <br></br>&nbsp;&nbsp; • Use conversions to see how your ad groups and
              campaigns perform.<br></br>&nbsp;&nbsp; • Determine which PPC keywords
              are most effectively driving traffic to your website.
            </Typography>
          </Grid>
          <Grid item>
            <Card
              sx={{
                minWidth: 250,
                minHeight: 100,
                display: 'flex',
                ml: 4,
              }}
              style={{ backgroundColor: '#f2f1ed' }}
              elevation={0}
            >
              <Grid item ml={2} mt={2} paddingBottom={2}>
                <Typography variant="body1" sx={{ width: 200, minWidth: '100%' }}>
                  Question about this integration? Check out here
                </Typography>
                <Link
                  target="blank"
                  href="https://sigmaleads.atlassian.net/wiki/spaces/LCS/pages/1377173505/Google+Ads+Integration"
                >
                  Support Documentation
                </Link>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" mt={1}>
          <Grid item xs={8}>
            {googleAccount ? (
              <>
                <Grid item sx={{ mt: 2 }}>
                  {isSuccess && (
                    <Alert severity="success">Google Ads account updated</Alert>
                  )}
                  {googleCustomersIsLoading && (
                    <Grid item align="center">
                      <ClipLoader size={50} color="#34D1B6" />
                    </Grid>
                  )}
                  {!googleCustomersIsLoading && (
                    <>
                      <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                        <InputLabel id="demo-multiple-name-label">
                          Account
                        </InputLabel>
                        <Select
                          value={`${googleMccId}:${googleAccountId}:${googleAccountName}`}
                          id="grouped-select"
                          label="Grouping"
                          onChange={handleUpdate}
                        >
                          {googleCustomers && buildGoogleAdsSelect(googleCustomers)}
                        </Select>
                      </FormControl>
                    </>
                  )}
                </Grid>
                {actionsIsFetching ? (
                  <Grid item align="center">
                    <ClipLoader size={50} color="#34D1B6" />
                  </Grid>
                ) : (
                  <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                    <InputLabel>Conversion Action</InputLabel>
                    <Select value={`${actionId}`} onChange={handleActionUpdate}>
                      {conversionActions &&
                        buildGoogleActionsSelect(conversionActions)}
                    </Select>
                  </FormControl>
                )}
                <Grid item sx={{ ml: 1 }}>
                  <LoadingButton
                    sx={{ mt: 1 }}
                    size="medium"
                    loading={isSaving}
                    onClick={handleSave}
                  >
                    Save
                  </LoadingButton>
                </Grid>
              </>
            ) : (
              <Typography variant="body1" sx={{ width: 200, minWidth: '100%' }}>
                Once our JavaScript snippet is installed on your website, you can
                authorize your integration with Google Ads. Calls to visitor tracking
                numbers and new web form submissions will report as conversions in
                Google Ads.<br></br> Sign into the Google Ads account you'd like to
                use for this integration. Then, return to this page and click
                Authorize to activate your integration.<br></br>
                <br></br>
                <Button
                  color="google"
                  sx={{
                    maxHeight: '36px',
                    py: 0,
                    pl: 0,
                    pr: 1,
                    borderRadius: 0.2,
                    mb: 2,
                    '& img': { dipslay: 'block', height: '100%' },
                    '& .MuiButton-startIcon': {
                      height: '40px',
                      m: 0,
                      p: 0,
                      ml: -0.3,
                    },
                  }}
                  href={`${process.env.REACT_APP_CALL_TRACKING}/google/login?organization_id=${orgId}`}
                  type="submit"
                  startIcon={<img src={GoogleIcon} alt="Sign in with Google" />}
                >
                  Sign in with Google
                </Button>
              </Typography>
            )}
          </Grid>
          {googleAccount ? (
            <Grid item mt={2}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Card
                  sx={{
                    minWidth: 250,
                    minHeight: 100,
                    display: 'flex',
                  }}
                  style={{ backgroundColor: '#f2f1ed' }}
                  elevation={0}
                >
                  <Grid item mt={2} paddingBottom={2}>
                    <Typography
                      ml={2}
                      variant="body1"
                      sx={{ width: 200, minWidth: '100%' }}
                    >
                      Deactivating this integration will clear your current settings
                      and disconnect Google Ads.
                    </Typography>

                    <Button
                      color="inherit"
                      sx={{
                        color: '#f55e53',
                        border: '2px solid #f55e53',
                        backgroundColor: '#f2f1ed',
                        boxShadow: 'none',
                        ml: 2,
                        mt: 2,
                      }}
                      onClick={handleDeactivate}
                    >
                      Deactivate Google Ads
                    </Button>
                  </Grid>
                </Card>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </SettingsCard>
    </Box>
  );
}

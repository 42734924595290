import { useQuery } from 'react-query';
import Reports from './Reports';
import { queryKeyFactory } from '../queryClientConfig';

export const reportsQueryKeys = queryKeyFactory('reports');

export const useReportsList = (
  params = { apiGroup: '', canUse: false, filters: [] },
  setReportValues
) => {
  if (params.apiGroup.startsWith('apiBase')) {
    return useQuery(
      reportsQueryKeys.list([...params?.filters]),
      () => {
        if (params.canUse) return Reports.listSales([...params?.filters]);
      },
      {
        onSuccess: (data) => {
          setReportValues(data);
        },
      }
    );
  } else if (params.apiGroup.startsWith('messagingApiBase')) {
    return useQuery(
      reportsQueryKeys.list([...params?.filters]),
      () => {
        if (params.canUse) return Reports.listMessaging([...params?.filters]);
      },
      {
        onSuccess: (data) => {
          setReportValues(data);
        },
      }
    );
  }
};

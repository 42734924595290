import { useMutation, useQueryClient } from 'react-query';
import Mailboxes from './Mailboxes';
import AuthorizedUser from './AuthorizedUsers';
import { mutationKeyFactory } from '../queryClientConfig';
import { mailboxesQueryKeys } from './queries';
import { useOrg } from '../../../contexts/OrgProvider';

export const mailboxesMutationKeys = mutationKeyFactory('mailboxes');
export const authorizedUsersMutationKeys = mutationKeyFactory('authorized-users');

export const useMailboxesEdit = (options) => {
  const queryClient = useQueryClient();
  return useMutation(Mailboxes.edit, {
    mutationKey: mailboxesMutationKeys.edit(),
    onSuccess: () => {
      queryClient.invalidateQueries(mailboxesQueryKeys.all);
    },
    ...options,
  });
};

export const useMailboxesDelete = (options) => {
  const queryClient = useQueryClient();
  return useMutation(Mailboxes.delete, {
    mutationKey: mailboxesMutationKeys.delete(),
    onSuccess: () => {
      queryClient.invalidateQueries(mailboxesQueryKeys.all);
    },
    ...options,
  });
};

export const useMailboxesSend = (options) => {
  const queryClient = useQueryClient();
  const { id } = useOrg();
  return useMutation((email) => Mailboxes.send(email, id), {
    // onSuccess: () => {
    //   queryClient.invalidateQueries();
    // },
    ...options,
  });
};

export const useAuthorizedUserCreate = (options) => {
  const queryClient = useQueryClient();
  return useMutation(AuthorizedUser.create, {
    mutationKey: authorizedUsersMutationKeys.create(),
    onSuccess: () => {
      queryClient.invalidateQueries(mailboxesQueryKeys.all);
    },
    ...options,
  });
};

export const useAuthorizedUserRemove = (options) => {
  const queryClient = useQueryClient();
  return useMutation(AuthorizedUser.delete, {
    mutationKey: authorizedUsersMutationKeys.delete,
    onSuccess: () => {
      queryClient.invalidateQueries(mailboxesQueryKeys.all);
    },
    ...options,
  });
};

import { useState, useEffect, useRef, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useLeadsList } from '../services/leads/queries';
import { useLeadLocksList } from '../services/leadLocks/queries';
import { hasLeadLock } from '../services/leadLocks/leadLocksUtils';
import { useOrganizationsList } from '../services/organizations/queries';
import { useUsersCurrent } from '../services/users/queries';

import { dateToAbbreviatedDateTimeString } from '../services/dateTime';
import { getLeadName } from '../services/leads/leadsUtils';
import { useOrg } from '../../contexts/OrgProvider';

import { Box, Typography, Alert } from '@mui/material';
import { LockIcon } from '../theme/icons';
import { DataGridStyle } from '../theme/styled/DataGridStyle';
import { CellSpanStyle } from '../theme/styled/CellSpanStyle';
import { Collapse } from '@mui/material';
import { parseFilterParams } from '../components/Filters/utils';

// non-uplift components
import FilterButtonWrapper from '../components/Filters/FilterButtonWrapper';
import AddLeadButtonWrapper from '../components/Leads/AddLeadButtonWrapper';
import GroupUpdateButton from '../components/Leads/GroupUpdateButton';
import GroupDeleteButton from '../components/Leads/GroupDeleteButton';
import CustomFooter from '../components/Leads/CustomFooter';
import FilterBar from '../components/Leads/FilterBar';
import { LeadCardWrapper, ActiveCellSpan } from '../components/Leads/ui';

import { toParamString } from '../services/queryClientConfig';

const Leads = () => {
  const history = useHistory();
  const { leadIdParam } = useParams();
  const { id: orgId, isMasterAccount } = useOrg();
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(1);
  const [sortModel, setSortModel] = useState([
    { field: 'created_at', sort: 'desc' },
  ]);
  const filterBarRef = useRef();
  const [leadsFilterMap, setLeadsFilterMap] = useState({ status: null });

  const { data: currentUser } = useUsersCurrent();
  const {
    data: accountsList,
    isError: isAccountsListError,
  } = useOrganizationsList();

  const { data: leadLocksList } = useLeadLocksList();

  const [requestParams, filterParams] = useMemo(
    () => parseFilterParams(leadsFilterMap, isMasterAccount, orgId),
    [leadsFilterMap, orgId]
  );
  const [showFilters, setFilterToggle] = useState(window.innerWidth > 768);

  const [isLeadShowError, setLeadShowError] = useState(false);
  const [isError, setError] = useState(true);
  const [selectionModel, setSelectionModel] = useState([]);

  const location = useLocation();

  useEffect(() => {
    if (isLeadShowError && isError) {
      if (location.pathname !== '/leads') {
        setError(false);
      }
    }
  }, [isLeadShowError, isError, location]);

  function handleWindowSizeChange() {
    if (window.innerWidth <= 768) {
      setFilterToggle(false);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const getSorting = () => {
    if (sortModel.length === 0) {
      return [];
    }
    return [
      ['order', `leads.${sortModel[0].field}`],
      ['direction', sortModel[0].sort],
    ];
  };

  const createLeadsListFilters = () => {
    const filters = [
      ['page', page],
      ['page_size', pageSize],
      ...getSorting(),
      ...filterParams,
    ];
    if (!isMasterAccount) {
      filters.push(['organization_id', orgId]);
    }
    return filters;
  };

  const transformLeadList = (data) => {
    const itemsWithAccountName = data?.items?.map((lead) => {
      const tempLead = { ...lead };
      const foundAccount = accountsList?.find(
        ({ id }) => String(lead.organization_id) === String(id)
      );
      if (foundAccount) tempLead.appended_account_name = foundAccount.name;
      return tempLead;
    });
    return { ...data, items: itemsWithAccountName };
  };

  const {
    data: leadsList,
    isError: isLeadsListError,
    isLoading,
    refetch,
  } = useLeadsList(createLeadsListFilters(), {
    select: transformLeadList,
  });

  const columns = [
    {
      field: 'first_name',
      headerName: 'Name',
      flex: 1,
      renderCell: (cell) => {
        const leadIsLocked = hasLeadLock({
          leadId: cell.row.id,
          userId: currentUser.id,
          leadLocks: leadLocksList,
        });

        return (
          <ActiveCellSpan cell={cell}>
            <Box
              sx={{
                dipslay: 'inline-block',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >{`${getLeadName(cell.row)}`}</Box>
            {leadIsLocked && <LockIcon color="error" fontSize="small" />}
          </ActiveCellSpan>
        );
      },
    },
    {
      field: 'task_sequence_id',
      headerName: 'Sequence',
      flex: 1,
      sortable: false,
      hide: !!leadIdParam,
      renderCell: (cell) => (
        <CellSpanStyle>{cell?.row?.task_sequence?.name}</CellSpanStyle>
      ),
    },
    {
      field: 'source_id',
      headerName: 'Source',
      flex: 1,
      sortable: false,
      hide: !!leadIdParam,
      renderCell: (cell) => <CellSpanStyle>{cell?.row?.source?.name}</CellSpanStyle>,
    },
    {
      field: 'organization_id',
      headerName: 'Account',
      flex: 1,
      hide: !!leadIdParam,
      sortable: false,
      renderCell: ({ row }) => (
        <CellSpanStyle>{row.appended_account_name}</CellSpanStyle>
      ),
    },
    {
      field: 'created_at',
      headerName: 'Created Date',
      flex: 1,
      hide: !!leadIdParam,
      renderCell: ({ formattedValue }) => formattedValue,
      valueFormatter: ({ value }) => (
        <CellSpanStyle>{dateToAbbreviatedDateTimeString(value)}</CellSpanStyle>
      ),
    },
  ];

  const handleSort = (newModel) => {
    setSortModel(newModel);
  };

  const currentPath = window.location.pathname;
  const shouldShowFilters = currentPath === '/leads';

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography fontSize={18} fontWeight={700}>
          Leads
        </Typography>
        <Box display="flex" alignItems="center">
          <Box sx={{ marginLeft: '10px' }}>
            {shouldShowFilters && (
              <FilterButtonWrapper
                showFilters={showFilters}
                setFilterToggle={setFilterToggle}
              />
            )}
            {!leadIdParam && (
              <>
                <GroupUpdateButton leads={selectionModel} />
                <GroupDeleteButton leads={selectionModel} />
              </>
            )}
            <AddLeadButtonWrapper />
          </Box>
        </Box>
      </Box>

      {isLeadsListError && !isAccountsListError && (
        <Alert severity="error">
          There was an error loading your leads. Try again.
        </Alert>
      )}
      {isAccountsListError && !isLeadsListError && (
        <Alert severity="error">
          There was an error loading the information for your accounts. Try again.
        </Alert>
      )}
      {isLeadShowError && (
        <Collapse in={isError}>
          <Alert onClose={() => setError(false)} severity="error">
            Lead not found
          </Alert>
        </Collapse>
      )}
      <Box display="flex" height="0%" width="100%" flexBasis="100%" gap={2}>
        {showFilters ? (
          <FilterBar
            setPage={setPage}
            ref={filterBarRef}
            setLeadsFilterMap={setLeadsFilterMap}
          />
        ) : (
          <></>
        )}
        <DataGridStyle
          disableSelectionOnClick
          checkboxSelection={!leadIdParam}
          keepNonExistentRowsSelected
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          componentsProps={{
            row: {
              onClick: (e) => {
                history.push(`/leads/${e.currentTarget.dataset.id}`);
              },
            },
          }}
          rows={leadsList?.items ?? []}
          columns={columns}
          loading={isLoading}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSort}
          disableColumnFilter
          disableColumnMenu
          density="compact"
          sx={{
            // custom style for selected cell
            '& .MuiDataGrid-cell': {
              outline: 'none',
              padding: 0,
              position: 'relative',
            },
          }}
          components={{
            Footer: () => (
              <CustomFooter
                rowCount={leadsList?.total_count ?? 0}
                page={page - 1}
                onPageChange={(page) => setPage(page + 1)}
                onPageSizeChange={setPageSize}
                pageSize={pageSize}
                organization_id={orgId}
                requested_url={`${
                  process.env.REACT_APP_API_BASE
                }/v2/leads?${toParamString(requestParams)}`}
                selectionModel={selectionModel}
                hideSelectedRows={!!leadIdParam}
                isCurrentUserAdmin={currentUser.roles.some(
                  (role) => role.role_name === 'Administrator'
                )}
              />
            ),
          }}
        />
        {leadIdParam && <LeadCardWrapper setLeadShowError={setLeadShowError} />}
      </Box>
    </Box>
  );
};

export default Leads;

import { useQuery } from 'react-query';
import { queryKeyFactory } from '../queryClientConfig';
import Cloud9Locations from './Cloud9Location';

export const cloud9QueryKeys = queryKeyFactory('cloud9Locations');

export const useCloud9Locations = (filters, options) => {
  return useQuery(
    cloud9QueryKeys.list(filters),
    () => Cloud9Locations.list(filters),
    {
      staleTime: 0,
      retry: 0,
      ...options,
    }
  );
};

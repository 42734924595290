import { Box, Typography, Divider as MuiDivider } from '@mui/material';
import { PaperStyle } from '../../theme/styled/PaperStyle';
import SettingsCardLoadingSpinner from './SettingsCardLoadingSpinner';

export const SettingsCard = ({ children, sx, showLoading, ...other }) => {
  return (
    <SettingsCardLoadingSpinner isLoading={showLoading}>
      <Box
        component={PaperStyle}
        sx={{
          '& .MuiAlert-standardError,.MuiAlert-standardSuccess': {
            marginBottom: '16px',
          },
          p: { xs: 0.6, sm: 1, md: 2 },
          ...sx,
        }}
        {...other}
      >
        <>{children}</>
      </Box>
    </SettingsCardLoadingSpinner>
  );
};

const Header = ({ children }) => <Typography variant="h6">{children}</Typography>;
const SubHeader = ({ children }) => (
  <Typography sx={{ width: 0, minWidth: '100%' }}>{children}</Typography>
);
// not sure exactly what to do with this yet since parent element might vary.
// Could possibly pass component prop.
const Main = ({ children, sx }) => <Box sx={{ pt: 2, ...sx }}>{children}</Box>;

// can style divider or create a set empty padding space
const Divider = () => <MuiDivider sx={{ my: 2 }} />;

// can update when footer required
const Footer = ({ children }) => <Box borderTop={1}>{children}</Box>;

SettingsCard.Header = Header;
SettingsCard.SubHeader = SubHeader;
SettingsCard.SubHeader = SubHeader;
SettingsCard.Main = Main;
SettingsCard.Divider = Divider;

import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import { Route, useHistory, useParams, useLocation } from 'react-router-dom';
import { setActiveOrg, getActiveOrg } from '../util/UserUtils';

const DynamicRouteLoader = ({ children }) => {
  const location = useLocation();
  if (location.pathname.includes('organizations')) {
    return (
      <Route
        exact
        path={'/organizations/:organizationId'}
        component={OrganizationRoute}
      />
    );
  }

  return <>{children}</>;
};

export default DynamicRouteLoader;

const OrganizationRoute = () => {
  const { organizationId } = useParams();
  const history = useHistory();
  const { search } = useLocation();

  setActiveOrg({ id: organizationId });

  const [leadIdParam, setLeadIdParam] = useState();
  const [taskId, setTaskId] = useState();

  const getLeadTasks = async (leadIdParam) => {
    await fetch(
      `${
        process.env.REACT_APP_API_BASE
      }/tasks?organization_id=${getActiveOrg()}&lead_id=${leadIdParam}&due_today=${true}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.json())
      .then((taskList) => {
        if (taskList.length === 0) {
          history.push(`/leads/${leadIdParam}`);
        }

        setTaskId(taskList[0]?.id);
      });
  };

  useEffect(() => {
    const dynamicRouteParams = getSearchParamsObj(decodeURIComponent(search));

    if (dynamicRouteParams.to?.startsWith('/tasks?lead_id=')) {
      const taskParams = dynamicRouteParams.to.split('?')[1];
      const leadId = getSearchParamsObj(decodeURIComponent(taskParams)).lead_id;

      setLeadIdParam(leadId);
    } else {
      let applyFilters = [];
      Object.entries(dynamicRouteParams).forEach(([key, value]) => {
        if (key !== 'to') applyFilters.push(`&${key}=${value}`);
      });

      history.push({
        pathname: dynamicRouteParams.to,
        state: { applyFilters },
      });
    }
  }, [search]);

  useEffect(() => {
    if (leadIdParam) {
      getLeadTasks(leadIdParam);
    }
  }, [leadIdParam]);

  useEffect(() => {
    if (taskId) {
      history.push(`/tasks/${taskId}`);
    }
  }, [taskId]);

  return <></>;
};

export const getSearchParamsObj = (search) => {
  let dynamicRouteParams = {};
  const query = new URLSearchParams(search);

  for (const [key, value] of query.entries()) {
    dynamicRouteParams[key] = value;
  }
  return dynamicRouteParams;
};

export const steps = [
  {
    name: 'networkStatus',
    complete: 10,
    headerTitle: 'Where do you want to use this tracking number?',
    details: [
      {
        title: 'I will use it online',
        subTitle: 'Web sites, search ads and more.',
        value: 'online',
        nextStep: 'locatedAt',
      },
      {
        title: 'I will use it offline',
        subTitle: 'Print ads, direct mail and more',
        value: 'offline',
        nextStep: 'numberCallFlow',
      },
    ],
  },
  {
    name: 'locatedAt',
    complete: 20,
    headerTitle: 'Where will you display this tracking number?',
    details: [
      {
        title: 'On my website',
        subTitle: 'We will provide the code to install call-tracking.',
        value: 'my-site',
        nextStep: 'trackType',
      },
      {
        title: 'Somewhere else',
        subTitle: 'Google Ads, email signatures and more',
        value: 'anywhere',
        nextStep: 'isGoogleAdExtension',
      },
    ],
  },
  {
    name: 'trackType',
    complete: 30,
    headerTitle: 'What would you like to track?',
    details: [
      {
        title: 'Calls, keywords & web sessions',
        subTitle:
          'Use a website pool to track details from campaigns and web sessions.',
        value: 'all',
        nextStep: 'callForwarding',
      },
      {
        title: 'Calls only',
        subTitle: 'Use one number to track all calls to a single campaign.',
        value: 'calls',
        nextStep: 'callForwarding',
      },
    ],
  },
  {
    name: 'isGoogleAdExtension',
    complete: 30,
    headerTitle: 'Will this number be used in a Google Ads extension?',
    details: [
      {
        title: 'Yes, in an Ads extension',
        subTitle: 'Call-only ads, call extension and message extensions.',
        value: 'yes',
        nextStep: 'whereGoogleAdShouldRun',
      },
      {
        title: 'No, somewhere else',
        subTitle: 'Facebook ads, email signatures and more.',
        value: 'no',
        nextStep: 'whereGoogleAdShouldRun',
      },
    ],
  },
  {
    name: 'whereGoogleAdShouldRun',
    complete: 35,
    headerTitle: 'Where will your Google Ads be displayed?',
    details: [
      {
        title: 'Only on mobile devices',
        subTitle:
          'You have call only campaign or a campaign targeting mobile devices.',
        value: 'mobile',
        nextStep: 'callForwarding',
      },
      {
        title: 'On desktop and mobile devices',
        subTitle: 'You have campaign that is targeting the multiple devices.',
        value: 'desktop&mobile',
        nextStep: 'callForwarding',
      },
    ],
  },
  {
    name: 'callForwarding',
    complete: 40,
  },
  {
    name: 'leadCreation',
    complete: 45,
  },
  {
    name: 'numberCallFlow',
    complete: 50,
  },
  {
    name: 'numberSetup',
    complete: 60,
  },
  {
    name: 'total',
    complete: 100,
  },
];

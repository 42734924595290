import { useQuery } from 'react-query';
import { queryKeyFactory } from '../queryClientConfig';
import NexHealthProviders from './NexHealthProviders';

export const nexHealthQueryKeys = queryKeyFactory('nexHealthProviders');

export const useNexHealthProviders = (filters, options) => {
  return useQuery(
    nexHealthQueryKeys.list(filters),
    () => NexHealthProviders.list(filters),
    {
      staleTime: 0,
      retry: 0,
      ...options,
    }
  );
};

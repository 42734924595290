import React, { useState, useContext, useEffect } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ClipLoader from 'react-spinners/ClipLoader';
import { useDropzone } from 'react-dropzone';
import { BsCloudUpload } from 'react-icons/bs';
import { FiCheckCircle } from 'react-icons/fi';
import axios from 'axios';
import Cookies from 'js-cookie';

const UploadContainer = () => {
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState();
  const [importFileId, setImportFileId] = useState();
  const [records, setRecords] = useState();
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('');
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    accept: '.csv',
  });

  useEffect(() => {
    if (acceptedFiles.length == 0) {
      return;
    }
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_BASE,
    });
    let formData = new FormData();
    formData.append('import_file[spreadsheet]', acceptedFiles[0]);
    setUploadStatus('Uploading...');
    axiosInstance
      .post('/import_files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        },
        onUploadProgress: (data) => {
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.status == 201) {
          setImportFileId(response.data.id);
          setRecords(response.data.records);
          setUploadStatus('Uploaded');
        } else {
          setProgress(0);
          setUploadStatus('Upload failed. Try again.');
        }
      })
      .catch((error) => {
        setProgress(0);
        setUploadStatus(error.toString());
      });
  }, [acceptedFiles]);

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className={`pt-0 sm:pt-5 px-0 sm:px-2`}>
          <nav aria-label="breadcrumb">
            <ol className="sigma-breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/settings">Settings</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/settings/import">Import</Link>
              </li>
              <li className="breadcrumb-item active">Upload</li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4 className="text-center">Upload</h4>
          <Row>
            <Col
              xs={{ offset: 2, span: 8 }}
              md={{ offset: 3, span: 6 }}
              className="text-center bg-sigma-light py-5 mb-5"
            >
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {uploadStatus == 'Uploaded' ? (
                  <FiCheckCircle size="100px" color="sigma-primary" />
                ) : (
                  <BsCloudUpload size="100px" />
                )}
                <br />
                Drag and drop CSV files to upload
                <br />
                <button
                  type="button"
                  onClick={open}
                  className="btn btn-sigma-primary mt-2"
                >
                  Browse
                </button>
              </div>
            </Col>
          </Row>
          {acceptedFiles[0] && (
            <Row>
              <Col xs={{ offset: 2, span: 4 }} md={{ offset: 3, span: 3 }}>
                {acceptedFiles[0].name}
              </Col>
              <Col xs="4" md="3" className="text-right">
                {uploadStatus}
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={{ offset: 2, span: 8 }} md={{ offset: 3, span: 6 }}>
              <ProgressBar now={progress} className="mt-2" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="mt-3">
          <Link
            to={{
              pathname: '/settings/import/preview',
              state: {
                importFileId: importFileId,
                taskSequenceId: state.taskSequenceId,
                records: records,
              },
            }}
            className={
              uploadStatus == 'Uploaded'
                ? 'btn btn-sigma-primary'
                : 'btn btn-sigma-primary disabled'
            }
          >
            Continue
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default UploadContainer;

import Cookies from 'js-cookie';
import { toJSON, toParamString } from '../queryClientConfig';

export default class SavedSearches {
  static async list(filters = []) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/saved_searches?${toParamString(filters)}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async create(savedSearch) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/saved_searches`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(savedSearch),
    }).then(toJSON);
  }

  static async edit(savedSearch) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/saved_searches/${savedSearch.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(savedSearch),
      }
    ).then(toJSON);
  }

  static async delete(saved_search_id) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/saved_searches/${saved_search_id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
  }
}

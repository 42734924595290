import Cookies from 'js-cookie';
import { toJSON } from '../queryClientConfig';

export default class Users {
  static async current() {
    return await fetch(`${process.env.REACT_APP_API_BASE}/users?is_current`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
      },
    }).then(toJSON);
  }

  static async list(filters) {
    const params = new URLSearchParams(filters).toString();
    return await fetch(`${process.env.REACT_APP_API_BASE}/users?${params}`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
      },
    }).then(toJSON);
  }

  static async show(user_id) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/users/${user_id}`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
      },
    }).then(toJSON);
  }

  static async edit(user) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/users/${user.id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    }).then(toJSON);
  }
  static async create(user) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/users`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    }).then(toJSON);
  }

  static async delete(id) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/users/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
}

import { useQuery, useQueryClient } from 'react-query';

import { useOrg } from '../../contexts/OrgProvider';
import { ONE_HOUR } from '../../MUI/services/queryClientConfig';
import ForwardingPhones from './ForwardingPhones';

export const useForwardingPhonesList = (teamId, options) => {
  const { id } = useOrg();
  return useQuery(
    ['forwarding_phones', teamId],
    () => ForwardingPhones.list({ team_id: teamId, organization_id: id }),
    {
      staleTime: ONE_HOUR,
      ...options,
    }
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { Modal, Row, Col, Button, Form } from 'react-bootstrap';
import { useOpportunitiesEdit } from '../../api/opportunities/opportunitiesMutations';
import { LOST } from '../tasks/TaskUtils';
import { getFormData } from '../../util/AppUtils';
import { useHistory } from 'react-router';
import { useLostReasons } from '../../api/lostReasons/lostReasonsQueries';
import { useUsersCurrent } from '../../MUI/services/users/queries';
import { useQueryClient } from 'react-query';
import { leadsQueryKeys } from '../../MUI/services/leads/queries';
import { tasksQueryKeys } from '../../MUI/services/tasks/queries';

const LeadLostModal = ({
  lead,
  organization,
  showLeadLostModal,
  setShowLeadLostModal,
  setLostReasonId = null,
  setLostReasonDetails = null,
  setLeadStatus = null,
}) => {
  const history = useHistory();
  const [isDetailsRequired, setIsDetailsRequired] = useState();
  const lostReasonsParams = [['organization_id[]', lead.organization_id]];

  if (organization.isMasterAccount && lead.organization_id !== organization.id) {
    lostReasonsParams.push(['organization_id[]', organization.id]);
  } else if (!organization.isMasterAccount) {
    lostReasonsParams.push(['find_globl_lost_reasons', true]);
  }

  const { data: lostReasons } = useLostReasons(lostReasonsParams);
  const { data: currentUser } = useUsersCurrent();
  const queryClient = useQueryClient();

  const { mutate: editOpportunity } = useOpportunitiesEdit({
    onSettled: () => {
      queryClient.invalidateQueries(leadsQueryKeys.all);
      queryClient.invalidateQueries(tasksQueryKeys.all);
      if (history.location.pathname.includes('leads'))
        history.push({
          state: 'refreshLeads',
        });
      else
        history.push({
          pathname: '/tasks',
          state: 'refreshLeads',
        });
    },
  });

  const handleSubmit = (e) => {
    const formData = getFormData(e);
    const { lostReasonId, lostReasonDetails } = { ...formData };

    let newOpportunityObj = {
      id: lead.opportunities[0].id,
      lead_id: lead.id,
      status: LOST,
      lost_reason_id: lostReasonId,
      user_id: currentUser.id,
    };
    if (lostReasonDetails) newOpportunityObj['lost_details'] = lostReasonDetails;
    // Check if modal being used in lead details or phone complete
    if (setLostReasonId) {
      setLostReasonId(lostReasonId);
      setLeadStatus('2');
      if (lostReasonDetails) setLostReasonDetails(lostReasonDetails);
    } else {
      editOpportunity(newOpportunityObj);
    }
    setShowLeadLostModal(false);
  };

  const isDuplicate = (name, organization_id) => {
    return lostReasons.some(
      (e) => e.name === name && e.organization_id !== organization_id
    );
  };

  return (
    <Modal
      className="modal-sigma"
      show={showLeadLostModal}
      onHide={() => {
        setShowLeadLostModal(false);
        setLeadStatus && setLeadStatus();
      }}
    >
      <Modal.Header>
        <Modal.Title>Lead Lost</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit} id="formId" name="formName">
        <Modal.Body>
          <Form.Label for="reason">Reason lead was lost</Form.Label>
          <Form.Control
            onChange={(e) =>
              setIsDetailsRequired(e.target.selectedOptions[0].id === 'Other')
            }
            as="select"
            type="select"
            id="lostReasonId"
            name="lostReasonId"
            required
          >
            <option value="" hidden>
              Select an option
            </option>
            {lostReasons?.every(
              (lostReason) =>
                lostReason.organization_id === lostReasons[0].organization_id
            ) ? (
              <>
                {lostReasons?.map(({ name, id }) => (
                  <option key={id} value={id} id={name}>
                    {name}
                  </option>
                ))}
              </>
            ) : (
              <>
                <optgroup
                  label={
                    lostReasons?.find(
                      (lostReason) =>
                        lostReason.organization_id !== lead.organization_id
                    ).organization_name
                  }
                >
                  {lostReasons
                    ?.filter(
                      ({ organization_id }) =>
                        organization_id !== lead.organization_id
                    )
                    ?.map(({ name, id }) => (
                      <option key={id} value={id} id={name}>
                        {name}
                      </option>
                    ))}
                </optgroup>
                <optgroup
                  label={
                    lostReasons?.find(
                      (lostReason) =>
                        lostReason.organization_id === lead.organization_id
                    ).organization_name
                  }
                >
                  {lostReasons
                    ?.filter(
                      ({ organization_id }) =>
                        organization_id === lead.organization_id
                    )
                    ?.map(({ organization_id, name, id }) => (
                      <option
                        key={id}
                        value={id}
                        id={name}
                        disabled={isDuplicate(name, organization_id)}
                      >
                        {name}
                      </option>
                    ))}
                </optgroup>
              </>
            )}
          </Form.Control>
          <Form.Label for="details" className="mt-2">
            Details
          </Form.Label>
          <Form.Control
            as="textarea"
            id="lostReasonDetails"
            name="lostReasonDetails"
            rows={4}
            maxLength={255}
            required={isDetailsRequired}
          />
        </Modal.Body>
        <Modal.Footer>
          <Row noGutters className="w-100">
            <Col xs={6} className="px-3">
              <Button
                className="btn btn-light btn-block"
                onClick={() => setShowLeadLostModal(false)}
              >
                Cancel
              </Button>
            </Col>
            <Col xs={6} className="px-3">
              <Button className="btn btn-sigma-primary btn-block" type="submit">
                Confirm
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default LeadLostModal;

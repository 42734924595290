export const getOpportunityValueCentsSum = (lead) => {
  return lead.opportunities?.reduce((acc, currentOpportunity) => {
    return acc + currentOpportunity.value_cents;
  }, 0);
};

export const getLeadName = (lead) => {
  return lead.first_name || lead.last_name
    ? `${lead.first_name ?? ''} ${lead.last_name ?? ''}`.trim()
    : 'Anonymous';
};

import {
  AccordionSummary,
  Accordion,
  Chip,
  Typography,
  Box,
  Select,
  MenuItem,
  AccordionActions,
  TextField,
  Autocomplete,
} from '@mui/material';
import { ChevronDown } from 'mdi-material-ui';
import { useState, useEffect } from 'react';
import {
  DATE_TIME_TYPE,
  FREESOLO_OPTION_TYPES,
  OPTION_TYPES,
  STRING_TYPES,
} from '../../../api/customFields/customFieldsUtils';
import { formatIsoStringToDateTimeLocal } from '../../services/dateTime';
import { useDebounce } from '../../services/hooks/useDebounce';

export const FilterAccordion = ({ children, defaultExpanded, onChange }) => (
  <Accordion
    disableGutters
    square
    defaultExpanded={defaultExpanded}
    onChange={onChange}
    sx={{
      borderRadius: '2px',
      border: `2px solid white`,
      '&:hover': {
        border: (theme) => `2px solid ${theme.palette.grey[100]}`,
      },
      boxShadow: 'none',
      '&::before': {
        display: 'none',
      },
      '&::after': {
        position: 'absolute',
        height: '1px',
        backgroundColor: (theme) => `${theme.palette.grey[100]}`,
        content: "''",
        width: '100%',
        bottom: 0,
      },
      '& .MuiAccordionSummary-root': {
        paddingLeft: 0.9,
        '&:hover': {
          backgroundColor: (theme) => theme.palette.grey[100],
        },
      },
      '&:focus-within': {
        border: (theme) => `2px solid ${theme.palette.primary.main}`,
      },
    }}
  >
    {children}
  </Accordion>
);
//-------------------------------------------------------------------
export const FilterAccordionSummary = ({ children }) => (
  <AccordionSummary
    expandIcon={<ChevronDown />}
    sx={{
      minHeight: '40px',
      fontSize: 14,
      '& .MuiAccordionSummary-content': {
        overflow: 'hidden',
        display: 'flex',
        height: '25px',
        margin: 0,
        justifyContent: 'space-between',
      },
    }}
  >
    {children}
  </AccordionSummary>
);
//-------------------------------------------------------------------
export const CustomFieldAccordion = ({
  customField,
  filterMap,
  setFilterMapValue,
  causeTextFieldValueChange,
  customFieldValueType,
  setSelectedSavedSearchId,
}) => {
  const [operator, setOperator] = useState('eq');
  const [stringTextField, setStringTextField] = useState('');
  const [datetimeSelection, setDatetimeSelection] = useState('');
  const SINGLE_VALUE_OPERATORS = ['blank', 'not_blank'];

  const debouncedStringText = useDebounce(stringTextField, 300);
  const currentCustomFieldFilterState =
    filterMap[customFieldValueType].customFieldsIdMap[customField.id];

  const handleSetFilterMapValue = (newFilterValue) => {
    setFilterMapValue(customFieldValueType, (prev) => {
      const prevCustomFieldsIdMap = { ...prev['customFieldsIdMap'] };
      let temp = { operator, filterValue: newFilterValue };
      return {
        customFieldsIdMap: { ...prevCustomFieldsIdMap, [customField.id]: temp },
      };
    });
  };

  useEffect(() => {
    if (customField.data_type === DATE_TIME_TYPE) {
      handleSetFilterMapValue(datetimeSelection);
    }
  }, [datetimeSelection]);

  useEffect(() => {
    if (operator == 'blank' || operator == 'not_blank') {
      handleSetFilterMapValue('_');
    } else if (datetimeSelection && customField.data_type === DATE_TIME_TYPE) {
      handleSetFilterMapValue(datetimeSelection);
    } else if (
      stringTextField?.length > 0 &&
      STRING_TYPES.includes(customField.data_type)
    ) {
      handleSetFilterMapValue(stringTextField);
    }
  }, [operator]);

  useEffect(() => {
    if (STRING_TYPES.includes(customField.data_type))
      setStringTextField(currentCustomFieldFilterState?.filterValue ?? '');
    if (customField.data_type === DATE_TIME_TYPE)
      setDatetimeSelection(currentCustomFieldFilterState?.filterValue ?? '');
    setOperator(currentCustomFieldFilterState?.operator ?? 'eq');
  }, [causeTextFieldValueChange]);

  useEffect(() => {
    if (!debouncedStringText || debouncedStringText.length == 0) {
      handleSetFilterMapValue('');
    } else if (STRING_TYPES.includes(customField.data_type)) {
      handleSetFilterMapValue(debouncedStringText);
    }
  }, [debouncedStringText]);

  return (
    <FilterAccordion>
      <FilterAccordionSummary>
        <Typography
          maxWidth="160px"
          fontSize="14px"
          display="inline-block"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {customField.name}
        </Typography>
        {currentCustomFieldFilterState?.filterValue?.length > 0 && (
          <Chip
            onDelete={() => {
              setSelectedSavedSearchId(null);
              handleSetFilterMapValue('');
              setOperator('eq');
              if (STRING_TYPES.includes(customField.data_type))
                setStringTextField('');
              else if (customField.data_type == DATE_TIME_TYPE)
                setDatetimeSelection('');
            }}
            label={'1'}
          />
        )}
      </FilterAccordionSummary>
      <AccordionActions>
        <Box display="flex" flexDirection="column" rowGap={1} width="100%">
          <Select
            sx={{ fontSize: 14, '& legend': { display: 'none' } }}
            fullWidth
            label="operator"
            labelId="lead-custom-field-op-label"
            size="small"
            value={operator}
            onChange={(event) => {
              setSelectedSavedSearchId(null);
              setOperator(event.target.value);
            }}
          >
            {[
              { operator: 'eq', label: 'Equals' },
              { operator: 'not_eq', label: 'Not equals' },
              { operator: 'not_blank', label: 'Present' },
              { operator: 'blank', label: 'Not present' },
              { operator: 'contains', label: 'Contains' },
              { operator: 'not_contains', label: 'Not contains' },
              { operator: 'gt', label: 'Greater than' },
              { operator: 'gte', label: 'Greater than or equals' },
              { operator: 'lt', label: 'Less than' },
              { operator: 'lte', label: 'Less than or equals' },
            ].map(({ operator, label }) => (
              <MenuItem key={operator} value={operator}>
                {label}
              </MenuItem>
            ))}
          </Select>
          {customField.data_type === 'string' && (
            <TextField
              InputProps={{ sx: { fontSize: '14px' } }}
              InputLabelProps={{ sx: { fontSize: '14px' } }}
              fullWidth
              label="Filter"
              value={stringTextField}
              disabled={SINGLE_VALUE_OPERATORS.includes(operator)}
              onChange={(e) => {
                setSelectedSavedSearchId(null);
                setStringTextField(e.target.value);
              }}
            />
          )}
          {customField.data_type === DATE_TIME_TYPE && (
            <TextField
              InputProps={{ sx: { fontSize: '14px' } }}
              sx={{ '& legend': { display: 'none' } }}
              fullWidth
              type="datetime-local"
              label=""
              InputLabelProps={{ shrink: true }}
              value={formatIsoStringToDateTimeLocal(datetimeSelection)}
              disabled={SINGLE_VALUE_OPERATORS.includes(operator)}
              onChange={(e) => {
                setSelectedSavedSearchId(null);
                if (e.target.value === '') setDatetimeSelection('');
                else setDatetimeSelection(new Date(e.target.value).toISOString());
              }}
            />
          )}
          {OPTION_TYPES.includes(customField.data_type) && (
            <Autocomplete
              size="small"
              forcePopupIcon={false}
              value={stringTextField ?? null}
              sx={{ width: '100%' }}
              onChange={(_, value) => {
                setSelectedSavedSearchId(null);
                setStringTextField(value);
              }}
              onBlur={(e) => {
                if (
                  FREESOLO_OPTION_TYPES.includes(customField.data_type) &&
                  e.target.value
                ) {
                  setSelectedSavedSearchId(null);
                  setStringTextField(e.target.value);
                }
              }}
              freeSolo={FREESOLO_OPTION_TYPES.includes(customField.data_type)}
              disabled={SINGLE_VALUE_OPERATORS.includes(operator)}
              options={customField.options_data ?? []}
              getOptionLabel={(o) => o || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={'Filter'}
                  placeholder=""
                  fullWidth
                  sx={{ fontSize: 14 }}
                />
              )}
              isOptionEqualToValue={(option, value) => {
                return option && value && option === value;
              }}
            />
          )}
        </Box>
      </AccordionActions>
    </FilterAccordion>
  );
};

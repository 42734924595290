import Cookies from 'js-cookie';

import { toJSON } from '../utils/utils';

export default class CustomFields {
  static async list(params = {}) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/custom_fields?${new URLSearchParams(
        params
      ).toString()}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async order(custom_fields) {
    await fetch(`${process.env.REACT_APP_API_BASE}/custom_fields_orders`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(custom_fields),
    }).then(toJSON);
  }

  static async create(custom_field) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/custom_fields`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(custom_field),
    }).then(toJSON);
  }

  static async edit(custom_field) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/custom_fields/${custom_field.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(custom_field),
      }
    ).then(toJSON);
  }

  static async delete(custom_field_id) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/custom_fields/${custom_field_id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
  }
}

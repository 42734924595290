import Cookies from 'js-cookie';
import { getActiveOrg } from '../util/UserUtils';

export default class TextMessage {
  static async create(leadId, teamId, message, userId, organizationId) {
    const response = await fetch(
      `${process.env.REACT_APP_TWILIO_ADAPTER}/text-lead`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
        },
        body: JSON.stringify({
          organization_id: organizationId,
          lead_id: leadId,
          team_id: teamId,
          user_id: userId,
          message: message,
          sender_type: 'user',
        }),
      }
    );
    return response.json();
  }

  static async list(filters) {
    filters['organization_id'] = getActiveOrg();
    const params = new URLSearchParams(filters).toString();
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/texts?${params}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    );
    return response.json();
  }
}

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ListView from '../components/ListView';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { useOrg } from '../../contexts/OrgProvider';
import { useMessageTemplatesList } from '../services/messageTemplates/queries';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { ContentState, convertFromHTML } from 'draft-js';
import TemplateDeleteModal from '../../components/templates/TemplateDeleteModal';
import { PlusIcon } from '../theme/icons';
import { useSharedTemplatesCreate } from '../../MUI/services/sharedTemplates/mutations';
import { fireDialog } from '../../MUI/components/Dialog';
import { useOrganizationsList } from '../services/organizations/queries';
import { DataGridStyle } from '../theme/styled/DataGridStyle';
import { useMessageTemplatesDelete } from '../services/messageTemplates/mutations';

const ShareTemplateDialog = ({ isOpen, onReject, templateId }) => {
  const { enterprise_id } = useOrg();
  const { data: organizations, isFetching } = useOrganizationsList({
    enterprise_id: enterprise_id,
  });
  const {
    mutate: createShareTemplate,
    isLoading: isLoadingCreate,
  } = useSharedTemplatesCreate();
  const [pageSize, setPageSize] = useState(25);
  const [selected, setSelected] = useState([]);
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
  ];

  const shareTemplate = () => {
    const orgIds = selected.map((id) => {
      return { organization_id: id };
    });
    createShareTemplate({ message_template_id: templateId, organizations: orgIds });
    onReject();
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Share</DialogTitle>
      <DialogContent>
        <DataGridStyle
          checkboxSelection
          loading={isFetching}
          rows={organizations ?? []}
          columns={columns}
          sx={{ minHeight: '400px' }}
          rowsPerPageOptions={[10, 25, 50]}
          onPageSizeChange={setPageSize}
          pageSize={pageSize}
          density="compact"
          disableSelectionOnClick
          onSelectionModelChange={setSelected}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="cancel" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton loading={isLoadingCreate} onClick={shareTemplate}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default function TemplatesPage() {
  const [messageType, setMessageType] = useState('Email');
  const filterTextList = (data) => {
    return data
      .filter((t) => t.task_action.name == messageType)
      .sort((a, b) => (a.name > b.name ? 1 : -1));
  };
  const history = useHistory();
  const { id: orgId } = useOrg();
  const { data: templates, isFetching } = useMessageTemplatesList(
    [['organization_id[]', orgId]],
    {
      select: filterTextList,
    }
  );

  const {
    mutate: deleteMessageTemplate,
    isSuccess: didSucceedMutatingMessageTemplate,
    isError: didFailMutatingMessageTemplate,
    isLoading: isMutatingMessageTemplate,
  } = useMessageTemplatesDelete();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [templateItem, setTemplateItem] = useState(null);
  const hideModal = () => setShowDeleteModal(false);

  const confirmDelete = (template_id, newMessageTemplateId) => {
    deleteMessageTemplate({
      id: template_id,
      newMessageTemplateId: newMessageTemplateId,
    });
    hideModal();
  };

  const handleChange = (_, newValue) => {
    setMessageType(newValue);
  };

  const stripHtml = (content) => {
    return ContentState.createFromBlockArray(
      convertFromHTML(content)
    ).getPlainText();
  };

  const shareTemplate = (templateId) =>
    fireDialog((promiseParams) =>
      ShareTemplateDialog({ ...promiseParams, templateId })
    );

  return (
    <SettingsCard showLoading={isFetching}>
      {showDeleteModal && (
        <TemplateDeleteModal
          confirmDelete={confirmDelete}
          templateItem={templateItem}
          showDeleteModal={showDeleteModal}
          hideModal={hideModal}
          messageTemplates={templates}
        />
      )}
      <SettingsCard.Header>Templates</SettingsCard.Header>
      <SettingsCard.SubHeader>
        Email, text and call templates that can be used in sequences.
      </SettingsCard.SubHeader>
      <SettingsCard.Main>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={messageType}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Email" value="Email" />
                <Tab label="Text" value="Text" />
                <Tab label="Call" value="Call" />
              </TabList>
            </Box>
            <Box sx={{ p: 1 }}>
              <ListView>
                {templates?.map((template) => (
                  <Box
                    sx={{ p: 1, borderBottom: 1, borderColor: 'divider' }}
                    key={template.id}
                  >
                    <ListView.Item>
                      <ListView.Text colWidth={600}>
                        <Typography component={'span'} variant="subtitle2">
                          {template.name}
                        </Typography>
                        <br />
                        {stripHtml(template.content)}
                      </ListView.Text>

                      <ListView.ShareButton
                        onClick={() => {
                          setTemplateItem(template);
                          shareTemplate(template.id);
                        }}
                      />
                      <ListView.EditButton
                        onClick={() =>
                          history.push(`/settings/templates/edit/${template.id}`)
                        }
                      />
                      <ListView.DeleteButton
                        onClick={() => {
                          setTemplateItem(template);
                          setShowDeleteModal(true);
                        }}
                      />
                    </ListView.Item>
                  </Box>
                ))}
              </ListView>
            </Box>
            <Button
              startIcon={<PlusIcon />}
              onClick={() =>
                history.push('/settings/templates/create', {
                  type: messageType,
                })
              }
            >
              Add New Template
            </Button>
          </TabContext>
        </Box>
      </SettingsCard.Main>
    </SettingsCard>
  );
}

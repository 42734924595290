import { Tabs, Tab, Box } from '@mui/material';
import { useState } from 'react';
import TaskEventHistoryTab from './TaskHistoryTab';
import { useTasksList } from '../../services/tasks/queries';
import { TabPanel } from '../TabPanel';
import LeadEventHistoryTab from './LeadEventHistoryTab';

const LeadHistoryTabs = ({ openActionModal, lead }) => {
  const [communicationsCount, setCommunicationsCount] = useState();
  const [tab, setTab] = useState(0);

  const { data: tasksList } = useTasksList([
    ['lead_id', lead.id],
    ['page_size', 1000],
    ['canceled', false],
  ]);
  return (
    <Box width="100%" height="100%" pl={0.5} display="flex" flexDirection="column">
      <Tabs
        sx={{
          '& .MuiTab-root': { flex: 1, fontSize: '16px' },
        }}
        value={tab}
        onChange={(_, newValue) => setTab(newValue)}
      >
        <Tab label={`Events (${communicationsCount ?? '...'})`} />
        <Tab label={`Tasks (${tasksList?.total_count ?? '...'})`} />
      </Tabs>

      <Box sx={{ pt: 1 }} height="0" flex={1}>
        <TabPanel sx={{ height: '100%' }} value={tab} index={0}>
          <LeadEventHistoryTab
            setCommunicationsCount={setCommunicationsCount}
            openActionModal={openActionModal}
            lead={lead}
          />
        </TabPanel>
        <TabPanel sx={{ height: '100%' }} value={tab} index={1}>
          <TaskEventHistoryTab lead={lead} />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default LeadHistoryTabs;

import { useEffect } from 'react';

export const Play = ({
  handleMutateComponentListByParametersInUI,
  id,
  parameters,
}) => {
  useEffect(() => {
    // defineParams = object with properties what will change and write to ComponentList
    const defineParams = {};
    const neededParams =
      parameters && Object.keys(parameters).length > 0 ? parameters : defineParams;
    handleMutateComponentListByParametersInUI(neededParams, id);
  }, []);
  return <h1>Play</h1>;
};

import { useLostReasons } from '../services/lostReasons/queries';
import {
  useLostReasonsCreate,
  useLostReasonsEdit,
  useLostReasonsDelete,
} from '../services/lostReasons/mutations';
import { useOrg } from '../../contexts/OrgProvider';
import { useUsersCurrent } from '../../MUI/services/users/queries';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { fireConfirmDialog, fireDialog } from '../components/Dialog';
import { LoadingButton } from '@mui/lab';
import HookForm from '../components/HookForm';
import { useConditionalUseFormProps } from '../components/HookForm/hooks';
import { DeleteIcon, EditIcon, PlusIcon } from '../theme/icons';
import { createYupResolver, NAME } from '../components/HookForm/yupValidations';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Alert,
  MenuItem,
  Tooltip,
  Zoom,
  Link,
  Typography,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';
import ClipLoader from 'react-spinners/ClipLoader';
import { useState } from 'react';
import { dateToPrettyDateTimeString } from '../services/dateTime';

const LostReasons = () => {
  const { data: lostReasons, isFetching, isError } = useLostReasons();
  const { data: user } = useUsersCurrent();
  const { mutate: deleteLostReason } = useLostReasonsDelete();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [lostReasonToEdit, setLostReasonToEdit] = useState();

  const handleDeleteLostReason = (l) => {
    fireConfirmDialog({
      title: 'Delete Lost Reason',
      message: `You are about to delete the lost reason "${l.name}". Are you sure?`,
    }).then(() => deleteLostReason(l.id));
  };

  const handleEditLostReasonClick = (lostReason) => {
    setLostReasonToEdit(lostReason);
    setIsEditOpen(true);
  };

  const onAddClick = () => {
    fireDialog((promiseProps) =>
      AddLostReasonDialog({
        ...promiseProps,
      })
    );
  };

  return (
    <Box sx={{ height: '100%' }}>
      <EditLostReasonDialog
        isOpen={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        lostReason={lostReasonToEdit}
      />
      <SettingsCard
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SettingsCard.Header>Lost Reasons</SettingsCard.Header>
        <SettingsCard.SubHeader>
          Add or edit lost reasons to be shown to the user when marking a lead lost.
        </SettingsCard.SubHeader>
        <SettingsCard.Main sx={{ flex: 1 }}>
          {isError && (
            <Alert severity="error">
              There was a problem loading your lost reasons. Try again.
            </Alert>
          )}
          <Box height="100%" display="flex" flexDirection="column" gap={2}>
            <TableContainer sx={{ mt: 1 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Updated</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lostReasons.map((l) => (
                    <TableRow
                      key={l.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {l.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {dateToPrettyDateTimeString(l.created_at)}
                      </TableCell>
                      <TableCell>
                        {dateToPrettyDateTimeString(l.updated_at)}
                      </TableCell>
                      <TableCell>
                        <Button
                          startIcon={<EditIcon />}
                          size="small"
                          variant="text"
                          color="primary"
                          onClick={() => handleEditLostReasonClick(l)}
                          sx={{ marginRight: 1 }}
                        >
                          Edit
                        </Button>
                        <Button
                          startIcon={<DeleteIcon />}
                          size="small"
                          variant="text"
                          color="error"
                          onClick={() => handleDeleteLostReason(l)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              sx={{ alignSelf: 'flex-start' }}
              startIcon={<PlusIcon />}
              onClick={onAddClick}
            >
              Add New
            </Button>
          </Box>
        </SettingsCard.Main>
      </SettingsCard>
    </Box>
  );
};

const AddLostReasonDialog = ({ isOpen, onResolve, onReject }) => {
  const { mutate: createLostReason, isError, isLoading } = useLostReasonsCreate();
  const { id } = useOrg();

  const handleCreateSubmit = (formData) => {
    createLostReason(
      {
        [NAME]: formData[NAME],
        organization_id: id,
      },
      {
        onSuccess: onResolve,
      }
    );
  };

  const defaultValues = {
    [NAME]: '',
  };

  const useFormProps = {
    defaultValues,
    resolver: createYupResolver(defaultValues),
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Create Lost Reason</DialogTitle>
      <HookForm useFormProps={useFormProps} onSubmit={handleCreateSubmit}>
        <DialogContent>
          {isError && (
            <Alert severity="error">
              There was a problem creating the lost reason. Try again.
            </Alert>
          )}
          <Box display="flex" flexDirection="column" rowGap={2}>
            <HookForm.TextField name={NAME} label="Name" />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton type="submit" loading={isLoading} disabled={isLoading}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </HookForm>
    </Dialog>
  );
};

const EditLostReasonDialog = ({ isOpen, onClose, lostReason }) => {
  const { mutate: editLostReason, isError, isLoading } = useLostReasonsEdit();

  const handleEditSubmit = (formData) => {
    editLostReason(
      {
        ...lostReason,
        [NAME]: formData[NAME],
      },
      {
        onSuccess: onClose,
      }
    );
  };

  const defaultValues = {
    [NAME]: lostReason?.name,
  };

  const useFormProps = {
    defaultValues,
    resolver: createYupResolver(defaultValues),
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Edit Lost Reason</DialogTitle>
      <HookForm useFormProps={useFormProps} onSubmit={handleEditSubmit}>
        <DialogContent>
          {isError && (
            <Alert severity="error">
              There was a problem editing the lost reason. Try again.
            </Alert>
          )}
          <Box display="flex" flexDirection="column" rowGap={2}>
            <Box>
              <HookForm.TextField name={NAME} label="Name" />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton type="submit" loading={isLoading} disabled={isLoading}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </HookForm>
    </Dialog>
  );
};

export default LostReasons;

export const eventTypeNameMap = {
  csv_created: 'Csv Export created',
  call_inbound: 'Call inbound',
  call_outbound: 'Call outbound',
  call_inbound_answered: 'Call inbound answered',
  call_inbound_not_answered: 'Call inbound not answered',
  text_inbound: 'Text inbound',
  text_outbound: 'Text outbound',
  email_inbound: 'Email inbound',
  email_outbound: 'Email outbound',
  email_unsubscribed: 'Email unsubscribed',
  opportunity_status_updated: 'Opportunity status updated',
  lead_created: 'Lead created',
  lead_deleted: 'Lead deleted',
  lead_duplicated: 'Duplicate lead',
  lead_note_created: 'Lead note created',
  call_slingshot_initiated: 'Active Call Notification initiated',
  call_slingshot_accepted: 'Active Call Notification accepted',
  call_slingshot_declined: 'Active Call Notification declined',
  call_slingshot_unanswered: 'Active Call Notification unanswered',
  integration_installed: 'Integration installed',
  sequence_created: 'Sequence created',
  sequence_updated: 'Sequence updated',
  sequence_deleted: 'Sequence deleted',
  sequence_shared: 'Sequence shared',
  template_created: 'Template created',
  template_updated: 'Template updated',
  template_deleted: 'Template deleted',
  task_cancelled: 'Task canceled',
  //TODO description text
  task_closed_no_communication: 'Follow up canceled without communication',
  user_created: 'User created',
  user_deleted: 'User deleted',
  user_login: 'User login',
  appointment_created: 'Appointment created',
  lead_updated: 'Lead updated',
  facebook_conversion: 'Facebook conversion',
  google_conversion: 'Google conversion',
};

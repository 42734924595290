import { useQuery } from 'react-query';
import { queryKeyFactory } from '../../MUI/services/queryClientConfig';
import CallFlowAPI from './CallFlow';

export const callFlowQueryKeys = queryKeyFactory('call_flow');

export const useGetCallFlowList = (options) => {
  return useQuery(
    callFlowQueryKeys.list(options),
    () => CallFlowAPI.getAll(options),
    {
      staleTime: 0,
    }
  );
};

export const useGetCallFlowById = (id, options) => {
  return useQuery(callFlowQueryKeys.list(id), () => CallFlowAPI.getById(id), {
    staleTime: 0,
    ...options,
  });
};

import {
  FormControl,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLostReasons } from '../../api/lostReasons/lostReasonsQueries';
import { useOrg } from '../../contexts/OrgProvider';
import CallNotes from './CallNotes';

export default function LeadStatus({ lead, handleCloseModal }) {
  const lostReasonsParams = [['organization_id[]', lead.organization_id]];
  const [leadStatus, setLeadStatus] = useState('0');
  const [lostReasonId, setLostReasonId] = useState(null);
  const [lostDetails, setLostDetails] = useState('');
  const [opportunityId, setopportunityId] = useState('');

  const { id: orgId, isMasterAccount } = useOrg();

  if (isMasterAccount && lead.organization_id !== orgId) {
    lostReasonsParams.push(['organization_id[]', orgId]);
  } else if (!isMasterAccount) {
    lostReasonsParams.push(['find_globl_lost_reasons', true]);
  }

  const { data: lostReasons } = useLostReasons(lostReasonsParams);

  const [opportunitiesStatus, setopportunitiesStatus] = useState('');

  const saveLeadStatus = (e) => {
    const opportunity = mostRecentOpportunity();
    setLeadStatus(parseInt(e.target.value));
    if (e.target.value != '2') {
      setLostReasonId(null);
      setLostDetails('');
    }
    setopportunityId(opportunity.id);
  };

  const saveLostDetails = (e) => {
    const opportunity = mostRecentOpportunity();
    setopportunityId(opportunity.id);
  };

  const saveLostReason = (e) => {
    const opportunity = mostRecentOpportunity();
    setLostReasonId(e.target.value);
    setopportunityId(opportunity.id);
  };

  const mostRecentOpportunity = () => {
    if (lead.opportunities.length == 0) {
      return;
    }
    return lead.opportunities.sort((a, b) =>
      a.created_at > b.created_at ? 1 : -1
    )[0];
  };

  const isDuplicate = (name, organization_id) => {
    return lostReasons.some(
      (e) => e.name === name && e.organization_id !== organization_id
    );
  };

  useEffect(() => {
    const opportunity = mostRecentOpportunity();
    if (!opportunity) {
      return;
    }
    switch (opportunity.status) {
      case 'pending':
        setLeadStatus('0');
        break;
      case 'won':
        setLeadStatus('1');
        break;
      case 'lost':
        setLeadStatus('2');
        break;
      default:
        setLeadStatus('0');
    }
    setLostReasonId(opportunity.lost_reason_id);
    setLostDetails(opportunity.lost_details);
    setopportunitiesStatus(opportunity.status);
  }, []);
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="template-label" sx={{ marginLeft: '-14px' }}>
            Lead Status
          </InputLabel>
          {opportunitiesStatus === 'lost' || opportunitiesStatus === 'won' ? (
            <Select
              variant={'standard'}
              value={leadStatus}
              onChange={saveLeadStatus}
              disabled
            >
              <MenuItem key="0" value="0">
                Pending
              </MenuItem>
              <MenuItem key="1" value="1">
                Won
              </MenuItem>
              <MenuItem key="2" value="2">
                Lost
              </MenuItem>
            </Select>
          ) : (
            <Select
              variant={'standard'}
              value={leadStatus}
              onChange={saveLeadStatus}
            >
              <MenuItem key="0" value="0">
                Pending
              </MenuItem>
              <MenuItem key="1" value="1">
                Won
              </MenuItem>
              <MenuItem key="2" value="2">
                Lost
              </MenuItem>
            </Select>
          )}
        </FormControl>
      </Grid>

      {leadStatus == '2' && (
        <>
          <Grid item xs={12} sx={{ marginTop: '12px' }}>
            <FormControl fullWidth>
              <InputLabel id="template-label" sx={{ marginLeft: '-14px' }}>
                Lost Reason
              </InputLabel>
              <Select
                variant={'standard'}
                value={lostReasonId}
                onChange={saveLostReason}
              >
                {lostReasons?.every(
                  (lostReason) =>
                    lostReason.organization_id === lostReasons[0].organization_id
                )
                  ? lostReasons?.map(({ name, id }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))
                  : [
                      <ListSubheader key="group-one">
                        {
                          lostReasons?.find(
                            (lostReason) =>
                              lostReason.organization_id !== lead.organization_id
                          ).organization_name
                        }
                      </ListSubheader>,
                      lostReasons
                        ?.filter(
                          ({ organization_id }) =>
                            organization_id !== lead.organization_id
                        )
                        ?.map(({ name, id }) => (
                          <MenuItem key={id} value={id} id={name}>
                            {name}
                          </MenuItem>
                        )),
                      <ListSubheader key="group-two">
                        {
                          lostReasons?.find(
                            (lostReason) =>
                              lostReason.organization_id === lead.organization_id
                          ).organization_name
                        }
                      </ListSubheader>,
                      lostReasons
                        ?.filter(
                          ({ organization_id }) =>
                            organization_id === lead.organization_id
                        )
                        ?.map(({ organization_id, name, id }) => (
                          <MenuItem
                            key={id}
                            value={id}
                            id={name}
                            disabled={isDuplicate(name, organization_id)}
                          >
                            {name}
                          </MenuItem>
                        )),
                    ]}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '12px' }}>
            <TextField
              multiline
              fullWidth
              variant={'filled'}
              label="Lost Reason Details"
              value={lostDetails}
              onChange={(e) => setLostDetails(e.target.value)}
              onBlur={saveLostDetails}
              rows={5}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} sx={{ marginTop: '12px' }}>
        <CallNotes
          lead={lead}
          handleCloseModal={handleCloseModal}
          opportunityId={opportunityId}
          lostReasonId={lostReasonId}
          leadStatus={leadStatus}
          lostDetails={lostDetails}
          lostReasons={lostReasons}
        />
      </Grid>
    </Grid>
  );
}

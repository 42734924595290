export const minimizeCall = () => ({
  type: 'MINIMIZE_CALL',
});

export const updateDeviceStatus = (status) => ({
  type: 'UPDATE_DEVICE_STATUS',
  payload: status,
});

export const resetMinimizeCall = () => ({
  type: 'RESET_MINIMIZE_CALL',
});

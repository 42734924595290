import { toJSON, toParamString } from '../queryClientConfig';
import Cookies from 'js-cookie';

export default class Services {
  static async list(filters) {
    return await fetch(
      `${process.env.REACT_APP_MESSAGING_API_BASE}/services?${toParamString(
        filters
      )}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }
}

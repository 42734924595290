import { useQuery } from 'react-query';
import { useOrg } from '../../../contexts/OrgProvider';
import { queryKeyFactory } from '../queryClientConfig';
import Agents from './Agents';

export const agentsQueryKeys = {
  ...queryKeyFactory('agents'),
};

export const useAgentsList = (filters) => {
  const { id } = useOrg();
  const filterKey = { organization_id: id, ...filters };
  return useQuery(agentsQueryKeys.list(filterKey), () => Agents.list(filterKey), {
    staleTime: 1000 * 60 * 5,
  });
};

export const useAgentsShow = (agentId) => {
  return useQuery(agentsQueryKeys.show({ agentId }), () => Agents.show(agentId), {
    staleTime: 1000 * 60 * 5,
  });
};

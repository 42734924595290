import { useMutation, useQueryClient } from 'react-query';
import Slots from './Slots';
import { slotesQueryKeys } from './queries';

export const useSlotesCreate = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => Slots.create({ ...params }), {
    onSuccess: () => {
      queryClient.invalidateQueries(slotesQueryKeys.all);
    },
  });
};
export const useSlotesEdit = (filter) => {
  const queryClient = useQueryClient();
  return useMutation((params) => Slots.edit({ ...params }), {
    onSuccess: () => {
      queryClient.invalidateQueries(slotesQueryKeys.all);
    },
  });
};

export const useSlotsDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(Slots.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(slotesQueryKeys.all);
    },
  });
};

import HookForm from '../components/HookForm';
import { fireConfirmDialog, fireDialog } from '../components/Dialog';
import { useEffect, useState } from 'react';
import { PlusIcon } from '../theme/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ListView from '../components/ListView';
import {
  useRulesCreate,
  useRulesEdit,
  useRulesDelete,
} from '../../api/rules/mutations';
import { useTeamsList } from '../../MUI/services/teams/queries';
import { useSourcesList } from '../../MUI/services/sources/queries';
import { useTaskSequencesList } from '../../MUI/services/taskSequences/queries';
import { useSlingshotFields } from '../../api/slingshotsFields/queries';
import { useRules } from '../../api/rules/queries';
import { mergeSlinghotAndCustomFields } from '../services/leadFields/resourceUtils';
import {
  actionsNameMap,
  getPrettyRule,
  operatorsNameMap,
} from '../../api/rules/rulesUtils';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { useCustomFields } from '../../api/customFields/queries';
import { useOrg } from '../../contexts/OrgProvider';
import { useUsersCurrent } from '../../MUI/services/users/queries';
import { DiceD10Outline } from 'mdi-material-ui';

const RULE_VALUE = 'ruleValue';
const RULE_FIELD = 'ruleField';
const RULE_ACTION = 'ruleAction';
const RULE_OPERATOR = 'ruleOperator';
const RULE_ACTION_DATA = 'ruleActionData';

const LeadQualificationSettingsCard = () => {
  const { id: orgId } = useOrg();
  const { data: user } = useUsersCurrent();
  const [accounts, setAccounts] = useState([]);
  useEffect(() => {
    setAccounts(user.authorizations.table.organizations);
  }, [user]);

  /* ANCHOR LeadQualifcationSettingsCard */
  const {
    data: rules,
    isFetching: isLoadingRules,
    isError: didFailLoadingRules,
  } = useRules();
  const {
    data: teams,
    isFetching: isLoadingTeams,
    isError: didFailLoadingTeams,
  } = useTeamsList();
  const {
    data: sources,
    isFetching: isLoadingSources,
    isError: didFailLoadingSources,
  } = useSourcesList();
  const {
    data: taskSequences,
    isFetching: isLoadingTaskSequences,
    isError: didFailLoadingTaskSequences,
  } = useTaskSequencesList();
  const {
    data: slingshotFields,
    isFetching: isLoadingSlingshotFields,
    isError: didFailLoadingSlingshotFields,
  } = useSlingshotFields();
  const {
    data: customFields,
    isFetching: isLoadingCustomFields,
    isError: didFailLoadingCustomFields,
  } = useCustomFields([['organization_id', orgId]]);
  const [leadFields, setLeadFields] = useState([]);

  useEffect(() => {
    setLeadFields(mergeSlinghotAndCustomFields(slingshotFields, customFields));
  }, [slingshotFields, customFields]);

  /* ANCHOR Edit Lead Qualification */

  const handleEditRule = (rule) => {
    handleEditRuleClick(rule);
  };

  const handleEditRuleClick = (rule) => {
    fireDialog((promiseParams) =>
      EditRuleDialog({
        ...promiseParams,
        rule,
        leadFields,
        orgId,
      })
    );
  };

  /* ANCHOR Delete Lead Qualification */

  const handleDeleteRule = (rule) => {
    fireDialog((promiseParams) => DeleteRuleDialog({ ...promiseParams, rule }));
  };

  /* ANCHOR Create Lead Qualification */

  const handleCreateRule = () => {
    fireDialog((promiseParams) =>
      CreateRuleDialog({
        ...promiseParams,
        leadFields,
        orgId,
      })
    );
  };

  /* ANCHOR Render Lead Qualification */

  return (
    <SettingsCard
      showLoading={
        isLoadingRules ||
        isLoadingCustomFields ||
        isLoadingSlingshotFields ||
        isLoadingTeams ||
        isLoadingSources ||
        isLoadingTaskSequences
      }
    >
      <SettingsCard.Header>Lead Qualifications</SettingsCard.Header>
      <SettingsCard.SubHeader>
        Use lead data to stop Active Call Notifications, direct leads to teams or
        accounts, or ignore them altogether.
      </SettingsCard.SubHeader>
      <SettingsCard.Main>
        {didFailLoadingCustomFields ||
        didFailLoadingSlingshotFields ||
        didFailLoadingRules ||
        didFailLoadingTeams ||
        didFailLoadingSources ||
        didFailLoadingTaskSequences ? (
          <Alert severity="error">
            There was a problem loading lead qualifications
          </Alert>
        ) : (
          <>
            {rules?.length == 0 ? (
              <Alert severity="info" sx={{ mt: 1, mb: 2 }}>
                No lead qualifications created. Add your first one below.
              </Alert>
            ) : (
              <ListView>
                <ListView.Item>
                  <ListView.HeaderText colWidth={'100%'}>
                    Qualification
                  </ListView.HeaderText>
                </ListView.Item>
                {slingshotFields &&
                  rules &&
                  leadFields &&
                  teams &&
                  rules
                    .sort((first, second) => {
                      return first.action < second.action;
                    })
                    .map((rule) => {
                      return (
                        <ListView.Item key={rule.id}>
                          <ListView.Text colWidth={600}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getPrettyRule(
                                  rule,
                                  teams,
                                  leadFields,
                                  accounts,
                                  sources,
                                  taskSequences
                                ),
                              }}
                            ></span>
                          </ListView.Text>
                          <ListView.EditButton
                            onClick={() => handleEditRule(rule)}
                          />
                          <ListView.DeleteButton
                            onClick={() => handleDeleteRule(rule)}
                          />
                        </ListView.Item>
                      );
                    })}
              </ListView>
            )}
            <Button
              startIcon={<PlusIcon />}
              onClick={handleCreateRule}
              sx={{ marginTop: 2 }}
            >
              Add New
            </Button>
          </>
        )}
      </SettingsCard.Main>
    </SettingsCard>
  );
};

/* ANCHOR Dialogs */

const EditRuleDialog = ({
  isOpen,
  onResolve,
  onReject,
  rule,
  leadFields,
  orgId,
}) => {
  const { data: user } = useUsersCurrent();
  const [accounts, setAccounts] = useState([]);
  useEffect(() => {
    setAccounts(user.authorizations.table.organizations);
  }, [user]);
  const {
    mutate: editRule,
    isLoading: isMutatingRule,
    isSuccess: didSucceedMutatingRule,
    isError: didFailMutatingRule,
  } = useRulesEdit();
  const { data: teams } = useTeamsList([['organization_id[]', orgId]]);
  const { data: sources } = useSourcesList([['organization_id[]', orgId]]);
  const { data: taskSequences } = useTaskSequencesList([
    ['organization_id[]', orgId],
  ]);

  const [localAction, setLocalAction] = useState(rule.action);
  const [blankTeamError, setBlankTeamError] = useState(false);

  useEffect(() => {
    if (didSucceedMutatingRule && !didFailMutatingRule) onResolve();
  }, [didSucceedMutatingRule, didFailMutatingRule]);

  const onEditRuleFieldSubmit = (formData) => {
    if (
      (localAction == 'set_team' ||
        localAction == 'set_source' ||
        localAction == 'set_task_sequence') &&
      !formData[RULE_ACTION_DATA]
    )
      return setBlankTeamError(true);

    setBlankTeamError(false);
    editRule({
      ...rule,
      value: formData[RULE_VALUE],
      action: localAction,
      operator: formData[RULE_OPERATOR],
      slingshot_field_id: formData[RULE_FIELD],
      action_data:
        (localAction == 'set_team' ||
          localAction == 'set_account' ||
          localAction == 'set_source' ||
          localAction == 'set_task_sequence') &&
        formData[RULE_ACTION_DATA]
          ? JSON.parse(formData[RULE_ACTION_DATA])
          : {},
    });
  };

  const defaultValues = {
    [RULE_VALUE]: rule.value ?? '',
    [RULE_FIELD]: rule.slingshot_field?.id,
    [RULE_ACTION]: rule.action,
    [RULE_OPERATOR]: rule.operator,
    [RULE_ACTION_DATA]:
      rule.action_data && rule.action_data.team_id
        ? `{"team_id": ${rule.action_data.team_id}}`
        : '',
  };

  const useFormProps = {
    defaultValues,
    resolver: yupResolver(
      yup.object({
        [RULE_VALUE]: yup.string(),
        [RULE_FIELD]: yup.mixed().oneOf(
          leadFields.map((item) => {
            return item.slingshot_field_id;
          })
        ),
        [RULE_ACTION]: yup.mixed().oneOf(Object.keys(actionsNameMap)),
        [RULE_OPERATOR]: yup.mixed().oneOf(Object.keys(operatorsNameMap)),
        [RULE_ACTION_DATA]: yup.string(),
      })
    ),
  };

  useEffect(() => {
    setLocalAction(rule.action);
  }, []);

  const handleActionChange = (e) => {
    if (e && e.target) {
      setLocalAction(e.target.value);
      setBlankTeamError(false);
    }
  };

  const generateActionItems = () => {
    const arr = [];
    Object.keys(actionsNameMap).forEach((key) => {
      arr.push(
        <MenuItem key={key} value={key}>
          {actionsNameMap[key].selector}
        </MenuItem>
      );
    });

    return arr;
  };

  const generateOperatorItems = () => {
    const arr = [];
    Object.keys(operatorsNameMap).forEach((key) => {
      arr.push(
        <MenuItem key={key} value={key}>
          {operatorsNameMap[key].selector}
        </MenuItem>
      );
    });

    return arr;
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Edit Lead Qualification</DialogTitle>
      <HookForm onSubmit={onEditRuleFieldSubmit} useFormProps={useFormProps}>
        <DialogContent>
          {blankTeamError && (
            <Alert severity="error">You must first select a Team</Alert>
          )}
          {didFailMutatingRule && (
            <Alert severity="error">
              There was a problem editing your qualification. Try again.
            </Alert>
          )}
          <Box display="grid" direction="column" rowGap={2}>
            <HookForm.Select name={RULE_FIELD} label="Lead Field">
              {leadFields?.map((leadField) => {
                return (
                  <MenuItem
                    key={leadField.slingshot_field_id}
                    value={leadField.slingshot_field_id}
                  >
                    {leadField.name}
                  </MenuItem>
                );
              })}
            </HookForm.Select>
            <HookForm.Select name={RULE_OPERATOR} label="Operator">
              {generateOperatorItems()}
            </HookForm.Select>
            <HookForm.TextField name={RULE_VALUE} label="Value" />
            <HookForm.Select
              name={RULE_ACTION}
              label="Action"
              onChange={handleActionChange}
              value={localAction}
            >
              {generateActionItems()}
            </HookForm.Select>
            {localAction == 'set_team' && (
              <HookForm.Select name={RULE_ACTION_DATA} label="Team">
                {teams?.map((team) => {
                  return (
                    <MenuItem
                      key={`{"team_id": ${team.id}}`}
                      value={`{"team_id": ${team.id}}`}
                    >
                      {team.name}
                    </MenuItem>
                  );
                })}
              </HookForm.Select>
            )}
            {localAction == 'set_account' && (
              <HookForm.Select name={RULE_ACTION_DATA} label="Account">
                {accounts?.map((account) => {
                  return (
                    <MenuItem
                      key={`{"organization_id": ${account.id}}`}
                      value={`{"organization_id": ${account.id}}`}
                    >
                      {account.name}
                    </MenuItem>
                  );
                })}
              </HookForm.Select>
            )}
            {localAction == 'set_source' && (
              <HookForm.Select name={RULE_ACTION_DATA} label="Source">
                {sources?.map((source) => {
                  return (
                    <MenuItem
                      key={`{"source_id": "${source.id}"}`}
                      value={`{"source_id": "${source.id}"}`}
                    >
                      {source.name}
                    </MenuItem>
                  );
                })}
              </HookForm.Select>
            )}
            {localAction == 'set_task_sequence' && (
              <HookForm.Select name={RULE_ACTION_DATA} label="Task Sequence">
                {taskSequences?.map((taskSequence) => {
                  return (
                    <MenuItem
                      key={`{"task_sequence_id": "${taskSequence.id}"}`}
                      value={`{"task_sequence_id": "${taskSequence.id}"}`}
                    >
                      {taskSequence.name}
                    </MenuItem>
                  );
                })}
              </HookForm.Select>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="cancel" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton loading={isMutatingRule} type="submit">
            Confirm
          </LoadingButton>
        </DialogActions>
      </HookForm>
    </Dialog>
  );
};

const CreateRuleDialog = ({ isOpen, onResolve, onReject, leadFields, orgId }) => {
  const { data: user } = useUsersCurrent();
  const [accounts, setAccounts] = useState([]);
  useEffect(() => {
    setAccounts(user.authorizations.table.organizations);
  }, [user]);
  const {
    mutate: createRule,
    isLoading: isMutatingRule,
    isSuccess: didSucceedMutatingRule,
    isError: didFailMutatingRule,
  } = useRulesCreate();
  const { data: teams } = useTeamsList([['organization_id[]', orgId]]);
  const { data: sources } = useSourcesList([['organization_id[]', orgId]]);
  const { data: taskSequences } = useTaskSequencesList([
    ['organization_id[]', orgId],
  ]);

  const [localAction, setLocalAction] = useState('disable_all');
  const [blankTeamError, setBlankTeamError] = useState(false);

  useEffect(() => {
    if (didSucceedMutatingRule && !didFailMutatingRule) onResolve();
  }, [didSucceedMutatingRule, didFailMutatingRule]);

  const onCreateRuleFieldSubmit = (formData) => {
    if (
      (localAction == 'set_team' ||
        localAction == 'set_source' ||
        localAction == 'set_task_sequence') &&
      !formData[RULE_ACTION_DATA]
    )
      return setBlankTeamError(true);

    setBlankTeamError(false);
    createRule({
      value: formData[RULE_VALUE],
      action: localAction,
      operator: formData[RULE_OPERATOR],
      slingshot_field_id: formData[RULE_FIELD],
      action_data:
        (localAction == 'set_team' ||
          localAction == 'set_account' ||
          localAction == 'set_source' ||
          localAction == 'set_task_sequence') &&
        formData[RULE_ACTION_DATA]
          ? JSON.parse(formData[RULE_ACTION_DATA])
          : {},
    });
  };

  const defaultValues = {
    [RULE_VALUE]: '',
    [RULE_FIELD]: leadFields[0]?.slingshot_field_id,
    [RULE_ACTION]: 'disable_all',
    [RULE_OPERATOR]: 'equal',
    [RULE_ACTION_DATA]: '',
  };

  const useFormProps = {
    defaultValues,
    resolver: yupResolver(
      yup.object({
        [RULE_VALUE]: yup.string(),
        [RULE_FIELD]: yup.mixed().oneOf(
          leadFields.map((item) => {
            return item.slingshot_field_id;
          })
        ),
        [RULE_ACTION]: yup.mixed().oneOf(Object.keys(actionsNameMap)),
        [RULE_OPERATOR]: yup.mixed().oneOf(Object.keys(operatorsNameMap)),
        [RULE_ACTION_DATA]: yup.string(),
      })
    ),
  };

  const handleActionChange = (e) => {
    if (e && e.target) {
      setLocalAction(e.target.value);
      setBlankTeamError(false);
    }
  };

  const generateActionItems = () => {
    const arr = [];
    Object.keys(actionsNameMap).forEach((key) => {
      arr.push(
        <MenuItem key={key} value={key}>
          {actionsNameMap[key].selector}
        </MenuItem>
      );
    });

    return arr;
  };

  const generateOperatorItems = () => {
    const arr = [];
    Object.keys(operatorsNameMap).forEach((key) => {
      arr.push(
        <MenuItem key={key} value={key}>
          {operatorsNameMap[key].selector}
        </MenuItem>
      );
    });

    return arr;
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Create Lead Qualification</DialogTitle>
      <HookForm onSubmit={onCreateRuleFieldSubmit} useFormProps={useFormProps}>
        <DialogContent>
          {blankTeamError && (
            <Alert severity="error">You must first select a Team</Alert>
          )}
          {didFailMutatingRule && (
            <Alert severity="error">
              There was a problem creating your qualification. Try again.
            </Alert>
          )}
          <Box display="grid" direction="column" rowGap={2}>
            <HookForm.Select name={RULE_FIELD} label="Lead Field">
              {leadFields?.map((leadField) => {
                return (
                  <MenuItem
                    key={leadField.slingshot_field_id}
                    value={leadField.slingshot_field_id}
                  >
                    {leadField.name}
                  </MenuItem>
                );
              })}
            </HookForm.Select>
            <HookForm.Select name={RULE_OPERATOR} label="Operator">
              {generateOperatorItems()}
            </HookForm.Select>
            <HookForm.TextField name={RULE_VALUE} label="Value" />
            <HookForm.Select
              name={RULE_ACTION}
              label="Action"
              onChange={handleActionChange}
              value={localAction}
            >
              {generateActionItems()}
            </HookForm.Select>
            {localAction == 'set_team' && (
              <HookForm.Select name={RULE_ACTION_DATA} label="Team">
                {teams?.map((team) => {
                  return (
                    <MenuItem
                      key={`{"team_id": ${team.id}}`}
                      value={`{"team_id": ${team.id}}`}
                    >
                      {team.name}
                    </MenuItem>
                  );
                })}
              </HookForm.Select>
            )}
            {localAction == 'set_account' && (
              <HookForm.Select name={RULE_ACTION_DATA} label="Account">
                {accounts?.map((account) => {
                  return (
                    <MenuItem
                      key={`{"organization_id": ${account.id}}`}
                      value={`{"organization_id": ${account.id}}`}
                    >
                      {account.name}
                    </MenuItem>
                  );
                })}
              </HookForm.Select>
            )}
            {localAction == 'set_source' && (
              <HookForm.Select name={RULE_ACTION_DATA} label="Source">
                {sources?.map((source) => {
                  return (
                    <MenuItem
                      key={`{"source_id": "${source.id}"}`}
                      value={`{"source_id": "${source.id}"}`}
                    >
                      {source.name}
                    </MenuItem>
                  );
                })}
              </HookForm.Select>
            )}
            {localAction == 'set_task_sequence' && (
              <HookForm.Select name={RULE_ACTION_DATA} label="Task Sequence">
                {taskSequences?.map((taskSequence) => {
                  return (
                    <MenuItem
                      key={`{"task_sequence_id": "${taskSequence.id}"}`}
                      value={`{"task_sequence_id": "${taskSequence.id}"}`}
                    >
                      {taskSequence.name}
                    </MenuItem>
                  );
                })}
              </HookForm.Select>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="cancel" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton loading={isMutatingRule} type="submit">
            Confirm
          </LoadingButton>
        </DialogActions>
      </HookForm>
    </Dialog>
  );
};

const DeleteRuleDialog = ({ isOpen, onResolve, onReject, rule }) => {
  const {
    mutate: deleteRule,
    isSuccess: didSucceedMutatingRule,
    isError: didFailMutatingRule,
    isLoading: isMutatingRule,
  } = useRulesDelete();

  useEffect(() => {
    if (didSucceedMutatingRule && !didFailMutatingRule) onResolve();
  }, [didSucceedMutatingRule, didFailMutatingRule]);

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Delete Lead Qualification</DialogTitle>
      <DialogContent>
        {didFailMutatingRule && (
          <Alert severity="error">
            There was a problem deleting your qualification. Try again.
          </Alert>
        )}
        Are you sure you wish {actionsNameMap[rule.action]?.deleting}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton loading={isMutatingRule} onClick={() => deleteRule(rule.id)}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default LeadQualificationSettingsCard;

import { useEffect } from 'react';
import { useMutation } from 'react-query';
import TwilioToken from '../../clients/TwilioToken';
const REFRESH_INTERVAL_MINUTES = 1000 * 60 * 55;

export const useTwilioTokenWithInterval = () => {
  const {
    data,
    mutate: createTwilioToken,
    isLoading: isLoadingTwilioToken,
  } = useMutation(() => TwilioToken.create(), {
    retry: 5,
  });
  // safe destructure jwt_token
  const { jwt_token: twilioToken } = { ...data };

  useEffect(() => {
    // initial token
    createTwilioToken();
    // refresh token
    const i = setInterval(() => createTwilioToken(), REFRESH_INTERVAL_MINUTES);
    return () => clearInterval(i);
  }, []);

  return { twilioToken, createTwilioToken, isLoadingTwilioToken };
};

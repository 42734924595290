import React, { useContext, useEffect, useState } from 'react';
import 'animate.css';
import SyncLoader from 'react-spinners/SyncLoader';
import { Button, Col, Container, Row } from 'react-bootstrap';
import EmailCreate from '../email/EmailCreate';
import PhoneCall from '../phone/PhoneCall';
import TextMessage from '../tasks/TextMessage';
import NoteCreate from '../notes/NoteCreate';
import NoteDetail from '../notes/NoteDetail';
import AppointmentList from '../appointments/appointmentlist';
import AppContext from '../../contexts/AppContext';
import Lead from '../../clients/Lead';
import EditDetails from '../editDetails/EditDetails';
import { BiLock, BiChevronLeft } from 'react-icons/bi';
import { FiPhone, FiMessageSquare } from 'react-icons/fi';
import { BsEnvelope } from 'react-icons/bs';
import OverrideLockModal from '../leads/OverrideLockModal';
import UserSelfUnlockRow from '../leads/UserSelfUnlockRow';
import { getLockStatus, LOCK_BY_USER } from '../tasks/TaskUtils';
import { renderInboundCommunicationDisplay } from '../../util/LeadUtils';
import { getLeadName } from '../../util/AppUtils';
import { useHistory } from 'react-router-dom';
import { useLeadLocksCreate } from '../../MUI/services/leadLocks/mutations';
import { useQueryClient } from 'react-query';
import { tasksQueryKeys } from '../../MUI/services/tasks/queries';
import { leadsQueryKeys } from '../../MUI/services/leads/queries';
import { useLeadLocksList } from '../../MUI/services/leadLocks/queries';
import { useCalendarsList } from '../../MUI/services/calendars/queries';
import { useLeadLinksList } from '../../api/leadlinks/queries';
import { useAppointmentsList } from '../../api/appointments/queries';
import LeadHistoryTabs from '../../MUI/components/leadEventHistory/LeadHistoryTabs';
import { Button as NewButton, Grid, Typography, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { FiMaximize2 } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { resetMinimizeCall, minimizeCall } from '../../redux/actions';
import MinimizedButton from '../common/MinimizedButton';

const LeadDetail = ({ lead }) => {
  const [showText, setShowText] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCancelEdit, setIsCancelEdit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [callCount, setCallCount] = useState();
  const [emailCount, setEmailCount] = useState();
  const [textCount, setTextCount] = useState();
  const [showOverride, setShowOverride] = useState(false);
  const [isLockedToUser, setIsLockedToUser] = useState(false);
  const { mutate: createLeadLock } = useLeadLocksCreate();
  const history = useHistory();

  const { setLeads, user } = useContext(AppContext);
  const handleCloseText = () => setShowText(false);
  const handleCloseEmail = () => setShowEmail(false);
  const handleClosePhone = () => setShowPhone(false);
  const handleCloseNote = () => setShowNote(false);
  const handleShowText = () => setShowText(true);
  const handleShowEmail = () => setShowEmail(true);
  const handleShowPhone = () => setShowPhone(true);
  const handleShowNote = () => setShowNote(true);
  const [leadlinkId, setLeadLinkID] = useState([]);
  const [calendarId, setCalendarID] = useState('');
  const isMinimized = useSelector((state) => state.phoneCall.isMinimized);
  const deviceStatus = useSelector((state) => state.phoneCall.deviceStatus);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [appointmentdata, setAppointmentData] = useState([]);
  const { data: locks, isFetching: isLoadingLocks } = useLeadLocksList({
    onSettled: (data) => {
      setIsLockedToUser(getLockStatus(lead.id, data, user) === LOCK_BY_USER);
    },
  });
  const { data: calendarData } = useCalendarsList([
    { organization_id: lead.organization_id, lead_id: lead.id },
  ]);
  const { data: lead_links } = useLeadLinksList({ lead_id: lead.id });

  useEffect(() => {
    if (lead_links && lead_links.items && Array.isArray(lead_links.items)) {
      const ids = lead_links.items.map((item) => item.id);
      setLeadLinkID(ids);
    }
  }, [lead_links]);

  const { data: appointments } = useAppointmentsList([
    { lead_id: lead.id, page_size: 1000 },
  ]);

  useEffect(() => {
    if (calendarData && calendarData.items && Array.isArray(calendarData.items)) {
      const calendar_id = calendarData.items.map((item) => item.id);
      setCalendarID(calendar_id);
    }
  }, [calendarData]);

  useEffect(() => {
    if (appointments && appointments.items && Array.isArray(appointments.items)) {
      const appointment = appointments.items.map((item) => ({
        id: item.id,
        start_time: item.begins_at,
        ends_at: item.ends_at,
      }));
      setAppointmentData(appointment);
    }
  }, [appointments]);

  useEffect(() => {
    dispatch(resetMinimizeCall());
  }, []);

  useEffect(() => {
    if (lead) {
      setIsLockedToUser(false);
      createLeadLock(
        {
          lead_id: lead.id,
          user_id: user.id,
          organization_id: lead.organization_id,
        },
        {
          onSuccess: () => {
            setTimeout(function () {
              queryClient.invalidateQueries(['lead_locks']);
            }, 1000);
            if (getLockStatus(lead, locks, user) != 'LOCK_BY_OTHER') {
              setIsLockedToUser(true);
            }
          },
        }
      );
    }
    return () => setIsEditing(false);
  }, [lead]);

  useEffect(() => {
    if (
      textCount == undefined ||
      emailCount == undefined ||
      callCount == undefined
    ) {
      return;
    }
  }, [textCount, emailCount, callCount]);

  const deleteLead = () => {
    if (window.confirm(`Do you really want to delete ${getLeadName(lead)}?`)) {
      setIsDeleting(true);
      Lead.delete(lead.id).then(() => {
        setIsDeleting(false);
        queryClient.invalidateQueries(tasksQueryKeys.all);
        queryClient.invalidateQueries(leadsQueryKeys.all);
        history.push({ pathname: '/leads', state: 'refreshLeads' });
      });
    }
  };
  const onDeviceReady = (device) => {
    setDeviceStatus('Ready');
  };

  const onDeviceConnect = (connection) => {
    setDeviceStatus('Connected');
    setCallSid(connection.parameters.CallSid);
  };

  const onDeviceDisconnect = (connection) => {
    setDeviceStatus('Ready');
    setActiveConnection();
    resetCallTimer();
  };

  const resetCallTimer = () => {
    clearInterval(timerIntervalId);
    setTimerIntervalId(null);
  };

  const hasLock = (l) => {
    if (locks)
      return (
        locks.find((lock) => lock.lead_id == l.id && lock.user_id != user.id) !=
        undefined
      );
  };
  const unlock = () => {
    setShowOverride(true);
  };
  const handleCloseOverride = () => {
    setShowOverride(false);
  };
  const handleMinimizeCall = () => {
    dispatch(minimizeCall());
  };

  return (
    <>
      <OverrideLockModal
        showOverride={showOverride}
        handleCloseOverride={handleCloseOverride}
        lead={lead}
        user={user}
      />
      <Container className="m-0 pl-3 pr-0 mw-100 h-100">
        <Button
          variant="link"
          className="tasks-back d-none d-lg-block"
          onClick={() => {
            history.push('/leads');
          }}
        >
          <BiChevronLeft size={'1.2em'} /> Leads
        </Button>
        <Row noGutters className="h-100 lead-task-show-container">
          <Grid
            container
            justifyContent="space-between"
            className="lead-task-show-header"
          >
            <Grid item>
              <Typography variant="h4">{getLeadName(lead)}</Typography>
            </Grid>
            <Grid item sx={{ pr: 2 }}>
              {isEditing && (
                <LoadingButton
                  variant={'contained'}
                  loading={isSaving}
                  size="md"
                  sx={{ mr: 1 }}
                  onClick={() => {
                    setOnSave(true);
                  }}
                >
                  Save
                </LoadingButton>
              )}
              {isEditing ? (
                <NewButton
                  variant={'outlined'}
                  size="md"
                  onClick={() => {
                    setIsEditing(false);
                    setIsCancelEdit(true);
                  }}
                >
                  Cancel
                </NewButton>
              ) : (
                <NewButton
                  variant={'outlined'}
                  size="md"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                >
                  Edit
                </NewButton>
              )}
            </Grid>
          </Grid>
          <Row noGutters className="lead-task-show-split-sections">
            <Col xs={12} xl={6} className="lead-task-detail-section">
              <hr />
              <Row noGutters>
                {hasLock(lead) ? (
                  <Col xs={12} className={'pb-2'}>
                    <button
                      type={'button'}
                      className={'btn btn-sigma-primary btn-lg btn-block'}
                      onClick={unlock}
                    >
                      {isLoadingLocks ? (
                        <SyncLoader color={'white'} size={'10px'} />
                      ) : (
                        <>
                          <BiLock className="mb-1 mr-1" />
                          Override Lead
                        </>
                      )}
                    </button>
                  </Col>
                ) : (
                  <>
                    <Col xs={4} xl={4} className="pb-2 pr-2">
                      {isMinimized ? (
                        <MinimizedButton
                          fullWidth
                          size="large"
                          onClick={handleMinimizeCall}
                        />
                      ) : (
                        <NewButton
                          fullWidth
                          size="large"
                          startIcon={<FiPhone />}
                          onClick={handleShowPhone}
                          disabled={!(lead.phones?.length > 0)}
                        >
                          Call
                        </NewButton>
                      )}
                    </Col>
                    <Col xs={4} xl={4} className="pb-2 px-1">
                      <NewButton
                        fullWidth
                        size="large"
                        startIcon={<BsEnvelope />}
                        onClick={handleShowEmail}
                        disabled={!(lead.email_addresses?.length > 0)}
                      >
                        Email
                      </NewButton>
                    </Col>
                    <Col xs={4} xl={4} className="pb-2 pl-2">
                      <NewButton
                        fullWidth
                        size="large"
                        startIcon={<FiMessageSquare />}
                        onClick={handleShowText}
                        disabled={!(lead.phones?.length > 0)}
                      >
                        Text/SMS
                      </NewButton>
                    </Col>
                  </>
                )}
              </Row>
              {showPhone && (
                <PhoneCall
                  show={showPhone}
                  closeModal={handleClosePhone}
                  lead={lead}
                />
              )}
              <EmailCreate
                show={showEmail}
                closeModal={handleCloseEmail}
                lead={lead}
              />
              <TextMessage
                show={showText}
                closeModal={handleCloseText}
                lead={lead}
              />
              <hr />
              {renderInboundCommunicationDisplay(lead)}
              <EditDetails
                lead={lead}
                isEditing={isEditing}
                isCancelEdit={isCancelEdit}
                setIsCancelEdit={setIsCancelEdit}
                user={user}
                onSave={onSave}
                setIsEditing={setIsEditing}
                setIsSaving={setIsSaving}
                setOnSave={setOnSave}
                setLeads={setLeads}
                setShowPhone={setShowPhone}
              />
              {calendarId.length > 0 && (
                <div>
                  <hr />
                  <Row>
                    <AppointmentList appointmentdata={appointmentdata} />
                  </Row>
                </div>
              )}
              <hr />
              <Row>
                <NoteCreate
                  show={showNote}
                  closeModal={handleCloseNote}
                  lead={lead}
                />
                <Col>
                  <strong>Notes</strong>
                </Col>
                <Col className={'text-right'}>
                  <Button variant={'light'} onClick={handleShowNote}>
                    Add Note
                  </Button>
                </Col>
              </Row>
              {lead &&
                lead.notes &&
                lead.notes
                  .sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
                  .map((note) => {
                    return <NoteDetail note={note} key={note.id} />;
                  })}
              {isEditing && (
                <>
                  <hr />
                  <Button variant={'danger'} onClick={deleteLead}>
                    {isDeleting ? (
                      <SyncLoader color={'white'} size={'10'} />
                    ) : (
                      'Delete'
                    )}
                  </Button>
                </>
              )}
              {isLockedToUser && <UserSelfUnlockRow lead={lead} pipeline={true} />}
            </Col>
            <Col xs={12} xl={6} className="lead-history-tabs-section">
              <LeadHistoryTabs lead={lead} />
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  );
};

export default LeadDetail;

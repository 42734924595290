import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import FewShosts from './FewShots';
import { fewshotsQueryKeys } from './queries';

export const useFewShotsCreate = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => FewShosts.create({ ...params }), {
    onSuccess: () => {
      queryClient.invalidateQueries(fewshotsQueryKeys.all);
    },
  });
};

export const useFewShotsEdit = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => FewShosts.edit({ ...params }), {
    onSuccess: () => {
      queryClient.invalidateQueries(fewshotsQueryKeys.all);
    },
  });
};

export const useFewShotsDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(FewShosts.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(fewshotsQueryKeys.all);
    },
  });
};

import { useQuery } from 'react-query';
import { queryKeyFactory } from '../../MUI/services/queryClientConfig';
import LeadLinks from './LeadLinks';

export const leadLeadLinksQueryKeys = queryKeyFactory('lead_links');
export const useLeadLinksList = (params, options) => {
  return useQuery(leadLeadLinksQueryKeys.list(), () => LeadLinks.list(params), {
    staleTime: 0,
    ...options,
  });
};

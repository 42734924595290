import React, { useContext, useEffect, useRef, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { Link, Prompt, useHistory, useParams } from 'react-router-dom';
import { FaTrash, FaInfoCircle } from 'react-icons/fa';
import AppContext from '../../contexts/AppContext';
import {
  useRenderedSteps,
  INITIALIZE,
  ADD_STEP,
  DELETE_STEP,
  UPDATE_STEP,
} from './useRenderedSteps';
import { actionId, toastOptions } from '../../util/AppUtils';
import { getGroupedStepsArray, getNextDaysAfterInt } from './util';
import { Alert } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { useMediaQuery } from 'react-responsive';
import Mailboxes from '../../clients/Mailboxes';
import TextOptions from './TextOptions';
import EmailOptions from './EmailOptions';
import CallOptions from './CallOptions';
import { toast } from 'react-toastify';
import {
  useTaskSequencesList,
  useTaskSequenceShow,
} from '../../MUI/services/taskSequences/queries';
import { useMessageTemplatesList } from '../../MUI/services/messageTemplates/queries';
import { useTaskSequenceEdit } from '../../MUI/services/taskSequences/mutations';
import { useOrg } from '../../contexts/OrgProvider';
import { useAiProfileList } from '../../api/aiprofile/queries';

export const SequenceCheckbox = ({ label, checked, onChange }) => {
  return (
    <>
      <div className="">
        <Form.Check
          custom
          label=""
          className="checkbox-sigma-primary"
          checked={checked}
          onChange={(e) => onChange(e)}
        />
      </div>
      <div className="h6 ml-2 m-0">{label}</div>
    </>
  );
};

const StepAction = ({
  getFirstTemplateId,
  action,
  dispatch,
  deleteAction,
  actionIndex,
  taskActionSet,
  messageTemplates,
}) => {
  const [options, setOptions] = useState();
  const optionMap = {
    Call: (
      <CallOptions
        dispatch={dispatch}
        action={action}
        messageTemplates={messageTemplates}
      />
    ),
    Email: (
      <EmailOptions
        dispatch={dispatch}
        action={action}
        messageTemplates={messageTemplates}
      />
    ),
    Text: (
      <TextOptions
        dispatch={dispatch}
        action={action}
        messageTemplates={messageTemplates}
      />
    ),
  };

  const updateOptions = (e) => {
    setOptions(optionMap[e.target.value]);
    dispatch({
      type: UPDATE_STEP,
      payload: {
        editID: action.editID,
        updates: {
          message_template_id: getFirstTemplateId(e.target.value),
          task_action: { id: actionId[e.target.value], name: e.target.value },
          task_action_id: actionId[e.target.value],
        },
      },
    });
  };

  useEffect(() => {
    setOptions(optionMap[action.task_action.name]);
  }, [action]);

  const selectActions = () => {
    let o = [
      <option value="Call" key="Call" disabled={taskActionSet.includes('Call')}>
        Call
      </option>,
      <option value="Email" key="Email" disabled={taskActionSet.includes('Email')}>
        Email
      </option>,
      <option value="Text" key="Text" disabled={taskActionSet.includes('Text')}>
        Text
      </option>,
    ];
    return <>{o}</>;
  };

  return (
    <Row className="py-2">
      <Col xs={1} className={'text-right'}>
        <FaTrash
          size="16px"
          className="medium-purple mt-2"
          onClick={() =>
            deleteAction({
              editID: action.editID,
              id: action.id,
            })
          }
        />
      </Col>
      <Col xs="4" lg={3}>
        <Form.Control
          as="select"
          onChange={updateOptions}
          value={action.task_action.name}
        >
          {selectActions()}
        </Form.Control>
      </Col>
      <Col xs="4" lg={5}>
        <Form.Row>
          <Col>
            <Form.Group>{options}</Form.Group>
          </Col>
        </Form.Row>
      </Col>
    </Row>
  );
};

const AutomationsConfig = ({ mailboxId, setMailboxIdHandler, sequence }) => {
  const [mailboxes, setMailboxes] = useState();
  const { user } = useContext(AppContext);

  useEffect(() => {
    if (user) {
      listMailboxes();
    }
  }, [user]);

  useEffect(() => {
    if (mailboxes && sequence) {
      const seqMailboxId = sequence.email_mailbox_id;

      if (seqMailboxId) {
        const seqMailbox = mailboxes.find((mailbox) => {
          return mailbox.id === seqMailboxId;
        });

        if (seqMailbox) {
          return;
        }

        Mailboxes.show(seqMailboxId).then((res) => {
          const newMailboxes = mailboxes.concat([res]);
          setMailboxes(newMailboxes);
        });
      }
    }
  }, [mailboxes, sequence]);

  useEffect(() => {
    if (sequence && sequence.email_mailbox_id) {
      setMailboxIdHandler(sequence.email_mailbox_id);
      return;
    }
  }, [mailboxes, sequence]);

  const listMailboxes = () => {
    if (user && user.id) {
      Mailboxes.listAuthorized(user.id).then((res) => {
        setMailboxes(res);
      });
    }
  };

  const generateMailboxOptions = () => {
    if (mailboxes && mailboxes.length > 0) {
      return mailboxes.map((mailbox) => {
        return (
          <option
            value={mailbox.id}
            key={mailbox.id}
            selected={mailbox.id == mailboxId}
          >
            {mailbox.email_address}
          </option>
        );
      });
    }
  };

  const changeMailbox = (e) => {
    setMailboxIdHandler(e.target.value);
  };

  return (
    <Card className={'mt-4'}>
      <Row className="mx-0 py-4 bg-sigma-light-primary bg-sigma-light-primary leads-header">
        <Col xs={10} className={'text-left p-0'}>
          Email
        </Col>
      </Row>
      <ListGroup>
        <ListGroup.Item className="list-group-item-sigma" key="1">
          <Row>
            <Col xs={1} lg={2} className="my-auto pr-0">
              Address
            </Col>
            <Col xs={6} md={8} xl={5}>
              <Form.Row>
                <Col className="my-auto text-right d-flex justify-content-end align-items-center">
                  <OverlayTrigger
                    placement={
                      useMediaQuery({ query: `(max-width: 760px)` })
                        ? 'bottom'
                        : 'top'
                    }
                    delay={{ show: 100, hide: 50 }}
                    overlay={
                      <Tooltip id="select-mailbox-tooltip">
                        Please select an email for automations to send emails from.
                        If you do not have any email configured you may add one by
                        navigating to Settings {'>'} Email. If no mailbox is
                        selected, emails will be sent from a fallback address.
                      </Tooltip>
                    }
                  >
                    <FaInfoCircle
                      size={'1.5em'}
                      color={'#34d1b6'}
                      className="mr-2"
                    />
                  </OverlayTrigger>
                  Send emails from
                </Col>
                <Col xl={6}>
                  <Form.Control
                    as={'select'}
                    onChange={changeMailbox}
                    disabled={!mailboxes || mailboxes.length === 0}
                  >
                    <option value="" selected={!!mailboxId} key="0">
                      Default
                    </option>
                    {generateMailboxOptions()}
                  </Form.Control>
                </Col>
              </Form.Row>
            </Col>
          </Row>
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
};

const CompletionBehaviorConfig = ({
  sequenceId,
  taskSequences,
  nextTaskSequenceId,
  setNextTaskSequenceIdHandler,
}) => {
  const taskSequenceOptions = () => {
    if (taskSequences && taskSequences.length > 0) {
      return taskSequences
        .filter((ts) => ts.id != sequenceId)
        .map((ts) => {
          return (
            <option key={ts.id} value={ts.id}>
              {ts.name}
            </option>
          );
        });
    }
  };

  const changeNextSequence = (e) => {
    const selectedId = e.target.value;
    if (selectedId) {
      if (selectedId === 'none') {
        setNextTaskSequenceIdHandler(null);
      } else {
        setNextTaskSequenceIdHandler(selectedId);
      }
    }
  };

  return (
    <Card className={'mt-4'}>
      <Row className="mx-0 py-4 bg-sigma-light-primary bg-sigma-light-primary leads-header">
        <Col xs={10} className={'text-left p-0'}>
          End-of-Sequence Action
        </Col>
      </Row>
      <ListGroup>
        <ListGroup.Item className="list-group-item-sigma" key="1">
          <Row>
            <Col xs={1} lg={2} className="my-auto pr-0">
              Next Sequence
            </Col>
            <Col xs={6} md={8} xl={5}>
              <Form.Row>
                <Col className="my-auto text-right d-flex justify-content-end align-items-center">
                  <OverlayTrigger
                    placement={
                      useMediaQuery({ query: `(max-width: 760px)` })
                        ? 'bottom'
                        : 'top'
                    }
                    delay={{ show: 100, hide: 50 }}
                    overlay={
                      <Tooltip id="select-next-sequence-tooltip">
                        Please select the next sequence for leads to be moved to
                        after they complete all tasks in this sequence. If you select
                        none, the lead will stay in this sequence with no future
                        tasks.
                      </Tooltip>
                    }
                  >
                    <FaInfoCircle
                      size={'1.5em'}
                      color={'#34d1b6'}
                      className="mr-2"
                    />
                  </OverlayTrigger>
                  Transfer leads to
                </Col>
                <Col xl={6}>
                  <Form.Control
                    as={'select'}
                    onChange={changeNextSequence}
                    value={nextTaskSequenceId || 'none'}
                  >
                    <option value="none" key="none">
                      None
                    </option>
                    {taskSequenceOptions()}
                  </Form.Control>
                </Col>
              </Form.Row>
            </Col>
          </Row>
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
};

const AIAgent = ({ aiProfileId, setaiProfileIdHandler }) => {
  const { id: orgId } = useOrg();
  const {
    data: aiProfileData,
    isError: aiProfileError,
    isLoading: aiProfileLoading,
  } = useAiProfileList({ organization_id: orgId });

  const aiProfileOptions = () => {
    if (aiProfileData && aiProfileData?.items.length > 0) {
      return aiProfileData?.items.map((aiprofile) => {
        return (
          <option
            key={aiprofile.id}
            value={aiprofile.id}
            selected={aiprofile.id == aiProfileId}
          >
            {aiprofile.name}
          </option>
        );
      });
    }
  };

  const changeAiProfile = (e) => {
    const selectedId = e.target.value;
    if (selectedId) {
      if (selectedId === 'none') {
        setaiProfileIdHandler(null);
      } else {
        setaiProfileIdHandler(selectedId);
      }
    }
  };

  return (
    <Card className={'mt-4'}>
      <Row className="mx-0 py-4 bg-sigma-light-primary bg-sigma-light-primary leads-header">
        <Col xs={10} className={'text-left p-0'}>
          AI Agents
        </Col>
      </Row>
      <ListGroup>
        <ListGroup.Item className="list-group-item-sigma" key="1">
          <Row>
            <Col xs={1} lg={2} className="my-auto pr-0">
              Profile
            </Col>
            <Col xs={4} md={5} xl={4}>
              <Form.Row>
                <Col className="my-auto text-right d-flex justify-content-end align-items-center">
                  <OverlayTrigger
                    placement={
                      useMediaQuery({ query: `(max-width: 760px)` })
                        ? 'bottom'
                        : 'top'
                    }
                    delay={{ show: 100, hide: 50 }}
                    overlay={
                      <Tooltip id="select-next-sequence-tooltip">
                        Please select the AI profile to respoend in comming messages.
                      </Tooltip>
                    }
                  >
                    <FaInfoCircle
                      size={'1.5em'}
                      color={'#34d1b6'}
                      className="mr-2"
                    />
                  </OverlayTrigger>
                  AI Profile
                </Col>
                <Col xl={6}>
                  <Form.Control as={'select'} onChange={changeAiProfile}>
                    <option value="none" key="none">
                      None
                    </option>
                    {aiProfileOptions()}
                  </Form.Control>
                </Col>
              </Form.Row>
            </Col>
          </Row>
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
};
const TaskRow = ({
  daysAfter,
  dispatch,
  messageTemplates,
  step,
  taskIndex,
  nonGroupedSteps,
  groupedSteps,
  actions,
  setDestroyed,
  daysAfterSet,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [immediately, setImmediately] = useState();

  useEffect(() => {
    setImmediately(taskIndex == 0 && step[0] == '0');
  });

  useEffect(() => {}, [messageTemplates]);

  const getFirstTemplateId = (type) => {
    const templateId = messageTemplates
      .filter((t) => t.task_action.name === type)
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))[0]?.id;

    return templateId ? templateId : null;
  };

  useEffect(() => {
    setImmediately(taskIndex == 0 && step[0] == '0');
  });

  const addAction = () => {
    let actionType;
    let messageTemplateId;
    if (!taskActionSet.includes('Call')) {
      actionType = 'Call';
      messageTemplateId = getFirstTemplateId('Call');
    }
    if (!taskActionSet.includes('Text')) {
      actionType = 'Text';
      messageTemplateId = getFirstTemplateId('Text');
    }
    if (!taskActionSet.includes('Email')) {
      actionType = 'Email';
      messageTemplateId = getFirstTemplateId('Email');
    }

    dispatch({
      type: ADD_STEP,
      payload: { actionType, daysAfter, messageTemplateId },
    });
  };

  const deleteAction = ({ id, editID }) => {
    dispatch({
      type: DELETE_STEP,
      payload: { editID },
    });
    if (id) {
      setDestroyed((prev) => [...prev, { id, _destroy: true }]);
    }
  };

  const updateDays = (delay, daysAfter) => {
    const prevDaysAfter =
      taskIndex === 0 ? 0 : parseInt(groupedSteps[taskIndex - 1][0]);
    const offset = prevDaysAfter + delay - daysAfter;
    nonGroupedSteps.forEach((action) => {
      if (action.days_after >= daysAfter) {
        dispatch({
          type: UPDATE_STEP,
          payload: {
            editID: action.editID,
            updates: {
              days_after: action.days_after + offset,
            },
          },
        });
      }
    });
  };

  const updateImmediately = (newImmediately) => {
    nonGroupedSteps.forEach((action) => {
      dispatch({
        type: UPDATE_STEP,
        payload: {
          editID: action.editID,
          updates: {
            days_after: newImmediately
              ? action.days_after - parseInt(step[0])
              : action.days_after + 1,
          },
        },
      });
    });
  };

  const deleteAllTasks = () => {
    actions.forEach((action) =>
      deleteAction({ id: action.id, editID: action.editID })
    );
  };

  const dayRange = () => {
    if (taskIndex == 0) {
      return [...Array(366).keys()];
    }
    return Array.from({ length: 366 }, (_, i) => i + 1);
  };

  const taskActionSet = actions.map((action) => action.task_action.name);

  const getDelayOffset = () =>
    taskIndex > 0
      ? parseInt(groupedSteps[taskIndex][0]) -
        parseInt(groupedSteps[taskIndex - 1][0])
      : parseInt(groupedSteps[taskIndex][0]);
  return (
    <Card className={`mt-4`}>
      <Row className="mx-0 py-4 bg-sigma-light-primary bg-sigma-light-primary leads-header">
        <Col xs={10} className={'text-left p-0'}>
          Task(s)
        </Col>
        <Col xs={2} className={'text-right'}>
          <FaTrash size="16px" className="medium-purple" onClick={deleteAllTasks} />
        </Col>
      </Row>
      <ListGroup>
        <ListGroup.Item className="list-group-item-sigma" key="1">
          <Row>
            <Col xs={1} lg={2} className="my-auto pr-0">
              Delay
            </Col>
            <Col xs={6} md={8} xl={5}>
              <Form.Row>
                <Col className="my-auto text-right d-flex justify-content-end align-items-center">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 50 }}
                    overlay={
                      <Tooltip id="delay=tooltip">
                        {`${
                          taskIndex === 0
                            ? 'Select number of days before you want your first task(s) to be created!'
                            : 'Select number of days to delay this task(s) from the previous one!'
                        }`}
                      </Tooltip>
                    }
                  >
                    <FaInfoCircle
                      size={'1.5em'}
                      color={'#34d1b6'}
                      className="mr-2"
                    />
                  </OverlayTrigger>
                  {`${
                    taskIndex === 0
                      ? 'Delay for first task(s)'
                      : 'Delay from previous task(s)'
                  }`}
                </Col>
                <Col xl={3}>
                  <Form.Control
                    as="select"
                    disabled={immediately}
                    value={getDelayOffset()}
                    onChange={(e) =>
                      updateDays(parseInt(e.target.value), parseInt(step[0]))
                    }
                  >
                    {dayRange().map((x) => (
                      <option value={x} key={x}>
                        {x}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
                <Col xs={1} md={2} className="my-auto">
                  Days
                </Col>
              </Form.Row>
            </Col>
            <Col xs="4" md={3} className="d-flex align-items-center px-0">
              {taskIndex == 0 && (
                <SequenceCheckbox
                  label="Schedule Immediately"
                  checked={immediately}
                  onChange={(e) => updateImmediately(e.target.checked)}
                />
              )}
            </Col>
          </Row>
          <hr />
          {isLoading ? (
            <Row>
              <Col className="text-center p-5">
                <ClipLoader size={100} color="#34D1B6" />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs="2" className="mt-3">
                Action
              </Col>
              <Col xs="10">
                {messageTemplates &&
                  actions.map((action, actionIndex) => (
                    <StepAction
                      getFirstTemplateId={getFirstTemplateId}
                      taskActionSet={taskActionSet}
                      messageTemplates={messageTemplates}
                      dispatch={dispatch}
                      setIsLoading={setIsLoading}
                      deleteAction={deleteAction}
                      action={action}
                      actionIndex={actionIndex}
                      taskIndex={taskIndex}
                      key={actionIndex}
                    />
                  ))}
                {actions.length < 3 && (
                  <Row>
                    <Col xs={{ offset: 1, span: 3 }}>
                      <Button variant="light" onClick={addAction}>
                        + Add Action
                      </Button>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          )}
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
};

const SequenceShow = () => {
  let { sequenceId } = useParams();
  const history = useHistory();
  const { id: orgId } = useOrg();

  const [sequence, setSequence] = useState({});
  const [taskSequences, setTaskSequences] = useState([]);
  const [nextTaskSequenceId, setNextTaskSequenceId] = useState();
  const [mailboxId, setMailboxId] = useState();
  const [steps, setSteps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [renderedSteps, renderedStepsDispatch] = useRenderedSteps();
  const [groupedRenderedSteps, setGroupedRenderedSteps] = useState();
  const [daysAfterSet, setDaysAfterSet] = useState();
  const [destroyed, setDestroyed] = useState([]);
  const [error, setError] = useState(false);
  const [aiProfileId, setaiProfileId] = useState();

  const {
    data: messageTemplates,
    isLoading: isLoadingMessageTemplates,
    isSuccess: isMessageTemplateListSuccess,
  } = useMessageTemplatesList([['organization_id[]', orgId]]);

  const {
    data: showResponse,
    isLoading: isLoadingSequence,
    isError: isSequenceShowError,
  } = useTaskSequenceShow(sequenceId);

  const {
    data: taskSequencesList,
    isLoading: isTaskSequenceLoading,
    isError: isTaskSequenceErrored,
  } = useTaskSequencesList([['order', 'name']]);

  const {
    mutate: editTaskSequence,
    isSuccess: didSucceedMutatingTaskSequence,
    isError: didFailMutatingTaskSequence,
    isLoading: isMutatingTaskSequence,
  } = useTaskSequenceEdit();

  const loadSequences = () => {
    setIsLoading(true);
    if (taskSequencesList && taskSequencesList?.length) {
      setTaskSequences(
        taskSequencesList
          .sort((a, b) => a.name - a.name || a.is_locked - b.is_locked)
          .reverse()
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadSequences();
  }, [isTaskSequenceLoading]);

  const loadSequence = () => {
    setIsLoading(true);
    if (showResponse !== undefined) {
      setSequence(showResponse);
      if (isSequenceShowError) {
        sendToSequencesOnError(showResponse);
      } else {
        setSteps(showResponse.steps);
        setName(showResponse.name);
        setSequence(showResponse);
        setMailboxId(showResponse.email_mailbox_id);
        setNextTaskSequenceId(showResponse.next_task_sequence_id);
        setaiProfileId(showResponse.ai_profile_id);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadSequence();
  }, [showResponse, sequence]);

  useEffect(() => {
    initializeRenderedSequences();
  }, [steps]);

  useEffect(() => {
    if (mailboxId !== sequence.email_mailbox_id) {
      setIsEditing(true);
    }
  }, [mailboxId]);

  useEffect(() => {
    if (nextTaskSequenceId !== sequence.next_task_sequence_id) {
      setIsEditing(true);
    }
  }, [nextTaskSequenceId]);

  useEffect(() => {
    if (aiProfileId !== sequence.ai_profile_id) {
      setIsEditing(true);
    }
  }, [aiProfileId]);

  useEffect(() => {
    setGroupedRenderedSteps(getGroupedStepsArray(renderedSteps));
    setDaysAfterSet(new Set(renderedSteps?.map((step) => step.days_after)));
  }, [renderedSteps]);

  const sendToSequencesOnError = (response) => {
    history.push({ pathname: '/sequences', state: { error: response.error } });
  };

  const dispatch = (arg) => {
    if (!isEditing) setIsEditing(true);
    renderedStepsDispatch(arg);
  };

  const initializeRenderedSequences = () => {
    const stepsCopy = JSON.parse(JSON.stringify(steps));
    stepsCopy.forEach((step) => (step.editID = uuid()));
    renderedStepsDispatch({ type: INITIALIZE, payload: stepsCopy });
    setDestroyed([]);
  };

  const addTask = () => {
    const daysAfter = getNextDaysAfterInt(daysAfterSet);
    dispatch({
      type: ADD_STEP,
      payload: { actionType: 'Call', daysAfter, messageMessageTemplateId: null },
    });
  };

  const saveTaskSequence = (e) => {
    setError(false);
    setIsLoading(true);
    setIsEditing(false);

    editTaskSequence({
      id: sequence.id,
      name: name,
      email_mailbox_id: mailboxId ? mailboxId : null,
      next_task_sequence_id: nextTaskSequenceId ? nextTaskSequenceId : null,
      task_sequence_steps: [...renderedSteps, ...destroyed],
      ai_profile_id: aiProfileId,
    });

    if (isSequenceShowError) {
      sendToSequencesOnError(showResponse);
    } else {
      setSteps(showResponse.steps);
      setName(showResponse.name);
      setSequence(showResponse);
      setIsLoading(false);
    }

    if (didFailMutatingTaskSequence) {
      setError(true);
    } else {
      toast.success('Your sequence was saved successfully.', {
        ...toastOptions,
      });
    }
  };

  const cancelEdit = () => {
    setName(sequence.name);
    setMailboxId(sequence.email_mailbox_id);
    setNextTaskSequenceId(sequence.next_task_sequence_id);
    setaiProfileId(sequence.ai_profile_id);
    initializeRenderedSequences();
    setIsEditing(false);
  };

  const updateName = (e) => {
    setIsEditing(true);
    setName(e.target.value);
  };
  const getActionsForStep = (step) => {
    return JSON.parse(JSON.stringify(step[1]));
  };

  return (
    <>
      <Prompt
        when={isEditing}
        message={(location) => {
          if (location.pathname.includes('logout')) return true;
          return 'You have unsaved changes, are you sure you would like to leave this page?';
        }}
      />
      {isLoading ? (
        <Row>
          <Col className="text-center p-5">
            <ClipLoader size={100} color="#34D1B6" />
          </Col>
        </Row>
      ) : (
        <>
          <Row className={'mt-0'}>
            <Col xs={12}>
              <nav aria-label="breadcrumb">
                <ol className="sigma-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/settings">Settings</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/settings/sequences">Sequences</Link>
                  </li>
                  <li className="breadcrumb-item active">{sequence.name}</li>
                </ol>
              </nav>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form>
                <Form.Group as={Row}>
                  <Form.Label column xs={3} md={2} lg={1} xl={2}>
                    Name
                  </Form.Label>
                  {sequence.is_locked ? (
                    <Col>
                      <Form.Control value={sequence.name} plaintext readOnly />
                    </Col>
                  ) : (
                    <>
                      <Col xs={9} md={10} lg={11} xl={10}>
                        <Form.Control onChange={updateName} value={name} />
                      </Col>
                    </>
                  )}
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Alert
            className="w-100"
            show={error}
            variant="danger"
            onClose={() => setError(false)}
            dismissible
          >
            Sorry, there was an error saving your changes. Please make your desired
            edits and try again.
          </Alert>
          {isMessageTemplateListSuccess &&
            groupedRenderedSteps?.map((step, index) => {
              return (
                <TaskRow
                  daysAfter={step[0]}
                  actions={getActionsForStep(step)}
                  dispatch={dispatch}
                  messageTemplates={messageTemplates}
                  setDestroyed={setDestroyed}
                  daysAfterSet={daysAfterSet}
                  step={step}
                  nonGroupedSteps={renderedSteps}
                  groupedSteps={groupedRenderedSteps}
                  taskIndex={index}
                  key={index}
                />
              );
            })}
          <Row className="mx-0 mt-3">
            <Col className="px-0" xs={8}>
              <button className="btn btn-sigma-primary" onClick={() => addTask()}>
                + Add Task
              </button>
            </Col>
          </Row>
          <CompletionBehaviorConfig
            sequenceId={sequenceId}
            taskSequences={taskSequences}
            nextTaskSequenceId={nextTaskSequenceId}
            setNextTaskSequenceIdHandler={setNextTaskSequenceId}
          />
          <AutomationsConfig
            mailboxId={mailboxId}
            setMailboxIdHandler={setMailboxId}
            sequence={sequence}
          />
          <AIAgent
            aiProfileId={aiProfileId}
            setaiProfileIdHandler={setaiProfileId}
          />
          <Row className="mx-0 mt-3">
            <Col xs={8} />
            <Col xs={2}>
              <Button
                disabled={!isEditing}
                onClick={saveTaskSequence}
                className="btn btn-sigma-primary btn-block"
              >
                Save
              </Button>
            </Col>
            <Col xs={2}>
              <Button
                disabled={!isEditing}
                onClick={cancelEdit}
                className="btn btn-light btn-block"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default SequenceShow;

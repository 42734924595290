export function handleApiErrors(response) {
  if (response.ok) {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return response.json();
    } else {
      return null;
    }
  }
  throw response;
}

export async function apiCatchResponse(error) {
  const errorResponse = await error.json();
  if (typeof errorResponse === 'object' && errorResponse !== null) {
    let errorFormat = '';
    Object.keys(errorResponse).map((err, index) => {
      errorFormat =
        errorFormat +
        `${err}: ${
          Array.isArray(errorResponse[err])
            ? errorResponse[err][0]
            : errorResponse[err]
        } `;
    });
    return errorFormat;
  } else {
    return errorResponse;
  }
}

import { cloneElement, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Container } from '../common/Container';
import { ArrowDownIcon } from '../../../theme/icons';
import { availableOptions } from './utils';

export default function InsertStep({ handleInsertComponent, insertAfterId }) {
  const [showList, setShowList] = useState(false);

  return (
    <section
      className={'insertStep'}
      style={{
        marginTop: '-40px',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
      }}
    >
      <ArrowDownIcon />
      {showList ? (
        <Box sx={{ width: '100%' }}>
          <Container
            title={availableOptions.title}
            isListOptions={availableOptions.isListOptions}
          >
            <Box>
              <Typography
                textAlign="center"
                variant="h4"
                sx={{ paddingTop: '25px' }}
              >
                What step would you like to insert ?
              </Typography>
              <Box onClick={() => setShowList(false)}>
                {availableOptions.component &&
                  cloneElement(availableOptions.component, {
                    handleInsertComponent,
                    insertAfterId,
                  })}
              </Box>
            </Box>
          </Container>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Button
            sx={{
              border: '1px solid black',
              padding: '10px',
              margin: '20px',
              borderRadius: '20px',
              width: '150px',
              '&:hover': {
                background: 'transparent',
              },
            }}
            variant="text"
            onClick={() => setShowList(true)}
          >
            Insert step here
          </Button>
        </Box>
      )}
      <ArrowDownIcon />
    </section>
  );
}

import Cookies from 'js-cookie';
import { toJSON, toParamString } from '../queryClientConfig';

export default class Task {
  static async create(task) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/tasks`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(task),
    }).then(toJSON);
  }

  static async list(filters) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/v2/tasks?${toParamString(filters)}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async show(id) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/tasks/${id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(toJSON);
  }

  static async edit(task) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/tasks/${task.id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(task),
    }).then(toJSON);
  }

  static async delete(task_id) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/tasks/${task_id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
}

import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { JWT_TOKEN, LOGIN_REDIRECT } from '../util/AuthUtil';
import Cookies from 'js-cookie';

function CognitoCallback() {
  let history = useHistory();
  let query = useLocation().hash;
  // Replace hash with question mark to be parsed by URLSearchParams
  const params = new URLSearchParams('?' + query.substring(1));
  const accessToken = params.get('access_token');

  useEffect(() => {
    Cookies.set('new-login', 'true');
    Cookies.set(JWT_TOKEN, accessToken, {
      domain: window.location.hostname.replace(/^[^.]+\./g, ''),
    });
    // Prevent users from going to this page in history
    history.replace('/');
    history.push(localStorage.getItem(LOGIN_REDIRECT) ?? '/');
    localStorage.removeItem(LOGIN_REDIRECT);
  }, [history, accessToken]);

  return <></>;
}

export default CognitoCallback;

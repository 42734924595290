// TODO HookForm
import { useSourcesList } from '../../MUI/services/sources/queries';
import { useTaskSequencesList } from '../services/taskSequences/queries';
import {
  useSourcesEdit,
  useSourcesDelete,
  useSourcesCreate,
} from '../../api/sources/mutations';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { useForm } from 'react-hook-form';
import HookForm, { FormProvider } from '../components/HookForm';
import { fireDialog } from '../components/Dialog';
import { DeleteIcon, EditIcon, PlusIcon } from '../theme/icons';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  Box,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { yupFieldValidationsMap } from '../components/HookForm/yupValidations';
import { CELL_HEIGHT_MD, COL_WIDTH_TH1, COL_WIDTH_TH2 } from '../theme/fixedValues';
import { useWatch } from '../components/HookForm/hooks';
import { useOrg } from '../../contexts/OrgProvider';

const LEAD_SOURCES_SELECT = 'leadSourcesSelect';
const SOURCE_NAME = 'sourceName';
const NO_SEQUENCE = 'No Sequence';
const TASK_SEQUENCE = 'task_sequence';
const AI_ENABLED = 'ai_enabled';

const LeadSourcesSettingsPage = () => {
  const { id: orgId } = useOrg();
  /* ANCHOR LeadSourcesSettingsPage */
  const {
    data: sources,
    isFetching: isFetchingSources,
    isError: didFailLoadingSources,
  } = useSourcesList([['organization_id[]', orgId]]);
  const {
    data: taskSequences,
    isLoading: isLoadingTaskSequences,
    isError: didFailLoadingTaskSequences,
  } = useTaskSequencesList([['organization_id[]', orgId]]);

  const findSequenceById = (findId) => taskSequences.find(({ id }) => id === findId);

  /* ANCHOR Handle Clicks */

  const handleDeleteSourceClick = (source) => {
    fireDialog((promiseParams) => DeleteSourceDialog({ ...promiseParams, source }));
  };

  const handleEditSourceClick = (source) => {
    fireDialog((promiseParams) => EditSourceDialog({ ...promiseParams, source }));
  };

  const handleAddLeadSourceClick = () => fireDialog(AddLeadSourceDialog);

  /* ANCHOR  Render */

  return (
    <SettingsCard showLoading={isFetchingSources || isLoadingTaskSequences}>
      <SettingsCard.Header>Default Lead Source</SettingsCard.Header>
      <SettingsCard.SubHeader>
        Unmapped lead sources will be marked as coming from this lead source instead.
      </SettingsCard.SubHeader>
      <SettingsCard.Main>
        {(didFailLoadingTaskSequences || didFailLoadingSources) && (
          <Alert severity="error">
            There was a problem loading your lead sources.
          </Alert>
        )}
        {!!sources && !didFailLoadingSources && !didFailLoadingTaskSequences && (
          <LeadSourcesSelectForm />
        )}
      </SettingsCard.Main>
      <SettingsCard.Divider />
      <SettingsCard.Header>Lead Sources</SettingsCard.Header>
      <SettingsCard.SubHeader>
        Assign leads to sequences based on their lead source. When a lead is assigned
        a source automatically through an integration, the automations of the
        corresponding task sequence will be initiated immediately.
      </SettingsCard.SubHeader>
      <SettingsCard.Main>
        {didFailLoadingTaskSequences || didFailLoadingSources ? (
          <Alert severity="error">
            There was a problem loading your lead sources.
          </Alert>
        ) : (
          <>
            <TableContainer sx={{ mb: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell width={COL_WIDTH_TH2}>Lead Source</TableCell>
                    <TableCell width={COL_WIDTH_TH2}>Task Sequence</TableCell>
                    <TableCell width={COL_WIDTH_TH1}>AI</TableCell>
                    <TableCell width={1} />
                    <TableCell width={1} />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {taskSequences &&
                    sources?.map((source) => {
                      return (
                        <TableRow key={source.id}>
                          <TableCell height={CELL_HEIGHT_MD}>
                            {source.name}
                          </TableCell>
                          <TableCell>
                            {findSequenceById(source.task_sequence_id)?.name ??
                              NO_SEQUENCE}
                          </TableCell>
                          <TableCell>
                            {source.ai_enabled ? (
                              <span>&#x2705;</span>
                            ) : (
                              <span>&#9744;</span>
                            )}
                          </TableCell>
                          <TableCell width={1}>
                            <Button
                              startIcon={<EditIcon />}
                              variant="text"
                              color="primary"
                              onClick={() => handleEditSourceClick(source)}
                            >
                              Edit
                            </Button>
                          </TableCell>
                          <TableCell width={1}>
                            <Button
                              startIcon={<DeleteIcon />}
                              sx={{ ml: 1 }}
                              variant="text"
                              color="error"
                              onClick={() => handleDeleteSourceClick(source)}
                              disabled={source.is_default}
                            >
                              Delete
                            </Button>
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <Button startIcon={<PlusIcon />} onClick={handleAddLeadSourceClick}>
              Add New Lead Source
            </Button>
          </>
        )}
      </SettingsCard.Main>
    </SettingsCard>
  );
};

export default LeadSourcesSettingsPage;

/* ANCHOR Components */

const LeadSourcesSelectForm = () => {
  const { id: orgId } = useOrg();
  const { data: sources } = useSourcesList([['organization_id[]', orgId]]);
  const {
    mutate: editSources,
    error,
    isLoading: isMutatingDefaultSource,
  } = useSourcesEdit();

  // TODO -need! -forms See comments below
  const methods = useForm({
    defaultValues: {
      [LEAD_SOURCES_SELECT]: sources.find((f) => {
        return f.is_default == true;
      })?.id,
    },
  });
  const { handleSubmit, watch } = { ...methods };

  const onSelectDefaultSourceSubmit = (formData) => {
    const selectedSource = sources.find(
      ({ id }) => id === formData[LEAD_SOURCES_SELECT]
    );
    editSources({
      ...selectedSource,
      is_default: true,
    });
  };

  //TODO -need! -forms This should almost certainly be switched to controled Mui Select. See readme in HookForm
  //TODO or at least moved into some kind of 'ApiCallOnSelect' component
  useWatch(() => handleSubmit(onSelectDefaultSourceSubmit)(), watch);

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSelectDefaultSourceSubmit)}
    >
      <FormProvider.Select
        error={error}
        helperText={error && 'There was a problem saving this field.'}
        name={LEAD_SOURCES_SELECT}
        label="Default Source"
      >
        {sources
          .sort((a) => (a.is_default ? -1 : 1))
          .map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
      </FormProvider.Select>
      {isMutatingDefaultSource && <CircularProgress />}
    </FormProvider>
  );
};

/* ANCHOR Edit Dialog */

// --------------------------------------------------------
const EditSourceDialog = ({ isOpen, onResolve, onReject, source }) => {
  const { id: orgId } = useOrg();
  const {
    mutate: editSource,
    isLoading: isMutatingSource,
    isSuccess: didSucceedMutatingSource,
    isError: didFailMutatingSource,
  } = useSourcesEdit();
  const { data: taskSequences } = useTaskSequencesList([
    ['organization_id[]', orgId],
  ]);

  const onEditSourceSubmit = (formData) => {
    editSource({
      ...source,
      name: formData[SOURCE_NAME],
      task_sequence_id: formData[TASK_SEQUENCE],
    });
  };

  useEffect(() => {
    if (didSucceedMutatingSource && !didFailMutatingSource) onResolve();
  }, [didSucceedMutatingSource, didFailMutatingSource]);

  const defaultValues = {
    [SOURCE_NAME]: source.name,
    [TASK_SEQUENCE]: source.task_sequence_id,
  };

  const useFormProps = {
    defaultValues,
    resolver: yupResolver(
      yup.object({
        [SOURCE_NAME]: yupFieldValidationsMap.requiredString,
        [TASK_SEQUENCE]: yup.string().nullable(),
      })
    ),
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Edit Source</DialogTitle>
      <HookForm
        onSubmit={onEditSourceSubmit}
        useFormProps={useFormProps}
        useDirtyFormCheck
      >
        <DialogContent>
          {didFailMutatingSource && (
            <Alert severity="error">
              There was a problem editing your lead source. Try again.
            </Alert>
          )}
          <Box display="grid" direction="column" rowGap={2}>
            <HookForm.TextField name={SOURCE_NAME} label="Source Name" />
            <HookForm.Select name={TASK_SEQUENCE} label="Task Sequence">
              <MenuItem value={null}>None</MenuItem>
              {taskSequences?.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </HookForm.Select>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="cancel" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton loading={isMutatingSource} type="submit">
            Confirm
          </LoadingButton>
        </DialogActions>
      </HookForm>
    </Dialog>
  );
};

/* ANCHOR Add Dialog */

const AddLeadSourceDialog = ({ isOpen, onResolve, onReject }) => {
  const { id: orgId } = useOrg();
  const {
    mutate: createSource,
    isLoading: isMutatingSource,
    isSuccess: didSucceedMutatingSource,
    isError: didFailMutatingSource,
  } = useSourcesCreate();
  const { data: taskSequences } = useTaskSequencesList([
    ['organization_id[]', orgId],
  ]);

  useEffect(() => {
    if (didSucceedMutatingSource && !didFailMutatingSource) onResolve();
  }, [didSucceedMutatingSource, didFailMutatingSource]);

  const onCreateSourceSubmit = (formData) => {
    createSource({ name: formData[SOURCE_NAME] });
  };
  const defaultValues = { [SOURCE_NAME]: '' };

  const useFormProps = {
    defaultValues,
    resolver: yupResolver(
      yup.object({ [SOURCE_NAME]: yupFieldValidationsMap.requiredString })
    ),
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Create Source</DialogTitle>
      <HookForm
        onSubmit={onCreateSourceSubmit}
        useFormProps={useFormProps}
        useDirtyFormCheck
      >
        <DialogContent>
          {didFailMutatingSource && (
            <Alert severity="error">
              There was a problem creating your lead source. Try again.
            </Alert>
          )}
          <HookForm.TextField name={SOURCE_NAME} label="Source Name" />
        </DialogContent>
        <DialogActions>
          <Button variant="cancel" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton loading={isMutatingSource} type="submit">
            Confirm
          </LoadingButton>
        </DialogActions>
      </HookForm>
    </Dialog>
  );
};

/* ANCHOR delete dialog */

const DeleteSourceDialog = ({ isOpen, onResolve, onReject, source }) => {
  const {
    mutate: deleteSource,
    isSuccess: didSucceedMutatingSource,
    isError: didFailMutatingSource,
    isLoading: isMutatingSource,
  } = useSourcesDelete();

  useEffect(() => {
    if (didSucceedMutatingSource && !didFailMutatingSource) onResolve();
  }, [didSucceedMutatingSource, didFailMutatingSource]);

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Delete Lead Source / {source.name}</DialogTitle>
      <DialogContent>
        {didFailMutatingSource && (
          <Alert severity="error">
            There was a problem deleting your lead source. Try again.
          </Alert>
        )}
        You are about to delete the following Lead Source. Any leads with this source
        will be moved to your default source. Metrics for this source will be
        removed. New leads from this source will come in as your default source until
        your integration is updated.
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          loading={isMutatingSource}
          onClick={() => deleteSource(source.id)}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import { useMutation, useQueryClient } from 'react-query';
import { mutationKeyFactory } from '../queryClientConfig';
import Schedules from './Schedules';
import { agentsQueryKeys } from '../agents/queries';

export const schedulesMutationKeys = {
  ...mutationKeyFactory('schedules'),
};

export const useSchedulesEdit = () => {
  const queryClient = useQueryClient();
  return useMutation(Schedules.edit, {
    mutationKey: schedulesMutationKeys.edit(),
    onSuccess: () => queryClient.invalidateQueries(agentsQueryKeys.shows()),
  });
};

export const useSchedulesDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(Schedules.delete, {
    mutationKey: schedulesMutationKeys.delete(),
    onSuccess: () => queryClient.invalidateQueries(agentsQueryKeys.shows()),
  });
};

export const useSchedulesCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(Schedules.create, {
    mutationKey: schedulesMutationKeys.create(),
    onSuccess: () => queryClient.invalidateQueries(agentsQueryKeys.shows()),
  });
};

import { useContext, useEffect, useState } from 'react';
import Metrics from '../clients/Metrics';
import AppContext from '../contexts/AppContext';

const useMetricsQuery = ({ metricKey, startDate, endDate }) => {
  const { user } = useContext(AppContext);
  const [currentData, setCurrentData] = useState(null);
  const [previousData, setPreviousData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (user.id && !!startDate && !!endDate) {
      setIsLoading(true);
      Metrics.list({
        key: metricKey,
        startDate,
        endDate,
        page_size: 1000,
      })
        .then((res) => {
          setIsLoading(false);
          setCurrentData(res.items);
        })
        .catch((error) => {
          setError(error);
          setIsLoading(false);
        });
    }
  }, [user, startDate, endDate]);

  return [currentData, isLoading, previousData, error];
};

export default useMetricsQuery;

import { useQuery } from 'react-query';
import { queryKeyFactory } from '../../MUI/services/queryClientConfig';
import AIProfile from './AIProfile';

export const aiprofileQueryKeys = queryKeyFactory('ai-profile');

export const useAiProfileList = (params, options) => {
  return useQuery(aiprofileQueryKeys.list(), () => AIProfile.list(params), {
    staleTime: 0,
    ...options,
  });
};

export const useAiProfileShow = (agentId, options) => {
  return useQuery(aiprofileQueryKeys.list(agentId), () => AIProfile.show(agentId), {
    staleTime: 0,
    ...options,
  });
};

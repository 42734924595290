import Cookies from 'js-cookie';
import { toJSON } from '../queryClientConfig';

export default class Roles {
  static async list(filters) {
    const params = new URLSearchParams(filters).toString();
    return await fetch(`${process.env.REACT_APP_API_BASE}/roles?${params}`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
      },
    }).then(toJSON);
  }
}

import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import AIMappigs from './AIMappings';
import { aimappingsQueryKeys } from './queries';
import { aiprofileQueryKeys } from '../aiprofile/queries';

export const useAIMappingsCreate = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => AIMappigs.create(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(aimappingsQueryKeys.all);
      queryClient.invalidateQueries(aiprofileQueryKeys.all);
    },
  });
};

export const useAIMappingsEdit = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => AIMappigs.edit({ ...params }), {
    onSuccess: () => {
      queryClient.invalidateQueries(aimappingsQueryKeys.all);
      queryClient.invalidateQueries(aiprofileQueryKeys.all);
    },
  });
};

export const useAIMappingsDelete = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => AIMappigs.delete(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(aimappingsQueryKeys.all);
      queryClient.invalidateQueries(aiprofileQueryKeys.all);
    },
  });
};

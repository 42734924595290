import { Editor } from 'react-draft-wysiwyg';
import { EditorState, Modifier } from 'draft-js';
import { useEffect } from 'react';

const TemplateTextEditor = ({
  editorState,
  setEditorState,
  token,
  setToken,
  onFocusHandler,
  setEditorHasFocus,
  editorHasFocus,
  editorHasValidText,
  setEditorHasValidText,
  mailMergeDropdown,
}) => {
  useEffect(() => {
    const oldHasValidTextCheck =
      editorState.getCurrentContent().getPlainText().trim() !== '';
    if (oldHasValidTextCheck !== editorHasValidText)
      setEditorHasValidText(oldHasValidTextCheck);
  }, [editorState]);

  useEffect(() => {
    if (token) insertToken(token);
  }, [token]);

  const insertToken = (token) => {
    if (editorHasFocus) {
      const currentContent = editorState.getCurrentContent();
      const currentSelection = editorState.getSelection();
      const newContent = Modifier.replaceText(
        currentContent,
        currentSelection,
        token + ' '
      );
      setEditorState(EditorState.push(editorState, newContent, 'insert-characters'));
      setEditorHasFocus(!!mailMergeDropdown);
      setToken();
    }
  };

  return (
    <Editor
      id="templates-editor"
      onFocus={onFocusHandler}
      onBlur={(e) => {
        if (e.relatedTarget?.id !== 'token-button') setEditorHasFocus(false);
      }}
      editorState={editorState}
      onEditorStateChange={setEditorState}
      toolbarCustomButtons={mailMergeDropdown ? [mailMergeDropdown] : null}
      toolbar={{
        options: [
          'inline',
          'blockType',
          'fontSize',
          'list',
          'textAlign',
          'history',
          'colorPicker',
          'link',
          'image',
        ],
        inline: { inDropdown: true },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true },
        history: { inDropdown: true },
        colorPicker: { inDropdown: true },
        link: { inDropdown: true },
        image: {
          urlEnabled: true,
          inDropdown: true,
        },
      }}
      editorClassName="editor-class"
      wrapperClassName="wrapper-class"
    />
  );
};

export default TemplateTextEditor;

import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Alert,
  MenuItem,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tab,
  Typography,
  Grid,
  TextField,
  DialogContentText,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import LoadingButton from '@mui/lab/LoadingButton';
import CallTrackingWizard from './CallTrackingWizard';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { DeleteIcon, EditIcon, PlusIcon } from '../theme/icons';
import { DataGridStyle } from '../theme/styled/DataGridStyle';
import { CellSpanStyle } from '../theme/styled/CellSpanStyle';
import CustomFooter from '../components/CallTracking/CustomFooter';
import ClipLoader from 'react-spinners/ClipLoader';
import { FormProvider } from '../components/HookForm';
import parsePhoneNumber from 'libphonenumber-js';
import { fireDialog } from '../components/Dialog';
import { fireConfirmDialog } from '../components/Dialog';
import { CallTrackingUpsellModal } from '../components/CallTracking/UpsellModal';
import { useTeamsList } from '../services/teams/queries';
import { usePhoneIDList } from '../services/phoneIds/queries';
import { useTargetsList } from '../services/targets/queries';
import { useGetCallFlowList } from '../../api/callFlow/queries';
import { useOrg } from '../../contexts/OrgProvider';
import { usePhoneIdEdit, usePhoneIdDelete } from '../services/phoneIds/mutations';
import { useTargetsEdit, useTargetsDelete } from '../services/targets/mutations';
import { toParamString } from '../services/queryClientConfig';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { string } from 'yup';
import { useOrganizationServices } from '../services/organizations/queries';
import {
  formatPhoneNumberE164,
  formatPhoneNumber,
} from '../services/textFormatting';
import { usePhonesDelete } from '../../api/phones/mutations';
import { useQueryClient } from 'react-query';
import { phoneIdQueryKeys } from '../services/phoneIds/queries';

const TARGET_NAME = 'targetName';
const TARGET_PHONE = 'targetPhone';
const SWAP_PHONES_CALL_FLOW_ID = 'swapPhonesCallFlowId';
const CALL_FLOW_ID = 'callFlowId';
const TARGET_POOL_SIZE = 'targetPoolSize';
const TEAM_ID = 'teamId';

const formatPhone = (phone) => {
  if (!phone) {
    return;
  }

  const phoneNumber = parsePhoneNumber(phone);
  if (phoneNumber) {
    return phoneNumber.formatNational();
  }
  return phone;
};

export default function CallTrackingList() {
  const { id: orgId, isMasterAccount } = useOrg();
  const [isCallTrackingEnabled, setIsCallTrackingEnabled] = useState(true);
  const { data: organizationServicesList } = useOrganizationServices(orgId);

  const [currentTab, setCurrentTab] = useState('Tracking Phones');
  const [targetsPageSize, setTargetsPageSize] = useState(25);
  const [phonesPageSize, setPhonesPageSize] = useState(25);
  const [targetsPage, setTargetsPage] = useState(1);
  const [phonesPage, setPhonesPage] = useState(1);
  const [sortPhonesModel, setSortPhonesModel] = useState([
    { field: 'created_at', sort: 'desc' },
  ]);
  const [sortTargetsModel, setSortTargetsModel] = useState([
    { field: 'created_at', sort: 'desc' },
  ]);
  const getTargetsSorting = () => {
    if (sortTargetsModel.length === 0) {
      return [];
    }
    return [
      ['order', `targets.${sortTargetsModel[0].field}`],
      ['direction', sortTargetsModel[0].sort],
    ];
  };
  const createTargetsFilters = () => {
    const filters = [
      ['page', targetsPage],
      ['page_size', targetsPageSize],
      ...getTargetsSorting(),
    ];
    if (!isMasterAccount) {
      filters.push(['organization_id', orgId]);
    }
    return filters;
  };
  const {
    isTeamFetching: isTeamFetching,
    isTeamError: isTeamError,
    data: teamsList,
  } = useTeamsList([['organization_id[]', orgId]]);
  const {
    isFetching: isTargetsFetching,
    isError: isTargetsError,
    data: targetsList,
    isLoading: isTagetsLoading,
  } = useTargetsList(createTargetsFilters());
  const history = useHistory();

  const getPhonesSorting = () => {
    if (sortPhonesModel.length === 0) {
      return [];
    }
    return [
      ['order', `phones.${sortPhonesModel[0].field}`],
      ['direction', sortPhonesModel[0].sort],
    ];
  };

  const createPhonesFilters = () => {
    const filters = [
      ['page', phonesPage],
      ['page_size', phonesPageSize],
      ...getPhonesSorting(),
    ];
    if (!isMasterAccount) {
      filters.push(['organization_id', orgId]);
    }
    return filters;
  };

  const {
    isFetching: isPhonesFetching,
    isError: isPhonesError,
    data: phoneIdList,
    isLoading: isPhonesLoading,
  } = usePhoneIDList([...createPhonesFilters(), ['phone_pool_presence', false]]);
  const { data: callFlowList } = useGetCallFlowList([['organization_id', orgId]]);

  const [mergedData, setMerged] = useState([]);
  const { mutate: deletePhone } = usePhoneIdDelete();
  const { mutate: deleteTarget } = useTargetsDelete();

  useEffect(() => {
    if (organizationServicesList) {
      const isEnabled = organizationServicesList.some(
        (service) => service.service_id == 3
      );
      setIsCallTrackingEnabled(isEnabled);
    }
  }, [organizationServicesList]);

  useEffect(() => {
    if (phoneIdList && teamsList) {
      const newMergedData = [];

      phoneIdList?.items.forEach((phone) => {
        teamsList.forEach((team) => {
          if (phone.team_id === String(team.id)) {
            newMergedData.push({
              id: phone.id,
              team_id: Number(phone.team_id),
              team_name: team.name,
              phone_number: phone.number,
              call_flow_id: phone.call_flow_id,
              call_flow_name: phone.call_flow_name,
              phone_sid: phone.phone_sid,
            });
          }
        });
      });
      setMerged(newMergedData);
    }
  }, [phoneIdList, teamsList]);

  const formattedTrackingNumbers = (target) => {
    return target.swap_phone_ids.length > 1 ? (
      <Link to={`/settings/call-tracking/${target.id}`}>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ textDecoration: 'underline' }}
        >
          {`${target.swap_phone_ids.length} Numbers`}
        </Typography>
      </Link>
    ) : target.swap_phone_ids.length == 1 ? (
      <Link to={`/settings/call-tracking/${target.id}`}>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ textDecoration: 'underline' }}
        >
          {formatPhone(target.next_available_phone?.number)}
        </Typography>
      </Link>
    ) : (
      '-'
    );
  };

  const onDeleteClick = (itemId) => {
    if (currentTab == 'Tracking Phones') {
      fireConfirmDialog({
        title: 'Delete Target',
        message: `This is not reversible. Are you sure?`,
      }).then(() => deleteTarget(itemId));
    } else if (currentTab == 'Phone Numbers') {
      fireConfirmDialog({
        title: 'Delete Phone',
        message: `This is not reversible. Are you sure?`,
      }).then(() => deletePhone(itemId));
    }
  };

  const handleChangeTab = (_, tabName) => {
    setCurrentTab(tabName);
  };

  const handleEditTargetsClick = (
    item,
    teamsList,
    hook,
    currentTab,
    callFlowList
  ) => {
    fireDialog((promiseParams) =>
      EditDialog({
        item,
        teamsList,
        hook,
        currentTab,
        callFlowList,
        promiseParams,
      })
    );
  };

  const buildTable = () => {
    const generateTableBody = () => {
      return (
        <>
          {currentTab === 'Tracking Phones' && Boolean(targetsList?.items.length) && (
            <DataGridStyle
              rows={targetsList?.items ?? []}
              columns={targetsColumns}
              loading={isTargetsFetching}
              autoHeight
              density="compact"
              sx={{
                '& .MuiDataGrid-row': {
                  cursor: 'default',
                },
              }}
              components={{
                Footer: () => (
                  <CustomFooter
                    rowCount={targetsList?.total_count ?? 0}
                    page={targetsPage - 1}
                    onPageChange={(targetsPage) => setTargetsPage(targetsPage + 1)}
                    onPageSizeChange={setTargetsPageSize}
                    pageSize={targetsPageSize}
                    organization_id={orgId}
                    requested_url={`${
                      process.env.REACT_APP_IVR
                    }/targets?${toParamString(createTargetsFilters())}`}
                  />
                ),
              }}
            />
          )}
          {currentTab === 'Phone Numbers' && Boolean(mergedData?.length) && (
            <DataGridStyle
              rows={mergedData ?? []}
              columns={phonesColumns}
              loading={isPhonesFetching}
              autoHeight
              density="compact"
              sx={{
                '& .MuiDataGrid-row': {
                  cursor: 'default',
                },
              }}
              components={{
                Footer: () => (
                  <CustomFooter
                    rowCount={phoneIdList?.total_count ?? 0}
                    page={phonesPage - 1}
                    onPageChange={(phonesPage) => setPhonesPage(phonesPage + 1)}
                    onPageSizeChange={setPhonesPageSize}
                    pageSize={phonesPageSize}
                    organization_id={orgId}
                    requested_url={`${
                      process.env.REACT_APP_IVR
                    }/phones?${toParamString(createPhonesFilters())}`}
                  />
                ),
              }}
            />
          )}
        </>
      );
    };

    return <Box sx={{ mt: 1, mb: 2 }}>{generateTableBody()}</Box>;
  };

  const [addNewCallTracking, setAddNewCallTracking] = useState(false);

  const targetsColumns = [
    {
      field: 'name',
      headerName: 'Number Name',
      flex: 1,
    },
    {
      field: 'swap_phone_ids',
      headerName: 'Tracking Number',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 140,
      renderCell: ({ row }) => (
        <CellSpanStyle>{formattedTrackingNumbers(row)}</CellSpanStyle>
      ),
    },
    {
      field: 'call_flow_name',
      headerName: 'Call Flow',
      flex: 1,
    },
    {
      field: 'target_phone',
      headerName: 'Swaps',
      flex: 1,
      minWidth: 140,
      renderCell: ({ formattedValue }) => formattedValue,
      valueFormatter: ({ value }) => (
        <CellSpanStyle>{formatPhone(value)}</CellSpanStyle>
      ),
    },
    {
      field: 'id',
      headerName: '',
      flex: 1,
      minWidth: 185,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <CellSpanStyle>
          <Button
            startIcon={<EditIcon />}
            size="small"
            variant="text"
            color="primary"
            onClick={() => {
              handleEditTargetsClick(
                row,
                teamsList,
                useTargetsEdit,
                currentTab,
                callFlowList
              );
            }}
            sx={{ marginRight: 1 }}
          >
            Edit
          </Button>
          <Button
            startIcon={<DeleteIcon />}
            size="small"
            variant="text"
            color="error"
            onClick={() => onDeleteClick(row.id)}
          >
            Delete
          </Button>
        </CellSpanStyle>
      ),
    },
  ];

  const phonesColumns = [
    {
      field: 'phone_number',
      headerName: 'Number',
      flex: 1,
      renderCell: ({ formattedValue }) => formattedValue,
      valueFormatter: ({ value }) => (
        <CellSpanStyle>{formatPhone(value)}</CellSpanStyle>
      ),
    },
    {
      field: 'team_name',
      headerName: 'Team',
      flex: 1,
    },
    {
      field: 'call_flow_name',
      headerName: 'Call Flow',
      flex: 1,
    },
    {
      field: 'id',
      headerName: '',
      flex: 1,
      minWidth: 185,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <CellSpanStyle>
          <Button
            startIcon={<EditIcon />}
            size="small"
            variant="text"
            color="primary"
            onClick={() => {
              handleEditTargetsClick(
                row,
                teamsList,
                usePhoneIdEdit,
                currentTab,
                callFlowList
              );
            }}
            sx={{ marginRight: 1 }}
          >
            Edit
          </Button>
          <Button
            startIcon={<DeleteIcon />}
            disabled={row?.phone_sid}
            size="small"
            variant="text"
            color="error"
            onClick={() => onDeleteClick(row.id)}
          >
            Delete
          </Button>
        </CellSpanStyle>
      ),
    },
  ];

  return (
    <SettingsCard>
      <CallTrackingUpsellModal callTrackingEnabled={isCallTrackingEnabled} />
      {addNewCallTracking ? (
        <CallTrackingWizard setAddNewCallTracking={setAddNewCallTracking} />
      ) : (
        <>
          <SettingsCard.Header>Call Tracking</SettingsCard.Header>
          <SettingsCard.Main>
            <TabContext value={currentTab}>
              <TabList onChange={handleChangeTab}>
                <Tab label="Phone Pools" value="Tracking Phones" />
                <Tab label="Phone Numbers" value="Phone Numbers" />
              </TabList>
            </TabContext>
            {isTargetsError || isPhonesError ? (
              <Alert severity="error" sx={{ mt: 1, mb: 2 }}>
                There was an error loading your call tracking numbers. Please refresh
                the page.
              </Alert>
            ) : (
              <>
                {isTagetsLoading || isPhonesLoading ? (
                  <Box display="flex" justifyContent="center">
                    <ClipLoader size={100} color="#34D1B6" />
                  </Box>
                ) : (
                  <>
                    {buildTable()}
                    <Button
                      startIcon={<PlusIcon />}
                      onClick={() => setAddNewCallTracking(!addNewCallTracking)}
                    >
                      Add New
                    </Button>
                  </>
                )}
              </>
            )}
          </SettingsCard.Main>
        </>
      )}
    </SettingsCard>
  );
}

const EditDialog = ({
  item,
  teamsList,
  hook,
  currentTab,
  callFlowList,
  promiseParams: { isOpen, onResolve, onReject },
}) => {
  const {
    mutate: editItem,
    isSuccess,
    isError: didFailMutatingItem,
    isLoading: isMutatingItem,
  } = hook();
  const [phone, setPhone] = useState('');
  const [releaseDialog, setReleaseDialog] = useState(false);
  const [phoneReleaseStatus, setPhoneReleaseStatus] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isSuccess) onResolve();
  }, [isSuccess]);

  useEffect(() => {
    if (item) {
      setPhone(item?.phone_number);
    }
  }, [item]);

  const onEditItemSubmit = (formData) => {
    const currentItems = { ...item };
    if (phoneReleaseStatus) {
      delete currentItems.phone_sid;
      delete currentItems.phone_number;
    }

    editItem({
      ...currentItems,
      name: formData[TARGET_NAME],
      target_phone: formatPhoneNumberE164(formData[TARGET_PHONE]),
      swap_phone_pool_size: formData[TARGET_POOL_SIZE],
      team_id: formData[TEAM_ID],
      swap_phones_call_flow_id: formData[SWAP_PHONES_CALL_FLOW_ID],
      call_flow_id: formData[CALL_FLOW_ID],
    });
  };

  const defaultValues = {
    [TARGET_NAME]: item.name,
    [TARGET_PHONE]: formatPhoneNumber(item.target_phone),
    [TARGET_POOL_SIZE]: item.swap_phone_ids?.length,
    [TEAM_ID]: item.team_id,
    [SWAP_PHONES_CALL_FLOW_ID]: item?.swap_phones_call_flow_id,
    [CALL_FLOW_ID]: item?.call_flow_id,
  };

  const methods = useForm({
    defaultValues,
    resolver:
      currentTab === 'Tracking Phones' &&
      yupResolver(
        yup.object({
          [TARGET_NAME]: string().required('This field is required'),
        })
      ),
  });
  const { handleSubmit } = { ...methods };

  const { mutate: deletePhone, isLoading: isReleasingPhone } = usePhonesDelete();

  const releasePhoneModal = () => {
    return (
      <Dialog open={releaseDialog} onClose={() => setReleaseDialog(false)}>
        <DialogTitle>Release phone number?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Releasing the phone number is irreversible. Are you sure you want to
            release the phone number?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setReleaseDialog(false)}>No</Button>
          <LoadingButton
            autoFocus
            loading={isReleasingPhone}
            disabled={isReleasingPhone}
            onClick={releasePhone}
          >
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  };

  const releasePhone = () => {
    setPhone('');
    deletePhone(item?.id, {
      onSuccess: () => {
        setPhoneReleaseStatus(true);
        queryClient.invalidateQueries(phoneIdQueryKeys.all);
        setReleaseDialog(false);
        setPhone('');
      },
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onReject}
      sx={{ '& .MuiPaper-root': { overflowY: 'visible' } }}
    >
      <DialogTitle>Edit {currentTab}</DialogTitle>
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(onEditItemSubmit)}
        useDirtyFormCheck={true}
      >
        <DialogContent>
          {currentTab === 'Tracking Phones' && (
            <Box display="grid" direction="column" rowGap={2}>
              <FormProvider.TextField name={TARGET_NAME} label={`Name`} />
              <FormProvider.TextField
                name={TARGET_POOL_SIZE}
                label={`Phone Pool Size`}
              />
              <FormProvider.TextField name={TARGET_PHONE} label={`Swaps`} />
              <FormProvider.Select
                sx={{ width: '100%' }}
                name={SWAP_PHONES_CALL_FLOW_ID}
                label="Call Flow"
              >
                {callFlowList?.items?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </FormProvider.Select>
            </Box>
          )}
          {currentTab === 'Phone Numbers' && (
            <>
              {releasePhoneModal()}
              <FormProvider.Select
                sx={{ width: '100%' }}
                name={TEAM_ID}
                label="Team"
              >
                {teamsList?.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </FormProvider.Select>
              <FormProvider.Select
                sx={{ width: '100%', margin: '20px 0' }}
                name={CALL_FLOW_ID}
                label="Call Flow"
              >
                {callFlowList?.items?.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </FormProvider.Select>
              <Grid item>
                <TextField
                  label="Phone"
                  size="small"
                  value={formatPhoneNumber(phone)}
                  disabled={phone}
                  disableClearable={!phone}
                />
                <Button
                  sx={{ margin: '5px 0' }}
                  variant="text"
                  onClick={() => setReleaseDialog(true)}
                  disabled={!phone}
                >
                  Release
                </Button>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="cancel" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton loading={isMutatingItem} type="submit">
            Confirm
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

import { Button } from '@mui/material';
import { FiMaximize2 } from 'react-icons/fi';

const MinimizedButton = (props) => {
  return (
    <Button {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FiMaximize2 style={{ fontSize: '16px', marginRight: '8px' }} />
        <div
          className="blink"
          style={{
            width: '10px',
            height: '10px',
            backgroundColor: 'red',
            borderRadius: '50%',
            display: 'inline-block',
            marginRight: '5px',
          }}
        />
        <span style={{ color: 'red' }}>Maximize</span>
      </div>
    </Button>
  );
};

export default MinimizedButton;

import { useMutation, useQueryClient } from 'react-query';
import webhookServices from './webhookServices';
import { calendarsQueryKeys } from '../calendars/queries';

export const webhookSubscribe = (options) => {
  const queryClient = useQueryClient();
  return useMutation(webhookServices.create, {
    mutationKey: ['id', 'nexhealth_subdomain'],
    onSuccess: () => {
      queryClient.invalidateQueries(calendarsQueryKeys.all);
    },
    ...options,
  });
};

export const webhookCancelSubscription = (options) => {
  const queryClient = useQueryClient();
  return useMutation(webhookServices.delete, {
    mutationKey: ['id', 'subscription_id', 'nexhealth_subdomain'],
    onSuccess: () => {
      queryClient.invalidateQueries(calendarsQueryKeys.all);
    },
    ...options,
  });
};

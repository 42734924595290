import {
  Box,
  FormControl,
  Typography,
  TextField,
  Button,
  Autocomplete,
} from '@mui/material';

import { useOrg } from '../../../contexts/OrgProvider';
import { useGetCallFlowList } from '../../../api/callFlow/queries';
import { useTeamsList } from '../../services/teams/queries';

export default function NumberCallFLowStep({
  handleCollect,
  collectedData,
  handleForwardNavigate,
  handleBackNavigate,
}) {
  const { id: orgId } = useOrg();
  const { data: teamsList } = useTeamsList([['organization_id[]', orgId]]);
  const { data: callFlowList } = useGetCallFlowList([
    ['organization_id', orgId],
    ['page', 1],
    ['page_size', 1000],
  ]);

  const normalizedList = (list) => {
    if (list) {
      return list.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    } else {
      return [];
    }
  };

  return (
    <Box mt={3} mb={5}>
      <h4>Number Call Flow</h4>
      <hr />
      <FormControl>
        <Box mt={1} display="flex" flexDirection="column">
          <Typography sx={{ marginBottom: '20px' }}>
            What should happen when a call is answered?
          </Typography>
          <Autocomplete
            disablePortal
            sx={{ width: '300px', marginBottom: '25px' }}
            options={normalizedList(callFlowList?.items)}
            value={collectedData?.numberCallFlowName || ''}
            onChange={(_, v) => {
              handleCollect('numberCallFlowName', v ? v.label : '');
              handleCollect('callFlowId', v ? v.value : '');
            }}
            noOptionsText="No call flows found"
            renderInput={(params) => <TextField {...params} label="Call Flow" />}
          />
          <Typography sx={{ marginBottom: '20px' }}>
            LeadSigma will create leads for missed calls, what team should these
            leads be assigned.
          </Typography>
          <Autocomplete
            disablePortal
            sx={{ width: '300px' }}
            options={normalizedList(teamsList)}
            value={collectedData?.teamName || ''}
            onChange={(_, v) => {
              handleCollect('teamName', v ? v.label : '');
              handleCollect('teamId', v ? v.value : '');
            }}
            renderInput={(params) => <TextField {...params} label="Team" />}
          />
        </Box>
      </FormControl>
      <Box display="flex" alignItems="center" justifyContent="space-between" mt={5}>
        <Button onClick={() => handleBackNavigate()}>Back</Button>
        <Button
          disabled={!(collectedData?.callFlowId && collectedData?.teamId)}
          onClick={() => handleForwardNavigate('numberSetup', 'numberCallFlow')}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

import { useQuery } from 'react-query';
import { queryKeyFactory } from '../../MUI/services/queryClientConfig';
import InstitutionsLists from './institutions';

export const instituteQueryKeys = {
  ...queryKeyFactory('institutions'),
};

export const useInstitutionsList = () => {
  return useQuery(instituteQueryKeys.list(), () => InstitutionsLists.list(), {
    staleTime: 1000 * 60 * 5,
  });
};

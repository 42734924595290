import { useQuery } from 'react-query';
import { ONE_HOUR } from '../../MUI/services/queryClientConfig';
import NotificationPreferences from './NotificationPreferences';

export const useNotificationPreferences = (user_id, options) => {
  const filterList = (data) => {
    const findType = (type) =>
      data.find(({ notification_option: { event_type } }) => event_type === type);

    const filteredList = [
      findType('email_inbound'),
      findType('text_inbound'),
      findType('call_inbound'),
      findType('lead_created'),
      findType('call_ai'),
    ].filter(Boolean);

    return filteredList;
  };

  return useQuery(
    ['notification-preferences'],
    () => NotificationPreferences.list(user_id),
    {
      staleTime: ONE_HOUR,
      select: filterList,
      ...options,
    }
  );
};

import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
// icon/logo
import blackLogo from '../../assets/images/black-logo.svg';
import greenTextLogo from '../../assets/images/green-text.svg';
import {
  HomeIcon,
  TasksIcon,
  LeadsIcon,
  ReportsIcon,
  SettingsIcon,
  SupportIcon,
  LogOutIcon,
  ActivityIcon,
  CollapseIcon,
  CallTrackingIcon,
} from '../theme/icons';
// MUI
import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Collapse,
  Fade,
  Badge,
  Chip,
  Divider,
  useMediaQuery,
  Link,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useLayout } from '../contexts/LayoutProvider';
import { useTasksDueToday } from '../../api/task/taskQueries';
import { NavLinkButtonStyle } from '../theme/styled/NavLinkButtonStyle';
import { wipeActiveOrg } from '../../util/UserUtils';
import { useEnterprisesShow } from '../services/enterprises/queries';
import { useUsersCurrent } from '../services/users/queries';
import { useOrg } from '../../contexts/OrgProvider';

const iconWidth = 'min-content';
const transition = 200;
const fullWidth = 230;

const SideNav = ({ setCollapsed, collapsed }) => {
  const { mobileNavIsOpen, setMobileNavIsOpen } = useLayout();
  const [smallLogo, setSmallLogo] = useState('');
  const [logo, setLogo] = useState('');
  const [logoStyle, setLogoStyle] = useState();
  const [smallLogoStyle, setSmallLogoStyle] = useState();
  const [hovered, setHovered] = useState(false);
  const [paperWidth, setPaperWidth] = useState(collapsed ? iconWidth : fullWidth);
  const isExpanded = hovered || !collapsed;
  const [enterpriseId, setEnterpriseId] = useState(null);

  const { enterpriseIds } = useOrg();
  const { data: enterprise } = useEnterprisesShow(enterpriseId, {
    enabled: enterpriseId !== null,
  });

  const { data: currentUser } = useUsersCurrent();

  useEffect(() => {
    if (!enterpriseIds) return;
    if (enterpriseIds.length === 1) {
      setEnterpriseId(enterpriseIds[0]);
    }
    if (enterpriseIds.length > 1) {
      setLogo(greenTextLogo);
      setSmallLogo(blackLogo);
      setLogoStyle({ height: 30, pl: 1, mt: 0.8 });
      setSmallLogoStyle({ height: 30 });
    }
  }, [enterpriseIds]);

  useEffect(() => {
    const hasLogo =
      enterprise &&
      enterprise.whitelabel_logo &&
      enterprise.whitelabel_logo.length > 0;
    if (enterprise && !hasLogo) {
      setLogo(greenTextLogo);
      setSmallLogo(blackLogo);
      setLogoStyle({ height: 30, pl: 1, mt: 0.8 });
      setSmallLogoStyle({ height: 30 });
    }
    if (hasLogo) {
      setLogo(enterprise.whitelabel_logo);
      setSmallLogo(enterprise.whitelabel_small_logo);
      setLogoStyle(JSON.parse(enterprise.whitelabel_logo_style || '{}'));
      setSmallLogoStyle(JSON.parse(enterprise.whitelabel_small_logo_style || '{}'));
    }
  }, [enterprise]);

  useEffect(() => {
    if (mobileNavIsOpen) setPaperWidth(fullWidth);
  }, [mobileNavIsOpen]);

  const drawerPaperStyles = ({ palette }) => ({
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      p: 1,
      overflow: 'hidden',
      position: 'fixed',
      width: collapsed ? paperWidth : fullWidth,
      boxSizing: 'border-box',
      backdropFilter: `blur(6px)`,
      WebkitBackdropFilter: `blur(6px)`,
      backgroundColor: alpha(palette.grey[100], 0.8),
    },
  });

  return (
    <Drawer
      open={
        useMediaQuery((theme) => theme.breakpoints.up('md')) ? true : mobileNavIsOpen
      }
      onClose={() => setMobileNavIsOpen(false)}
      onMouseOver={() => {
        setHovered(true);
        setPaperWidth(fullWidth);
      }}
      onMouseLeave={() => {
        setHovered(false);
        collapsed && setPaperWidth(iconWidth);
      }}
      variant={mobileNavIsOpen ? 'temporary' : 'persistent'}
      anchor="left"
      sx={drawerPaperStyles}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          component={NavLink}
          to="/"
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 1,
            height: 70,
            py: 1,
          }}
        >
          <Box component="img" src={smallLogo} sx={smallLogoStyle} />
          <Collapse
            in={isExpanded}
            timeout={transition}
            orientation="horizontal"
            unmountOnExit
          >
            <Box component="img" src={logo} sx={logoStyle} />
          </Collapse>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexBasis: '100%',
        }}
      >
        <List>
          {primaryRoutes(currentUser).map((route, i) => (
            <NavLinkButton {...route} isExpanded={isExpanded} key={i} />
          ))}
        </List>
        <List>
          {secondaryRoutes.map((route, i) => (
            <NavLinkButton {...route} isExpanded={isExpanded} key={i} />
          ))}
          <NavLinkButtonStyle
            component={Link}
            href={process.env.REACT_APP_LOGOUT_URL}
            onClick={() => wipeActiveOrg()}
          >
            <ListItemIcon sx={{ minWidth: '0px' }}>
              <LogOutIcon />
            </ListItemIcon>

            <Collapse
              in={isExpanded}
              orientation="horizontal"
              timeout={transition}
              unmountOnExit
            >
              <ListItemText
                sx={{
                  pl: 1,
                }}
              >
                Logout
              </ListItemText>
            </Collapse>
          </NavLinkButtonStyle>
          <Divider />
          {!mobileNavIsOpen && (
            <ListItemButton
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexBasis: '100%',
                height: 45,
              }}
              onClick={() => {
                setCollapsed((prev) => !prev);
              }}
            >
              <Collapse
                in={isExpanded}
                orientation="horizontal"
                timeout={transition}
                unmountOnExit
              >
                <ListItemText>{collapsed ? 'Expand' : 'Collapse'}</ListItemText>
              </Collapse>
              <ListItemIcon sx={{ minWidth: 0 }}>
                <CollapseIcon
                  fontSize="small"
                  sx={{
                    transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: (theme) =>
                      theme.transitions.create('transform', {
                        easing: theme.transitions.easing.sharp,
                        duration: transition,
                      }),
                  }}
                />
              </ListItemIcon>
            </ListItemButton>
          )}
        </List>
      </Box>
    </Drawer>
  );
};

export default SideNav;

const NavLinkButton = ({ title, to, icon, isExpanded, nestedPaths }) => {
  const { data: tasksDueToday, isLoading } = useTasksDueToday();
  const showTasksChip = () =>
    !isLoading && tasksDueToday?.total_count > 0 && isExpanded && title === 'Tasks';

  return (
    <>
      <NavLinkButtonStyle
        component={NavLink}
        isActive={(route) => route?.url.includes(to)}
        to={to}
      >
        <ListItemIcon sx={{ minWidth: '0px' }}>
          {typeof icon === 'function' ? icon({ isExpanded }) : icon}
        </ListItemIcon>
        <Collapse
          in={isExpanded}
          orientation="horizontal"
          timeout={transition}
          unmountOnExit
        >
          <ListItemText sx={{ pl: 1 }}>{title}</ListItemText>
        </Collapse>
        {showTasksChip() && (
          <Fade in timeout={200}>
            <Chip
              label={tasksDueToday?.total_count}
              size="small"
              sx={{
                ml: 'auto',
                backgroundColor: ({ palette }) => palette.error.main,
                color: 'white',
              }}
            />
          </Fade>
        )}
      </NavLinkButtonStyle>
      {nestedPaths?.length && (
        <Collapse in={isExpanded} timeout={transition} unmountOnExit>
          <List sx={{ pl: 5 }} disablePadding>
            {nestedPaths.map((path, i) => (
              <NavLinkButtonStyle
                component={NavLink}
                isActive={(route) => route?.url.includes(path.to)}
                to={path.to}
                key={i}
              >
                <ListItemText>
                  <Typography sx={{ fontSize: '14px' }}>{path.title}</Typography>
                </ListItemText>
              </NavLinkButtonStyle>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

const TasksIconWithCount = ({ isExpanded }) => {
  const { data: tasksDueToday } = useTasksDueToday();

  if (isExpanded) return <TasksIcon />;
  return (
    <Badge
      showZero={false}
      badgeContent={tasksDueToday?.total_count}
      color="error"
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      sx={{
        '& .MuiBadge-badge': {
          height: 14,
          fontSize: 12,
          minWidth: 0,
        },
      }}
    >
      <TasksIcon />
    </Badge>
  );
};

const primaryRoutes = (currentUser) => {
  const callTrackingEnabled = currentUser.roles.find((role) => {
    return (
      role.role_name == 'Administrator' ||
      (role.role_name == 'Call Tracking' &&
        currentUser.organization.call_tracking_enabled)
    );
  });

  const paths = [
    { title: 'Dashboard', to: '/', icon: <HomeIcon /> },
    { title: 'Tasks', to: '/tasks', icon: TasksIconWithCount },
    { title: 'Leads', to: '/leads', icon: <LeadsIcon /> },
    callTrackingEnabled
      ? { title: 'Call Tracking', to: '/calls', icon: <CallTrackingIcon /> }
      : null,
    {
      title: 'Reports',
      to: '/reports/overview',
      icon: <ReportsIcon />,
      nestedPaths: [
        { title: 'Activity', to: '/reports/activity', icon: <></> },
        { title: 'Conversion', to: '/reports/conversion', icon: <></> },
      ],
    },
    { title: 'Activity', to: '/activity', icon: <ActivityIcon /> },
  ];

  return paths.filter(function (e) {
    return e;
  });
};

const secondaryRoutes = [
  { title: 'Settings', to: '/settings', icon: <SettingsIcon /> },
  { title: 'Support', to: '/support', icon: <SupportIcon /> },
];

import Cookies from 'js-cookie';
import { getActiveOrg } from '../../util/UserUtils';
import { toJSON } from '../utils/utils';

export default class LostReasons {
  static async list(filters = {}) {
    filters['organization_id'] = getActiveOrg();
    const params = new URLSearchParams(filters).toString();
    return await fetch(`${process.env.REACT_APP_API_BASE}/lost_reasons?${params}`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
      },
    }).then(toJSON);
  }
}

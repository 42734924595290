import { useQuery } from 'react-query';
import { queryKeyFactory } from '../../MUI/services/queryClientConfig';
import FewShosts from './FewShots';

export const fewshotsQueryKeys = queryKeyFactory('few-shots');
export const useFewShotsList = (params, options) => {
  return useQuery(fewshotsQueryKeys.list(), () => FewShosts.list(params), {
    staleTime: 0,
    ...options,
  });
};

import Cookies from 'js-cookie';
import { toJSON } from '../utils/utils';

export default class Appointments {
  static async list(filters = {}) {
    return await fetch(
      `${process.env.REACT_APP_CALENDAR_SERVICE}/appointments?${new URLSearchParams(
        filters
      ).toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(toJSON);
  }
}

import { v4 as uuid } from 'uuid';
import {
  Greeting,
  Dial,
  VoiceMail,
  InitialList,
  Menu,
  CreateLead,
  Hangup,
  Ai,
  Play,
} from '../index';

export const availableOptions = {
  title: 'What would you like to happen ?',
  isListOptions: true,
  id: uuid(),
  component: <InitialList />,
  action: 'initialList',
};

export const menuOption = {
  title: 'Menu',
  parameters: {},
  isListOptions: false,
  activeCallerPressOption: 1,
  availableCallerPressOption: {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: [],
  },
  id: uuid(),
  component: <Menu />,
  action: 'respond',
};

export const greetingOption = {
  title: 'Greeting',
  parameters: {},
  isListOptions: false,
  id: uuid(),
  component: <Greeting />,
  action: 'say',
};

export const playOption = {
  title: 'Play',
  parameters: {},
  isListOptions: false,
  id: uuid(),
  component: <Play />,
  action: 'play',
};

export const aiOption = {
  title: 'AI',
  parameters: {},
  isListOptions: false,
  id: uuid(),
  component: <Ai />,
  action: 'ai',
};

export const dialOption = {
  title: 'Dial',
  parameters: {},
  isListOptions: false,
  id: uuid(),
  component: <Dial />,
  action: 'forward',
};

export const createLeadOption = {
  title: 'Create Lead',
  parameters: {},
  isListOptions: false,
  id: uuid(),
  component: <CreateLead />,
  action: 'create_lead',
};

export const voiceMailOption = {
  action: 'record',
  parameters: {},
  title: 'VoiceMail',
  isListOptions: false,
  id: uuid(),
  component: <VoiceMail />,
};

export const hangupOption = {
  action: 'hangup',
  parameters: {},
  title: 'Hangup',
  isListOptions: false,
  id: uuid(),
  component: <Hangup />,
};

export const voiceOptions = [
  { operator: 'Polly.Ivy', label: 'Polly.Ivy' },
  { operator: 'Polly.Joanna', label: 'Polly.Joanna' },
  { operator: 'Polly.Joey', label: 'Polly.Joey' },
  { operator: 'Polly.Justin', label: 'Polly.Justin' },
  { operator: 'Polly.Kendra', label: 'Polly.Kendra' },
  { operator: 'Polly.Kimberly', label: 'Polly.Kimberly' },
  { operator: 'Polly.Matthew', label: 'Polly.Matthew' },
  { operator: 'Polly.Salli', label: 'Polly.Salli' },
  { operator: 'Polly.Neural', label: 'Polly.Neural' },
  { operator: 'Polly.Kevin-Neural', label: 'Polly.Kevin - Neural' },
  { operator: 'Polly.Kimberly-Neural', label: 'Polly.Kimberly - Neural' },
  { operator: 'Polly.Joey-Neural', label: 'Polly.Joey - Neural' },
  { operator: 'Polly.Justin-Neural', label: 'Polly.Justin - Neural' },
  { operator: 'Polly.Stephen-Neural', label: 'Polly.Stephen - Neural' },
  { operator: 'Google.en-US-Standard-A', label: 'Google.en-US-Standard-A' },
  { operator: 'Google.en-US-Standard-B', label: 'Google.en-US-Standard-B' },
  { operator: 'Google.en-US-Standard-C', label: 'Google.en-US-Standard-C' },
  { operator: 'Google.en-US-Standard-D', label: 'Google.en-US-Standard-D' },
  { operator: 'Google.en-US-Standard-E', label: 'Google.en-US-Standard-E' },
  { operator: 'Google.en-US-Standard-F', label: 'Google.en-US-Standard-F' },
  { operator: 'Google.en-US-Standard-G', label: 'Google.en-US-Standard-G' },
  { operator: 'Google.en-US-Standard-H', label: 'Google.en-US-Standard-H' },
  { operator: 'Google.en-US-Standard-I', label: 'Google.en-US-Standard-I' },
  { operator: 'Google.en-US-Standard-J', label: 'Google.en-US-Standard-J' },
  { operator: 'Google.en-US-Wavenet-A', label: 'Google.en-US-Wavenet-A' },
  { operator: 'Google.en-US-Wavenet-B', label: 'Google.en-US-Wavenet-B' },
  { operator: 'Google.en-US-Wavenet-C', label: 'Google.en-US-Wavenet-C' },
  { operator: 'Google.en-US-Wavenet-D', label: 'Google.en-US-Wavenet-D' },
  { operator: 'Google.en-US-Wavenet-E', label: 'Google.en-US-Wavenet-E' },
  { operator: 'Google.en-US-Wavenet-F', label: 'Google.en-US-Wavenet-F' },
  { operator: 'Google.en-US-Wavenet-G', label: 'Google.en-US-Wavenet-G' },
  { operator: 'Google.en-US-Wavenet-H', label: 'Google.en-US-Wavenet-H' },
  { operator: 'Google.en-US-Wavenet-I', label: 'Google.en-US-Wavenet-I' },
  { operator: 'Google.en-US-Wavenet-J', label: 'Google.en-US-Wavenet-J' },
  { operator: 'Google.en-US-Neural2-A', label: 'Google.en-US-Neural2-A' },
  { operator: 'Google.en-US-Neural2-B', label: 'Google.en-US-Neural2-B' },
  { operator: 'Google.en-US-Neural2-C', label: 'Google.en-US-Neural2-C' },
  { operator: 'Google.en-US-Neural2-D', label: 'Google.en-US-Neural2-D' },
  { operator: 'Google.en-US-Neural2-E', label: 'Google.en-US-Neural2-E' },
  { operator: 'Google.en-US-Neural2-F', label: 'Google.en-US-Neural2-F' },
  { operator: 'Google.en-US-Neural2-G', label: 'Google.en-US-Neural2-G' },
  { operator: 'Google.en-US-Neural2-H', label: 'Google.en-US-Neural2-H' },
  { operator: 'Google.en-US-Neural2-I', label: 'Google.en-US-Neural2-I' },
  { operator: 'Google.en-US-Neural2-J', label: 'Google.en-US-Neural2-J' },
];

import { useUsersCurrent, usersQueryKeys } from '../services/users/queries';
import { useNotificationEmailAddressesList } from '../services/notificationEmailAddresses/queries';
import { useUsersEdit } from '../services/users/mutations';
import {
  createYupResolver,
  FIRST_NAME,
  LAST_NAME,
  PHONE_NUMBER,
} from '../components/HookForm/yupValidations';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { Button, Box, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import HookForm from '../components/HookForm';
import { SaveIcon, PlusIcon } from '../theme/icons';
import { FIELD_WIDTH } from '../theme/fixedValues';
import { formatPhoneNumber } from '../services/textFormatting';
import { fireDialog } from '../components/Dialog';
import ListView from '../components/ListView';
import {
  AddEmailDialog,
  EditEmailDialog,
  DeleteEmailDialog,
} from './_settingsPagesComponents/notificationEmailAddressesDialogs';
import { useQueryClient } from 'react-query';

export default function UserProfileSettingsPage() {
  const queryClient = useQueryClient();
  const { data: user, isLoading: isLoadingUser } = useUsersCurrent();
  const {
    mutate: editUser,
    isLoading: isEditing,
    isError,
    isSuccess,
    reset,
  } = useUsersEdit();
  const {
    data: notificationEmailAddresses,
    isFetching: isFetchingNotificationEmailAddresses,
    isError: isErrorNotificationEmailAddresses,
    isSuccess: isEmailsSuccess,
  } = useNotificationEmailAddressesList(user.id);

  const handleAddEmailClick = () =>
    fireDialog((promiseProps) => AddEmailDialog({ ...promiseProps, user }));

  const handleEditEmailClick = (emailAddress) =>
    fireDialog((promiseProps) =>
      EditEmailDialog({ emailAddress, user, ...promiseProps })
    );

  const handleDeleteEmailClick = (emailAddress) => {
    fireDialog((promiseProps) =>
      DeleteEmailDialog({
        ...promiseProps,
        emailAddress,
        user,
      })
    );
  };

  const onUserProfileSubmit = (data) => {
    editUser(
      {
        id: user.id,
        first_name: data.firstName,
        last_name: data.lastName,
        phone: data.phoneNumber,
      },
      { onSuccess: () => queryClient.invalidateQueries(usersQueryKeys.current) }
    );
  };

  const defaultValues = {
    [FIRST_NAME]: user.first_name,
    [LAST_NAME]: user.last_name,
    [PHONE_NUMBER]: formatPhoneNumber(user.phone ?? ''),
  };

  const useFormProps = { defaultValues, resolver: createYupResolver(defaultValues) };

  // temporary way to handle reseting error/success state
  const resetOnTimer = () =>
    setTimeout(() => {
      reset();
    }, 5000);

  return (
    <SettingsCard
      showLoading={isLoadingUser || isFetchingNotificationEmailAddresses}
    >
      <SettingsCard.Header>User Profile</SettingsCard.Header>
      <SettingsCard.SubHeader>
        This is the name that will appear in automated communications and the
        activity log. Your phone number will be used for SMS notifications if
        enabled.
      </SettingsCard.SubHeader>
      <SettingsCard.Main>
        <HookForm
          onSubmit={onUserProfileSubmit}
          useFormProps={useFormProps}
          useDirtyFormCheck
        >
          {isSuccess && resetOnTimer() && (
            <Alert severity="success" onClose={() => reset()}>
              Changes to your profile were saved successfully.
            </Alert>
          )}
          {isError && (
            <Alert severity="error" onClose={() => reset()}>
              There was an error saving your changes. Please try again.
            </Alert>
          )}
          <Box
            display="grid"
            direction="column"
            rowGap={2}
            sx={{ width: FIELD_WIDTH }}
          >
            <HookForm.TextField name="firstName" label="First Name" />
            <HookForm.TextField name="lastName" label="Last Name" />
            <HookForm.PhoneNumberField name="phoneNumber" label="Phone Number" />
            <LoadingButton
              type="submit"
              loading={isEditing}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              sx={{ maxWidth: 'max-content' }}
            >
              Save
            </LoadingButton>
          </Box>
        </HookForm>
      </SettingsCard.Main>
      <SettingsCard.Divider />
      <SettingsCard.Header>Notification Emails</SettingsCard.Header>
      <SettingsCard.SubHeader>
        We will send you notifications about LeadSigma activity at these email
        addresses:
      </SettingsCard.SubHeader>
      <SettingsCard.Main>
        {isErrorNotificationEmailAddresses && (
          <Alert>
            There was an error loading your notification email addresses. Try again
          </Alert>
        )}
        {isEmailsSuccess && notificationEmailAddresses.length == 0 && (
          <Alert severity="info" sx={{ mt: 1, mb: 2 }}>
            Add your first email address to start receiving notifications by email
          </Alert>
        )}
        {isEmailsSuccess && notificationEmailAddresses.length > 0 && (
          <ListView sx={{ mb: 2 }}>
            <ListView.Item>
              <ListView.HeaderText colWidth="auto">
                Email Address
              </ListView.HeaderText>
            </ListView.Item>
            {notificationEmailAddresses?.map((emailAddress) => (
              <ListView.Item key={emailAddress.id}>
                <ListView.Text colWidth={400}>{emailAddress.email}</ListView.Text>
                <ListView.EditButton
                  onClick={() => handleEditEmailClick(emailAddress)}
                />
                <ListView.DeleteButton
                  onClick={() => handleDeleteEmailClick(emailAddress)}
                />
              </ListView.Item>
            ))}
          </ListView>
        )}
        <Button startIcon={<PlusIcon />} onClick={handleAddEmailClick}>
          Add New
        </Button>
      </SettingsCard.Main>
    </SettingsCard>
  );
}

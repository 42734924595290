import Cookies from 'js-cookie';
import { getActiveOrg } from '../../util/UserUtils';

export default class Organization {
  static async reset() {
    const url = `${
      process.env.REACT_APP_API_BASE
    }/demo_reset?organization_id=${getActiveOrg()}`;
    return await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: '{}',
    });
  }
}

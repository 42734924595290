import { useMutation, useQueryClient } from 'react-query';
import { organizationsQueryKeys } from './queries';
import Organizations from './Organizations';
import { usersQueryKeys } from '../users/queries';
import { userOrganizationConnectionsQueryKeys } from '../userOrganizationConnections/queries';
import { sourcesQueryKeys } from '../sources/queries';
import { leadsQueryKeys } from '../leads/queries';
import { taskKeys } from '../../../api/utils/queryClient/queryConfigs';
import { taskSequencesQueryKeys } from '../taskSequences/queries';
import { teamsQueryKeys } from '../teams/queries';

export const useOrganizationsEdit = () => {
  const queryClient = useQueryClient();
  return useMutation(Organizations.edit, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(organizationsQueryKeys.lists());
      queryClient.invalidateQueries(
        organizationsQueryKeys.billingAddresses(variables.id)
      );
      queryClient.invalidateQueries(
        organizationsQueryKeys.organizationServices(variables.id)
      );
    },
  });
};

export const useOrganizationsCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(Organizations.create, {});
};

export const useOrganizationsDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(Organizations.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(organizationsQueryKeys.lists());
      queryClient.invalidateQueries(userOrganizationConnectionsQueryKeys.lists());
      queryClient.invalidateQueries(usersQueryKeys.lists());
      queryClient.invalidateQueries(sourcesQueryKeys.lists());
      queryClient.invalidateQueries(['custom_fields']);
      queryClient.invalidateQueries(taskSequencesQueryKeys.lists());
      queryClient.invalidateQueries(leadsQueryKeys.lists());
      queryClient.invalidateQueries(taskKeys.lists());
      queryClient.invalidateQueries(teamsQueryKeys.lists());
    },
  });
};

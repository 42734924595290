import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './sass/App.scss';
import './sass/main.scss';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DashboardContainer from './components/dashboard/DashboardContainer';
import TopNavigationGroup from './components/layout/TopNavigationGroup';
import Activity from './MUI/pages/Activity';
import ReportsOverview from './MUI/pages/ReportsOverview';
import ReportsActivity from './MUI/pages/ReportsActivity';
import ReportsConversion from './MUI/pages/ReportsConversion';
import { ToastContainer } from 'react-toastify';
import CustomPushSubPrompt from './components/pushNotifications/CustomPushSubPrompt';
import { useLocalStorage } from './hooks/useLocalStorage';
import { localStorageKeys } from './util/Constants';
import moment from 'moment';
import { useUsersCurrent } from './MUI/services/users/queries';
import { v4 as uuid } from 'uuid';
import { useUserPushEndpointsCreate } from './api/userPushEndpoints/userPushEndpointsMutations';
import { getActiveOrg } from './util/UserUtils';
import { Container, Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import BroadcastChannelModal from './components/pushNotifications/BroadcastChannelModal';
import SideNav from './MUI/components/SideNav';
import SettingsPage from './MUI/pages/SettingsPage';
import ModalContainer from './MUI/components/PromiseDialog';
import Leads from './MUI/pages/Leads';
import Tasks from './MUI/pages/Tasks';
import CallTracking from './MUI/pages/CallTracking';
import CallFlowPage from './MUI/pages/CallFlowPage';
import Cookies from 'js-cookie';
import { useEventsCreate } from './MUI/services/events/mutations';
import LogRocket from 'logrocket';
LogRocket.init('ztdi90/leadsigma', {
  release: '2.43.0',
});

function App() {
  const [showCustomPushSubPrompt, setShowCustomPushSubPrompt] = useState(false);
  const { data: user } = useUsersCurrent();
  const { mutate: createPushEndpoint } = useUserPushEndpointsCreate();
  const [pushStatus, setPushStatus] = useLocalStorage(
    localStorageKeys.pushNotificationRemdinerStatus
  );
  const { mutate: createEvent } = useEventsCreate();

  const allowPushSubPrompt = () => {
    // if no entry in local storage, allow prompt
    if (!pushStatus) {
      return true;
    }
    // if timeout expired, allow prompt
    if (moment().isAfter(pushStatus.datetime)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (user) {
      if (Cookies.get('new-login')) {
        createEvent({
          organization_id: user.organization.id,
          event_type: 800,
          actor_user_id: user.id,
          actor: {
            user: {
              id: user.id,
              first_name: user.first_name,
              last_name: user.last_name,
            },
          },
          subject: {
            user: {
              id: user.id,
              first_name: user.first_name,
              last_name: user.last_name,
            },
          },
        });
        Cookies.remove('new-login');
      }
    }

    if (
      'serviceWorker' in navigator &&
      'Notification' in window &&
      allowPushSubPrompt()
    ) {
      navigator.serviceWorker.ready
        .then((registration) => {
          registration.pushManager
            .getSubscription()
            .then((subscription) => {
              if (Notification.permission === 'denied') return;

              if (subscription === null) {
                setShowCustomPushSubPrompt(true);
              } else {
                const subscriptionString = JSON.stringify(subscription);
                const endpoints = user.user_push_endpoints.map(
                  ({ endpoint_id }) => endpoint_id
                );
                // in case backend for whatever reason does not have current subscription
                if (!endpoints.includes(subscriptionString)) {
                  createPushEndpoint({
                    user_id: user.id,
                    endpoint_id: subscriptionString,
                    status: 'active',
                    device_id: uuid(),
                    organization_id: getActiveOrg(),
                  });
                } else {
                  setPushStatus(null);
                }
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    }
  }, []);

  const fullWidth = 230;
  const collapsedWidth = 76;
  const [collapsed, setCollapsed] = useState(() => {
    const saved = localStorage.getItem('nav.side_nav');
    const initialValue = JSON.parse(saved);
    return initialValue || false;
  });
  const [drawerBoxWidth, setDrawerBoxWidth] = useState(collapsedWidth);

  useEffect(() => {
    if (collapsed) setDrawerBoxWidth(collapsedWidth);
    if (!collapsed) setDrawerBoxWidth(fullWidth);
    localStorage.setItem('nav.side_nav', JSON.stringify(collapsed));
  }, [collapsed]);

  return (
    <>
      <ToastContainer />
      <ModalContainer />
      <BroadcastChannelModal />
      {showCustomPushSubPrompt && (
        <CustomPushSubPrompt
          showCustomPushSubPrompt={showCustomPushSubPrompt}
          setShowCustomPushSubPrompt={setShowCustomPushSubPrompt}
        />
      )}
      <Container sx={{ display: 'flex', height: '100%' }}>
        <Box
          sx={{
            width: drawerBoxWidth,
            ...(useMediaQuery((theme) => theme.breakpoints.down('md')) && {
              maxWidth: 0,
            }),
          }}
        >
          <SideNav setCollapsed={setCollapsed} collapsed={collapsed} />
        </Box>

        <Container
          sx={{
            margin: 0,
            flexGrow: 1,
            overflow: 'hidden',
            width: `calc(100vw - ${drawerBoxWidth}px)`,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: 200,
              }),
          }}
        >
          <Box height="100%" display="flex" flexDirection="column">
            <Box zIndex="255">
              <TopNavigationGroup />
            </Box>
            <Box p={2} overflow="auto" flex="1">
              <Route exact path={`/`} component={DashboardContainer} />
              <Route
                exact
                path={['/tasks', '/tasks/:taskIdParam']}
                component={Tasks}
              />
              <Route
                exact
                path={['/leads', '/leads/:leadIdParam']}
                component={Leads}
              />
              <Route
                exact
                path={['/calls', '/calls/:callIdParam']}
                component={CallTracking}
              />
              {user.roles.find((role) => {
                return (
                  role.role_name == 'Administrator' ||
                  (role.role_name == 'Call Tracking' &&
                    user.organization.call_tracking_enabled)
                );
              }) && <Route exact path={'/call-flow'} component={CallFlowPage} />}
              <Route exact path={`/templates`}>
                <Redirect to={`/settings/templates`}></Redirect>
              </Route>
              <Route exact path={`/templates/create`}>
                <Redirect to={`settings/templates/create`}></Redirect>
              </Route>
              <Route exact path={`/templates/edit/:templateId`}>
                <Redirect to={`/settings/templates`}></Redirect>
              </Route>
              <Route exact path={`/import`}>
                <Redirect to={`/settings/import`}></Redirect>
              </Route>
              <Route exact path={`/upload`}>
                <Redirect to={`/settings/import`}></Redirect>
              </Route>
              <Route exact path={`/preview`}>
                <Redirect to={`/settings/import`}></Redirect>
              </Route>
              <Route exact path={`/complete`}>
                <Redirect to={`/settings/import`}></Redirect>
              </Route>
              <Route
                exact
                path={`/support`}
                component={() => {
                  window.location.replace(
                    'https://sigmaleads.atlassian.net/servicedesk/customer/portals'
                  );
                  return null;
                }}
              />
              <Route exact path={`/reports/overview`} component={ReportsOverview} />
              <Route exact path={`/reports/activity`} component={ReportsActivity} />
              <Route
                exact
                path={`/reports/conversion`}
                component={ReportsConversion}
              />
              <Route exact path={`/activity`} component={Activity} />
              <Route exact path={`/sequences`}>
                <Redirect to={`/settings/sequences`}></Redirect>
              </Route>
              <Route exact path={`/sequences/:sequenceId`}>
                <Redirect to={`/settings/sequences`}></Redirect>
              </Route>
              <Route path={`/settings`} component={SettingsPage} />
            </Box>
          </Box>
        </Container>
      </Container>
    </>
  );
}

export default App;

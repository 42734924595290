import { useState } from 'react';
import { Button } from '@mui/material';

import { EditIcon } from '../../theme/icons';
import LeadsUpdateSequenceModal from './LeadsUpdateModal';
import { fireDialog } from '../Dialog';

const GroupUpdateButton = ({ leads }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        variant="outlined"
        size="medium"
        disabled={leads.length === 0}
        startIcon={<EditIcon />}
        onClick={() => {
          fireDialog((promisParams) =>
            LeadsUpdateSequenceModal({ ...promisParams, leads: leads })
          );
        }}
        sx={{
          marginRight: '5px',
        }}
      >
        Update
      </Button>
    </>
  );
};

export default GroupUpdateButton;

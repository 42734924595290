import { useEnterprisesShow } from '../services/enterprises/queries';
import { useOrg } from '../../contexts/OrgProvider';
import { useUsersCurrent } from '../../MUI/services/users/queries';
import { useEnterprisesEdit } from '../services/enterprises/mutations';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { fireDialog } from '../components/Dialog';
import { LoadingButton } from '@mui/lab';
import HookForm from '../components/HookForm';
import { useConditionalUseFormProps } from '../components/HookForm/hooks';
import { EditIcon, PlusIcon } from '../theme/icons';
import { FIELD_WIDTH } from '../theme/fixedValues';
import {
  createYupResolver,
  ADDRESS_LINE_ONE,
  ADDRESS_LINE_TWO,
  CITY,
  REGION,
  POSTAL_CODE,
  NAME,
} from '../components/HookForm/yupValidations';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Alert,
  Tooltip,
  Zoom,
  Link,
  Typography,
  InputLabel,
  Grid,
} from '@mui/material';
import ClipLoader from 'react-spinners/ClipLoader';
import { useState } from 'react';
import { DataGridStyle } from '../theme/styled/DataGridStyle';
import { dateToPrettyDateTimeString } from '../services/dateTime';
import { SaveIcon } from '../theme/icons';

const EnterprisePage = () => {
  const { isMasterAccount, isEnterpriseUser, enterprise_id } = useOrg();
  const { data: user } = useUsersCurrent();
  const {
    data: enterprise,
    isFetching: isFetchingEnterprises,
    isError,
  } = useEnterprisesShow(enterprise_id, {
    enabled: isEnterpriseUser,
  });
  const {
    mutate: editEnterprise,
    isLoading: isEditing,
    isError: isEnterpriseEditError,
    isSuccess,
    reset,
  } = useEnterprisesEdit();

  const onEnterpriseSubmit = (data) => {
    editEnterprise({
      id: enterprise.id,
      name: data.name,
      greeting_audio_url: data.greetingAudioUrl,
      temp_password: data.tempPassword,
      whitelabel_name: data.whiteLabelName,
      whitelabel_sender_email: data.whiteLabelSenderEmail,
      whitelabel_support_email: data.whiteLabelSupportEmail,
    });
  };

  const defaultValues = {
    ['name']: enterprise?.name || '',
    ['greetingAudioUrl']: enterprise?.greeting_audio_url || '',
    ['tempPassword']: enterprise?.temp_password || '',
    ['whiteLabelName']: enterprise?.whitelabel_name || '',
    ['whiteLabelSenderEmail']: enterprise?.whitelabel_sender_email || '',
    ['whiteLabelSupportEmail']: enterprise?.whitelabel_support_email || '',
  };

  const useFormProps = useConditionalUseFormProps(
    {
      defaultValues,
      resolver: createYupResolver(defaultValues),
    },
    !!enterprise
  );

  return (
    <Box sx={{ height: '100%' }}>
      {!isMasterAccount || !isEnterpriseUser ? (
        <Alert severity="error">
          This page cannot be viewed outside an enterprise or you do not have the
          proper access to view this page.
        </Alert>
      ) : (
        <SettingsCard
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <SettingsCard.Header>Enterprise</SettingsCard.Header>
          <SettingsCard.Main sx={{ flex: 1 }}>
            <HookForm onSubmit={onEnterpriseSubmit} useFormProps={useFormProps}>
              {isError && (
                <Alert severity="error">
                  There was a problem loading your enterprise. Try again.
                </Alert>
              )}
              {isEnterpriseEditError && (
                <Alert severity="error" onClose={() => reset()}>
                  There was an error saving your changes. Please try again.
                </Alert>
              )}
              <Grid container direction="column" rowSpacing={2} sx={{ width: 500 }}>
                <Grid item>
                  <HookForm.TextField name="name" label="Name" />
                </Grid>
                <Grid item>
                  <InputLabel shrink={true}>
                    Customize the ACN whisper text with your own audio
                  </InputLabel>
                  <HookForm.TextField
                    name="greetingAudioUrl"
                    label="Greeting Audio URL"
                  />
                </Grid>
                <Grid item>
                  <InputLabel shrink={true}>
                    Turn off the welcome email and use a set default temporary
                    password
                  </InputLabel>
                  <HookForm.TextField
                    name="tempPassword"
                    label="Temporary Password"
                  />
                </Grid>
                <Grid item>
                  <InputLabel shrink={true}>
                    White label the notification emails that are sent to users
                  </InputLabel>
                  <HookForm.TextField
                    name="whiteLabelName"
                    label="White Label Name"
                  />
                </Grid>
                <Grid item>
                  <HookForm.TextField
                    name="whiteLabelSenderEmail"
                    label="Sender Email"
                  />
                </Grid>
                <Grid item>
                  <HookForm.TextField
                    name="whiteLabelSupportEmail"
                    label="Support Email"
                  />
                </Grid>
                <Grid item>
                  <LoadingButton
                    type="submit"
                    loading={isEditing}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    sx={{ maxWidth: 'max-content' }}
                  >
                    Save
                  </LoadingButton>
                </Grid>
              </Grid>
            </HookForm>
          </SettingsCard.Main>
        </SettingsCard>
      )}
    </Box>
  );
};

export default EnterprisePage;

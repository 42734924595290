import Cookies from 'js-cookie';
import { toJSON } from '../utils/utils';

export default class Sources {
  static async create(source) {
    // source['organization_id'] = getActiveOrg();
    return await fetch(`${process.env.REACT_APP_API_BASE}/sources`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(source),
    }).then(toJSON);
  }

  static async list(organization_id) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/sources?organization_id=${organization_id}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async edit(source) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/sources/${source.id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(source),
    }).then(toJSON);
  }

  static async delete(source_id) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/sources/${source_id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
}

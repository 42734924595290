import { Box, IconButton, Icon, Typography, Collapse, alpha } from '@mui/material';
import { ChevronDownIcon } from '../../theme/icons';
import { useEffect, useState } from 'react';

export const EventCard = ({
  icon,
  title,
  renderTitle,
  timestamp,
  timestampIcon,
  actionButton,
  metaTexts,
  collapseContent,
  direction,
  defaultOpen,
}) => {
  const headerProps = {
    icon,
    title,
    renderTitle,
    timestamp,
    timestampIcon,
    actionButton,
    direction,
  };
  const contentCollapseProps = {
    metaTexts,
    collapseContent,
    direction,
    defaultOpen,
  };
  return (
    <Box>
      <Header {...headerProps} />
      {(metaTexts || collapseContent) && (
        <ContentCollapse {...contentCollapseProps} />
      )}
    </Box>
  );
};

const Header = ({
  icon,
  title,
  renderTitle,
  timestamp,
  timestampIcon,
  actionButton,
  direction,
}) => {
  return (
    <Box display="flex" minHeight="34px" alignItems="center" gap={1} padding={0}>
      {icon && (
        <Icon
          sx={{
            display: 'flex',
            ...(direction === 'in' && { transform: 'rotateY(180deg)' }),
          }}
          color={direction === 'in' ? 'secondary' : ''}
        >
          {icon}
        </Icon>
      )}
      {renderTitle ? (
        <Box flex={1}>{renderTitle()}</Box>
      ) : (
        <Typography sx={{ flex: 1 }}>{title}</Typography>
      )}

      {timestampIcon && <Icon sx={{ display: 'flex' }}>{timestampIcon}</Icon>}
      <Typography fontStyle="italic">{timestamp}</Typography>
      {actionButton}
    </Box>
  );
};

const ContentCollapse = ({ collapseContent, metaTexts, direction, defaultOpen }) => {
  const [open, setOpen] = useState(defaultOpen);

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  const smallHeight = 40;
  const largeHeight = 60;
  const isSmallContent = () => {
    if (!!collapseContent) return false;
    if (metaTexts?.length > 1) return false;
    return true;
  };
  return (
    <Collapse
      collapsedSize={isSmallContent() ? smallHeight : largeHeight}
      orientation="vertical"
      in={open}
      sx={{
        borderRadius: '6px',
        position: 'relative',
      }}
      onClick={() => !open && setOpen((prev) => !prev)}
    >
      {!isSmallContent() && (
        <IconButton
          onClick={() => open && setOpen((prev) => !prev)}
          sx={{
            position: 'absolute',
            top: 12,
            right: 16,
            transform: open && 'rotate(180deg)',
            transition: '200ms',
          }}
        >
          <ChevronDownIcon />
        </IconButton>
      )}
      <Box
        sx={{
          background: ({ palette }) =>
            direction === 'in' ? palette.secondary.light : palette.grey[100],
          py: 1,
          px: 2,
          overflow: 'hidden',
          overflowWrap: 'break-word',
          borderRadius: '6px',
        }}
      >
        <Box
          sx={{
            ...(!open && {
              WebkitMaskImage: (theme) =>
                `linear-gradient(to bottom, black 0px 20px, ${alpha(
                  theme.palette.common.black,
                  0.5
                )} 20px)`,
              maskImage: (theme) =>
                `linear-gradient(to bottom, black 0px 20px, ${alpha(
                  theme.palette.common.black,
                  0.5
                )} 20px)`,
            }),
          }}
        >
          {metaTexts && (
            <Box>
              {metaTexts.map((text, i) => (
                <Typography key={i} fontWeight={500}>
                  {text}
                </Typography>
              ))}
            </Box>
          )}
          {collapseContent && (
            <Box sx={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}>
              {collapseContent}
            </Box>
          )}
        </Box>
      </Box>
    </Collapse>
  );
};

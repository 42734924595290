import Cookies from 'js-cookie';
import { toJSON, toParamString } from '../queryClientConfig';

export default class Leads {
  static async list(filters = []) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/v2/leads?${toParamString(filters)}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async show(lead_id) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/leads/${lead_id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(toJSON);
  }

  static async create(lead) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/leads`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(lead),
    }).then(toJSON);
  }

  static async edit(lead) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/leads/${lead.id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(lead),
    }).then(toJSON);
  }

  static async editGroup(lead) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/v2/leads`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(lead),
    }).then(toJSON);
  }

  static async delete(lead_id) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/leads/${lead_id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }

  static async deleteGroup(params) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/v2/leads`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
  }
}

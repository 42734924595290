// import { useUsersCurrent } from '../MUI/services/users/queries';
import { useUsersCurrent } from '../MUI/services/users/queries';

import ClipLoader from 'react-spinners/ClipLoader';
import { Row } from 'react-bootstrap';
import { useEffect } from 'react';

const UserCacheGuard = ({ children }) => {
  const { data: user } = useUsersCurrent();

  // data from RQ is null until successful, so app won't load until isCurrent passes
  if (!user)
    return (
      <Row
        noGutters
        className="d-flex align-items-center justify-content-center mt-5"
      >
        <ClipLoader size="300px" color="#25ac94" />
      </Row>
    );

  return <>{children}</>;
};

export default UserCacheGuard;

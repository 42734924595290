export const statuses = {
  0: 'Pending',
  1: 'Won',
  2: 'Lost',
};
export const emailValidation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
export const todayDate = new Date()
  .toLocaleString('en-US', options)
  .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');

export const colorValues = {
  greyColor: '#666666',
  greySecondary: '#f8f8f8',
  mutedGrey: '#b3b3b3',
  primaryColor: '#34d1b6',
  secondaryColor: '#907bf0',
  successColor: '#e6a647',
  warmColor: '#ffb340',
  dangerColor: '#eb5757',
};

export const localStorageKeys = {
  pushNotificationRemdinerStatus: 'pushNotificationRemdinerStatus',
};

import Cookies from 'js-cookie';
import { toJSON, toParamString } from '../queryClientConfig';

export default class Calendars {
  static async list(filters = {}) {
    return await fetch(
      `${process.env.REACT_APP_CALENDAR_SERVICE}/calendars?${new URLSearchParams(
        filters
      ).toString()}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async delete(filters) {
    return await fetch(
      `${process.env.REACT_APP_CALENDAR_SERVICE}/calendars/${filters}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    );
  }

  static async create(event) {
    return await fetch(`${process.env.REACT_APP_CALENDAR_SERVICE}/calendars`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(event),
    }).then(toJSON);
  }

  static async edit(params) {
    return await fetch(
      `${process.env.REACT_APP_CALENDAR_SERVICE}/calendars/${params.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      }
    ).then(toJSON);
  }

  static async get(filters) {
    return await fetch(
      `${process.env.REACT_APP_CALENDAR_SERVICE}/calendars/${filters.calendar_id}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }
}

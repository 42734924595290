import Cookies from 'js-cookie';
import { toJSON, toParamString } from '../queryClientConfig';

export default class TaskSequences {
  static async list(filters) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/task_sequences?${toParamString(filters)}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async create(sequence) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/task_sequences`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sequence),
    }).then(toJSON);
  }

  static async show(id) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/task_sequences/${id}`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
      },
    }).then(toJSON);
  }

  static async edit(task_sequence) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/task_sequences/${task_sequence.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(task_sequence),
      }
    ).then(toJSON);
  }

  static async delete(options) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/task_sequences/${options.id}?new_task_sequence_id=${options.newTaskSequenceId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
  }
}

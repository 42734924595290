import React, { cloneElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useCallFlowMethods } from '../components/CallFlow/hooks';
import { CallFlowHeader, MappedOptions } from '../components/CallFlow';
import { availableOptions } from '../components/CallFlow/options/utils';
import { Container } from '../components/CallFlow/common/Container';
import { useGetCallFlowById } from '../../api/callFlow/queries';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';

const CallFlowVisits = () => {
  const {
    blocks,
    componentList,
    createApiChains,
    createApiBlocks,
    setComponentList,
    handleAddComponent,
    handleDeleteComponent,
    handleInsertComponent,
    getUpdatedInputParams,
    checkAllComponentsReady,
    handleDeleteBranchInBuilder,
    parseFromServerComponentList,
    handleMutateBuilderCallerPressOption,
    handleMutateComponentListByParametersInUI,
  } = useCallFlowMethods();

  const { callFlowId } = useParams();

  const [callFlowName, setCallFlowName] = useState();
  const [callFlowWebhook, setCallFlowWebhook] = useState();
  const [doSearchEmptyInputs, setDoSearchEmptyInputs] = useState(false);
  const [shouldRecord, setShouldRecord] = useState(false);

  const { isLoading } = useGetCallFlowById(callFlowId, {
    onSuccess: (payload) => {
      setShouldRecord(payload?.should_record);
      setCallFlowName(payload?.name);
      setCallFlowWebhook(payload?.webhook);
      parseFromServerComponentList(payload.blocks);
    },
  });

  const resetToInitial = () => {
    setComponentList([]);
    setCallFlowName('');
    setCallFlowWebhook('');
    setShouldRecord(false);
  };

  return (
    <SettingsCard sx={{ padding: '70px' }} showLoading={isLoading}>
      <Box sx={{ '.insertStep:last-of-type': { display: 'none!important' } }}>
        <CallFlowHeader
          callFlowId={callFlowId}
          resetToInitial={resetToInitial}
          shouldRecord={shouldRecord}
          setShouldRecord={setShouldRecord}
          callFlowName={callFlowName}
          setCallFlowName={setCallFlowName}
          callFlowWebhook={callFlowWebhook}
          setCallFlowWebhook={setCallFlowWebhook}
          createApiBlocks={() => createApiBlocks(componentList)}
          createApiChains={() => createApiChains(blocks)}
          parseFromServerComponentList={parseFromServerComponentList}
          isCallFlowReady={checkAllComponentsReady(componentList)}
          doSearchEmptyInputs={doSearchEmptyInputs}
          setDoSearchEmptyInputs={setDoSearchEmptyInputs}
        />
        <MappedOptions
          handleMutateComponentListByParametersInUI={
            handleMutateComponentListByParametersInUI
          }
          createApiBlocks={createApiBlocks}
          handleInsertComponent={handleInsertComponent}
          handleDeleteBranchInBuilder={handleDeleteBranchInBuilder}
          handleMutateBuilderCallerPressOption={handleMutateBuilderCallerPressOption}
          handleDeleteComponent={handleDeleteComponent}
          handleAddComponent={handleAddComponent}
          componentList={componentList}
          doSearchEmptyInputs={doSearchEmptyInputs}
          getUpdatedInputParams={getUpdatedInputParams}
        />
        {componentList.some(
          (el) =>
            el.action === 'respond' ||
            el.action === 'hangup' ||
            el.action === 'ai' ||
            el.action === 'initialList'
        ) || (
          <Container
            title={availableOptions.title}
            isListOptions={availableOptions.isListOptions}
          >
            <Box>
              <Typography textAlign="center" variant="h5" pt={4}>
                {availableOptions.title}
              </Typography>
              <Box>
                {cloneElement(availableOptions.component, { handleAddComponent })}
              </Box>
            </Box>
          </Container>
        )}
      </Box>
    </SettingsCard>
  );
};

export default CallFlowVisits;

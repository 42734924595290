/*
  !Will be removed.
*/
import { FormProvider } from 'react-hook-form';
import { Prompt } from 'react-router-dom';

/**
 *
 *
 * @param {*} { methods, onSubmit, useDirtyFormCheck, children }
 * @return {*}
 */
const FormProviderWrapper = ({
  methods,
  onSubmit,
  useDirtyFormCheck,
  children,
  onChange,
  onSelect,
}) => {
  const {
    formState: { isDirty },
  } = { ...methods };

  return (
    <>
      {useDirtyFormCheck && isDirty && (
        <Prompt
          when={isDirty}
          message={(location) => {
            if (location.pathname.includes('logout')) return true;
            return typeof useDirtyFormCheck === 'string'
              ? useDirtyFormCheck
              : 'You have unsaved changes, are you sure you would like to leave this page?';
          }}
        />
      )}
      <FormProvider {...methods}>
        <form onSubmit={onSubmit} onSelect={onSelect} onChange={onChange}>
          {children}
        </form>
      </FormProvider>
    </>
  );
};

export default FormProviderWrapper;

import React from 'react';
import { Row, Col, Button, Dropdown, ButtonGroup } from 'react-bootstrap';

const TemplateModalFooter = ({
  isSaveAsNew,
  setIsSaveAsNew,
  isCreateTemplate,
  isButtonDisabled,
  saveAsNewName,
  setSaveAsNewName,
  saveAsNewTemplateHelper,
  saveTemplateChangesHelper,
  createNewTemplateHelper,
  closeModal,
}) => {
  return (
    <>
      {isSaveAsNew ? (
        <Row className="w-100">
          <Col xs={0} sm={4} lg={5} />
          <Col className="px-1">
            <Button
              variant="primary"
              className="w-100"
              disabled={saveAsNewName.trim() === ''}
              onClick={() => saveAsNewTemplateHelper()}
            >
              Save
            </Button>
          </Col>
          <Col className="px-1">
            <Button
              className="btn btn-light w-100"
              onClick={() => {
                setIsSaveAsNew(false);
                setSaveAsNewName('');
              }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      ) : (
        <Row className="w-100">
          <Col xs={0} sm={4} lg={5} />
          {!isCreateTemplate ? (
            <>
              <Col className="px-1">
                <Dropdown
                  className="w-100"
                  as={ButtonGroup}
                  disabled={isButtonDisabled()}
                >
                  <Button
                    variant="primary"
                    className="w-100 text-nowrap"
                    onClick={() => saveTemplateChangesHelper()}
                  >
                    Save Changes
                  </Button>
                  <Dropdown.Toggle split variant="primary" />
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setIsSaveAsNew(true)}>
                      Save as New Template
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </>
          ) : (
            <Col className="px-1">
              <Button
                className="w-100 text-nowrap"
                disabled={isButtonDisabled()}
                variant="primary"
                onClick={() => createNewTemplateHelper()}
              >
                Create Template
              </Button>
            </Col>
          )}
          <Col className="px-1">
            <Button className="btn btn-light w-100" onClick={closeModal}>
              Cancel
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

export default TemplateModalFooter;

import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import AIModulesFunctionDefinitions from './AIModulesFunctionDefinitions';
import { aimodulesFunctionDefinitionsQueryKeys } from './queries';
import { aiprofileQueryKeys } from '../aiprofile/queries';

export const useAiModuleFunctionDefinitionsCreate = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => AIModulesFunctionDefinitions.create(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(aimodulesFunctionDefinitionsQueryKeys.all);
      queryClient.invalidateQueries(aiprofileQueryKeys.all);
    },
  });
};

export const useAiModuleFunctionDefinitionsEdit = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => AIModulesFunctionDefinitions.edit({ ...params }), {
    onSuccess: () => {
      queryClient.invalidateQueries(aimodulesFunctionDefinitionsQueryKeys.all);
      queryClient.invalidateQueries(aiprofileQueryKeys.all);
    },
  });
};

export const useAiModuleFunctionDefinitionsDelete = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => AIModulesFunctionDefinitions.delete(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(aimodulesFunctionDefinitionsQueryKeys.all);
      queryClient.invalidateQueries(aiprofileQueryKeys.all);
    },
  });
};

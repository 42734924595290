import { useQuery } from 'react-query';

import { useOrg } from '../../contexts/OrgProvider';
import { ONE_HOUR, queryKeyFactory } from '../../MUI/services/queryClientConfig';
import AccountFieldsEntity from './accountFieldsEntity';

export const accountFieldsQueryKeys = queryKeyFactory('account_fields');

export const useAccountFields = (params = [], options) => {
  const transformToItems = (data) => {
    return data.items;
  };
  const { id, isMasterAccount } = useOrg();
  const filters = [['page_size', '10000'], ...params];
  filters.push(['organization_id[]', id]);
  return useQuery(
    accountFieldsQueryKeys.list(filters),
    () => AccountFieldsEntity.list(filters),
    {
      select: transformToItems,
      staleTime: ONE_HOUR,
      ...options,
    }
  );
};

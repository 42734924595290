import React, { useState, useEffect } from 'react';
import { Nav, Navbar, NavDropdown, Button } from 'react-bootstrap';

import LeadSearch from '../../MUI/components/LeadSearch';
import { routerPathWithoutUuid } from '../../util/AppUtils';
import { getActiveOrg, getActiveOrgName, setActiveOrg } from '../../util/UserUtils';
import { Link } from 'react-router-dom';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { colorValues } from '../../util/Constants';
import { AiOutlineMenu } from 'react-icons/ai';
import { useLayout } from '../../MUI/contexts/LayoutProvider';
import { useUsersCurrent } from '../../MUI/services/users/queries';

const TopNavigationGroup = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);
  const { data: user } = useUsersCurrent();
  const { mobileNavIsOpen, setMobileNavIsOpen } = useLayout();

  useEffect(() => {
    window.addEventListener('resize', (event) => {
      setIsMobile(event.target.innerWidth <= 992);
    });
    return () => {
      window.removeEventListener('resize', (event) => {
        setIsMobile(event.target.innerWidth <= 992);
      });
    };
  }, []);

  const handleOnOrganizationClick = (org) => {
    setActiveOrg(org);
    window.location.pathname = routerPathWithoutUuid();
  };

  const renderOrganizationSwitcher = () => {
    return (
      user &&
      user.authorizations &&
      user.authorizations.table &&
      user.authorizations.table.organizations &&
      user.authorizations.table.organizations.length > 1 && (
        <>
          <div className="top-nav-org-list">
            {user.authorizations.table.organizations.map((org) => {
              if (org.id != getActiveOrg()) {
                return (
                  <NavDropdown.Item
                    key={org.id}
                    onClick={(_) => {
                      handleOnOrganizationClick(org);
                    }}
                  >
                    {org.name}
                  </NavDropdown.Item>
                );
              }
            })}
          </div>
          <NavDropdown.Divider />
        </>
      )
    );
  };

  return (
    <Navbar
      variant="light"
      bg="white"
      className="z-255 rounded top-nav-text top-navbar pl-0 pl-lg-3 d-flex"
      expand="lg"
    >
      {isMobile && (
        <Button
          variant="outlined-primary"
          onClick={() => setMobileNavIsOpen((prev) => !prev)}
        >
          <AiOutlineMenu size={30} />
        </Button>
      )}
      <LeadSearch />
      <Navbar.Toggle aria-controls="responsesive-navbar-nav">
        <BiDotsVerticalRounded size="40px" color={colorValues.greyColor} />
      </Navbar.Toggle>
      <Navbar.Collapse className="justify-content-end">
        <NavDropdown title={getActiveOrgName()} alignRight={!isMobile}>
          {renderOrganizationSwitcher()}
          <Nav.Link
            className="pl-3"
            href="https://support.leadsigma.com"
            target="_blank"
          >
            Help
          </Nav.Link>
          <Nav.Link className="pl-3" href={process.env.REACT_APP_LOGOUT_URL}>
            Logout
          </Nav.Link>
        </NavDropdown>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default TopNavigationGroup;

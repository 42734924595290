import { useEffect } from 'react';

export const Hangup = ({
  handleMutateComponentListByParametersInUI,
  parameters,
  id,
}) => {
  useEffect(() => {
    // defineParams = object with properties what will change and write to ComponentList
    const defineParams = {};
    const neededParams =
      parameters && Object.keys(parameters).length > 0 ? parameters : defineParams;
    handleMutateComponentListByParametersInUI(neededParams, id);
  }, []);
  return <h1>Hangup</h1>;
};

import Cookies from 'js-cookie';
import { getActiveOrg } from '../util/UserUtils';

export default class LeadBatch {
  static async create(leadBatch) {
    leadBatch['organization_id'] = getActiveOrg();
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/lead_batches`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(leadBatch),
    });
    if (response.status >= 200 && response.status < 300) {
      return response.json();
    }
    return null;
  }
}

import OrganizationDefaults from './OrganizationDefaults';
import { useMutation, useQueryClient } from 'react-query';
import { mutationKeyFactory } from '../queryClientConfig';

const orgDefaultKeys = mutationKeyFactory('organization_defaults');

export const useOrgDefaultEdit = (options) => {
  const queryClient = useQueryClient();
  return useMutation((orgDefault) => OrganizationDefaults.edit(orgDefault), {
    mutationKey: orgDefaultKeys.edit(),
    onSuccess: () => {
      queryClient.invalidateQueries(orgDefaultKeys.all);
    },
    ...options,
  });
};

export const useOrgDefaultCreate = (options) => {
  const queryClient = useQueryClient();
  return useMutation((orgDefault) => OrganizationDefaults.create(orgDefault), {
    mutationKey: orgDefaultKeys.create(),
    onSuccess: () => {
      queryClient.invalidateQueries(orgDefaultKeys.all);
    },
    ...options,
  });
};

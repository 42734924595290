import { useMutation } from 'react-query';
import SharedTemplates from './SharedTemplates';
import { useOrg } from '../../../contexts/OrgProvider';

export const useSharedTemplatesCreate = (options) => {
  const { id } = useOrg();
  return useMutation(
    (sharedTemplate) =>
      SharedTemplates.create({ organization_id: id, ...sharedTemplate }),
    {
      ...options,
    }
  );
};

import { convertDateFilterToQueryParams } from './DateFilter';

export const filterMapStringValueToParamArray = (filterKey, string) => {
  return [[filterKey, string]];
};

export const filterMapArrayValueToParams = (filterKey, arr) => {
  let pairsArr = [];
  arr.forEach(({ filterValue }) => pairsArr.push([`${filterKey}[]`, filterValue]));
  return pairsArr;
};

const filterMapValuesToParamHandlers = {
  target_id: filterMapArrayValueToParams,
  organization_id: filterMapArrayValueToParams,
  cnam: filterMapStringValueToParamArray,
  cohort: filterMapStringValueToParamArray,
  target_name: filterMapStringValueToParamArray,
  target_phone: filterMapStringValueToParamArray,
  tracking_phone: filterMapStringValueToParamArray,
  from: filterMapStringValueToParamArray,
  created_at: (k, v) => convertDateFilterToQueryParams('created_at', k, v),
  last_contacted_at: (k, v) => {
    return convertDateFilterToQueryParams('last_contacted_at', k, v);
  },
  next_task_due_at: (k, v) => {
    return convertDateFilterToQueryParams('next_task_due_at', k, v);
  },
  date_range: (k, v) => {
    return convertDateFilterToQueryParams('date_range', k, v);
  },
  first_name: filterMapStringValueToParamArray,
  last_name: filterMapStringValueToParamArray,
  email_address: filterMapStringValueToParamArray,
  phone_number: filterMapStringValueToParamArray,
  phone_pool_id: filterMapArrayValueToParams,
  task_sequence_id: filterMapArrayValueToParams,
  task_sequence_name: filterMapArrayValueToParams,
  status: filterMapArrayValueToParams,
  session_browser: filterMapStringValueToParamArray,
  session_device: filterMapStringValueToParamArray,
  session_user_agent: filterMapStringValueToParamArray,
  source_name: filterMapArrayValueToParams,
  source_id: filterMapArrayValueToParams,
  team_id: filterMapArrayValueToParams,
  assigned_user_id: filterMapArrayValueToParams,
  lost_reason_name: filterMapArrayValueToParams,
  visit_campaign: filterMapStringValueToParamArray,
  visit_referring_url: filterMapStringValueToParamArray,
  visit_source: filterMapStringValueToParamArray,
  visit_url: filterMapStringValueToParamArray,
  custom_field_values: (filterKey, filterMapValue) => {
    let customFieldFilterParams = [];
    const customFieldsIdMap = filterMapValue['customFieldsIdMap'];
    Object.entries(customFieldsIdMap).forEach(
      ([customFieldId, { operator, filterValue }]) => {
        if (filterValue?.length > 0)
          customFieldFilterParams.push([
            'custom_field_values[]',
            `${customFieldId}::${operator}::${filterValue}`,
          ]);
      }
    );
    return customFieldFilterParams;
  },
};

export const parseFilterParams = (filterMap, isMasterAccount, orgId) => {
  let queryParamPairs = [];
  let tmp_params = [];
  Object.entries(filterMap).forEach(([filterMapKey, filterMapValue]) => {
    const isCustomFieldsIdMap = () => {
      if (filterMapValue?.customFieldsIdMap)
        return Object.entries(filterMapValue.customFieldsIdMap).length > 0;
      else return false;
    };

    if (filterMapValue?.length > 0 || isCustomFieldsIdMap()) {
      queryParamPairs.push(
        ...filterMapValuesToParamHandlers[filterMapKey](filterMapKey, filterMapValue)
      );
    }
    tmp_params = [...queryParamPairs];
    if (filterMap?.organization_id?.length === 0 && !isMasterAccount) {
      tmp_params.push(['organization_id[]', orgId]);
    }
  });

  return [tmp_params, queryParamPairs];
};

export const getAccountName = (item, accounts) => {
  const foundAccount = accounts?.find(
    ({ id }) => String(item.organization_id) === String(id)
  );
  if (foundAccount) return foundAccount.name;
  // no account exists that maps to given account id
  return null;
};

import { useState } from 'react';
import {
  Box,
  MenuItem,
  Button,
  FormControl,
  Select,
  InputLabel,
  TextField as MuiTextField,
} from '@mui/material';
import { LeadSourcesIcon, XIcon, CheckIcon } from '../../../../theme/icons';

export const FieldOption = ({
  id,
  mutate,
  isActive,
  itemSelectValue,
  selectOptionsProp,
  setSelectOptionsProp,
  setSelectOptionsChanges,
  setIsSelectOptionsChanged,
  setTakenOptionsIndexes,
  takenOptionIndexes,
  handleRemoveOption,
  defineActiveOption,
  handleMutateBuilderCallerPressOption,
}) => {
  const [selectValue, setSelectValue] = useState(+itemSelectValue);
  const [labelValue, setLabelValue] = useState(
    () => selectOptionsProp.find((option) => option.digit === selectValue).text
  );
  const [selectOptions, setSelectOptions] = useState(selectOptionsProp);

  const handleChange = (e) => {
    setLabelValue(e.target.value);
    setSelectOptions((prevSelectOptions) =>
      prevSelectOptions.map((option) => {
        if (option.digit === selectValue) {
          return {
            ...option,
            text: e.target.value,
          };
        }
        return option;
      })
    );
    setSelectOptionsProp((prevProps) =>
      prevProps.map((option) => {
        if (option.digit === selectValue) {
          return {
            ...option,
            text: e.target.value,
          };
        }
        return option;
      })
    );
  };

  const handleSelect = (e) => {
    const [newDigit, oldDigit] = [e.target.value, selectValue];
    const [newOption, oldOption] = [
      {
        digit: newDigit,
        text: selectOptions.find((option) => option.digit === oldDigit).text,
      },
      {
        digit: oldDigit,
        text: '',
      },
    ];

    setSelectOptions((prevSelectOptions) =>
      prevSelectOptions.map((option) => {
        if (option.digit === newDigit) {
          return newOption;
        } else if (option.digit === oldDigit) {
          return oldOption;
        }

        return option;
      })
    );

    setSelectOptionsProp((prevSelectOptionsProp) =>
      prevSelectOptionsProp.map((option) => {
        if (option.digit === newDigit) {
          return newOption;
        } else if (option.digit === oldDigit) {
          return oldOption;
        }

        return option;
      })
    );

    setIsSelectOptionsChanged(true);
    setSelectOptionsChanges({
      optionMovedFrom: oldDigit,
      optionMovedTo: newDigit,
    });

    setTakenOptionsIndexes((list) => {
      list.delete(oldDigit);
      list.add(newDigit);
      return list;
    });
    setSelectValue(newDigit);
    mutate(oldDigit, newDigit);

    if (isActive) handleMutateBuilderCallerPressOption(newDigit, id);
  };

  const handleClick = (e) => {
    if (!isActive) {
      defineActiveOption(e, itemSelectValue, selectValue.toString());
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        zIndex: '3',
      }}
    >
      <FormControl sx={{ width: '80%' }}>
        <Box
          data-parent
          onClick={(e) => handleClick(e)}
          sx={{
            margin: '0 0 10px 0',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            position: 'relative',
            zIndex: '2',
            background: isActive ? '#b1dcff' : 'initial',
            borderLeft: isActive ? '3px solid steelblue' : '3px solid transparent',
            '&:hover': {
              cursor: 'pointer',
              background: '#b1dcff',
            },
          }}
        >
          <Box sx={{ display: 'flex', width: '20%' }}>
            <InputLabel sx={{ padding: '0 10px' }}>Caller press</InputLabel>
            <Select
              sx={{ width: '100%' }}
              value={selectValue}
              size="small"
              onChange={handleSelect}
            >
              {Array.from(Array(9), (_, i) => i + 1).map((digit) => {
                return (
                  <MenuItem
                    disabled={takenOptionIndexes.has(digit)}
                    key={digit}
                    value={digit}
                  >
                    {digit}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <MuiTextField
            sx={{ m: '0 10px', width: '60%' }}
            onChange={handleChange}
            value={labelValue}
            placeholder="type your text here"
            label="text"
            size="small"
          />
          {isActive || (
            <Button
              sx={{
                p: '0',
                m: '0 15px 0 0',
                width: '15px',
                height: '15px',
                minWidth: '15px',
                minHeight: '15px',
                '& span': {
                  m: '0',
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveOption(selectValue);
              }}
              variant="text"
              endIcon={<XIcon />}
            />
          )}
        </Box>
      </FormControl>
      {!isActive ? (
        <LeadSourcesIcon sx={{ m: '0 0 0 25px' }} />
      ) : (
        <CheckIcon sx={{ m: '0 0 0 25px' }} />
      )}
    </Box>
  );
};

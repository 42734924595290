import {
  CreationIcon,
  HumanGreetingIcon,
  MenuIcon,
  PhoneDialIcon,
  PhoneHangupIcon,
  PlusIcon,
  VoicemailIcon,
} from '../../theme/icons';

export const defineUiElements = (componentName, needIcon) => {
  switch (componentName) {
    case 'Create Lead':
      return needIcon ? <CreationIcon /> : 'CreationIcon';
    case 'Dial':
      return needIcon ? <PhoneDialIcon /> : 'PhoneDialIcon';
    case 'Greeting':
      return needIcon ? <HumanGreetingIcon /> : 'HumanGreetingIcon';
    case 'Hangup':
      return needIcon ? <PhoneHangupIcon /> : 'PhoneHangupIcon';
    case 'Menu':
      return needIcon ? <MenuIcon /> : 'MenuIcon';
    case 'VoiceMail':
      return needIcon ? <VoicemailIcon /> : 'VoicemailIcon';
    default:
      return needIcon ? <PlusIcon /> : 'PlusIcon';
  }
};

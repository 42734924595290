import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Button,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import HookForm from '../components/HookForm';
import { LoadingButton } from '@mui/lab';
import { PlusIcon, EditIcon, DeleteIcon } from '../theme/icons';
import { useOrg } from '../../contexts/OrgProvider';
import { fireDialog } from '../components/Dialog';
import { useAiProfileShow } from '../../api/aiprofile/queries';
import { useCustomFields } from '../../api/customFields/queries';
import {
  useOverrideArgumentsCreate,
  useOverrideArgumentsDelete,
  useOverrideArgumentsEdit,
} from '../../api/overrideArguments/mutation';
import { useOverrideArgumentsList } from '../../api/overrideArguments/queries';

export default function OverrideArgumentsPage() {
  const { id: orgId } = useOrg();
  const { agentID } = useParams();

  const [updatedoverrideArrgumentsList, setUpdtaedOverrideArrguments] = useState();

  const {
    data: customFields,
    isFetching: areCustomFieldsLoading,
    isError: didLoadingCustomFieldsError,
  } = useCustomFields([['organization_id[]', orgId]]);

  const { data: OverrideArgumentsList } = useOverrideArgumentsList();

  const { data: aiModulesShow } = useAiProfileShow(agentID);

  const onCreate = () => {
    fireDialog((promiseProps) =>
      AddOverrideArguments({ agentID, customFields, orgId, ...promiseProps })
    );
  };

  const onEditClick = (item) => {
    fireDialog((promiseProps) =>
      EditOverrideArguments({
        item,
        customFields,
        orgId,
        aiModulesShow,
        ...promiseProps,
      })
    );
  };

  const onDeleteClick = (id) => {
    fireDialog((promiseProps) => DeleteOverrideArguments({ id, ...promiseProps }));
  };

  useEffect(() => {
    if (customFields && aiModulesShow) {
      // Find the name of the custom field by id
      const findNameById = (collection, id) => {
        const item = collection?.find((item) => item.id === id);
        return item ? item.name : null;
      };

      // Map over each item in aiModulesShow and update it with the names
      const newAiMappingsList = aiModulesShow?.override_arguments?.map(
        (argument) => {
          const customFieldName = findNameById(
            customFields,
            argument?.custom_field_id
          );
          return {
            ...argument,
            custom_field_name: customFieldName,
          };
        }
      );
      setUpdtaedOverrideArrguments(newAiMappingsList);
    }
  }, [customFields, aiModulesShow, OverrideArgumentsList]);

  return (
    <Box sx={{ mt: 4 }}>
      <h6>Override Arguments</h6>
      <TableContainer sx={{ mt: 1, mb: 2 }}>
        <Table>
          <TableHead>
            <TableRow size="small">
              <TableCell>Name</TableCell>
              <TableCell>Custom</TableCell>
              <TableCell>Required</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {updatedoverrideArrgumentsList?.map((item) => (
              <TableRow key={item.id} size="small">
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.custom_field_name}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={item.required}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </TableCell>
                <TableCell align="right">
                  <Button
                    startIcon={<EditIcon />}
                    size="small"
                    variant="text"
                    color="primary"
                    sx={{ marginRight: 1 }}
                    onClick={() => onEditClick(item)}
                  >
                    Edit
                  </Button>
                  <Button
                    startIcon={<DeleteIcon />}
                    size="small"
                    variant="text"
                    color="error"
                    sx={{ marginRight: 1 }}
                    onClick={() => onDeleteClick(item.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button startIcon={<PlusIcon />} onClick={onCreate} sx={{ marginRight: 1 }}>
        Add New
      </Button>
    </Box>
  );
}

const AddOverrideArguments = ({
  agentID,
  customFields,
  isOpen,
  onReject,
  onResolve,
}) => {
  const {
    mutate: createOverrideArgumetns,
    isLoading,
    isError,
    isSuccess,
  } = useOverrideArgumentsCreate();

  const [selectedCustomField, setSelectedCustomField] = useState();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [required, setRequired] = useState();
  const [selectdatatype, setSelectedDataType] = useState();

  const handleCreateSubmit = () => {
    createOverrideArgumetns({
      ai_profile_id: agentID,
      custom_field_id: selectedCustomField || '',
      name: name || '',
      description: description || '',
      required: required || '',
      data_type: selectdatatype || '',
    });
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        onResolve();
      }, 1000);
    }
  }, [isError, isSuccess]);

  const defaultValues = {
    ai_profile_id: '',
    custom_field_id: '',
    name: '',
    description: '',
    required: '',
    data_type: '',
  };

  const useFormProps = {
    defaultValues,
  };

  const handleChangeRequired = () => {
    setRequired(true);
  };

  const dataTypes = ['string', 'number', 'boolean'];

  return (
    <Box>
      <Dialog open={isOpen} onClose={onReject}>
        <DialogTitle>Create Override Arguments</DialogTitle>
        <HookForm useFormProps={useFormProps} onSubmit={handleCreateSubmit}>
          <DialogContent>
            {isError && (
              <Alert severity="error">
                There was a problem while creating override arguments.
              </Alert>
            )}
            {isSuccess && (
              <Alert severity="success">
                Override Arguments created successfully.
              </Alert>
            )}
            <Box display="flex" flexDirection="column" rowGap={2}>
              <TextField
                label="Name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                label="Description"
                type="text"
                multiline
                rows={5}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <FormControl size="small">
                <InputLabel id="custom_field_id">Custom Field</InputLabel>
                <Select
                  id="custom_field_id"
                  label="Custom Field"
                  value={selectedCustomField || ''}
                  onChange={(e) => setSelectedCustomField(e.target.value)}
                >
                  {customFields &&
                    customFields?.length > 0 &&
                    customFields?.map((customField) => (
                      <MenuItem key={customField.name} value={customField.id}>
                        {customField.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl size="small">
                <InputLabel>Data Type</InputLabel>
                <Select
                  label="Data Type"
                  value={selectdatatype}
                  onChange={(e) => setSelectedDataType(e.target.value)}
                >
                  {dataTypes.map((type) => (
                    <MenuItem value={type} key={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControlLabel
                style={{ marginLeft: -10 }}
                control={
                  <Checkbox
                    checked={required}
                    onChange={handleChangeRequired}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Required"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="text" color="inherit" onClick={onReject}>
              Cancel
            </Button>
            <LoadingButton type="submit" loading={isLoading} disabled={isSuccess}>
              Confirm
            </LoadingButton>
          </DialogActions>
        </HookForm>
      </Dialog>
    </Box>
  );
};

const EditOverrideArguments = ({
  item,
  customFields,
  isOpen,
  onReject,
  onResolve,
}) => {
  const {
    mutate: aiOverrideArgumentsedit,
    isLoading,
    isError,
    isSuccess,
  } = useOverrideArgumentsEdit();

  const [selectedCustomField, setSelectedCustomField] = useState(
    item?.custom_field_id
  );
  const [name, setName] = useState(item?.name);
  const [description, setDescription] = useState(item?.description);
  const [required, setRequired] = useState(item?.required);
  const [selectdatatype, setSelectedDataType] = useState(item?.data_type);

  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        onResolve();
      }, 1000);
    }
  }, [isError, isSuccess]);

  const defaultValues = {
    custom_field_id: '',
    name: '',
    description: '',
    required: '',
    data_type: '',
  };

  const useFormProps = {
    defaultValues,
  };

  const handleCreateSubmit = () => {
    aiOverrideArgumentsedit({
      id: item.id,
      custom_field_id: selectedCustomField,
      name: name,
      description: description,
      required: required,
      data_type: selectdatatype,
    });
  };

  const handleChange = (event) => {
    setRequired(event.target.checked);
  };
  const dataTypes = ['string', 'number', 'boolean'];

  return (
    <Box>
      <Dialog open={isOpen} onClose={onReject}>
        <DialogTitle>Edit Override Arguments</DialogTitle>
        <HookForm useFormProps={useFormProps} onSubmit={handleCreateSubmit}>
          <DialogContent>
            {isError && (
              <Alert severity="error">
                There was a problem while updating override arguments.
              </Alert>
            )}
            {isSuccess && (
              <Alert severity="success">
                Override Arguments updated successfully.
              </Alert>
            )}
            <Box display="flex" flexDirection="column" rowGap={2}>
              <TextField
                label="Name"
                type="text"
                value={name || ''}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                label="Description"
                type="text"
                multiline
                rows={5}
                value={description || ''}
                onChange={(e) => setDescription(e.target.value)}
              />
              <FormControl size="small">
                <InputLabel id="custom_field_id">Custom Field</InputLabel>
                <Select
                  id="custom_field_id"
                  label="Custom Field"
                  value={selectedCustomField || ''}
                  onChange={(e) => setSelectedCustomField(e.target.value)}
                >
                  {customFields &&
                    customFields?.length > 0 &&
                    customFields?.map((customField) => (
                      <MenuItem key={customField.name} value={customField.id}>
                        {customField.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl size="small">
                <InputLabel>Data Type</InputLabel>
                <Select
                  label="Data Type"
                  value={selectdatatype || ''}
                  onChange={(e) => setSelectedDataType(e.target.value)}
                >
                  {dataTypes.map((type) => (
                    <MenuItem value={type} key={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <FormControlLabel
              style={{ marginLeft: -10 }}
              control={
                <Checkbox
                  checked={required}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Required"
            />
          </DialogContent>
          <DialogActions>
            <Button variant="text" color="inherit" onClick={onReject}>
              Cancel
            </Button>
            <LoadingButton type="submit" loading={isLoading} disabled={isSuccess}>
              Confirm
            </LoadingButton>
          </DialogActions>
        </HookForm>
      </Dialog>
    </Box>
  );
};

const DeleteOverrideArguments = ({ id, isOpen, onReject, onResolve }) => {
  const {
    mutate: deleteOverrideArguments,
    isError,
    isSuccess,
    isLoading,
  } = useOverrideArgumentsDelete();

  const handledeleteSubmit = () => {
    deleteOverrideArguments(id);
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        onResolve();
      }, 1000);
    }
  }, [isError, isSuccess]);

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Delete Override Arguments</DialogTitle>
      <DialogContent>
        {isError && (
          <Alert severity="error">
            There was a problem while deleting override arguments.
          </Alert>
        )}
        {isSuccess && <Alert severity="success">Deleted successfully.</Alert>}
        Are you sure to override arguments.
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          loading={isLoading}
          disabled={isSuccess}
          onClick={handledeleteSubmit}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

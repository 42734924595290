import Cookies from 'js-cookie';
import { handleApiErrors } from '../util/HandleApiErrors';
import { getActiveOrg } from '../util/UserUtils';

export default class Email {
  static async list(filters = {}) {
    filters['organization_id'] = getActiveOrg();
    const params = new URLSearchParams(filters).toString();
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/emails?${params}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    );
    return response.json();
  }

  static async edit(email) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/email_addresses/${email.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(email),
      }
    );
    return response.json();
  }

  static async create(email) {
    email['organization_id'] = getActiveOrg();
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/email_addresses`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(email),
      }
    );
    return response.json();
  }

  static async send(email, mailbox_id) {
    return await fetch(
      `${process.env.REACT_APP_EMAIL_SERVICE}/mailboxes/${mailbox_id}/send_email`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(email),
      }
    ).then(handleApiErrors);
  }

  static async ses_send(email, lead_id) {
    return await fetch(
      `${process.env.REACT_APP_SES_EMAIL_API}/leads/${lead_id}/emails`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(email),
      }
    ).then(handleApiErrors);
  }
}

import { useMutation, useQueryClient } from 'react-query';
import Tasks from './Tasks';
import { useOrg } from '../../../contexts/OrgProvider';
import { mutationKeyFactory } from '../queryClientConfig';
import { tasksQueryKeys } from './queries';

export const tasksMutationKeys = {
  ...mutationKeyFactory('tasks'),
};

export const useTasksCreate = (options) => {
  const { id } = useOrg();
  return useMutation((task) => Tasks.create({ organization_id: id, ...task }), {
    mutationKey: tasksMutationKeys.create(),
    ...options,
  });
};

export const useTasksEdit = (options) => {
  const queryClient = useQueryClient();
  const { id } = useOrg();
  return useMutation((task) => Tasks.edit({ organization_id: id, ...task }), {
    mutationKey: tasksMutationKeys.edit(),
    onSuccess: (data, vars) => {
      queryClient.invalidateQueries(tasksQueryKeys.lists());
      queryClient.invalidateQueries(tasksQueryKeys.show(vars.id));
    },
    ...options,
  });
};

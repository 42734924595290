import React from 'react';
import { Row, Col, Alert } from 'react-bootstrap';

const TemplateErrorBanner = ({ errorDisplay, setErrorDisplay }) => {
  return (
    <Alert
      show={errorDisplay}
      variant="danger"
      onClose={() => setErrorDisplay(null)}
      dismissible
    >
      {errorDisplay}
    </Alert>
  );
};

export default TemplateErrorBanner;

import Cookies from 'js-cookie';

import { useState } from 'react';
import { Button, Box, Alert, Typography } from '@mui/material';
import { MdFileDownload } from 'react-icons/md';

import { DataGridStyle } from '../theme/styled/DataGridStyle';
import { CellSpanStyle } from '../theme/styled/CellSpanStyle';

import { dateToAbbreviatedDateTimeString } from '../services/dateTime';

import { useOrg } from '../../contexts/OrgProvider';

// Queries
import { useUsersCurrent } from '../services/users/queries';
import { useDownloadsList } from '../services/downloads/queries';

import Downloads from '../services/downloads/Downloads';

export default function DownloadsPage() {
  const { id: orgId } = useOrg();
  const { data: currentUser } = useUsersCurrent();
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(1);
  const [sortModel, setSortModel] = useState([
    { field: 'created_at', sort: 'desc' },
  ]);

  const handleSort = (newModel) => {
    setSortModel(newModel);
  };

  const getSorting = () => {
    if (sortModel.length === 0) {
      return [];
    }
    return [
      ['order', `csv_exports.${sortModel[0].field}`],
      ['direction', sortModel[0].sort],
    ];
  };

  const createDownloadsFilters = () => {
    const filters = [
      ['page', page],
      ['page_size', pageSize],
      ['user_id', currentUser?.id],
      ['organization_id', orgId],
      ...getSorting(),
    ];

    return filters;
  };

  const {
    data: downloadsData,
    isFetching: isLoadingPrefs,
    isError: didLoadingdownloadsDataFail,
  } = useDownloadsList(createDownloadsFilters());

  const CSVFileDownload = async (exportID) => {
    const responseData = await Downloads.getDownloadLink(exportID);
    window.open(responseData.json_url);
  };

  const columns = [
    {
      field: 'created_at',
      headerName: 'Created At',
      flex: 1,
      renderCell: ({ formattedValue }) => formattedValue,
      valueFormatter: ({ value }) => (
        <CellSpanStyle>{dateToAbbreviatedDateTimeString(value)}</CellSpanStyle>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      sortable: false,
      renderCell: (cell) => (
        <CellSpanStyle>
          <Typography textTransform="capitalize">{cell?.row?.status}</Typography>
        </CellSpanStyle>
      ),
    },
    {
      field: 'download',
      headerName: 'Download',
      flex: 1,
      sortable: false,
      renderCell: (cell) =>
        cell?.row?.status === 'complete' ? (
          <Button
            onClick={() => CSVFileDownload(cell?.row?.id)}
            startIcon={<MdFileDownload />}
            size="small"
            variant="text"
            color="primary"
            sx={{
              marginRight: 1,
            }}
          >
            Download
          </Button>
        ) : (
          <></>
        ),
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      {didLoadingdownloadsDataFail && !didLoadingdownloadsDataFail && (
        <Alert severity="error">
          There was an error loading your downloads. Try again.
        </Alert>
      )}
      <Box display="flex" justifyContent="space-between">
        <Typography fontSize={18} fontWeight={700}>
          Downloads
        </Typography>
      </Box>
      <Box display="flex" height="0%" width="100%" flexBasis="100%" gap={2}>
        <DataGridStyle
          disableSelectionOnClick
          rows={downloadsData?.items ?? []}
          columns={columns}
          loading={isLoadingPrefs}
          rowsPerPageOptions={[10, 25, 50]}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSort}
          rowCount={downloadsData?.total_count ?? 0}
          paginationMode="server"
          page={page - 1}
          onPageChange={(page) => setPage(page + 1)}
          onPageSizeChange={setPageSize}
          pageSize={pageSize}
          disableColumnFilter
          disableColumnMenu
          density="compact"
          sx={{
            '& .MuiDataGrid-cell': {
              outline: 'none',
              padding: 0,
              position: 'relative',
            },
          }}
        />
      </Box>
    </Box>
  );
}

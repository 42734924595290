import { styled } from '@mui/system';
import { ListItemButton } from '@mui/material';
import { alpha } from '@mui/material/styles';

export const NavLinkButtonStyle = styled(ListItemButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  height: 38,
  '&.active': {
    boxShadow: `0px 3px 5px -1px ${alpha(
      theme.palette.primary.main,
      0.2
    )},0px 5px 8px 0px ${alpha(
      theme.palette.primary.main,
      0.14
    )},0px 1px 14px 0px ${alpha(theme.palette.primary.main, 0.12)}`,
    backgroundColor: theme.palette.primary.main,
  },
  '&.active .MuiListItemText-root': {
    color: theme.palette.common.white,
  },
  '&.active .MuiSvgIcon-root': {
    color: theme.palette.common.white,
  },
}));

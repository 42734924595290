import { FaRobot } from 'react-icons/fa';

const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
const today = new Date().toLocaleString('en-US', options);
const NO_LOCK = 'NO_LOCK';
const LOCK_BY_USER = 'LOCK_BY_USER';
const LOCK_BY_OTHER = 'LOCK_BY_OTHER';
export const PENDING = 0;
export const WON = 1;
export const LOST = 2;

const filterTodayTasks = (tasks) => {
  return tasks.filter((task) => {
    const due = new Date(task.due_at).toLocaleString('en-US', options);
    if (task.completed_at == null && due === today) {
      return task;
    }
  });
};

const filterOverDueTasks = (tasks) => {
  return tasks.filter((task) => {
    const due = new Date(task.due_at).toLocaleString('en-US', options);
    if (task.completed_at == null && due < today) {
      return task;
    }
  });
};

const existingLockIsUsers = (leadLockMatch, user) =>
  leadLockMatch.user_id === user.id;

const getLeadLockMatch = (lead, locks) => ({
  ...locks?.find((lock) => lock.lead_id === lead.id),
});

const getLockStatus = (lead, locks, user) => {
  const tempLeadLock = getLeadLockMatch(lead, locks);
  if (existingLockIsUsers(tempLeadLock, user)) return LOCK_BY_USER;
  if (tempLeadLock.id && !existingLockIsUsers(tempLeadLock, user))
    return LOCK_BY_OTHER;
  return NO_LOCK;
};

const getTaskFilterName = (taskSequences, taskFilter) => {
  return taskFilter >= 0 ? taskSequences[taskFilter].name : '';
};

const getActionFilterName = (taskActions, taskActionFilter, isSecondValue) => {
  return taskActionFilter >= 0
    ? (isSecondValue ? ', ' : '') + taskActions[taskActionFilter].title
    : '';
};

// Takes in a task and renders it's automated status as an icon or as a centered block
const renderAutomatedStatus = (task, includeText) => {
  if (typeof task.automated == 'undefined' || task.automated === false) return;

  if (includeText === true) {
    return (
      <div className="task-automated-block">
        <span>This task is automated</span>
        <FaRobot className="mb-1 ml-1" />
      </div>
    );
  }
  return (
    <span className="task-automated-icon">
      <FaRobot className="ml-1" />
    </span>
  );
};

export const getTaskActionNameString = (taskAction) => {
  let newName;
  if (taskAction === 'FollowUp') {
    newName = 'Follow Up';
  } else if (taskAction === 'ReEngage') {
    newName = 'Re-Engage';
  } else {
    newName = taskAction;
  }

  return newName;
};

export {
  filterTodayTasks,
  filterOverDueTasks,
  NO_LOCK,
  LOCK_BY_OTHER,
  LOCK_BY_USER,
  getLockStatus,
  getTaskFilterName,
  getActionFilterName,
  renderAutomatedStatus,
};

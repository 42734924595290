import { useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import UnlockUserLockModal from './UnlockUserLockModal';

const UserSelfUnlockRow = ({ lead, pipeline, tasks }) => {
  const [showSelfUnlock, setShowSelfUnlock] = useState(false);
  const handleCloseUnlock = () => setShowSelfUnlock(false);

  return (
    <div>
      <UnlockUserLockModal
        showSelfUnlock={showSelfUnlock}
        handleCloseUnlock={handleCloseUnlock}
        lead={lead}
        pipeline={pipeline}
        tasks={tasks}
      />
      <hr />
      <Row>
        <Col>
          This lead is locked to you.{' '}
          <Button variant={'link'} onClick={() => setShowSelfUnlock(true)}>
            Unlock
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default UserSelfUnlockRow;

import { useQuery } from 'react-query';
import { queryKeyFactory } from '../queryClientConfig';
import Transcriptions from './Transcriptions';

export const transcriptionsIdQueryKeys = queryKeyFactory('callstracking');

export const useTranscriptionsShow = (transcription_id, options) => {
  return useQuery(
    transcriptionsIdQueryKeys.show({ transcription_id }),
    () => Transcriptions.show(transcription_id),
    {
      enabled: !!transcription_id,
      ...options,
    }
  );
};

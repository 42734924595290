import React, { useEffect, useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { DragIcon } from '../theme/icons';

export const DragableTable = ({ items }) => {
  return (
    <Droppable droppableId="droppable" direction="vertical">
      {(droppableProvided) => (
        <TableBody
          ref={droppableProvided.innerRef}
          {...droppableProvided.droppableProps}
        >
          {items.map((item, index) => (
            <Draggable key={item.key} draggableId={item.key} index={index}>
              {(draggableProvided, snapshot) => {
                return (
                  <TableRow
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    style={{
                      ...draggableProvided.draggableProps.style,
                      background: snapshot.isDragging
                        ? 'rgba(245,245,245, 0.75)'
                        : 'none',
                    }}
                    key={index}
                  >
                    <TableCell size="small">
                      <div {...draggableProvided.dragHandleProps}>
                        <DragIcon />
                      </div>
                    </TableCell>
                    {item.cols.map((itemCol, colIndex) => (
                      <TableCell key={colIndex}>{itemCol}</TableCell>
                    ))}
                  </TableRow>
                );
              }}
            </Draggable>
          ))}
          {droppableProvided.placeholder}
        </TableBody>
      )}
    </Droppable>
  );
};

import { useQuery } from 'react-query';
import { ONE_DAY, queryKeyFactory } from '../queryClientConfig';
import { useOrg } from '../../../contexts/OrgProvider';
import OrganizationDefaults from './OrganizationDefaults';

export const organizationDefaultsQueryKeys = queryKeyFactory(
  'organization_defaults'
);

export const useOrganizationDefaultsList = (params = []) => {
  const { id, isMasterAccount } = useOrg();
  return useQuery(
    organizationDefaultsQueryKeys.list([...params]),
    () => {
      const filters = [['page_size', 10000], ...params];
      if (!isMasterAccount) {
        filters.push(['organization_id[]', id]);
      }
      return OrganizationDefaults.list(filters);
    },
    {
      staleTime: ONE_DAY,
    }
  );
};

import { useQuery } from 'react-query';
import Mailboxes from './Mailboxes';
import { ONE_HOUR, queryKeyFactory } from '../queryClientConfig';

const mailboxesQueryKeys = queryKeyFactory('mailboxes');
mailboxesQueryKeys.owned = ['mailboxes', 'owned'];
mailboxesQueryKeys.authorized = ['mailboxes', 'authorized'];
export { mailboxesQueryKeys };

export const useMailboxesList = (params = [], options) => {
  return useQuery(mailboxesQueryKeys.list(params), () => Mailboxes.list(params), {
    staleTime: ONE_HOUR,
    ...options,
  });
};

import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import { aiprofileQueryKeys } from './queries';
import AIProfile from './AIProfile';

export const useAiProfileEdit = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => AIProfile.edit({ ...params }), {
    onSuccess: () => {
      queryClient.invalidateQueries(aiprofileQueryKeys.all);
    },
  });
};

export const useAiProfileDelete = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => AIProfile.delete(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(aiprofileQueryKeys.all);
    },
  });
};

export const useAiProfileCreate = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => AIProfile.create(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(aiprofileQueryKeys.all);
    },
  });
};

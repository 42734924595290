import { useMutation, useQueryClient } from 'react-query';
import { mutationKeyFactory } from '../queryClientConfig';
import AdsAccount from './AdsAccount';
import { adsAccountsQueryKeys } from './queries';

export const adsAccountsMutationKeys = {
  ...mutationKeyFactory('ads_accounts'),
};

export const useAdsAccountDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(AdsAccount.delete, {
    mutationKey: adsAccountsMutationKeys.delete(),
    onSuccess: () => queryClient.invalidateQueries(adsAccountsQueryKeys.all),
  });
};

export const useAdsAccountEdit = () => {
  const queryClient = useQueryClient();
  return useMutation(AdsAccount.edit, {
    mutationKey: adsAccountsMutationKeys.edit(),
    onSuccess: () => queryClient.invalidateQueries(adsAccountsQueryKeys.all),
  });
};

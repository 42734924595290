import Cookies from 'js-cookie';
import { toJSON, toParamString } from '../queryClientConfig';

export default class Mailboxes {
  static async list(filters) {
    return await fetch(
      `${process.env.REACT_APP_EMAIL_SERVICE}/mailboxes?${toParamString(filters)}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async send(email, mailbox_id) {
    return await fetch(
      `${process.env.REACT_APP_EMAIL_SERVICE}/mailboxes/${mailbox_id}/send_email`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(email),
      }
    ).then(toJSON);
  }

  static async edit(mailbox) {
    return await fetch(
      `${process.env.REACT_APP_EMAIL_SERVICE}/mailboxes/${mailbox.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(mailbox),
      }
    ).then(toJSON);
  }

  static async show(mailbox_id) {
    return await fetch(
      `${process.env.REACT_APP_EMAIL_SERVICE}/mailboxes/${mailbox_id}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async delete(id) {
    return await fetch(`${process.env.REACT_APP_EMAIL_SERVICE}/mailboxes/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
}

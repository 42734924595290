import React from 'react';

const NoDataDisplay = ({ title }) => {
  return (
    <div className="no-data-display">
      <img src={process.env.PUBLIC_URL + '/empty.png'} />
      <p className="text-sigma-grey title">{title}</p>
    </div>
  );
};

export default NoDataDisplay;

import Cookies from 'js-cookie';
import { toJSON } from '../queryClientConfig';

export default class NexHealthLocations {
  static async list(filters = {}) {
    return await fetch(
      `${process.env.REACT_APP_NEXHEALTH_WEBHOOK}/v1/locations?${new URLSearchParams(
        filters
      ).toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          'Content-Type': 'application/json',
        },
      }
    ).then(toJSON);
  }
}

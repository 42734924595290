import { useEffect, useState } from 'react';
import {
  alpha,
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { CssBaseline } from '@mui/material';
import LayoutProvider from '../contexts/LayoutProvider';
import { useUsersCurrent } from '../services/users/queries';

const PRIMARY_COLOR_MAIN = '#34d1b6';
const PRIMARY_COLOR_LIGHT = '#ebfbf8';
const SECONDARY_COLOR_MAIN = '#907bf0';
const ERROR_COLOR_MAIN = '#eb5757';
const PRIMARY_TEXT_COLOR = grey[700];

const createMuiTheme = (primaryColor) =>
  createTheme({
    typography: {
      fontFamily: 'Roboto, Arial, sans-serif',
      button: { textTransform: 'none' },
    },
    shape: { borderRadius: 3 },
    palette: {
      google: { main: '#4285F4', contrastText: '#fff' },
      text: { primary: PRIMARY_TEXT_COLOR },
      primary: {
        main: primaryColor,
        light: PRIMARY_COLOR_LIGHT,
        contrastText: '#fff',
      },
      secondary: {
        main: SECONDARY_COLOR_MAIN,
        light: alpha(SECONDARY_COLOR_MAIN, 0.4),
        contrastText: '#fff',
      },
      error: {
        main: ERROR_COLOR_MAIN,
      },
    },
    components: {
      MuiFormControlLabel: {
        styleOverrides: { root: { margin: 0 } },
      },
      MuiButton: {
        styleOverrides: {
          root: { '&:focus': { outline: 'none' } },
        },
      },
      MuiLink: {
        defaultProps: { underline: 'hover' },
        styleOverrides: { root: { '&:hover': { cursor: 'pointer' } } },
      },
      MuiCssBaseline: {
        styleOverrides: {
          '*': {
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
          },
          html: {
            width: '100%',
            height: '100%',
            WebkitOverflowScrolling: 'touch',
          },
          body: {
            width: '100%',
            height: '100%',
          },
          a: {
            '&:hover': { color: 'inherit' },
          },
          '#root': {
            width: '100%',
            height: '100%',
          },
          img: {
            display: 'block',
            maxWidth: '100%',
          },
          button: {
            '&:focus': { outline: 'none' },
          },
        },
      },
      MuiContainer: {
        defaultProps: {
          disableGutters: true,
          maxWidth: false,
        },
      },
      MuiButton: {
        styleOverrides: { root: { '&:focus': { outline: 'none' } } },
        defaultProps: { variant: 'contained', size: 'small', color: 'primary' },
      },
      MuiLoadingButton: {
        styleOverrides: { root: { '&:focus': { outline: 'none' } } },
        defaultProps: { variant: 'contained', size: 'small', color: 'primary' },
      },
      MuiDialog: {
        defaultProps: { PaperProps: { sx: { minWidth: '25vw' } } },
        styleOverrides: {
          root: {
            '& .MuiAlert-root': {
              marginBottom: '16px',
            },
            '& .MuiDialogTitle-root': {
              backgroundColor: `${grey[100]}`,
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: { paddingTop: '16px' },
        },
      },
      MuiDialogContent: {
        defaultProps: { dividers: true },

        styleOverrides: { root: { paddingTop: '16px' } },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            backgroundColor: `${grey[100]}`,
          },
        },
      },
      MuiTable: {
        defaultProps: { size: 'small' },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: 0,
            height: '36px',
          },
        },
      },
      MuiTextField: {
        defaultProps: { size: 'small' },
      },
      MuiAutocomplete: {
        defaultProps: {
          ListboxProps: {
            sx: {
              '.MuiAutocomplete-groupLabel': {
                lineHeight: '24px',
              },
            },
          },
        },
      },
      MuiChip: {
        defaultProps: { size: 'small' },
      },
      MuiTooltip: {
        defaultProps: { arrow: true },
      },
    },
  });

const MuiThemeProvider = ({ children }) => {
  const { data: currentUser } = useUsersCurrent();
  const [primaryColor, setPrimaryColor] = useState(PRIMARY_COLOR_MAIN);
  const [muiTheme, setMuiTheme] = useState(
    responsiveFontSizes(createMuiTheme(primaryColor))
  );

  const isValidHexColor = (value) => {
    const hexColorRegex = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{4}|[0-9A-Fa-f]{6}|[0-9A-Fa-f]{8})$/;

    return hexColorRegex.test(value);
  };

  const handleColorOverride = (color) => {
    if (isValidHexColor(color)) {
      setPrimaryColor(currentUser?.organization?.enterprise?.whitelabel_color);
      setMuiTheme(
        responsiveFontSizes(
          createMuiTheme(currentUser?.organization?.enterprise?.whitelabel_color)
        )
      );
    }
  };

  useEffect(() => {
    if (currentUser) {
      handleColorOverride(currentUser?.organization?.enterprise?.whitelabel_color);
    }
  }, [currentUser]);

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <LayoutProvider> {children}</LayoutProvider>
    </ThemeProvider>
  );
};

export default MuiThemeProvider;

import Cookies from 'js-cookie';
import { toJSON, toParamString } from '../queryClientConfig';

export default class Visits {
  static async list(filters = []) {
    return await fetch(
      `${process.env.REACT_APP_IVR}/visits?${toParamString(filters)}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(toJSON);
  }
}

import { Editor } from 'react-draft-wysiwyg';

const TextEditor = ({ editorState, setEditorState }) => {
  return (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'list',
            'textAlign',
            'history',
            'colorPicker',
            'link',
            'image',
          ],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          colorPicker: { inDropdown: true },
          link: { inDropdown: true },
          image: {
            urlEnabled: true,
            inDropdown: true,
          },
        }}
        editorClassName="editor-class"
      />
    </div>
  );
};

export default TextEditor;

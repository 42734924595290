import logo from '../../assets/images/leadsigma-white-logo.svg';
import { IoMdTimer } from 'react-icons/io';
import { colorValues } from '../../util/Constants';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { goToLoginUrl } from '../../AuthContainer';
import { Prompt } from 'react-router-dom';

export default function Logout({ setIsValidCognitoJwt }) {
  const handleLogBackInClick = () => {
    goToLoginUrl();
  };

  return (
    <Container className="vh-100" fluid>
      <Prompt
        message={(location) => {
          if (!location.pathname.includes('logout')) {
            setIsValidCognitoJwt(false);
            return goToLoginUrl();
          } else return false;
        }}
      />
      <Row className="h-100">
        <Col className="d-none bg-black h-100 d-lg-flex justify-content-center align-items-center">
          <div className="d-flex flex-column">
            <img src={logo} className="mb-3" />
            <p className="text-white text-center">Lightning fast lead response.</p>
            <p className="text-white text-center">Bulletproof follow-up.</p>
          </div>
        </Col>
        <Col className="h-100">
          <Col className="h-100 d-flex justify-content-center align-items-center">
            <div className="d-flex flex-column align-items-center">
              <h2 className="mb-2">Logged Out</h2>
              <p className="text-center mb-5">Your session has expired</p>
              <IoMdTimer
                className="mb-3"
                size="150px"
                color={colorValues.primaryColor}
              />
              <Button className="w-100" onClick={handleLogBackInClick}>
                Log back in
              </Button>
            </div>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

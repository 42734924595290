import { useMutation, useQueryClient } from 'react-query';
import MessageTemplates from './MessageTemplates';

export const useMessageTemplatesCreate = (options) => {
  const queryClient = useQueryClient();
  return useMutation(MessageTemplates.create, {
    onSuccess: () => {
      queryClient.invalidateQueries('message-templates');
    },
    ...options,
  });
};

export const useMessageTemplatesEdit = (options) => {
  const queryClient = useQueryClient();
  return useMutation(MessageTemplates.edit, {
    onSuccess: () => {
      queryClient.invalidateQueries('message-templates');
    },
    ...options,
  });
};

export const useMessageTemplatesDelete = (options) => {
  const queryClient = useQueryClient();
  return useMutation(MessageTemplates.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries('message-templates');
    },
    ...options,
  });
};

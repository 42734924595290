import { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

const TemplateDeleteModal = ({
  confirmDelete,
  templateItem,
  showDeleteModal,
  hideModal,
  messageTemplates,
}) => {
  const [newMessageTemplateId, setNewMessageTemplateId] = useState();

  useEffect(() => {
    const templateOptions = messageTemplateOptions();
    if (templateOptions.length > 0) {
      setNewMessageTemplateId(templateOptions[0].id);
    }
  }, []);

  const updateNewMessageTemplate = (e) => {
    setNewMessageTemplateId(e.target.value);
  };

  const messageTemplateOptions = () => {
    return messageTemplates?.filter(
      (tmpl) =>
        tmpl.id !== templateItem.id &&
        tmpl.task_action.id === templateItem.task_action.id
    );
  };

  const renderedMessageTemplateOptions = () => {
    return messageTemplateOptions().map((tmpl) => {
      return (
        <option key={tmpl.id} value={tmpl.id}>
          {tmpl.name}
        </option>
      );
    });
  };

  return (
    <Modal className={'modal-sigma'} show={showDeleteModal} onHide={hideModal}>
      <Modal.Header className={'text-center modal-title'}>
        <Modal.Title className={'w-100'}>Delete Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center">{`You are about to delete the ${templateItem.task_action.name} template: `}</p>
        <p className="text-truncate text-center font-weight-bold">{`${templateItem.name}`}</p>
        <p className="text-center">
          You must select a new template to replace this one for existing tasks and
          sequence steps
        </p>
        <Form.Control
          as="select"
          className="my-3"
          onChange={updateNewMessageTemplate}
          defaultValue={newMessageTemplateId}
        >
          {renderedMessageTemplateOptions()}
        </Form.Control>
      </Modal.Body>
      <Modal.Footer>
        <Row className={'w-100'}>
          <Col xs="6">
            <Button className={'btn btn-light btn-block'} onClick={hideModal}>
              Cancel
            </Button>
          </Col>
          <Col xs="6">
            <Button
              className={'btn btn-sigma-primary w-100'}
              onClick={() => confirmDelete(templateItem.id, newMessageTemplateId)}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default TemplateDeleteModal;

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Box, Select, Typography, MenuItem } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const ranges = {
  pastDay: {
    rangeLabel: 'Past 24 Hours',
    unit: 'hour',
    startRangeAmount: 24,
    endRangeAmount: 0,
  },
  thisWeek: {
    rangeLabel: 'This Week',
    unit: 'week',
    startRangeAmount: 0,
    endRangeAmount: 0,
  },
  pastWeek: {
    rangeLabel: 'Past Week',
    unit: 'week',
    startRangeAmount: 1,
    endRangeAmount: 0,
  },
  thisMonth: {
    rangeLabel: 'This Month',
    unit: 'month',
    startRangeAmount: 0,
    endRangeAmount: 0,
  },
  pastMonth: {
    rangeLabel: 'Past Month',
    unit: 'month',
    startRangeAmount: 1,
    endRangeAmount: 0,
  },
  lastMonth: {
    rangeLabel: 'Last Month',
    unit: 'month',
    startRangeAmount: 1,
    endRangeAmount: 1,
  },
};

const DateRangeSelection = ({ setSelectedDateRange, earliestAllowedStartDate }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedRangeLabel, setSelectedRangeLabel] = useState(null);

  useEffect(() => {
    selectPreviousRange('pastMonth');
  }, []);

  useEffect(() => {
    if (startDate && endDate) setSelectedDateRange({ startDate, endDate });
  }, [startDate, endDate]);

  const selectPreviousRange = (selection) => {
    let startDate = moment().subtract(
      ranges[selection].startRangeAmount,
      ranges[selection].unit
    );
    let endDate = moment().subtract(
      ranges[selection].endRangeAmount,
      ranges[selection].unit
    );

    if (ranges[selection].endRangeAmount > 0) {
      endDate = endDate.endOf(ranges[selection].unit);
      startDate = startDate.startOf(ranges[selection].unit);
    } else if (selection.startsWith('past') && ranges[selection].unit != 'hour') {
      startDate = startDate.startOf('day');
    } else if (selection.startsWith('this')) {
      startDate = startDate.startOf(ranges[selection].unit);
    }

    setStartDate(startDate);
    setEndDate(endDate);
    setSelectedRangeLabel(ranges[selection].rangeLabel);
  };

  const findKeyByRangeLabel = (ranges, label) => {
    for (const key in ranges) {
      if (ranges[key].rangeLabel === label) {
        return key;
      }
    }
  };

  const handleChange = (event) => {
    setSelectedRangeLabel(event.target.value);
    selectPreviousRange(findKeyByRangeLabel(ranges, event.target.value));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        flexWrap: 'nowrap',
        justifyContent: 'end',
        alignItems: { xs: 'end', sm: 'center' },
        rowGap: '8px',
      }}
    >
      <Select
        value={selectedRangeLabel}
        onChange={handleChange}
        size="small"
        className="range-select"
        sx={{
          minWidth: '150px',
          marginRight: { sm: 3 },
        }}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {Object.entries(ranges)?.map(([key, { rangeLabel }]) => (
          <MenuItem key={key} value={rangeLabel}>
            {rangeLabel}
          </MenuItem>
        ))}
        {selectedRangeLabel == 'Custom Range' && (
          <MenuItem value="Custom Range">Custom Range</MenuItem>
        )}
      </Select>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: { sm: 'center' },
          rowGap: { xs: '8px', sm: '4px' },
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(date) => {
              setStartDate(date);
              setSelectedRangeLabel('Custom Range');
            }}
            minDate={moment(earliestAllowedStartDate, 'DD-MM-YYYY')}
            maxDate={endDate}
            sx={{ width: '150px' }}
          />
          <Typography
            className="text-sigma-grey"
            sx={{ mx: 1, display: { xs: 'none', sm: 'block' } }}
          >
            to
          </Typography>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(date) => {
              setEndDate(date);
              setSelectedRangeLabel('Custom Range');
            }}
            minDate={startDate}
            sx={{ width: '150px' }}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default DateRangeSelection;

import { Button } from '@mui/material';
import { FilterON, FilterOFF } from '../../theme/icons';

const FilterButtonWrapper = (props) => {
  const toggleFilterVisibility = () => {
    props.setFilterToggle(!props.showFilters);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="medium"
        onClick={() => toggleFilterVisibility()}
        sx={{
          marginRight: '5px',
        }}
      >
        {props.showFilters ? <FilterON /> : <FilterOFF />}
      </Button>
    </>
  );
};

export default FilterButtonWrapper;

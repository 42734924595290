import Cookies from 'js-cookie';

export default class InstitutionsLists {
  static async list() {
    const response = await fetch(
      `${process.env.REACT_APP_NEXHEALTH_WEBHOOK}/v1/institutions`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      }
    );
    return response.json();
  }
}

import {
  FormControl,
  InputLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useMessageTemplatesList } from '../../MUI/services/messageTemplates/queries';
import ClipLoader from 'react-spinners/ClipLoader';
import { useState } from 'react';
import MessageTemplate from '../../clients/MessageTemplate';
import { useEffect } from 'react';

export default function CallAssist({
  organization_id,
  user_id,
  lead_id,
  template_id,
}) {
  const [selectId, setSelectId] = useState(template_id);
  const [renderedTemplate, setRenderedTemplate] = useState('');
  const [renderingTemplate, setRenderingTemplate] = useState(false);
  const filterTextList = (data) => {
    return data.filter((t) => t.task_action.name == 'Call');
  };

  const renderTemplate = (templateId) => {
    setSelectId(templateId);
    setRenderingTemplate(true);
    setRenderedTemplate(null);

    MessageTemplate.render(templateId, user_id, lead_id)
      .then((res) => {
        setRenderedTemplate(res.content);
        setRenderingTemplate(false);
      })
      .catch(() => {
        setRenderedTemplate(null);
        setRenderingTemplate(false);
      });
  };

  const { data: scripts, isFetching } = useMessageTemplatesList(
    [['organization_id[]', organization_id]],
    {
      select: filterTextList,
    }
  );

  useEffect(() => {
    if (template_id) {
      renderTemplate(template_id);
    }
  }, [template_id]);

  return (
    <Grid container>
      <Grid item xs={12}>
        {isFetching && scripts == undefined ? (
          <Grid item align="center">
            <ClipLoader size={50} color="#34D1B6" />
          </Grid>
        ) : (
          <FormControl fullWidth>
            <InputLabel id="template-label" sx={{ marginLeft: '-14px' }}>
              Template
            </InputLabel>
            <Select
              labelId="call-scripts-select"
              id="call-scripts-select"
              value={selectId}
              variant={'standard'}
              onChange={(e) => renderTemplate(e.target.value)}
            >
              {scripts?.map((script) => (
                <MenuItem key={script.id} value={script.id}>
                  {script.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        {renderingTemplate ? (
          <Grid item align="center" sx={{ height: '100px' }}>
            <ClipLoader size={50} color="#34D1B6" />
          </Grid>
        ) : (
          <TextField
            id="rendered-template"
            multiline
            fullWidth
            variant={'filled'}
            defaultValue={renderedTemplate}
            rows={13}
          />
        )}
      </Grid>
    </Grid>
  );
}

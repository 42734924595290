import React, { cloneElement, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useCallFlowMethods } from '../components/CallFlow/hooks';
import { CallFlowHeader, MappedOptions } from '../components/CallFlow';
import { availableOptions } from '../components/CallFlow/options/utils';
import { Container } from '../components/CallFlow/common/Container';
import { DeleteIcon, EditIcon, ShareIcon, PlusIcon } from '../theme/icons';
import { useOrg } from '../../contexts/OrgProvider';
import { fireDialog } from '../components/Dialog';
import { useCallFlowDelete, useCallFlowShare } from '../../api/callFlow/mutations';
import { useGetCallFlowList } from '../../api/callFlow/queries';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { DataGridStyle } from '../theme/styled/DataGridStyle';
import { CellSpanStyle } from '../theme/styled/CellSpanStyle';
import { useOrganizationsList } from '../services/organizations/queries';

const DeleteCallFlowDialog = ({ isOpen, onResolve, onReject, callFlow, hook }) => {
  const {
    mutate: deleteCallFlow,
    isSuccess: didSucceedMutatingCallFlow,
    isError: didFailMutatingCallFlow,
    isLoading: isMutatingCallFlow,
  } = hook();

  useEffect(() => {
    if (didSucceedMutatingCallFlow && !didFailMutatingCallFlow) onResolve();
  }, [didSucceedMutatingCallFlow, didFailMutatingCallFlow]);

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Delete Call Flow / {callFlow?.name}</DialogTitle>
      <DialogContent>
        {didFailMutatingCallFlow && (
          <Alert severity="error">
            There was a problem deleting your call flow. Try again.
          </Alert>
        )}
        You are about to delete the call flow listed above. This will permanently
        remove the call flow from all leads and no leads created in the future will
        display the call flow.
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          loading={isMutatingCallFlow}
          onClick={() => deleteCallFlow(callFlow?.id)}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const CallFlowPage = () => {
  const {
    blocks,
    componentList,
    createApiChains,
    createApiBlocks,
    setComponentList,
    handleAddComponent,
    handleDeleteComponent,
    handleInsertComponent,
    getUpdatedInputParams,
    checkAllComponentsReady,
    handleDeleteBranchInBuilder,
    handleMutateBuilderCallerPressOption,
    handleMutateComponentListByParametersInUI,
  } = useCallFlowMethods();

  const history = useHistory();
  const { callFlowId } = useParams();
  const { id: orgId } = useOrg();
  const [tab, setTab] = useState(true);
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(1);

  const [shouldRecord, setShouldRecord] = useState(false);
  const [callFlowName, setCallFlowName] = useState();
  const [callFlowWebhook, setCallFlowWebhook] = useState();
  const [doSearchEmptyInputs, setDoSearchEmptyInputs] = useState(false);
  const createFilters = () => {
    return [
      ['page', page],
      ['page_size', pageSize],
      ['organization_id', orgId],
    ];
  };

  const { data, isLoading: isFetching } = useGetCallFlowList(createFilters());

  const handleDeleteCallFlowClick = (callFlow, hook) => {
    fireDialog((promiseProps) =>
      DeleteCallFlowDialog({
        ...promiseProps,
        callFlow,
        hook,
      })
    );
  };

  const shareCallFlow = (callFlowId) =>
    fireDialog((promiseParams) =>
      ShareCallFlowDialog({ ...promiseParams, callFlowId })
    );

  const columns = [
    {
      field: '---',
      headerName: 'Name',
      sortable: false,
      flex: 3,
      renderCell: ({ row }) => <CellSpanStyle id={row.id}>{row.name}</CellSpanStyle>,
    },
    {
      field: '',
      headerName: '',
      flex: 2,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <CellSpanStyle id={row?.id}>
            <Button
              startIcon={<EditIcon />}
              variant="text"
              onClick={() => history.push(`/settings/call-flow/${row.id}`)}
            >
              Edit
            </Button>
            <Button
              startIcon={<ShareIcon />}
              variant="text"
              onClick={() => shareCallFlow(row.id)}
            >
              Share
            </Button>
            <Button
              disabled={row?.phones_size !== 0}
              startIcon={<DeleteIcon />}
              size="small"
              variant="text"
              color="error"
              onClick={() => handleDeleteCallFlowClick(row, useCallFlowDelete)}
            >
              Delete
            </Button>
          </CellSpanStyle>
        );
      },
    },
  ];

  const resetToInitial = () => {
    setComponentList([]);
    setCallFlowName('');
    setCallFlowWebhook('');
    setShouldRecord(false);
  };

  return (
    <SettingsCard sx={{ padding: '20px' }}>
      {tab && (
        <>
          <SettingsCard.Header mb={2}>Call Flow</SettingsCard.Header>
          <DataGridStyle
            disableSelectionOnClick
            rows={data?.items ?? []}
            columns={columns}
            loading={isFetching}
            rowsPerPageOptions={[10, 25, 50]}
            onPageChange={(page) => setPage(page + 1)}
            rowCount={data?.total_count ?? 0}
            page={page - 1}
            sortingMode="server"
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
            paginationMode="server"
            disableColumnFilter
            disableColumnMenu
            density="compact"
            sx={{
              minHeight: '600px',
              marginTop: '20px',
            }}
          />
        </>
      )}
      {tab && (
        <Button
          sx={{ margin: '20px 0' }}
          onClick={() => {
            setTab((val) => !val);
            resetToInitial();
          }}
        >
          + Add New
        </Button>
      )}
      {tab || (
        <Box sx={{ '.insertStep:last-of-type': { display: 'none!important' } }}>
          <CallFlowHeader
            setTab={setTab}
            resetToInitial={resetToInitial}
            shouldRecord={shouldRecord}
            setShouldRecord={setShouldRecord}
            callFlowName={callFlowName}
            setCallFlowName={setCallFlowName}
            callFlowWebhook={callFlowWebhook}
            setCallFlowWebhook={setCallFlowWebhook}
            createApiBlocks={() => createApiBlocks(componentList)}
            createApiChains={() => createApiChains(blocks)}
            isCallFlowReady={checkAllComponentsReady(componentList)}
            doSearchEmptyInputs={doSearchEmptyInputs}
            setDoSearchEmptyInputs={setDoSearchEmptyInputs}
          />
          <MappedOptions
            handleMutateComponentListByParametersInUI={
              handleMutateComponentListByParametersInUI
            }
            createApiBlocks={createApiBlocks}
            handleInsertComponent={handleInsertComponent}
            handleDeleteBranchInBuilder={handleDeleteBranchInBuilder}
            handleMutateBuilderCallerPressOption={
              handleMutateBuilderCallerPressOption
            }
            handleDeleteComponent={handleDeleteComponent}
            handleAddComponent={handleAddComponent}
            componentList={componentList}
            doSearchEmptyInputs={doSearchEmptyInputs}
            getUpdatedInputParams={getUpdatedInputParams}
          />
          {componentList.some(
            ({ action }) =>
              action === 'respond' ||
              action === 'hangup' ||
              action === 'ai' ||
              action === 'initialList'
          ) || (
            <Container
              title={availableOptions.title}
              isListOptions={availableOptions.isListOptions}
            >
              <Box>
                <Typography textAlign="center" variant="h4" pt={4}>
                  {availableOptions.title}
                </Typography>
                <Box>
                  {cloneElement(availableOptions.component, { handleAddComponent })}
                </Box>
              </Box>
            </Container>
          )}
        </Box>
      )}
    </SettingsCard>
  );
};

const ShareCallFlowDialog = ({ isOpen, onReject, callFlowId }) => {
  const { enterprise_id } = useOrg();
  const { data: organizations, isFetching } = useOrganizationsList({
    enterprise_id: enterprise_id,
  });
  const {
    mutate: createShareCallFlow,
    isLoading: isLoadingCreate,
  } = useCallFlowShare();
  const [pageSize, setPageSize] = useState(25);
  const [selected, setSelected] = useState([]);
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
  ];

  const shareCallFlow = () => {
    const orgIds = selected.map((id) => {
      return { organization_id: id };
    });
    createShareCallFlow({ task_sequence: callFlowId, organizations: orgIds });
    onReject();
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Share</DialogTitle>
      <DialogContent>
        <DataGridStyle
          checkboxSelection
          loading={isFetching}
          rows={organizations ?? []}
          columns={columns}
          sx={{ minHeight: '400px' }}
          rowsPerPageOptions={[10, 25, 50]}
          onPageSizeChange={setPageSize}
          pageSize={pageSize}
          density="compact"
          disableSelectionOnClick
          onSelectionModelChange={setSelected}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="cancel" onClick={onReject}>
          Cancel
        </Button>

        <LoadingButton onClick={shareCallFlow} loading={isLoadingCreate}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CallFlowPage;

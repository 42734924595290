import Cookies from 'js-cookie';
import { toJSON, toParamString } from '../queryClientConfig';

export default class Sessions {
  static async list(filters = []) {
    return await fetch(
      `${process.env.REACT_APP_IVR}/sessions?${toParamString(filters)}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async show(session_id) {
    return await fetch(`${process.env.REACT_APP_IVR}/sessions/${session_id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(toJSON);
  }

  static async edit(session) {
    return await fetch(`${process.env.REACT_APP_IVR}/sessions/${session.id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(session),
    }).then(toJSON);
  }

  static async delete(session_id) {
    return await fetch(`${process.env.REACT_APP_IVR}/sessions/${session_id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
}

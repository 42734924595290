import { Box, Button, Typography, Grid, Alert } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { SaveIcon } from '../../theme/icons';
import { usePhoneIdCreate } from '../../services/phoneIds/mutations';
import { useState } from 'react';

export default function TotalStep({
  collectedData,
  handleBackNavigate,
  setAddNewCallTracking,
}) {
  const { mutate: createCallTracking, isLoading } = usePhoneIdCreate();
  const [isSuccess, setSuccess] = useState(collectedData.networkStatus == 'offline');

  const renderRow = (label, value) => {
    if (!value) return null;
    return (
      <Grid container>
        <Grid mb={1} item xs={4}>
          <Typography color={'gray'}>{label}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography fontWeight={'500'}>{value}</Typography>
        </Grid>
      </Grid>
    );
  };

  const handleSubmit = () => {
    return createCallTracking(
      { ...collectedData },
      {
        onSuccess: (_) => {
          setSuccess(true);
          setTimeout(() => {
            setAddNewCallTracking(false);
          }, 3000);
        },
      }
    );
  };

  return (
    <Box mt={3} mb={5}>
      {isSuccess && (
        <Alert severity="success">Call tracking successfully created.</Alert>
      )}
      <h4>Here are the details of your new number: </h4>
      <hr />
      <Box mt={4}>
        {renderRow('Network status :', collectedData.networkStatus)}
        {renderRow('Located at :', collectedData.locatedAt)}
        {renderRow('TrackType :', collectedData.trackType)}
        {renderRow('GoogleAd Extension :', collectedData.isGoogleAdExtension)}
        {renderRow(
          'Where GoogleAd Should Run :',
          collectedData.whereGoogleAdShouldRun
        )}
        {renderRow('CallForwarding :', collectedData.callForwarding)}
        {renderRow(
          'Number Features Call Recording :',
          collectedData.numberFeaturesCallRecording
        )}
        {renderRow(
          'Number Features Whisper Message :',
          collectedData.numberFeaturesWhisperMessage
        )}
        {renderRow('Number Call Flow Name :', collectedData.numberCallFlowName)}
        {renderRow('Number Setup Value :', collectedData.numberSetup)}
        {renderRow('Number Setup Name :', collectedData.numberSetupName)}
        {renderRow('Team Name :', collectedData.teamName)}
        {renderRow('Source Name :', collectedData.sourceName)}
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" mt={5}>
        <Button onClick={() => handleBackNavigate()}>Back</Button>
        <LoadingButton
          disabled={isSuccess}
          loading={isLoading}
          startIcon={<SaveIcon />}
          loadingPosition="start"
          onClick={() => handleSubmit()}
        >
          Save
        </LoadingButton>
      </Box>
    </Box>
  );
}

import { useState, useRef } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import FilterButtonWrapper from '../components/Filters/FilterButtonWrapper';
import FilterBar from '../components/Reports/Activity/FilterBar';
import { initialReportsFilterMapValues } from '../components/Reports/Activity/FilterBar';
import ReportCard from '../components/Reports/ReportCard';
import ReportsUsersChart from '../components/Reports/ReportsUsersChart';
import { useOrg } from '../../contexts/OrgProvider';
import { parseFilterParams } from '../components/Filters/utils';

const ReportsActivity = () => {
  const reportsByUsersGroups = [
    ['types[]', 'manual_call_made_by_user'],
    ['types[]', 'manual_email_sent_by_user'],
    ['types[]', 'manual_text_messages_sent_by_user'],
    ['types[]', 'task_cancelation_by_user'],
    ['types[]', 'task_completion_by_user'],
    ['types[]', 'lead_won_by_user'],
    ['types[]', 'lead_lost_by_user'],
    ['types[]', 'daily_logins_by_user'],
  ];
  const reportGroups = {
    'Active Call Notifications': {
      apiBase: [
        { type: 'acn_accepted', name: 'Active Call Notifications Accepted' },
        { type: 'acn', name: 'Active Call Notifications' },
        { type: 'acn_accept_rate', name: 'Active Call Notifications Accept Rate' },
      ],
      messagingApiBase: [
        {
          type: 'acn_accept_rate_by_team',
          name: 'Active Call Notifications Accept Rate by Team',
        },
      ],
    },
    'Automated Communication': {
      apiBase: [
        {
          type: 'automated_text_messages_sent',
          name: 'Automated Text Messages Sent',
        },
        { type: 'text_messages_received', name: 'Text Messages Received' },
        { type: 'automated_email_sent', name: 'Automated Emails Sent' },
        { type: 'email_received', name: 'Emails Received' },
      ],
    },
    Calls: {
      apiBase: [
        { type: 'call_answered', name: 'Calls Answered' },
        { type: 'call_received', name: 'Calls Received' },
        { type: 'call_made', name: 'Calls Made' },
        { type: 'call_answer_rate', name: 'Calls Answer Rate' },
      ],
    },
  };

  const filterBarRef = useRef();
  const { id: orgId, isMasterAccount } = useOrg();

  const [showFilters, setFilterToggle] = useState(window.innerWidth > 768);
  const [reportsFilterMap, setReportsFilterMap] = useState(
    initialReportsFilterMapValues
  );

  const isDateRangeSelected = () => {
    if (!reportsFilterMap['date_range']?.length) return false;

    const dateFilters = reportsFilterMap['date_range'][0];

    return Boolean(
      (dateFilters.filterValue === '16' && dateFilters.startDate) ||
        (dateFilters.filterValue === '16' && dateFilters.endDate) ||
        dateFilters.filterValue !== '16'
    );
  };

  const createParams = (apiGroup, type) => {
    const [_, filters] = parseFilterParams(reportsFilterMap);
    filters.push(['type', type]);

    const params = {
      apiGroup,
      canUse: isDateRangeSelected(),
      filters,
    };
    if (!isMasterAccount) {
      params.filters.push(['organization_id', orgId]);
    }
    return params;
  };
  const createReportsByUsersFilters = () => {
    const [_, filters] = parseFilterParams(reportsFilterMap);
    filters.push(...reportsByUsersGroups);

    const params = {
      canUse: isDateRangeSelected(),
      filters,
    };
    if (!isMasterAccount) {
      params.filters.push(['organization_id', orgId]);
    }
    return params;
  };

  const currentPath = window.location.pathname;
  const shouldShowFilters = currentPath === '/reports/activity';

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          <Typography fontSize={18} fontWeight={700}>
            Activity Reports
          </Typography>
          <Box display="flex" alignItems="center">
            <Box sx={{ marginLeft: '10px' }}>
              {shouldShowFilters && (
                <FilterButtonWrapper
                  showFilters={showFilters}
                  setFilterToggle={setFilterToggle}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" height="0%" width="100%" flexBasis="100%" gap={2}>
        {showFilters ? (
          <FilterBar ref={filterBarRef} setReportsFilterMap={setReportsFilterMap} />
        ) : (
          <></>
        )}
        <Box>
          <Box sx={{ pl: 3 }}>
            <ReportsUsersChart params={createReportsByUsersFilters()} />
          </Box>
          {Object.entries(reportGroups).map(([reportType, groups]) => (
            <Box key={reportType}>
              <Box sx={{ my: 3 }}>
                {reportType}
                <Divider />
              </Box>
              <Box
                sx={{
                  pl: 3,
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'start',
                  gap: 3,
                }}
              >
                {Object.entries(groups).map(([apiGroup, reports]) =>
                  reports.map((report) => (
                    <ReportCard
                      key={report.type}
                      report={report}
                      params={createParams(apiGroup, report.type)}
                    />
                  ))
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ReportsActivity;

import { Link, Prompt } from 'react-router-dom';
import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import HookForm from '../components/HookForm';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { DeleteIcon, EditIcon, PlusIcon } from '../theme/icons';
import ClipLoader from 'react-spinners/ClipLoader';
import parsePhoneNumber from 'libphonenumber-js';
import { fireConfirmDialog, fireDialog } from '../components/Dialog';
import { useTeamsList } from '../services/teams/queries';
import { useTeamsCreate, useTeamsDelete } from '../../api/teams/mutations';
import { string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useOrg } from '../../contexts/OrgProvider';

const onAddClick = () => {
  fireDialog((promiseProps) =>
    AddTeamDialog({
      ...promiseProps,
    })
  );
};

const formatPhone = (phone) => {
  if (!phone) {
    return;
  }

  const phoneNumber = parsePhoneNumber(phone);
  if (phoneNumber) {
    return phoneNumber.formatNational();
  }
  return phone;
};

export default function TeamListSettings() {
  const { id: orgId } = useOrg();
  const { isFetching, isError, data } = useTeamsList([['organization_id[]', orgId]]);
  const { mutate: deleteTeam } = useTeamsDelete();

  const onDeleteClick = (team) => {
    fireConfirmDialog({
      title: 'Delete Team',
      subTitle: team.name,
      message: `You are about to delete the team listed above. This will permanently remove the team and all settings within.  This is not reversible.  Are you sure?`,
    }).then(() => deleteTeam(team.id));
  };

  const buildTable = (teams) => {
    if (teams.length == 0) {
      return (
        <Alert severity="info" sx={{ mt: 1, mb: 2 }}>
          Add your first phone number to get started calling and texting.
        </Alert>
      );
    }

    return (
      <Grid item sx={{ mb: 2 }}>
        <TableContainer sx={{ mt: 1 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Caller ID</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teams.map((team) => (
                <TableRow
                  key={team.name}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {team.name}
                  </TableCell>
                  <TableCell>{formatPhone(team.phone)}</TableCell>
                  <TableCell>
                    <Button
                      startIcon={<EditIcon />}
                      size="small"
                      variant="text"
                      color="primary"
                      component={Link}
                      to={`/settings/teams/${team.id}`}
                      sx={{ marginRight: 1 }}
                    >
                      Edit
                    </Button>
                    <Button
                      startIcon={<DeleteIcon />}
                      size="small"
                      variant="text"
                      color="error"
                      onClick={() => onDeleteClick(team)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  };

  return (
    <SettingsCard>
      <SettingsCard.Header>Teams</SettingsCard.Header>
      <SettingsCard.SubHeader>
        Configure Active Call Notification and forwarding phone numbers for your
        teams.
      </SettingsCard.SubHeader>
      <SettingsCard.Main>
        {isError ? (
          <Alert severity="error" sx={{ mt: 1, mb: 2 }}>
            There was an error loading your phones. Please refresh the page.
          </Alert>
        ) : (
          <Grid container direction="column">
            {isFetching ? (
              <Grid item align="center">
                <ClipLoader size={100} color="#34D1B6" />
              </Grid>
            ) : (
              <>
                {buildTable(data)}
                <Grid item>
                  <Button startIcon={<PlusIcon />} onClick={onAddClick}>
                    Add New
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </SettingsCard.Main>
    </SettingsCard>
  );
}

const AddTeamDialog = ({ isOpen, onResolve, onReject }) => {
  const { mutate: createTeam } = useTeamsCreate();

  const onCreateTeamSubmit = (formData) => {
    createTeam({ name: formData['name'], is_default: false, call_routing_id: 1 });
    onResolve();
  };

  const useFormProps = {
    defaultValues: { name: '' },
    resolver: yupResolver(
      yup.object({
        name: string().required('This field is required'),
      })
    ),
  };
  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Create Team</DialogTitle>
      <HookForm
        onSubmit={onCreateTeamSubmit}
        useFormProps={useFormProps}
        useDirtyFormCheck
      >
        <DialogContent>
          <HookForm.TextField name="name" label="Name" />
        </DialogContent>
        <DialogActions>
          <Button variant="cancel" onClick={onReject}>
            Cancel
          </Button>
          <Button type="submit">Confirm</Button>
        </DialogActions>
      </HookForm>
    </Dialog>
  );
};

import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Box, Grid } from '@mui/material';
import ClipLoader from 'react-spinners/ClipLoader';

import LeadDetail from '../../../components/pipeline/LeadDetail';

import { useLeadsShow } from '../../services/leads/queries';
import { CellSpanStyle } from '../../theme/styled/CellSpanStyle';

export const LeadCardWrapper = ({ setLeadShowError }) => {
  const { leadIdParam } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const { data: lead, error: error } = useLeadsShow(leadIdParam, {
    onSettled: (data, error) => {
      if (error?.status === 404) {
        setLeadShowError(true);
        history.push({
          pathname: '/leads',
        });
      }
      setIsLoading(false);
    },
  });

  useEffect(() => {
    setIsLoading(true);
  }, [leadIdParam]);

  if (!lead || isLoading) {
    return (
      <Grid container direction="column">
        <Grid item align="center">
          <ClipLoader size={100} color="#34D1B6" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Box maxHeight={'100%'} flex={4}>
      <Box position="relative" height="100%">
        <LeadDetail lead={lead} />
      </Box>
    </Box>
  );
};

export const ActiveCellSpan = ({ children, cell }) => {
  const { leadIdParam } = useParams();

  return (
    <Box
      component={CellSpanStyle}
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        color: ({ palette }) => palette.info.main,
        backgroundColor:
          cell.id === leadIdParam ? ({ palette }) => palette.grey[100] : 'inherit',
        '&::before':
          cell.id === leadIdParam
            ? {
                content: "''",
                position: 'absolute',
                left: 0,
                height: '100%',
                width: '4px',
                backgroundColor: ({ palette }) => palette.primary.main,
              }
            : {},
      }}
    >
      {children}
    </Box>
  );
};

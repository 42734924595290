/* ------ Stale time constants ------ */
const THIRTY_SECONDS = 1000 * 30;
const ONE_HOUR = 1000 * 60 * 60;

/* ------ Query configurations ------ */
// tasks
export const taskKeys = {
  allTasks: ['tasks'],
  tasksDueToday: ['tasks', 'due-today'],
  tasksCompleted: ['tasks', 'completed'],
};

// activity
export const activityKeys = {
  all: ['activity'],
  paged: (params) => [...activityKeys.all, params],
};

export const activityStaleTimes = {
  paged: THIRTY_SECONDS,
};

// lost_reasons
export const lostReasonsKeys = {
  all: ['lost_reasons'],
};

export const lostReasonsStaleTimes = {
  all: ONE_HOUR,
};

/* ------ Exports ------ */
export const queryKeys = {
  taskKeys,
  activityKeys,
  lostReasonsKeys,
};

export const staleTimes = {
  lostReasonsStaleTimes,
  activityStaleTimes,
};

import { useQuery } from 'react-query';
import { queryKeyFactory } from '../queryClientConfig';
import Visits from './Visits';

export const visitsQueryKeys = queryKeyFactory('callstracking');

export const useVisitsList = (filters = [], options) => {
  return useQuery(visitsQueryKeys.list(filters), () => Visits.list(filters), {
    staleTime: 0,
    ...options,
  });
};

import { useMutation, useQueryClient } from 'react-query';
import Cloud9Calendar from './Cloud9';
import { calendarsQueryKeys } from '../calendars/queries';

export const useCloud9CalendarCreate = (options) => {
  return useMutation(Cloud9Calendar.create, {
    ...options,
  });
};

export const useCloud9CalendarDelete = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => Cloud9Calendar.delete(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(calendarsQueryKeys.all);
      queryClient.invalidateQueries(calendarsQueryKeys.lists());
    },
  });
};

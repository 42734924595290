import { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Box,
  Chip,
  Typography,
  AccordionActions,
  Autocomplete,
  TextField,
  Button,
  IconButton,
} from '@mui/material';

import { useOrg } from '../../../contexts/OrgProvider';
import { useUsersList, useUsersCurrent } from '../../services/users/queries';
import { useOrganizationsList } from '../../services/organizations/queries';
import { useSavedSearchesList } from '../../services/savedSearches/queries';
import { useSavedSearchesEdit } from '../../services/savedSearches/mutations';
import { useFilters } from '../../services/hooks/useFilters';
import { formatName } from '../../services/textFormatting';
import { eventTypeNameMap } from '../../services/events/eventsUtils';
import { formatIsoStringToDateTimeLocal } from '../../services/dateTime';

import {
  deserializeActivitesSavedSearches,
  parseActivitiesFilterParams,
} from '../Activities/utils';

import { DeleteIcon, StarIcon } from '../../theme/icons';
import {
  CreateSavedSearchDialog,
  DeleteSavedSearchDialog,
} from '../SavedSearchDialogs';
import { FilterAccordion, FilterAccordionSummary } from '../Filters/FilterAccordion';
import { fireDialog } from '../../components/Dialog';

const FilterBar = forwardRef(({ setPage, setActivitiesFilterMap }, ref) => {
  useImperativeHandle(ref, () => ({
    changeFilterParams(params) {
      setFilterMap((prev) => ({
        ...prev,
        ...params,
      }));
    },
  }));

  const history = useHistory();
  const { search } = useLocation();
  const { id: orgId, isMasterAccount, enterprise_id } = useOrg();
  const { data: currentUser } = useUsersCurrent();
  const { data: accountsList } = useOrganizationsList();
  const senderTypes = { user: 'User', automation: 'Automation' };

  // useRef since neither states need to cause renders
  const selectedSavedSearchId = useRef(null);
  const shouldLoadDefaultSavedSearch = useRef(true);

  const [canUseUsersList, setCanUseUsersList] = useState(false);
  const [isTextEventSelected, setIsTextEventSelected] = useState(false);

  const parseQueryParams = () => {
    const params = new URLSearchParams(search);
    let queryParamsHash = {};
    params.forEach((value, key) => {
      queryParamsHash[key] = value;
    });

    return queryParamsHash;
  };
  const [queryParams, setQueryParams] = useState(parseQueryParams());

  const { data: savedSearchesList } = useSavedSearchesList(
    { user_id: currentUser.id, organization_id: orgId },
    { resourceClass: 'activities' }
  );
  const { data: usersList, isLoading: usersListIsLoading } = useUsersList([], {
    enabled: canUseUsersList,
  });
  const normalizedUsersList = () => {
    if (usersList) {
      const list = usersList.map(({ id, first_name, last_name }) => {
        return {
          filterValue: id,
          name: formatName(first_name, last_name),
        };
      });
      list.push({ filterValue: null, name: '(None)' });

      return list;
    }

    return [];
  };

  const { mutate: editSavedSearch } = useSavedSearchesEdit();
  const { filterMap, setFilterMapValue, setFilterMap, clearFilterMap } = useFilters({
    initialFilters: initialActivitiesFilterMapValues,
  });

  const loadSavedSearch = (
    savedSearches = Object.entries(queryParams),
    doClearFilterMap
  ) => {
    if (doClearFilterMap) handleClearFilterMap();
    const savedFiltersMap = deserializeActivitesSavedSearches({
      savedSearches,
      accountsList,
      usersList,
    });

    setFilterMap((prev) => ({ ...prev, ...savedFiltersMap }));
  };

  const handleClearFilterMap = () => {
    history.push(`/activity`);
    selectedSavedSearchId.current = null;
    clearFilterMap();
  };

  const handleSetFilterMapValue = (...params) => {
    selectedSavedSearchId.current = null;
    setFilterMapValue(...params);
  };

  const handleClearEventTypes = () => {
    handleSetFilterMapValue('event_type', []);
    handleSetFilterMapValue('sender_type', []);
    setIsTextEventSelected(false);
  };

  const handleCheckTextEventSelected = (eventTypes) => {
    eventTypes.forEach((type) => {
      if (['text_inbound', 'text_outbound'].includes(type.filterValue)) {
        setIsTextEventSelected(true);
      } else {
        setIsTextEventSelected(false);
        handleSetFilterMapValue('sender_type', []);
      }
    });
  };

  const isOnEnterprise = () => {
    return !!enterprise_id;
  };

  // handle loading default search once
  useEffect(() => {
    if (
      savedSearchesList &&
      accountsList &&
      usersList &&
      shouldLoadDefaultSavedSearch.current
    ) {
      const defaultSearch = savedSearchesList?.find(({ is_default }) => is_default);
      if (!selectedSavedSearchId.current) {
        selectedSavedSearchId.current = defaultSearch?.id;
        shouldLoadDefaultSavedSearch.current = false;
      }
      if (!!defaultSearch) loadSavedSearch(defaultSearch?.data, false);
    }
  }, [savedSearchesList, accountsList, usersList]);

  useEffect(() => {
    setActivitiesFilterMap(filterMap);
    setPage(1);
  }, [filterMap]);

  useEffect(() => {
    if (Object.keys(queryParams).length > 0) {
      const queryParamsFiltersMap = deserializeActivitesSavedSearches({
        savedSearches: Object.entries(queryParams),
        accountsList,
        usersList,
      });

      setFilterMap((prev) => ({ ...prev, ...queryParamsFiltersMap }));
    }
  }, [queryParams]);

  useEffect(() => {
    setQueryParams(parseQueryParams());
  }, [search]);

  const eventTypeNameSorted = Object.fromEntries(
    Object.entries(eventTypeNameMap).sort((a, b) => a[1].localeCompare(b[1]))
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      width={300}
      mr={2}
      sx={{
        border: ({ palette }) => `1px solid ${palette.grey[300]}`,
        borderRadius: '4px',
        backgroundColor: 'white',
      }}
    >
      <Box
        columnGap={1}
        sx={{
          borderRadius: 'inherit',
          minHeight: '39px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: '10px',
          backgroundColor: ({ palette }) => palette.grey[100],
          borderBottom: ({ palette }) => `1px solid ${palette.grey[300]}`,
        }}
      >
        <Typography fontSize={14} fontWeight={500} sx={{ flexBasis: '100%' }}>
          Filters
        </Typography>
        <Button
          variant="outlined"
          color="inherit"
          sx={{
            backgroundColor: ({ palette }) => palette.common.white,
            minWidth: '40px',
            height: '25px',
            p: 0.5,
          }}
          onClick={() => {
            const [_, filterParams] = parseActivitiesFilterParams(
              filterMap,
              isMasterAccount,
              orgId
            );

            fireDialog((promiseProps) =>
              CreateSavedSearchDialog({
                ...promiseProps,
                resourceClass: 'activities',
                filterParams,
              })
            ).then((selectedId) => (selectedSavedSearchId.current = selectedId));
          }}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          sx={{
            backgroundColor: ({ palette }) => palette.common.white,
            minWidth: '40px',
            height: '25px',
            p: 0.5,
          }}
          onClick={handleClearFilterMap}
        >
          Clear
        </Button>
      </Box>
      <Box overflow="auto">
        <FilterAccordion defaultExpanded>
          <FilterAccordionSummary>Saved Searches</FilterAccordionSummary>
          <AccordionActions sx={{ alignItems: 'flex-start' }}>
            <Box width="100%" display="flex" flexDirection="column">
              {savedSearchesList?.length > 0 ? (
                savedSearchesList.map((savedSearch) => (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: 0.5,
                      ':hover': {
                        cursor: 'pointer',
                      },
                    }}
                    key={savedSearch.id}
                  >
                    <Box
                      flexBasis="100%"
                      onClick={() => {
                        loadSavedSearch(savedSearch.data, true);
                        selectedSavedSearchId.current = savedSearch.id;
                      }}
                    >
                      <Typography
                        fontSize={14}
                        fontWeight={
                          selectedSavedSearchId.current === savedSearch.id
                            ? '600'
                            : ''
                        }
                      >
                        {savedSearch.name}
                      </Typography>
                    </Box>
                    <IconButton
                      onClick={() =>
                        editSavedSearch({
                          ...savedSearch,
                          is_default: !savedSearch.is_default,
                        })
                      }
                      size="small"
                      sx={{
                        padding: 0,
                        color: ({ palette }) =>
                          savedSearch.is_default
                            ? palette.warning.light
                            : palette.grey[300],
                      }}
                    >
                      <StarIcon />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        fireDialog((promiseProps) =>
                          DeleteSavedSearchDialog({
                            ...promiseProps,
                            savedSearchId: savedSearch.id,
                          })
                        )
                      }
                      sx={{ padding: 0 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))
              ) : (
                <Typography fontSize={14}>No saved searches</Typography>
              )}
            </Box>
          </AccordionActions>
        </FilterAccordion>
        <FilterAccordion disableGutters square>
          <FilterAccordionSummary
            sx={{
              fontSize: 14,
              '& .MuiAccordionSummary-content': {
                display: 'flex',
                justifyContent: 'space-between',
              },
            }}
          >
            Event
            {filterMap?.event_type?.length > 0 && (
              <Chip
                size="small"
                onDelete={() => handleClearEventTypes()}
                label={filterMap?.event_type.length}
              />
            )}
          </FilterAccordionSummary>
          <AccordionActions>
            <Autocomplete
              autoComplete
              size="small"
              multiple
              sx={{ width: '100%' }}
              value={filterMap?.event_type ?? []}
              onChange={(_, value) => {
                if (value.length === 0) {
                  handleClearEventTypes();
                } else {
                  handleSetFilterMapValue('event_type', value);
                  handleCheckTextEventSelected(value);
                }
              }}
              options={Object.entries(eventTypeNameSorted).map(
                ([event_type, name]) => ({
                  filterValue: event_type,
                  name,
                })
              )}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Event" placeholder="" />
              )}
              isOptionEqualToValue={(option, value) => {
                return option.filterValue === value.filterValue;
              }}
            />
          </AccordionActions>
        </FilterAccordion>
        {isTextEventSelected && (
          <FilterAccordion defaultExpanded={true} disableGutters square>
            <FilterAccordionSummary
              sx={{
                fontSize: 14,
                '& .MuiAccordionSummary-content': {
                  display: 'flex',
                  justifyContent: 'space-between',
                },
              }}
            >
              Sender Type
              {filterMap?.sender_type?.length > 0 && (
                <Chip
                  size="small"
                  onDelete={() => handleSetFilterMapValue('sender_type', [])}
                  label={filterMap?.sender_type.length}
                />
              )}
            </FilterAccordionSummary>
            <AccordionActions>
              <Autocomplete
                autoComplete
                size="small"
                multiple
                sx={{ width: '100%' }}
                value={filterMap?.sender_type ?? []}
                onChange={(_, value) => {
                  handleSetFilterMapValue('sender_type', value);
                }}
                options={Object.entries(senderTypes)
                  .map(([sender_type, name]) => ({
                    filterValue: sender_type,
                    name,
                  }))
                  .concat({ filterValue: null, name: '(None)' })}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Sender Type" placeholder="" />
                )}
                isOptionEqualToValue={(option, value) => {
                  return option.filterValue === value.filterValue;
                }}
              />
            </AccordionActions>
          </FilterAccordion>
        )}
        <FilterAccordion
          disableGutters
          square
          onChange={(_, expanded) => {
            setCanUseUsersList(expanded);
          }}
        >
          <FilterAccordionSummary
            sx={{
              fontSize: 14,
              '& .MuiAccordionSummary-content': {
                display: 'flex',
                justifyContent: 'space-between',
              },
            }}
          >
            User
            {filterMap?.actor_user_id?.length > 0 && (
              <Chip
                size="small"
                onDelete={() => handleSetFilterMapValue('actor_user_id', [])}
                label={filterMap?.actor_user_id.length}
              />
            )}
          </FilterAccordionSummary>
          <AccordionActions>
            <Autocomplete
              autoComplete
              size="small"
              multiple
              sx={{ width: '100%' }}
              value={filterMap?.actor_user_id ?? []}
              onChange={(_, value) =>
                handleSetFilterMapValue('actor_user_id', value)
              }
              options={normalizedUsersList()}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="User" placeholder="" />
              )}
              isOptionEqualToValue={(option, value) => {
                return option.filterValue === value.filterValue;
              }}
            />
          </AccordionActions>
        </FilterAccordion>

        <FilterAccordion disableGutters square>
          <FilterAccordionSummary
            sx={{
              fontSize: 14,
              '& .MuiAccordionSummary-content': {
                display: 'flex',
                justifyContent: 'space-between',
              },
            }}
          >
            Date
            {(filterMap?.min_dttm || filterMap?.max_dttm) && (
              <Chip
                size="small"
                onDelete={() => {
                  handleSetFilterMapValue('min_dttm', '');
                  handleSetFilterMapValue('max_dttm', '');
                }}
                label={1}
              />
            )}
          </FilterAccordionSummary>
          <AccordionActions
            disableSpacing
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Box display="grid" direction="column" rowGap={2}>
              <TextField
                type="datetime-local"
                label="From"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  max: formatIsoStringToDateTimeLocal(filterMap?.max_dttm),
                }}
                value={formatIsoStringToDateTimeLocal(filterMap?.min_dttm)}
                onChange={(e) => {
                  if (e.target.value === '') handleSetFilterMapValue('min_dttm', '');
                  else
                    handleSetFilterMapValue(
                      'min_dttm',
                      new Date(e.target.value).toISOString()
                    );
                }}
              />
              <TextField
                type="datetime-local"
                label="To"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: formatIsoStringToDateTimeLocal(filterMap?.min_dttm),
                }}
                value={formatIsoStringToDateTimeLocal(filterMap?.max_dttm)}
                onChange={(e) => {
                  if (e.target.value === '') handleSetFilterMapValue('max_dttm', '');
                  else
                    handleSetFilterMapValue(
                      'max_dttm',
                      new Date(e.target.value).toISOString()
                    );
                }}
              />
            </Box>
          </AccordionActions>
        </FilterAccordion>

        {isOnEnterprise() && (
          <FilterAccordion>
            <FilterAccordionSummary>
              Account
              {filterMap?.organization_id.length > 0 && (
                <Chip
                  onDelete={() => {
                    handleSetFilterMapValue('organization_id', []);
                  }}
                  label={filterMap?.organization_id?.length}
                />
              )}
            </FilterAccordionSummary>
            <AccordionActions>
              <Autocomplete
                autoComplete
                multiple
                sx={{ width: '100%' }}
                value={filterMap?.organization_id ?? []}
                onChange={(_, value) =>
                  handleSetFilterMapValue('organization_id', value)
                }
                options={
                  accountsList?.map(({ id, name }) => ({
                    filterValue: id,
                    name,
                  })) ?? []
                }
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Account" />}
                isOptionEqualToValue={(option, value) =>
                  option.filterValue === value.filterValue
                }
              />
            </AccordionActions>
          </FilterAccordion>
        )}
      </Box>
    </Box>
  );
});

const initialActivitiesFilterMapValues = {
  event_type: [],
  sender_type: [],
  min_dttm: '',
  max_dttm: '',
  actor_user_id: [],
  organization_id: [],
};

export default FilterBar;

import { toJSON } from '../queryClientConfig';
import Cookies from 'js-cookie';

export default class Agents {
  static async list(filters) {
    const params = new URLSearchParams(filters).toString();
    return await fetch(
      `${process.env.REACT_APP_MESSAGING_API_BASE}/agents?${params}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async show(agent_id) {
    return await fetch(
      `${process.env.REACT_APP_MESSAGING_API_BASE}/agents/${agent_id}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async edit(agent) {
    return await fetch(
      `${process.env.REACT_APP_MESSAGING_API_BASE}/agents/${agent.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(agent),
      }
    ).then(toJSON);
  }

  static async create(agent) {
    return await fetch(`${process.env.REACT_APP_MESSAGING_API_BASE}/agents`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(agent),
    }).then(toJSON);
  }

  static async delete(agent_id) {
    return await fetch(
      `${process.env.REACT_APP_MESSAGING_API_BASE}/agents/${agent_id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
  }
}

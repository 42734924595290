import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  Typography,
  TextField,
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  useSavedSearchesCreate,
  useSavedSearchesDelete,
} from '../services/savedSearches/mutations';

import { savedSearchesQueryKeys } from '../services/savedSearches/queries';

export const CreateSavedSearchDialog = ({
  isOpen,
  onResolve,
  onReject,
  resourceClass,
  setSelectedSavedSearchId,
  filterParams,
}) => {
  const { mutate: createSavedSearch, isError, isLoading } = useSavedSearchesCreate();
  const [name, setName] = useState('');
  const queryClient = useQueryClient();

  const handleCreateSavedSearch = () => {
    createSavedSearch(
      {
        name,
        is_default: false,
        resource_class: resourceClass,
        data: JSON.stringify(filterParams),
      },
      {
        onSuccess: (output, _) => {
          queryClient
            .invalidateQueries(savedSearchesQueryKeys.lists())
            //TODO change leads and tasks to use onResolve value
            .then(setSelectedSavedSearchId && setSelectedSavedSearchId(output.id));
          onResolve(output.id);
        },
      }
    );
  };
  //TODO use a form and add validations
  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Save Search</DialogTitle>
      <DialogContent>
        {isError && (
          <Alert severity="error">
            There was a problem saving your search. Try again.
          </Alert>
        )}
        <Typography>
          This will save all of your currently selected filters into a saved search.
        </Typography>
        <TextField
          sx={{ mt: 2 }}
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          disabled={isLoading}
          onClick={handleCreateSavedSearch}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export const DeleteSavedSearchDialog = ({
  isOpen,
  onResolve,
  onReject,
  savedSearchId,
}) => {
  const { mutate: deleteSavedSearch, isError, isLoading } = useSavedSearchesDelete();

  const handleDeleteSavedSearch = () => {
    deleteSavedSearch(savedSearchId, {
      onSuccess: onResolve,
    });
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Delete Saved Search</DialogTitle>
      <DialogContent>
        {isError && (
          <Alert severity="error">
            There was a problem deleting your search. Try again.
          </Alert>
        )}
        Are you sure you would like to delete this search?
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          disabled={isLoading}
          onClick={handleDeleteSavedSearch}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import { useMutation, useQueryClient } from 'react-query';
import { mutationKeyFactory } from '../queryClientConfig';
import { notificationEmailAddressesQueryKeys } from './queries';
import NotificationEmailAddresses from './NotificationEmailAddresses';

const notificationEmailAddressesMutationKeys = mutationKeyFactory(
  'notification-email-addresses'
);

export const useNotificationEmailAddressesCreate = (options) => {
  const queryClient = useQueryClient();
  return useMutation((email) => NotificationEmailAddresses.create({ ...email }), {
    mutationKey: notificationEmailAddressesMutationKeys.create(),
    onSuccess: () => {
      queryClient.invalidateQueries(notificationEmailAddressesQueryKeys.all);
    },
    ...options,
  });
};

export const useNotificationEmailAddressesEdit = (options) => {
  const queryClient = useQueryClient();
  return useMutation((email) => NotificationEmailAddresses.edit(email), {
    mutationKey: notificationEmailAddressesMutationKeys.edit(),
    onSuccess: () => {
      queryClient.invalidateQueries(notificationEmailAddressesQueryKeys.all);
    },
    ...options,
  });
};

export const useNotificationEmailAddressesDelete = (options) => {
  const queryClient = useQueryClient();
  return useMutation((email_id) => NotificationEmailAddresses.delete(email_id), {
    mutationKey: notificationEmailAddressesMutationKeys.delete(),
    onSuccess: () => {
      queryClient.invalidateQueries(notificationEmailAddressesQueryKeys.all);
    },
    ...options,
  });
};

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { styled } from '@mui/system';

export function AiUpsellModal({ enabled }) {
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={!enabled}
      maxWidth="md"
    >
      <DialogTitle
        sx={{ m: 0, p: 2, textAlign: 'center' }}
        id="customized-dialog-title"
      >
        Activate Copilot - Your New AI Receptionist
      </DialogTitle>
      <DialogContent dividers>
        <Typography mb={1.5}>
          Put your practice growth on autopilot today!
        </Typography>
        <Typography mb={1.5}>
          ✅ Never miss another new patient call: Your custom AI-powered receptionist
          will answer your calls and book appointments 24x7x365
        </Typography>
        <Typography mb={1.5}>
          ✅ Never send another new patient to voicemail: Your Copilot will answer
          questions, take messages, and guide new patients to scheduling an
          appointment automatically
        </Typography>
        <Typography mb={1.5}>
          ✅ Never let another opportunity slip through the cracks: Your Copilot will
          send any unscheduled new patients automatically to your dashboard for
          automated follow-up
        </Typography>
        <Typography mb={1.5}>
          The perfect growth supplement for a busy front desk.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => (window.location = '/')}>Back</Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

export function CallTrackingUpsellModal({ callTrackingEnabled }) {
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={!callTrackingEnabled}
      maxWidth="md"
    >
      <DialogTitle
        sx={{ m: 0, p: 2, textAlign: 'center' }}
        id="customized-dialog-title"
      >
        Activate Call Tracking
      </DialogTitle>
      <DialogContent dividers>
        <Typography mb={1.5}>
          Are you tired of not knowing exactly how new customers are finding you?
        </Typography>
        <Typography mb={1.5}>
          Do you desire better than “best guess” figures for new customer calls?{' '}
        </Typography>
        <Typography mb={1.5}>
          Do you wish you had all of your marketing and new customer attribution data
          in one place?
        </Typography>
        <Typography mb={1.5}>
          Activate our Call Tracking feature now and gain a 360° view of your
          marketing. Maximize ROI, boost conversion rates, and optimize marketing
          spend. Dive into customer conversations for enhanced profiles, retarget
          with precision, and refine your messaging. Improve team member performance
          effortlessly with seamless integration into your existing technology.
        </Typography>
        <Typography mb={1.5}>
          Don’t miss out — email{' '}
          <a href="mailto:support@leadsigma.com">support@leadsigma.com</a> to enable
          Call Tracking and elevate your marketing game with accurate and complete
          new customer attribution today!🚀
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => (window.location = '/')}>Back</Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

import { React, useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import {
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TableHead,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  Grid,
  Alert,
  Select,
  MenuItem,
  Slider,
  InputLabel,
} from '@mui/material';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { LoadingButton } from '@mui/lab';
import { SaveIcon, EditIcon, DeleteIcon, PlusIcon } from '../theme/icons';
import HookForm from '../components/HookForm';
import { fireDialog } from '../components/Dialog';
import { useAiModulesShow } from '../../api/aiModules/queries';
import { useAiModuleEdit } from '../../api/aiModules/mutation';
import {
  useAiModuleFunctionDefinitionsCreate,
  useAiModuleFunctionDefinitionsDelete,
} from '../../api/aiModulesFunctionDefinitions/mutation';

export default function AIModulesEdit() {
  const aiModuleID = useParams();
  const {
    data: aiModulesShow,
    isFetching: aiModulesFetaching,
    isLoading: aiModuleLoading,
    isError: aiModulesError,
    error,
  } = useAiModulesShow(aiModuleID?.moduleID);

  const { mutate: editmutate, isError, isSuccess, isLoading } = useAiModuleEdit();
  const [formData, setFormData] = useState({
    name: '',
    prompt: '',
    llm: '',
    temperature: '',
  });

  const [isAlertVisible, setAlertVisible] = useState(false);

  const handleEditSubmit = () => {
    editmutate({
      id: aiModuleID?.moduleID,
      name: formData?.name || aiModulesShow?.name,
      prompt: formData?.prompt || aiModulesShow?.prompt,
      llm: formData?.llm || aiModulesShow?.llm,
      temperature: formData?.temperature || aiModulesShow?.temperature,
    });
  };

  useEffect(() => {
    if (aiModulesShow) {
      setFormData({
        name: aiModulesShow?.name || '',
        prompt: aiModulesShow?.prompt || '',
        llm: aiModulesShow?.llm || '',
        temperature: aiModulesShow?.temperature || '',
      });
    }
  }, [aiModulesShow]);

  const handleInputChange = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const handleSliderChange = (value, field) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
      }, 200);
    }
  }, [isSuccess, isError]);

  const onCreate = () => {
    fireDialog((promiseProps) => (
      <AddAIModulesFunction aiModuleID={aiModuleID} {...promiseProps} />
    ));
  };

  const onDelete = (aiModuleFunID) => {
    fireDialog((promiseProps) => (
      <DeleteAIModulesFunction aiModuleFunID={aiModuleFunID} {...promiseProps} />
    ));
  };

  return (
    <Box sx={{ height: '100%' }}>
      <SettingsCard>
        <SettingsCard.Header>Edit Modules</SettingsCard.Header>
        {isError && (
          <Alert severity="error">There was a problem while editing.</Alert>
        )}
        {isAlertVisible && (
          <Alert severity="success">Module updated successfully.</Alert>
        )}
        <Grid
          container
          display="flex"
          direction="column"
          sx={{ rowGap: 2, marginTop: 2 }}
        >
          <Grid item>
            <TextField
              label="Name"
              type="text"
              value={formData?.name || ''}
              onChange={(e) => handleInputChange(e, 'name')}
            />
          </Grid>
          <Grid item>
            <TextField
              multiline
              label="Prompt"
              type="text"
              rows={6}
              defaultValue={formData?.prompt || ''}
              sx={{ width: '50%' }}
              onChange={(e) => handleInputChange(e, 'prompt')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item>
            <InputLabel>LLM</InputLabel>
            <Select
              variant={'standard'}
              value={formData.llm || ''}
              onChange={(e) => handleInputChange(e, 'llm')}
            >
              <MenuItem value="gpt35">GPT 3.5</MenuItem>
              <MenuItem value="gpt4">GPT 4</MenuItem>
              <MenuItem value="azure_gpt35">Azure GPT 3.5</MenuItem>
              <MenuItem value="azure_gpt4">Azure GPT 4</MenuItem>
            </Select>
          </Grid>
          <Grid item>
            <InputLabel>Temperature</InputLabel>
            <Slider
              value={formData?.temperature || ''}
              onChange={(e, value) => handleSliderChange(value, 'temperature')}
              step={0.01}
              marks={[
                { value: 0, label: '0' },
                { value: 1, label: '1' },
                { value: 2, label: '2' },
              ]}
              min={0}
              max={2}
              sx={{ width: '50%' }}
              valueLabelDisplay="auto"
            />
          </Grid>
          <Grid item>
            <LoadingButton
              loading={isLoading}
              startIcon={<SaveIcon />}
              type="submit"
              onClick={handleEditSubmit}
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid
          container
          display="flex"
          direction="column"
          sx={{ rowGap: 1, marginTop: 2.5 }}
        >
          <SettingsCard.SubHeader>Function Definitions</SettingsCard.SubHeader>
          <TableContainer sx={{ mb: 2 }}>
            <Table>
              <TableHead>
                <TableRow size="small">
                  <TableCell>Name</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {aiModulesShow?.function_definitions?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <Typography component={'span'} variant="subtitle3">
                        {item?.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Button
                        startIcon={<EditIcon />}
                        size="small"
                        variant="text"
                        color="primary"
                        sx={{ marginRight: 1 }}
                        component={Link}
                        to={`/settings/function/${item?.id}`}
                      >
                        Edit
                      </Button>
                      <Button
                        startIcon={<DeleteIcon />}
                        size="small"
                        variant="text"
                        color="error"
                        sx={{ marginRight: 1 }}
                        onClick={() => onDelete(item.id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid item>
            <Button
              startIcon={<PlusIcon />}
              onClick={onCreate}
              sx={{ marginRight: 1 }}
            >
              Add New
            </Button>
          </Grid>
        </Grid>
      </SettingsCard>
    </Box>
  );
}

const AddAIModulesFunction = ({ isOpen, onReject, onResolve, aiModuleID }) => {
  const {
    mutate: createmutatefunction,
    isError,
    isSuccess,
    isLoading,
  } = useAiModuleFunctionDefinitionsCreate();
  const [aiModulesFunctionName, setAIModulesFunctionName] = useState();
  const [aiModulesFunctionDescription, setAIModulesFunctionDescription] = useState();
  const history = useHistory();

  const handleCreateSubmit = () => {
    createmutatefunction(
      {
        name: aiModulesFunctionName,
        description: aiModulesFunctionDescription,
        ai_module_id: aiModuleID?.moduleID,
      },
      {
        onSuccess: (data) => {
          let to = `/settings/function/${data.id}`;
          history.push(to);
        },
      }
    );
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        onResolve();
      }, 200);
    }
  }, [isError, isSuccess]);

  const defaultValues = {
    name: '',
    description: '',
  };

  const useFormProps = {
    defaultValues,
  };

  return (
    <Box>
      <Dialog open={isOpen} onClose={onReject}>
        <DialogTitle>Create Function</DialogTitle>
        <HookForm useFormProps={useFormProps} onSubmit={handleCreateSubmit}>
          <DialogContent>
            {isError && (
              <Alert severity="error">
                There was a problem while creating Modules Function.
              </Alert>
            )}
            {isSuccess && (
              <Alert severity="success">
                Modules Function created successfully.
              </Alert>
            )}
            <Box display="flex" flexDirection="column" rowGap={2}>
              <TextField
                label="Name"
                type="text"
                value={aiModulesFunctionName || ''}
                onChange={(e) => setAIModulesFunctionName(e.target.value)}
              />
              <TextField
                label="Description"
                type="text"
                multiline
                rows={5}
                defaultValue={aiModulesFunctionDescription || ''}
                onChange={(e) => setAIModulesFunctionDescription(e.target.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="text" color="inherit" onClick={onReject}>
              Cancel
            </Button>
            <LoadingButton type="submit" loading={isLoading} disabled={isSuccess}>
              Confirm
            </LoadingButton>
          </DialogActions>
        </HookForm>
      </Dialog>
    </Box>
  );
};

const DeleteAIModulesFunction = ({ isOpen, onReject, onResolve, aiModuleFunID }) => {
  const {
    mutate: aiModulesFunctionDelete,
    isLoading: didDeleteloading,
    isError: didDeleteError,
    isSuccess: didDelteSuccess,
  } = useAiModuleFunctionDefinitionsDelete();
  useEffect(() => {
    if (didDelteSuccess && !didDeleteError) {
      setTimeout(() => {
        onResolve();
      }, 200);
    }
  }, [didDelteSuccess, didDeleteError]);

  const onSubmit = () => {
    aiModulesFunctionDelete({ id: aiModuleFunID });
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Delete Function</DialogTitle>
      <DialogContent>
        {didDeleteError && (
          <Alert severity="error">
            There was a problem while deleting function.
          </Alert>
        )}
        {didDelteSuccess && <Alert severity="success">Deleted successfully.</Alert>}
        Are you sure to delete function.
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          loading={didDeleteloading}
          disabled={didDelteSuccess}
          onClick={onSubmit}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import { useMutation, useQueryClient } from 'react-query';
import CallFlowAPI from './CallFlow';
import { callFlowQueryKeys } from './queries';

export const useCallFlowCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(CallFlowAPI.create, {
    mutationKey: ['call_flow'],
    onSuccess: () => {
      queryClient.invalidateQueries(callFlowQueryKeys.all);
    },
  });
};

export const useCallFlowEdit = (...options) => {
  const queryClient = useQueryClient();
  return useMutation(CallFlowAPI.edit, {
    mutationKey: ['call_flow'],
    onSuccess: () => {
      queryClient.invalidateQueries(callFlowQueryKeys.all);
    },
    options,
  });
};

export const useCallFlowShare = (options) => {
  const queryClient = useQueryClient();
  return useMutation(CallFlowAPI.share, {
    mutationKey: ['call_flow'],
    onSuccess: () => {
      queryClient.invalidateQueries(callFlowQueryKeys.all);
    },
  });
};

export const useCallFlowDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(CallFlowAPI.delete, {
    mutationKey: ['call_flow'],
    onSuccess: () => queryClient.invalidateQueries(callFlowQueryKeys.all),
  });
};

import parsePhoneNumber from 'libphonenumber-js';

export const getMediaDevicePermission = (setHasMediaPermission, setWarning) =>
  navigator.mediaDevices
    .getUserMedia({ video: false, audio: true })
    .then((stream) => {
      setHasMediaPermission(true);
      stream.getTracks().forEach((track) => track.stop());
      setWarning();
    })
    .catch((_) => {
      setHasMediaPermission(false);
      setWarning('Please enable your microphone to make calls from your browser.');
    });

export const getUserTaskPhone = (user) => {
  const phone = user.task_phone && parsePhoneNumber(user.task_phone.number);
  return phone ? phone.formatNational() : '';
};

import {
  useNotificationEmailAddressesList,
  notificationEmailAddressesQueryKeys,
} from '../../services/notificationEmailAddresses/queries';
import { useNotificationEmailAddressesEdit } from '../../services/notificationEmailAddresses/mutations';
import { useUsersEdit } from '../../services/users/mutations';
import { useQueryClient } from 'react-query';
import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import HookForm from '../../components/HookForm';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  createYupResolver,
  EMAIL_ADDRESS,
  yupFieldValidationsMap,
} from '../../components/HookForm/yupValidations';

// dialogs -----------------------------------------------------------
export const AddEmailDialog = ({ isOpen, onResolve, onReject, user }) => {
  const queryClient = useQueryClient();
  const { mutate: editUser, isLoading, isError } = useUsersEdit();
  const { data: notificationEmailAddresses } = useNotificationEmailAddressesList(
    user.id
  );

  const onAddEmailSubmit = (formData) => {
    if (
      confirm(
        'By adding this email you confirm it is safe to send Personally Identifiable Information (PII) and Personal Health Information (PHI) of your leads to the email address.'
      )
    )
      editUser(
        {
          id: user.id,
          notification_email_addresses: [
            {
              email: formData[EMAIL_ADDRESS],
              name: formData[EMAIL_ADDRESS],
            },
          ],
        },
        {
          onSuccess: onResolve,
        }
      );
  };

  const existingEmails = [];
  notificationEmailAddresses?.forEach(({ email }) => existingEmails.push(email));

  const defaultValues = { [EMAIL_ADDRESS]: '' };
  const useFormProps = {
    defaultValues,
    resolver: yupResolver(
      yup.object({
        [EMAIL_ADDRESS]: yup
          .mixed()
          .notOneOf(existingEmails, 'This email already exists')
          .concat(yupFieldValidationsMap.emailAddress),
      })
    ),
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Add New Email</DialogTitle>
      <HookForm
        useFormProps={useFormProps}
        onSubmit={onAddEmailSubmit}
        useDirtyFormCheck
      >
        <DialogContent>
          {isError && (
            <Alert severity="error">
              There was an error saving your email address. Please try again.
            </Alert>
          )}
          <HookForm.TextField name={EMAIL_ADDRESS} label="Email Address" />
        </DialogContent>
        <DialogActions>
          <Button variant="cancel" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton loading={isLoading} type="submit">
            Confirm
          </LoadingButton>
        </DialogActions>
      </HookForm>
    </Dialog>
  );
};
// ----------------------------------------------
export const EditEmailDialog = ({
  onResolve,
  onReject,
  isOpen,
  emailAddress,
  user,
}) => {
  const { mutate: editUser, isLoading, isError } = useUsersEdit();

  const onAddEmailSubmit = (formData) => {
    if (
      confirm(
        'By changing this email you confirm it is safe to send Personally Identifiable Information (PII) and Personal Health Information (PHI) of your leads to the email address.'
      )
    ) {
      editUser(
        {
          id: user.id,
          notification_email_addresses: [
            {
              ...emailAddress,
              email: formData[EMAIL_ADDRESS],
            },
          ],
        },
        {
          onSuccess: onResolve,
        }
      );
    }
  };

  const defaultValues = { [EMAIL_ADDRESS]: emailAddress.email };
  const useFormProps = {
    defaultValues,
    resolver: createYupResolver(defaultValues),
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Edit Notification Email</DialogTitle>
      <HookForm
        onSubmit={onAddEmailSubmit}
        useFormProps={useFormProps}
        useDirtyFormCheck
      >
        <DialogContent>
          {isError && (
            <Alert severity="error">
              There was an error editing your email address. Please try again.
            </Alert>
          )}
          <HookForm.TextField name={EMAIL_ADDRESS} label="Email Address" />
        </DialogContent>
        <DialogActions>
          <Button variant="cancel" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton loading={isLoading} type="submit">
            Confirm
          </LoadingButton>
        </DialogActions>
      </HookForm>
    </Dialog>
  );
};
// ----------------------------------------------

export const DeleteEmailDialog = ({
  isOpen,
  onResolve,
  onReject,
  emailAddress,
  user,
}) => {
  const { mutate: editUser, isLoading, isError } = useUsersEdit();

  const handleConfirmClick = () => {
    editUser(
      {
        id: user.id,
        notification_email_addresses: [
          {
            id: emailAddress.id,
            _destroy: true,
          },
        ],
      },
      {
        onSuccess: onResolve,
      }
    );
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Delete Notification Email / {emailAddress.email}</DialogTitle>
      <DialogContent>
        {isError && (
          <Alert severity="error">
            There was a problem deleting your email. Try again.
          </Alert>
        )}
        Are you sure you no longer wish to be sent notifications to this email
        address?
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton loading={isLoading} onClick={handleConfirmClick}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import { useQuery } from 'react-query';
import { ONE_HOUR, queryKeyFactory } from '../queryClientConfig';
import NotificationEmailAddresses from './NotificationEmailAddresses';

export const notificationEmailAddressesQueryKeys = queryKeyFactory(
  'notification-email-addresses'
);

export const useNotificationEmailAddressesList = (user_id) => {
  const transformToItems = (data) => {
    return data.items;
  };

  const filters = { user_id };
  return useQuery(
    notificationEmailAddressesQueryKeys.list(filters),
    () => NotificationEmailAddresses.list({ ...filters }),
    {
      select: transformToItems,
      staleTime: ONE_HOUR,
    }
  );
};

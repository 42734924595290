import Cookies from 'js-cookie';
import { getActiveOrg } from '../util/UserUtils';

export default class Phone {
  static async edit(phone) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/phones/${phone.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(phone),
      }
    );
    return response.json();
  }

  static async create(phone) {
    phone['organization_id'] = getActiveOrg();
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/phones`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(phone),
    });
    return response.json();
  }
}

import { useMutation, useQueryClient } from 'react-query';

import { useOrg } from '../../contexts/OrgProvider';
import AccountFieldsEntity from './accountFieldsEntity';

export const useAccountFieldsEdit = () => {
  const queryClient = useQueryClient();
  return useMutation(AccountFieldsEntity.edit, {
    mutationKey: ['account_fields', 'edit'],
    onSuccess: () => {
      queryClient.invalidateQueries('account_fields');
      queryClient.invalidateQueries('slingshot_fields');
    },
  });
};

export const useAccountFieldsDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(AccountFieldsEntity.delete, {
    mutationKey: ['account_fields', 'delete'],
    onSuccess: () => {
      queryClient.invalidateQueries('account_fields');
      queryClient.invalidateQueries('slingshot_fields');
    },
  });
};

export const useAccountFieldsCreate = () => {
  const queryClient = useQueryClient();
  const { id } = useOrg();
  return useMutation(
    (params) =>
      AccountFieldsEntity.create({
        ...params,
        organization_id: String(id),
      }),
    {
      mutationKey: ['account_fields', 'create'],
      onSuccess: () => {
        queryClient.invalidateQueries('account_fields');
        queryClient.invalidateQueries('slingshot_fields');
      },
    }
  );
};

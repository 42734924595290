import React, { createContext, useContext, useState } from 'react';

const LayoutContext = createContext();
export const useLayout = () => useContext(LayoutContext);

const LayoutProvider = ({ children }) => {
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);

  return (
    <LayoutContext.Provider value={{ mobileNavIsOpen, setMobileNavIsOpen }}>
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;

import {
  useOrganizationsList,
  useBillingAddress,
  useOrganizationServices,
} from '../services/organizations/queries';
import { useServicesList } from '../services/services/queries';
import {
  useOrganizationsCreate,
  useOrganizationsEdit,
} from '../services/organizations/mutations';
import { useOrg } from '../../contexts/OrgProvider';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { fireDialog } from '../components/Dialog';
import { LoadingButton } from '@mui/lab';
import HookForm from '../components/HookForm';
import { useConditionalUseFormProps } from '../components/HookForm/hooks';
import { EditIcon, PlusIcon } from '../theme/icons';
import {
  createYupResolver,
  ADDRESS_LINE_ONE,
  ADDRESS_LINE_TWO,
  CITY,
  REGION,
  POSTAL_CODE,
  NAME,
} from '../components/HookForm/yupValidations';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Alert,
  MenuItem,
  Tooltip,
  Switch,
  FormControlLabel,
  FormGroup,
  FormControl,
  FormLabel,
  Zoom,
  Link,
  Typography,
} from '@mui/material';
import ClipLoader from 'react-spinners/ClipLoader';
import { useState, useEffect } from 'react';
import { DataGridStyle } from '../theme/styled/DataGridStyle';
import { dateToPrettyDateTimeString } from '../services/dateTime';

const AccountsList = () => {
  const { isMasterAccount, isEnterpriseUser, enterprise_id } = useOrg();
  const { data: organizations, isFetching, isError } = useOrganizationsList({
    enterprise_id: enterprise_id,
  });
  const { data: servicesList } = useServicesList();
  const [isTimeoutRunning, setIsTimeoutRunning] = useState(false);
  var timeout = null;

  const startRefreshTimer = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      window.location.href = window.location.href;
    }, 10000);
    setIsTimeoutRunning(true);
  };

  const handleEditAccountClick = (organization) => {
    fireDialog((promiseProps) =>
      EditAccountDialog({
        organization,
        servicesList,
        ...promiseProps,
      })
    );
  };

  const onAddClick = () => {
    fireDialog((promiseProps) =>
      AddAccountDialog({
        servicesList,
        ...promiseProps,
        onResolve: () => {
          startRefreshTimer();
          promiseProps.onResolve();
        },
      })
    );
  };

  const [pageSize, setPageSize] = useState(25);
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'time_zone',
      headerName: 'Time Zone',
      flex: 0.7,
    },
    {
      field: 'created_at',
      headerName: 'Created at',
      flex: 1,
      renderCell: ({ formattedValue }) => (
        <Typography variant="body2" noWrap>
          {dateToPrettyDateTimeString(formattedValue)}
        </Typography>
      ),
    },
    {
      field: 'edit',
      headerName: '',
      flex: 0.5,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Button
            sx={{}}
            startIcon={<EditIcon />}
            variant="text"
            onClick={() => handleEditAccountClick(row)}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  return (
    <Box sx={{ height: '100%' }}>
      {!isMasterAccount || !isEnterpriseUser ? (
        <Alert severity="error">
          This page cannot be viewed outside an enterprise or you do not have the
          proper access to view this page.
        </Alert>
      ) : (
        <SettingsCard
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <SettingsCard.Header>Accounts</SettingsCard.Header>
          <SettingsCard.SubHeader>
            Add or edit accounts. Please contact{' '}
            <Link
              underline="none"
              sx={{ color: ({ palette }) => palette.info.main }}
              href="mailto:support@leadsigma.com"
            >
              support@leadsigma.com
            </Link>{' '}
            to remove an account.
          </SettingsCard.SubHeader>
          <SettingsCard.Main sx={{ flex: 1 }}>
            {isError && (
              <Alert severity="error">
                There was a problem loading your accounts. Try again.
              </Alert>
            )}
            {isTimeoutRunning ? (
              <Box>
                <Alert severity="info">
                  Please wait while we setup your new account.
                </Alert>
                <Box display="flex" justifyContent="center">
                  <ClipLoader size={300} color="#34D1B6" />
                </Box>
              </Box>
            ) : (
              <Box height="100%" display="flex" flexDirection="column" gap={2}>
                <DataGridStyle
                  loading={isFetching}
                  rows={organizations ?? []}
                  columns={columns}
                  sx={{ flex: 1, minHeight: '400px' }}
                  rowsPerPageOptions={[10, 25, 50]}
                  onPageSizeChange={setPageSize}
                  pageSize={pageSize}
                  disableColumnMenu
                  density="compact"
                  disableSelectionOnClick
                />
                <Button
                  sx={{ alignSelf: 'flex-start' }}
                  startIcon={<PlusIcon />}
                  onClick={onAddClick}
                >
                  Add New
                </Button>
              </Box>
            )}
          </SettingsCard.Main>
        </SettingsCard>
      )}
    </Box>
  );
};

export default AccountsList;

// dialogs --------------------------------------------------------------
const EditAccountDialog = ({
  organization,
  servicesList,
  isOpen,
  onResolve,
  onReject,
}) => {
  const { mutate: editAccount, isError, isLoading } = useOrganizationsEdit();
  const {
    data: billingAddresses,
    isFetching: isFetchingBillingAddress,
    isError: isErrorBillingAddresses,
  } = useBillingAddress(organization.id);
  const {
    data: organizationServicesList,
    isError: isErrorOrganizationServicesList,
  } = useOrganizationServices(organization.id);

  const [organizationServices, setOrganizationServices] = useState();

  const possibleTimezones = [
    'Pacific/Honolulu',
    'America/Los_Angeles',
    'America/Phoenix',
    'America/Denver',
    'America/Chicago',
    'America/New_York',
    'America/Puerto_Rico',
  ];

  const handleEditOrgSubmit = (formData) => {
    editAccount(
      {
        ...organization,
        [NAME]: formData[NAME],
        ['time_zone']: formData['timeZone'],
        billing_address: {
          ...(billingAddresses.length > 0 && billingAddresses[0]),
          address_line_one: formData[ADDRESS_LINE_ONE],
          address_line_two: formData[ADDRESS_LINE_TWO],
          city: formData[CITY],
          region: formData[REGION],
          postal_code: formData[POSTAL_CODE],
          //TODO internationalization
          country: 'US',
        },
        enable_services: Object.entries(organizationServices).map(
          ([id, service]) => {
            if (!service.isEnabled && service.isEnablementChanged)
              return { service_id: id };
          }
        ),
        disable_services: Object.entries(organizationServices).map(
          ([id, service]) => {
            if (service.isEnabled && service.isEnablementChanged)
              return { id: id, _destroy: true };
          }
        ),
      },
      {
        onSuccess: onResolve,
      }
    );
  };

  const defaultValues = {
    [NAME]: organization.name,
    ['timeZone']: organization.time_zone,
    [ADDRESS_LINE_ONE]: billingAddresses
      ? billingAddresses[0]?.address_line_one ?? ''
      : '',
    [ADDRESS_LINE_TWO]: billingAddresses
      ? billingAddresses[0]?.address_line_two ?? ''
      : '',
    [CITY]: billingAddresses ? billingAddresses[0]?.city ?? '' : '',
    [REGION]: billingAddresses ? billingAddresses[0]?.region ?? '' : '',
    [POSTAL_CODE]: billingAddresses ? billingAddresses[0]?.postal_code ?? '' : '',
  };

  const useFormProps = useConditionalUseFormProps(
    {
      defaultValues,
      resolver: createYupResolver(defaultValues),
    },
    !isFetchingBillingAddress
  );

  const handleChange = (id, newValue) => {
    const obj = Object.defineProperty(organizationServices, id, {
      value: { ...organizationServices[id], isEnablementChanged: newValue },
      writable: false,
    });
    setOrganizationServices({ ...obj });
  };

  const isServiceEnabled = (id) => {
    return organizationServicesList.some(
      (orgSrevice) => orgSrevice.service_id == id
    );
  };

  useEffect(() => {
    if (organizationServicesList) {
      let obj = {};
      servicesList.forEach((service) => {
        obj[service.id] = {
          ...service,
          isEnabled: isServiceEnabled(service.id),
          isEnablementChanged: false,
        };
      });

      setOrganizationServices({ ...obj });
    }
  }, [organizationServicesList]);

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Edit Account</DialogTitle>
      <HookForm useFormProps={useFormProps} onSubmit={handleEditOrgSubmit}>
        <DialogContent>
          {isError && (
            <Alert severity="error">
              There was a problem editing your organization. Try again.
            </Alert>
          )}
          {isErrorBillingAddresses && (
            <Alert severity="error">
              There was a problem loading your billing address. Try again.
            </Alert>
          )}
          <Box display="flex" flexDirection="column" rowGap={2}>
            <Tooltip
              title={
                <Box>
                  Updates Account Name.
                  <br />
                  Note: To update the name displayed to leads you must edit this
                  account's Team names.
                </Box>
              }
              placement="top-end"
              TransitionComponent={Zoom}
            >
              <Box>
                <HookForm.TextField name={NAME} label="Account Name" />
              </Box>
            </Tooltip>
            <HookForm.Select name="timeZone" label="Time Zone">
              {possibleTimezones.map((zone) => (
                <MenuItem value={zone} key={zone}>
                  {zone}
                </MenuItem>
              ))}
            </HookForm.Select>
            <HookForm.TextField
              name={ADDRESS_LINE_ONE}
              label="Street address or PO Box"
              autoComplete="address-line1"
            />
            <HookForm.TextField
              name={ADDRESS_LINE_TWO}
              label="Apt, suite, building, etc"
              autoComplete="address-line2"
            />
            <HookForm.TextField
              name={CITY}
              label="City"
              autoComplete="address-level2"
            />
            <Box display="flex" gap={2}>
              <HookForm.TextField
                name={REGION}
                label="State"
                autoComplete="address-level1"
              />
              <HookForm.TextField
                name={POSTAL_CODE}
                label="Postal Code"
                autoComplete="postal-code"
              />
            </Box>
            {isErrorOrganizationServicesList && (
              <Alert severity="error">
                There was a problem loading your organization services. Try again.
              </Alert>
            )}
            {servicesList && organizationServices && (
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">Organization Services</FormLabel>
                <FormGroup>
                  {Object.entries(organizationServices).map(([id, service]) => (
                    <FormControlLabel
                      key={id}
                      label={service.name}
                      control={
                        <Switch
                          defaultChecked={service.isEnabled}
                          onChange={() =>
                            handleChange(id, !service.isEnablementChanged)
                          }
                        />
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton type="submit" loading={isLoading} disabled={isLoading}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </HookForm>
    </Dialog>
  );
};

const AddAccountDialog = ({ servicesList, isOpen, onResolve, onReject }) => {
  const { mutate: createAccount, isError, isLoading } = useOrganizationsCreate();
  const { enterprise_id } = useOrg();

  const [organizationServices, setOrganizationServices] = useState();

  const possibleTimezones = [
    'Pacific/Honolulu',
    'America/Los_Angeles',
    'America/Phoenix',
    'America/Denver',
    'America/Chicago',
    'America/New_York',
    'America/Puerto_Rico',
  ];

  const handleCreateOrgSubmit = (formData) => {
    createAccount(
      {
        [NAME]: formData[NAME],
        ['time_zone']: formData['timeZone'],
        billing_address: {
          address_line_one: formData[ADDRESS_LINE_ONE],
          address_line_two: formData[ADDRESS_LINE_TWO],
          city: formData[CITY],
          region: formData[REGION],
          postal_code: formData[POSTAL_CODE],
          //TODO internationalization
          country: 'US',
        },
        affiliated_enterprise_id: enterprise_id,
        enable_services: Object.entries(organizationServices).map(
          ([id, service]) => {
            if (!service.isEnabled && service.isEnablementChanged)
              return { service_id: id };
          }
        ),
      },
      {
        onSuccess: onResolve,
      }
    );
  };

  const defaultValues = {
    [NAME]: '',
    [ADDRESS_LINE_ONE]: '',
    [ADDRESS_LINE_TWO]: '',
    [CITY]: '',
    [REGION]: '',
    [POSTAL_CODE]: '',
    ['timeZone']: 'America/Chicago',
  };

  const useFormProps = useConditionalUseFormProps(
    {
      defaultValues,
      resolver: createYupResolver(defaultValues),
    },
    true
  );

  const handleChange = (id, newValue) => {
    const obj = Object.defineProperty(organizationServices, id, {
      value: { ...organizationServices[id], isEnablementChanged: newValue },
      writable: false,
    });
    setOrganizationServices({ ...obj });
  };

  useEffect(() => {
    let obj = {};
    servicesList.forEach((service) => {
      obj[service.id] = {
        ...service,
        isEnabled: false,
        isEnablementChanged: false,
      };
    });

    setOrganizationServices({ ...obj });
  }, []);

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Create Account</DialogTitle>
      <HookForm useFormProps={useFormProps} onSubmit={handleCreateOrgSubmit}>
        <DialogContent>
          {isError && (
            <Alert severity="error">
              There was a problem creating your account. Try again.
            </Alert>
          )}
          <Box display="flex" flexDirection="column" rowGap={2}>
            <HookForm.TextField name={NAME} label="Account Name" />
            <HookForm.Select name="timeZone" label="Time Zone">
              {possibleTimezones.map((zone) => (
                <MenuItem value={zone} key={zone}>
                  {zone}
                </MenuItem>
              ))}
            </HookForm.Select>
            <HookForm.TextField
              name={ADDRESS_LINE_ONE}
              label="Street address or PO Box"
              autoComplete="address-line1"
            />
            <HookForm.TextField
              name={ADDRESS_LINE_TWO}
              label="Apt, suite, building, etc"
              autoComplete="address-line2"
            />
            <HookForm.TextField
              name={CITY}
              label="City"
              autoComplete="address-level2"
            />
            <Box display="flex" gap={2}>
              <HookForm.TextField
                name={REGION}
                label="State"
                autoComplete="address-level1"
              />
              <HookForm.TextField
                name={POSTAL_CODE}
                label="Postal Code"
                autoComplete="postal-code"
              />
            </Box>
            {servicesList && organizationServices && (
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">Organization Services</FormLabel>
                <FormGroup>
                  {Object.entries(organizationServices).map(([id, service]) => (
                    <FormControlLabel
                      key={id}
                      label={service.name}
                      control={
                        <Switch
                          defaultChecked={service.isEnabled}
                          onChange={() =>
                            handleChange(id, !service.isEnablementChanged)
                          }
                        />
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton type="submit" loading={isLoading} disabled={isLoading}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </HookForm>
    </Dialog>
  );
};

import Cookies from 'js-cookie';
import { toJSON, toParamString } from '../queryClientConfig';

export default class Event {
  static async list(filters = {}) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/events?${toParamString(filters)}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async create(event) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/events`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(event),
    }).then(toJSON);
  }
}

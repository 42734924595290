import { useMutation, useQueryClient } from 'react-query';

import { useOrg } from '../../contexts/OrgProvider';
import CustomFields from './CustomFields';

export const useCustomFieldsEdit = () => {
  const queryClient = useQueryClient();
  return useMutation(CustomFields.edit, {
    mutationKey: ['custom_fields', 'edit'],
    onSuccess: () => {
      queryClient.invalidateQueries('custom_fields');
      queryClient.invalidateQueries('slingshot_fields');
    },
  });
};

export const useCustomFieldsReorder = () => {
  const queryClient = useQueryClient();
  const { id } = useOrg();
  return useMutation(
    (customFieldsOrders) => {
      const body = {
        custom_fields: customFieldsOrders,
        organization_id: String(id),
      };
      return CustomFields.order(body);
    },
    {
      mutationKey: ['custom_fields', 'edit'],
      onSuccess: () => {
        queryClient.invalidateQueries('custom_fields');
        queryClient.invalidateQueries('slingshot_fields');
      },
    }
  );
};

export const useCustomFieldsDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(CustomFields.delete, {
    mutationKey: ['custom_fields', 'delete'],
    onSuccess: () => {
      queryClient.invalidateQueries('custom_fields');
      queryClient.invalidateQueries('slingshot_fields');
    },
  });
};

export const useCustomFieldsCreate = () => {
  const queryClient = useQueryClient();
  const { id } = useOrg();
  return useMutation(
    (params) =>
      CustomFields.create({
        ...params,
        organization_id: String(id),
      }),
    {
      mutationKey: ['custom_fields', 'create'],
      onSuccess: () => {
        queryClient.invalidateQueries('custom_fields');
        queryClient.invalidateQueries('slingshot_fields');
      },
    }
  );
};

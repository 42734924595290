// ANCHOR key factories
export const queryKeyFactory = (resourceString) => {
  const keys = {
    all: [resourceString],
    lists: () => [...keys.all, 'list'],
    list: (filters = []) => {
      if (Array.isArray(filters)) {
        var t;
        filters = filters.filter(((t = {}), (a) => !(t[a] = a in t)));
      }
      return [...keys.lists(), { filters }];
    },
    shows: () => [...keys.all, 'show'],
    show: (params) => [...keys.shows(), { params }],
  };
  return keys;
};

export const mutationKeyFactory = (resourceString) => {
  const keys = {
    all: [resourceString],
    create: (params) => [...keys.all, 'create', { params }],
    edit: (params) => [...keys.all, 'edit', { params }],
    delete: (params) => [...keys.all, 'delete', { params }],
    batchUpdate: (params) => [...keys.all, 'batchUpdate', { params }],
  };
  return keys;
};

// Function to ensure that successful responses that return non-ok status get handled as errors in RQ
export const toJSON = (response) => {
  if (response.ok) return response.json();
  throw response;
};

export const toParamString = (filters = []) => {
  if (Array.isArray(filters)) {
    var t;
    const uniq_filters = filters.filter(((t = {}), (a) => !(t[a] = a in t)));
    return new URLSearchParams(uniq_filters).toString();
  }
  return new URLSearchParams(filters).toString();
};

export const ONE_DAY = 86400000;
export const ONE_HOUR = 3600000;
export const FIVE_MINUTES = 300000;
export const THIRTY_SECONDS = 30000;

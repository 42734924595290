/* 
  ! This component is the correct way to use react-hook-form in the app. 
  ! Current interface will only change by adding props if needed
*/
import { useForm, UseFormProps } from 'react-hook-form';
import FormProviderWrapper from './FormProviderWrapper';

/**
 *
 * UseFormProps
 * @param {*} { useFormProps, onSubmit, children, ...formProps }
 * @return {*}
 */
//TODO -need -docs Change all files and comments to give IDE suggestion for consumer
const UseFormWrapper = ({
  useFormProps,
  onSubmit,
  onSelect,
  children,
  useDirtyFormCheck,
  onChange,
  ...formProps
}) => {
  // This should be safe enough but some cases might come up that require update to safety check
  // Updates will not break current instances.
  if (!useFormProps?.defaultValues) return <></>;

  const methods = useForm(useFormProps);
  const { handleSubmit } = { ...methods };
  return (
    <FormProviderWrapper
      methods={methods}
      onChange={onChange}
      onSelect={onSelect}
      onSubmit={handleSubmit(onSubmit)}
      useDirtyFormCheck={useDirtyFormCheck}
      {...formProps}
    >
      {children}
    </FormProviderWrapper>
  );
};
export default UseFormWrapper;

import { useQuery } from 'react-query';
import Sources from './Sources';
import { ONE_HOUR, queryKeyFactory } from '../queryClientConfig';

export const sourcesQueryKeys = queryKeyFactory('sources');

export const useSourcesList = (params = [], options) => {
  const sortList = (data) => {
    return data?.sort((a, b) => a.name.localeCompare(b.name));
  };

  return useQuery(
    sourcesQueryKeys.list([...params]),
    () => {
      const filters = [['page_size', 10000], ...params];
      return Sources.list(filters);
    },
    {
      select: sortList,
      staleTime: ONE_HOUR,
      ...options,
    }
  );
};

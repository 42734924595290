import { Box, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTasksShow } from '../../services/tasks/queries';
import { PhoneIcon, EmailIcon, TextIcon } from '../../theme/icons';
import LeadEventHistory from './LeadEventHistory';
import { useDispatch } from 'react-redux';
import { minimizeCall } from '../../../redux/actions';
import { useSelector } from 'react-redux';
import MinimizedButton from '../../../components/common/MinimizedButton';

const LeadEventHistoryTab = ({ setCommunicationsCount, openActionModal, lead }) => {
  const { taskIdParam } = useParams();
  const { data: task } = useTasksShow(taskIdParam);
  const isTaskOperationDisabled = !!task?.completed_at || !!task?.canceled_at;
  const deviceStatus = useSelector((state) => state.phoneCall.deviceStatus);
  const isMinimized = useSelector((state) => state.phoneCall.isMinimized);
  const dispatch = useDispatch();

  const handleMinimizeCall = () => {
    dispatch(minimizeCall());
  };
  return (
    <Box flex display="flex" flexDirection="column" gap={2} height="100%">
      <LeadEventHistory
        setCommunicationsCount={setCommunicationsCount}
        lead={lead}
      />
      {(task?.task_action.name === 'FollowUp' ||
        task?.task_action.name === 'ReEngage') && (
        <Box display="flex" gap={2} justifyContent="space-evenly" px={2} pb="50px">
          <>
            {!isMinimized ? (
              <Button
                size="large"
                startIcon={<PhoneIcon />}
                sx={{ flex: 1 }}
                disabled={isTaskOperationDisabled}
                onClick={() => openActionModal('call')}
              >
                Call
              </Button>
            ) : (
              <MinimizedButton size="large" onClick={handleMinimizeCall} />
            )}
          </>

          <Button
            size="large"
            startIcon={<EmailIcon />}
            sx={{ flex: 1 }}
            disabled={isTaskOperationDisabled}
            onClick={() => openActionModal('email')}
          >
            Email
          </Button>
          <Button
            size="large"
            startIcon={<TextIcon />}
            sx={{ flex: 1 }}
            disabled={isTaskOperationDisabled}
            onClick={() => openActionModal('text')}
          >
            Text/SMS
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default LeadEventHistoryTab;

import { useQuery } from 'react-query';
import { ONE_HOUR, queryKeyFactory } from '../queryClientConfig';
import Calendars from './Calendars';
export const calendarsQueryKeys = queryKeyFactory('calendars');

export const useCalendarsList = (filters, options) => {
  const mergedFilters = Object.assign({}, ...filters);

  return useQuery(
    calendarsQueryKeys.list(mergedFilters),
    () => Calendars.list(mergedFilters),
    {
      staleTime: ONE_HOUR,
      ...options,
    }
  );
};

export const useCalendarsListById = (filters, options) => {
  return useQuery(calendarsQueryKeys.list(filters), () => Calendars.get(filters), {
    staleTime: ONE_HOUR,
    ...options,
  });
};

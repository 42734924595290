import { Icon, Box } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import { DragIcon } from '../../theme/icons';
import { ListViewItemStyle } from '../ListView';

const DraggableListViewItem = ({ children, draggableId, index, disabled }) => {
  return (
    <Draggable
      key={draggableId}
      draggableId={draggableId}
      index={index}
      isDragDisabled={disabled}
    >
      {(provided) => (
        <Box
          component={ListViewItemStyle}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <Icon
            {...provided.dragHandleProps}
            sx={{
              '& .MuiSvgIcon-root': {
                height: '100%',
                width: '100%',
              },
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              width: 32,
              color: ({ palette }) => palette.grey[600],
            }}
          >
            <DragIcon />
          </Icon>
          {children}
        </Box>
      )}
    </Draggable>
  );
};

export default DraggableListViewItem;

import { useMutation, useQueryClient } from 'react-query';
import { mutationKeyFactory } from '../queryClientConfig';
import { calendarsQueryKeys } from './queries';
import Calendars from './Calendars';

const calendarsMutationKeys = mutationKeyFactory('calendars');

export const useCalendarsDelete = (options) => {
  const queryClient = useQueryClient();
  return useMutation(Calendars.delete, {
    mutationKey: calendarsMutationKeys.delete,
    onSuccess: () => {
      queryClient.invalidateQueries(calendarsQueryKeys.lists());
    },
    ...options,
  });
};

export const useCalendarsCreate = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => Calendars.create({ ...params }), {
    onSuccess: () => {
      queryClient.invalidateQueries(calendarsMutationKeys.all);
    },
  });
};

export const useCalendarsEdit = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => Calendars.edit({ ...params }), {
    onSuccess: () => {
      queryClient.invalidateQueries(calendarsMutationKeys.all);
    },
  });
};

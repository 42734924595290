import { useQuery } from 'react-query';
import { useOrg } from '../../../contexts/OrgProvider';
import { ONE_HOUR, queryKeyFactory } from '../queryClientConfig';
import LostReasons from './LostReasons';

export const lostReasonsQueryKeys = queryKeyFactory('lost-reasons');
export const lostReasonsAllQueryKeys = queryKeyFactory('lost-reasons-all');

export const useLostReasons = (filters) => {
  const sortList = (data) => {
    return data.items.sort((a, b) => (a.name === 'Other' ? -1 : 1));
  };

  const { id } = useOrg();
  const filtersWithOrg = { organization_id: id, ...filters };
  return useQuery(
    lostReasonsQueryKeys.list(filtersWithOrg),
    () => LostReasons.list(filtersWithOrg),
    {
      placeholderData: { items: [] },
      staleTime: ONE_HOUR,
      select: sortList,
    }
  );
};

export const useLostReasonsList = (filters) => {
  const sortList = (data) => {
    return data.items.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
  };

  return useQuery(
    lostReasonsAllQueryKeys.list(filters),
    () => LostReasons.list(filters),
    {
      placeholderData: { items: [] },
      staleTime: ONE_HOUR,
      select: sortList,
    }
  );
};

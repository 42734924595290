import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { useOrg } from '../../../contexts/OrgProvider';
import { useTaskSequencesList } from '../../services/taskSequences/queries';
import { useLeadsEditGroup } from '../../services/leads/mutations';
import HookForm from '../HookForm';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { createYupHash, SEQUENCE } from '../HookForm/yupValidations';
import { LoadingButton } from '@mui/lab';

const LeadsUpdateModal = ({ isOpen, onResolve, onReject, leads }) => {
  const { id: orgId } = useOrg();
  const history = useHistory();

  const {
    data: sequences,
    isLoading: isLoadingSequences,
    isError: didFailLoadingSequences,
  } = useTaskSequencesList([['organization_id[]', orgId]], {});

  const {
    mutate: updateGroupLeads,
    isLoading: isMutatingLeads,
    isSuccess: didSucceedMutatingLeads,
    isError: didFailMutatingLeads,
  } = useLeadsEditGroup();

  useEffect(() => {
    if (didSucceedMutatingLeads && !didFailMutatingLeads) {
      onResolve();
      history.push({ state: 'refreshLeads' });
    }
  }, [didSucceedMutatingLeads, didFailMutatingLeads]);

  const handleSave = (formData) => {
    const _lead = {};
    _lead.task_sequence_id = formData[SEQUENCE]?.id;

    updateGroupLeads({ id: leads, lead: _lead });
  };

  // ANCHOR Form params setup
  let defaultValues = {};
  let yupValidations = {};
  if (!isLoadingSequences && !!sequences) {
    const manualYupHash = {};
    defaultValues = {
      [SEQUENCE]: sequences[0],
    };

    yupValidations = yupResolver(
      yup.object({ ...createYupHash(defaultValues), ...manualYupHash })
    );
  }

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Update Selected Leads</DialogTitle>
      {isLoadingSequences ? (
        <>
          <DialogContent>
            <Box display="flex" justifyContent="center">
              <ClipLoader size={300} color="#34D1B6" />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="cancel" onClick={onReject}>
              Cancel
            </Button>
            <LoadingButton loading={false} type="submit">
              Save
            </LoadingButton>
          </DialogActions>
        </>
      ) : (
        <>
          <HookForm
            onSubmit={handleSave}
            useFormProps={{ defaultValues, resolver: yupValidations }}
            useDirtyFormCheck={false}
          >
            <DialogContent>
              {didFailLoadingSequences && (
                <Alert severity="error">
                  There was a problem while loading. Try again.
                </Alert>
              )}
              {didFailMutatingLeads && (
                <Alert severity="error">
                  There was a problem update your leads. Try again.
                </Alert>
              )}
              <Box
                display="flex"
                flexDirection="column"
                rowGap={2}
                sx={{ pt: 2, px: 0, width: '100%' }}
              >
                <Box display="grid" direction="row" rowGap={2}>
                  <HookForm.Autocomplete
                    options={sequences}
                    name={SEQUENCE}
                    label="Sequence"
                    required={true}
                  />
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="cancel" onClick={onReject}>
                Cancel
              </Button>
              <LoadingButton loading={isMutatingLeads} type="submit">
                Save
              </LoadingButton>
            </DialogActions>
          </HookForm>
        </>
      )}
    </Dialog>
  );
};

export default LeadsUpdateModal;

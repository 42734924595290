// TODO not used yet, just added to get dnd agents table working
import { useState } from 'react';

/**
 * Hook to handle a drag and drop list of flat array of data.
 *
 * @return {*}
 */
export const useOrderLocalData = () => {
  const [localCopy, setLocalCopy] = useState([]);
  const [isOrderDirty, setIsOrderDirty] = useState(false);

  const setCopy = (data) => {
    setIsOrderDirty(false);
    setLocalCopy(JSON.parse(JSON.stringify(data)));
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    setIsOrderDirty(true);
    setLocalCopy((prev) => {
      let newLocalItems = [...prev];
      const moving_item = newLocalItems[result.source.index];
      newLocalItems.splice(result.source.index, 1);
      newLocalItems.splice(result.destination.index, 0, moving_item);
      newLocalItems = newLocalItems.map((item, index) => {
        return { ...item, order: index + 1 };
      });
      return newLocalItems;
    });
  };

  return { localCopy, isOrderDirty, handleDragEnd, setCopy };
};

import { useMutation, useQueryClient } from 'react-query';
import Opportunities from './Opportunities';
import { tasksQueryKeys } from '../../MUI/services/tasks/queries';
import { leadsQueryKeys } from '../../MUI/services/leads/queries';
import { eventsQueryKeys } from '../../MUI/services/events/queries';

export const useOpportunitiesEdit = (options) => {
  const queryClient = useQueryClient();
  return useMutation((opportunity) => Opportunities.edit(opportunity), {
    onSuccess: () => {
      queryClient.invalidateQueries(leadsQueryKeys.all);
      queryClient.invalidateQueries(tasksQueryKeys.all);
      queryClient.invalidateQueries(eventsQueryKeys.all);
    },
    ...options,
  });
};

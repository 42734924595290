import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Alert,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Button } from 'react-bootstrap';

import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { MdKeyboardBackspace } from 'react-icons/md';
import ClipLoader from 'react-spinners/ClipLoader';
import parsePhoneNumber from 'libphonenumber-js';
import { CallTrackingUpsellModal } from '../components/CallTracking/UpsellModal';
import { useSessionsList } from '../services/sessions/queries';
import { useTargetsShow } from '../services/targets/queries';
import { useOrg } from '../../contexts/OrgProvider';
import { dateToAbbreviatedDateTimeString } from '../services/dateTime';
import { useOrganizationServices } from '../services/organizations/queries';

export default function CallTrackingVisits() {
  const tableColumns = ['Number', 'Last Active', 'Device', 'Browser'];

  const history = useHistory();
  const { callTrackingId } = useParams();
  const [mergedData, setMerged] = useState([]);

  const { id: orgId } = useOrg();
  const [isCallTrackingEnabled, setIsCallTrackingEnabled] = useState(true);
  const { data: organizationServicesList } = useOrganizationServices(orgId);
  const { data: callTracking } = useTargetsShow(callTrackingId);
  const {
    isFetching: isFetching,
    isIvrError: isIvrError,
    data: sessionsList,
  } = useSessionsList(
    callTracking?.swap_phone_ids.map((id) => ['phones_for_last_sessions[]', id])
  );

  const formatPhone = (phone) => {
    if (!phone) {
      return;
    }

    const phoneNumber = parsePhoneNumber(phone);
    if (phoneNumber) {
      return phoneNumber.formatNational();
    }
    return phone;
  };

  useEffect(() => {
    if (organizationServicesList) {
      const isEnabled = organizationServicesList.some(
        (service) => service.service_id == 3
      );
      setIsCallTrackingEnabled(isEnabled);
    }
  }, [organizationServicesList]);

  return (
    <>
      <SettingsCard
        sx={{
          mb: 2,
          padding: '10px !important',
          width: 'calc(77% + 20rem)',
          maxWidth: '100%',
        }}
      >
        <Button
          variant="link"
          size="sm"
          onClick={() => {
            history.push('/settings/call-tracking');
          }}
        >
          <MdKeyboardBackspace
            style={{
              fontSize: '1.5rem',
              margin: '0px 5px 0px 0px',
            }}
          />
          Back to report
        </Button>
      </SettingsCard>
      <SettingsCard>
        <CallTrackingUpsellModal callTrackingEnabled={isCallTrackingEnabled} />

        <SettingsCard.Header>Call Tracking Visits</SettingsCard.Header>
        <SettingsCard.Main>
          {isIvrError ? (
            <Alert severity="error" sx={{ mt: 1, mb: 2 }}>
              There was an error loading your call tracking numbers. Please refresh
              the page.
            </Alert>
          ) : (
            <>
              {isFetching ? (
                <Box display="flex" justifyContent="center">
                  <ClipLoader size={100} color="#34D1B6" />
                </Box>
              ) : Boolean(sessionsList?.length) ? (
                <TableContainer sx={{ mt: 1 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        {tableColumns.map((col, index) => (
                          <TableCell key={index}>{col}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Boolean(callTracking?.swap_phone_ids.length) &&
                        sessionsList?.map((phoneSession) => {
                          const phoneId = Object.keys(phoneSession)[0];
                          const session = Object.values(phoneSession)[0];
                          const phoneNumber = session.phones.find(
                            (sessionPhone) => sessionPhone.id === phoneId
                          )?.number;

                          return (
                            <TableRow
                              key={phoneId}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {formatPhone(phoneNumber)}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {dateToAbbreviatedDateTimeString(session.created_at)}
                              </TableCell>
                              <TableCell>{session.device}</TableCell>
                              <TableCell component="th" scope="row">
                                {session.browser}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Box>Could not find sessions with these phones.</Box>
              )}
            </>
          )}
        </SettingsCard.Main>
      </SettingsCard>
    </>
  );
}

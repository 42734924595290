import Cookies from 'js-cookie';
import { toJSON } from '../../../api/utils/utils';

export default class NotificationEmailAddresses {
  static async list(filters) {
    const params = new URLSearchParams(filters).toString();
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/notification_email_addresses?${params}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async edit(email) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/notification_email_addresses/${email.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(email),
      }
    ).then(toJSON);
  }

  static async create(email) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/notification_email_addresses`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(email),
      }
    ).then(toJSON);
  }

  static async delete(id) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/notification_email_addresses/${id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    return response;
  }
}

import React, { useState, useEffect, useRef } from 'react';
import { Modal, Form } from 'react-bootstrap';
import Text from '../common/Text/Text';
import TemplateTextEditor from '../templates/TemplateTextEditor';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { MailMergeDropdown } from './MailMergeDropdown';
import TemplateModalFooter from './TemplateModalFooter';

const EmailTemplateModal = ({
  showEmailTemplatesModal,
  setShowEmailTemplatesModal,
  templates,
  action,
  editTemplate,
  createTemplate,
  isNewTemplate,
  setIsNewTemplate,
}) => {
  const [selectedTemplateId, setSelectedId] = useState();
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const subjectRef = useRef(null);
  const [isCreateTemplate, setIsCreateTemplate] = useState(false);
  const [token, setToken] = useState();
  const [editorHasFocus, setEditorHasFocus] = useState(false);
  const [subjectHasFocus, setSubjectHasFocus] = useState(false);
  const [editorHasValidText, setEditorHasValidText] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isSaveAsNew, setIsSaveAsNew] = useState(false);
  const [saveAsNewName, setSaveAsNewName] = useState('');

  useEffect(() => {
    if (selectedTemplateId === 'new') {
      setName('');
      setSubject('');
      setEditorState(EditorState.createEmpty());
      setIsCreateTemplate(true);
    } else {
      setIsCreateTemplate(false);
      const selectedTemplate = templates.find(({ id }) => id === selectedTemplateId);
      if (selectedTemplate) {
        setName(selectedTemplate.name);
        setSubject(selectedTemplate.subject);
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(htmlToDraft(selectedTemplate.content))
          )
        );
      }
    }
  }, [selectedTemplateId]);

  useEffect(() => {
    if (isNewTemplate) setSelectedId('new');
    else setSelectedId(action.message_template_id);
  }, [isNewTemplate]);

  const isButtonDisabled = () => {
    return name?.trim() === '';
  };

  const closeModal = () => {
    setShowEmailTemplatesModal(false);
    setIsNewTemplate(false);
  };

  const onFocusHandler = (e) => {
    setEditorHasFocus(false);
    setSubjectHasFocus(false);
    setToken();
    if (e.target.id === 'subject') {
      setSubjectHasFocus(true);
    } else {
      setEditorHasFocus(true);
    }
  };

  const contentToHTML = () => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  const selectMailMergeFieldHandler = (e) => {
    setToken(e);
    if (subjectHasFocus)
      setSubject((prev) => {
        return `${prev.slice(0, subjectRef.current.selectionStart)}${e} ${prev.slice(
          subjectRef.current.selectionStart
        )}`;
      });
    subjectRef.current.blur();
  };

  const saveAsNewTemplate = () => {
    if (subject.trim() === '') {
      if (
        confirm(
          'You do not have a subject for this email template. Continue anyway?'
        )
      )
        createTemplate({
          name: saveAsNewName,
          content: contentToHTML(),
          subject,
        });
    } else {
      createTemplate({
        name: saveAsNewName,
        content: contentToHTML(),
        subject,
      });
    }
  };

  const saveTemplateChanges = () => {
    if (subject.trim() === '') {
      if (
        confirm(
          'You do not have a subject for this email template. Continue anyway?'
        )
      )
        editTemplate({
          name,
          content: contentToHTML(),
          subject,
          selectedTemplateId,
        });
    } else {
      editTemplate({
        name,
        content: contentToHTML(),
        subject,
        selectedTemplateId,
      });
    }
  };

  const createNewTemplate = () => {
    if (subject.trim() === '') {
      if (
        confirm(
          'You do not have a subject for this email template. Continue anyway?'
        )
      )
        createTemplate({ name, content: contentToHTML(), subject });
    } else {
      createTemplate({ name, content: contentToHTML(), subject });
    }
  };

  const renderSaveAsNewForm = () => {
    return (
      <>
        <Form.Label>
          <Text weight="bold">New Template Name</Text>
        </Form.Label>
        <Form.Control
          id="new-template-name"
          type="text"
          maxLength={127}
          placeholder={'Template Name'}
          value={saveAsNewName}
          onChange={(e) => setSaveAsNewName(e.target.value)}
        />
      </>
    );
  };

  return (
    <Modal
      show={showEmailTemplatesModal}
      onHide={closeModal}
      className="modal-sigma"
      backdrop="static"
      dialogClassName="template-modal"
    >
      <Modal.Header>
        <Modal.Title className="w-100 text-center">Edit Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isSaveAsNew ? (
          renderSaveAsNewForm()
        ) : (
          <>
            <Form.Label>
              <Text weight="bold">Template</Text>
            </Form.Label>
            <Form.Control
              id="template-select"
              as="select"
              onChange={(e) => setSelectedId(e.target.value)}
              value={selectedTemplateId}
            >
              <option key="new" value="new">
                Create New Template
              </option>
              {templates.map((t) => (
                <option key={t.id} value={t.id}>
                  {t.name}
                </option>
              ))}
            </Form.Control>
            {isCreateTemplate && (
              <>
                <Form.Label>
                  <Text weight="bold">Template Name</Text>
                </Form.Label>
                <Form.Control
                  id="subject"
                  type="text"
                  maxLength={127}
                  placeholder={'Template Name'}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </>
            )}
            <Form.Label>
              <Text weight="bold">Subject</Text>
            </Form.Label>
            <Form.Control
              id="subject"
              type="text"
              maxLength={127}
              placeholder={'Template Subject'}
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
              onFocus={onFocusHandler}
              onBlur={(e) => {
                if (e.relatedTarget?.id !== 'token-button')
                  setSubjectHasFocus(false);
              }}
              ref={subjectRef}
            />
            <Form.Label>
              <Text weight="bold">Content</Text>
            </Form.Label>
            <TemplateTextEditor
              editorState={editorState}
              setEditorState={setEditorState}
              token={token}
              setToken={setToken}
              onFocusHandler={onFocusHandler}
              editorHasFocus={editorHasFocus}
              setEditorHasFocus={setEditorHasFocus}
              editorHasValidText={editorHasValidText}
              setEditorHasValidText={setEditorHasValidText}
              mailMergeDropdown={
                <MailMergeDropdown
                  toggleClassName="btn-light p-1"
                  onSelect={selectMailMergeFieldHandler}
                  disabled={!subjectHasFocus && !editorHasFocus}
                />
              }
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <TemplateModalFooter
          isSaveAsNew={isSaveAsNew}
          setIsSaveAsNew={setIsSaveAsNew}
          isCreateTemplate={isCreateTemplate}
          isButtonDisabled={isButtonDisabled}
          saveAsNewName={saveAsNewName}
          setSaveAsNewName={setSaveAsNewName}
          closeModal={closeModal}
          saveAsNewTemplateHelper={saveAsNewTemplate}
          saveTemplateChangesHelper={saveTemplateChanges}
          createNewTemplateHelper={createNewTemplate}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default EmailTemplateModal;

import { useQuery } from 'react-query';
import TaskSequences from './TaskSequences';
import { ONE_HOUR, queryKeyFactory } from '../queryClientConfig';
import { useOrg } from '../../../contexts/OrgProvider';

export const taskSequencesQueryKeys = queryKeyFactory('task-sequences');

export const useTaskSequencesList = (params = [], options) => {
  const { id, isMasterAccount } = useOrg();

  const transformToItems = (data) => {
    return data.items;
  };

  return useQuery(
    taskSequencesQueryKeys.list(params),
    () => {
      const filters = [['page_size', 10000], ...params];
      filters.push(['organization_id[]', id]);
      return TaskSequences.list(filters);
    },
    {
      select: transformToItems,
      staleTime: ONE_HOUR,
      ...options,
    }
  );
};

export const useTaskSequenceShow = (sequenceId) => {
  return useQuery(
    taskSequencesQueryKeys.show({ sequenceId }),
    () => TaskSequences.show(sequenceId),
    {
      staleTime: ONE_HOUR,
    }
  );
};

import { useState, useEffect } from 'react';
import {
  FormControlLabel,
  TableContainer,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Checkbox,
  Box,
  Grid,
  Link,
  Card,
  Chip,
  Typography,
  Alert,
  CircularProgress,
} from '@mui/material';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { useMailboxesList } from '../services/mailboxes/queries';
import { LoadingButton } from '@mui/lab';
import Nylas from '../services/nylas/queries';
import { useCalendarsList } from '../services/calendars/queries';
import Calendars from '../services/calendars/Calendars';
import { useOrg } from '../../contexts/OrgProvider';

export default function NylasMailboxlist() {
  const [mailboxidList, setMailBoxIdList] = useState([]);
  const [isSubscribeDialogOpen, setIsSubscribeDialogOpen] = useState(false);
  const [selectedMailboxID, setSelectedMailboxID] = useState([]);
  const [isdeactivesubscribeOpen, setDeactiveSubscribeState] = useState(false);
  const [isActiveCalenderids, setActiveCalendarid] = useState();
  const [isAccountID, setAccountID] = useState();
  const [isNylasActive, setNylasActive] = useState();
  const [validNylasID, setValidateNylasID] = useState();
  const [isChangeCircularProgress, setisChangeCircularProgress] = useState(false);
  const [isSelected, setIsSelected] = useState();
  const [selectedMailboxEmail, setMailboxEmail] = useState();
  const [selectedCalendarName, setCalendarName] = useState();
  const [isMailboxEmpty, setMailboxEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { id: orgId } = useOrg();

  const { data: calendardata } = useCalendarsList([
    { config_item_name: 'nylas_calendar_id', organization_id: orgId },
  ]);

  const { data: ownedMailboxes } = useMailboxesList({ owner_org_id: orgId });

  const handleSubscribe = () => {
    setIsSubscribeDialogOpen(true);
  };

  useEffect(() => {
    if (calendardata && Array.isArray(calendardata.items)) {
      const ids = calendardata.items.map((item) => item.id);
      if (ids.length > 0) {
        setActiveCalendarid(ids);
        setNylasActive(ids.length > 0);
        setIsLoading(false);
      }

      if (calendardata.items.length > 0) {
        setCalendarName(calendardata.items[0].name);
      }
    }
  }, [calendardata]);

  useEffect(() => {
    if (calendardata && Array.isArray(calendardata.items)) {
      const value = calendardata.items.map((item) => item);

      setValidateNylasID(value[0]?.config_items[0].value);
      if (ownedMailboxes && Array.isArray(ownedMailboxes)) {
        if (ownedMailboxes.length > 0) {
          setMailboxEmpty(false);
        } else {
          setMailboxEmpty(true);
        }
        const filteredMailboxes = ownedMailboxes.filter(
          (item) => item.nylas_account_id === validNylasID
        );
        if (filteredMailboxes.length > 0) {
          setAccountID(filteredMailboxes[0].nylas_account_id);
          setSelectedMailboxID(filteredMailboxes[0].id);
          setMailboxEmail(filteredMailboxes[0].email_address);
          setIsLoading(false);
        }
        setisChangeCircularProgress(false);
        setIsLoading(false);
      }
    }
  }, [calendardata, ownedMailboxes]);

  useEffect(() => {
    if (ownedMailboxes && Array.isArray(ownedMailboxes)) {
      const namesAndValues = ownedMailboxes.map((item) => ({
        id: item.id,
        email: item.email_address,
        nylas_account_id: item.nylas_account_id,
      }));
      setMailBoxIdList(namesAndValues);
    }
  }, [ownedMailboxes]);

  const closeDialog = () => {
    if (isSubscribeDialogOpen) {
      if (isSelected) {
        setisChangeCircularProgress(true);

        const responses = Nylas.list(selectedMailboxID).then((responses) => {
          if (responses.length > 0) {
            const firstAccountId = responses[0].account_id;
            event = {
              name: responses[0].description,
              organization_id: orgId,
              config_items: [
                {
                  name: 'nylas_calendar_id',
                  value: firstAccountId,
                },
              ],
            };
            const response = Calendars.create(event).then((response) => {
              if (response) {
                setIsLoading(false);
                setActiveCalendarid(response.config_items[0].calendar_id);
                setCalendarName(response.name);
              }
            });
            setAccountID(firstAccountId);
            setNylasActive(true);
            setisChangeCircularProgress(false);
            setIsLoading(false);
          }
        });
      }
    }

    setIsSubscribeDialogOpen(false);
  };

  const closeDeactivateDialog = () => {
    setDeactiveSubscribeState(false);
  };

  const callsubscribe = () => {
    setIsSubscribeDialogOpen(false);
  };

  const handleCheckboxChange = (e) => {
    setSelectedMailboxID(e.target.name);
    setMailboxEmail(e.target.value);
    if (e.target.checked) {
      !isSelected && setIsSelected(e.target.name);
    } else {
      setIsSelected(null);
    }
  };

  const handleDeactivesubsribe = () => {
    setDeactiveSubscribeState(true);
  };

  const handleActivecancel = () => {
    closeDeactivateDialog();
    const response = Calendars.delete(isActiveCalenderids).then((response) => {
      if (response.status === 204) {
        setNylasActive(false);
      } else {
        setNylasActive(true);
      }
    });
  };

  const closeSubscribeDialog = () => {
    closeDialog();
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <SettingsCard
        sx={{
          width: '100%',
        }}
      >
        <Grid container>
          <SettingsCard.Header>Nylas Integrations</SettingsCard.Header>
          <Grid item xs={3} m={0.5}>
            {isNylasActive ? (
              <Chip
                label="Active"
                color="primary"
                style={{
                  backgroundColor: '#009900',
                  borderRadius: '4px',
                  mt: '2',
                  border: '0px solid green',
                }}
              />
            ) : (
              <Chip
                label="Inactive"
                color="primary"
                style={{
                  backgroundColor: '#dce0dd',
                  borderRadius: '4px',
                  mt: '2',
                  border: '0px solid #dce0dd',
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" sx={{ marginTop: 2 }}>
          <Grid item xs={8}>
            <>
              {isChangeCircularProgress ? (
                <CircularProgress color="inherit" size={20} />
              ) : null}
            </>
            {isLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <>
                {isMailboxEmpty ? (
                  <Alert severity="warning" sx={{ mb: 2 }}>
                    No mailboxes found on this account.
                    <Link href="settings/connected-mailboxes">
                      Go to Settings `{'>'}`Email
                    </Link>{' '}
                    to connect a mailbox before enabling Nylas.
                  </Alert>
                ) : null}

                {!isNylasActive && !isChangeCircularProgress ? (
                  <Alert severity="info" sx={{ mb: 2 }}>
                    Activate our Nylas integration now, and watch your leads
                    transform into wins effortlessly. <br />
                    <br /> With Nylas, new appointments scheduled using your Google
                    or Microsoft 365 calendar automatically mark leads as won. Plus,
                    empower our AI digital assistant to schedule appointments
                    seamlessly on your calendar. <br />
                    <br /> Don't miss out on the efficiency boost – enable Nylas
                    integration today and take control of your scheduling game!
                  </Alert>
                ) : null}
              </>
            )}

            {isNylasActive && !isChangeCircularProgress ? (
              <>
                <Typography variant="subtitle1" sx={{ color: 'black' }}>
                  Mailbox
                </Typography>

                {!selectedMailboxEmail && !isChangeCircularProgress ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <Typography variant="body2">{selectedMailboxEmail}</Typography>
                )}

                <Typography variant="subtitle1" sx={{ color: 'black' }}>
                  Calendar Name
                </Typography>
                {!selectedCalendarName && !isChangeCircularProgress ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <Typography variant="body2">{selectedCalendarName}</Typography>
                )}

                <Typography variant="subtitle1" sx={{ color: 'black' }}>
                  AccountID
                </Typography>

                {!isAccountID && !isChangeCircularProgress ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <Typography variant="body2">{isAccountID}</Typography>
                )}
              </>
            ) : null}
          </Grid>
          <Grid item>
            <Card
              sx={{
                minWidth: 250,
                minHeight: 100,
                display: 'flex',
                ml: 4,
              }}
              style={{ backgroundColor: '#f2f1ed' }}
              elevation={0}
            >
              <Grid item ml={2} mt={2} paddingBottom={2}>
                <Typography variant="body1" sx={{ width: 200, minWidth: '100%' }}>
                  Question about this integration? Check out here
                </Typography>
                <Link
                  target="blank"
                  href="https://sigmaleads.atlassian.net/wiki/spaces/LCS/pages/1377009666/NexHealth+Integration"
                >
                  Support Documentation
                </Link>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        {isNylasActive ? (
          <Grid item mt={2}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Card
                sx={{
                  minWidth: 250,
                  minHeight: 100,
                  display: 'flex',
                }}
                style={{ backgroundColor: '#f2f1ed' }}
                elevation={0}
              >
                <Grid item mt={2} paddingBottom={2}>
                  <Typography
                    ml={2}
                    variant="body1"
                    sx={{ width: 200, minWidth: '100%' }}
                  >
                    Deactivating this integration will clear your current settings
                    and disconnect Nylas.
                  </Typography>

                  <Button
                    color="inherit"
                    sx={{
                      color: '#f55e53',
                      border: '2px solid #f55e53',
                      backgroundColor: '#f2f1ed',
                      boxShadow: 'none',
                      ml: 2,
                      mt: 2,
                    }}
                    onClick={handleDeactivesubsribe}
                  >
                    Deactivate Nylas
                  </Button>
                </Grid>
              </Card>
            </Box>
          </Grid>
        ) : null}

        {isMailboxEmpty ? null : isLoading ? null : !isNylasActive ? (
          <Button color="inherit" onClick={handleSubscribe}>
            Subscribe
          </Button>
        ) : null}
        <TableContainer sx={{ mb: 2 }}></TableContainer>
      </SettingsCard>
      <Dialog open={isSubscribeDialogOpen} onClose={closeDialog}>
        <DialogTitle>Mailbox</DialogTitle>
        {mailboxidList.map((item, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                value={item.email}
                name={item.id}
                key={item.id}
                onChange={handleCheckboxChange}
                mailboxid={item.id}
                disabled={isSelected ? isSelected !== item.id : false}
              />
            }
            label={item.email}
          />
        ))}
        <DialogActions>
          <Button variant="cancel" onClick={closeSubscribeDialog}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            onClick={() => {
              callsubscribe();
              closeDialog();
            }}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog open={isdeactivesubscribeOpen} onClose={closeDeactivateDialog}>
        <DialogTitle>Deactivate Nylas</DialogTitle>
        <DialogContent>
          Are you sure you want to deactivate the integration?
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={closeDeactivateDialog}>
            Cancel
          </Button>
          <LoadingButton onClick={handleActivecancel}>Confirm</LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

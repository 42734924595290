import { createContext, useState, useEffect } from 'react';
// React Query
import { useUsersCurrent } from '../MUI/services/users/queries';
import { useTwilioTokenWithInterval } from '../api/twilio/twilioMutations';
// old clients
import MessageTemplate from '../clients/MessageTemplate.js';

/*
  Create and export AppContext
*/
const AppContext = createContext();
export default AppContext;
/*
  Provide higher order component for AppContext
*/
export const AppContextProvider = ({ children }) => {
  // React Query
  const {
    twilioToken,
    createTwilioToken,
    isLoadingTwilioToken,
  } = useTwilioTokenWithInterval();
  // Context Values (all with same initial states)
  const [user, setUser] = useState({});
  const [leads, setLeads] = useState([]);
  const [totalLeadCount, setTotalLeadCount] = useState(0);
  const [leadsTotalPages, setLeadsTotalPages] = useState(0);
  const [leadsCurrentPageSize, setLeadsCurrentPageSize] = useState(10);
  const [leadsCurrentPage, setLeadsCurrentPage] = useState(1);
  const [isLoadingTasks, setIsLoadingTasks] = useState(true);
  const [isLoadingLeads, setIsLoadingLeads] = useState(true);
  const [getLeadDetailLoading, setGetLeadDetailLoading] = useState(false);
  const [tasksCurrentPageSize, setTasksCurrentPageSize] = useState(10);
  const [tasksCurrentPage, setTasksCurrentPage] = useState(1);
  const [preload, setPreload] = useState(false);
  // TODO temporary solution for passing mobile side nav state until more

  //Not a permanent solution! Only here to prevent having to update
  //all components that are consuming user from AppContext. Any new user
  //consumer should use the useUser hook instead of AppContext.
  const { data: userTemp } = useUsersCurrent();

  useEffect(() => {
    // see comment above
    setUser(userTemp);
  }, []);

  const value = {
    isLoadingTasks,
    setIsLoadingTasks,
    isLoadingLeads,
    setIsLoadingLeads,
    leads,
    setLeads,
    twilioToken,
    createTwilioToken,
    isLoadingTwilioToken,
    user,
    preload,
    setPreload,
    totalLeadCount,
    leadsTotalPages,
    leadsCurrentPageSize,
    setLeadsCurrentPageSize,
    leadsCurrentPage,
    setLeadsCurrentPage,
    setLeadsTotalPages,
    setTotalLeadCount,
    setTasksCurrentPage,
    setTasksCurrentPageSize,
    tasksCurrentPage,
    tasksCurrentPageSize,
    setGetLeadDetailLoading,
    getLeadDetailLoading,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

import { Box, Typography } from '@mui/material';

export default function CommonItem({
  name,
  mainTitle,
  itemDetails,
  handleCollect,
  handleForwardNavigate,
}) {
  return (
    <Box mt="50px">
      <Typography variant="h5" style={{ textAlign: 'center' }}>
        {mainTitle}
      </Typography>
      <Box
        style={{
          margin: '40px 0',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {itemDetails.map((item) => {
          return (
            <Box
              onClick={() => {
                handleForwardNavigate(item.nextStep, name);
                handleCollect(name, item.value);
              }}
              key={item.title}
              style={{
                padding: '40px',
                width: 'calc(50% - 8px)',
                textAlign: 'center',
                border: '1px solid gray',
                borderRadius: '3px',
                cursor: 'pointer',
                backgroundColor: '#f2f2f2',
              }}
            >
              <Typography variant="h6">{item.title}</Typography>
              <p style={{ color: 'gray' }}>{item.subTitle}</p>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

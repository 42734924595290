import Cookies from 'js-cookie';
import { toJSON, toParamString } from '../queryClientConfig';

export default class Downloads {
  static async create(csv_export) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/csv_exports`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(csv_export),
    }).then(toJSON);
  }

  static async list(filters) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/csv_exports?${toParamString(filters)}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async getDownloadLink(exportID) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/csv_exports/${String(exportID)}/download`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }
}

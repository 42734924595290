import Cookies from 'js-cookie';
import { toJSON, toParamString } from '../../MUI/services/queryClientConfig';

export default class AIProfile {
  static async list(params) {
    return await fetch(
      `${process.env.REACT_APP_IVR}/ai_profiles?${toParamString(params)}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async show(agentID) {
    return await fetch(`${process.env.REACT_APP_IVR}/ai_profiles/${agentID}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
      },
    }).then(toJSON);
  }

  static async edit(params) {
    return await fetch(`${process.env.REACT_APP_IVR}/ai_profiles/${params.id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    }).then(toJSON);
  }

  static async delete(params) {
    return await fetch(`${process.env.REACT_APP_IVR}/ai_profiles/${params.id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
      },
    });
  }

  static async create(params) {
    return await fetch(
      `${process.env.REACT_APP_IVR}/ai_profiles?${toParamString(params)}`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      }
    ).then(toJSON);
  }
}

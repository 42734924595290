import { useMutation, useQueryClient } from 'react-query';
import { useOrg } from '../../contexts/OrgProvider';
import ForwardingPhones from './ForwardingPhones';

export const useForwardingPhonesEdit = (options) => {
  const queryClient = useQueryClient();
  return useMutation(ForwardingPhones.edit, {
    onSuccess: (data, variables) => {
      const phones = queryClient.getQueryData(['forwarding_phones']);
      if (phones) {
        const phoneIndex = teams.findIndex((x) => x.id == variables.id);
        phones[phoneIndex] = data;
        queryClient.setQueryData(['forwarding_phones'], phones);
      }
      queryClient.setQueryData(['forwarding_phones', data.team_id], [data]);
    },
    ...options,
  });
};

export const useForwardingPhonesCreate = (options) => {
  const queryClient = useQueryClient();
  const { id } = useOrg();
  return useMutation(
    (params) =>
      ForwardingPhones.create({ ...params, organization_id: String(id), order: 1 }),
    {
      onSuccess: (data) => {
        const phones = queryClient.getQueryData(['forwarding_phones']);
        if (phones) {
          phones.push(data);
          queryClient.setQueryData(['forwarding_phones'], phones);
        }
        queryClient.setQueryData(['forwarding_phones', data.team_id], [data]);
      },
      ...options,
    }
  );
};

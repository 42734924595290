import React, { forwardRef, Fragment, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import 'animate.css';
import { Button, Card, Col, Dropdown, Row, Modal } from 'react-bootstrap';
import SyncLoader from 'react-spinners/SyncLoader';
import AppContext from '../../contexts/AppContext';
import EmailCreate from '../email/EmailCreate';
import PhoneCall from '../phone/PhoneCall';
import TextMessage from './TextMessage';
import NoteDetail from '../notes/NoteDetail';
import NoteCreate from '../notes/NoteCreate';
import EditDetails from '../editDetails/EditDetails';
import { BiLock, BiChevronLeft, BiDotsVerticalRounded } from 'react-icons/bi';
import { FiPhone, FiMessageSquare } from 'react-icons/fi';
import { BsEnvelope } from 'react-icons/bs';
import { FaTrash, FaCheck } from 'react-icons/fa';
import OverrideLockModal from '../leads/OverrideLockModal';
import { getLockStatus, getTaskActionNameString, LOCK_BY_USER } from './TaskUtils';
import UserSelfUnlockRow from '../leads/UserSelfUnlockRow';
import { Button as NewButton } from '@mui/material';
import { FiMaximize2 } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import {
  getInboundCommunicationCounts,
  renderInboundCommunicationDisplay,
} from '../../util/LeadUtils';
import { renderAutomatedStatus } from '../tasks/TaskUtils';
import { useHistory } from 'react-router';
import { getLeadName } from '../../util/AppUtils';
import Task from '../../clients/Task';
import { colorValues } from '../../util/Constants';
import { useUsersCurrent } from '../../MUI/services/users/queries';
import { useLeadLocksCreate } from '../../MUI/services/leadLocks/mutations';
import { useLeadLocksList } from '../../MUI/services/leadLocks/queries';
import { fireDialog } from '../../MUI/components/Dialog';
import { useTasksEdit } from '../../MUI/services/tasks/mutations';
import { useTasksShow } from '../../MUI/services/tasks/queries';
import { resetMinimizeCall, minimizeCall } from '../../redux/actions';
import { useSelector } from 'react-redux';
import MinimizedButton from '../common/MinimizedButton';
import {
  Button as MuiButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useEventsCreate } from '../../MUI/services/events/mutations';
import { useOrg } from '../../contexts/OrgProvider';
import LeadHistoryTabs from '../../MUI/components/leadEventHistory/LeadHistoryTabs';
import { useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

const TaskDetail = ({ task, lead }) => {
  const history = useHistory();
  const [showText, setShowText] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showOverride, setShowOverride] = useState(false);
  const [isLockedToUser, setIsLockedToUser] = useState(false);
  const deviceStatus = useSelector((state) => state.phoneCall.deviceStatus);
  const isMinimized = useSelector((state) => state.phoneCall.isMinimized);
  const [
    hasUnansweredInboundCommunication,
    setHasUnansweredInboundCommunication,
  ] = useState(true);
  const [taskOptionModalType, setTaskOptionModalType] = useState(false);
  const { data: currentUser } = useUsersCurrent();
  const { mutate: createLeadLock } = useLeadLocksCreate();
  const { data: locks, isFetching: isLoadingLocks } = useLeadLocksList();

  const { tasksCurrentPageSize, tasksCurrentPage, taskSequences } = useContext(
    AppContext
  );

  const isTaskOperationDisabled = !!task?.completed_at || !!task?.canceled_at;

  useEffect(() => {
    dispatch(resetMinimizeCall());
  }, []);

  useEffect(() => {
    if (!lead) return;
    createLeadLock(
      {
        lead_id: lead.id,
        user_id: currentUser.id,
        organization_id: lead.organization_id,
      },
      {
        onSuccess: () => {
          setTimeout(function () {
            queryClient.invalidateQueries(['lead_locks']);
          }, 1000);
          if (getLockStatus(lead, locks, currentUser) != 'LOCK_BY_OTHER') {
            setIsLockedToUser(true);
          }
        },
      }
    );

    setHasUnansweredInboundCommunication(
      getInboundCommunicationCounts(lead).totalCount > 0
    );
  }, [lead]);

  useEffect(() => {
    if (!lead || !locks) {
      return;
    }
    setIsLockedToUser(getLockStatus(lead, locks, currentUser) === LOCK_BY_USER);
  }, [lead, locks]);

  const handleShow = () => {
    switch (task.task_action.name) {
      case 'Email':
        setShowEmail(true);
        return;
      case 'Text':
        setShowText(true);
        return;
      case 'Call':
        setShowPhone(true);
        return;
    }
  };

  const handleShowNote = () => setShowNote(true);
  const handleCloseText = () => setShowText(false);
  const handleCloseEmail = () => setShowEmail(false);
  const handleClosePhone = () => setShowPhone(false);
  const handleCloseNote = () => setShowNote(false);
  const dispatch = useDispatch();
  const actionIcon = (action) => {
    switch (action) {
      case 'Email':
        return <BsEnvelope size="1.5em" className={'mr-2'} />;
      case 'Call':
        return <FiPhone size="1.5em" className={'mr-2'} />;
      case 'Text':
        return <FiMessageSquare size="1.5em" className={'mr-2'} />;
      default:
        return;
    }
  };

  const enableEditing = () => {
    setIsEditing(!isEditing);
  };

  const hasLock = (l) => {
    if (locks)
      return (
        locks.find(
          (lock) => lock.lead_id == l.id && lock.user_id != currentUser.id
        ) != undefined
      );
  };

  const unlock = () => {
    setShowOverride(true);
  };

  const handleCloseOverride = () => {
    setShowOverride(false);
  };

  const openActionModal = (action) => {
    if (action === 'call') setShowPhone(true);
    if (action === 'email') setShowEmail(true);
    if (action === 'text') setShowText(true);
  };

  const queryClient = useQueryClient();

  const completeTask = () => {
    Task.edit({
      id: task.id,
      user_id: currentUser.id,
      completed_at: new Date().toISOString(),
    }).then((updatedTask) => {
      history.push({ pathname: '/tasks' });
      queryClient.invalidateQueries(['tasks', 'due-today']);
      queryClient.invalidateQueries(['tasks', 'completed']);
    });
  };

  const cancelTask = () => {
    Task.edit({
      id: task.id,
      user_id: currentUser.id,
      canceled_at: new Date().toISOString(),
    }).then((updatedTask) => {
      history.push({ pathname: '/tasks' });
      queryClient.invalidateQueries(['tasks', 'due-today']);
      queryClient.invalidateQueries(['tasks', 'completed']);
    });
  };

  const handleTaskOption = (option) => {
    if (option === 'cancel') cancelTask();
    else completeTask();
  };

  const handleFollowUpCompleteClick = (task) => {
    if (hasUnansweredInboundCommunication) {
      fireDialog((promiseProps) =>
        UnansweredFollowUpDialog({ ...promiseProps, task })
      ).then(() => completeTask());
    } else {
      completeTask();
    }
  };

  if (!task || !lead) {
    return (
      <Grid container direction="column">
        <Grid item align="center">
          <ClipLoader size={100} color="#34D1B6" />
        </Grid>
      </Grid>
    );
  }

  const handleMinimizeCall = () => {
    dispatch(minimizeCall());
  };

  return (
    <Card className="position-relative task-detail-card h-100" key="task-card">
      <DeleteTaskModal
        taskOptionModalType={taskOptionModalType}
        setTaskOptionModalType={setTaskOptionModalType}
        handleTaskOption={handleTaskOption}
      />
      <Button
        variant="link"
        className="tasks-back"
        onClick={() => {
          history.push('/tasks');
        }}
      >
        <BiChevronLeft size={'1.2em'} /> Tasks
      </Button>
      <OverrideLockModal
        showOverride={showOverride}
        handleCloseOverride={handleCloseOverride}
        lead={lead}
        user={currentUser}
      />
      <Row
        noGutters
        className="h-100 lead-task-show-container"
        key="task-show-container"
      >
        <Row
          noGutters
          className="lead-task-show-split-sections"
          key="task-show-first"
        >
          <Col xs={12} xl={6} className="lead-task-detail-section" key="task-detail">
            <Row noGutters className="w-100" key="task-title-row">
              <Col xs={12} sm={6} key="task-title">
                <h5 className="m-0 text-sigma-secondary">
                  {getTaskActionNameString(task.task_action.name)}
                </h5>
                <h3 className="text-sigma-secondary">
                  {task && task.lead && getLeadName(task.lead)}
                </h3>
              </Col>
              {!task.completed_at && lead && (
                <Col
                  xs={12}
                  sm={6}
                  key="task-lock"
                  className="d-flex justify-content-end align-items-center"
                >
                  {hasLock(task.lead) ? (
                    <Button
                      type={'button'}
                      className={'btn btn-sigma-primary'}
                      onClick={unlock}
                    >
                      {isLoadingLocks ? (
                        <SyncLoader color={'white'} size={'10px'} />
                      ) : (
                        <>
                          <BiLock className="mb-1 mr-1" />
                          Override Lock
                        </>
                      )}
                    </Button>
                  ) : (
                    <>
                      {task.task_action.name === 'FollowUp' ||
                      task.task_action.name === 'ReEngage' ? (
                        <Button
                          disabled={isTaskOperationDisabled}
                          type="button"
                          className="btn-sigma-primary py-2 px-4"
                          onClick={() => handleFollowUpCompleteClick(task)}
                        >
                          Mark Complete
                        </Button>
                      ) : (
                        <>
                          {isMinimized ? (
                            <MinimizedButton
                              fullWidth
                              size="large"
                              onClick={handleMinimizeCall}
                            />
                          ) : (
                            <Button
                              type="button"
                              className="btn btn-sigma-primary p-2 px-3"
                              onClick={handleShow}
                            >
                              {actionIcon(task.task_action.name)}
                              {task.task_action.name} Now
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {!isTaskOperationDisabled &&
                    task?.task_action.name !== 'FollowUp' && (
                      <Dropdown
                        onSelect={(eventKey) => setTaskOptionModalType(eventKey)}
                      >
                        <Dropdown.Toggle as={DotMenuDropdown} />
                        <Dropdown.Menu size="sm">
                          <Dropdown.Item
                            eventKey="cancel"
                            className="d-flex align-items-center"
                          >
                            <FaTrash color={colorValues.greyColor} />
                            <p className="ml-2">Cancel Task</p>
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="complete"
                            className="d-flex align-items-center"
                          >
                            <FaCheck color={colorValues.greyColor} />
                            <p className="ml-2">Complete Task</p>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  {showPhone && (
                    <PhoneCall
                      show={showPhone}
                      closeModal={handleClosePhone}
                      task={task}
                      lead={lead}
                    />
                  )}
                  <EmailCreate
                    show={showEmail}
                    closeModal={handleCloseEmail}
                    task={task}
                    lead={lead}
                  />
                  <TextMessage
                    show={showText}
                    closeModal={handleCloseText}
                    task={task}
                    lead={lead}
                  />
                </Col>
              )}
              <Col xs={12} key="automated-icon">
                {renderAutomatedStatus(task, true)}
              </Col>
              <Col xs={12} key="edit-lead-body">
                <div className={'edit-lead'}>
                  <div className={'edit-lead'}>
                    <span>Lead</span>
                    <Button
                      variant={'link'}
                      onClick={() => {
                        enableEditing();
                      }}
                    >
                      {isEditing ? 'Cancel' : 'Edit'}
                    </Button>{' '}
                  </div>
                  {isEditing && (
                    <Button
                      variant={'primary'}
                      className={'mr-2'}
                      onClick={() => {
                        setOnSave(true);
                      }}
                    >
                      {isSaving ? (
                        <SyncLoader color={'white'} size={'10px'} />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
            <Row noGutters key="task-edit-details">
              <Col xs={12} className="px-0">
                <hr />
                {hasUnansweredInboundCommunication &&
                  renderInboundCommunicationDisplay(lead)}
                {lead && (
                  <EditDetails
                    lead={lead}
                    isEditing={isEditing}
                    user={currentUser}
                    onSave={onSave}
                    setIsEditing={setIsEditing}
                    setIsSaving={setIsSaving}
                    setOnSave={setOnSave}
                    tasksCurrentPage={tasksCurrentPage}
                    tasksCurrentPageSize={tasksCurrentPageSize}
                    taskSequences={taskSequences}
                    setShowPhone={setShowPhone}
                  />
                )}
                <hr />
                <Row className="px-0">
                  <NoteCreate
                    show={showNote}
                    closeModal={handleCloseNote}
                    lead={lead}
                    task={task}
                  />
                  <Col key="notes-list">
                    <strong>Notes</strong>
                  </Col>
                  <Col className={'text-right'} key="add-note-button">
                    <Button variant={'light'} onClick={handleShowNote}>
                      Add Note
                    </Button>
                  </Col>
                </Row>
                {lead &&
                  lead.notes &&
                  lead.notes
                    .sort((a, b) =>
                      new Date(a.created_at) > new Date(b.created_at) ? 1 : -1
                    )
                    .map((note) => {
                      return <NoteDetail note={note} key={note.id} />;
                    })}
                {isLockedToUser && <UserSelfUnlockRow lead={lead} tasks />}
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}
            xl={6}
            key="lead-history"
            className="px-0 py-1 lead-history-tabs-section"
          >
            <LeadHistoryTabs openActionModal={openActionModal} lead={lead} />
          </Col>
        </Row>
      </Row>
    </Card>
  );
};

export default TaskDetail;
//--------------------------------------------------------------------------------
const UnansweredFollowUpDialog = ({ isOpen, onResolve, onReject, task }) => {
  const { data: currentUser } = useUsersCurrent();
  const { mutate: editTask, isLoading } = useTasksEdit();
  const { mutate: createEvent } = useEventsCreate();
  const { id: orgId } = useOrg();

  const handleCompleteTask = () => {
    editTask(
      {
        id: task.id,
        user_id: currentUser.id,
        completed_at: new Date().toISOString(),
      },
      {
        onSuccess: () => {
          createEvent({
            organization_id: orgId,
            event_type: 'task_closed_no_communication',
            actor_user_id: currentUser.id,
            actor: {
              user: {
                id: currentUser.id,
                first_name: currentUser.first_name,
                last_name: currentUser.last_name,
              },
            },
            subject: {
              task: { id: task.id, automated: task.automated },
              task_action: { id: task.task_action.id },
              lead: {
                first_name: task.lead.first_name,
                last_name: task.lead.last_name,
                id: task.lead.id,
              },
            },
          });
          onResolve();
        },
      }
    );
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Complete Task</DialogTitle>
      <DialogContent>
        No follow up communication detected. Would you like to close the task and
        confirm that you responded to the lead outside of LeadSigma?
      </DialogContent>
      <DialogActions>
        <MuiButton variant="text" color="inherit" onClick={onReject}>
          Close
        </MuiButton>
        <LoadingButton loading={isLoading} onClick={handleCompleteTask}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
//--------------------------------------------------------------------------------
const DotMenuDropdown = forwardRef(({ onClick }, ref) => {
  return (
    <a
      className="d-block h-100"
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <BiDotsVerticalRounded size="40px" color={colorValues.greyColor} />
    </a>
  );
});
//--------------------------------------------------------------------------------

const DeleteTaskModal = ({
  taskOptionModalType,
  setTaskOptionModalType,
  handleTaskOption,
}) => {
  return (
    <Fragment>
      {taskOptionModalType ? (
        <Modal
          className={'modal-sigma'}
          show={taskOptionModalType}
          onHide={() => setTaskOptionModalType()}
        >
          <Modal.Header className={'text-center modal-title'}>
            <Modal.Title className={'w-100'}>{`${
              taskOptionModalType === 'cancel' ? 'Cancel' : 'Complete'
            } Task`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="text-center">
              This task will be
              {`${
                taskOptionModalType === 'cancel'
                  ? ' canceled'
                  : ' marked as complete'
              }.`}
            </p>
            <p className="text-center">Are you sure you would like to continue?</p>
          </Modal.Body>
          <Modal.Footer>
            <Row className={'w-100'}>
              <Col xs="6">
                <Button
                  className={'btn btn-light btn-block'}
                  onClick={() => setTaskOptionModalType()}
                >
                  Cancel
                </Button>
              </Col>
              <Col xs="6">
                <Button
                  className={'btn btn-sigma-primary w-100'}
                  onClick={() => handleTaskOption(taskOptionModalType)}
                >
                  Confirm
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      ) : null}
    </Fragment>
  );
};

import React, { useState, useEffect } from 'react';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import {
  Box,
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Grid,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { SaveIcon } from '../theme/icons';
import { useAiProfileEdit } from '../../api/aiprofile/mutations';
import { useParams, Redirect } from 'react-router-dom';
import { useAiProfileShow } from '../../api/aiprofile/queries';
import { useOrg } from '../../contexts/OrgProvider';
import { useCalendarsList } from '../services/calendars/queries';
import { useAiModulesList } from '../../api/aiModules/queries';
import AIMappingsList from './AIMappingsList';
import OverrideArgumentsPage from './OverrideArgumentsPage';

export default function FewShotsEdit() {
  const { agentID } = useParams();
  const [editedData, setEditedData] = useState({});
  const { id: orgId } = useOrg();
  const [selectedCalendarId, setSelectedCalendarId] = useState();
  const [selectedModuleId, setSelectedModuleId] = useState();
  const [selectedMouleName, setSelectedModuleName] = useState();
  const [isAlertVisible, setAlertVisible] = useState(false);
  const { data: calendarData } = useCalendarsList([{ organization_id: orgId }]);
  const [editCalendarName, seteditCalendarName] = useState();
  const {
    data: aiProfileShow,
    isFetching: aiProfileFetaching,
    isLoading: aiProfileLoading,
    isError: aiProfileError,
  } = useAiProfileShow(agentID);

  const {
    mutate: aiprofileedit,
    isLoading: isEditloading,
    isError,
    isSuccess,
  } = useAiProfileEdit();

  const {
    data: aiModulesList,
    isLoading: aiModuleLoading,
    isError: aiModuleError,
  } = useAiModulesList();

  useEffect(() => {
    if (
      calendarData &&
      aiProfileShow &&
      calendarData.items &&
      Array.isArray(calendarData.items)
    ) {
      const selectedItem = calendarData.items.find(
        (item) => item.id === aiProfileShow.calendar_id
      );
      if (selectedItem) {
        setSelectedCalendarId(selectedItem.id);
        seteditCalendarName(selectedItem.name + ' ' + selectedItem.vendor);
      }
    }
  }, [calendarData, aiProfileShow]);

  useEffect(() => {
    if (aiProfileShow) {
      const selectedItem = aiModulesList?.items.find(
        (item) => item?.id === aiProfileShow?.ai_module?.id
      );

      if (selectedItem) {
        setSelectedModuleName(selectedItem?.name);
      }
    }
  }, [aiProfileShow, aiModulesList]);

  const handleTextChange = (e, field) => {
    const value = e.target.value;
    setEditedData({ ...editedData, [field]: value });
  };

  const handleSelectedCalendarId = (id, selectedCalendarName) => {
    setSelectedCalendarId(id);
    seteditCalendarName(selectedCalendarName);
  };

  const handleSelectedModuleId = (id, mouleName) => {
    setSelectedModuleId(id);
    setSelectedModuleName(mouleName);
  };

  const handleaiprofile = () => {
    aiprofileedit({
      id: agentID,
      greeting: editedData.greeting,
      name: editedData.name,
      calendar_id: selectedCalendarId,
      ai_module_id: selectedModuleId,
    });
    setAlertVisible(true);
  };

  // switchRedirect if useres to another account while on the old account's team
  if (String(orgId) !== String(aiProfileShow?.organization_id) && !!aiProfileShow) {
    return <Redirect to="/settings/AI" />;
  }
  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        setAlertVisible(false);
      }, 1000);
    }
  }, [isSuccess, isError]);

  return (
    <SettingsCard showLoading={aiProfileLoading}>
      <Box sx={{ height: '100%' }}>
        <SettingsCard.Header>AI Agents</SettingsCard.Header>
        {isError && (
          <Alert severity="error">There was a problem while editing.</Alert>
        )}
        {isAlertVisible && (
          <Alert severity="success">Saved profile changes successfully.</Alert>
        )}
        {aiProfileShow && (
          <Grid container direction="column" rowGap={2}>
            <Grid item>
              <InputLabel>Name</InputLabel>
              <TextField
                label="Name"
                type="text"
                defaultValue={editedData.name || aiProfileShow.name || ''}
                sx={{ width: '25%' }}
                onChange={(e) => handleTextChange(e, 'name')}
              />
            </Grid>
            <Grid item>
              <InputLabel>Module</InputLabel>
              <FormControl>
                <Select
                  variant={'standard'}
                  value={selectedMouleName || ''}
                  onChange={(e) => handleTextChange(e, 'module_name')}
                >
                  {aiModulesList &&
                    aiModulesList?.items.length > 0 &&
                    aiModulesList?.items.map((aiModule) => (
                      <MenuItem
                        key={aiModule.id}
                        value={aiModule.name}
                        onClick={() =>
                          handleSelectedModuleId(aiModule.id, aiModule.name)
                        }
                      >
                        {aiModule.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <InputLabel>Calendar</InputLabel>
              <FormControl>
                <Select
                  variant={'standard'}
                  value={editCalendarName || ''}
                  onChange={(e) => handleTextChange(e, 'calendar_name')}
                >
                  {calendarData &&
                    calendarData?.items.length > 0 &&
                    calendarData?.items.map((calendar) => (
                      <MenuItem
                        key={calendar.id}
                        value={calendar.name + ' ' + calendar.vendor}
                        onClick={() =>
                          handleSelectedCalendarId(
                            calendar.id,
                            calendar.name + ' ' + calendar.vendor
                          )
                        }
                      >
                        {calendar.name + ' ' + calendar.vendor}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <InputLabel>Greeting</InputLabel>
              <TextField
                multiline
                label="Greeting"
                type="text"
                rows={6}
                defaultValue={editedData.greeting || aiProfileShow.greeting}
                sx={{ width: '50%' }}
                onChange={(e) => handleTextChange(e, 'greeting')}
              />
            </Grid>
            <Grid item>
              <LoadingButton
                disabled={isEditloading}
                loading={isEditloading}
                startIcon={<SaveIcon />}
                type="submit"
                onClick={handleaiprofile}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        )}
        <Grid sx={{ mt: 2.3 }}>
          <AIMappingsList />
        </Grid>
        <Grid sx={{ mt: 2.3 }}>
          <OverrideArgumentsPage />
        </Grid>
      </Box>
    </SettingsCard>
  );
}

import Cookies from 'js-cookie';

export default class SharedUsersFilter {
  static async create(id, listIds) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/saved_searches/${id}/share`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(listIds),
      }
    );
  }
}

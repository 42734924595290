import { useQuery } from 'react-query';
import { queryKeyFactory } from '../../MUI/services/queryClientConfig';
import AIModulesFunctionArguments from './AIModulesFunctionArguments';

export const aiModulesFunctionArgumentsQueryKeys = queryKeyFactory(
  'ai-modules-function-arguments'
);

export const useAIModulesFunctionArgumentsList = (params, options) => {
  return useQuery(
    aiModulesFunctionArgumentsQueryKeys.list(),
    () => AIModulesFunctionArguments.list(params),
    {
      staleTime: 0,
      ...options,
    }
  );
};

export const useAIModulesFunctionArgumentsShow = (functionID, options) => {
  return useQuery(
    aiModulesFunctionArgumentsQueryKeys.list(functionID),
    () => AIModulesFunctionArguments.show(functionID),
    {
      staleTime: 0,
      ...options,
    }
  );
};

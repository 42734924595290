import { useState, useEffect } from 'react';
import { Chip, AccordionActions, TextField } from '@mui/material';

import { useDebounce } from '../../services/hooks/useDebounce';
import { FilterAccordion, FilterAccordionSummary } from './FilterAccordion';

const DebouncedTextFilter = ({
  filterMap,
  setFilterMapValue,
  label,
  filterKey,
  causeTextFieldValueChange,
  setSelectedSavedSearchId,
  options = [],
}) => {
  const [textFieldValue, setTextFieldValue] = useState('');
  const debouncedTextValue = useDebounce(textFieldValue, 300);

  useEffect(() => {
    setFilterMapValue(filterKey, debouncedTextValue);
  }, [debouncedTextValue]);

  useEffect(() => {
    setTextFieldValue(filterMap[filterKey]);
  }, [causeTextFieldValueChange]);

  return (
    <FilterAccordion>
      <FilterAccordionSummary>
        {label}
        {!!filterMap[filterKey]?.trim() && (
          <Chip
            onDelete={() => {
              setFilterMapValue(filterKey, '');
              setTextFieldValue('');
              setSelectedSavedSearchId(null);
            }}
            label={'1'}
          />
        )}
      </FilterAccordionSummary>
      <AccordionActions>
        <TextField
          fullWidth
          InputProps={{ sx: { fontSize: '14px' } }}
          InputLabelProps={{ sx: { fontSize: '14px' } }}
          label={label}
          value={textFieldValue}
          onChange={(e) => {
            setTextFieldValue(e.target.value);
            setSelectedSavedSearchId(null);
          }}
        />
      </AccordionActions>
    </FilterAccordion>
  );
};

export default DebouncedTextFilter;

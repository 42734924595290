import { useEffect } from 'react';
import { useOrganizationDefaultsList } from '../services/organizationDefaults/queries';
import {
  useOrgDefaultEdit,
  useOrgDefaultCreate,
} from '../services/organizationDefaults/mutations';
import { useOrg } from '../../contexts/OrgProvider';
import { useUsersCurrent } from '../../MUI/services/users/queries';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { LoadingButton } from '@mui/lab';
import HookForm from '../components/HookForm';
import { useConditionalUseFormProps } from '../components/HookForm/hooks';
import { createYupResolver } from '../components/HookForm/yupValidations';
import { Box, Alert, InputLabel, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import { SaveIcon } from '../theme/icons';
import { useOrganizationsShow } from '../services/organizations/queries';
import { useOrganizationsEdit } from '../services/organizations/mutations';

const AccountSettings = () => {
  const { id: orgId } = useOrg();
  const [url, setUrl] = useState('');
  const {
    mutate: editAccount,
    isError: isErrorEditingAccount,
    isEditing: isEditingAccount,
    isSuccess,
  } = useOrganizationsEdit();
  const { data: account, isError, isLoading } = useOrganizationsShow(orgId, {
    onSuccess: (data) => {
      setUrl(data.greeting_audio_url);
    },
  });

  const onSubmit = () => {
    editAccount({
      id: account.id,
      greeting_audio_url: url,
    });
  };

  return (
    <Grid container direction="column" rowSpacing={2} sx={{ width: 500 }}>
      {isError && (
        <Alert severity="error">
          There was a problem loading your account. Try again.
        </Alert>
      )}
      {isErrorEditingAccount && (
        <Alert severity="error" onClose={() => reset()}>
          There was an error saving your changes. Please try again.
        </Alert>
      )}
      {isSuccess && <Alert severity="success">Your changes were saved.</Alert>}
      <Grid item>
        <h6>Active Call Notifications</h6>
      </Grid>
      <Grid item>
        <TextField
          id="greeting-audio-text"
          label="Whisper Greeting Override"
          value={url}
          onChange={() => setUrl(event.target.value)}
          size="small"
        />
      </Grid>
      <Grid item>
        <LoadingButton
          type="submit"
          onClick={onSubmit}
          loading={isEditingAccount}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          sx={{ maxWidth: 'max-content' }}
        >
          Save
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

const AccountPage = () => {
  const { data: user } = useUsersCurrent();
  const { id: orgId } = useOrg();

  const {
    data: orgDefaults,
    isFetching: isFetchingOrgDefaults,
    isError,
  } = useOrganizationDefaultsList([['organization_id[]', orgId]], {});

  const {
    mutate: editOrgDefault,
    isLoading: isEditingDefaults,
    isError: isEditError,
    isSuccess,
    reset,
  } = useOrgDefaultEdit();

  const {
    mutate: createOrgDefault,
    isLoading: isCreatingDefaults,
    isError: isCreateError,
    isCreateSuccess,
  } = useOrgDefaultCreate();

  const onAccountDefaultsSubmit = (data) => {
    const defaults = [
      'opportunity_value_cents',
      'required_phone',
      'required_email',
      'required_source',
      'required_assigned_user',
      'auto_follow_up_schedule',
      'auto_follow_up_days',
      'reengagement_attempts',
      'auto_close_appointments',
    ];

    defaults.forEach((orgDefault) => {
      let value = data[orgDefault];
      let originalValue = getOrgDefaultValue(orgDefault);
      const id = getOrgDefaultId(orgDefault);

      if (orgDefault == 'opportunity_value_cents') {
        value = parseInt(value.replace(/,/g, '')) * 100;
      }
      if (value.toString() !== originalValue) {
        if (id === undefined || id === null) {
          createOrgDefault({
            organization_id: orgId,
            key: orgDefault,
            value: value.toString(),
          });
        } else {
          editOrgDefault({
            id: id,
            value: value.toString(),
          });
        }
      }
    });
  };

  const getOrgDefaultId = (key) => {
    try {
      return orgDefaults?.find((x) => x['key'] === key).id;
    } catch (error) {
      return null;
    }
  };

  const getOrgDefaultValue = (key) => {
    try {
      return orgDefaults?.find((x) => x['key'] === key).value;
    } catch (error) {
      return null;
    }
  };

  const getOrgValue = (key, defaultValue) => {
    const found = orgDefaults?.find((x) => x['key'] === key);
    if (found) {
      if (found.value === 'true') return true;
      if (found.value === 'false') return false;
      return found.value.toString();
    }
    return defaultValue;
  };

  const parseCentsToDollars = (cents) => {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      currency: 'USD',
    }).format(parseInt(cents) / 100);
  };

  const defaultValues = {
    ['opportunity_value_cents']: parseCentsToDollars(
      getOrgValue('opportunity_value_cents', '300000')
    ),
    ['required_phone']: getOrgValue('required_phone', false),
    ['required_email']: getOrgValue('required_email', false),
    ['required_source']: getOrgValue('required_source', false),
    ['required_assigned_user']: getOrgValue('required_assigned_user', false),
    ['auto_follow_up_schedule']: getOrgValue('auto_follow_up_schedule', false),
    ['auto_follow_up_days']: getOrgValue('auto_follow_up_days', 3),
    ['reengagement_attempts']: getOrgValue('reengagement_attempts', 3),
    ['auto_close_appointments']: getOrgValue('auto_close_appointments', false),
  };

  const useDefaultsFormProps = useConditionalUseFormProps(
    {
      defaultValues,
      resolver: createYupResolver(defaultValues),
    },
    !!orgDefaults
  );

  return (
    <Box sx={{ height: '100%' }}>
      <SettingsCard
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SettingsCard.Header>Account</SettingsCard.Header>
        <SettingsCard.Main sx={{ flex: 1 }}>
          {isError && (
            <Alert severity="error">
              There was a problem loading your account. Try again.
            </Alert>
          )}
          {isEditError && (
            <Alert severity="error" onClose={() => reset()}>
              There was an error saving your changes. Please try again.
            </Alert>
          )}
          {isSuccess && <Alert severity="success">Your changes were saved.</Alert>}
          <HookForm
            onSubmit={onAccountDefaultsSubmit}
            useFormProps={useDefaultsFormProps}
          >
            <Grid
              container
              direction="column"
              rowSpacing={2}
              sx={{ width: 500, mb: 4 }}
            >
              <Grid item>
                <h6>Lead value</h6>
              </Grid>
              <Grid item>
                <HookForm.TextField
                  name="opportunity_value_cents"
                  label="Default Lead Value"
                />
              </Grid>
              <Grid item>
                <h6>Required fields</h6>
              </Grid>
              <Grid item>
                <InputLabel shrink={true}>
                  Require certain fields when users add a lead through the add lead
                  button. These rules are not followed for leads created by
                  integrations.
                </InputLabel>
                <HookForm.Switch name="required_phone" label="Require phone" />
              </Grid>
              <Grid item>
                <HookForm.Switch name="required_email" label="Require email" />
              </Grid>
              <Grid item>
                <HookForm.Switch name="required_source" label="Require lead" />
              </Grid>
              <Grid item>
                <HookForm.Switch
                  name="required_assigned_user"
                  label="Require assigned user"
                />
              </Grid>
              <Grid item>
                <h6>Re-engagement Tasks</h6>
              </Grid>
              <Grid item>
                <InputLabel shrink={true}>
                  Automatically create re-engagement tasks when a user has responded
                  but then stops responding.
                </InputLabel>
                <HookForm.Switch
                  name="auto_follow_up_schedule"
                  label="Automatically create re-engagement for lack of response"
                />
              </Grid>

              <Grid item>
                <HookForm.TextField
                  name="reengagement_attempts"
                  label="Re-Engagement Attempts"
                />
              </Grid>
              <Grid item>
                <HookForm.TextField
                  name="auto_follow_up_days"
                  label="Days between"
                />
              </Grid>
              <Grid item>
                <h6>Scheduling Automations</h6>
              </Grid>
              <Grid item>
                <HookForm.Switch
                  name="auto_close_appointments"
                  label="Automatically Mark Won if Scheduled"
                />
              </Grid>
              <Grid item>
                <LoadingButton
                  type="submit"
                  loading={isEditingDefaults}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  sx={{ maxWidth: 'max-content' }}
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </HookForm>
          <AccountSettings />
        </SettingsCard.Main>
      </SettingsCard>
    </Box>
  );
};

export default AccountPage;

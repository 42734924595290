import jwt_decode from 'jwt-decode';
import parsePhoneNumber from 'libphonenumber-js';
import moment from 'moment';

export const getLeadName = (lead) => {
  return lead.first_name || lead.last_name
    ? `${lead.first_name ?? ''} ${lead.last_name ?? ''}`.trim()
    : 'Anonymous';
};

export const actionId = {
  Call: 'a50370fd-5b8b-4b87-ba15-744ec43840e6',
  Voicemail: '37e7e6e4-7bdb-42b7-a32b-0b285ae3a9f6',
  Email: '0fcbfe42-8a33-411e-8dad-46b269a34ec1',
  Text: '1b09c81b-8384-49a0-85c5-2466aacf9d37',
  FollowUp: '38212f0b-924f-4274-ad37-2086bc8d708d',
  ReEngage: 'd9e9de16-0d12-4358-91b6-bc0145c32b08',
};

const MMDDYYYYHMS = { timeStyle: 'medium', dateStyle: 'short' };
const MMDDYYYY = { dateStyle: 'short' };
export const dateToMMDDYYYYHMS = (date) =>
  date ? new Date(date).toLocaleString('en-US', MMDDYYYYHMS) : null;

export const dateToPrettyDateTime = (date) =>
  date ? moment(date).format('LLL') : null;

export const dateToMMDDYYYY = (date) =>
  date ? new Date(date).toLocaleString('en-US', MMDDYYYY) : null;

export const routerPathWithoutUuid = () => {
  var split_path = window.location.pathname.split('/');
  if (split_path[split_path.length - 1].length == 36) {
    split_path.pop();
  }
  var final = split_path.join('/');

  return final;
};

export const toastOptions = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

export const minutesUntilJwtExpires = (jwt) => {
  if (!jwt) return null;
  return (jwt_decode(jwt).exp - Math.floor(Date.now() / 1000)) / 60;
};

export const formatPhoneNumber = (phone) => {
  if (!phone) {
    return;
  }

  const phoneNumber = parsePhoneNumber(phone);
  if (phoneNumber) {
    return phoneNumber.formatNational();
  }
  return phone;
};

export const getFormData = (e) => {
  e.preventDefault();
  const formData = new FormData(e.currentTarget);
  return Object.fromEntries(formData.entries());
};

import { useMutation, useQueryClient } from 'react-query';
import { useOrg } from '../../contexts/OrgProvider';
import Rules from './Rules';

export const useRulesEdit = () => {
  const queryClient = useQueryClient();
  return useMutation(Rules.edit, {
    mutationKey: ['rules', 'edit'],
    onSuccess: () => {
      queryClient.invalidateQueries('rules');
    },
  });
};

export const useRulesDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(Rules.delete, {
    mutationKey: ['rules', 'delete'],
    onSuccess: () => {
      queryClient.invalidateQueries('rules');
    },
  });
};

export const useRulesCreate = () => {
  const queryClient = useQueryClient();
  const { id } = useOrg();
  return useMutation(
    (params) => Rules.create({ ...params, organization_id: String(id) }),
    {
      mutationKey: ['rules', 'create'],
      onSuccess: () => {
        queryClient.invalidateQueries('rules');
      },
    }
  );
};

import { MenuItem, TextField, Typography, Box } from '@mui/material';
import { useAiProfileList } from '../../../../api/aiprofile/queries';
import { useOrg } from '../../../../contexts/OrgProvider';
import { useEffect, useState } from 'react';
import TaskSequences from '../../../services/taskSequences/TaskSequences';

const options = [
  { operator: 'Emily', label: 'Emily' },
  { operator: 'Grace', label: 'Grace' },
  { operator: 'Chloe', label: 'Chloe' },
  { operator: 'Steve', label: 'Steve' },
  { operator: 'Spanish', label: 'Spanish' },
  { operator: 'Chinese', label: 'Chinese' },
  { operator: 'Vietnamese', label: 'Vietnamese' },
];

export const Ai = ({
  handleMutateComponentListByParametersInUI,
  id,
  parameters,
  doSearchEmptyInputs,
  getUpdatedInputParams,
}) => {
  const [inputParameters, setInputParameters] = useState(
    getUpdatedInputParams(id)?.parameters || parameters
  );
  const { id: orgId } = useOrg();
  const [taskSequencesList, setTaskSequenceList] = useState('');
  const { data: aiProfileData } = useAiProfileList({ organization_id: orgId });

  useEffect(() => {
    TaskSequences.list({
      organization_id: orgId,
      page_size: 10000,
    }).then((data) => {
      setTaskSequenceList(data);
    });
  }, []);

  const handleChange = (e) => {
    setInputParameters({
      ...inputParameters,
      [e.target.name]: e.target.value,
    });
    handleMutateComponentListByParametersInUI(
      { ...inputParameters, [e.target.name]: e.target.value },
      id
    );
  };

  return (
    <>
      <Box>
        <Typography fontSize={15} fontWeight={700} mb={1}>
          Please choose a voice
        </Typography>
        <TextField
          sx={{ width: '300px', marginBottom: '20px' }}
          value={inputParameters.agent}
          label="Voice"
          select
          name="agent"
          onChange={handleChange}
          error={doSearchEmptyInputs && !inputParameters.agent}
        >
          {options.map(({ operator, label }) => (
            <MenuItem key={operator} value={operator}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box>
        <Typography fontSize={15} fontWeight={700} mb={1}>
          Please choose AI Agents
        </Typography>
        <TextField
          sx={{ width: '300px', marginBottom: '20px' }}
          label="Agents"
          value={inputParameters.ai_profile_id}
          select
          name="ai_profile_id"
          onChange={handleChange}
          error={doSearchEmptyInputs && !inputParameters.ai_profile_id}
        >
          {aiProfileData?.items.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box>
        <Typography fontSize={15} fontWeight={700} mb={1}>
          Scheduled Task Sequence
        </Typography>
        <TextField
          sx={{ width: '300px', marginBottom: '20px' }}
          value={inputParameters.scheduled_task_sequence_id}
          label="Scheduled Task Sequence"
          select
          name="scheduled_task_sequence_id"
          onChange={handleChange}
          error={doSearchEmptyInputs && !inputParameters.scheduled_task_sequence_id}
        >
          {taskSequencesList?.items?.map((tasksequence) => (
            <MenuItem value={tasksequence?.id} key={tasksequence?.id}>
              {tasksequence?.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box>
        <Typography fontSize={15} fontWeight={700} mb={1}>
          Unscheduled Task Sequence
        </Typography>
        <TextField
          sx={{ width: '300px', marginBottom: '20px' }}
          value={inputParameters.unscheduled_task_sequence_id}
          label="Unscheduled Task Sequence"
          select
          name="unscheduled_task_sequence_id"
          onChange={handleChange}
          error={
            doSearchEmptyInputs && !inputParameters.unscheduled_task_sequence_id
          }
        >
          {taskSequencesList?.items?.map((tasksequence) => (
            <MenuItem value={tasksequence?.id} key={tasksequence?.id}>
              {tasksequence?.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </>
  );
};

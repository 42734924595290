import Cookies from 'js-cookie';
import { handleApiErrors } from '../util/HandleApiErrors';
import { getActiveOrg } from '../util/UserUtils';

export default class Mailboxes {
  static async listOwned(user_id) {
    return await fetch(
      `${
        process.env.REACT_APP_EMAIL_SERVICE
      }/mailboxes?owner_user_id=${user_id}&owner_org_id=${getActiveOrg()}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(handleApiErrors);
  }

  static async listAuthorized(user_id) {
    return await fetch(
      `${
        process.env.REACT_APP_EMAIL_SERVICE
      }/mailboxes?authorized_for=${user_id}&owner_org_id=${getActiveOrg()}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(handleApiErrors);
  }

  static async createAuthorizedUser(mailbox) {
    return await fetch(`${process.env.REACT_APP_EMAIL_SERVICE}/authorized_users`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(mailbox),
    }).then(handleApiErrors);
  }

  static async edit(mailbox) {
    const response = await fetch(
      `${process.env.REACT_APP_EMAIL_SERVICE}/mailboxes/${mailbox.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(mailbox),
      }
    );
    return response.json();
  }

  static async removeUser(id) {
    return await fetch(
      `${process.env.REACT_APP_EMAIL_SERVICE}/authorized_users/${id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(handleApiErrors);
  }

  static async show(mailbox_id) {
    return await fetch(
      `${process.env.REACT_APP_EMAIL_SERVICE}/mailboxes/${mailbox_id}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(handleApiErrors);
  }

  static async delete(id) {
    const response = await fetch(
      `${process.env.REACT_APP_EMAIL_SERVICE}/mailboxes/${id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    return response;
  }
}

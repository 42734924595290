import Cookies from 'js-cookie';
import { toJSON, toParamString } from '../queryClientConfig';
//
export default class PhoneId {
  static async create(data) {
    return await fetch(`${process.env.REACT_APP_CALL_TRACKING}/phone/setup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(toJSON);
  }

  static async list(filters) {
    return await fetch(
      `${process.env.REACT_APP_IVR}/phones?${toParamString(filters)}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async show(team_id) {
    return await fetch(`${process.env.REACT_APP_IVR}/phones/${team_id}`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
      },
    }).then(toJSON);
  }

  static async edit(team) {
    return await fetch(`${process.env.REACT_APP_IVR}/phones/${team.id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(team),
    }).then(toJSON);
  }

  static async delete(team_id) {
    return await fetch(`${process.env.REACT_APP_IVR}/phones/${team_id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
}

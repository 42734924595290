import { useState, useEffect } from 'react';
import { Link, useLocation, Prompt } from 'react-router-dom';
import { Container, Row, Col, Table, Form, Dropdown } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';
import { useContext } from 'react';
import AppContext from '../../../contexts/AppContext';
import CustomField from '../../../clients/CustomField';

const FIRST_NAME = 'dc3e9166-20ef-4173-ab42-f15d8722e513';
const LAST_NAME = '6df4c702-8d99-4e3a-9dc8-d361e69e8b16';
const PHONE = 'd1395960-8bca-4cd6-86a1-85e80a0672ea';
const EMAIL = '4ac5e10f-c362-42cd-ab31-1a832470cc3d';
const SOURCE = '91aaa73a-4ac7-4f82-81fa-3c17226ed859';
const TEAM = 'd7a0b780-b472-4292-8a5d-30274a856fe8';

const PreviewContainer = () => {
  const { user } = useContext(AppContext);
  const { state } = useLocation();
  const [displayFields, setDisplayFields] = useState([
    { display: 'First Name', key: FIRST_NAME },
    { display: 'Last Name', key: LAST_NAME },
    { display: 'Phone', key: PHONE },
    { display: 'Email', key: EMAIL },
    { display: 'Lead Source', key: SOURCE },
    { display: 'Team', key: TEAM },
  ]);
  const [orderedFields, setOrderedFields] = useState({});
  const [immediately, setImmediately] = useState('true');
  const [taskDelay, setTaskDelay] = useState(0);

  useEffect(() => {
    if (user.id) {
      CustomField.show(user.organization_id).then((res) => {
        setDisplayFields((prev) => [
          ...prev,
          ...res.items.map((customField) => ({
            display: customField.name,
            key: customField.id,
          })),
        ]);
      });
    }
  }, [user]);

  const isDisabled = (field) =>
    Object.entries(orderedFields).filter(
      ([key, value]) => field.display === value?.display
    ).length > 0;

  const fieldDropDown = (index) => {
    return (
      <Dropdown className="w-100">
        <Dropdown.Toggle
          className="import-csv-dropdown-toggle btn btn-light w-100 d-flex justify-content-between align-items-center"
          id={index}
        >
          {orderedFields[index] ? orderedFields[index].display : '---'}
        </Dropdown.Toggle>
        <Dropdown.Menu className="import-csv-dropdown-menu">
          <Dropdown.Item
            className="overflow-hidden text-truncate"
            onClick={() => setOrderedFields((prev) => ({ ...prev, [index]: null }))}
          >
            ---
          </Dropdown.Item>
          {displayFields.map((field) => {
            return (
              <Dropdown.Item
                className="overflow-hidden text-truncate"
                disabled={isDisabled(field)}
                onClick={() =>
                  setOrderedFields((prev) => ({
                    ...prev,
                    [index]: { key: field.key, display: field.display },
                  }))
                }
              >
                {field.display}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const updateImmediately = (e) => {
    setImmediately(e.target.value);
    if (e.target.checked) {
      setTaskDelay(0);
    }
  };

  const updateTaskDelay = (e) => {
    setImmediately('false');
    setTaskDelay(e.target.value);
  };

  const getLeadFields = () => {
    let temp = [];
    Object.entries(orderedFields).forEach(([objKey, objValue]) => {
      if (objValue) temp = [...temp, { order: parseInt(objKey), key: objValue.key }];
    });
    return temp;
  };

  if (!state?.records)
    return (
      <Alert variant="danger">
        It seems you do not have a CSV file uploaded. Please click on Import and
        upload a CSV.
      </Alert>
    );

  return (
    <Container fluid>
      <Prompt
        message={(location) => {
          if (location.pathname.includes('logout')) return true;
          const leadFields = location.state?.leadFields;
          const hasFirstAndLastName = () => {
            const first = leadFields?.find((f) => f.key === FIRST_NAME);
            const last = leadFields?.find((f) => f.key === LAST_NAME);
            return first && last;
          };

          if (location.pathname === '/complete') {
            if (!hasFirstAndLastName()) {
              alert('You must select values for First Name and Last Name.');
              return false;
            }
            return true;
          }
          return 'You have unsaved changes, are you sure you would like to leave this page?';
        }}
      />
      <Row>
        <Col xs={12} className={`pt-0 sm:pt-5 px-0 sm:px-2`}>
          <nav aria-label="breadcrumb">
            <ol className="sigma-breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/settings">Settings</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/settings/import">Import</Link>
              </li>
              <li className="breadcrumb-item">
                <Link
                  to={{
                    pathname: '/settings/import/upload',
                    state: { task_sequence_id: state?.sequenceId },
                  }}
                >
                  Upload
                </Link>
              </li>
              <li className="breadcrumb-item active">Preview</li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row>
        The first 20 records are shown
        <br />
        Please use the drop-downs to select the correct fields at the top of each
        column.
        <br />
        <Table striped bordered hover className="import-csv-table">
          <thead>
            <tr>
              {state?.records[0]?.map((_, index) => (
                <th>{fieldDropDown(index)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {state?.records?.slice(1, 20).map((row) => (
              <tr>
                {row.map((cell) => (
                  <td>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
      <Row className="mt-5">
        <Col>
          <h4>Task Distribution</h4>
          <hr />
          <Row>
            <Col xs="1" className="text-right my-auto">
              <Form.Check
                type="radio"
                name="distribution"
                id="distribution-immediately"
                checked={immediately == 'true'}
                value={true}
                onChange={updateImmediately}
              />
            </Col>
            <Col xs="11" className="my-auto">
              <label for="distribution-immediately">
                Start sequence now for all leads
              </label>
            </Col>
          </Row>
          <Row>
            <Col xs="1" className="text-right my-auto">
              <Form.Check
                type="radio"
                name="distribution"
                id="distribution-following"
                checked={immediately == 'false'}
                value={false}
                onChange={updateImmediately}
              />
            </Col>
            <Col xs="4" className="my-auto">
              <label for="distribution-following">
                Start sequence by distribution over the following
              </label>
            </Col>
            <Col xs="1" className="my-auto">
              <Form.Control
                as="select"
                defaultValue={taskDelay || 0}
                onChange={updateTaskDelay}
                disabled={immediately == 'true'}
              >
                {Array.from({ length: 365 }, (_, i) => i).map((x) => (
                  <option value={x} key={x}>
                    {x}
                  </option>
                ))}
              </Form.Control>
            </Col>
            <Col xs="5" className="my-auto">
              Days
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="mt-3">
          <Link
            to={{
              pathname: '/settings/import/complete',
              state: {
                importFileId: state?.importFileId,
                taskSequenceId: state?.taskSequenceId,
                leadFields: getLeadFields(),
                taskDelay: taskDelay,
              },
            }}
            className="btn btn-sigma-primary"
          >
            Finish
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default PreviewContainer;

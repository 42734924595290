import { object, string, number } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// field names =================================================
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const EMAIL_ADDRESS = 'emailAddress';
export const EMAIL_ADDDRESS_OPTIONAL = 'emailAddressOptional';
export const USER_NAME = EMAIL_ADDRESS;
export const PHONE_NUMBER = 'phoneNumber';
export const PHONE_NUMBER_OPTIONAL = 'phoneNumberOptional';

export const SOURCE = 'source';
export const SEQUENCE = 'sequence';
export const USER = 'user';
export const TEAM = 'team';

export const ADDRESS_LINE_ONE = 'addressLineOne';
export const ADDRESS_LINE_TWO = 'addressLineTwo';
export const CITY = 'city';
export const REGION = 'region';
export const POSTAL_CODE = 'postalCode';

export const SENDER_NAME = 'senderName';
export const ALIAS = 'alias';
export const NAME = 'name';
export const WAIT_TIME = 'waitTime';
export const TIMEOUT_SECONDS = 'timeoutSeconds';
export const MONEY = 'money';
export const FRIENDLY_FROM = 'friendlyFrom';

// Fields validations =================================================
const requiredString = string().required('This field is required');

const firstName = string().required('First Name is required');

const lastName = string().required('Last Name is required');

const emailAddress = string()
  .required('Email is required')
  .email('Must be valid email format');

const emailAddressOptional = string().email('Must be valid email format');

const userName = string()
  .required('Username is required')
  .email('Must be valid email format');

const phoneNumber = string()
  .required('Phone Number is required')
  .matches(
    /^\(\d{3}\)\s\d{3}-\d{4}/,
    'Phone number must be in the format: (555) 555-5555'
  );
const phoneNumberOptional = string()
  .transform((value) => (value === '' ? null : value))
  .matches(
    /^\(\d{3}\)\s\d{3}-\d{4}/,
    'Phone number must be in the format: "(555) 555-5555"'
  )
  .nullable(true);

const senderName = string().required();

const name = string().required('Name is required');

const addressLineOne = string().required('Street address is required');

const addressLineTwo = string();

const city = string().required('City is required');

const region = string().required('State is required');

const postalCode = string()
  .required('Postal code is required')
  .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Postal code must be correct format');

const waitTime = number()
  .integer()
  .min(5, 'Wait time must be at least 5 seconds')
  .required('Wait time is required')
  .typeError('Wait time must be a number');
const timeoutSeconds = number()
  .positive()
  .integer()
  .min(5, 'Wait time must be at least 5 seconds')
  .max(600, 'Wait time cannot be greater than 600 seconds')
  .nullable(true)
  .transform((_, val) => (!!parseInt(val) ? parseInt(val) : null))
  .typeError('Wait time must be a number');
const alias = string().nullable();

const source = object({ id: string() }).nullable().required('Source is required');
const sequence = object({ id: string() })
  .nullable()
  .required('Sequence is required');
const team = object({ id: string() }).nullable().required('Team is required');
const user = object({ id: string() }).nullable().required('User is required');

const money = number()
  .positive('Must be positive')
  .nullable(true)
  .transform((_, val) => (!!parseFloat(val) ? parseFloat(val) : null))
  .typeError('Value must be a valid dollar value');

const friendly_from = string().required('This field is required');

// Field validations mapping
export const yupFieldValidationsMap = {
  firstName,
  lastName,
  phoneNumber,
  phoneNumberOptional,
  emailAddress,
  emailAddressOptional,
  userName,
  senderName,
  requiredString,
  addressLineOne,
  addressLineTwo,
  city,
  region,
  postalCode,
  name,
  waitTime,
  timeoutSeconds,
  alias,
  source,
  sequence,
  team,
  user,
  money,
  friendly_from,
};

// Resolver creater function =================================================
// to help decrease chance of mismatching default fields and schema fields
export const createYupHash = (fields) => {
  let resolverObject = {};
  Object.keys(fields).forEach((key) => {
    if (!(key in yupFieldValidationsMap)) {
      // TODO throw error ?
    }
    resolverObject = {
      ...resolverObject,
      ...(key in yupFieldValidationsMap && {
        [key]: yupFieldValidationsMap[key],
      }),
    };
  });
  return resolverObject;
};

export const createYupResolver = (fields) => {
  return yupResolver(object(createYupHash(fields)));
};

import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Task from '../../clients/Task';
import AppContext from '../../contexts/AppContext';
import { useHistory } from 'react-router';
import TextEditor from '../common/TextEditor/TextEditor';
import Email from '../../clients/Email';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import Text from '../common/Text/Text';
import MessageTemplate from '../../clients/MessageTemplate';
import { toastOptions } from '../../util/AppUtils';
import { useQueryClient } from 'react-query';
import { tasksQueryKeys } from '../../MUI/services/tasks/queries';
import { leadsQueryKeys } from '../../MUI/services/leads/queries';
import { useMessageTemplatesList } from '../../MUI/services/messageTemplates/queries';
import { useMailboxesList } from '../../MUI/services/mailboxes/queries';
import { useSenderEmailAddressesList } from '../../MUI/services/senderEmailAddresses/queries';
import LeadEventHistory from '../../MUI/components/leadEventHistory/LeadEventHistory';

const EmailCreate = ({ show, closeModal, task, lead }) => {
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [mailboxId, setMailboxId] = useState();
  const [messageSubject, setMessageSubject] = useState('');
  const [defaultTemplate, setDefaultTemplate] = useState();
  const [renderedTemplate, setRenderedTemplate] = useState();
  const [resetDefaultTemplate, setResetDefaultTemplate] = useState();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isRenderingTemplate, setRenderingTemplate] = useState(false);
  const [mailboxOptions, setMailboxOptions] = useState([]);
  const { user } = useContext(AppContext);
  const queryClient = useQueryClient();
  const history = useHistory();
  const filterEmailList = (data) => {
    return data.filter((t) => t.task_action.name == 'Email');
  };
  const { data: messageTemplates } = useMessageTemplatesList(
    [['organization_id[]', lead.organization_id]],
    {
      select: filterEmailList,
    }
  );
  const { data: mailboxes } = useMailboxesList([
    ['owner_org_id', lead.organization_id],
    ['authorized_for', user.id],
  ]);
  const { data: senderEmailAddresses } = useSenderEmailAddressesList(user.id);

  const getDefaultEmail = () => {
    if (lead.emails.length == 0) {
      return;
    }
    const lastOut = lead.emails.reverse().find((e) => e.direction == 'out');
    if (lastOut) {
      return lastOut.from;
    }
  };

  useEffect(() => {
    if (messageTemplates) setEmailTemplates(messageTemplates);
  }, [messageTemplates, task]);

  useEffect(() => {
    const tempDefaultTemplate = emailTemplates.find((template) =>
      isDefault(template)
    );
    if (tempDefaultTemplate) {
      setDefaultTemplate(tempDefaultTemplate.id);
      renderTemplate(tempDefaultTemplate.id);
      setResetDefaultTemplate();
    }
  }, [emailTemplates, resetDefaultTemplate]);

  useEffect(() => {
    let options = [];
    if (mailboxes && mailboxes.length > 0) {
      options.push(
        mailboxes.map((mailbox) => {
          return (
            <option value={`mailbox:${mailbox.id}`} key={mailbox.id}>
              {mailbox.email_address}
            </option>
          );
        })
      );
    }

    if (senderEmailAddresses && senderEmailAddresses.length > 0) {
      options.push(
        senderEmailAddresses.map((mailbox) => {
          return (
            <option value={`senderEmail:${mailbox.id}`} key={mailbox.id}>
              {mailbox.address}
            </option>
          );
        })
      );
    }
    const defaultEmail = getDefaultEmail();
    options.forEach((o) => {
      if (defaultEmail == o[0].props.children) {
        setMailboxId(o[0].props.value);
      }
    });
    if (!mailboxId && options.length > 0) {
      setMailboxId(options[0][0].props.value);
    }
    setMailboxOptions(options);
  }, [mailboxes, senderEmailAddresses]);

  useEffect(() => {
    if (renderedTemplate) {
      setMessageSubject(renderedTemplate.subject);
      setEditorState(templateHTMLtoEditorState(renderedTemplate.content));
    } else {
      setMessageSubject('');
      setEditorState(EditorState.createEmpty());
    }
  }, [renderedTemplate]);

  const renderTemplate = (templateId) => {
    setRenderingTemplate(true);

    MessageTemplate.render(templateId, user.id, lead.id)
      .then((res) => {
        setRenderedTemplate(res);
        setRenderingTemplate(false);
      })
      .catch(() => {
        setRenderedTemplate(null);
        setRenderingTemplate(false);
      });
  };

  const primaryEmail = () => {
    if (lead.email_addresses && lead.email_addresses.length == 1) {
      return lead.email_addresses[0].email;
    }
    const primary = lead.email_addresses
      ? lead.email_addresses.filter((x) => x.is_primary)
      : [];
    return primary.length > 0 ? primary[0].email : '';
  };

  const messageTemplateOptions = () => {
    return emailTemplates
      .map((template) => {
        return (
          <option value={template.id} key={template.id}>
            {template.name}
          </option>
        );
      })
      .sort((a, b) => (a.props.children > b.props.children ? 1 : -1));
  };

  const changeMailbox = (e) => {
    const selectedId = e.target.value;
    if (selectedId) {
      setMailboxId(selectedId);
    }
  };

  const changeTemplate = (e) => {
    const selectedId = e.target.value;
    const template = emailTemplates.find((e) => e.id == selectedId);
    if (!template) {
      setMessageSubject('');
      setEditorState(EditorState.createEmpty());
      return;
    }
    renderTemplate(template.id);
  };

  const templateHTMLtoEditorState = (templateHtml) => {
    return EditorState.createWithContent(
      ContentState.createFromBlockArray(htmlToDraft(templateHtml))
    );
  };

  const completeTask = () => {
    Task.edit({
      id: task.id,
      user_id: user.id,
      completed_at: new Date().toISOString(),
    }).then(() => {
      queryClient.invalidateQueries(tasksQueryKeys.all);
      queryClient.invalidateQueries(leadsQueryKeys.all);
      closeModal();
      history.push({ pathname: '/tasks' });
    });
  };

  const handleSend = () => {
    closeModal();
    toast.info('Sending email', toastOptions);

    const sender = mailboxId.split(':');
    const body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (sender[0] == 'mailbox') {
      Email.send(
        {
          subject: messageSubject,
          body: body,
          lead_id: lead.id,
          sender_user_id: user.id,
          sender_type: 'user',
        },
        sender[1]
      )
        .then((res) => {
          toast.success('Sent email successfully', toastOptions);
          queryClient.invalidateQueries(tasksQueryKeys.all);
          queryClient.invalidateQueries(leadsQueryKeys.shows());
          setEditorState(EditorState.createEmpty());
          if (task) {
            completeTask();
          }
        })
        .catch((_) => {
          toast.error('Failed to send email. Please try again.', toastOptions);
        });
    } else {
      Email.ses_send(
        {
          user_id: user.id,
          subject: messageSubject,
          body: body,
          sender_type: 'user',
        },
        lead.id
      )
        .then((res) => {
          toast.success('Sent email successfully', toastOptions);
          queryClient.invalidateQueries(tasksQueryKeys.all);
          queryClient.invalidateQueries(leadsQueryKeys.shows());
          setEditorState(EditorState.createEmpty());
          if (task) {
            completeTask();
          }
        })
        .catch((_) => {
          toast.error('Failed to send email. Please try again.', toastOptions);
        });
    }
  };

  const updateMessageSubject = (e) => {
    setMessageSubject(e.target.value);
  };

  const handleClose = () => {
    setResetDefaultTemplate(true);
    setMessageSubject('');
    setEditorState(EditorState.createEmpty());
    closeModal();
  };

  const isDefault = (template) => {
    if (!task) {
      return false;
    }
    return task.message_template_id === template.id;
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className={'modal-sigma'}
        backdrop={'static'}
        dialogClassName="email-modal"
      >
        <Row noGutters className="p-3">
          <Col xs={12} lg={4}>
            <Row noGutters className="p-2 mt-2">
              <Col xs={12}>
                <Text as="h4" align="center">
                  Communication History
                </Text>
                <hr />
              </Col>
              <Col xs={12} className="pr-2 email-communication-history">
                <LeadEventHistory lead={lead} />
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={8}>
            <Modal.Header className={'text-center'}>
              <Modal.Title className={'w-100'}>
                <Row noGutters className="justify-content-between">
                  <p>Sending Email</p>
                  <p className="text-truncate">{primaryEmail()}</p>
                </Row>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row noGutters>
                  <Col xs={6}>
                    <Form.Group controlId={'to'} className="d-flex flex-column">
                      <Form.Label className={'mr-1'}>
                        <strong>To:</strong>
                      </Form.Label>
                      {lead.first_name} {lead.last_name}
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group controlId={'mailbox'}>
                      <Form.Label className="d-flex align-items-center">
                        <strong>Mailbox</strong>
                        <OverlayTrigger
                          placement={'bottom'}
                          delay={{ show: 100, hide: 50 }}
                          overlay={
                            <Tooltip id="select-mailbox-tooltip" variant="secondary">
                              Please select a connected mailbox to send the email
                              from. If you do not have any connected mailboxes you
                              may add one by navigating to Settings {'>'} Connected
                              Mailboxes.
                            </Tooltip>
                          }
                        >
                          <FaInfoCircle
                            size={'1.5em'}
                            color={'#34d1b6'}
                            className="ml-2"
                          />
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        as={'select'}
                        onChange={changeMailbox}
                        defaultValue={mailboxId}
                        disabled={!mailboxes || mailboxes.length == 0}
                      >
                        {mailboxOptions}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row noGutters>
                  <Col xs={6} className="pr-3">
                    <Form.Group controlId={'template'}>
                      <Form.Label>
                        <strong>Template:</strong>
                      </Form.Label>
                      <Form.Control
                        as={'select'}
                        onChange={changeTemplate}
                        defaultValue={defaultTemplate}
                      >
                        <option>---</option>
                        {messageTemplateOptions()}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group controlId={'subject'}>
                      <Form.Label>
                        <strong>Subject:</strong>
                      </Form.Label>
                      <Form.Control
                        type={'text'}
                        placeholder={'Subject'}
                        value={messageSubject}
                        onChange={updateMessageSubject}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId={'message'}>
                  <Form.Label>
                    <strong>Message:</strong>
                  </Form.Label>
                  <TextEditor
                    editorState={editorState}
                    setEditorState={setEditorState}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Container>
                <Row>
                  <Col xs={12} sm={6}>
                    <button
                      type={'button'}
                      className={'btn btn-light btn-block'}
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Button
                      variant="primary"
                      className={'w-100'}
                      onClick={handleSend}
                      disabled={
                        !editorState.getCurrentContent().hasText() ||
                        !mailboxId ||
                        !messageSubject ||
                        messageSubject.trim().length == 0
                      }
                    >
                      Send Email Now
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Modal.Footer>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default EmailCreate;

import { useQuery } from 'react-query';
import { queryKeyFactory } from '../queryClientConfig';
import NexHealthLocations from './NexHealthLocations';

export const nexHealthQueryKeys = queryKeyFactory('nexHealthLocations');

export const useNexHealthLocations = (filters, options) => {
  return useQuery(
    nexHealthQueryKeys.list(filters),
    () => NexHealthLocations.list(filters),
    {
      staleTime: 0,
      retry: 0,
      ...options,
    }
  );
};

import Cookies from 'js-cookie';
import { toJSON } from '../utils/utils';

export default class Slots {
  static async create(rule) {
    return await fetch(`${process.env.REACT_APP_CALENDAR_SERVICE}/slots`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(rule),
    }).then(toJSON);
  }
  static async list(params = {}) {
    return await fetch(
      `${process.env.REACT_APP_CALENDAR_SERVICE}/slots?${new URLSearchParams(
        params
      ).toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async edit(params) {
    return await fetch(
      `${process.env.REACT_APP_CALENDAR_SERVICE}/slots/${params.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      }
    ).then(toJSON);
  }

  static async delete(slots_id) {
    return await fetch(
      `${process.env.REACT_APP_CALENDAR_SERVICE}/slots/${slots_id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    );
  }
}

import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import Container from 'react-bootstrap/Container';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Card from 'react-bootstrap/Card';
import ReportCard from '../../MUI/components/Reports/ReportCard';

import ChartCard from './ChartCard';
import AppContext from '../../contexts/AppContext';
import { useOrg } from '../../contexts/OrgProvider';
import DateRangeSelection from '../common/DateRangePicker/DateRangeSelection';
import { DateRangeProvider, useDateRangeContext } from '../common/DateRangeProvider';
import { ActivityContainerWidget } from '../activity/ActivityContainer';
import { useCompletedTasks, useTasksDueToday } from '../../api/task/taskQueries';
import { useUsersCurrent } from '../../MUI/services/users/queries';

const DashboardContainer = () => {
  const reportGroups = {
    // messagingApiBase: [],
    apiBase: [
      {
        type: 'acn_accept_rate',
        name: 'Active Call Notifications Accept Rate',
      },
      { type: 'call_answer_rate', name: 'Calls Answer Rate' },
      { type: 'task_today', name: 'Tasks To Do Today' },
      { type: 'task_overdue', name: 'Overdue Tasks' },
      { type: 'lead_generated', name: 'Total Leads Created' },
      { type: 'lead_won', name: 'Leads Won' },
      { type: 'lead_conversion_rate', name: 'Conversion Rate' },
      { type: 'win_worth', name: 'LeadSigma Wins' },
      { type: 'pipeline_value', name: 'Pipeline Value' },
      { type: 'daily_logins', name: 'Recent Logins' },
    ],
  };

  const today = moment(Date.now()).format('YYYY-MM-DD');
  const { data: user } = useUsersCurrent();
  const { leads } = useContext(AppContext);
  const [tasksDue, setTasksDue] = useState([]);
  const [{ startDate, endDate }, setSelectedDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const { id: orgId, isMasterAccount } = useOrg();
  const { data: tasksDueToday } = useTasksDueToday();
  const { data: tasksCompleted } = useCompletedTasks();

  const createParams = (apiGroup, type) => {
    const params = {
      apiGroup: apiGroup,
      canUse: Boolean(startDate && endDate),
      filters: [
        ['type', type],
        ['date_range_start', startDate],
        ['date_range_end', endDate],
        ['primary_organization_id', orgId],
      ],
    };
    if (!isMasterAccount) {
      params.filters.push(['organization_id', orgId]);
    }
    return params;
  };

  const timePeriod = () => {
    var today = new Date();
    var curHr = today.getHours();

    if (curHr < 12) {
      return 'morning';
    } else if (curHr < 18) {
      return 'afternoon';
    } else {
      return 'evening';
    }
  };

  useEffect(() => {
    setTasksDue(tasksDueToday?.items);
  }, [tasksDueToday]);

  const toggleView = (e) => {
    setListView(!view);
  };

  const getCompletedOverTotal = () => {
    return Math.ceil(
      (tasksCompleted?.total_count /
        (tasksCompleted?.total_count + tasksDueToday?.total_count)) *
        100
    );
  };

  return (
    <DateRangeProvider selectedDateRange={{ startDate, endDate }}>
      <Container fluid>
        <Row className={'mt-0 sm:mt-5'}>
          <Col xs={12}>
            <Row noGutters>
              <Col xs={12} md={4}>
                <h3>
                  Good {timePeriod()}, {user?.first_name}
                </h3>
                <nav aria-label="breadcrumb">
                  <ol className="sigma-breadcrumb">
                    <li className="breadcrumb-item active">Dashboard</li>
                  </ol>
                </nav>
              </Col>
              <Col xs={12} md={8}>
                <DateRangeSelection
                  setSelectedDateRange={setSelectedDateRange}
                  earliestAllowedStartDate="09-12-2021"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <ProgressBar
              className={'mt-3'}
              now={getCompletedOverTotal()}
              bsPrefix={'sigma-progress'}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={9} className={'pl-0 pr-0 pr-lg-5'}>
            <Row>
              <Col xs={12} className={'mt-5'}>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'start',
                    gap: 3,
                  }}
                >
                  {Object.entries(reportGroups).map(([apiGroup, reportTypes]) => (
                    <>
                      {reportTypes.map((report) => (
                        <ReportCard
                          key={report.type}
                          report={report}
                          params={createParams(apiGroup, report.type)}
                        />
                      ))}
                    </>
                  ))}
                </Box>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <ChartCard leads={leads} linkEnabled={true} />
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} lg={3}>
            <Row>
              <Col xs={12} className={'mt-5 px-0'}>
                <Card>
                  <ActivityContainerWidget />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </DateRangeProvider>
  );
};

export default DashboardContainer;

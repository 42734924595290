import { Box } from '@mui/system';
import ClipLoader from 'react-spinners/ClipLoader';
import { useState } from 'react';

const SettingsCardLoadingSpinner = ({ children, isLoading }) => {
  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <ClipLoader size={300} color="#34D1B6" />
        </Box>
      ) : (
        children
      )}
    </>
  );
};

export default SettingsCardLoadingSpinner;

import { useState } from 'react';
import { MenuItem, TextareaAutosize, TextField } from '@mui/material';
import { voiceOptions } from './utils';

export const Greeting = ({
  handleMutateComponentListByParametersInUI,
  id,
  parameters,
  doSearchEmptyInputs,
  getUpdatedInputParams,
}) => {
  const [inputParameters, setInputParameters] = useState(
    getUpdatedInputParams(id)?.parameters || parameters
  );

  const handleChange = (e) => {
    setInputParameters({
      ...inputParameters,
      [e.target.name]: e.target.value,
    });
    handleMutateComponentListByParametersInUI(
      { ...inputParameters, [e.target.name]: e.target.value },
      id
    );
  };

  return (
    <>
      <TextField
        sx={{ width: '300px', marginBottom: '20px' }}
        value={inputParameters.voice}
        label="Please choose voice"
        select
        name="voice"
        onChange={handleChange}
        error={doSearchEmptyInputs && !inputParameters.voice}
      >
        {voiceOptions.map(({ operator, label }) => {
          return (
            <MenuItem key={operator} value={operator}>
              {label}
            </MenuItem>
          );
        })}
      </TextField>
      <br />
      <TextField
        label="Message"
        placeholder="Type your message here"
        style={{ width: '300px' }}
        value={inputParameters.message}
        name="message"
        onChange={handleChange}
        multiline
        error={doSearchEmptyInputs && !inputParameters.message}
      />
    </>
  );
};

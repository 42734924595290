import React, { Fragment, useState, useEffect } from 'react';
import { Row, Card, Col } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import moment from 'moment';
import { Chrono } from 'react-chrono';
import NoDataDisplay from '../noDataDisplay/NoDataDisplay';
import { useActivitiesList } from '../../MUI/services/activity/queries';
import { NavLink, useHistory } from 'react-router-dom';
//----------------------------------------------------------------

export const ActivityContainerWidget = () => {
  const [showError, setShowError] = useState(false);
  const history = useHistory();
  const {
    data: data,
    isError: isActivityErrored,
    error: activitiesError,
    isLoading: isLoadingActivities,
    isFetching: isFetchingActivities,
    isIdle: isIdleLoadingActivities,
  } = useActivitiesList([
    ['page', 1],
    ['page_size', 10],
  ]);

  const {
    total_count: totalActivitiesCount,
    total_pages: totalActivitiesPages,
    items: activities,
  } = { ...data };

  useEffect(() => {
    setShowError(isActivityErrored);
  }, [isActivityErrored]);

  const renderWidgetActivityRecord = (activity) => {
    return (
      <div key={activity.id} className="chrono-item card-content-wrapper">
        <section className="chrono-section timeline-card-content">
          <header className="w-100">
            <p
              className="chrono-title card-title"
              dangerouslySetInnerHTML={{
                __html: activity.description,
              }}
            />
            <p className="chrono-sub-title card-sub-title">
              {moment(activity.created_at).fromNow()}
            </p>
          </header>
        </section>
      </div>
    );
  };

  const widgetTheme = {
    primary: '#34D1B6',
    secondary: '#907BF0',
    cardBgColor: 'white',
    cardForeColor: '#505050',
  };

  return (
    <Fragment>
      <Card className={`card-sigma card-upcoming`}>
        <Card.Header className={`py-4`}>
          <Row>
            <Col xs={12} className={`text-truncate font-weight-medium`}>
              Recent Activity
            </Col>
          </Row>
        </Card.Header>
        {isLoadingActivities || isFetchingActivities || isIdleLoadingActivities ? (
          <Col className="text-center p-5">
            <ClipLoader size={250} color="#34D1B6" />
          </Col>
        ) : (
          <>
            {activities?.length > 0 ? (
              <Chrono
                mode={'VERTICAL'}
                hideControls={true}
                theme={widgetTheme}
                scrollable={{ scrollbar: false }}
              >
                {activities.slice(0, 5).map((activity) => {
                  return renderWidgetActivityRecord(activity);
                })}
              </Chrono>
            ) : (
              <NoDataDisplay title="No Activities to show" />
            )}
          </>
        )}
        <NavLink
          to={'/activity'}
          className={'btn btn-block btn-light stretched-link'}
        >
          Load More
        </NavLink>
      </Card>
    </Fragment>
  );
};

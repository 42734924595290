import { GridFooterContainer } from '@mui/x-data-grid';
import TablePagination from '@mui/material/TablePagination';

const CustomFooter = ({
  rowCount,
  page,
  onPageChange,
  onPageSizeChange,
  pageSize,
}) => {
  const handlePageChange = (_, newPage) => {
    onPageChange(newPage);
  };

  const handlePageSizeChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    onPageSizeChange(newPageSize);
  };

  return (
    <GridFooterContainer>
      <TablePagination
        component="div"
        count={rowCount}
        page={page}
        rowsPerPage={pageSize}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handlePageSizeChange}
        rowsPerPageOptions={[10, 25, 50]}
        style={{ marginRight: 'auto' }}
      />
    </GridFooterContainer>
  );
};

export default CustomFooter;

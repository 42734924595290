import Cookies from 'js-cookie';
import { handleApiErrors } from '../util/HandleApiErrors';
import { getActiveOrg } from '../util/UserUtils';

export default class CustomField {
  static async show() {
    const response = await fetch(
      `${
        process.env.REACT_APP_API_BASE
      }/custom_fields?organization_id=${getActiveOrg()}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    );
    return response.json();
  }

  static async create(custom_field) {
    custom_field['organization_id'] = getActiveOrg();
    await fetch(`${process.env.REACT_APP_API_BASE}/custom_fields`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(custom_field),
    }).then(handleApiErrors);
  }

  static async order(custom_fields) {
    custom_fields['organization_id'] = getActiveOrg();
    await fetch(`${process.env.REACT_APP_API_BASE}/custom_fields_orders`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(custom_fields),
    }).then(handleApiErrors);
  }

  static async delete(custom_field_id) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/custom_fields/${custom_field_id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    return response;
  }

  static async edit(custom_field) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/custom_fields/${custom_field.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(custom_field),
      }
    );
    return response.json();
  }
}

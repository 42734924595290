import { useReducer } from 'react';
import { createStep } from './util';

export const INITIALIZE = 'INITIALIZE';
export const ADD_STEP = 'ADD_STEP';
export const DELETE_STEP = 'DELETE_STEP';
export const UPDATE_STEP = 'UPDATE_STEP';

export const useRenderedSteps = () => {
  return useReducer((state = [], { payload, type }) => {
    switch (type) {
      case INITIALIZE:
        return payload;
      case ADD_STEP:
        return [
          ...state,
          {
            ...createStep({
              type: payload.actionType,
              days_after: payload.daysAfter,
              message_template_id: payload.messageTemplateId,
            }),
          },
        ];
      case DELETE_STEP:
        return state.filter((step) => step.editID !== payload.editID);
      case UPDATE_STEP:
        return state.map((step) => {
          if (step.editID === payload.editID)
            Object.entries(payload.updates).forEach(
              ([key, value]) => (step[key] = value)
            );
          return { ...step };
        });
      default:
        return state;
    }
  });
};

import { useMutation, useQueryClient } from 'react-query';
import { useOrg } from '../../contexts/OrgProvider';
import Teams from './Teams';

export const useTeamsEdit = (options) => {
  const queryClient = useQueryClient();
  return useMutation(Teams.edit, {
    onSuccess: (data, variables) => {
      const teams = queryClient.getQueryData(['teams']);
      if (teams) {
        const teamIndex = teams.findIndex((x) => x.id == variables.id);
        teams[teamIndex] = data;
        queryClient.setQueryData(['teams'], teams);
      }
      queryClient.setQueryData(['teams', variables.id], data);
    },
    ...options,
  });
};

export const useTeamsDelete = (options) => {
  const queryClient = useQueryClient();
  return useMutation(Teams.delete, {
    onSuccess: (data, variables) => {
      const teams = queryClient.getQueryData(['teams']);
      queryClient.removeQueries(['teams', variables.id]);
      const teamIndex = teams.findIndex((x) => x.id == variables.id);
      teams.splice(teamIndex, 1);
      queryClient.setQueryData(['teams'], teams);
    },
    ...options,
  });
};

export const useTeamsCreate = (options) => {
  const queryClient = useQueryClient();
  const { id } = useOrg();
  return useMutation(
    (params) => Teams.create({ ...params, organization_id: String(id) }),
    {
      onSuccess: (data, variables) => {
        const teams = queryClient.getQueryData(['teams']);
        teams.push(data);
        queryClient.setQueryData(['teams'], teams);
      },
      ...options,
    }
  );
};

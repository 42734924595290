import { Button, Modal, Row, Col } from 'react-bootstrap';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { localStorageKeys } from '../../util/Constants';
import moment from 'moment';
import { useUsersCurrent } from '../../MUI/services/users/queries';
import { v4 as uuid } from 'uuid';
import { useUserPushEndpointsCreate } from '../../api/userPushEndpoints/userPushEndpointsMutations';
import { getActiveOrg } from '../../util/UserUtils';

const pushNotificationRemdinerStatuses = {
  askMeLater: { datetime: moment().add(7, 'days'), status: 'Ask me later' },
  unspecified: { datetime: moment().add(1, 'days'), status: 'Unspecified' },
  enable: { datetime: moment(), status: 'Enable' },
};

const CustomPushSubPrompt = ({
  showCustomPushSubPrompt,
  setShowCustomPushSubPrompt,
}) => {
  const { mutate: createPushEndpoint } = useUserPushEndpointsCreate();
  const { data: user } = useUsersCurrent();
  const [
    pushNotificationRemdinerStatus,
    setPushNotificationRemdinerStatus,
  ] = useLocalStorage(localStorageKeys.pushNotificationRemdinerStatus);

  const subscribeToPush = () => {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlB64ToUint8Array(
              process.env.REACT_APP_VAPID_KEY
            ),
          })
          .then((subscription) => {
            const subscriptionString = JSON.stringify(subscription);
            createPushEndpoint({
              user_id: user.id,
              endpoint_id: subscriptionString,
              status: 'active',
              device_id: uuid(),
              organization_id: getActiveOrg(),
            });
          })
          .catch((e) => {});
      })
      .catch((e) => {});
  };

  return (
    <Modal
      className="modal-sigma"
      onHide={() => {
        setPushNotificationRemdinerStatus(
          pushNotificationRemdinerStatuses.unspecified
        );
        setShowCustomPushSubPrompt(false);
      }}
      show={showCustomPushSubPrompt}
    >
      <Modal.Header className="justify-content-center">
        <Modal.Title className="text-center">
          Important: Enable Notifications
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {pushNotificationRemdinerStatus?.action !== 'enable'
          ? `We will send you occasional concierge notifications to make your life easier.
        👍`
          : `Please click the "Enable" button below then click "Allow" when you are prompted again.`}
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100">
          <Col>
            <Button
              className="w-100"
              onClick={() => {
                subscribeToPush();
                setPushNotificationRemdinerStatus(
                  pushNotificationRemdinerStatuses.enable
                );
                setShowCustomPushSubPrompt(false);
              }}
            >
              Enable
            </Button>
          </Col>
          <Col>
            <Button
              className="btn btn-light btn-block"
              onClick={() => {
                setPushNotificationRemdinerStatus(
                  pushNotificationRemdinerStatuses.askMeLater
                );
                setShowCustomPushSubPrompt(false);
              }}
            >
              Ask me later
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomPushSubPrompt;

const urlB64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const setScopeToNativePrompt = (scope) => {
  scope.setTransactionName('PushSubscriptionNativePrompt');
  return scope;
};

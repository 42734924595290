import { Box, Button } from '@mui/material';
import { defineUiElements } from '../utils';
import * as options from './utils';

export const InitialList = ({
  handleAddComponent,
  handleInsertComponent,
  insertAfterId,
}) => {
  const optionsList = [
    insertAfterId ? null : options.menuOption,
    options.greetingOption,
    options.playOption,
    options.aiOption,
    options.dialOption,
    options.createLeadOption,
    options.voiceMailOption,
    options.hangupOption,
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        width: '80%',
        justifyContent: 'center',
        flexWrap: 'wrap',
        margin: '30px auto',
      }}
    >
      {optionsList.filter(Boolean).map((el) => {
        return (
          <Button
            variant="text"
            key={el.id}
            onClick={() =>
              insertAfterId
                ? handleInsertComponent(insertAfterId, el)
                : handleAddComponent(el)
            }
            sx={{
              margin: '20px',
              display: 'flex',
              width: '100px',
              height: '100px',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              color: 'black',
              '&:hover': {
                background: 'transparent',
              },
            }}
          >
            <Box sx={{ transform: 'scale(1.5)', paddingBottom: '10px' }}>
              {defineUiElements(el.title, true)}
            </Box>
            {el.title}
          </Button>
        );
      })}
    </Box>
  );
};

import { useQuery } from 'react-query';
import Organizations from './Organizations';
import { queryKeyFactory, ONE_HOUR } from '../queryClientConfig';

const organizationsQueryKeys = queryKeyFactory('organizations');
organizationsQueryKeys.billingAddresses = (id) => [
  'organizations',
  'billing-address',
  { id },
];
organizationsQueryKeys.organizationServices = (id) => [
  'organizations',
  'organization-services',
  { id },
];
export { organizationsQueryKeys };

export const useOrganizationsList = (params = [], options) => {
  return useQuery(
    organizationsQueryKeys.list(params),
    () => Organizations.list(params),
    {
      staleTime: ONE_HOUR,
      ...options,
    }
  );
};

export const useOrganizationsShow = (id, options) => {
  return useQuery(organizationsQueryKeys.show(id), () => Organizations.show(id), {
    ...options,
  });
};

export const useBillingAddress = (organization_id) => {
  return useQuery(
    organizationsQueryKeys.billingAddresses(organization_id),
    () => Organizations.billingAddresses(organization_id),
    {
      staleTime: ONE_HOUR,
    }
  );
};

export const useOrganizationServices = (organization_id) => {
  return useQuery(
    organizationsQueryKeys.organizationServices(organization_id),
    () => Organizations.organizationServices(organization_id),
    {
      staleTime: ONE_HOUR,
    }
  );
};

import Cookies from 'js-cookie';
import { toJSON } from '../utils/utils';

export default class SlingshotFields {
  static async list(params = {}) {
    return await fetch(
      `${
        process.env.REACT_APP_MESSAGING_API_BASE
      }/slingshot_fields?${new URLSearchParams(params).toString()}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }
}

import Cookies from 'js-cookie';
import React, { useState, useEffect, useRef } from 'react';
import { CallsPlay, FastForward, Backward, CallsPause } from '../../theme/icons';
import { Box, Alert } from '@mui/material';
import WaveSurfer from 'wavesurfer.js';

const CallsAuthenticated = ({ source }) => {
  const [url, setUrl] = useState();
  const [isInS3Error, setIsInS3Error] = useState(false);
  const [isInError, setIsInError] = useState(false);

  const errorHandler = (err) => {
    setIsInS3Error(true);
  };

  useEffect(() => {
    if (!isInS3Error && url) {
      return;
    }
    const options = {
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${Cookies.get('cognito-jwt')}`,
      },
    };
    fetch(source, options)
      .then((response) => {
        setIsInS3Error(false);
        setUrl(response.url);
      })
      .catch((err) => {
        setIsInError(true);
      });
  }, [source, isInS3Error]);

  return isInError ? (
    <Alert severity="error">Error loading recording.</Alert>
  ) : !url || isInS3Error ? (
    <Box mt={2}>Loading...</Box>
  ) : (
    <AudioPlayerWithWaveform
      audioFile={url}
      controls
      autoPlay={false}
      onError={errorHandler}
    />
  );
};

export default CallsAuthenticated;

const AudioPlayerWithWaveform = ({ audioFile }) => {
  const waveformRef = useRef(null);
  const [wavesurfer, setWavesurfer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    const wavesurferInstance = WaveSurfer.create({
      barWidth: 5,
      container: waveformRef.current,
      progressColor: '#4db8ff',
      responsive: true,
      waveColor: '#99d6ff',
      cursorColor: 'transparent',
      display: 'flex',
    });

    setWavesurfer(wavesurferInstance);

    wavesurferInstance.load(audioFile);

    return () => wavesurferInstance.destroy();
  }, [audioFile]);

  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.on('audioprocess', () => {
        setCurrentTime(wavesurfer.getCurrentTime());
      });
    }
  }, [wavesurfer]);

  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.on('finish', () => {
        setIsPlaying(false);
        setCurrentTime(wavesurfer.getDuration());
      });
    }
  }, [wavesurfer]);

  const handlePlayPause = () => {
    if (wavesurfer) {
      if (isPlaying) {
        if (wavesurfer.isPlaying()) {
          wavesurfer.pause();
          setIsPlaying(false);
        }
      } else {
        wavesurfer.play();
        setIsPlaying(true);
      }
    }
  };

  const handleForward = () => {
    if (wavesurfer) {
      const currentTime = wavesurfer.getCurrentTime();
      const newTime = currentTime + 10;

      if (newTime <= wavesurfer.getDuration()) {
        wavesurfer.seekTo(newTime / wavesurfer.getDuration());
        setCurrentTime(newTime);
      }
    }
  };

  const handleBackward = () => {
    if (wavesurfer) {
      const currentTime = wavesurfer.getCurrentTime();
      const newTime = currentTime - 10;

      if (newTime >= 0) {
        wavesurfer.seekTo(newTime / wavesurfer.getDuration());
        setCurrentTime(newTime);
      }
    }
  };

  const handleSeek = (e) => {
    if (wavesurfer) {
      const seekPosition = e.nativeEvent.offsetX / waveformRef.current.clientWidth;
      const newTime = seekPosition * wavesurfer.getDuration();

      wavesurfer.seekTo(seekPosition);
      setCurrentTime(newTime);
    }
  };

  return (
    <Box>
      <Box ref={waveformRef} onClick={handleSeek} />

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Backward
          sx={{ m: 1, fontSize: '2rem' }}
          onClick={handleBackward}
          style={{ color: '#99d6ff' }}
        />
        {isPlaying ? (
          <CallsPause
            sx={{ m: 1, fontSize: '2rem' }}
            onClick={handlePlayPause}
            style={{ color: '#99d6ff' }}
          />
        ) : (
          <CallsPlay
            sx={{ m: 1, fontSize: '2rem' }}
            onClick={handlePlayPause}
            style={{ color: '#99d6ff' }}
          />
        )}
        <FastForward
          sx={{ m: 1, fontSize: '2rem' }}
          onClick={handleForward}
          style={{ color: '#99d6ff' }}
        />
      </div>
    </Box>
  );
};

import { useQuery } from 'react-query';

import { ONE_HOUR, queryKeyFactory } from '../queryClientConfig';
import Downloads from './Downloads';

export const downloadsQueryKeys = queryKeyFactory('csv-downloads');

export const useDownloadsList = (filters) => {
  return useQuery(downloadsQueryKeys.list(filters), () => Downloads.list(filters), {
    staleTime: ONE_HOUR,
  });
};

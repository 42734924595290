import Cookies from 'js-cookie';
import { toParamString, toJSON } from '../queryClientConfig';

export default class OrganizationDefaults {
  static async list(filters = []) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/organization_defaults?${toParamString(
        filters
      )}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async edit(orgDefault) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/organization_defaults/${orgDefault.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orgDefault),
      }
    ).then(toJSON);
  }

  static async create(orgDefault) {
    return await fetch(`${process.env.REACT_APP_API_BASE}/organization_defaults`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(orgDefault),
    }).then(toJSON);
  }
}

import Cookies from 'js-cookie';
import { getActiveOrg } from '../../util/UserUtils';
import { toJSON } from '../utils/utils';

export default class NotificationPreferences {
  static async list(user_id) {
    return await fetch(
      `${
        process.env.REACT_APP_API_BASE
      }/notification_preferences?user_id=${user_id}&organization_id=${getActiveOrg()}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    ).then(toJSON);
  }

  static async edit(preference) {
    return await fetch(
      `${process.env.REACT_APP_API_BASE}/notification_preferences/${preference?.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(preference),
      }
    ).then(toJSON);
  }
}

import { useQuery } from 'react-query';
import { ONE_HOUR, queryKeyFactory } from '../queryClientConfig';
import Leads from './Leads';

export const leadsQueryKeys = queryKeyFactory('leads');

export const useLeadsList = (filters, options) => {
  return useQuery(leadsQueryKeys.list(filters), () => Leads.list(filters), {
    staleTime: 0,
    ...options,
  });
};

export const useLeadsShow = (lead_id, options) => {
  return useQuery(leadsQueryKeys.show({ lead_id }), () => Leads.show(lead_id), {
    enabled: !!lead_id,
    ...options,
  });
};

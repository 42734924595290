import Cookies from 'js-cookie';
import { handleApiErrors } from '../util/HandleApiErrors';
import { getActiveOrg } from '../util/UserUtils';

export default class Lead {
  static async create(lead) {
    lead['organization_id'] = getActiveOrg();
    await fetch(`${process.env.REACT_APP_API_BASE}/leads`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(lead),
    }).then(handleApiErrors);
  }

  static async edit(lead) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/leads/${lead.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(lead),
      }
    );
    return response.json();
  }

  static async show(id) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/leads/${id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return response.json();
  }

  static async delete(lead_id) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/leads/${lead_id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    return response;
  }

  static async list(filters = {}, filtersListString) {
    filters['organization_id'] = getActiveOrg();
    if (!filters.page_size) {
      filters.page_size = 10;
    }
    if (!filters.page) {
      filters.page = 1;
    }
    let params = new URLSearchParams(filters).toString();
    if (filtersListString) params += filtersListString;
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/v2/leads?${params}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    );
    return response.json();
  }

  static async metrics({ key = 0, organizationId, startDate, endDate }) {
    if (!organizationId) {
      organizationId = getActiveOrg();
    }
    const params = `key=${key}&organization_id=${organizationId}&calculated_at_begin=${startDate}&calculated_at_end=${endDate}`;
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/metrics?${params}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    );
    return response.json();
  }
}

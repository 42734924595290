import React from 'react';

const variantMap = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  p: 'p',
  span: 'span',
};

const Text = ({
  children,
  className = '',
  as = 'p',
  color,
  align,
  weight,
  italics,
  noWrap,
  truncate,
  id,
}) => {
  const Variant = variantMap[as];

  const classes = () => {
    let classString = '';
    if (color) classString += ` text-sigma-${color}`;
    if (italics) classString += ` font-italic`;
    if (weight) classString += ` font-weight-${weight}`;
    if (align) classString += ` text-${align}`;
    if (noWrap) classString += 'text-nowrap';
    if (truncate) classString += 'text-nowrap text-truncate';
    return (classString += ' ' + className);
  };

  return (
    <Variant className={`${classes().trim()}`} id={id}>
      {children}
    </Variant>
  );
};

export default Text;

import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import AIModulesFunctionArguments from './AIModulesFunctionArguments';
import { aiModulesFunctionArgumentsQueryKeys } from './queries';
import { aimodulesFunctionDefinitionsQueryKeys } from '../aiModulesFunctionDefinitions/queries';

export const useAIModulesFunctionArgumentsCreate = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => AIModulesFunctionArguments.create(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(aiModulesFunctionArgumentsQueryKeys.all);
      queryClient.invalidateQueries(aimodulesFunctionDefinitionsQueryKeys.all);
    },
  });
};

export const useAIModulesFunctionArgumentsEdit = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => AIModulesFunctionArguments.edit({ ...params }), {
    onSuccess: () => {
      queryClient.invalidateQueries(aiModulesFunctionArgumentsQueryKeys.all);
      queryClient.invalidateQueries(aimodulesFunctionDefinitionsQueryKeys.all);
    },
  });
};

export const useAIModulesFunctionArgumentsDelete = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => AIModulesFunctionArguments.delete(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(aiModulesFunctionArgumentsQueryKeys.all);
      queryClient.invalidateQueries(aimodulesFunctionDefinitionsQueryKeys.all);
    },
  });
};

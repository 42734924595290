//queries
import { useUsersCurrent } from '../../MUI/services/users/queries';
import { useNotificationPreferences } from '../../api/notificationPreferences/queries';
import { useNotificationPreferencesEdit } from '../../api/notificationPreferences/mutations';
// other
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { SaveIcon } from '../theme/icons';
import { COL_WIDTH_LG } from '../theme/fixedValues';
import { useMemo, useState } from 'react';
import HookForm from '../components/HookForm';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

export default function NotificationsSettingsPage() {
  /* ANCHOR NotificationsSettingsPage */
  const { data: user } = useUsersCurrent();
  const {
    data: notificationPreferences,
    isFetching: isLoadingPrefs,
    isError: didLoadingNotificationPreferencesFail,
  } = useNotificationPreferences(user.id);
  const {
    mutate: editNotificationPreferences,
    isLoading: isMutatingNotificationPreferences,
    isSuccess: didSucceedMutatingPreferences,
    reset: resetNotificationPreferences,
  } = useNotificationPreferencesEdit({
    onError: () => {
      setDidFailMutatingPreferences(true);
    },
  });
  const [didFailMutatingPreferences, setDidFailMutatingPreferences] = useState(
    false
  );

  const getDefaultValues = useMemo(() => {
    if (notificationPreferences?.length > 0) {
      let tempDefaultValues = {};
      notificationPreferences?.forEach((pref) => {
        if (pref && pref?.notification_option?.user_configurable) {
          tempDefaultValues = {
            ...tempDefaultValues,
            [pref?.notification_option?.event_type]: {
              ...(pref?.notification_option?.text_enableable && {
                text: pref.text,
              }),
              ...(pref?.notification_option?.email_enableable && {
                email: pref.email,
              }),
              ...(pref?.notification_option?.push_enableable && {
                push: pref.push,
              }),
            },
          };
        }
      });
      return tempDefaultValues;
    }
  }, [notificationPreferences]);

  const onNotificationPreferencesSubmit = (formData) => {
    setDidFailMutatingPreferences(false);
    notificationPreferences.forEach((preference) => {
      const tempPref = {
        ...preference,
        ...formData[preference?.notification_option?.event_type],
      };
      editNotificationPreferences(tempPref, {});
    });
  };

  return (
    <SettingsCard showLoading={isLoadingPrefs}>
      <SettingsCard.Header>Notification Preferences</SettingsCard.Header>
      <SettingsCard.SubHeader>
        How would you like to receive your notifications? Some notifications allow
        you to respond to leads by replying to them.
      </SettingsCard.SubHeader>
      <SettingsCard.Main>
        {didFailMutatingPreferences && (
          <Alert severity="error" onClose={() => resetNotificationPreferences()}>
            There was an error saving your notification preferences. Try again.
          </Alert>
        )}
        {didSucceedMutatingPreferences && !didFailMutatingPreferences && (
          <Alert severity="success" onClose={() => resetNotificationPreferences()}>
            Successfully saved your notification preferences.
          </Alert>
        )}
        {!isLoadingPrefs &&
          notificationPreferences.filter((x) => {
            return x;
          }).length == 0 && (
            <Alert severity="error">
              Contact support to configure notification preferences for this account.
            </Alert>
          )}
        {didLoadingNotificationPreferencesFail ? (
          <Alert severity="error">
            There was a problem loading your notification preferences.
          </Alert>
        ) : (
          <Box>
            <HookForm
              useFormProps={{ defaultValues: getDefaultValues }}
              onSubmit={onNotificationPreferencesSubmit}
              useDirtyFormCheck
            >
              <TableContainer sx={{ mb: 2 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell width={COL_WIDTH_LG}>Preference</TableCell>
                      <TableCell width={40} align="center">
                        Text
                      </TableCell>
                      <TableCell width={40} align="center">
                        Email
                      </TableCell>
                      <TableCell width={40} align="center">
                        Push
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {notificationPreferences
                      ?.filter((x) => {
                        return x;
                      })
                      ?.map(
                        ({
                          notification_option: {
                            event_type,
                            label,
                            text_enableable,
                            email_enableable,
                            push_enableable,
                          },
                        }) => (
                          <TableRow key={event_type}>
                            <TableCell>
                              <Typography variant="body2">{label}</Typography>
                            </TableCell>
                            {[
                              { type: 'text', enableable: text_enableable },
                              { type: 'email', enableable: email_enableable },
                              { type: 'push', enableable: push_enableable },
                            ].map(({ type, enableable }) => (
                              <TableCell key={type} align="center">
                                {enableable && (
                                  <HookForm.Checkbox
                                    name={`${event_type}.${type}`}
                                    checkboxSx={{ p: 0 }}
                                    label=""
                                  />
                                )}
                              </TableCell>
                            ))}
                            <TableCell />
                          </TableRow>
                        )
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
              <LoadingButton
                loading={isMutatingNotificationPreferences}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Save
              </LoadingButton>
            </HookForm>
          </Box>
        )}
      </SettingsCard.Main>
    </SettingsCard>
  );
}

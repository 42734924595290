import Cookies from 'js-cookie';
import { useOrg } from '../contexts/OrgProvider';
import { getActiveOrg } from '../util/UserUtils';

export default class Call {
  static async list(filters = {}) {
    const params = new URLSearchParams({
      organization_id: getActiveOrg(),
      ...filters,
    }).toString();
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/calls?${params}`,
      {
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
      }
    );
    return response.json();
  }

  static async edit(call) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE}/calls/${call.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(call),
      }
    );
    return response.json();
  }
}

import { useQuery } from 'react-query';
import SavedSearches from './SavedSearches';
import { ONE_HOUR, queryKeyFactory } from '../queryClientConfig';

export const savedSearchesQueryKeys = queryKeyFactory('saved_searches');

export const useSavedSearchesList = (filters, config, options) => {
  const filterList = (data) => {
    return data.items.filter(({ resource_class }) => {
      if (!config?.resourceClass) return true;
      return config.resourceClass === resource_class;
    });
  };

  return useQuery(
    savedSearchesQueryKeys.list(filters),
    () => SavedSearches.list(filters),
    {
      staleTime: ONE_HOUR,
      select: filterList,
      ...options,
    }
  );
};

import { Dropdown } from 'react-bootstrap';

export const mailMergeFields = [
  { display: 'First Name', value: '{{first_name}}' },
  { display: 'Last Name', value: '{{last_name}}' },
  { display: 'User First Name', value: '{{user_first_name}}' },
  { display: 'User Last Name', value: '{{user_last_name}}' },
  { display: 'Team Name', value: '{{team_name}}' },
  { display: 'Team Phone', value: '{{team_phone}}' },
];

export const MailMergeDropdown = ({ toggleClassName, onSelect, disabled }) => {
  return (
    <Dropdown onSelect={(e) => onSelect(e)}>
      <Dropdown.Toggle
        id="token-button"
        className={toggleClassName}
        disabled={disabled}
      >
        Insert Mail Merge Field
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {mailMergeFields.map(({ display, value }) => (
          <Dropdown.Item key={value} eventKey={value}>
            {display}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

import { useMutation, useQueryClient } from 'react-query';
import { leadsQueryKeys } from './queries';
import Leads from './Leads';
import { mutationKeyFactory } from '../queryClientConfig';
import { tasksQueryKeys } from '../tasks/queries';

const leadsMutationKeys = mutationKeyFactory('leads');

export const useLeadsCreate = (options) => {
  const queryClient = useQueryClient();
  return useMutation(Leads.create, {
    mutationKey: leadsMutationKeys.create,
    onSuccess: () => {
      queryClient.invalidateQueries(leadsQueryKeys.lists());
      queryClient.invalidateQueries(tasksQueryKeys.lists());
    },
    ...options,
  });
};

export const useLeadsEdit = (options) => {
  const queryClient = useQueryClient();
  return useMutation(Leads.edit, {
    mutationKey: leadsMutationKeys.edit,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(leadsQueryKeys.lists());
      queryClient.invalidateQueries(leadsQueryKeys.show({ lead_id: variables.id }));
    },
    ...options,
  });
};

export const useLeadsEditGroup = (options) => {
  const queryClient = useQueryClient();
  return useMutation(Leads.editGroup, {
    mutationKey: leadsMutationKeys.edit,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(leadsQueryKeys.lists());
    },
    ...options,
  });
};

export const useLeadsDelete = (options) => {
  const queryClient = useQueryClient();
  return useMutation(Leads.delete, {
    mutationKey: leadsMutationKeys.delete,
    onSuccess: () => {
      queryClient.invalidateQueries(leadsQueryKeys.lists());
    },
    ...options,
  });
};

export const useLeadsDeleteGroup = (options) => {
  const queryClient = useQueryClient();
  return useMutation(Leads.deleteGroup, {
    mutationKey: leadsMutationKeys.delete,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(leadsQueryKeys.lists());
    },
    ...options,
  });
};

import { useEffect, useState } from 'react';
import { Box, Typography, Autocomplete, TextField } from '@mui/material';
import { useSourcesList } from '../../../services/sources/queries';
import { useOrg } from '../../../../contexts/OrgProvider';
import { useTeamsList } from '../../../services/teams/queries';

export const CreateLead = ({
  handleMutateComponentListByParametersInUI,
  id,
  parameters,
  doSearchEmptyInputs,
  getUpdatedInputParams,
}) => {
  const { id: orgId } = useOrg();
  const { data: sources } = useSourcesList([['organization_id[]', orgId]]);
  const { data: teams } = useTeamsList([['organization_id[]', orgId]]);

  const parseParameters = () => {
    const data = getUpdatedInputParams(id)?.parameters;
    if (!sources || !teams) return {};

    const source = sources.find((s) => s.id === data.source_id);
    const team = teams.find((t) => t.id === data.team_id);

    return {
      source: { label: source?.name, value: source?.id },
      team: { label: team?.name, value: team?.id },
    };
  };

  const [inputParameters, setInputParameters] = useState(parseParameters());

  const handleChange = (key, value) => {
    setInputParameters({
      ...inputParameters,
      [key]: value,
    });
    handleMutateComponentListByParametersInUI(
      {
        source_id: inputParameters.source?.value,
        team_id: inputParameters.team?.value,
        [`${key}_id`]: value?.value,
      },
      id
    );
  };

  const normalizedList = (list) => {
    return (
      list?.length &&
      list
        .filter((item) => item.name && item.id)
        .map((item) => {
          return { label: item.name, value: item.id };
        })
    );
  };

  useEffect(() => {
    setInputParameters(parseParameters());
  }, [sources, teams]);

  return (
    <>
      <Box>
        <Typography fontSize={15} fontWeight={700} mb={1}>
          Please choose source
        </Typography>
        <Autocomplete
          onChange={(_, newValue) => handleChange('source', newValue)}
          sx={{ width: '300px', marginBottom: '20px' }}
          value={inputParameters?.source?.label || ''}
          options={normalizedList(sources) || []}
          name="source"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Source"
              error={doSearchEmptyInputs && !inputParameters?.source?.label}
            />
          )}
        />
      </Box>
      <Box>
        <Typography fontSize={15} fontWeight={700} mb={1}>
          Please choose the team
        </Typography>
        <Autocomplete
          onChange={(_, newValue) => handleChange('team', newValue)}
          sx={{ width: '300px', marginBottom: '20px' }}
          value={inputParameters?.team?.label || ''}
          options={normalizedList(teams) || []}
          name="team"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Team"
              error={doSearchEmptyInputs && !inputParameters?.team?.label}
            />
          )}
        />
      </Box>
    </>
  );
};

import { useQueryClient } from 'react-query';
import { queryKeys } from './queryConfigs';

const useQueryClientUtil = () => {
  const {
    taskKeys: { tasksDueToday, tasksCompleted },
  } = queryKeys;
  const queryClient = useQueryClient();

  const refethTasksDueToday = () => queryClient.refetchQueries(tasksDueToday);
  const refetchTasksCompleted = () => queryClient.refetchQueries(tasksCompleted);

  const refetchTodoAndCompletedTasks = () => {
    refetchTasksCompleted();
    refethTasksDueToday();
  };

  return {
    refethTasksDueToday,
    refetchTodoAndCompletedTasks,
  };
};

export default useQueryClientUtil;
